import React from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { solicitorsRefFilterUpdate, solicitorsRefFilterClear, solicitorsRefLookup } from '../../redux/solicitorsRefReducer';

import MyDateField from '../MyDateField';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& input, & select": {
            padding: "0px 1px",
            fontSize: "13px",
            height: "1.5em",
        },

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },
    },
});

export default function SolicitorsRefFilter({ filter }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const filters = JSON.parse(filter.filter);

    const _getFilterValue = (field) => {
        const i = _.findIndex(filters, function (o) { return o.property == field; });
        return (i >= 0) ? filters[i].value : "";
    };

    const filterChange = (field, value) => {
        dispatch(solicitorsRefFilterUpdate(field, value));
    };

    const clearFilter = () => {
        dispatch(solicitorsRefFilterClear());
    };

    const lookup = () => {
        dispatch(solicitorsRefLookup(filter));
    }

    return (
        <div>
            <table className={`${classes.frmFilter} mb-2`}>
                <tbody>
                    <tr>
                        <th>律師樓(中)：</th><td><input onChange={(e) => { filterChange('l_solicitors_chi', e.target.value); }} value={_getFilterValue('l_solicitors_chi')} style={{ width: '200px' }} tabIndex={1} /></td>
                        <th>律師樓(英)：</th><td><input onChange={(e) => { filterChange('l_solicitors_eng', e.target.value); }} value={_getFilterValue('l_solicitors_eng')} style={{ width: '200px' }} tabIndex={2} /></td>
                        <th>律師 Tel：</th><td><input onChange={(e) => { filterChange('l_lawyer_tel', e.target.value); }} value={_getFilterValue('l_lawyer_tel')} style={{ width: '200px' }} tabIndex={3} /></td>
                    </tr>
                    <tr>
                        <th>律師(中)：</th><td><input onChange={(e) => { filterChange('l_lawyer_chi', e.target.value); }} value={_getFilterValue('l_lawyer_chi')} style={{ width: '200px' }} tabIndex={4} /></td>
                        <th>律師(英)：</th><td><input onChange={(e) => { filterChange('l_lawyer_eng', e.target.value); }} value={_getFilterValue('l_lawyer_eng')} style={{ width: '200px' }} tabIndex={5} /></td>
                        <th>律師 Fax：</th><td><input onChange={(e) => { filterChange('l_lawyer_fax', e.target.value); }} value={_getFilterValue('l_lawyer_fax')} style={{ width: '200px' }} tabIndex={6} /></td>
                    </tr>
                </tbody>
            </table>
            <Grid container justifyContent="flex-end" spacing={2} className="mb-2">
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { lookup(); }}>搜　尋</Button>
                </Grid>
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { clearFilter(); }}>清　除</Button>
                </Grid>
            </Grid>
        </div>
    );
}
