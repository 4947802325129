import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Moment from 'moment';

import Bonu from "../models/bonu"; // created by scaffolder
import { showErrorResponse } from "./msgReducer";


//================== Whole State ==================//
const initialState = {
    tableRateBonuses: {
        SECOND_HAND: _.range(5).map(i => new Bonu({ Class: "SECOND_HAND" })),
        SPECIAL: _.range(5).map(i => new Bonu({ Class: "SPECIAL" })),
        SUPERVISOR: _.range(5).map(i => new Bonu({ Class: "SUPERVISOR" })),
        //SUPERVISOR_SECOND_HAND: _.range(5).map(i => new Bonu({ Class: "SUPERVISOR_SECOND_HAND" })),
        //SUPERVISOR_SPECIAL: _.range(5).map(i => new Bonu({ Class: "SUPERVISOR_SPECIAL" })),
    }
};

//================== Action Types ==================//
export const BonusActions = Object.freeze({
    // tableRate Detail page associated
    TABLERATE_DETAIL_SUCCESS: Symbol("TABLERATE_DETAIL_SUCCESS"),
    TABLERATE_DETAIL_UPDATE: Symbol("TABLERATE_DETAIL_UPDATE"), // commTran update
    TABLERATE_DETAIL_SAVE: Symbol("TABLERATE_DETAIL_SAVE"),
});


//================== Action Creators ==================//
const _tableRateDetailSuccess = ({ data }, tableRateId, isReference) => {
    return {
        type: BonusActions.TABLERATE_DETAIL_SUCCESS,
        payload: { data, tableRateId, isReference },
    }
}

export const tableRateDetail = (isReference = false) => {
    return (dispatch, getState) => {
        const { tableRate: { workingEntity, referenceEntity } } = getState();
        const tableRate = isReference ? referenceEntity : workingEntity;
        if (tableRate.isNew()) {
            dispatch(dispatch(_tableRateDetailSuccess([], tableRate.Id, isReference)));
            return;
        }

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/bonu/GetEntitiesByTableRate`;

        axios.post(apiEndpoint, tableRate, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch(_tableRateDetailSuccess(response, tableRate.Id, isReference));
                }
            })
    };
}

export const tableRateDetailUpdate = (class1, index, fieldName, value) => {
    return {
        type: BonusActions.TABLERATE_DETAIL_UPDATE,
        payload: { class1, index, fieldName, value },
    }
}

export const saveEntitiesByTableRate = (tableRate, getState) => {
    const { bonus: { tableRateBonuses } } = getState();

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/bonu/SaveEntitiesByTableRate?tableRateId=${tableRate.Id}`;
    return axios.post(apiEndpoint, tableRateBonuses, {
        timeout: 30000,
    });
}

export const deleteEntitiesByTableRate = (getState) => {
    const { bonus: { tableRateBonuses } } = getState();
    console.log('bonus/DeleteEntitiesByTableRate');
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/bonu/DeleteEntitiesByTableRate`;
    return axios.post(apiEndpoint, tableRateBonuses, {
        timeout: 30000,
    });
}


//================== Reducer ==================//
const bonusReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);

    switch (action.type) {
        case BonusActions.TABLERATE_DETAIL_SUCCESS:
            _state.tableRateBonuses = cloneDeep(initialState.tableRateBonuses);

            const { data, tableRateId, isReference } = action.payload;
            //console.log(isReference);
            _.forEach(_.keys(_state.tableRateBonuses), function (_class) {
                _.forEach(_.filter(data, function (b) { return b.Class == _class; }), function (bonus, i) {
                    bonus.IdTableRate = tableRateId; // assign to tableRateDetail workingEntity
                    if (isReference) bonus.Id = 0; // new Bars when referenceing other tableRate

                    _state.tableRateBonuses[_class][i] = bonus;
                });
            });
            return _state;

        case BonusActions.TABLERATE_DETAIL_UPDATE:
            const { class1: updateClass1, index: updateIndex, fieldName: updateFieldName, value: updateValue } = action.payload;
            _state.tableRateBonuses[updateClass1][updateIndex][updateFieldName] = updateValue;
            return _state;

        case BonusActions.TABLERATE_DETAIL_SAVE:
            return _state;

        default:
            return state;
    }
}

export default bonusReducer;
