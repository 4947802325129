import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  title: {
    paddingBottom: "15px",
    fontSize: "26px",
    lineHeight: "26px",
    fontWeight: "bold",
  },
  reportLinks: {
    padding: "0px 0px 0px 40px",
    listStyleType: "none",

    "& a, h3": {
      fontWeight: "bold",
      fontSize: "19px",
      lineHeight: "30px",
      padding: "0px",
    },

    "& ul": {
      padding: "0px 0px 0px 40px",
      listStyleType: "none",
    }
  },
});


export default function Report() {
  const classes = useStyles();

  const history = useHistory();

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  return (
    <div>
      <Typography variant="h1" className={classes.title}>7. 報告</Typography>
      <ul className={classes.reportLinks}>
        <li>
          <h3>
            <NavLink tag={Link} to="/reportComm">7.1. 佣金報告(糧單)</NavLink>
          </h3>
          <ul>
            <h3>
              <NavLink tag={Link} to="/reportCommPrepaid">7.1.1 特殊預支佣金</NavLink>
            </h3>
          </ul>
        </li>
        <li>
          <h3>
            <NavLink tag={Link} to="/reportCommSupervisor">7.2. 主管佣金報告</NavLink>
          </h3>
        </li>
        <li>
          <Typography variant="h3">7.3. 佣金交易活動：</Typography>
          <ul>
            <h3>
              <NavLink tag={Link} to="/reportCommReceived">7.3.1 已收佣金報告</NavLink>
            </h3>
            <h3>
              <NavLink tag={Link} to="/reportCommReduced">7.3.2 減收佣金報告</NavLink>
            </h3>
          </ul>
        </li>
        <li>
          <h3>
            <NavLink tag={Link} to="/reportBusiness">7.4. 營業報告</NavLink>
          </h3>
        </li>
        <li>
          <h3>
            <NavLink tag={Link} to="/reportConclude">7.5. 物業成交報告</NavLink>
          </h3>
        </li>
        <li>
          <h3>
            <NavLink tag={Link} to="/reportEmployeeDue">7.6. 營業員應收未收報告</NavLink>
          </h3>
        </li>
        <li>
          <h3>
            <NavLink tag={Link} to="/reportCommDue">7.7. 應收未收佣金報告（已過 / 未過到期日）</NavLink>
          </h3>
        </li>
        <li>
          <h3>
            <NavLink tag={Link} to="/reportEmployeeTurnover">7.8. 營業員營業報告（所有交易）</NavLink>
          </h3>
        </li>
        <li>
          <h3>
            <NavLink tag={Link} to="/reportDebitNote">7.9. 付款通知單</NavLink>
          </h3>
          <ul>
            <h3>
              <NavLink tag={Link} to="/reportReceipt">7.9.1 正式收據</NavLink>
            </h3>
            <h3>
              <NavLink tag={Link} to="/jobSendInvoice">7.9.2 發送付款通知單記錄</NavLink>
            </h3>
          </ul>
        </li>
        <li>
          <h3>
            <NavLink tag={Link} to="/reportRank">7.10. 營業員每月排位</NavLink>
          </h3>
        </li>
        <li>
          <h3>
            <NavLink tag={Link} to="/reportQtyTran">7.11. 營業員營業比率報告</NavLink>
          </h3>
        </li>
        <li>
          <h3>
            <NavLink tag={Link} to="/reportProvision">7.12. Provision Commission Paid</NavLink>
          </h3>
        </li>
        <li>
          <h3>
            <NavLink tag={Link} to="/reportSupervisorRank">7.13. 經理業績報告</NavLink>
          </h3>
        </li>
        <li>
          <h3>
            <NavLink tag={Link} to="/reportSolicitors">7.14. 律師樓報告</NavLink>
          </h3>
        </li>
        <li>
          <h3>
            <NavLink tag={Link} to="/reportCommOverview">7.15. 佣金報告 (月份總覽)</NavLink>
          </h3>
        </li>
        <li>
          <h3>
            <NavLink tag={Link} to="/reportVacation">7.16. 假期補發佣金報告</NavLink>
          </h3>
        </li>
      </ul>
    </div>
  );
}
