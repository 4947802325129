import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import { branchLookup, branchChangeSort, branchFetchPage, branchPageUpdate } from '../../redux/branchReducer';

import Pagination from '../Pagination';
import BranchList from './BranchList';

const useStyles = makeStyles({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },

    },
});

export default function Branch() {
    const classes = useStyles();

    const history = useHistory();

    const dispatch = useDispatch();

    const newBranch = () => {
        history.push("/branchDetail/0");
    }

    const { isLoading, filter, entities, workingEntityId, errors } = useSelector(state => {
        return state.branch;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");

        dispatch(branchLookup());
    }, []);

    const sorts = JSON.parse(filter.sort);

    const _getSortDirection = (field) => {
        const i = _.findIndex(sorts, function (o) { return o.property == field; });
        return (i >= 0) ? sorts[i].direction : "";
    };

    const changeSort = (field) => {
        dispatch(branchChangeSort(field, filter));
    };

    const showDetail = (id) => {
        history.push(`/branchDetail/${id}`);
    }
    return (
        <>
            <Breadcrumbs className={classes.title}>
                <NavLink className="nav-link" tag={Link} to="/setting">
                    <Typography variant="h1" className={classes.title}>10. 設定</Typography>
                </NavLink>
                <Typography variant="h1" className={classes.title}>分行</Typography>
            </Breadcrumbs>
            <Grid container justifyContent="space-between" className="mb-2">
                <Grid item>
                    <Button variant="contained" size="small" onClick={newBranch}>新增分行</Button>
                </Grid>
            </Grid>

            <BranchList />

            <Pagination filter={filter} fnFetchPage={branchFetchPage} fnPageUpdate={branchPageUpdate} />
        </>
    );
}
