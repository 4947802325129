export default class CommTran {
    constructor({
        Id,
        IdComm,
        Class,
        Party,
        Amount,
        PaymentMethod,
        PaymentCheque,
        PaymentBank,
        DateTran,
        DateReport,
        DateDeposit,
        DateContract,
        DateConclude,
        DateDue,
        EditDate,
        EditTime,
        EditUser,
    }) {
        this.Id = Id;
        this.IdComm = IdComm;
        this.Class = Class;
        this.Party = Party;
        this.Amount = Amount;
        this.PaymentMethod = PaymentMethod;
        this.PaymentCheque = PaymentCheque;
        this.PaymentBank = PaymentBank;
        this.DateTran = DateTran;
        this.DateReport = DateReport;
        this.DateDeposit = DateDeposit;
        this.DateContract = DateContract;
        this.DateConclude = DateConclude;
        this.DateDue = DateDue;
        this.EditDate = EditDate;
        this.EditTime = EditTime;
        this.EditUser = EditUser;
    }


    isNew() {
        return (this.Id == undefined) || (this.Id < 1) || (this.Id.toString().toUpperCase() == "NEW");
    }

    getDesc() {
        if (this.isNew()) return "新已收佣金";

        return `#${this.Id}`;
    }

    hasInputValue() {
        return this.Amount > 0;
    }
}
