import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { bankReferralDetailUpdate } from '../../redux/bankReferralReducer';

export default function BankReferralInputField({ index, fieldName, width, tabIndex, textAlign }) {
    const dispatch = useDispatch();
    const { entities } = useSelector(state => {
        return state.bankReferral;
    });

    const entity = entities[index];

    return <input value={entity[fieldName] ? entity[fieldName] : ''} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(bankReferralDetailUpdate(index, fieldName, e.target.value)); }} />
}