import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        border: '1px solid #849df3',
        backgroundColor: '#F1FBFD',
        color: 'black',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(14),
    },
}))(Tooltip);

export default function MyToolTip({ message, component }) {
    const [isVisible, setIsVisible] = useState(false);

    return <ClickAwayListener onClickAway={() => { setIsVisible(false); }}>
        <HtmlTooltip
            onClose={() => { setIsVisible(false); }}
            open={isVisible}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={<p>{message}</p>}
        >
            {component
                ? <Button variant= "contained" size="small" color="primary" onClick={() => { setIsVisible(true); }} >{component}</Button>
                : <IconButton size="small" color="primary" onClick={() => { setIsVisible(true); }}><HelpIcon /></IconButton>
            }
        </HtmlTooltip>
    </ClickAwayListener>;
};