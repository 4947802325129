import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Moment from 'moment';
import _ from 'lodash';

import { branchHeadCountDetails, branchHeadCountDetailsInit, branchHeadCountDetailUpdate } from '../../redux/branchHeadCountReducer';

import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import BeatLoader from "react-spinners/BeatLoader";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';

import BranchHeadCountDetailInputField from './BranchHeadCountDetailInputField';
import BranchHeadCountDetailBottomButtons from './BranchHeadCountDetailBottomButtons';
import MyDateField from '../MyDateField';

const useStyles = makeStyles((theme) => ({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
  table: {
    width: "100%",
    backgroundColor: "white",

    "& tr.alt": {
      backgroundColor: "#FFF9F0",
    },

    "& th": {
      backgroundColor: "#FAF3DE",
    },

    "& th, & td": {
      padding: "2px 6px",
      border: "1px solid #D5D5D5",
      textAlign: 'center',
    },
  },
}));

export default function BranchHeadCountDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, workingEntities } = useSelector(state => {
    return state.branchHeadCount;
  });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  const { id, calDate } = useParams();

  useEffect(() => {
    dispatch(branchHeadCountDetails(id));
  }, [id]);

  const qty = workingEntities.length;

  useEffect(() => {
    if (!calDate || !qty) return; // after loaded

    dispatch(branchHeadCountDetailsInit(calDate));
  }, [calDate, qty]);

  const navClicked = (path) => {
    history.push(path);
  };

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/branchHeadCount/list">
          <Typography variant="h1" className={classes.title}>4. 分行人數</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : workingEntities && workingEntities[0] ? workingEntities[0].getDesc() : ""}</Typography>
      </Breadcrumbs>
      {workingEntities &&
        <>
          <Grid container>
            <Button variant="contained" className="mr-4" size="small" disableElevation onClick={() => { navClicked("/branchHeadCount"); }}>返回列表</Button>
            <div style={{ flex: 1 }} />
            <Button variant="contained" size="small" disableElevation onClick={() => { dispatch(branchHeadCountDetails(id, true)); }}><RefreshIcon /></Button>
          </Grid>

          <div style={{ height: '10px' }}></div>
          {(workingEntities.length > 0) &&
            <table className={classes.table} style={{ width: '600px' }}>
              <tbody>
                <tr>
                  <th style={{ width: '120px' }}>開始日期</th><td><MyDateField dateValue={workingEntities && workingEntities[0] ? workingEntities[0].Fdate : ''} onDateChange={(d) => { dispatch(branchHeadCountDetailUpdate(0, 'Fdate', d)); }} tabIndex={1} /></td>
                  <th style={{ width: '120px' }}>結束日期</th><td><MyDateField dateValue={workingEntities && workingEntities[0] ? workingEntities[0].Tdate : ''} onDateChange={(d) => { dispatch(branchHeadCountDetailUpdate(0, 'Tdate', d)); }} tabIndex={2} /></td>
                </tr>
              </tbody>
            </table>
          }

          <div style={{ height: '10px' }}></div>

          {workingEntities &&
            <table className={classes.table} style={{ width: '600px' }}>
              <thead>
                <tr>
                  <th>分行</th>
                  <th>PC 平均人數</th>
                </tr>
              </thead>
              <tbody>
                {workingEntities.map((branchHeadCount, index) =>
                  <tr key={`branchHeadCount_${index}`} className={index % 2 == 0 ? "normal" : "alt"}>
                    <td>
                      {branchHeadCount.Branch}
                    </td>
                    <td>
                      <BranchHeadCountDetailInputField index={index} fieldName="AvgHeadCount" width={60} tabIndex={index} textAlign="right" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          }

          <BranchHeadCountDetailBottomButtons />
        </>
      }
    </>
  );
}
