import React from 'react';
import { Link as RouteLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { bankReferralChangeSort, bankReferralSwap, bankReferralDelete, bankReferralMore } from '../../redux/bankReferralReducer';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import BeatLoader from "react-spinners/BeatLoader";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';

import BankReferralInputField from './BankReferralInputField';
import BankReferralTextArea from './BankReferralTextArea';

const useStyles = makeStyles({
    tblList: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& thead": {
            backgroundColor: "#c5e2ff",
            color: "#45525c",
        },

        "& th.sortable": {
            cursor: "pointer",
        },

        "& tr.alt": {
            backgroundColor: "#f7fbff",
        },

        "& tr.lastWorkingEntity": {
            border: "2px solid #da59da",
        },

        "& tbody tr:hover, tbody tr.alt:hover": {
            backgroundColor: "#ffffcc !important",
        },

        "& th": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
            verticalAlign: 'top',
            paddingTop: '5px,'
        },
    },
});

export default function BankReferralList({ printEntity }) {
    const classes = useStyles();

    const history = useHistory();
    const dispatch = useDispatch();

    const { isLoading, filter, entities, errors } = useSelector(state => {
        return state.bankReferral;
    });

    const sorts = JSON.parse(filter.sort);

    const _getSortDirection = (field) => {
        const i = _.findIndex(sorts, function (o) { return o.property == field; });
        return (i >= 0) ? sorts[i].direction : "";
    };

    const changeSort = (field) => {
        dispatch(bankReferralChangeSort(field, filter));
    };

    const swapEntity = (index) => {
        dispatch(bankReferralSwap(index));
    };

    const deleteEntity = (index) => {
        dispatch(bankReferralDelete(index));
    }

    const moreEntity = (index) => {
        dispatch(bankReferralMore());
    }

    let i = 1;

    return (
        <table className={classes.tblList} >
            <thead>
                <tr>
                    <th rowSpan={2}><span>行</span></th>
                    <th className="sortable" rowSpan={2} onClick={(_) => changeSort('id')}><span>Id</span><MyArrowIcon direction={_getSortDirection("id")} /></th>
                    <th className="sortable" rowSpan={2} onClick={(_) => changeSort('branch')}><span>分行</span><MyArrowIcon direction={_getSortDirection("branch")} /></th>
                    {/*<th rowSpan={2}><span>身分證號碼</span></th>*/}
                    {/*<th rowSpan={2}><span>員工編號</span></th>*/}
                    {/*<th rowSpan={2}><span>員工姓名</span></th>*/}
                    <th className="sortable" rowSpan={2} onClick={(_) => changeSort('hkid')}><span>身分證號碼</span><MyArrowIcon direction={_getSortDirection("hkid")} /></th>
                    <th className="sortable" rowSpan={2} onClick={(_) => changeSort('id_employee')}><span>員工編號</span><MyArrowIcon direction={_getSortDirection("id_employee")} /></th>
                    <th className="sortable" rowSpan={2} onClick={(_) => changeSort('name')}><span>員工姓名</span><MyArrowIcon direction={_getSortDirection("name")} /></th>
                    <th colSpan={4}>營業員</th>
                    <th rowSpan={2}>&nbsp;</th>
                    <th colSpan={5}>主管</th>
                    <th rowSpan={2}>&nbsp;</th>
                </tr>
                <tr>
                    <th><span>列印</span></th>
                    <th><span>Bank Referral</span></th>
                    <th><span>其他金額</span></th>
                    <th><span>其他 Remarks</span></th>
                    <th><span>列印</span></th>
                    <th><span>Bank Referral</span></th>
                    <th><span>營業額獎金</span></th>
                    <th><span>其他金額</span></th>
                    <th><span>其他 Remarks</span></th>
                </tr>
            </thead>

            {isLoading
                ?
                <tbody>
                    <tr>
                        <td colSpan={17} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
                    </tr>
                </tbody>
                :
                (errors !== "")
                    ?
                    <tbody>
                        <tr>
                            <td colSpan={75} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
                        </tr>
                    </tbody >
                    :
                    <tbody>
                        {
                            entities.length < 1
                                ? <tr>
                                    <td colSpan={17} style={{ padding: "3em", textAlign: "center", }}><em>沒有符合的記錄</em></td>
                                </tr>
                                : entities.map((entity, index) => {
                                    return <tr key={index} className={clsx({ "alt": index % 2 == 1, "normal": index % 2 == 0 })}>
                                        <td>{i++}</td>
                                        <td>{entity.Id}</td>
                                        <td><BankReferralInputField index={index} fieldName="Branch" tabIndex={index * 0 + 1} width={90} /></td>
                                        <td><BankReferralInputField index={index} fieldName="Hkid" tabIndex={index * 0 + 2} width={90} /></td>
                                        <td>{entity.IdEmployee}</td>
                                        <td>{entity.Name}</td>
                                        <td><IconButton className="ml-1" size="small" onClick={e => { printEntity(index, 'ReportComm'); }}><PrintIcon style={{ color: "rgb(162 162 162)" }} /></IconButton></td>
                                        <td><BankReferralInputField index={index} fieldName="EmpBankReferral" tabIndex={index * 0 + 3} width={70} /></td>
                                        <td><BankReferralInputField index={index} fieldName="EmpOthers" tabIndex={index * 0 + 4} width={70} /></td>
                                        <td><BankReferralTextArea index={index} fieldName="EmpRemarks" tabIndex={index * 0 + 5} width={160} /></td>
                                        <td><IconButton className="ml-1" size="small" onClick={e => { swapEntity(index); }}><SwapHorizIcon style={{ color: "rgb(162 162 162)" }}/></IconButton></td>
                                        <td><IconButton className="ml-1" size="small" onClick={e => { printEntity(index, 'ReportCommSupervisor'); }}><PrintIcon style={{ color: "rgb(162 162 162)" }} /></IconButton></td>
                                        <td><BankReferralInputField index={index} fieldName="SupBankReferral" tabIndex={index * 0 + 6} width={70} /></td>
                                        <td><BankReferralInputField index={index} fieldName="SupCommBonus" tabIndex={index * 0 + 7} width={70} /></td>
                                        <td><BankReferralInputField index={index} fieldName="SupOthers" tabIndex={index * 0 + 8} width={70} /></td>
                                        <td>
                                            <BankReferralTextArea index={index} fieldName="SupRemarks" tabIndex={index * 0 + 9} width={160} />
                                        </td>
                                        <td><IconButton className="ml-1" size="small" onClick={e => { deleteEntity(index); }}><DeleteIcon style={{ color: "rgb(162 162 162)" }} /></IconButton></td>
                                    </tr>
                                })

                        }
                        <tr>
                            <td colSpan={17} style={{ textAlign:'left' }} >
                                <Button onClick={moreEntity} size="small" variant="contained" disableElevation>更多 Bank Referral 記錄</Button>
                            </td>
                        </tr>
                    </tbody>
            }
        </table>
    );
}

function MyArrowIcon({ direction }) {
    switch (direction) {
        case "ASC": return <ArrowDropUpIcon />;
        case "DESC": return <ArrowDropDownIcon />;
        default: return null;
    }
}

function MySettleIcon({ arReceive, received }) {
    if (arReceive == 0) return <RemoveCircleIcon style={{ color: "rgb(193 178 197)" }} />;
    if (received >= arReceive) return <CheckCircleIcon style={{ color: "#4fa917" }} />;
    return <CancelIcon style={{ color: "#e43e3e" }} />
}