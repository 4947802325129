import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { changePassword } from '../../redux/userReducer';
import { showConfirm } from '../../redux/msgReducer';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import BeatLoader from "react-spinners/BeatLoader";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },

  },
  detail: {
    "& table": {
      width: "100%",
      backgroundColor: "white",

      "& th": {
        backgroundColor: "#FAF3DE",
        textAlign: 'right',
      },

      "& th, & td": {
        padding: "2px 6px",
        border: "1px solid #D5D5D5",

        "& .MuiInputBase-input": { // <OutlinedInput>
          padding: "3px 4px",
        },

        "& .MuiIconButton-root": { // <Text
          padding: "0px",
        },

        "& legend": {
          borderLeft: "none", // <OutlinedInput>
        },
      },
    },
  },
});

export default function UserDetailPassword() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { user: { isLoading } } = useSelector(state => state);

  const [state, setState] = useState({
    OldPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
    IsVisible: false,
    OldPasswordError: undefined,
    NewPasswordError: undefined,
    ConfirmPasswordError: undefined,
  });

  const confirmUpdatePassword = (e) => {
    const fnConfirmed = (_) => { dispatch(changePassword(state, setState)); };
    dispatch(showConfirm("修改密碼？", fnConfirmed));
  };

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : "修改密碼"}</Typography>
      </Breadcrumbs>
      <div className={classes.detail}>
        <table className="mt-2" style={{ width: '300px' }}>
          <tbody>
            <tr>
              <th style={{ width: '120px' }}>原有密碼</th>
              <td>
                <MyPasswordFields state={state} setState={setState} name="OldPassword" />
              </td>
            </tr>
            <tr>
              <th style={{ width: '120px' }}>新修改密碼	</th>
              <td>
                <MyPasswordFields state={state} setState={setState} name="NewPassword" />
              </td>
            </tr>
            <tr>
              <th style={{ width: '120px' }}>重覆新密碼	</th>
              <td>
                <MyPasswordFields state={state} setState={setState} name="ConfirmPassword" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style={{ height: "10px" }}></div>

      <div style={{ textAlign: 'center' }} className="mt-5">
        <Button variant="contained" className="mr-2" size="small" disableElevation onClick={confirmUpdatePassword}>修　　改</Button>
      </div>        </>
  );
}

function MyPasswordFields({ state, setState, name }) {

  const handleClickShowPassword = (_) => {
    setState({ ...state, IsVisible: !state.IsVisible });
  };

  const errorName = `${name}Error`;

  return <FormControl>
    <OutlinedInput size="small"
      type={state.IsVisible ? "text" : "password"}
      value={state[name] ?? ""}
      error={state[errorName] ? true : false}
      onChange={(e) => { setState({ ...state, [name]: e.target.value, [errorName]: undefined }); }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
          >
            {state.IsVisible ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
    />
    {
      state[errorName] &&
      <FormHelperText error>
        {state[errorName].join()}
      </FormHelperText>
    }
  </FormControl>
}
