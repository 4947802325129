import React, { useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import { userLookup, userFetchPage, userPageUpdate } from '../../redux/userReducer';

import UserFilter from './UserFilter';
import Pagination from '../Pagination';
import UserList from './UserList';

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },

  },
});

export default function User() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { hasFilter } = useParams();

  const newUser = () => {
    history.push("/userDetail/0");
  }

  const { filter } = useSelector(state => {
    return state.user;
  });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");

    dispatch(userLookup(hasFilter ? filter : null));
  }, []);

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/setting">
          <Typography variant="h1" className={classes.title}>10. 設定</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>登入用戶</Typography>
      </Breadcrumbs>
      <Grid container justifyContent="space-between" className="mb-2">
        <Grid item>
          <Button variant="contained" size="small" onClick={newUser}>新增登入用戶</Button>
        </Grid>
      </Grid>

      <UserFilter filter={filter} />
      <Pagination filter={filter} fnFetchPage={userFetchPage} fnPageUpdate={userPageUpdate} />

      <UserList />

      <Pagination filter={filter} fnFetchPage={userFetchPage} fnPageUpdate={userPageUpdate} />
    </>
  );
}
