import React, { useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { jobSendInvoiceChangeSort } from '../../redux/jobSendInvoiceReducer';
import ReactMoment from "react-moment";
import NumberFormat from 'react-number-format';

import BeatLoader from "react-spinners/BeatLoader";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import JobSendInvoice from '../../models/jobSendInvoice';

const useStyles = makeStyles({
  tblList: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& thead": {
      backgroundColor: "#c5e2ff",
      color: "#45525c",
    },

    "& tr": {
      cursor: "pointer",
    },

    "& tr.alt": {
      backgroundColor: "#f7fbff",
    },

    "& tr.lastWorkingEntity": {
      border: "2px solid #da59da",
    },

    "& tbody tr:hover, tbody tr.alt:hover": {
      backgroundColor: "#ffffcc !important",
    },

    "& th": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },
  },
});

export default function JobSendInvoiceList() {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading, filter, entities, workingEntityId, errors } = useSelector(state => {
    return state.jobSendInvoice;
  });

  //console.log('JobSendInvoiceList', { entities });

  const sorts = JSON.parse(filter.sort);

  const _getSortDirection = (field) => {
    const i = _.findIndex(sorts, function (o) { return o.property == field; });
    return (i >= 0) ? sorts[i].direction : "";
  };

  const changeSort = (field) => {
    dispatch(jobSendInvoiceChangeSort(field, filter));
  };

  const showCommDetail = (id) => {
    history.push(`/commDetail/${id}`);
  }

  let i = filter.qtyPerPage * (filter.page - 1) + 1;

  const myRef = useRef(null);
  useEffect(() => {
    if (myRef.current) myRef.current.scrollIntoView({ block: "center" });
  }, []);

  return (
    <table className={classes.tblList} >
      <thead>
        <tr>
          <th><span>行</span></th>
          <th onClick={(_) => changeSort('ref_no1')}><span>物業編號</span><MyArrowIcon direction={_getSortDirection("ref_no1")} /></th>
          <th onClick={(_) => changeSort('party')}><span>業主/買家</span><MyArrowIcon direction={_getSortDirection("party")} /></th>
          <th onClick={(_) => changeSort('desc1')}><span>內容</span><MyArrowIcon direction={_getSortDirection("desc1")} /></th>
          <th onClick={(_) => changeSort('date_due')}><span>到期付款日</span><MyArrowIcon direction={_getSortDirection("date_due")} /></th>
          <th onClick={(_) => changeSort('send_to')}><span>電郵</span><MyArrowIcon direction={_getSortDirection("send_to")} /></th>
          <th onClick={(_) => changeSort('cc')}><span>cc</span><MyArrowIcon direction={_getSortDirection("cc")} /></th>
          <th><span>更新日期</span></th>
          <th><span>狀況日期</span></th>
          <th><span>狀況</span></th>
        </tr>
      </thead>

      {isLoading
        ?
        <tbody>
          <tr>
            <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
          </tr>
        </tbody>
        :
        (errors !== "")
          ?
          <tbody>
            <tr>
              <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
            </tr>
          </tbody >
          :
          <tbody>
            {
              entities.length < 1
                ? <tr>
                  <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>沒有符合的記錄</em></td>
                </tr>
                : entities.map((entity, index) => {

                  const jobSendInvoice = new JobSendInvoice(entity);
                  let iconStatus = null;
                  
                  // __INIT__ < AWAITING < ABORTED < SUCCESS < FAILURE
                  switch (jobSendInvoice.getStatus()) {
                    case "FAILURE": iconStatus = "已失敗"; break;
                    case "SUCCESS": iconStatus = "已傳送"; break;
                    case "ABORTED": iconStatus = "已取消"; break;
                    case "AWAITING": iconStatus = "待傳送"; break;
                    default: iconStatus = "--"; break;
                  }

                  return <tr
                    key={entity.Id}
                    ref={workingEntityId == jobSendInvoice.Id ? myRef : null}
                    className={clsx({ "lastWorkingEntity": workingEntityId == jobSendInvoice.Id, "alt": index % 2 == 1, "normal": index % 2 == 0 })}
                    onClick={() => { showCommDetail(jobSendInvoice.IdComm) }}
                  >
                    <td>{i++}</td>
                    <td>{jobSendInvoice.RefNo1}</td>
                    <td>{jobSendInvoice.Party}</td>
                    <td>{jobSendInvoice.Desc1}</td>
                    <td>{jobSendInvoice.DateDue && <ReactMoment format="YYYY-MM-DD" date={jobSendInvoice.DateDue} />}</td>
                    <td>{jobSendInvoice.SendTo}</td>
                    <td>{jobSendInvoice.Cc}</td>
                    <td>{jobSendInvoice.DatetimeCreate && <ReactMoment format="YYYY-MM-DD HH:mm" date={jobSendInvoice.DatetimeCreate} />}</td>
                    <td>{jobSendInvoice.getStatusDatetime() && <ReactMoment format="YYYY-MM-DD HH:mm" date={jobSendInvoice.getStatusDatetime()} />}</td>
                    <td>{iconStatus}</td>
                  </tr>
                })
            }
          </tbody>
      }
    </table>
  );
}

function MyArrowIcon({ direction }) {
  switch (direction) {
    case "ASC": return <ArrowDropUpIcon />;
    case "DESC": return <ArrowDropDownIcon />;
    default: return null;
  }
}
