import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';

import SolicitorsRef from '../models/solicitorsRef';
import { showLoading, showInfo, showErrorResponse, clearMsg } from './msgReducer';


//================== Whole State ==================//
const initialState = {
    isLoading: false,
    filter: { // match EntitiesResult<T>
        allRecords: 0,
        matchedRecords: 0,
        totalRecords: 0,
        qtyPerPage: 100,
        page: 1,
        row: 0,
        filter: "[]",
        sort: JSON.stringify([{ "property": "id", "direction": "DESC" }]),
        success: true,
        message: '',
    },
    entities: [],
    isDetailInited: false,
    workingEntityId: -1,
    workingEntity: null,
    duplicateEntityId: -1, // for duplication in detail
    errors: '',
    lookupInfoesIsOpen: false,
    lookupParty: '',
    lookupInfoes: [],
};


//================== Action Types ==================//
export const SolicitorsRefActions = Object.freeze({
    // filter
    CHANGE_SORT: Symbol("CHANGE_SORT"),
    FILTER_UPDATE: Symbol("FILTER_UPDATE"),
    PAGE_UPDATE: Symbol("PAGE_UPDATE"),
    FETCH_PAGE: Symbol("FETCH_PAGE"),

    // listing
    REQUEST: Symbol("REQUEST"),
    SUCCESS: Symbol("SUCCESS"),
    FAILURE: Symbol("FAILURE"),

    // detail page
    DETAIL_ID: Symbol("DETAIL_ID"),
    DETAIL_SUCCESS: Symbol("DETAIL_SUCCESS"),
    DETAIL_FAILURE: Symbol("DETAIL_FAILURE"),
    DETAIL_UPDATE: Symbol("DETAIL_UPDATE"),
    DETAIL_DELETED: Symbol("DETAIL_DELETED"),

    // duplicate current workingEntity
    DUPLICATE_ID: Symbol("DUPLICATE_ID"),
    DUPLICATE_SUCCESS: Symbol("DUPLICATE_SUCCESS"),

    // lookup info
    LOOKUP_INFO_SHOW: Symbol("LOOKUP_INFO_SHOW"), 
    LOOKUP_INFO_SUCCESS: Symbol("LOOKUP_INFO_SUCCESS"),
    LOOKUP_INFO_FAILURE: Symbol("LOOKUP_INFO_FAILURE"),
    LOOKUP_INFO_HIDE: Symbol("LOOKUP_INFO_HIDE"),
});


//================== Action Creators ==================//
export const solicitorsRefFilterUpdate = (field, value) => {
    return {
        type: SolicitorsRefActions.FILTER_UPDATE,
        payload: {
            field: field,
            value: value
        }
    }
}

const _setWorkingEntityId = (id) => {
    return {
        type: SolicitorsRefActions.DETAIL_ID,
        payload: id,
    }
}

const _setDuplicateEntityId = (id) => {
    return {
        type: SolicitorsRefActions.DUPLICATE_ID,
        payload: id,
    }
}

export const solicitorsRefFilterClear = () => {
    return (dispatch) => {
        dispatch(_setWorkingEntityId(initialState.workingEntityId));
        dispatch(solicitorsRefLookup(initialState.filter));
    }
}

export const solicitorsRefChangeSort = (field) => {
    return (dispatch, getState) => {
        dispatch({
            type: SolicitorsRefActions.CHANGE_SORT,
            payload: field,
        });
        const { solicitorsRef: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(solicitorsRefLookup(filter));
    };
}

export const solicitorsRefPageUpdate = (page) => {
    return (dispatch, getState) => {
        const { solicitorsRef: { filter } } = getState(); // state updated

        let _page = 1;

        if (page == "") {
            _page = "";
        } else {
            _page = parseInt(page);
            if (_.isNaN(_page)) return;
            if (_page < 1) _page = 1;
            if (_page > Math.ceil(filter.matchedRecords / filter.qtyPerPage)) _page = Math.ceil(filter.matchedRecords / filter.qtyPerPage);
        }

        dispatch({
            type: SolicitorsRefActions.FETCH_PAGE,
            payload: _page,
        });
    };
}

export const solicitorsRefFetchPage = (page) => {
    return (dispatch, getState) => {
        dispatch({
            type: SolicitorsRefActions.FETCH_PAGE,
            payload: page,
        });
        const { solicitorsRef: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(solicitorsRefLookup(filter));
    };
}

const _solicitorsRefRequest = () => {
    return {
        type: SolicitorsRefActions.REQUEST
    }
}

const _solicitorsRefSuccess = (data) => {
    return {
        type: SolicitorsRefActions.SUCCESS,
        payload: data,
    }
}

const _solicitorsRefFailure = (response) => {
    return {
        type: SolicitorsRefActions.FAILURE,
        payload: response,
    }
}

export const solicitorsRefLookup = (formData) => {
    const _formData = formData ? formData : initialState.filter;
    //console.log(_formData);
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/SolicitorsRef/GetEntities`;

    return (dispatch, getState) => {
        dispatch(_solicitorsRefRequest());

        const { solicitorsRef } = getState(); // state updated
        if (solicitorsRef.isFilterUpdated) _formData.page = 1; // reset page if filter changed
        if (solicitorsRef.filter.page == "") _formData.page = 1; // reset page if filter changed

        axios.post(apiEndpoint, _formData, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch(_solicitorsRefSuccess(response.data));
                } else {
                    dispatch(_solicitorsRefFailure("未能讀取"));
                }
            })
            .catch(error => {
                dispatch(_solicitorsRefFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    }
}

const _solicitorsRefDetailSuccess = (data) => {
    return {
        type: SolicitorsRefActions.DETAIL_SUCCESS,
        payload: data,
    }
}

export const _solicitorsRefDuplicateSuccess = (data) => {
    return {
        type: SolicitorsRefActions.DUPLICATE_SUCCESS,
        payload: data,
    }
}

const _solicitorsRefDetailFailure = (response) => {
    return {
        type: SolicitorsRefActions.DETAIL_FAILURE,
        payload: response,
    }
}

export const solicitorsRefDetail = (Id, IdDuplicate, isForce) => {
    return (dispatch, getState) => {
        const { solicitorsRef: { workingEntityId: lastWorkingEntityId, duplicateEntityId: lastDuplicateEntityId } } = getState();

        let workingEntityId = -1;
        let fnSuccess = null;

        if (!IdDuplicate) {
            workingEntityId = Id;
            if (!isForce && lastWorkingEntityId == Id) return; // same entity
            dispatch(_setWorkingEntityId(Id)); // 任何 dispatch 之前先記住 current working entity Id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗
            fnSuccess = _solicitorsRefDetailSuccess;
        } else {
            workingEntityId = IdDuplicate;
            if (!isForce && lastDuplicateEntityId == IdDuplicate) return; // same entity
            dispatch(_setDuplicateEntityId(IdDuplicate)); // 任何 dispatch 之前先記住 current working entity Id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗
            fnSuccess = _solicitorsRefDuplicateSuccess;
        }

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/SolicitorsRef/GetEntity`;

        dispatch(_solicitorsRefRequest());

        axios.post(apiEndpoint, { Id: workingEntityId }, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch(fnSuccess(response.data));
                } else {
                    dispatch(_solicitorsRefDetailFailure("未能讀取"));
                }
            })
            .catch(error => {
                dispatch(_solicitorsRefDetailFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

export const solicitorsRefDetailUpdate = (field, value) => {
    //console.log(`${field} = ${value}`);
    return {
        type: SolicitorsRefActions.DETAIL_UPDATE,
        payload: { field, value }
    }
}

export const saveEntity = (history) => {
    return (dispatch, getState) => {
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/solicitorsRef/SaveEntity`;

        const { solicitorsRef: { workingEntity } } = getState();

        dispatch(showLoading());

        axios.post(apiEndpoint, workingEntity, {
            timeout: 30000,
        })
            .then(response => {
                if ((history.location.pathname == "/solicitorsRefDetail/0") || history.location.pathname.startsWith("/solicitorsRefDetail/0/")) {
                    history.replace(`/solicitorsRefDetail/${response.data.Id}`); // reload detail
                } else {
                    dispatch(solicitorsRefDetail(response.data.Id, null, true)); // update detail
                }
                dispatch(showInfo("已保存"));

                const { solicitorsRef: { filter } } = getState();
                dispatch(solicitorsRefLookup(filter)); // update list
            })
            .catch(error => {
                dispatch(_solicitorsRefDetailFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

export const deleteEntity = (history) => {
    return (dispatch, getState) => {
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/SolicitorsRef/DeleteEntity`;

        const { solicitorsRef: { workingEntity } } = getState();

        dispatch(showLoading());
        dispatch(_solicitorsRefRequest());

        axios.post(apiEndpoint, workingEntity, {
            timeout: 30000,
        })
            .then(response => {
                dispatch(clearMsg());
                history.push('/solicitorsRef');

                const { solicitorsRef: { filter } } = getState();
                dispatch(solicitorsRefLookup(filter));  // update list
            })
            .catch(error => {
                dispatch(_solicitorsRefDetailFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

const _solicitorsRefLookupInfoSuccess = (data) => {
    return {
        type: SolicitorsRefActions.LOOKUP_INFO_SUCCESS,
        payload: data,
    }
}

const _solicitorsRefLookupInfoFailure = (response) => {
    return {
        type: SolicitorsRefActions.LOOKUP_INFO_FAILURE,
        payload: response,
    }
}

export const solicitorsRefLookupInfo = (fieldName) => {
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/SolicitorsRef/LookupInfo`;

    return (dispatch, getState) => {
        const { comm: { workingEntity } } = getState(); // 取得 comm 的 workingEntity

        const [str, lookupParty, _fieldName] = fieldName.match(/(Owner|Buyer)(.+)/);
        const data = { property: _fieldName, value: workingEntity[fieldName] };

        dispatch({ type: SolicitorsRefActions.LOOKUP_INFO_SHOW, payload: lookupParty });

        axios.post(apiEndpoint, data, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch(_solicitorsRefLookupInfoSuccess(response.data));
                } else {
                    dispatch(_solicitorsRefLookupInfoFailure("未能讀取"));
                }
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.errors) {
                    dispatch(_solicitorsRefLookupInfoFailure(error.response.data.errors));
                } else if (error.response && error.response.status) {
                    dispatch(_solicitorsRefLookupInfoFailure(`未能讀取 (${error.response.status})`));
                } else {
                    dispatch(_solicitorsRefLookupInfoFailure(`未能聯繫伺服器`));
                }
            });
    }
}

export const solicitorsRefLookupInfoHide = () => {
    return {
        type: SolicitorsRefActions.LOOKUP_INFO_HIDE,
    }
}


//================== Reducer ==================//
const solicitorsRefReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);
    let i = -1;

    switch (action.type) {
        case SolicitorsRefActions.FILTER_UPDATE:
            const { field: filterField, value } = action.payload;
            let filters = JSON.parse(state.filter.filter);
            const iFilter = _.findIndex(filters, function (o) { return o.property == filterField; });
            if (iFilter >= 0) {
                if (value) filters[iFilter].value = value;
                else filters.splice(iFilter, 1);
            } else {
                filters.push({ "property": filterField, "value": value });
            }

            _state.filter.filter = JSON.stringify(filters);
            _state.isFilterUpdated = true;
            //console.log('_state.filter', _state.filter.filter);
            return _state;

        case SolicitorsRefActions.CHANGE_SORT:
            const field = action.payload;
            let sorts = JSON.parse(state.filter.sort);
            i = _.findIndex(sorts, function (o) { return o.property == field; });
            if (i >= 0) {
                sorts[i].direction = sorts[i].direction == "ASC" ? "DESC" : "ASC";
            } else {
                // single sort only
                sorts = [{ "property": field, "direction": "ASC" }];
            }

            _state.filter.sort = JSON.stringify(sorts);
            //console.log('_state.filter.sort', _state.filter.sort);

            solicitorsRefLookup(_state.filter);
            return _state;

        case SolicitorsRefActions.PAGE_UPDATE:
            _state.filter.page = action.payload;
            return _state;

        case SolicitorsRefActions.FETCH_PAGE:
            _state.filter.page = action.payload;
            return _state;

        case SolicitorsRefActions.REQUEST:
            _state.isLoading = true;
            return _state;

        case SolicitorsRefActions.SUCCESS:
            _state.isLoading = false;
            _state.filter = {
                allRecords: action.payload.allRecords,
                matchedRecords: action.payload.matchedRecords,
                totalRecords: action.payload.totalRecords,
                qtyPerPage: action.payload.qtyPerPage,
                page: action.payload.page,
                row: action.payload.row,
                filter: action.payload.filter,
                sort: action.payload.sort,
                success: action.payload.success,
                message: action.payload.message,
            };
            _state.entities = action.payload.entities.map(entity => new SolicitorsRef(entity));
            _state.errors = "";
            _state.isFilterUpdated = false;
            return _state;

        case SolicitorsRefActions.FAILURE:
            _state.isLoading = false;
            _state.errors = action.payload;
            _state.entities = [];
            return _state;

        case SolicitorsRefActions.DETAIL_ID:
            _state.workingEntityId = action.payload;
            _state.duplicateEntityId = null;
            return _state;

        case SolicitorsRefActions.DETAIL_SUCCESS:

            _state.isLoading = false;
            _state.workingEntity = new SolicitorsRef(action.payload);
            _state.workingEntityId = _state.workingEntity.Id;

            return _state;

        case SolicitorsRefActions.DUPLICATE_ID:
            _state.workingEntityId = 0;
            _state.duplicateEntityId = action.payload;
            return _state;

        case SolicitorsRefActions.DUPLICATE_SUCCESS:
            _state.isLoading = false;
            _state.workingEntity = new SolicitorsRef({ ...action.payload, Id: 0 }); // always 0 for duplication
            return _state;

        case SolicitorsRefActions.DETAIL_FAILURE:
            _state.isLoading = false;
            _state.errors = action.payload;
            return _state;

        case SolicitorsRefActions.DETAIL_UPDATE:
            const { field: updateField, value: updateValue } = action.payload;
            _state.workingEntity[updateField] = updateValue;
            return _state;
        case SolicitorsRefActions.LOOKUP_INFO_SHOW:
            _state.lookupParty = action.payload;
            _state.errors = '';
            _state.lookupInfoesIsOpen = true;
            _state.lookupInfoes = [];
            return _state;

        case SolicitorsRefActions.LOOKUP_INFO_SUCCESS:
            _state.lookupInfoes = action.payload.entities;
            return _state;

        case SolicitorsRefActions.LOOKUP_INFO_FAILURE:
            _state.errors = action.payload;
            return _state;

        case SolicitorsRefActions.LOOKUP_INFO_HIDE:
            _state.lookupInfoesIsOpen = false;
            return _state;

        default:
            return state;
    }
}

export default solicitorsRefReducer;

