import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { clearMsg } from '../redux/msgReducer';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import InfoIcon from '@material-ui/icons/Info'; // info, confirm
import WarningIcon from '@material-ui/icons/Warning'; // warning
import CancelIcon from '@material-ui/icons/Cancel'; // error
import BeatLoader from "react-spinners/BeatLoader"; // loading

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    backdrop: {
        backgroundColor: 'rgb(0, 0, 0, 0.2)',
    },
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: "auto",
    }
}));

export default function ModelMsg() {
    const classes = useStyles();

    const dispatch = useDispatch();

    const { isShow, type, msg, fnConfirmed } = useSelector(state => {
        return state.msg;
    });

    const handleClose = () => {
        dispatch(clearMsg());
    };

    let icon = null;
    switch (type) {
        case "INFO":
        case "CONFIRM":
            icon = <InfoIcon style={{ color: '#98cfff' }} />
            break;
        case "WARNING":
            icon = <WarningIcon style={{ color: 'rgb(251 232 5)' }} />
            break;
        case "ERROR":
            icon = <CancelIcon style={{ color: 'rgb(232 85 85)' }} />
            break;
    }

    const hasClose = type !== "LOADING" && fnConfirmed == null;

    return <Modal
        className={classes.root}
        open={isShow}
        closeAfterTransition
        onClose={hasClose ? handleClose : null}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 250, className: classes.backdrop, }}
    >
        <Fade in={isShow}>
            {type == "LOADING"
                ? <div><BeatLoader /></div>
                : <div className={classes.paper}>
                    {icon} {msg}
                    <Grid container justifyContent="center" className="mt-3">
                        {(fnConfirmed) ?
                            <>
                                <Button variant="contained" className="mr-2" size="small" disableElevation onClick={(_) => { fnConfirmed() }}>確　定</Button>
                                <Button variant="contained" className="mr-2" size="small" disableElevation onClick={(_) => { handleClose() }}>取　消</Button>
                            </>
                            :
                            <Button variant="contained" className="mr-2" size="small" disableElevation onClick={(_) => { handleClose() }}>確　定</Button>
                         }
                    </Grid>
                </div>
                }
        </Fade>
    </Modal>;
};

export const LocalModelMsg = (state) => {
    const classes = useStyles();

    const { msgState, msgSetState } = state;
    const { isShow, type, msg, fnConfirmed } = msgState;

    const handleClose = () => {
        msgSetState({ ...msgState, isShow: false });
    };

    let icon = null;
    switch (type) {
        case "INFO":
        case "CONFIRM":
            icon = <InfoIcon style={{ color: '#98cfff' }} />
            break;
        case "WARNING":
            icon = <WarningIcon style={{ color: 'rgb(251 232 5)' }} />
            break;
        case "ERROR":
            icon = <CancelIcon style={{ color: 'rgb(232 85 85)' }} />
            break;
    }

    const hasClose = type !== "LOADING" && fnConfirmed == null;

    return <Modal
        className={classes.root}
        open={isShow}
        closeAfterTransition
        onClose={hasClose ? handleClose : null}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 250, className: classes.backdrop, }}
    >
        <Fade in={isShow}>
            {type == "LOADING"
                ? <div><BeatLoader /></div>
                : <div className={classes.paper}>
                    {icon} {msg}
                    <Grid container justifyContent="center" className="mt-3">
                        {(fnConfirmed) ?
                            <>
                                <Button variant="contained" className="mr-2" size="small" disableElevation onClick={(_) => { fnConfirmed() }}>確　定</Button>
                                <Button variant="contained" className="mr-2" size="small" disableElevation onClick={(_) => { handleClose() }}>取　消</Button>
                            </>
                            :
                            <Button variant="contained" className="mr-2" size="small" disableElevation onClick={(_) => { handleClose() }}>確　定</Button>
                        }
                    </Grid>
                </div>
            }
        </Fade>
    </Modal>;
};
