import Bar from './bar.js';

export default class TableRate {
    constructor({
        Id,
        IdBranch,
        Branch,
        IdTeam,
        Team,
        IdEmployee,
        Name,
        Alias,
        IsSupervisor,
        IdSupervisorGroup,
        SupervisorGroup,
        QtySuperviseTeam,
        Class,
        Fdate,
        Tdate,
        Bars,
    }) {
        this.Id = Id;
        this.IdBranch = IdBranch;
        this.Branch = Branch;
        this.IdTeam = IdTeam;
        this.Team = Team;
        this.IdEmployee = IdEmployee;
        this.Name = Name;
        this.Alias = Alias;
        this.IsSupervisor = IsSupervisor;
        this.IdSupervisorGroup = IdSupervisorGroup;
        this.SupervisorGroup = SupervisorGroup;
        this.QtySuperviseTeam = QtySuperviseTeam;
        this.Class = Class;
        this.Fdate = Fdate;
        this.Tdate = Tdate;

        this.Bars = [];
        if (Bars) {
            this.Bars = Bars.map(b => new Bar(b));
        }
    }

    isNew() {
        return !this.Id || (this.Id < 1);
    }

    // 新 tableRate 有可能需要指定 defaultType
    getType(defaultValue = "__TEAM__") {
        //<TableRateIsSupervisorRadioField selectedValue={typeFilter} fieldName="type" label="組別" value="__TEAM__" />
        //<TableRateIsSupervisorRadioField selectedValue={typeFilter} fieldName="type" label="主管組別" value="__SUPERVISOR_GROUP__" />
        //<TableRateIsSupervisorRadioField selectedValue={typeFilter} fieldName="type" label="" value="__SUPERVISOR__" />
        if (this.IdTeam) return "__TEAM__";
        if (this.IdSupervisorGroup) return "__SUPERVISOR_GROUP__";
        if (this.IdEmployee) return "__SUPERVISOR__";

        return defaultValue;
    }

    getDescType() {
        switch (this.getType()) {
            case "__TEAM__": return "組別";
            case "__SUPERVISOR_GROUP__": return "主管組別";
            case "__SUPERVISOR__": return "主管(個人)";
            default: return "- 無類型 -"
        }
    }

    getDesc() {
        //console.log('getDesc', this);

        if (this.isNew()) return "新比率表";

        switch (this.getType()) {
            case "__TEAM__": return '組別 ' + (this.Team ? this.Team : `#${this.IdTeam}`);
            case "__SUPERVISOR_GROUP__": return "主管組別 " + (this.SupervisorGroup ? this.SupervisorGroup : `#${this.IdSupervisorGroup}`) + (this.QtySuperviseTeam ? ` (${this.QtySuperviseTeam} Teams)` : "");
            case "__SUPERVISOR__": return `主管(個人) ${this.IdEmployee ? this.IdEmployee : "[員工編號]"}. ${this.Name ? this.Name : "[姓名]"} (${this.Alias ? this.Alias : "[別名]"})`;
            default: return `比率表 #${this.Id}`;
        }

        return ;
    }
}
