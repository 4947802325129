import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import BeatLoader from "react-spinners/BeatLoader";

import MemoFrmDebitNote from './MemoFrmDebitNote';
import { reportDebitNoteShowForComms, reportDebitNoteSetEmployee, reportDebitNoteReset } from '../../../redux/reportDebitNoteReducer';
import { reportDebitNoteSetModalEmployee } from '../../../redux/selectionsReducer';

import { openPdf } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";

import { EmployeeSelection } from '../../Selections';

const useStyles = makeStyles((theme) => ({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
  backdrop: {
    backgroundColor: 'rgb(0, 0, 0, 0.2)',
  },
  paper: {
    margin: '40px auto',
    width: 320,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },
}));

export default function ReportDebitNote() {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reportDebitNoteShowForComms());
  }, [])

  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

  const { entities, isLoading } = useSelector(state => {
    return state.reportDebitNote;
  });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  const print = (index) => {
    const _entities = _.isNil(index) ? entities : [entities[index]];
    //console.log("ReportDebitNote", _entities);

    openPdf("/api/ReportDebitNote/GetPDF", _entities, msgState, msgSetState);
  }

  const reset = () => {
    dispatch(reportDebitNoteReset());
  }

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/report">
          <Typography variant="h1" className={classes.title}>7. 報告</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : "7.9.付款通知單"}</Typography>
      </Breadcrumbs>

      <div>
        <Button className="mb-3 mr-2" size="small" disableElevation variant="contained" onClick={(_) => { print(); }}>列印全部</Button>
        <Button className="mb-3" size="small" disableElevation variant="contained" onClick={(_) => { reset(); }}>清除</Button>
      </div>

      {entities.map((entity, index) => <div key={index}>
        <MemoFrmDebitNote entity={entity} index={index} print={print} />
        <Button className="mb-3" size="small" disableElevation variant="contained" onClick={(_) => { print(); }}>列印全部</Button>
      </div>)}

      <ModalSetEmployee />

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
    </>
  );
}

function ModalSetEmployee() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { isShowModalEmployees, nthReportDebitNote, balEmployees: employees } = useSelector(state => {
    return state.selections;
  });

  const { entities } = useSelector(state => {
    return state.reportDebitNote;
  });
  let selectedValue = "";
  if (nthReportDebitNote !== null) {
    console.log('nthReportDebitNote', nthReportDebitNote);

    const employeeName = entities[nthReportDebitNote].EmployeeName; // 對 ReportDebitNote
    if (employeeName) {
      let index = _.findIndex(employees, function (e) {
        return _.startsWith(`${e.Name.toLowerCase()} (${e.Branch}})`, employeeName.toLowerCase());
      });
      console.log('index', index);

      if (index < 0) index = employees.length - 1;

      selectedValue = employees[index] ? employees[index].Id : "";
    }
  }

  const handleClose = () => {
    dispatch(reportDebitNoteSetModalEmployee(false, null));
  };

  const handleSelected = (employee) => {
    dispatch(reportDebitNoteSetModalEmployee(false, null));
    dispatch(reportDebitNoteSetEmployee(nthReportDebitNote, employee));
  };

  return <Modal
    open={isShowModalEmployees}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{ timeout: 250, className: classes.backdrop, }}
  >
    <Fade in={isShowModalEmployees}>
      <div className={classes.paper}>
        <EmployeeSelection showAny={false} value={selectedValue} onPicked={handleSelected} size={10} tabIndex={999} />
        <Grid container justifyContent="center" className="mt-3">
          <Button variant="contained" size="small" disableElevation onClick={(_) => { handleClose() }}>取　消</Button>
        </Grid>
      </div>
    </Fade>
  </Modal>;
}
