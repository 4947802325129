import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Moment from 'moment';

import { openPdf, downloadXls } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";

import Button from '@material-ui/core/Button';

import MyDateField from '../../MyDateField';
import { BranchSelection } from '../../Selections';

import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
  frmFilter: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& th": {
      fontWeight: 'bold',
      border: "1px solid #B0B0B0",
      backgroundColor: "#FAF3DE",
      padding: "2px 4px",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      padding: "2px 4px",
    },

    "& label": {
      marginBottom: "0px",
    }
  },
});

export default function ReportCommDue() {
  const classes = useStyles();

  const history = useHistory();

  const { dfnCommTagReports } = useSelector(state => {
    return state.selections;
  });

  const [state, setState] = useState({
    tags: [],
    optDue: '__ANY__',
    t_date_received: Moment.utc().format("YYYY-MM-DD"),
    idBranch: '',
  });

  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");

    setState({ ...state, tags: ['一般', ...dfnCommTagReports.map(t => t.TagReport)] });
  }, []);

  const print = () => {
    let filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    openPdf("/api/ReportCommDue/GetPDF", {
      filter: JSON.stringify(filter)
    }, msgState, msgSetState);
  }

  const excel = () => {
    const filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    downloadXls("/api/ReportCommDue/GetXlsx", {
      filter: JSON.stringify(filter)
    }, "應收未收佣金報告.xlsx", msgState, msgSetState);
  }

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/report">
          <Typography variant="h1" className={classes.title}>7. 報告</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>7.7. 應收未收佣金報告（已過 / 未過到期日）</Typography>
      </Breadcrumbs>

      <div style={{ width: '650px' }}>
        <table className={classes.frmFilter}>
          <tbody>
            <tr>
              <th>特別類別</th>
              <td>
                <RptConcludeFilterCheckBoxes state={state} setState={setState} fieldName="tags" label="一般" value="一般" />
                {dfnCommTagReports.map(t => <RptConcludeFilterCheckBoxes key={t.Id} state={state} setState={setState} fieldName="tags" label={t.TagReport} value={t.TagReport} />)}
              </td>
            </tr>
            <tr>
              <th>填報日期</th>
              <td>
                <MyDateField dateValue={state.t_date_received} onDateChange={(d) => { setState({ ...state, t_date_received: d.format("YYYY-MM-DD") }); }} tabIndex={4} />
              </td>
            </tr>
            <tr>
              <th>到　　期</th>
              <td>
                <RptConcludeFilterRadioField state={state} setState={setState} fieldName="optDue" label="全部" value="__ANY__" />
                <RptConcludeFilterRadioField state={state} setState={setState} fieldName="optDue" label="已過到期日" value="__REACHED__" />
                <RptConcludeFilterRadioField state={state} setState={setState} fieldName="optDue" label="未過到期日" value="__NOT_REACHED__" />
              </td>
            </tr>
            <tr>
              <th>分　　行</th>
              <td>
                <BranchSelection showAny={true} value={state.idBranch} onChange={(e) => { setState({ ...state, idBranch: e.target.value }) }} tabIndex={1} />
              </td>
            </tr>
          </tbody >
        </table >

        <div className="mt-2 mb-3" style={{ textAlign: "right" }}>
          <Button startIcon={<PrintIcon />} className="mr-3" size="small" variant="contained" disableElevation onClick={(e) => { print(false); }}>列　印</Button>
          <Button startIcon={<DescriptionIcon />} size="small" variant="contained" disableElevation onClick={excel}>Excel</Button>
        </div>
      </div >

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
    </>
  );
}

function RptConcludeFilterCheckBoxes({ state, setState, fieldName, label, value }) {
  const name = `filter_${fieldName}`;
  const id = `${name}_${value}`;

  const values = state[fieldName];
  const checked = values.includes(value);

  const toggle = (e) => {
    if (values.includes(e.target.value)) {
      setState({ ...state, [fieldName]: _.without(values, e.target.value) });
    } else {
      setState({ ...state, [fieldName]: _.concat(values, e.target.value) });
    }
  };

  return <span className="mr-1 ml-1">
    <input className="mr-1" type="checkbox" checked={checked} value={value} id={id} name={name} onChange={toggle} />
    <label htmlFor={id}>{label}</label>
  </span>;
}

function RptConcludeFilterRadioField({ state, setState, fieldName, label, value }) {
  const name = `filter_${fieldName}`;
  const id = `${name}_${value}`;
  return <span className="mr-1 ml-1">
    <input className="mr-1" type="radio" value={value} id={id} name={name} onChange={(e) => { setState({ ...state, [fieldName]: e.target.value, }); }} checked={state[fieldName] == value} />
    <label htmlFor={id}>{label}</label>
  </span>;
}
