import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import selectionsReducer from './selectionsReducer';
import msgReducer from './msgReducer';
import authReducer from './authReducer';
import commReducer from './commReducer';
import commRefReducer from './commRefReducer';
import solicitorsRefReducer from './solicitorsRefReducer';
import commShareAssocReducer from './commShareAssocReducer';
import commShareReducer from './commShareReducer';
import commPrepaidReducer from './commPrepaidReducer';
import commTranReducer from './commTranReducer';
import prepaidReducer from './prepaidReducer';
import commBonusShareReducer from './commBonusShareReducer';
import employeeReducer from './employeeReducer';
import employeeHistoryReducer from './employeeHistoryReducer';
import supervisorReducer from './supervisorReducer';
import vSupervisorReducer from './vSupervisorReducer';
import supervisorTeamReducer from './supervisorTeamReducer';
import vCommTranBonuReducer from './vCommTranBonuReducer';
import branchHeadCountReducer from './branchHeadCountReducer';
import jobSendInvoiceReducer from './jobSendInvoiceReducer';
import tableRateReducer from './tableRateReducer';
import vCommDueReducer from './vCommDueReducer';
import barReducer from './barReducer';
import bonusReducer from './bonusReducer';
import branchReducer from './branchReducer';
import teamReducer from './teamReducer';
import userReducer from './userReducer';
import reportCommReceivedReducer from './reportCommReceivedReducer';
import reportCommReducedReducer from './reportCommReducedReducer';
import reportCommPrepaidReducer from './reportCommPrepaidReducer';
import reportDebitNoteReducer from './reportDebitNoteReducer';
import reportReceiptReducer from './reportReceiptReducer';
import employeeVacationReducer from './employeeVacationReducer';
import bankReferralReducer from './bankReferralReducer';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers(
    {
      selections: selectionsReducer, // 指定 selectionsReducer 放喺 state 嘅名為 selections, 比 <selections> 用
      msg: msgReducer, // 指定 msgReducer 放喺 state 嘅名為 msg
      auth: authReducer, // 指定 authReducer 放喺 state 嘅名為 auth
      comm: commReducer, // 指定 commReducer 放喺 state 嘅名為 comm
      commRef: commRefReducer, // 指定 commRefReducer 放喺 state 嘅名為 commRef
      solicitorsRef: solicitorsRefReducer, // 指定 solicitorsRefReducer 放喺 state 嘅名為 solicitorsRef
      commShareAssoc: commShareAssocReducer, // 指定 commShareAssocReducer 放喺 state 嘅名為 commShareAssoc
      commShare: commShareReducer, // 指定 commShareReducer 放喺 state 嘅名為 commShare
      commPrepaid: commPrepaidReducer, // 指定 commPrepaidReducer 放喺 state 嘅名為 commPrepaid
      commTran: commTranReducer, // 指定 commTranReducer 放喺 state 嘅名為 commTran
      prepaid: prepaidReducer, // 指定 prepaidReducer 放喺 state 嘅名為 prepaid
      commBonusShare: commBonusShareReducer, // 指定 commBonusShareReducer 放喺 state 嘅名為 commBonusShare
      vCommTranBonus: vCommTranBonuReducer,
      employee: employeeReducer,
      supervisor: supervisorReducer,
      vSupervisor: vSupervisorReducer,
      supervisorTeam: supervisorTeamReducer,
      branchHeadCount: branchHeadCountReducer,
      jobSendInvoice: jobSendInvoiceReducer,
      tableRate: tableRateReducer,
      vCommDue: vCommDueReducer,
      employeeHistory: employeeHistoryReducer,
      bar: barReducer,
      bonus: bonusReducer,
      branch: branchReducer,
      team: teamReducer,
      user: userReducer,
      reportCommReceived: reportCommReceivedReducer,
      reportCommReduced: reportCommReducedReducer,
      reportCommPrepaid: reportCommPrepaidReducer,
      reportDebitNote: reportDebitNoteReducer,
      reportReceipt: reportReceiptReducer,
      employeeVacation: employeeVacationReducer,
      bankReferral: bankReferralReducer,
    }
  ),
  composeEnhancer(applyMiddleware(thunkMiddleware)) // 容許 async
);
export default store;