import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Moment from 'moment';

import Employee from '../models/employee';
import VSupervisor from '../models/vSupervisor';
import Branch from '../models/branch';
import DfnDistrictManager from '../models/dfnDistrictManager';
import Team from '../models/team';
import SupervisorGroup from '../models/supervisorGroup';

//================== Whole State ==================//
const initialState = {
  users: [],
  employees: [], // EmployeeSelection (any employee)
  balEmployees: [], // EmployeeSelection (only employee is active or has balance not received)
  branches: [],
  teams: [],
  supervisorGroups: [],
  districts: [],
  dfnCommClasses: [],
  dfnCommTagReports: [],
  dfnEmpRoles: [],
  dfnUserRoles: [],
  vSupervisors: [],
  dfnEmpRanks: [],
  dfnDistrictManagers: [],
  ccEmails: [],

  calMonths: [],
  credCalMonths: [],

  credEmployees: [],
  credSupervisors: [],
  credBranches: [],

  cfgSelection: {
    skips: {
    },
    includes: {
    },
  },

  isShowModalEmployees: false, // for comm detail shows ModalUser
  nthCommShare: null, // for comm detail shows ModalUser
  nthReportDebitNote: null, // for reportDebitNote shows ModalUser
  nthReportReceipt: null, // for reportReceipt shows ModalUser
};


//================== Action Types ==================//
export const SelectionsActions = Object.freeze({
  DETAIL_COMM_SET_MODAL_EMPLOYEE: Symbol("DETAIL_COMM_SET_MODAL_EMPLOYEE"),
  DETAIL_PREPAID_SET_MODAL_EMPLOYEE: Symbol("DETAIL_PREPAID_SET_MODAL_EMPLOYEE"),
  REPORTDEBITNOTE_SET_MODAL_EMPLOYEE: Symbol("REPORTDEBITNOTE_SET_MODAL_EMPLOYEE"),
  REPORTRECEIPT_SET_MODAL_EMPLOYEE: Symbol("REPORTRECEIPT_SET_MODAL_EMPLOYEE"),

  USER_SUCCESS: Symbol("USER_SUCCESS"),
  EMPLOYEE_SUCCESS: Symbol("EMPLOYEE_SUCCESS"),
  BAL_EMPLOYEE_SUCCESS: Symbol("BAL_EMPLOYEE_SUCCESS"),
  BRANCH_SUCCESS: Symbol("BRANCH_SUCCESS"),
  TEAM_SUCCESS: Symbol("TEAM_SUCCESS"),
  SUPERVISOR_GROUP_SUCCESS: Symbol("SUPERVISOR_GROUP_SUCCESS"),
  DISTRICT_SUCCESS: Symbol("DISTRICT_SUCCESS"),
  DFN_COMM_CLASS_SUCCESS: Symbol("DFN_COMM_CLASS_SUCCESS"),
  DFN_COMM_TAG_REPORT_SUCCESS: Symbol("DFN_COMM_TAG_REPORT_SUCCESS"),
  DFN_EMP_ROLE_SUCCESS: Symbol("DFN_EMP_ROLE_SUCCESS"),
  DFN_USER_ROLE_SUCCESS: Symbol("DFN_USER_ROLE_SUCCESS"),
  SUPERVISOR_SUCCESS: Symbol("SUPERVISOR_SUCCESS"),
  DFN_EMP_RANK_SUCCESS: Symbol("DFN_EMP_RANK_SUCCESS"),
  DFN_DISTRICT_MANAGER_SUCCESS: Symbol("DFN_DISTRICT_MANAGER_SUCCESS"),
  CC_EMAIL_SUCCESS: Symbol("CC_EMAIL_SUCCESS"),
  CAL_MONTH_SUCCESS: Symbol("CAL_MONTH_SUCCESS"),

  CRED_CAL_MONTH_SUCCESS: Symbol("CRED_CAL_MONTH_SUCCESS"),
  CRED_EMPLOYEE_SUCCESS: Symbol("CRED_EMPLOYEE_SUCCESS"),
  CRED_SUPERVISOR_SUCCESS: Symbol("CRED_SUPERVISOR_SUCCESS"),
  CRED_BRANCH_SUCCESS: Symbol("CRED_BRANCH_SUCCESS"),

  CFG_SELECTION_SUCCESS: Symbol("CFG_SELECTION_SUCCESS"),
});


//================== Action Creators ==================//
export const detailCommSetModalEmployee = (isShowModalEmployees, nthCommShare) => {
  return {
    type: SelectionsActions.DETAIL_COMM_SET_MODAL_EMPLOYEE,
    payload: { isShowModalEmployees, nthCommShare },
  };
}

export const detailPrepaidSetModalEmployee = (isShowModalEmployees) => {
  return {
    type: SelectionsActions.DETAIL_PREPAID_SET_MODAL_EMPLOYEE,
    payload: isShowModalEmployees,
  };
}

export const reportDebitNoteSetModalEmployee = (isShowModalEmployees, nthReportDebitNote) => {
  return {
    type: SelectionsActions.REPORTDEBITNOTE_SET_MODAL_EMPLOYEE,
    payload: { isShowModalEmployees, nthReportDebitNote },
  };
}

export const reportReceiptSetModalEmployee = (isShowModalEmployees, nthReportReceipt) => {
  return {
    type: SelectionsActions.REPORTRECEIPT_SET_MODAL_EMPLOYEE,
    payload: { isShowModalEmployees, nthReportReceipt },
  };
}

const _userSuccess = (data) => {
  return {
    type: SelectionsActions.USER_SUCCESS,
    payload: data,
  }
}

export const userLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetUsers`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_userSuccess(response.data));
        }
      })
      .catch();
  }
}

const _branchSuccess = (data) => {
  return {
    type: SelectionsActions.BRANCH_SUCCESS,
    payload: data,
  }
}

export const branchLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetBranches`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_branchSuccess(response.data));
        }
      })
      .catch();
  }
}

const _teamSuccess = (data) => {
  return {
    type: SelectionsActions.TEAM_SUCCESS,
    payload: data,
  }
}

export const teamLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetTeams`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_teamSuccess(response.data));
        }
      })
      .catch();
  }
}

export const supervisorGroupLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetSupervisorGroups`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch({
            type: SelectionsActions.SUPERVISOR_GROUP_SUCCESS,
            payload: response.data,
          });
        }
      })
      .catch();
  }
}

const _districtSuccess = (data) => {
  return {
    type: SelectionsActions.DISTRICT_SUCCESS,
    payload: data,
  }
}

export const districtLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetDistricts`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_districtSuccess(response.data));
        }
      })
      .catch();
  }
}

const _dfnCommClassSuccess = (data) => {
  return {
    type: SelectionsActions.DFN_COMM_CLASS_SUCCESS,
    payload: data,
  }
}

export const dfnCommClassLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetDfnCommClass`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_dfnCommClassSuccess(response.data));
        }
      })
      .catch();
  }
}

const _dfnCommTagReportSuccess = (data) => {
  return {
    type: SelectionsActions.DFN_COMM_TAG_REPORT_SUCCESS,
    payload: data,
  }
}

export const dfnCommTagReportLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetDfnCommTagReport`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_dfnCommTagReportSuccess(response.data));
        }
      })
      .catch();
  }
}

const _dfnEmpRoleSuccess = (data) => {
  return {
    type: SelectionsActions.DFN_EMP_ROLE_SUCCESS,
    payload: data,
  }
}

export const dfnEmpRoleLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetDfnEmpRole`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_dfnEmpRoleSuccess(response.data));
        }
      })
      .catch();
  }
}

const _dfnUserRoleSuccess = (data) => {
  return {
    type: SelectionsActions.DFN_USER_ROLE_SUCCESS,
    payload: data,
  }
}

export const dfnUserRoleLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetDfnUserRole`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_dfnUserRoleSuccess(response.data));
        }
      })
      .catch();
  }
}

const _supervisorSuccess = (data) => {
  return {
    type: SelectionsActions.SUPERVISOR_SUCCESS,
    payload: data,
  }
}

export const supervisorLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetSupervisors`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_supervisorSuccess(response.data));
        }
      })
      .catch();
  }
}

const _dfnEmpRankSuccess = (data) => {
  return {
    type: SelectionsActions.DFN_EMP_RANK_SUCCESS,
    payload: data,
  }
}

export const dfnEmpRankLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetDfnEmpRank`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_dfnEmpRankSuccess(response.data));
        }
      })
      .catch();
  }
}

const _dfnDistrictManagerSuccess = (data) => {
  return {
    type: SelectionsActions.DFN_DISTRICT_MANAGER_SUCCESS,
    payload: data,
  }
}

export const dfnDistrictManagerLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetDfnDistrictManager`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_dfnDistrictManagerSuccess(response.data));
        }
      })
      .catch();
  }
}

export const ccEmailLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetCcEmails`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch({
            type: SelectionsActions.CC_EMAIL_SUCCESS,
            payload: response.data,
          });
        }
      })
      .catch();
  }
}

const _calMonthSuccess = (data) => {
  return {
    type: SelectionsActions.CAL_MONTH_SUCCESS,
    payload: data,
  }
}

export const calMonthLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetCalMonth`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_calMonthSuccess(response.data));
        }
      })
      .catch();
  }
}

const _credCalMonthSuccess = (data) => {
  return {
    type: SelectionsActions.CRED_CAL_MONTH_SUCCESS,
    payload: data,
  }
}

const _employeeSuccess = (data) => {
  return {
    type: SelectionsActions.EMPLOYEE_SUCCESS,
    payload: data,
  }
}

const _balEmployeeSuccess = (data) => {
  return {
    type: SelectionsActions.BAL_EMPLOYEE_SUCCESS,
    payload: data,
  }
}

const _credEmployeeSuccess = (data) => {
  return {
    type: SelectionsActions.CRED_EMPLOYEE_SUCCESS,
    payload: data,
  }
}

const _credSupervisorSuccess = (data) => {
  return {
    type: SelectionsActions.CRED_SUPERVISOR_SUCCESS,
    payload: data,
  }
}

const _credBranchSuccess = (data) => {
  return {
    type: SelectionsActions.CRED_BRANCH_SUCCESS,
    payload: data,
  };
}

export const credCalMonthLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetCredCalMonth`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_credCalMonthSuccess(response.data));
        }
      })
      .catch();
  }
}

export const employeeLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetEmployees`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_employeeSuccess(response.data));
        }
      })
      .catch();
  }
}

export const balEmployeeLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetBalEmployees`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_balEmployeeSuccess(response.data));
        }
      })
      .catch();
  }
}

export const credEmployeeLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetCredEmployees`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_credEmployeeSuccess(response.data));
        }
      })
      .catch();
  }
}

export const credSupervisorLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetCredSupervisors`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_credSupervisorSuccess(response.data));
        }
      })
      .catch();
  }
}

export const credBranchLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetCredBranches`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_credBranchSuccess(response.data));
        }
      })
      .catch();
  }
}

export const cfgSelectionLookup = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Selections/GetCfgSelection`;

  return (dispatch) => {
    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch({
            type: SelectionsActions.CFG_SELECTION_SUCCESS,
            payload: response.data,
          });
        }
      })
      .catch();
  }
}



//================== Reducer ==================//
const selectionsReducer = (state = initialState, action) => {
  let _state = cloneDeep(state);

  switch (action.type) {
    case SelectionsActions.DETAIL_COMM_SET_MODAL_EMPLOYEE:
      const { isShowModalEmployees, nthCommShare } = action.payload;
      _state.isShowModalEmployees = isShowModalEmployees;
      _state.nthCommShare = nthCommShare;
      return _state;

    case SelectionsActions.DETAIL_PREPAID_SET_MODAL_EMPLOYEE:
      _state.isShowModalEmployees = action.payload;
      return _state;

    case SelectionsActions.REPORTDEBITNOTE_SET_MODAL_EMPLOYEE:
      const { isShowModalEmployees: reportDebitNoteIsShowModalEmployees, nthReportDebitNote } = action.payload;
      _state.isShowModalEmployees = reportDebitNoteIsShowModalEmployees;
      _state.nthReportDebitNote = nthReportDebitNote;
      return _state;

    case SelectionsActions.REPORTRECEIPT_SET_MODAL_EMPLOYEE:
      const { isShowModalEmployees: reportReceiptIsShowModalEmployees, nthReportReceipt } = action.payload;
      _state.isShowModalEmployees = reportReceiptIsShowModalEmployees;
      _state.nthReportReceipt = nthReportReceipt;
      return _state;

    case SelectionsActions.USER_SUCCESS:
      _state.users = _.sortBy(action.payload.entities, [
        function (u) {
          return u.IsActive ? 1 : 2;
        },
        function (u) {
          return u.Id;
        },
      ]);
      return _state;

    case SelectionsActions.EMPLOYEE_SUCCESS:
      _state.employees = action.payload.entities.map(e => new Employee(e));
      //console.log(_state.employees.map((e) => e.getSelectionName()));
      return _state;

    case SelectionsActions.BAL_EMPLOYEE_SUCCESS:
      //console.log(action.payload.entities);
      _state.balEmployees = action.payload.entities.map(e => new Employee(e));
      return _state;

    case SelectionsActions.BRANCH_SUCCESS:
      //console.log(action.payload.entities);
      _state.branches = _.sortBy(action.payload.entities, [
        function (b) {
          return b.Tdate == null ? 1 : 2;
        },
        function (b) {
          return b.Seq;
        },
      ]);
      return _state;

    case SelectionsActions.TEAM_SUCCESS:
      _state.teams = _.sortBy(action.payload.entities.map(t => new Team(t)), [
        function (t) {
          return t.IsActive ? 1 : 2;
        },
        function (t) {
          return t.Seq;
        },
      ]);
      return _state;

    case SelectionsActions.SUPERVISOR_GROUP_SUCCESS:
      _state.supervisorGroups = action.payload.entities.map(e => new SupervisorGroup(e));
      return _state;

    case SelectionsActions.DISTRICT_SUCCESS:
      _state.districts = _.sortBy(action.payload.entities, [
        function (d) {
          return d.Code;
        },
      ]);
      return _state;

    case SelectionsActions.DFN_COMM_CLASS_SUCCESS:
      _state.dfnCommClasses = _.sortBy(action.payload.entities, [
        function (d) {
          return d.Code;
        },
      ]);
      return _state;

    case SelectionsActions.DFN_COMM_TAG_REPORT_SUCCESS:
      _state.dfnCommTagReports = _.sortBy(action.payload.entities, [
        function (d) {
          return d.Code;
        },
      ]);
      return _state;

    case SelectionsActions.DFN_EMP_ROLE_SUCCESS:
      _state.dfnEmpRoles = _.sortBy(action.payload.entities, [
        function (d) {
          return d.Seq;
        },
      ]);
      return _state;

    case SelectionsActions.DFN_USER_ROLE_SUCCESS:
      _state.dfnUserRoles = _.sortBy(action.payload.entities, [
        function (d) {
          return d.Seq;
        },
      ]);
      return _state;

    case SelectionsActions.SUPERVISOR_SUCCESS:
      _state.vSupervisors = action.payload.entities.map(s => new VSupervisor(s));
      return _state;

    case SelectionsActions.DFN_EMP_RANK_SUCCESS:
      _state.dfnEmpRanks = _.sortBy(action.payload.entities, [
        function (d) {
          return d.Seq;
        },
      ]);
      return _state;

    case SelectionsActions.DFN_DISTRICT_MANAGER_SUCCESS:
      _state.dfnDistrictManagers = action.payload.entities;
      return _state;

    case SelectionsActions.CC_EMAIL_SUCCESS:
      _state.ccEmails = action.payload.entities;
      return _state;

    case SelectionsActions.CAL_MONTH_SUCCESS:
      _state.calMonths = action.payload.entities.map(e => new Moment.utc(e));
      return _state;

    case SelectionsActions.CRED_CAL_MONTH_SUCCESS:
      _state.credCalMonths = action.payload.entities.map(e => new Moment.utc(e));
      return _state;

    case SelectionsActions.CRED_EMPLOYEE_SUCCESS:
      _state.credEmployees = action.payload.entities.map(e => new Employee(e));
      //console.log('_state.credEmployees', _state.credEmployees);
      return _state;

    case SelectionsActions.CRED_SUPERVISOR_SUCCESS:
      _state.credSupervisors = action.payload.entities.map(e => new Employee(e));
      return _state;

    case SelectionsActions.CRED_BRANCH_SUCCESS:
      _state.credBranches = action.payload.entities.map(e => new Branch(e));
      return _state;

    case SelectionsActions.CFG_SELECTION_SUCCESS:
      const { cfgSelection: { skips: { dfnDistrictManagerSkips }, includes: { supervisorEmployeeIncludes } } } = action.payload;
      _state.cfgSelection.skips.dfnDistrictManagerSkips = dfnDistrictManagerSkips.map(e => new DfnDistrictManager(e));
      _state.cfgSelection.includes.supervisorEmployeeIncludes = supervisorEmployeeIncludes.map(e => new Employee(e));
      return _state;

    default:
      return _state;
  }
}

export default selectionsReducer;
