import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { saveEntities, deleteEntities } from '../../redux/employeeVacationReducer';
import { showConfirm, showWarning } from '../../redux/msgReducer';

import Button from '@material-ui/core/Button';

export default function EmployeeVacationDetailBottomButtons() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { workingEntities } = useSelector(state => {
        return state.employeeVacation;
    });

    const navClicked = (path) => {
        history.push(path);
    };

    const confirmDelete = () => {
        const fnConfirm = (_) => { dispatch(deleteEntities(history)); };
        dispatch(showConfirm("確定刪除？", fnConfirm));
    };

    return <div style={{ textAlign: 'center' }} className="mt-5">
        <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { dispatch(saveEntities(history)); }}>儲存放假日數</Button>
        {workingEntities && workingEntities.length > 0 && !workingEntities[0].isNew() && <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { confirmDelete(); }}>刪　除</Button>}
        <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { navClicked("/employeeVacation"); }}>返回列表</Button>
    </div>
}
