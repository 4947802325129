import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { bankReferralFilterUpdate, bankReferralFilterClear, bankReferralLookup, uploadXlsx, isConfirmedLookup, toggleIsConfirmed } from '../../redux/bankReferralReducer';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { CalMonthSelection } from "../Selections";
import { BtnIsConfirmed } from "./BtnIsConfirmed";

const useStyles = makeStyles({
    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& input, & select": {
            padding: "0px 1px",
            fontSize: "13px",
        },

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },

        "& label": {
            margin: 0,
        }
    },
});

export default function BankReferralFilter({ filter }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const filters = JSON.parse(filter.filter);

    const _getFilterValue = (field) => {
        const i = _.findIndex(filters, function (o) { return o.property == field; });
        return (i >= 0) ? filters[i].value : "";
    };

    const { isConfirmed } = useSelector(state => {
        return state.bankReferral;
    });

    const [ optExists, setOptExists ] = useState("OVERRIDE"); // [OVERRIDE | SKIP]

    const filterChange = (field, value) => {
        dispatch(bankReferralFilterUpdate(field, value));
    };

    const clearFilter = () => {
        dispatch(bankReferralFilterClear());
    };

    const lookup = () => {
        dispatch(bankReferralLookup(filter));
        dispatch(isConfirmedLookup(filter));
    }

    const onFileClick = (event) => {
        event.target.value = '';
    }

    const onFileChange = (event) => {
        //dispatch(uploadXlsxShow());
        dispatch(uploadXlsx(event.target.files[0], optExists))
    };

    const _toggleIsConfirmed = () => {
        dispatch(toggleIsConfirmed());
    }

    return (
        <div style={{ width: "600px" }}>
            <table className={clsx(classes.frmFilter, { "mb-3": true })}>
                <tbody>
                    <tr>
                        <th style={{ textAlign: 'right', width: '150px' }}>計算年度及月份：</th>
                        <td>
                            <CalMonthSelection value={_getFilterValue('date_report')} onChange={(e) => { filterChange('date_report', e.target.value); }} tabIndex="1" />
                        </td>
                    </tr>
                    <tr>
                        <th style={{ textAlign: 'right', width: '150px' }}>導入 Excel 檔選項：</th>
                        <td>
                            <span className="mr-2" >
                                <input type="radio" id="OVERRIDE" value="OVERRIDE" onChange={e => { setOptExists("OVERRIDE"); }} checked={optExists == "OVERRIDE"} />
                                <label htmlFor="OVERRIDE">取代</label>
                            </span>
                            <span className="mr-2" >
                                <input type="radio" id="SKIP" value="SKIP" onChange={e => { setOptExists("SKIP"); }} checked={optExists == "SKIP"} />
                                <label htmlFor="SKIP">略過</label>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th style={{ textAlign: 'right', width: '150px' }}>導入 Excel 檔：</th>
                        <td>
                            <input type="file" onChange={onFileChange} onClick={onFileClick} />
                        </td>
                    </tr>
                </tbody>
            </table>

            <Grid container justifyContent="space-between" spacing={2} className="mb-2">
                <Grid item >
                    <BtnIsConfirmed isConfirmed={isConfirmed} handler={_toggleIsConfirmed} />
                </Grid>
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { lookup(); }}>搜　尋</Button>
                </Grid>
            </Grid>
        </div>
    );
}
