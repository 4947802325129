export default class Team {
    constructor({
        Id,
        Team1,
        IdRole,
        Role,
        Seq,
        Fdate,
        Tdate,
        Lv,
        IsActive,
    }) {
        this.Id = Id;
        this.Team1 = Team1;
        this.IdRole = IdRole;
        this.Role = Role;
        this.Seq = Seq;
        this.Fdate = Fdate;
        this.Tdate = Tdate;
        this.Lv = Lv;
        this.IsActive = IsActive;
    }

    isNew() {
        return !this.Id || (this.Id < 1);
    }

    getDesc() {
        return `${this.isNew() ? "新組別" : `${this.Id}. ${this.Team1 ? this.Team1 : "[無組別]"}`} (身份:${this.Role ? this.Role : "無"})`;
    }

    getSelectionDesc() {
        return `${this.Team1 ? this.Team1 : "[無組別]"}${this.Role == "主管" ? ` (主管 Lv${this.Lv})`: ""}`;
    }
}
