import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { supervisorDetail, supervisorDetailUpdate } from '../../redux/supervisorReducer';

import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import BeatLoader from "react-spinners/BeatLoader";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';

import { SupervisorSelection } from '../Selections';
import MyDateField from '../MyDateField';
import SupervisorTeams from '../supervisorTeam/SupervisorTeams';
import SupervisorDetailTableRates from '../tableRate/SupervisorDetailTableRates';
import SupervisorDetailBottomButtons from './SupervisorDetailBottomButtons';

const useStyles = makeStyles((theme) => ({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& a.nav-link:hover h1": {
            color: "#006ed0",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },
    },
    detail: {
        "& table": {
            width: "100%",
            backgroundColor: "white",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
            },

            "& th": {
                backgroundColor: "#FAF3DE",
                textAlign: 'right',
            },
        },
    },
    backdrop: {
        backgroundColor: 'rgb(0, 0, 0, 0.2)',
    },
    paper: {
        margin: '40px auto',
        width: 320,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: "auto",
    },
}));

export default function SupervisorDetail() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { isLoading, workingEntity } = useSelector(state => {
        return state.supervisor;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    const { id } = useParams();
    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");

        dispatch(supervisorDetail(id));
    }, [id]);

    const navClicked = (path) => {
        history.push(path);
    };

    return (
        <>
            <Breadcrumbs className={classes.title}>
                <NavLink className="nav-link" tag={Link} to="/supervisor/list">
                    <Typography variant="h1" className={classes.title}>3. 主管資料</Typography>
                </NavLink>
                <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : workingEntity !== null ? workingEntity.getDesc() : ""}</Typography>
            </Breadcrumbs>
            { workingEntity &&
                <div className={classes.detail}>
                    <Grid container>
                        <Button variant="contained" className="mr-4" size="small" disableElevation onClick={() => { navClicked("/supervisor"); }}>返回列表</Button>
                        <div style={{ flex: 1 }} />
                        <Button variant="contained" size="small" disableElevation onClick={() => { dispatch(supervisorDetail(id, true)); }}><RefreshIcon /></Button>
                    </Grid>

                    <table className="mt-2 mb-3" style={{ width: '540px' }}>
                        <tbody>
                            <tr><th style={{ width: '160px' }}>主管員工</th>
                            <td>
                                {workingEntity.isNew() ?
                                    <SupervisorSelection showAny={false} value={workingEntity.IdEmployee} onChange={(e) => { dispatch(supervisorDetailUpdate('IdEmployee', e.target.value)); }} tabIndex={1} />
                                    :
                                    <span>{workingEntity.getDesc()}</span>
                                }
                            </td>
                            </tr>
                        <tr>
                            <th>開始日期</th>
                            <td><MyDateField dateValue={workingEntity.Fdate} onDateChange={(d) => { dispatch(supervisorDetailUpdate('Fdate', d)); }} tabIndex={1} /></td>
                        </tr>
                        <tr>
                            <th>完結日期</th>
                            <td><MyDateField dateValue={workingEntity.Tdate} onDateChange={(d) => { dispatch(supervisorDetailUpdate('Tdate', d)); }} tabIndex={2} />（未完結可漏空這格）</td>
                        </tr>
                        <tr>
                            <th>佣金報告開始日期</th>
                            <td><MyDateField dateValue={workingEntity.RptCommFdate} onDateChange={(d) => { dispatch(supervisorDetailUpdate('RptCommFdate', d)); }} tabIndex={2} /><span style={{ color: '#606060', padding: '5px 0px' }}>（在 7.2 主管佣金報告列印後會被更新）</span></td>
                        </tr>
                        <tr>
                            <th>佣金計算方法</th>
                            <td>
                                <SupervisorPlanRadioField fieldName="CommPlan" label="Plan E: 以收到佣金總數除分行人數 (設定) 跳 BAR 計算" value={5} selectedValue={workingEntity.CommPlan} />
                                <br/>
                                <SupervisorPlanRadioField fieldName="CommPlan" label="Plan F: 以收到佣金跳 BAR 計算 (不計算分行人數)" value={6} selectedValue={workingEntity.CommPlan} />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            }

            <SupervisorTeams />

            <div style={{height:"10px"}}></div>

            <SupervisorDetailTableRates />

            <SupervisorDetailBottomButtons />
        </>
    );
}

function SupervisorPlanRadioField({ fieldName, label, value, selectedValue }) {
    const dispatch = useDispatch();
    const name = `detail_${fieldName}`;
    const id = `${name}_${value}`;
    return <span className="mr-2" >
        <input type="radio" value={value} id={id} name={name} onChange={e => { dispatch(supervisorDetailUpdate(fieldName, e.target.value)); }} checked={selectedValue == value} />
        <label htmlFor={id}>{label}</label>
    </span>;
}

