import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles = makeStyles({
    root: {
        '& div.pageNumber': {
            display: "inline-block",
            position: "relative",
        },

        '& input.page': {
            textAlign: 'center',
        },

        '& dl': {
            position: "absolute",
            top: "25px",
            width: "60px",
            zIndex: "99",
            height: "100px",
            backgroundColor: "white",
            border: "1px solid #888888",
            overflow: "auto",
        },

        '& dd': {
            padding: '2px 8px',
            fontSize: '13px',
            cursor: 'pointer',
            margin: '0px',
            textAlign: 'right',
        },

        '& dd:hover': {
            backgroundColor: '#c5e2ff',
        },
    },
});

export default function Pagination({ filter, fnFetchPage, fnPageUpdate }) {
    const classes = useStyles();

    const { page, allRecords, matchedRecords, qtyPerPage } = filter;
    const pages = _.range(1, Math.ceil(matchedRecords / qtyPerPage) + 1);

    const [isShowDDPages, setIsShowDDPages] = useState(false);

    const dispatch = useDispatch();

    const fetchPage = (page) => {
        dispatch(fnFetchPage(page));
        setIsShowDDPages(false);
    };

    const pageUpdate = (page) => {
        dispatch(fnPageUpdate(page));
    };

    const pageInputKeyPressed = (e) => {
        if (e.which !== 13 && e.keyCode !== 13) return;

        dispatch(fnFetchPage(page));
    };

    return (
        <>
        {
            pages.length <= 1 &&
            <div>
                <span> （符合條件 {matchedRecords} 筆，總 {allRecords} 筆）</span>
            </div>
        }
        {
            pages.length > 1 &&
                 <div className={classes.root}>
                    <IconButton size="small" onClick={(_) => { fetchPage(1); }} disabled={page <= 1}>
                        <FirstPageIcon />
                    </IconButton>
                    <IconButton size="small" onClick={(_) => { fetchPage(page - 1); }} disabled={page <= 1}>
                        <NavigateBeforeIcon />
                    </IconButton>
                    <span>頁</span>
                    <div className="pageNumber ml-2">
                        <dl className="optPages" style={{ display: isShowDDPages ? 'block' : 'none' }}>
                            {pages.map((p) => {
                                return <dd key={`page_${p}`} onClick={() => { fetchPage(p); }}>{p}</dd>
                            })}
                        </dl>
                        <input className="page" onKeyPress={pageInputKeyPressed} onClick={(e) => { e.target.select(); }} onChange={(e) => { pageUpdate(e.target.value); }} value={page} style={{ width: '40px' }} />
                        <IconButton size="small" onClick={(_) => { setIsShowDDPages(!isShowDDPages); }}>
                            <ArrowDropDownIcon />
                        </IconButton>
                    </div>
                    <IconButton size="small" onClick={(_) => { fetchPage(page + 1); }} disabled={page >= pages.length}>
                        <NavigateNextIcon />
                    </IconButton>
                    <IconButton size="small" onClick={(_) => { fetchPage(pages.length); }} disabled={page >= pages.length}>
                        <LastPageIcon />
                    </IconButton>
                    <span> （符合條件 {matchedRecords} 筆，總 {allRecords} 筆）</span>
                </div>
            }
        </>
    );
}
