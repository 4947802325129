export default class DfnDistrictManager {
    constructor({
        Id,
        District,
        IdEmployee,
        Name,
        Alias,
        Priority,
    }) {
        this.Id = Id;
        this.District = District;
        this.IdEmployee = IdEmployee;
        this.Name = Name;
        this.Alias = Alias;
        this.Priority = Priority;
    }
}
