import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Moment from 'moment';

import { openPdf, downloadXls, actionPdf, PreviewPdf } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import MyDateField from '../../MyDateField';
import { EmployeeSelection, SupervisorMultipleCheckbox, BranchMultipleCheckbox, CcEmailMultipleCheckbox } from '../../Selections';

import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EmailIcon from '@material-ui/icons/Email';
import ClearIcon from '@material-ui/icons/Clear';

const StyledTabs = withStyles({
  root: {
    padding: '0px',
    minHeight: 'auto',
  },
  indicator: {
    backgroundColor: '#f7de8c'
  }
})(Tabs);

const StyledTab = withStyles({
  root: {
    padding: '0px',
    minHeight: 'auto',
    lineHeight: '2rem',
    borderRadius: '8px 8px 0px 0px',
    border: '1px solid silver',
    backgroundColor: '#ebebeb',
  },
  selected: {
    backgroundColor: '#FAF3DE',
  },
})(Tab);

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
  frmFilter: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& th": {
      fontWeight: 'bold',
      border: "1px solid #B0B0B0",
      backgroundColor: "#FAF3DE",
      padding: "2px 4px",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      padding: "2px 4px",
    },

    "& label": {
      marginBottom: "0px",
    }
  },
});

export default function ReportBusiness() {
  const classes = useStyles();
  const history = useHistory();

  // security
  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });
  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  const [state, setState] = useState({
    optType: '__DEFAULT__',
    fDate: Moment.utc().format("YYYY-MM-01"),
    tDate: Moment.utc().endOf('month').format("YYYY-MM-DD"),
    isShowEmptyBranch: "__NO__",
    idBranches: "[]",
    idEmployee: "",
    propertyType: "__ANY__",
    supervisorAdLogins: "[]",
    otherReceivers: "",
    ccEmails: "[]",
  });

  const emailState = { // override for emailing
    ...state,
    optType: '__EMPLOYEE_GROUPED__',
    isShowEmptyBranch: "__NO__",
    propertyType: "__ANY__",
  };
  const emailFilter = _.keys(emailState).map(key => ({
    property: key,
    value: !_.isArray(emailState[key]) ? emailState[key] : JSON.stringify(emailState[key])
  }));

  const [tabValue, setTabValue] = React.useState(0); // tab
  const [preview, setPreview] = useState(false); // preview
  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null }); // dialog

  const setIdBranches = (e) => {
    setState({
      ...state,
      idBranches: Array.from(e.target.selectedOptions, option => option.value)
    });
  }

  // PreviewPdf action
  const email = () => {
    //console.log('emailFilter', emailFilter);

    actionPdf("/api/ReportBusiness/EmailPDF", {
      filter: JSON.stringify(emailFilter)
    }, msgState, msgSetState);
  };

  const { supervisorAdLogins, otherReceivers, ccEmails } = state;

  const qtyReceivers = JSON.parse(supervisorAdLogins).length + JSON.parse(ccEmails).length + _.filter(otherReceivers.split("\n"), (s) => s).length;

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/report">
          <Typography variant="h1" className={classes.title}>7. 報告</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>7.4 營業報告</Typography>
      </Breadcrumbs>

      <div style={{ width: '1000px' }}>
        <StyledTabs
          value={tabValue}
          onChange={(_e, value) => { setTabValue(value) }}
          indicatorColor="primary"
        >
          <StyledTab disableRipple label="列 印" />
          <StyledTab disableRipple label="電郵 (詳細營業員報告)" />
        </StyledTabs>
        <SwipeableViews index={tabValue} >
          <TabPrint index={0} state={state} setState={setState} setIdBranches={setIdBranches} msgState={msgState} msgSetState={msgSetState} />
          <TabPreviewEmail index={1} state={state} setState={setState} setIdBranches={setIdBranches} setPreview={setPreview} />
        </SwipeableViews>
      </div>

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
      <PreviewPdf open={preview} setOpen={setPreview} url={"/api/ReportBusiness/GetPDF"} params={{ filter: JSON.stringify(emailFilter) }}>
        <Button startIcon={<EmailIcon />} className="mr-2" size="small" variant="contained" disabled={qtyReceivers <= 0} disableElevation onClick={email}>{qtyReceivers ? `電郵給 (${qtyReceivers} 位同事)` : `電郵`}</Button>
      </PreviewPdf>
    </>
  );
}

function TabPrint({ index, state, setState, msgState, msgSetState, setIdBranches }) {
  const classes = useStyles();

  const print = () => {
    const filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    openPdf("/api/ReportBusiness/GetPDF", {
      filter: JSON.stringify(filter)
    }, msgState, msgSetState);
  }

  const handleIdBranchChange = (strIdBranch) => {
    setState({
      ...state,
      idBranches: strIdBranch
    });
  };

  const excel = () => {
    const filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    downloadXls("/api/ReportBusiness/GetXlsx", {
      filter: JSON.stringify(filter)
    }, "營業報告.xlsx", msgState, msgSetState);
  }

  return <div index={index}>
    <table className={classes.frmFilter}>
      <tbody>
        <tr>
          <th style={{ width: '80px' }}>選　　項</th>
          <td>
            <RptBusinessFilterRadioField state={state} setState={setState} fieldName="optType" label="營業報告" value="__DEFAULT__" set={index} />
            <RptBusinessFilterRadioField state={state} setState={setState} fieldName="optType" label="分行總結報告" value="__BRANCH__" set={index} />
            <RptBusinessFilterRadioField state={state} setState={setState} fieldName="optType" label="詳細營業員報告" value="__EMPLOYEE__" set={index} />
          </td>
        </tr>
        <tr>
          <th>落訂日期</th>
          <td>
            <MyDateField name="fDate" dateValue={state.fDate} onDateChange={(d) => { setState({ ...state, fDate: d?.format("YYYY-MM-DD") }); }} tabIndex={1} />
            &nbsp;至&nbsp;
            <MyDateField name="tDate" dateValue={state.tDate} onDateChange={(d) => { setState({ ...state, tDate: d?.format("YYYY-MM-DD") }); }} tabIndex={2} />
          </td>
        </tr>
        {(state.optType !== "__BRANCH__") && <tr>
          <th>分　　行</th>
          <td>
            <BranchMultipleCheckbox value={state.idBranches} onChange={handleIdBranchChange} tabIndex={6} />
            {state.idBranches !== "[]" && <IconButton size="small" onClick={e => { setState({ ...state, idBranches: "[]" }); }}>
              <ClearIcon />
            </IconButton>}
          </td>
        </tr>
        }
        {(state.optType !== "__BRANCH__") && <tr>
          <th>員　　工</th>
          <td><EmployeeSelection name="idEmployee" showAny={true} value={state.idEmployee} onPicked={(employee) => setState({ ...state, idEmployee: employee ? employee.Id : "" })} width="auto" size={1} tabIndex={7} /></td>
        </tr >
        }
        {(state.optType == "__BRANCH__") && <tr>
          <th style={{ width: '80px' }}>隱藏分行</th>
          <td>
            <RptBusinessFilterRadioField state={state} setState={setState} fieldName="isShowEmptyBranch" label="隱藏沒有金額的分行" value="__NO__" set={index} />
            <RptBusinessFilterRadioField state={state} setState={setState} fieldName="isShowEmptyBranch" label="顯示全部" value="__YES__" set={index} />
          </td>
        </tr>
        }
        <tr>
          <th>類　　別</th>
          <td>
            <RptBusinessFilterRadioField state={state} setState={setState} fieldName="propertyType" label="全部" value="__ANY__" set={index} />
            <RptBusinessFilterRadioField state={state} setState={setState} fieldName="propertyType" label="特殊" value="SPECIAL" set={index} />
            <RptBusinessFilterRadioField state={state} setState={setState} fieldName="propertyType" label="二手" value="SECOND_HAND" set={index} />
          </td>
        </tr>
      </tbody >
    </table >
    <p>** 「營業報告」的「分行」，是指在成交中所指定的分行，並不會考慮分成內同事的所屬分行。<br />** 要知道個別分行的確實金額，你可以選取「詳細營業員報告」，再選取分行</p>

    <div className="mt-2 mb-3" style={{ textAlign: "right" }}>
      <Button startIcon={<PrintIcon />} className="mr-3" size="small" variant="contained" disableElevation onClick={(e) => { print(false); }}>列　印</Button>
      <Button startIcon={<DescriptionIcon />} size="small" variant="contained" disableElevation onClick={excel}>Excel</Button>
    </div>
  </div>;
}

function TabPreviewEmail({ index, state, setState, setIdBranches, setPreview }) {
  const classes = useStyles();

  const { branches, vSupervisors, balEmployees } = useSelector(state => {
    return state.selections;
  });

  const handleAdLoginChange = (supervisorAdLogins) => {
    const qtyOldSupervisorAdLogins = JSON.parse(state.supervisorAdLogins).length;
    const qtySupervisors = JSON.parse(supervisorAdLogins).length;
    const qtyBranches = JSON.parse(state.idBranches).length;
    //console.log('qtyOldSupervisorAdLogins', qtyOldSupervisorAdLogins);
    //console.log('qtySupervisors', qtySupervisors);
    //console.log('qtyBranches', qtyBranches);

    // supervisor 由無變有第一個，branch 無
    if ((qtyOldSupervisorAdLogins == 0) && (qtySupervisors == 1) && (qtyBranches == 0)) {
      //console.log('auto branches');

      const adLogin = JSON.parse(supervisorAdLogins)[0];
      const employee = _.find(balEmployees, function (e) { return e.AdLogin == adLogin; });
      const idEmployee = employee ? employee.Id : null;
      const vSupervisor = _.find(vSupervisors, function (s) { return idEmployee && s.IdEmployee == idEmployee; });
      const idBranches = JSON.parse(vSupervisor ? vSupervisor.IdBranches : "[]");
      //console.log('vSupervisor', vSupervisor);
      //console.log('idBranches', idBranches);

      // 篩走已過期
      const autoSelectBranches = _.filter(branches, function (b) {
        return true
          && _.find(idBranches, function (id) { return id == b.Id; }) // 包含在 supervisor 屬下分行
          && !b.Tdate // 已過期
      });
      //console.log('autoSelectBranches', autoSelectBranches);
      const autoSelectBranchIds = JSON.stringify(autoSelectBranches.map(b => b.Id));
      //console.log('autoSelectBranchIds', autoSelectBranchIds);

      setState({
        ...state,
        supervisorAdLogins,
        idBranches: autoSelectBranchIds
      });

      return;
    }

    setState({
      ...state,
      supervisorAdLogins
    });
  };

  const handleIdBranchChange = (strIdBranch) => {
    const qtyOldIdBranches = JSON.parse(state.idBranches).length;
    const qtyStrIdBranch = JSON.parse(strIdBranch).length;
    const qtySupervisors = JSON.parse(state.supervisorAdLogins).length;

    // branch 由無變有第一個，supervisor 無
    if ((qtyOldIdBranches == 0) && (qtyStrIdBranch == 1) && (qtySupervisors == 0)) {
      //console.log('auto supervisorAdLogins');

      const idBranch = JSON.parse(strIdBranch)[0];
      const branch = _.find(branches, function (b) { return b.Id == idBranch; });
      const idEmployees = JSON.parse(branch ? branch.IdEmployees : "[]");
      const supervisorAdLogins = JSON.stringify(_.filter(balEmployees, function (e) { return _.includes(idEmployees, e.Id); }).map(e => e.AdLogin));
      //console.log('supervisorAdLogins', supervisorAdLogins);

      setState({
        ...state,
        idBranches: strIdBranch,
        supervisorAdLogins
      });

      return;
    }

    setState({
      ...state,
      idBranches: strIdBranch,
    });
  };

  const handleCcEmailChange = (strCcEmail) => {
    setState({
      ...state,
      ccEmails: strCcEmail,
    });
  };

  const { supervisorAdLogins, otherReceivers, ccEmails } = state;

  const qtyReceivers = JSON.parse(supervisorAdLogins).length + JSON.parse(ccEmails).length + _.filter(otherReceivers.split("\n"), (s) => s.indexOf("@") > 0).length;

  return <div index={index}>
    <table className={classes.frmFilter}>
      <tbody>
        <tr>
          <th>落訂日期</th>
          <td colSpan={3}>
            <MyDateField name="fDate" dateValue={state.fDate} onDateChange={(d) => { setState({ ...state, fDate: d?.format("YYYY-MM-DD") }); }} tabIndex={101} />
            &nbsp;至&nbsp;
            <MyDateField name="tDate" dateValue={state.tDate} onDateChange={(d) => { setState({ ...state, tDate: d?.format("YYYY-MM-DD") }); }} tabIndex={102} />
          </td>
        </tr>
        <tr>
          <th>收件主管</th>
          <td>
            <SupervisorMultipleCheckbox value={state.supervisorAdLogins} onChange={handleAdLoginChange} tabIndex={102} isShowEmail={true} />
          </td>
          <th rowSpan="3">附件分行</th>
          <td rowSpan="3" style={{ verticalAlign: 'top' }}>
            <BranchMultipleCheckbox value={state.idBranches} onChange={handleIdBranchChange} tabIndex={103} />
            {state.idBranches !== "[]" && <IconButton size="small" onClick={e => { setState({ ...state, idBranches: "[]" }); }}>
              <ClearIcon />
            </IconButton>}
          </td>
        </tr>
        <tr>
          <th>CC 電郵</th>
          <td>
            <CcEmailMultipleCheckbox value={state.ccEmails} onChange={handleCcEmailChange} tabIndex={104} />
          </td>
        </tr>
        <tr>
          <th>CC 其他電郵</th>
          <td>
            <TextareaAutosize minRows={2} value={state.otherReceivers} style={{ width: 400 }} tabIndex={104} onChange={(e) => {
              let value = e.target.value;
              if (_.endsWith(value, "@cent")) {
                value = value.replaceAll("@cent", "@century21-goodwin.com");
                value = value.replaceAll("@century21-goodwin.comury21-goodwin.com", "@century21-goodwin.com");
              }
              setState({ ...state, otherReceivers: value });
            }} />
          </td>
        </tr>
      </tbody >
    </table >

    <div className="mt-2 mb-3" style={{ textAlign: "right" }}>
      <Button startIcon={<VisibilityIcon />} size="small" variant="contained" disableElevation onClick={(_) => { setPreview(true); }}>{qtyReceivers ? `檢視 (共 ${qtyReceivers} 位收件人)` : `檢視`}</Button>
    </div>
  </div>;
}

function RptBusinessFilterRadioField({ state, setState, fieldName, label, value, set }) {
  const name = `${fieldName}_${set}`;
  const id = `${name}_${value}`;

  return <span className="mr-1 ml-1">
    <input className="mr-1" type="radio" value={value} id={id} name={name} onChange={(e) => { setState({ ...state, [fieldName]: e.target.value, }); }} checked={state[fieldName] == value} />
    <label htmlFor={id}>{label}</label>
  </span>;
}
