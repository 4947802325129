import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import axios from 'axios';
import cfg from '../../../config.json';
import Moment from 'moment';
import Employee from '../../../models/employee';

import { showLoading, showInfo, showErrorResponse, clearMsg } from '../../../redux/msgReducer';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { BranchSelection, TeamSelection } from '../../Selections';
import Branch from '../../../models/branch';
import Team from '../../../models/team';

import { Link } from "react-router-dom";

import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';


const useStyles = makeStyles({
  tblList: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& thead": {
      backgroundColor: "#c5e2ff",
      color: "#45525c",
    },

    //"& tr": {
    //    cursor: "pointer",
    //},

    "& tr.alt": {
      backgroundColor: "#f7fbff",
    },

    "& tr.lastWorkingEntity": {
      border: "2px solid #da59da",
    },

    "& tbody tr:hover, tbody tr.alt:hover": {
      backgroundColor: "#ffffcc !important",
    },

    "& th": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },
  },
});

// hideShowCheckHasComm: share to ReportComm && ReportEmployeeDue, toggle 自動選取
export default function ReportCommList({ filter, state, setState, printEmployees, hideShowCheckHasComm, showSelectAll }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { balEmployees, branches, teams } = useSelector(state => {
    return state.selections
  });

  // init
  useEffect(() => {
    // 要 load 晒
    if (_.isEmpty(branches) || _.isEmpty(balEmployees)) return;

    let _employees = [];
    if (state.idEmployee) {
      _employees = _.filter(balEmployees, function (e) { return e.Id == state.idEmployee; });
      setState({
        ...state,
        employees: _employees,
        isPrints: _employees.map(e => { return { id: e.Id, value: true }; })
      });
    } else {
      _employees = _.filter(balEmployees, function (e) { return e.Branch == branches[0].Branch1; });
      setState({
        ...state,
        employees: _employees,
        branch: branches[0],
        isPrints: _employees.map(e => { return { id: e.Id, value: false }; })
      });
    }
  }, [balEmployees, branches]);

  //useEffect(() => {
  //    console.log('state.employees', state.employees);
  //}, [state.employees]);

  const changeFilter = ({ idBranch, idTeam, idEmployee: _idEmployee, name: _name }) => {
    const branch = idBranch ? _.find(branches, function (b) { return b.Id == idBranch }) : idBranch == "" ? new Branch({ Id: "", Branch1: "" }) : state.branch;
    const team = idTeam ? _.find(teams, function (t) { return t.Id == idTeam }) : idTeam == "" ? new Team({ Id: "", Team1: "" }) : state.team;
    const idEmployee = !_.isNil(_idEmployee) ? _idEmployee : state.idEmployee;
    const name = !_.isNil(_name) ? _name : state.name;

    const _employees = _.filter(balEmployees, function (e) {
      let visible = true;
      visible &= (branch?.Branch1 ? e.Branch == branch.Branch1 : true);
      visible &= (team?.Team1 ? e.Team == team.Team1 : true);
      visible &= (idEmployee ? e.Id.toString().startsWith(idEmployee.toString()) : true);
      visible &= (name ? e.Name.toUpperCase().includes(name.toUpperCase()) : true);
      return visible;
    });

    const oldIsPrints = [...state.isPrints];
    const isPrints = _employees.map(e => { return { id: e.Id, value: false }; });
    _.forEach(isPrints, function (isPrint) {
      if (!_.find(oldIsPrints, function (oldIsPrint) {
        return oldIsPrint.value && oldIsPrint.Id == isPrint.id;
      })) return;

      isPrint.value = true;
    });

    setState({
      ...state,
      employees: _employees,
      branch,
      team,
      idEmployee,
      name,
      isPrints
    });
  }

  const handleChecked = (employee) => {
    const i = _.findIndex(state.isPrints, function (_isPrint) { return _isPrint.Id == employee.Id; });
    state.isPrints[i].value = !state.isPrints[i].value;
    setState({ ...state });
  }

  const printChecked = () => {
    const isPrintIdEmployees = _.filter(state.isPrints, function (isPrint) {
      return isPrint.value;
    }).map(isPrint => isPrint.id);

    printEmployees(isPrintIdEmployees);
  }

  const checkHasComm = () => {
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/reportComm/GetEmployeeHasComm`;

    //console.log('filter', filter);

    const DateReport = new Moment(filter.calMonth);
    const IdEmployees = state.employees.map(e => e.Id);

    axios.post(apiEndpoint, { IdEmployees, DateReport }, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          _.forEach(state.isPrints, function (isPrint) {
            isPrint.value = response.data.includes(isPrint.id);
          });

          setState({ ...state });
        }
      })
      .catch(error => {
        dispatch(showErrorResponse(error));
      })
  }

  const clearIsPrints = () => {
    _.forEach(state.isPrints, function (isPrint) { isPrint.value = false });
    setState({ ...state });
  }

  const selectAll = () => {
    _.forEach(state.isPrints, function (isPrint) { isPrint.value = true });
    setState({ ...state });
  }

  const hasChecked = _.findIndex(state.isPrints, function (isPrint) { return isPrint.value; }) >= 0;

  return (
    <table className={classes.tblList} >
      <thead>
        <tr>
          <th>分行 <BranchSelection showAny={false} value={state.branch?.Id} onChange={(e) => { changeFilter({ idBranch: e.target.value }); }} /></th>
          <th>組別 <TeamSelection showAny={false} value={state.team?.Id} onChange={(e) => { changeFilter({ idTeam: e.target.value }); }} /></th>
          <th>編號 <input style={{ width: '50px' }} value={state.idEmployee} onChange={(e) => { changeFilter({ idEmployee: e.target.value }); }} /></th>
          <th>姓名 <input style={{ width: '120px' }} value={state.name} onChange={(e) => { changeFilter({ name: e.target.value }); }} /></th>
          <th width="200">
            {hasChecked && <Button className="mr-1" size="small" variant="contained" onClick={printChecked} >列印已選取</Button>}
            {hasChecked && <Button size="small" variant="contained" onClick={clearIsPrints}>清　　除</Button>}
            {_.isNil(hideShowCheckHasComm) && !hasChecked && <Button size="small" variant="contained" onClick={checkHasComm}>自動選取</Button>}
            {(showSelectAll == true) && !hasChecked && <Button size="small" variant="contained" onClick={selectAll}>全　　選</Button>}
          </th>
        </tr>
      </thead>

      <tbody>
        {
          _.isEmpty(state.employees)
            ? <tr>
              <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>沒有符合的記錄</em></td>
            </tr>
            : state.employees.map((employee, index) => {
              return <tr key={employee.Id} className={index % 2 == 1 ? "alt" : "normal"}>
                <td>{employee.Branch}</td>
                <td>{employee.Team}</td>
                <td>{employee.Id}</td>
                <td><Link to={`/employeeDetail/${employee.Id}`}>{employee.Name}</Link></td>
                <td>
                  <Checkbox size="small" checked={state.isPrints[index].value} onChange={(e) => { handleChecked(employee) }} />
                  <IconButton size="small" onClick={(e) => { printEmployees([employee.Id]); }}><PrintIcon /></IconButton>
                </td>
              </tr>
            })
        }
      </tbody>
    </table>
  );
}

function MyArrowIcon({ direction }) {
  switch (direction) {
    case "ASC": return <ArrowDropUpIcon />;
    case "DESC": return <ArrowDropDownIcon />;
    default: return null;
  }
}
