import _ from 'lodash';

export default class VCommPrepaid {
    constructor({
        Id,
        IdComm,
        DateDeposit,
        RefNo,
        Lot,
        StreetChi,
        BldgChi,
        Block,
        Floor,
        Flat,
        Price,
        CommOwner,
        CommBuyer,
        PercentOwner,
        PercentBuyer,
        CommShared,
        IdCommPrepaid,
        Prepaid,
        IdEmployee,
        IdBranch,
        Branch,
        Name,
        Alias,
    }) {
        this.Id = Id;
        this.IdComm = IdComm;
        this.DateDeposit = DateDeposit;
        this.RefNo = RefNo;
        this.Lot = Lot;
        this.StreetChi = StreetChi;
        this.BldgChi = BldgChi;
        this.Block = Block;
        this.Floor = Floor;
        this.Flat = Flat;
        this.Price = Price;
        this.CommOwner = CommOwner;
        this.CommBuyer = CommBuyer;
        this.PercentOwner = PercentOwner;
        this.PercentBuyer = PercentBuyer;
        this.CommShared = CommShared;
        this.IdCommPrepaid = IdCommPrepaid;
        this.Prepaid = Prepaid;
        this.IdEmployee = IdEmployee;
        this.IdBranch = IdBranch;
        this.Branch = Branch;
        this.Name = Name;
        this.Alias = Alias;
    }
}
