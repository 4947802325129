import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';

import EmployeeVacation from '../models/employeeVacation';

import { showLoading, showInfo, showErrorResponse, clearMsg } from './msgReducer';

//================== Whole State ==================//
let initialCalDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
initialCalDate.setMonth(initialCalDate.getMonth() - 1);

const initialState = {
    isLoading: false,
    isFilterUpdated: false,
    filter: { // match EntitiesResult<T>
        allRecords: 0,
        matchedRecords: 0,
        totalRecords: 0,
        qtyPerPage: 100,
        page: 1,
        row: 0,
        filter: JSON.stringify([{ "property": "f_cal_month", "value": initialCalDate }]),
        sort: JSON.stringify([{ "property": "cal_month", "direction": "DESC" }]),
        success: true,
        message: '',
    },
    entities: [],
    workingEntityId: -1,
    calMonth: '',
    workingEntities: [],
    errors: '',
};


//================== Action Types ==================//
export const EmployeeVacationActions = Object.freeze({
    // filter
    FILTER_UPDATE: Symbol("FILTER_UPDATE"),
    CHANGE_SORT: Symbol("CHANGE_SORT"),
    PAGE_UPDATE: Symbol("PAGE_UPDATE"),
    FETCH_PAGE: Symbol("FETCH_PAGE"), 

    // listing
    REQUEST: Symbol("REQUEST"), 
    SUCCESS: Symbol("SUCCESS"), 
    FAILURE: Symbol("FAILURE"),

    // detail page
    DETAIL_ID: Symbol("DETAIL_ID"),
    DETAIL_SUCCESS: Symbol("DETAIL_SUCCESS"), // ajax loaded
    DETAIL_FAILURE: Symbol("DETAIL_FAILURE"),

    DETAILS_CALMONTH: Symbol("DETAILS_CALMONTH"),
    DETAIL_UPDATE: Symbol("DETAIL_UPDATE"), // form fields update
    DETAIL_DELETED: Symbol("DETAIL_DELETED"), // ajax delete
});


//================== Action Creators ==================//
export const employeeVacationFilterUpdate = (field, value) => {
    return {
        type: EmployeeVacationActions.FILTER_UPDATE,
        payload: {
            field: field,
            value: value
        }
    }
}

const _setCalMonth = (calMonth) => {
    return {
        type: EmployeeVacationActions.DETAILS_CALMONTH,
        payload: calMonth,
    }
}

const _setWorkingEntityId = (id) => {
    return {
        type: EmployeeVacationActions.DETAIL_ID,
        payload: id,
    }
}

export const employeeVacationFilterClear = () => {
    return (dispatch) => {
        dispatch(_setWorkingEntityId(initialState.workingEntityId));
        dispatch(employeeVacationLookup(initialState.filter));
    }
}

export const employeeVacationChangeSort = (field) => {
    return (dispatch, getState) => {
        dispatch({
            type: EmployeeVacationActions.CHANGE_SORT,
            payload: field,
        });
        const { employeeVacation: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(employeeVacationLookup(filter));
    };
}

export const employeeVacationPageUpdate = (page) => {
    return (dispatch, getState) => {
        const { employeeVacation: { filter } } = getState(); // state updated

        let _page = 1;

        if (page == "") {
            _page = "";
        } else {
            _page = parseInt(page);
            if (_.isNaN(_page)) return;
            if (_page < 1) _page = 1;
            if (_page > Math.ceil(filter.matchedRecords / filter.qtyPerPage)) _page = Math.ceil(filter.matchedRecords / filter.qtyPerPage);
        }

        dispatch({
            type: EmployeeVacationActions.FETCH_PAGE,
            payload: _page,
        });
    };
}

export const employeeVacationFetchPage = (page) => {
    return (dispatch, getState) => {
        dispatch({
            type: EmployeeVacationActions.FETCH_PAGE,
            payload: page,
        });
        const { employeeVacation: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(employeeVacationLookup(filter));
    };
}

const _employeeVacationRequest = () => {
    return {
        type: EmployeeVacationActions.REQUEST
    }
}

const _employeeVacationSuccess = (data) => {
    return {
        type: EmployeeVacationActions.SUCCESS,
        payload: data,
    }
}

const _employeeVacationFailure = (response) => {
    return {
        type: EmployeeVacationActions.FAILURE,
        payload: response,
    }
}

export const employeeVacationLookup = (formData) => {
    const _formData = formData ? formData : initialState.filter;
    //console.log(_formData);
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/EmployeeVacation/GetEntities`;

    return (dispatch, getState) => {
        dispatch(_employeeVacationRequest());

        const { employeeVacation } = getState(); // state updated
        if (employeeVacation.isFilterUpdated) _formData.page = 1; // reset page if filter changed
        if (employeeVacation.filter.page == "") _formData.page = 1; // reset page if filter changed

        axios.post(apiEndpoint, _formData, {
            timeout: 30000,
        })
            .then(response => {
            if (response.data) {
                dispatch(_employeeVacationSuccess(response.data)); 
            } else {
                dispatch(_employeeVacationFailure("未能讀取"));
            }
        })
        .catch(error => {
            if (error.response && error.response.data && error.response.data.errors) {
                dispatch(_employeeVacationFailure(error.response.data.errors));
            } else if (error.response && error.response.status) {
                dispatch(_employeeVacationFailure(`未能讀取 (${error.response.status})`));
            } else {
                dispatch(_employeeVacationFailure(`未能聯繫伺服器`));
            }
        })
    }
}

const _employeeVacationDetailSuccess = (data) => {
    return {
        type: EmployeeVacationActions.DETAIL_SUCCESS,
        payload: data,
    }
}

const _employeeVacationDetailFailure = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: EmployeeVacationActions.DETAIL_FAILURE,
            payload: response,
        })
    }
}

export const employeeVacationDetails = (calMonth, isForce) => {
    //console.log('calMonth', calMonth);
    return (dispatch, getState) => {
        const { employeeVacation: { calMonth: lastCalMonth } } = getState();
        if (!isForce && lastCalMonth == calMonth) return; // same month

        dispatch(_setCalMonth(calMonth)); // 任何 dispatch 之前先記住 current working entity id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/EmployeeVacation/GetSameMonthEntities`;

        dispatch(_employeeVacationRequest());

        axios.post(apiEndpoint, { calMonth }, {
            timeout: 30000,
        })
            .then(response => {
                dispatch(clearMsg());

                if (response.data) {
                    dispatch(_employeeVacationDetailSuccess(response.data));
                } else {
                    dispatch(_employeeVacationDetailFailure("未能讀取"));
                }
            })
            .catch(error => {
                dispatch(_employeeVacationDetailFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

export const employeeVacationDetailUpdate = (index, fieldName, value) => {
    return {
        type: EmployeeVacationActions.DETAIL_UPDATE,
        payload: { index, fieldName, value },
    }
}

export const saveEntities = (history) => {
    return (dispatch, getState) => {
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/EmployeeVacation/SaveEntities`;

        const { employeeVacation: { workingEntities } } = getState();

        dispatch(showLoading());

        axios.post(apiEndpoint, workingEntities, {
            timeout: 30000,
        })
            .then(response => {
                if (history.location.pathname == "/employeeVacationDetails/0") {
                    history.replace(`/employeeVacationDetails/${response.data[0].Id}`);
                    dispatch(showInfo("已保存"));
                    return;
                }

                dispatch(_employeeVacationDetailSuccess(response.data));
                dispatch(showInfo("已保存"));
            })
            .catch(error => {
                dispatch(_employeeVacationDetailFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

export const deleteEntities = (history) => {
    return (dispatch, getState, x) => {
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/EmployeeVacation/DeleteEntities`;

        const { employeeVacation: { workingEntities } } = getState();

        dispatch(showLoading());
        dispatch(_employeeVacationRequest());

        axios.post(apiEndpoint, workingEntities, {
            timeout: 30000,
        })
            .then(response => {
                const { employeeVacation: { filter } } = getState();

                dispatch(clearMsg());
                history.push('/employeeVacation');
                dispatch(employeeVacationLookup(filter));
            })
            .catch(error => {
                dispatch(_employeeVacationDetailFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

//================== Reducer ==================//
const employeeVacationReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);

    switch (action.type) {
        case EmployeeVacationActions.FILTER_UPDATE:
            const { field: filterField, value } = action.payload;
            let filters = JSON.parse(state.filter.filter);
            const iFilter = _.findIndex(filters, function (o) { return o.property == filterField; });
            if (iFilter >= 0) {
                if (value) filters[iFilter].value = value;
                else filters.splice(iFilter, 1);
            } else {
                filters.push({ "property": filterField, "value": value });
            }

            _state.filter.filter = JSON.stringify(filters);
            _state.isFilterUpdated = true;
            //console.log('_state.filter', _state.filter.filter);
            return _state;

        case EmployeeVacationActions.CHANGE_SORT:
            const field = action.payload;
            let sorts = JSON.parse(state.filter.sort);
            const i = _.findIndex(sorts, function (o) { return o.property == field; });
            if (i >= 0) {
                sorts[i].direction = sorts[i].direction == "ASC" ? "DESC" : "ASC";
            } else {
                // single sort only
                sorts = [{ "property": field, "direction": "ASC" }];
            }

            _state.filter.sort = JSON.stringify(sorts);
            //console.log('_state.filter.sort', _state.filter.sort);

            employeeVacationLookup(_state.filter);
            return _state;

        case EmployeeVacationActions.PAGE_UPDATE:
            _state.filter.page = action.payload;
            return _state;

        case EmployeeVacationActions.FETCH_PAGE:
            _state.filter.page = action.payload;
            return _state;

        case EmployeeVacationActions.REQUEST:
            _state.isLoading = true;
            return _state

        case EmployeeVacationActions.SUCCESS:
            _state.isLoading = false;
            _state.filter = {
                allRecords: action.payload.allRecords,
                matchedRecords: action.payload.matchedRecords,
                totalRecords: action.payload.totalRecords,
                qtyPerPage: action.payload.qtyPerPage,
                page: action.payload.page,
                row: action.payload.row,
                filter: action.payload.filter,
                sort: action.payload.sort,
                success: action.payload.success,
                message: action.payload.message,
            };
            _state.entities = action.payload.entities;
            _state.errors = "";
            _state.isFilterUpdated = false;
            return _state;

        case EmployeeVacationActions.FAILURE:
            _state.isLoading = false;
            _state.errors = action.payload;
            _state.entities = [];
            return _state;

        case EmployeeVacationActions.DETAIL_ID:
            _state.workingEntityId = action.payload;
            return _state;

        case EmployeeVacationActions.DETAILS_CALMONTH:
            _state.calMonth = action.payload;
            return _state;

        case EmployeeVacationActions.DETAIL_SUCCESS:
            _state.isLoading = false;
            _state.workingEntities = action.payload.map(c => new EmployeeVacation(c));
            return _state;

        case EmployeeVacationActions.DETAIL_FAILURE:
            _state.isLoading = false;
            return _state;

        case EmployeeVacationActions.DETAIL_UPDATE:
            const { index: updateIndex, fieldName: updateFieldName, value: updateValue } = action.payload;
            _state.workingEntities[updateIndex][updateFieldName] = updateValue;

            switch (updateFieldName) {
                case 'Fdate': // apply to all
                    _.forEach(_state.workingEntities, function (employeeVacation) {
                        employeeVacation.Fdate = updateValue;
                    });
                    break;
                case 'Tdate': // apply to all
                    _.forEach(_state.workingEntities, function (employeeVacation) {
                        employeeVacation.Tdate = updateValue;
                    });
                    break;
            }
            return _state;

        default:
            return state;
    }
}

export default employeeVacationReducer;

