import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { employeeVacationDetailUpdate } from '../../redux/employeeVacationReducer';

export default function EmployeeVacationDetailInputField({ index, fieldName, width, tabIndex, textAlign }) {
    const dispatch = useDispatch();
    const { workingEntities } = useSelector(state => {
        return state.employeeVacation;
    });

    return <input value={workingEntities[index][fieldName] ? workingEntities[index][fieldName] : ''} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(employeeVacationDetailUpdate(index, fieldName, e.target.value)); }} />
}