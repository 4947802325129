import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _, { cloneDeep } from 'lodash';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { commDetailApplyCalTool } from "../../redux/commReducer";
import { detailCommSetVisibleCalTool, detailCommApplyCalTool } from "../../redux/commShareAssocReducer";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    backgroundColor: 'rgb(0, 0, 0, 0.2)',
  },
  paper: {
    position: 'absolute',
    width: 500,
    maxHeight: '95%',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",

    "& table": {
      width: "100%",

      "& th, & td": {
        width: "20%",
        border: "1px solid #606060",
        textAlign: "center",
        padding: '0px',

        "& input": {
          width: '100%',
          border: '0px',
          textAlign: 'center',
          background: 'transparent',
        },
      },

      "& td.diff": {
        backgroundColor: "silver",
      },

      "& td.input": {
        backgroundColor: "#ffc000",
      },

      "& td.valueOwner": {
        backgroundColor: 'khaki',
      },

      "& td.valueBuyer": {
        backgroundColor: 'bisque',
      },

      "& td.subTotal": {
        backgroundColor: '#FBE6F2',
      },

      "& td.owner, & td.buyer": {
        backgroundColor: '#ccaed8',
      },

      "& td.total": {
        backgroundColor: '#bd97cc',
      },
    },
  }
}));

export default function ModalCalTool() {
  const classes = useStyles();

  const { isShowModalCalTool } = useSelector(state => {
    return state.commShareAssoc;
  });
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(detailCommSetVisibleCalTool(false));
  };

  const initValues = {
    total: 0,
    owner: 0,
    buyer: 0,
    diffParties: 0,
    agents: _.range(10).map(function (i) { return { subTotal: 0, owner: 0, buyer: 0, diff: 0 }; }),
    diffAgents: 0,
    diffOwners: 0,
    diffBuyers: 0,
  };

  const [values, setValues] = useState(_.cloneDeep(initValues));

  const updateValues = (field, value) => {
    if (isNaN(value)) return;
    const v = value == "" ? 0 : parseFloat(value);

    let _values = cloneDeep(values);

    switch (field) {
      case 'total': _values.total = v; break;
      case 'owner': _values.owner = v; break;
      case 'buyer': _values.buyer = v; break;
      case 'agents0': _values.agents[0].subTotal = v; break;
      case 'agents1': _values.agents[1].subTotal = v; break;
      case 'agents2': _values.agents[2].subTotal = v; break;
      case 'agents3': _values.agents[3].subTotal = v; break;
      case 'agents4': _values.agents[4].subTotal = v; break;
      case 'agents5': _values.agents[5].subTotal = v; break;
      case 'agents6': _values.agents[6].subTotal = v; break;
      case 'agents7': _values.agents[7].subTotal = v; break;
      case 'agents8': _values.agents[8].subTotal = v; break;
      case 'agents9': _values.agents[9].subTotal = v; break;
      default: throw new Error(`${field} is not handled`);
    }

    _values.diffParties = _.round(_values.total - _values.owner - _values.buyer, 2);
    _.forEach(_values.agents, function (agent) {
      if (!agent.subTotal) return;
      agent.owner = _.round(_values.owner / _values.total * agent.subTotal, 2);
      agent.buyer = _.round(_values.buyer / _values.total * agent.subTotal, 2);
      agent.diff = _.round(agent.subTotal - agent.owner - agent.buyer, 2);
    });
    _values.diffAgents = _.round(_values.total - _.sumBy(_values.agents, function (a) { return a.subTotal }), 2);
    _values.diffOwners = _.round(_values.owner - _.sumBy(_values.agents, function (a) { return a.owner }), 2);
    _values.diffBuyers = _.round(_values.buyer - _.sumBy(_values.agents, function (a) { return a.buyer }), 2);

    setValues(_values);
  };

  const clearValues = () => {
    setValues(_.cloneDeep(initValues));
  }

  return <Modal
    className={classes.root}
    open={isShowModalCalTool}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{ timeout: 250, className: classes.backdrop, }}
  >
    <Fade in={isShowModalCalTool}>
      <div className={classes.paper}>
        <h3>指定金額</h3>
        <table>
          <thead>
            <tr>
              <th className="rowleft" rowSpan={2}>&nbsp;</th>
              <th>總數</th>
              <th>業主</th>
              <th>買家</th>
              <th>差異</th>
            </tr>
            <tr className="total">
              <td className="input"><input value={values.total ? values.total : ""} onChange={e => { updateValues('total', e.target.value); }} /></td>
              <td className="input"><input value={values.owner ? values.owner : ""} onChange={e => { updateValues('owner', e.target.value); }} /></td>
              <td className="input"><input value={values.buyer ? values.buyer : ""} onChange={e => { updateValues('buyer', e.target.value); }} /></td>
              <td className="diff">{values.diffParties ? values.diffParties : '-'}</td>
            </tr>
          </thead>
          <tbody>
            {values.agents.map((agent, index) => (
              <tr className="agents" key={`agents${index}`}>
                <td className="rowleft">員工 {index + 1}</td>
                <td className="input"><input value={agent.subTotal ? agent.subTotal : ""} onChange={e => { updateValues(`agents${index}`, e.target.value); }} /></td>
                <td className="valueOwner">{values.agents[index].owner ? values.agents[index].owner : ''}</td>
                <td className="valueBuyer">{values.agents[index].buyer ? values.agents[index].buyer : ''}</td>
                <td className="diff">{values.agents[index].diff ? values.agents[index].diff : '-'}</td>
              </tr>
            ))}
            <tr className="diff">
              <td className="rowleft">差異</td>
              <td className="diff">{values.diffAgents ? values.diffAgents : '-'}</td>
              <td className="diff">{values.diffOwners ? values.diffOwners : '-'}</td>
              <td className="diff">{values.diffBuyers ? values.diffBuyers : '-'}</td>
              <td className="diff">&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <Grid container justifyContent="center" className="mt-3" spacing={2}>
          <Grid item>
            <Button variant="contained" size="small" disableElevation onClick={(_) => { dispatch(commDetailApplyCalTool(values)); dispatch(detailCommApplyCalTool(values)); }}>套　用</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" size="small" disableElevation onClick={(_) => { clearValues(); }}>清　除</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" size="small" disableElevation onClick={(_) => { handleClose(); }}>取　消</Button>
          </Grid>
        </Grid>
      </div>
    </Fade>
  </Modal>;
};