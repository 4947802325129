export default class BranchHistory {
    constructor({
        Id,
        IdBranch,
        Branch,
        Fdate,
        Tdate,
        IdDistrictManager,
        IdEmployee,
        DateHistory,
    }) {
        this.Id = Id;
        this.IdBranch = IdBranch;
        this.Branch = Branch;
        this.Fdate = Fdate;
        this.Tdate = Tdate;
        this.IdDistrictManager = IdDistrictManager;
        this.IdEmployee = IdEmployee;
        this.DateHistory = DateHistory;
    }

    isNew() {
        return !this.Id || (this.Id < 1);
    }

    getFdate() {
        return this.Fdate ? new Date(this.Fdate) : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    }

    getTdate() {
        return this.Tdate ? new Date(this.Tdate) : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    }
}
