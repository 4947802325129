import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Moment from 'moment';

import { commPrepaidUpdate } from "./commPrepaidReducer";
import CommShare from "../models/commShare";

//================== Whole State ==================//
const initialState = {
  isLoading: false,
  errors: '',
  detailOwnerCommShares: _.range(10).map(i => new CommShare({ Party: "OWNER" })),
  detailBuyerCommShares: _.range(10).map(i => new CommShare({ Party: "BUYER" })),

  isShowModalCalTool: false, // toggle showing cal tools

  isShowModalDiff: false,
  diffCommShareOwner: 0, // toggle showing no diff modal window
  diffCommShareBuyer: 0, // toggle showing no diff modal window
};


//================== Action Types ==================//
export const CommShareActions = Object.freeze({
  COMM_SHARES_REQUEST: Symbol("COMM_SHARES_REQUEST"),

  // commDetail associate commshares
  DETAIL_COMM_SHARES_ASSOC: Symbol("DETAIL_COMM_SHARES_ASSOC"),
  DETAIL_COMM_SHARES_SUCCESS: Symbol("DETAIL_COMM_SHARES_SUCCESS"),
  DETAIL_COMM_SHARES_FAILURE: Symbol("DETAIL_COMM_SHARES_FAILURE"),
  DETAIL_COMM_UPDATE: Symbol("DETAIL_COMMS_SHARE"), // comm update
  DETAIL_COMM_SHARES_UPDATE: Symbol("DETAIL_COMM_SHARES_UPDATE"), // commShare update
  DETAIL_COMM_SHARES_SAVE: Symbol("DETAIL_COMMS_SAVE"),

  DETAIL_COMM_SET_EMPLOYEE: Symbol("DETAIL_COMM_SET_EMPLOYEE"),
  DETAIL_COMM_SET_VISIBLE_CAL_TOOL: Symbol("DETAIL_COMM_SET_VISIBLE_CAL_TOOL"),
  DETAIL_COMM_APPLY_CAL_TOOL: Symbol("DETAIL_COMM_APPLY_CAL_TOOL"),

  DETAIL_COMM_CHECK_DIFF: Symbol("DETAIL_COMM_CHECK_DIFF"),
  DETAIL_COMM_SET_VISIBLE_CHECK_DIFF: Symbol("DETAIL_COMM_SET_VISIBLE_CHECK_DIFF"),
});


//================== Action Creators ==================//
const _detailCommSharesAssoc = () => {
  return {
    type: CommShareActions.DETAIL_COMM_SHARES_ASSOC,
  }
}

const _detailCommSharesSuccess = (data) => {
  return {
    type: CommShareActions.DETAIL_COMM_SHARES_SUCCESS,
    payload: data,
  }
}

const _detailCommSharesFailure = (response) => {
  return {
    type: CommShareActions.DETAIL_COMM_SHARES_FAILURE,
    payload: response,
  }
}

export const detailCommShares = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/CommShare/GetEntities`;

  return (dispatch, getState) => {
    const { comm: { workingEntity } } = getState(); // 取得 comm 的 workingEntity
    const data = { filter: JSON.stringify([{ property: 'id_comm', value: `${workingEntity.Id}` }]) };

    dispatch(_detailCommSharesAssoc());

    axios.post(apiEndpoint, data, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_detailCommSharesSuccess(response.data));
        } else {
          dispatch(_detailCommSharesFailure("未能讀取"));
        }
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          dispatch(_detailCommSharesFailure(error.response.data.errors));
        } else if (error.response && error.response.status) {
          dispatch(_detailCommSharesFailure(`未能讀取 (${error.response.status})`));
        } else {
          dispatch(_detailCommSharesFailure(`未能聯繫伺服器`));
        }
      });
  }
}

export const detailCommUpdate = (field, value) => {
  return {
    type: CommShareActions.DETAIL_COMM_UPDATE,
    payload: { field, value },
  };
}

export const commShareUpdate = (commAmount, party, nth, fieldName, value) => {
  return (dispatch, getState) => {
    dispatch({
      type: CommShareActions.DETAIL_COMM_SHARES_UPDATE,
      payload: { commAmount, party, nth, fieldName, value },
    });

    // sync to buyer and prepaid
    if (party == "OWNER" && (fieldName == "IdEmployee")) {
      dispatch({
        type: CommShareActions.DETAIL_COMM_SHARES_UPDATE,
        payload: { commAmount, party: "BUYER", nth, fieldName, value },
      });

      dispatch(commPrepaidUpdate(nth, fieldName, value));
    }
  };
}

export const detailCommShareSetEmployee = (nth, employee) => {
  return {
    type: CommShareActions.DETAIL_COMM_SET_EMPLOYEE,
    payload: { nth, employee },
  };
}

export const detailCommSetVisibleCalTool = (isVisible) => {
  return {
    type: CommShareActions.DETAIL_COMM_SET_VISIBLE_CAL_TOOL,
    payload: isVisible,
  };
}

export const detailCommApplyCalTool = (values) => {
  return {
    type: CommShareActions.DETAIL_COMM_APPLY_CAL_TOOL,
    payload: values,
  };
}

export const detailCommCheckDiff = () => {
  return (dispatch, getState) => {
    const { comm: { workingEntity: { CommOwner, CommBuyer } } } = getState(); // 取得 comm 的 workingEntity
    dispatch({
      type: CommShareActions.DETAIL_COMM_CHECK_DIFF,
      payload: { CommOwner, CommBuyer },
    });
  };
}

export const detailCommSetVisibleCheckDiff = (isVisible) => {
  return {
    type: CommShareActions.DETAIL_COMM_SET_VISIBLE_CHECK_DIFF,
    payload: isVisible,
  };
}

export const showDebitNote = (party) => {
  console.log(party);
  //    return (dispatch, getState) => {
  //        const { comm: { workingEntity: { CommOwner, CommBuyer } } } = getState(); // 取得 comm 的 workingEntity
  //        dispatch({
  //            type: CommShareActions.DETAIL_COMM_CHECK_DIFF,
  //            payload: { CommOwner, CommBuyer },
  //        });
  //    };
}

export const saveAssoc = (comm, getState) => {
  const { commShare: { detailOwnerCommShares, detailBuyerCommShares } } = getState();

  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/CommShare/SaveAssoc`;
  const data = { comm, detailOwnerCommShares, detailBuyerCommShares };
  return axios.post(apiEndpoint, data, {
    timeout: 30000,
    //}).then(response => {
  });
}


//================== Reducer ==================//
const commShareReducer = (state = initialState, action) => {
  let _state = cloneDeep(state);

  switch (action.type) {
    case CommShareActions.DETAIL_COMM_SHARES_ASSOC:
      _state.isLoading = true;
      _state.detailOwnerCommShares = initialState.detailOwnerCommShares;
      _state.detailBuyerCommShares = initialState.detailBuyerCommShares;
      return _state;

    case CommShareActions.DETAIL_COMM_SHARES_SUCCESS:
      _state.isLoading = false;
      let i = 0;
      i = 0;
      _.forEach(_.filter(action.payload.entities, entity => entity.Party == "OWNER"), entity => {
        _state.detailOwnerCommShares[i] = new CommShare(entity);
        i++;
      });
      //console.log(_state.detailOwnerCommShares);

      i = 0;
      _.forEach(_.filter(action.payload.entities, entity => entity.Party == "BUYER"), entity => {
        _state.detailBuyerCommShares[i] = new CommShare(entity);
        i++;
      });
      //console.log(_state.detailBuyerCommShares);

      return _state;

    case CommShareActions.DETAIL_COMM_SHARES_FAILURE:
      _state.isLoading = false;
      _state.errors = action.payload;
      return _state;

    case CommShareActions.DETAIL_COMM_UPDATE:
      const { field, value } = action.payload;
      let amount = null;
      switch (field) {
        case "CommOwner": _calCommShares(_state.detailOwnerCommShares, value); break;
        case "CommBuyer": _calCommShares(_state.detailBuyerCommShares, value); break;
        default: return _state;
      }
      return _state;

    case CommShareActions.DETAIL_COMM_SHARES_UPDATE:
      const { commAmount, party, nth, fieldName: commShareField, value: _commShareValue } = action.payload;
      const commShareValue = _.round(parseFloat(_commShareValue ? _commShareValue : 0), 2);
      switch (party) {
        case "OWNER":
          _state.detailOwnerCommShares[nth][commShareField] = _commShareValue; // raw value
          if (!commAmount) return _state;

          switch (commShareField) {
            case "Amount": // 0.01 allowance
              const percentOwner = _.round(commShareValue / commAmount * 100, 2);
              if (!parseFloat(_state.detailOwnerCommShares[nth].Percent) || Math.abs(parseFloat(_state.detailOwnerCommShares[nth].Percent) - percentOwner) > 0.02)
                _state.detailOwnerCommShares[nth].Percent = percentOwner;
              break;
            case "Percent": _state.detailOwnerCommShares[nth].Amount = _.round(commAmount * commShareValue / 100, 2); break;
          }
          break;
        case "BUYER":
          _state.detailBuyerCommShares[nth][commShareField] = _commShareValue; // raw value
          if (!commAmount) return _state;

          switch (commShareField) {
            case "Amount": // 0.01 allowance
              const percentBuyer = _.round(commShareValue / commAmount * 100, 2);
              if (!parseFloat(_state.detailBuyerCommShares[nth].Percent) || Math.abs(parseFloat(_state.detailBuyerCommShares[nth].Percent) - percentBuyer) > 0.02)
                _state.detailBuyerCommShares[nth].Percent = percentBuyer;
              break;
            case "Percent": _state.detailBuyerCommShares[nth].Amount = _.round(commAmount * commShareValue / 100, 2); break;
          }
          break;
      }
      return _state;

    case CommShareActions.DETAIL_COMM_SET_EMPLOYEE:
      const { nth: nthSetEmployee, employee } = action.payload;
      if (nthSetEmployee == null) return _state;
      _state.detailOwnerCommShares[nthSetEmployee].IdEmployee = employee ? employee.Id : null;
      _state.detailOwnerCommShares[nthSetEmployee].Name = employee ? employee.Name : null;
      _state.detailBuyerCommShares[nthSetEmployee].IdEmployee = employee ? employee.Id : null;
      _state.detailBuyerCommShares[nthSetEmployee].Name = employee ? employee.Name : null;
      //console.log(_state.detailOwnerCommShares[nthSetEmployee]);
      //console.log(_state.detailBuyerCommShares[nthSetEmployee]);
      return _state;

    case CommShareActions.DETAIL_COMM_SET_VISIBLE_CAL_TOOL:
      _state.isShowModalCalTool = action.payload;
      return _state;

    case CommShareActions.DETAIL_COMM_APPLY_CAL_TOOL:
      const owner = action.payload.owner;
      const buyer = action.payload.buyer;
      const values = action.payload.agents;
      for (let i = 0; i < 10; i++) {
        if (!values[i].owner && !values[i].buyer) continue;

        _state.detailOwnerCommShares[i].Amount = values[i].owner;
        _state.detailOwnerCommShares[i].Percent = _.round(values[i].owner / owner * 100, 2);

        _state.detailBuyerCommShares[i].Amount = values[i].buyer;
        _state.detailBuyerCommShares[i].Percent = _.round(values[i].buyer / buyer * 100, 2);
      }
      return _state;

    case CommShareActions.DETAIL_COMM_CHECK_DIFF:
      const { CommOwner, CommBuyer } = action.payload;
      const sumOwner = _.sumBy(_state.detailOwnerCommShares, function (cs) { return parseFloat(cs.Amount ? cs.Amount : 0); });
      const sumBuyer = _.sumBy(_state.detailBuyerCommShares, function (cs) { return parseFloat(cs.Amount ? cs.Amount : 0); });

      _state.diffCommShareOwner = Math.abs(CommOwner - sumOwner);
      _state.diffCommShareBuyer = Math.abs(CommBuyer - sumBuyer);
      _state.isShowModalDiff = true;
      return _state;

    case CommShareActions.DETAIL_COMM_SET_VISIBLE_CHECK_DIFF:
      _state.isShowModalDiff = action.payload;
      return _state;

    default:
      return state;
  }
}

export default commShareReducer;

const _calCommShares = (commShares, value) => {
  let amount = _.parseInt(value)

  for (let i = 0; i < 10; i++) {
    if (_.isNaN(amount) || !commShares[i].Percent) {
      commShares[i].Amount = null;
      continue;
    }

    commShares[i].Amount = _.round(amount / 100 * commShares[i].Percent, 2);
  }
}

