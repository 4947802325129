export default class CommShare {
    constructor({
        Id,
        IdComm,
        CommClass,
        Party,
        IdEmployee,
        Name,
        Alias,
        IsSupervisor,
        Amount,
        Percent,
        CalPercent,
        DateDeposit,
        DateContract,
        DateConclude,
        IdEmpHistory,
        IdTableRate,
        TableDesc,
        AcReceive2nd,
        AcReceiveSpl,
        AcEarnClass,
        AcEarn,
        AcEarnCombine,
        AcEarnSeperate,
        DateDue,
        EditDate,
        EditTime,
        EditUser,
    }) {
        this.Id = Id;
        this.IdComm = IdComm;
        this.CommClass = CommClass;
        this.Party = Party;
        this.IdEmployee = IdEmployee;
        this.Name = Name;
        this.Alias = Alias;
        this.IsSupervisor = IsSupervisor;
        this.Amount = Amount;
        this.Percent = Percent;
        this.CalPercent = CalPercent;
        this.DateDeposit = DateDeposit;
        this.DateContract = DateContract;
        this.DateConclude = DateConclude;
        this.IdEmpHistory = IdEmpHistory;
        this.IdTableRate = IdTableRate;
        this.TableDesc = TableDesc;
        this.AcReceive2nd = AcReceive2nd;
        this.AcReceiveSpl = AcReceiveSpl;
        this.AcEarnClass = AcEarnClass;
        this.AcEarn = AcEarn;
        this.AcEarnCombine = AcEarnCombine;
        this.AcEarnSeperate = AcEarnSeperate;
        this.DateDue = DateDue;
        this.EditDate = EditDate;
        this.EditTime = EditTime;
        this.EditUser = EditUser;
    }

    getDesc() {
        if (this.Id.toString().toUpperCase() == "NEW" || this.Id < 1) return "新佣金分成";

        return `#${this.Id}`;
    }
}
