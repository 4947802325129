import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { employeeVacationFilterUpdate, employeeVacationFilterClear, employeeVacationLookup } from '../../redux/employeeVacationReducer';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import MyDateField from '../MyDateField';
import { BranchSelection, EmployeeSelection } from '../Selections';

const useStyles = makeStyles({
    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& input, & select": {
            padding: "0px 1px",
            fontSize: "13px",
        },

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },

        "& label": {
            margin: 0,
        }
    },
});

export default function EmployeeVacationFilter({ filter }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const filters = JSON.parse(filter.filter);

    const _getFilterValue = (field) => {
        const i = _.findIndex(filters, function (o) { return o.property == field; });
        return (i >= 0) ? filters[i].value : "";
    };

    const filterChange = (field, value) => {
        dispatch(employeeVacationFilterUpdate(field, value));
    };

    const clearFilter = () => {
        dispatch(employeeVacationFilterClear());
    };

    const lookup = () => {
        dispatch(employeeVacationLookup(filter));
    }

    return (
        <div style={{ width: '600px' }}>
            <table className={`${classes.frmFilter} mb-2`}>
                <tbody>
                    <tr>
                        <th style={{ width: '120px' }}>開始日期</th><td><MyDateField dateValue={_getFilterValue('f_cal_month')} onDateChange={(d) => { filterChange('f_cal_month', d); }} tabIndex={1} /></td>
                        <th style={{ width: '120px' }}>結束日期</th><td><MyDateField dateValue={_getFilterValue('t_cal_month')} onDateChange={(d) => { filterChange('t_cal_month', d); }} tabIndex={2} /></td>
                    </tr>
                    <tr>
                        <th>分　　行</th><td><BranchSelection showAny={true} onChange={(e) => { filterChange('id_branch', e.target.value); }} value={_getFilterValue('id_branch')} tabIndex={3} /></td>
                        <th>同　　事</th><td><EmployeeSelection showAny={true} autoFocus={false} width="auto" size={1} onPicked={(employee) => { filterChange('id_employee', employee? employee.Id: ""); }} value={_getFilterValue('id_employee')} tabIndex={4} /></td>
                    </tr>
                </tbody>
            </table>
            <Grid container justifyContent="flex-end" spacing={2} className="mb-2">
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { lookup(); }}>搜　尋</Button>
                </Grid>
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { clearFilter(); }}>清　除</Button>
                </Grid>
            </Grid>
        </div>
    );
}

