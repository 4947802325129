import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Moment from 'moment';

import { openPdf, downloadXls } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";

import Button from '@material-ui/core/Button';
import MyDateField from "../../MyDateField";
import _ from 'lodash';

import { BranchSelection, CalMonthSelection, EmployeeSelection } from '../../Selections';

import DescriptionIcon from '@material-ui/icons/Description';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
  frmFilter: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& th": {
      fontWeight: 'bold',
      border: "1px solid #B0B0B0",
      backgroundColor: "#FAF3DE",
      padding: "2px 4px",
      textAlign: 'center',
    },

    "& td": {
      border: "1px solid #B0B0B0",
      padding: "2px 4px",
    },

    "& label": {
      marginBottom: "0px",
    }
  },
});

export default function ReportVacation() {
  const classes = useStyles();

  const history = useHistory();

  const [state, setState] = useState({
    calMonth: Moment.utc().add(-1, "M").format("YYYY-MM-01"),
    optClass: "__BRANCH__",
    idBranch: "",
    idEmployee: "",
    fDate: Moment.utc().add(-1, "M").format("YYYY-MM-01"),
    tDate: Moment.utc().add(-1, "M").endOf('month').format("YYYY-MM-DD"),
  });

  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  const print = () => {
    let filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    switch (state.optClass) {
      case "__EMPLOYEE_COMM__":
        openPdf("/api/ReportComm/GetPDFEmployeeComm", {
          filter: JSON.stringify(filter)
        }, msgState, msgSetState);
        break;
      case "__SUPERVISOR_COMM__":
        openPdf("/api/ReportCommSupervisor/GetPDFSupervisorComm", {
          filter: JSON.stringify(filter)
        }, msgState, msgSetState);
        break;
      default:
        openPdf("/api/ReportVacation/GetPDF", {
          filter: JSON.stringify(filter)
        }, msgState, msgSetState);
        break;
    }

  }

  const excel = () => {
    let filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));

    downloadXls("/api/ReportVacation/GetXlsx", {
      filter: JSON.stringify(filter)
    }, "假期補發佣金報告.xlsx", msgState, msgSetState);
  }

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/report">
          <Typography variant="h1" className={classes.title}>7. 報告</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>7.16. 假期補發佣金報告</Typography>
      </Breadcrumbs>

      <div style={{ width: '760px' }}>
        <table className={classes.frmFilter}>
          <tbody>
            <tr>
              <th style={{ width: "140px" }}>選　　項</th>
              <td>
                <RptVacationFilterRadioField state={state} setState={setState} fieldName="optClass" label="補發佣金報告" value="__BRANCH__" />
                <RptVacationFilterRadioField state={state} setState={setState} fieldName="optClass" label="詳細補發佣金報告" value="__EMPLOYEE__" />
                <RptVacationFilterRadioField state={state} setState={setState} fieldName="optClass" label="詳細「員工」糧單(對數用)" value="__EMPLOYEE_COMM__" />
                <RptVacationFilterRadioField state={state} setState={setState} fieldName="optClass" label="詳細「經理」糧單(對數用)" value="__SUPERVISOR_COMM__" />
              </td>
            </tr>
            {
              (state.optClass == "__BRANCH__") &&
              <tr>
                <th>計算年度及月份</th>
                <td>
                  <CalMonthSelection value={state.calMonth} onChange={(e) => { setState({ ...state, calMonth: e.target.value }); }} tabIndex={2} />
                </td>
              </tr>
            }
            {
              (state.optClass == "__BRANCH__") &&
              <tr>
                <th>分　　行</th>
                <td>
                  <BranchSelection showAny={true} value={state.idBranch} onChange={(e) => { setState({ ...state, idBranch: e.target.value }) }} tabIndex={3} />
                </td>
              </tr>
            }
            {
              (state.optClass == "__EMPLOYEE__") &&
              <tr>
                <th>佣金日期</th>
                <td>
                  <MyDateField dateValue={state.fDate} onDateChange={(d) => { setState({ ...state, fDate: d?.format("YYYY-MM-DD") }); }} tabIndex={1} />
                  &nbsp;至&nbsp;
                  <MyDateField dateValue={state.tDate} onDateChange={(d) => { setState({ ...state, tDate: d?.format("YYYY-MM-DD") }); }} tabIndex={2} />
                </td>
              </tr>
            }
            {
              (state.optClass == "__EMPLOYEE__") &&
              <tr>
                <th>員　　工</th>
                <td>
                  <EmployeeSelection isShowBalEmployeesOnly={false} size={1} width="auto" showAny={false} value={state.idEmployee} onPicked={(employee) => { setState({ ...state, idEmployee: employee ? employee.Id : "" }) }} tabIndex={1} />
                </td>
              </tr>
            }
            {
              (state.optClass == "__EMPLOYEE_COMM__" || state.optClass == "__SUPERVISOR_COMM__") &&
              <tr>
                <th>佣金日期</th>
                <td>
                  <MyDateField dateValue={state.fDate} onDateChange={(d) => { setState({ ...state, fDate: d?.format("YYYY-MM-DD") }); }} tabIndex={1} />
                  &nbsp;至&nbsp;
                  <MyDateField dateValue={state.tDate} onDateChange={(d) => { setState({ ...state, tDate: d?.format("YYYY-MM-DD") }); }} tabIndex={2} />
                </td>
              </tr>
            }
            {
              (state.optClass == "__EMPLOYEE_COMM__" || state.optClass == "__SUPERVISOR_COMM__") &&
              <tr>
                <th>員　　工</th>
                <td>
                  <EmployeeSelection isShowBalEmployeesOnly={false} size={1} width="auto" showAny={false} value={state.idEmployee} onPicked={(employee) => { setState({ ...state, idEmployee: employee ? employee.Id : "" }) }} tabIndex={1} />
                </td>
              </tr>
            }
          </tbody >
        </table >

        <div className="mt-2 mb-3" style={{ textAlign: "right" }}>
          <Button className="mr-2" size="small" variant="contained" disableElevation onClick={print}>
            <PrintIcon className="mr-1" />列&nbsp;印</Button>
          {
            (state.optClass == "__BRANCH__") &&

            <Button size="small" variant="contained" disableElevation onClick={excel}>
              <DescriptionIcon className="mr-1" />Excel</Button>
          }
          {
            (state.optClass == "__EMPLOYEE__") &&

            <Button size="small" variant="contained" disableElevation onClick={excel}>
              <DescriptionIcon className="mr-1" />Excel</Button>
          }
        </div>

        <div>
          <p>個別同事對數:</p>
          <ul>
            <li>列印前 13 個月的「詳細員工糧單」(重新計算當月已發佣金)</li>
            <li>列印前 13 個月的「詳細補發佣金報告」，比對每月的當月已發佣金</li>
            <li>列印「補發佣金報告」，比詳細補發佣金報告</li>
          </ul>
        </div>
      </div >

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
    </>
  );
}

function RptVacationFilterRadioField({ state, setState, fieldName, label, value }) {
  const name = `filter_${fieldName}`;
  const id = `${name}_${value}`;
  return <span className="mr-1 ml-1">
    <input className="mr-1" type="radio" value={value} id={id} name={name} onChange={(e) => { setState({ ...state, [fieldName]: e.target.value, }); }} checked={state[fieldName] == value} />
    <label htmlFor={id}>{label}</label>
  </span>;
}
