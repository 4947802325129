import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'reactstrap';
import _ from 'lodash';

import { openPdf } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";

import ReportEmployeeDueOption from './ReportEmployeeDueOption';
import ReportCommList from '../7.1ReportComm/ReportCommList';

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
});

export default function ReportComm() {
  const classes = useStyles();

  const history = useHistory();

  const [filterState, filterSetState] = useState({ opt: "__FIRST_RELEVANT__" });
  const [listState, listSetState] = useState({ employees: [], branch: null, team: null, idEmployee: "", name: "", isPrints: [] });
  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  const printEmployees = (employeeIds) => {
    //const params = {
    //    ...filterState,
    //    employeeIds: employeeIds,
    //};

    let filter = _.keys(filterState).map(key => ({
      property: key,
      value: !_.isArray(filterState[key]) ? filterState[key] : JSON.stringify(filterState[key])
    }));
    filter.push({ property: "employeeIds", value: JSON.stringify(employeeIds) });
    //console.log(filter);

    openPdf("/api/ReportEmployeeDue/GetPDF", {
      filter: JSON.stringify(filter)
    }, msgState, msgSetState);
  }

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/report">
          <Typography variant="h1" className={classes.title}>7. 報告</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>7.6. 營業員應收未收報告</Typography>
      </Breadcrumbs>

      <ReportEmployeeDueOption state={filterState} setState={filterSetState} />
      <ReportCommList filter={filterState} state={listState} setState={listSetState} printEmployees={printEmployees} hideShowCheckHasComm={true} />

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
    </>
  );
}
