import React, { useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { userChangeSort } from '../../redux/userReducer';
import { actUser } from '../../redux/authReducer';
import ReactMoment from "react-moment";

import BeatLoader from "react-spinners/BeatLoader";
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import PersonPinIcon from '@material-ui/icons/PersonPin';

const useStyles = makeStyles({
    tblList: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& thead": {
            backgroundColor: "#c5e2ff",
            color: "#45525c",
        },

        "& tr": {
            cursor: "pointer",
        },

        "& tr.alt": {
            backgroundColor: "#f7fbff",
        },

        "& tr.lastWorkingEntity": {
            border: "2px solid #da59da",
        },

        "& tbody tr:hover, tbody tr.alt:hover": {
            backgroundColor: "#ffffcc !important",
        },

        "& th": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },

        "& tr.breakdown": {
            borderLeft: '1px solid #B0B0B0',
            borderRight: '1px solid #B0B0B0',

            "& td": {
                border: 'none',
                backgroundColor: '#dddddd',
            }
        },
    },
});

export default function UserList() {
    const classes = useStyles();

    const history = useHistory();
    const dispatch = useDispatch();

    const { isLoading, filter, entities, workingEntityId, errors } = useSelector(state => {
        return state.user;
    });

    const { dfnDistrictManagers } = useSelector(state => state.selections);

    const { page, qtyPerPage } = filter;

    const sorts = JSON.parse(filter.sort);

    const _getSortDirection = (field) => {
        const i = _.findIndex(sorts, function (o) { return o.property == field; });
        return (i >= 0) ? sorts[i].direction : "";
    };

    const changeSort = (field) => {
        dispatch(userChangeSort(field, filter));
    };

    const showDetail = (id) => {
        history.push(`/userDetail/${id}`);
    }

    const _actUser = (id) => {
        dispatch(actUser(id, history));
    }

    const myRef = useRef(null);
    useEffect(() => {
        if (myRef.current) myRef.current.scrollIntoView({ block: "center" });
    }, []);

    return (
        <table className={classes.tblList} >
            <thead>
                <tr>
                    <th>行</th>
                    <th onClick={(_) => changeSort('dfn_role')}><span>組別</span><MyArrowIcon direction={_getSortDirection("dfn_role")} /></th>
                    <th onClick={(_) => changeSort('name')}><span>姓名</span><MyArrowIcon direction={_getSortDirection("name")} /></th>
                    <th onClick={(_) => changeSort('login')}><span>登入</span><MyArrowIcon direction={_getSortDirection("login")} /></th>
                    <th onClick={(_) => changeSort('date_join')}><span>建立日期</span><MyArrowIcon direction={_getSortDirection("date_join")} /></th>
                    <th onClick={(_) => changeSort('date_login')}><span>最後登入日期</span><MyArrowIcon direction={_getSortDirection("date_login")} /></th>
                    <th onClick={(_) => changeSort('is_active')}><span>有效</span><MyArrowIcon direction={_getSortDirection("is_active")} /></th>
                    <th>套用身分</th>
                </tr>
            </thead>
            {isLoading
                ?
                <tbody>
                    <tr>
                        <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
                    </tr>
                </tbody>
                :
                (errors !== "")
                    ?
                    <tbody>
                        <tr>
                            <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
                        </tr>
                    </tbody >
                    :

                    <tbody>
                        {
                            (entities.length < 1) && <tr>
                                <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>沒有符合的記錄</em></td>
                            </tr>
                        }
                        {
                            entities.map((entity, index) => {
                                return <React.Fragment key= { entity.Id }>
                                    <tr ref={workingEntityId == entity.Id ? myRef : null} className={clsx({ "lastWorkingEntity": workingEntityId == entity.Id, "alt": index % 2 == 1, "normal": index % 2 == 0 })} onClick={() => { showDetail(entity.Id) }}>
                                        <td>{(page - 1) * qtyPerPage + index + 1}</td>
                                        <td>{entity.Role}</td>
                                        <td>{entity.Name}</td>
                                        <td>{entity.Login}</td>
                                        <td>{entity.DateJoin ? <ReactMoment format="YYYY-MM-DD" date={entity.DateJoin} /> : "---"}</td>
                                        <td>{entity.DateLogin ? <ReactMoment format="YYYY-MM-DD hh:mm:ss" date={entity.DateLogin} /> : "---"}</td>
                                        <td><_isActiveIcon isActive={entity.IsActive} /></td>
                                        <td>
                                            <IconButton size="small" onClick={(e) => { e.stopPropagation(); _actUser(entity.Id, history); }}>
                                                <PersonPinIcon style={{ color: "rgb(54 113 221)" }} />
                                            </IconButton>
                                        </td>
                                    </tr>
                                </React.Fragment>
                                })
                        }
                    </tbody>
            }
        </table>
    );
}

function MyArrowIcon({ direction }) {
    switch (direction) {
        case "ASC": return <ArrowDropUpIcon />;
        case "DESC": return <ArrowDropDownIcon />;
        default: return null;
    }
}

function _isActiveIcon({ isActive }) {
    if (_.isNil(isActive)) return <RemoveCircleIcon style={{ color: "rgb(193 178 197)" }} />;
    return isActive ? <CheckCircleIcon style={{ color: "#4fa917" }} /> : <CancelIcon style={{ color: "#e43e3e" }} />;
}