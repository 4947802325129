import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { commApplyRef } from "../../redux/commReducer";
import { commRefLookupAddrsHide } from "../../redux/commRefReducer";

const useStyles = makeStyles((theme)=>({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    backdrop: {
        backgroundColor: 'rgb(0, 0, 0, 0.2)',
    },
    paper: {
        position: 'absolute',
        width: 950,
        height: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: "auto",

        "& table": {
            width: "100%",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
            },

            "& thead": {
                backgroundColor: "#c5e2ff",
                color: "#45525c",
            },

            "& tbody tr": {
                cursor: 'pointer',
            },

            "& tbody tr:hover, tbody tr.alt:hover": {
                backgroundColor: "#ffffcc !important",
            },
        }
    }
}));

export default function ModalLookupAddress() {
    const classes = useStyles();

    const { lookupAddrsIsOpen, lookupAddrs } = useSelector(state => {
        return state.commRef;
    });

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(commRefLookupAddrsHide());
    };

    const handleSelect = (commRef) => {
        dispatch(commApplyRef(commRef));
    }

    return <Modal
        className={classes.root}
        open={lookupAddrsIsOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 250, className: classes.backdrop, }}
    >
        <Fade in={lookupAddrsIsOpen}>
            <div className={classes.paper}>
                (符合條件{lookupAddrs.length}筆)
                <table className="list" style={{ fontSize: '12px', width: '100%' }}>
                    <thead>
                        <tr>
                            <th>行</th>
                            <th style={{ width: '105px' }}>類別</th>
                            <th style={{ width: '50px' }}>地區</th>
                            <th>Lot/街</th>
                            <th>街名(英)</th>
                            <th>街名(中)</th>
                            <th>大廈(英)</th>
                            <th>大廈(中)</th>
                            <th>期</th>
                            <th>座</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lookupAddrs.length < 1
                                ?
                                <tr>
                                    <td colSpan="10">
                                        <Grid container justifyContent="center" className="p-3">
                                            沒有記錄
                                        </Grid>
                                    </td>
                                </tr>
                                :
                                lookupAddrs.map((commRef, index) => <tr key={commRef.Id} onClick={(_) => { handleSelect(commRef); }}>
                                    <td>{index+1}</td>
                                    <td>{commRef.Class}</td>
                                    <td>{commRef.District}</td>
                                    <td>{commRef.Lot}</td>
                                    <td>{commRef.StreetEng}</td>
                                    <td>{commRef.StreetChi}</td>
                                    <td>{commRef.BldgEng}</td>
                                    <td>{commRef.BldgChi}</td>
                                    <td>{commRef.Phrase}</td>
                                    <td>{commRef.Block}</td>
                                </tr>) 
                        }
                    </tbody>
                </table>
                <Grid container justifyContent="center"  className="mt-3">
                    <Button variant="contained" size="small" disableElevation onClick={(_) => { handleClose() }}>取　消</Button>
                </Grid>
            </div>
        </Fade>
    </Modal>;
};