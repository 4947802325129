import React, { useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ReactMoment from "react-moment";
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

import { reportCommReceivedChangeSort, gotoCommDetail } from '../../../redux/reportCommReceivedReducer';

const useStyles = makeStyles({
    tblList: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& thead": {
            backgroundColor: "#c5e2ff",
            color: "#45525c",
        },

        //"& tr": {
        //    cursor: "pointer",
        //},

        "& tr.alt": {
            backgroundColor: "#f7fbff",
        },

        "& tr.lastWorkingEntity": {
            border: "2px solid #da59da",
        },

        "& tbody tr:hover, tbody tr.alt:hover": {
            backgroundColor: "#ffffcc !important",
        },

        "& th": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },
    },
});

export default function ReportCommReceivedList() {
    const classes = useStyles();

    const history = useHistory();
    const dispatch = useDispatch();

    const { isLoading, filter, entities, totalAmount, errors, workingEntityId } = useSelector(state => {
        return state.reportCommReceived;
    });

    const { page, qtyPerPage } = filter;

    const sorts = JSON.parse(filter.sort);

    const _getSortDirection = (field) => {
        const i = _.findIndex(sorts, function (o) { return o.property == field; });
        return (i >= 0) ? sorts[i].direction : "";
    };

    const changeSort = (field) => {
        dispatch(reportCommReceivedChangeSort(field, filter));
    };

    const intlAbs2 = Intl.NumberFormat(undefined, { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 });
    const intlMax2 = Intl.NumberFormat(undefined, { style: 'decimal', maximumFractionDigits: 2 });
    const intlMin3 = Intl.NumberFormat(undefined, { style: 'decimal', minimumFractionDigits: 3 });

    const myRef = useRef(null);
    useEffect(() => {
        if (myRef.current) myRef.current.scrollIntoView({ block: "center" });
    }, []);

    return (
        <table className={classes.tblList} >
            <thead>
                <tr>
                    <th>行</th>
                    <th width="100" onClick={(_) => changeSort('date_deposit')}><span>落訂日期</span><MyArrowIcon direction={_getSortDirection("date_deposit")} /></th>
                    <th width="90" onClick={(_) => changeSort('ref_no')}>物業編號<MyArrowIcon direction={_getSortDirection("ref_no")} /></th>
                    <th onClick={(_) => changeSort('long_addr')}>物業名稱<MyArrowIcon direction={_getSortDirection("long_addr")} /></th>
                    <th width="100" onClick={(_) => changeSort('date_conclude')}>成交日期<MyArrowIcon direction={_getSortDirection("date_conclude")} /></th>
                    <th width="80" onClick={(_) => changeSort('price')}>成交樓價<MyArrowIcon direction={_getSortDirection("price")} /></th>
                    <th width="120" onClick={(_) => changeSort('amount')}>佣金<MyArrowIcon direction={_getSortDirection("amount")} /></th>
                    <th width="100" onClick={(_) => changeSort('date_tran')}>入帳日期<MyArrowIcon direction={_getSortDirection("date_tran")} /></th>
                    <th width="120" onClick={(_) => changeSort('relevant_1st_name')}>有關營業員<MyArrowIcon direction={_getSortDirection("relevant_1st_name")} /></th>
                    <th width="200" onClick={(_) => changeSort('remarks')}>備註<MyArrowIcon direction={_getSortDirection("remarks")} /></th>
                </tr>
            </thead>

            {isLoading
                ?
                <tbody>
                    <tr>
                        <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
                    </tr>
                </tbody>
                :
                (errors !== "")
                    ?
                    <tbody>
                        <tr>
                            <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
                        </tr>
                    </tbody >
                    :

                    <tbody>
                        {
                            entities.length < 1
                                ? <tr>
                                    <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>沒有符合的記錄</em></td>
                                </tr>
                                : entities.map((entity, index) => {
                                    return <tr key={entity.Id} ref={workingEntityId == entity.Id ? myRef : null} className={clsx({ "lastWorkingEntity": workingEntityId == entity.Id, "alt": index % 2 == 1, "normal": index % 2 == 0 })}>
                                        <td>{(page - 1) * qtyPerPage + index + 1}</td>
                                        <td>{entity.DateDeposit && <ReactMoment format="YYYY-MM-DD" date={entity.DateDeposit} />}</td>
                                        <td><Link size="small" onClick={(_) => { dispatch(gotoCommDetail(entity.Id)); }} to={`/commDetail/${entity.Id}`}>{entity.RefNo}</Link></td>
                                        <td><Link size="small" onClick={(_) => { dispatch(gotoCommDetail(entity.Id)); }} to={`/commDetail/${entity.Id}`}>{entity.LongAddr}</Link></td>
                                        <td>{entity.DateConclude && <ReactMoment format="YYYY-MM-DD" date={entity.DateConclude} />}</td>
                                        <td>{entity.Price ? ((entity.Price > 1000000) ? '$ ' + intlMin3.format(entity.Price / 1000000) + 'M' : '$ ' + intlMax2.format(entity.Price)) : '-'}</td>
                                        <td>{entity.Amount ? '$ ' + intlMax2.format(entity.Amount) : '-'}</td>
                                        <td>{entity.DateTran && <ReactMoment format="YYYY-MM-DD" date={entity.DateTran} />}</td>
                                        <td>{entity.getFirstName()}</td>
                                        <td>{entity.Remarks}</td>
                                    </tr>
                                })
                        }
                    </tbody>
            }
            <tfoot>
                <tr>
                    <td style={{ textAlign: 'right' }} colSpan={6}>總數 : </td>
                    <td>$ {intlAbs2.format(totalAmount)}</td>
                    <td colSpan={3} style={{ textAlign: 'left', color: 'OrangeRed' }}>&nbsp;(包括所有分頁)</td>
                </tr>
            </tfoot>
        </table>    );
}

function MyArrowIcon({ direction }) {
    switch (direction) {
        case "ASC": return <ArrowDropUpIcon />;
        case "DESC": return <ArrowDropDownIcon />;
        default: return null;
    }
}
