import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import _ from 'lodash';

import { commTranUpdate, detailCommMoreCommTran, clearCommTran } from '../../redux/commTranReducer';
import { showConfirm } from '../../redux/msgReducer';

import MyDateField from '../MyDateField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    fieldSet: {
        border: "1px solid #c0c0c0",
        padding: "0px 5px 5px",
        backgroundColor: "#FFFFFF",
        lineHeight: '13px',

        '& legend': {
            width: 'auto',
        },

        "& table": {
            width: "100%",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
            },

            "& th": {
                backgroundColor: "#FAF3DE",
                textAlign: 'right',
            },
        },
        "& table.commShares th": {
            textAlign: 'center',
        },

        "& table.commShares .hidden": {
            display: "none",
        },

        "& table.commShares tr.row1st td": {
            backgroundColor: '#DBFFB8',
        },
    },
    backdrop: {
        backgroundColor: 'rgb(0, 0, 0, 0.2)',
    },
    paper: {
        margin: '40px auto',
        width: 320,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: "auto",
    },
}));

export default function FieldsetCommDetailBonus() {
    const classes = useStyles();

    const dispatch = useDispatch();

    const { detailBonusCommTrans, detailBonusCommTransIsVisible } = useSelector(state => {
        return state.commTran;
    });

    return (
        <>
            <fieldset className={clsx(classes.fieldSet, { "mt-3": true })}>
                <legend>已收獎金</legend>
                <table>
                    <tbody>
                        {detailBonusCommTrans.map((commTran, nth) => <CommTranRow key={`commTranBonus_${nth}`} commTran={commTran} nth={nth} isVisible={detailBonusCommTransIsVisible[nth]} baseTabIndex={233} />)}
                        <tr>
                            <td colSpan="2" >
                                <Button onClick={(_) => { dispatch(detailCommMoreCommTran("RECEIVED", "BONUS")); }} size="small" variant="contained" disableElevation>更多已收獎金</Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ height: '10px' }} />
            </fieldset>
        </>
    );

};

function CommTranRow({ commTran, nth, isVisible, baseTabIndex }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const confirmJump = () => {
        const fnConfirm = (_) => { history.push(`/commBonusShareDetail/${commTran.Id}`); };
        dispatch(showConfirm(`跳到獎金分成 #${commTran.Id}？`, fnConfirm));
    };

    return <tr style={{ display: isVisible ? "table-row" : "none" }}>
        <th style={{ width: '120px' }}> 收獎金 {nth + 1}</th>
        <td>
            獎金 $ <CommTranInputField commTran={commTran} nth={nth} fieldName="Amount" width={70} tabIndex={baseTabIndex + nth * 7 + 1} />
            &nbsp;&nbsp;
            日期 <MyDateField dateValue={commTran.DateTran} onDateChange={(d) => { dispatch(commTranUpdate(commTran.Class, commTran.Party, nth, 'DateTran', d)); }} tabIndex={baseTabIndex + nth * 7 + 2} />
            &nbsp;
            <Button onClick={(_) => { dispatch(clearCommTran(commTran.Class, commTran.Party, nth)); }} size="small" variant="contained" disableElevation>清除</Button>
            { !commTran.isNew() &&
                <>
                &nbsp;
                    <Button onClick={e => { e.stopPropagation(); confirmJump() }} size="small" variant="contained" disableElevation>獎金分配</Button>
                </>
            }
        </td>
    </tr>;
}

function CommTranInputField({ commTran, nth, fieldName, width, tabIndex, textAlign }) {
    const dispatch = useDispatch();
    return <input value={commTran[fieldName] ? commTran[fieldName] : ''} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(commTranUpdate(commTran.Class, commTran.Party, nth, fieldName, e.target.value)); }} />;
}
