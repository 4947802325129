import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: "85px",
    "&.invalid": {
      borderColor: "red",
    }
  }
});

// if value length is null, onDateChange(null), if value length is 10, onDateChange([the date])
export default function MyDateField({ dateValue, onDateChange, tabIndex, name }) {
  const classes = useStyles();

  const [str, setStr] = useState('');
  const [d, setD] = useState(null);
  const [isValid, setIsValid] = useState(true);

  if (d !== dateValue) {
    setD(dateValue);

    if (dateValue) {
      //console.log(dateValue);
      Moment.locale('en');
      setStr(Moment(dateValue).format('DD/MM/YYYY'))
    } else {
      setStr('');
    }
  }

  const handleChange = (e) => {
    console.log('handleChange', e.target.value);

    switch (e.target.value.length) {
      case 0: onDateChange(null); setIsValid(true); break;
      case 2:
      case 5: setStr(e.target.value + '/'); setIsValid(false); break;
      case 10:
        setStr(e.target.value);
        //console.log(e.target.value);
        const d = Moment.utc(e.target.value, "DD/MM/YYYY"); // 要用 utc 呀！否則 timezone 會錯Q晒
        //const d = Moment(e.target.value, "DD/MM/YYYY");
        if (d.isValid()) {
          onDateChange(d);
          setIsValid(true)
        } else {
          setIsValid(false);
        }
        break;
      default: setStr(e.target.value); setIsValid(false); break;
    }
  };

  const handleClick = (e) => {
    e.target.select();
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case "Backspace":
        switch (e.target.value.length) {
          case 3:
          case 6:
            //console.log('backspace');
            e.preventDefault();
            setStr(e.target.value.substr(0, e.target.value.length - 2));
            break;
        }
        break;
    }
  };

  return <input className={`${classes.root}` + (isValid ? "": " invalid")} name={name} onClick={handleClick} onKeyDown={handleKeyDown} tabIndex={tabIndex} onChange={handleChange} value={str}></input>;
};