import React from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import ReactMoment from "react-moment";

import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& a.nav-link:hover h1": {
            color: "#006ed0",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },
    },
    detail: {
        "& table": {
            width: "100%",
            backgroundColor: "white",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
                textAlign: 'center',
            },

            "& th": {
                backgroundColor: "#FAF3DE",
            },
        },
    },
}));

export default function TeamDetailTableRates() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { teamDetailEntities } = useSelector(state => {
        return state.tableRate;
    });

    const { workingEntity: team } = useSelector(state => {
        return state.team;
    });

    console.log(team);

    return (
        <div className={classes.detail}>
            <h1>佣金拆賬表</h1>
            <table>
                <thead>
                    <tr>
                        <th>拆賬表編號</th>
                        {team && team.Role == "主管" && <th>分行數量</th>}
                        <th>{team && team.Role == "主管" ? "主管 Override Rate" : "二手樓盤 Rate"}</th>
                        <th>開始日期</th>
                        <th>結束日期</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                { teamDetailEntities.map((tableRate, index) =>
                    <tr key={`tableRates_${index}`}>
                        <td>{tableRate.Id}</td>
                        {team && team.Role == "主管" && <td>{tableRate.QtySuperviseTeam}</td>}
                        <td style={{textAlign:'center'}}>
                            <div style={{ width: '250px', textAlign: 'left', display: "inline-block" }}>
                                {tableRate.Bars.map(b => b.getBarsComponent())}
                            </div>
                        </td>
                        <td>{tableRate.Fdate ? <ReactMoment format="YYYY-MM-DD" date={tableRate.Fdate} /> : "---"}</td>
                        <td>{tableRate.Tdate ? <ReactMoment format="YYYY-MM-DD" date={tableRate.Tdate} /> : "--- 應用中 ---"}</td>
                        <td>
                            <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { history.push(`/tableRateDetail/${tableRate.Id}`); }}>修改</Button>
                        </td>
                    </tr>
                    )}
                </tbody>
                {team && !team.isNew() &&
                <tfoot>
                    <tr>
                        <td colSpan="5" style={{ textAlign:"left" }}>
                            <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { history.push(`/tableRateDetail/0/0/${team.Id}`); }}>新增佣金拆賬表</Button>
                        </td>
                    </tr>
                </tfoot>
                }
            </table>
        </div>
    );
}
