import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Moment from 'moment';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import _ from 'lodash';

import { downloadPdf } from '../../extensions/FileExtensions';
import { LocalModelMsg } from "../ModalMsg";

import { CalMonthSelection, CredSupervisorSelection } from "../Selections";

const useStyles = makeStyles({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& a.nav-link:hover h1": {
            color: "#006ed0",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },
    },

    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },

        "& label": {
            marginBottom: "0px",
        }
    },
});

export default function CredReportCommReceived() {
    const classes = useStyles();

    const [state, setState] = useState({
        fDate: Moment.utc().startOf('month').format("YYYY-MM-DD"),
        tDate: Moment.utc().endOf('month').format("YYYY-MM-DD"),
        idEmployee: "",
    });

    const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

    const print = () => {
        const filter = _.keys(state).map(key => ({
            property: key,
            value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
        }));
        //console.log(filter);

        downloadPdf("/api/CredReportCommReceived/GetPDF", {
            filter: JSON.stringify(filter)
        }, '已收佣金報告.pdf', msgState, msgSetState);
    }

    return (
        <>
            <Breadcrumbs className={classes.title}>
                <Typography variant="h1" className={classes.title}>4. 已收佣金報告</Typography>
            </Breadcrumbs>

            <div key="CalMonthSelection_1" className="form-group row">
                <label htmlFor="fCalMonth" className="col-sm-2 col-form-label">收款日期</label>
                <div className="col-sm-10">
                    <CalMonthSelection className="form-control" value={state.fDate} onChange={(e) => { setState({ ...state, fDate: e.target.value }); }} tabIndex="1" isEndOfMonth={false} />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="tCalMonth" className="d-none d-sm-block col-sm-2 col-form-label">&nbsp;</label>
                <div className="col-sm-10">
                    <span>至</span>
                </div>
            </div>

            <div key="CalMonthSelection_2" className="form-group row">
                <label htmlFor="tCalMonth" className="d-none d-sm-block col-sm-2 col-form-label">&nbsp;</label>
                <div className="col-sm-10">
                    <CalMonthSelection className="form-control" value={state.tDate} onChange={(e) => { setState({ ...state, tDate: e.target.value }); }} tabIndex="2" isEndOfMonth={true} />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="calMonth" className="col-sm-2 col-form-label">經理:</label>
                <div className="col-sm-10">
                    <CredSupervisorSelection showAny={false} className="form-control" value={state.idEmployee} onChange={(e) => { setState({ ...state, idEmployee: e.target.value }); }} tabIndex="2" />
                </div>
            </div>

            <div className="mt-2 mb-3" style={{ textAlign: "center" }}>
                <Button size="small" variant="contained" disableElevation onClick={print}>列　　印</Button>
            </div>

            <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
        </>
    );
}
