import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { employeeDetail, employeeDetailUpdate } from '../../redux/employeeReducer';

import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import BeatLoader from "react-spinners/BeatLoader";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReactMoment from "react-moment";

import { DfnEmpRankSelection } from '../Selections';

import MyDateField from '../MyDateField';
import EmployeeDetailInputField from './EmployeeDetailInputField';
import EmployeeDetailBottomButtons from './EmployeeDetailBottomButtons';
import EmployeeDetailHistories from '../employeeHistory/EmployeeDetailHistories';

const useStyles = makeStyles((theme) => ({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
  detail: {
    "& table": {
      width: "100%",
      backgroundColor: "white",

      "& th, & td": {
        padding: "2px 6px",
        border: "1px solid #D5D5D5",
      },

      "& th": {
        backgroundColor: "#FAF3DE",
        textAlign: 'right',
      },
    },
  },
  backdrop: {
    backgroundColor: 'rgb(0, 0, 0, 0.2)',
  },
  paper: {
    margin: '40px auto',
    width: 320,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },
}));

export default function EmployeeDetail() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, workingEntity } = useSelector(state => {
    return state.employee;
  });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  const { id } = useParams();
  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");

    dispatch(employeeDetail(id));
  }, [id]);

  const navClicked = (path) => {
    history.push(path);
  };

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/employee/list">
          <Typography variant="h1" className={classes.title}>2. 員工資料</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : workingEntity !== null ? workingEntity.getDesc() : ""}</Typography>
      </Breadcrumbs>
      {workingEntity &&
        <div className={classes.detail}>
          <Grid container>
            <Button variant="contained" className="mr-4" size="small" disableElevation onClick={() => { navClicked("/employee"); }}>返回列表</Button>
            <div style={{ flex: 1 }} />
            <Button variant="contained" size="small" disableElevation onClick={() => { dispatch(employeeDetail(id, true)); }}><RefreshIcon /></Button>
          </Grid>

          <table className="mt-2 mb-3" style={{ width: '800px' }}>
            <tbody>
              <tr>
                <th style={{ width: '100px' }}>編號</th>
                <td>{id.toUpperCase() == "NEW" ?
                  <input value={workingEntity.Id.toUpperCase() !== "NEW" ? workingEntity.Id : ""} tabIndex={1} onChange={(e) => { dispatch(employeeDetailUpdate("Id", e.target.value)); }} />
                  : workingEntity.Id}</td>
                <th style={{ width: '200px' }}>組別</th>
                <td>{workingEntity.Team}</td>
              </tr>
              <tr>
                <th>分行</th><td>{workingEntity.Branch}</td>
                <th>身分</th><td>{workingEntity.Role}</td>
              </tr>
              <tr>
                <th>員工姓名</th>
                <td>
                  <EmployeeDetailInputField fieldName="Name" width={140} tabIndex={1} />
                </td>
                <th>入職日期</th>
                <td>{workingEntity.DateJoin ? <ReactMoment format="YYYY-MM-DD" date={workingEntity.DateJoin} /> : "---"}</td>
              </tr>
              <tr>
                <th>員工別名</th>
                <td>
                  <EmployeeDetailInputField fieldName="Alias" width={140} tabIndex={2} />
                </td>
                <th>離職日期</th>
                <td>{workingEntity.DateLeave ? <ReactMoment format="YYYY-MM-DD" date={workingEntity.DateLeave} /> : "--- 應用中 ---"}</td>
              </tr>
              <tr>
                <th>職位</th>
                <td>
                  <DfnEmpRankSelection onChange={(e) => { dispatch(employeeDetailUpdate("IdRank", e.target.value)); }} value={workingEntity.IdRank} tabIndex={3} />
                </td>
                <th>有效的 <span style={{ fontSize: "0.5rem" }}><br />(7.2. 主管佣金報告使用)</span></th>
                <td>
                  <EmployeeIsActiveRadioField fieldName="IsActive" label="有效的" value={true} selectedValue={workingEntity.IsActive} />
                  <EmployeeIsActiveRadioField fieldName="IsActive" label="已停用" value={false} selectedValue={workingEntity.IsActive} />
                </td>
              </tr>
              <tr>
                <th>電郵帳號</th>
                <td>
                  <EmployeeDetailInputField fieldName="AdLogin" width={250} tabIndex={8} />
                </td>
                <th>身分證號碼</th>
                <td>
                  <EmployeeDetailInputField fieldName="Hkid" width={100} tabIndex={8} />
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      }

      <EmployeeDetailHistories />

      <EmployeeDetailBottomButtons />
    </>
  );
}

function EmployeeIsActiveRadioField({ fieldName, label, value, selectedValue }) {
  const dispatch = useDispatch();
  const name = `employee_${fieldName}`;
  const id = `${name}_${value}`;
  return <span className="mr-2" >
    <input type="radio" value={value} id={id} name={name} onChange={e => { dispatch(employeeDetailUpdate(fieldName, value)); }} checked={selectedValue == value} />
    <label htmlFor={id}>{label}</label>
  </span>;
}
