import React, { useMemo }  from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import MyDateField from '../../MyDateField';
import { reportReceiptUpdate, fetchComm } from '../../../redux/reportReceiptReducer';
import { reportReceiptSetModalEmployee } from '../../../redux/selectionsReducer';
import IconButton from '@material-ui/core/IconButton';
import FindInPageIcon from '@material-ui/icons/FindInPage';

const useStyles = makeStyles({
    Receipt: {
        fontSize: '15px',
        backgroundColor: '#DFDFDF',
        border: '1px solid #C5C5C5',
        marginBottom: '10px',
        padding: '10px 25px',
        width: '900px',

        "& table": {
            width: '100%',

            "& th, & td": {
                verticalAlign: 'top',
            },

            "& label": {
                marginBottom: 'auto',
            },

            "& textarea": {
                height: '3em',
                lineHeight: '1.2em',
                overflow: 'hidden'
            }
        },
    },

    fieldSet: {
        border: "1px solid",
        margin: "1em 0em",
        padding: "0em 0.5em 0.5em",

        "& legend": {
            borderLeft: "18px solid #D3BBED",
            fontSize: '18px',
            fontWeight: 'bold',
            padding: '2px 8px 2px 6px',
            width: 'auto',
        }
    }
});

export default function MemoFrmReceipt({ entity, index, print }) {
    const memoFrmReceipt = useMemo(() => <FrmReceipt entity={entity} index={index} print={print} />, [entity]);
    return memoFrmReceipt;
};

function FrmReceipt({entity, index, print }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    //console.log('render FrmReceipt');
    return (
        <div className={classes.Receipt}>
            <table>
                <thead>
                    <tr>
                        <th style={{width:'90px'}}>付款通知單</th>
                        <th style={{ width: '170px' }}>
                            <FrmReceiptRadioField index={index} fieldName="Party" label="業主" value="OWNER" selectedValue={entity.Party} />
                            <FrmReceiptRadioField index={index} fieldName="Party" label="買家 / 租客" value="BUYER" selectedValue={entity.Party} />
                        </th>
                        <th style={{ textAlign:'center' }}>世紀21奇豐物業顧問行</th>
                        <th style={{ width: '260px' }}>&nbsp;</th>
                    </tr>
                </thead>
            </table>
            <table>
                <tbody>
                    <tr>
                        <td rowSpan="2">客戶名稱：</td>
                        <td rowSpan="2">
                            <textarea style={{ width: '180px' }} value={entity.Name ? entity.Name: ''} onChange={(e) => { dispatch(reportReceiptUpdate(index, 'Name', e.target.value)); }} />
                        </td>
                        <td rowSpan="2">地址：</td>
                        <td rowSpan="2">
                            <textarea style={{ width: '290px' }} value={entity.Addr ? entity.Addr: ""} onChange={(e) => { dispatch(reportReceiptUpdate(index, 'Addr', e.target.value)); }} />
                        </td>
                        <td style={{ textAlign: 'right', }}>日　　期：</td>
                        <td><MyDateField dateValue={entity.Date} onDateChange={(d) => { dispatch(reportReceiptUpdate(index, 'Date', d)); }} /></td>
                    </tr>
                </tbody>
            </table>
            <table style={{width:'auto'}}>
                <tbody>
                    <tr>
                        <td>RE:</td>
                        <td>
                            <FrmReceiptInputField index={index} fieldName="RefNo1" value={entity.RefNo1} width="100" />
                            <IconButton size="small" onClick={(e) => { dispatch(fetchComm(index, entity, '1')); }}><FindInPageIcon /></IconButton>
                        </td>
                        <td>
                            <FrmReceiptInputField index={index} fieldName="Desc1" value={entity.Desc1} width="360" />
                        </td>
                        <td>已收佣金:</td>
                        <td>
                            <FrmReceiptInputField index={index} fieldName="Bal1" value={entity.Bal1} width="80" textAlign="right" />
                        </td>
                    </tr>
                    <tr>
                        <td>RE:</td>
                        <td>
                            <FrmReceiptInputField index={index} fieldName="RefNo2" value={entity.RefNo2} width="100" />
                            <IconButton size="small" onClick={(e) => { dispatch(fetchComm(index, entity, '2')); }}><FindInPageIcon /></IconButton>
                        </td>
                        <td>
                            <FrmReceiptInputField index={index} fieldName="Desc2" value={entity.Desc2} width="360" />
                        </td>
                        <td>已收佣金:</td>
                        <td>
                            <FrmReceiptInputField index={index} fieldName="Bal2" value={entity.Bal2} width="80" textAlign="right" />
                        </td>
                    </tr>
                    <tr>
                        <td>RE:</td>
                        <td>
                            <FrmReceiptInputField index={index} fieldName="RefNo3" value={entity.RefNo3} width="100" />
                            <IconButton size="small" onClick={(e) => { dispatch(fetchComm(index, entity, '3')); }}><FindInPageIcon /></IconButton>
                        </td>
                        <td>
                            <FrmReceiptInputField index={index} fieldName="Desc3" value={entity.Desc3} width="360" />
                        </td>
                        <td>已收佣金:</td>
                        <td>
                            <FrmReceiptInputField index={index} fieldName="Bal3" value={entity.Bal3} width="80" textAlign="right" />
                        </td>
                    </tr>
                    <tr>
                        <td>RE:</td>
                        <td>
                            <FrmReceiptInputField index={index} fieldName="RefNo4" value={entity.RefNo4} width="100" />
                            <IconButton size="small" onClick={(e) => { dispatch(fetchComm(index, entity, '4')); }}><FindInPageIcon /></IconButton>
                        </td>
                        <td>
                            <FrmReceiptInputField index={index} fieldName="Desc4" value={entity.Desc4} width="360" />
                        </td>
                        <td>已收佣金:</td>
                        <td>
                            <FrmReceiptInputField index={index} fieldName="Bal4" value={entity.Bal4} width="80" textAlign="right" />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="3">&nbsp;</td>
                        <td>總數:</td>
                        <td><input readOnly value={entity.BalTotal??""} style={{ width:'80px', textAlign: "right" }} /></td>
                    </tr>
                </tbody>
            </table>

            <fieldset className={classes.fieldSet}>
                <legend>正式收據</legend>
                <table>
                    <tbody><tr>
                        <td>收據編號</td>
                        <td>
                            <FrmReceiptInputField index={index} fieldName="ReceiptNo" value={entity.ReceiptNo} width="150" />
                        </td>
                    </tr>
                        <tr>
                            <td>支付營業員服務費</td>
                            <td>
                                <FrmReceiptRadioField index={index} fieldName="PaymentMethod" label="現金 Cash" value="CASH" selectedValue={entity.PaymentMethod} />
                                <br />
                                <FrmReceiptRadioField index={index} fieldName="PaymentMethod" label="支票 Cheque" value="CHEQUE" selectedValue={entity.PaymentMethod} />
                                &nbsp;
                                <FrmReceiptInputField index={index} fieldName="PaymentCheque" value={entity.PaymentCheque} width="150" />
                                &nbsp;
                                ( Bank: <FrmReceiptInputField index={index} fieldName="PaymentBank" value={entity.PaymentBank} width="150" /> )
                                <br />
                                <FrmReceiptRadioField index={index} fieldName="PaymentMethod" label="銀行過數 Bank Transfer" value="BANK" selectedValue={entity.PaymentMethod} />
                            </td>
                        </tr>
                        <tr>
                            <td align="center" colSpan={2}>
                                <Button className="ml-2" size="small" disableElevation color="primary" variant="contained" onClick={(_) => { print(index); }}>列印正式收據</Button>
                            </td>
                        </tr>
                    </tbody></table>
            </fieldset>
        </div>
    );
}

function FrmReceiptRadioField({ index, fieldName, label, value, selectedValue }) {
    //console.log(`value=${value}, selectedValue=${selectedValue}, checked=${value == selectedValue}`);

    const dispatch = useDispatch();

    const name = `field_${index}__${fieldName}`;
    const id = `${name}_${value}`;
    return <span className="mr-1 ml-1">
        <input className="mr-1" type="radio" value={value} id={id} name={name} onChange={(e) => { dispatch(reportReceiptUpdate(index, fieldName, e.target.value)); }} checked={selectedValue == value} />
        <label htmlFor={id}>{label}</label>
    </span>;
}

function FrmReceiptInputField({ index, fieldName, value, width, textAlign }) {
    const dispatch = useDispatch();

    return <input style={{ width: width ? width + 'px' : '100%', textAlign: textAlign ? textAlign: 'auto' }} value={value?value:''} onChange={(e) => { dispatch(reportReceiptUpdate(index, fieldName, e.target.value)); }} />
}
