import React from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { commRefFilterUpdate, commRefFilterClear, commRefLookup } from '../../redux/commRefReducer';

import { BranchSelection, TeamSelection, DistrictSelection, DfnCommClassSelection } from '../Selections';
import MyDateField from '../MyDateField';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& input, & select": {
            padding: "0px 1px",
            fontSize: "13px",
            height: "1.5em",
        },

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },
    },
});

export default function CommRefFilter({ filter }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const filters = JSON.parse(filter.filter);

    const _getFilterValue = (field) => {
        const i = _.findIndex(filters, function (o) { return o.property == field; });
        return (i >= 0) ? filters[i].value : "";
    };

    const filterChange = (field, value) => {
        dispatch(commRefFilterUpdate(field, value));
    };

    const clearFilter = () => {
        dispatch(commRefFilterClear());
    };

    const lookup = () => {
        dispatch(commRefLookup(filter));
    }

    return (
        <div>
            <table className={`${classes.frmFilter} mb-2`}>
                <tbody>
                    <tr>
                        <th>地　　區</th><td><DistrictSelection showAny={true} onChange={(e) => { filterChange('district', e.target.value); }} value={_getFilterValue('district')} tabIndex={1} /></td>
                        <th>英文街道</th><td><input onChange={(e) => { filterChange('l_street_eng', e.target.value); }} value={_getFilterValue('l_street_eng')} style={{ width: '200px' }} tabIndex={3} /></td>
                        <th>英文大廈</th><td><input onChange={(e) => { filterChange('l_bldg_eng', e.target.value); }} value={_getFilterValue('l_bldg_eng')} style={{ width: '200px' }} tabIndex={5} /></td>
                    </tr>
                    <tr>
                        <th>類別</th><td><DfnCommClassSelection showAny={true} onChange={(e) => { filterChange('class', e.target.value); }} value={_getFilterValue('class')} tabIndex={2} /></td>
                        <th>中文街道</th><td><input onChange={(e) => { filterChange('l_street_chi', e.target.value); }} value={_getFilterValue('l_street_chi')} style={{ width: '200px' }} tabIndex={4} /></td>
                        <th>中文大廈</th><td><input onChange={(e) => { filterChange('l_bldg_chi', e.target.value); }} value={_getFilterValue('l_bldg_chi')} style={{ width: '200px' }} tabIndex={6} /></td>
                    </tr>
                </tbody>
            </table>
            <Grid container justifyContent="flex-end" spacing={2} className="mb-2">
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { lookup(); }}>搜　尋</Button>
                </Grid>
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { clearFilter(); }}>清　除</Button>
                </Grid>
            </Grid>
        </div>
    );
}
