import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { saveEntity, deleteEntity } from '../../redux/prepaidReducer';
import { showConfirm, showWarning } from '../../redux/msgReducer';

import Button from '@material-ui/core/Button';

export default function PrepaidDetailBottomButtons() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { workingEntity } = useSelector(state => {
        return state.prepaid;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    const navClicked = (path) => {
        history.push(path);
    };

    const confirmDelete = () => {
        const fnConfirm = (_) => { dispatch(deleteEntity(history)); };
        dispatch(showConfirm("確定刪除？", fnConfirm));
    };

    return <div style={{ textAlign: 'center' }} className="mt-5">
        {Creds.includes("SAVING") && <React.Fragment>
            <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { dispatch(saveEntity(history)); }}>儲存預支佣金</Button>
            {workingEntity && !workingEntity.isNew() && <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { confirmDelete(); }}>刪　除</Button>}
        </React.Fragment>}
        <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { navClicked("/prepaid"); }}>返回列表</Button>
    </div>
}
