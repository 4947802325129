import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import _ from 'lodash';

import BeatLoader from "react-spinners/BeatLoader";

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { authFormUpdate, asyncAuth, authRequestByStorage, authRequestByGoogle } from '../../redux/authReducer';

const useStyles = makeStyles({
  root: {
    padding: "150px 0 200px",
    background: "lightGoldenRodYellow",
  },
  frmLogin: {
    width: 'auto',
    minWidth:'340px',
    border: '1px solid grey',
    margin: '0px auto',
    background: 'white',

    '& th, & td': {
      verticalAlign: 'bottom',
      paddingRight: '10px',
    },

    '& thead': {
      backgroundColor: "#ffc000",
    },

    '& h3': {
      fontSize: '16px',
      lineHeight: "30px",
      fontWeight: "bold",
    },
  },
});

export default function Auth() {
  const googleClientId = "293822838718-btrenddmr350bg99bv9lelkdeqjj79b4.apps.googleusercontent.com";

  const classes = useStyles();

  const history = useHistory();

  const { isLoading, fields: { Login, Password } } = useSelector(state => {
    return state.auth;
  });

  const auth = useSelector(state => {
    return state.auth;
  });
  //console.log(auth);

  const dispatch = useDispatch();

  // 試 storage，如果得就唔駛出 GoogleLogin
  useEffect(() => {
    dispatch(authRequestByStorage(history));
  }, []);

  const googleSignOut = () => { };
  //const { signOut: googleSignOut } = useGoogleLogout({ clientId: googleClientId });

  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleChange = (prop, value) => {
    dispatch(authFormUpdate(prop, value));
  };

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const login = () => {
    dispatch(asyncAuth({
      "Login": Login.value,
      "Password": Password.value,
    }, history, googleSignOut));
  };

  const responseGoogle = (response) => {
    //console.log('responseGoogle', response);

    const { credential } = response;

    if (!_.isNil(credential)) {
      dispatch(authRequestByGoogle(credential, history, googleSignOut));
      return;
    }
  }

  return (
    <div className={classes.root}>
      <table width="100%" className={classes.frmLogin}>
        <thead style={{ borderBottom: "1px solid grey" }}>
          <tr>
            <th colSpan="2" style={{ textAlign: "center" }}>
              <Typography variant="h3">登入系統</Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="right" width="50"><AccountCircle /></td>
            <td>
              <TextField label="登入帳戶" value={Login.value} error={Login.errors.length > 0} helperText={Login.errors.join(",")}
                onChange={(e) => { handleChange('Login', e.target.value); }}
                fullWidth={true}
              />
            </td>
          </tr>
          <tr>
            <td align="right" width="50"><VpnKeyIcon /></td>
            <td>
              <TextField label="密碼" value={Password.value} error={Password.errors.length > 0} helperText={Password.errors.join(",")}
                onChange={(e) => { handleChange('Password', e.target.value); }}
                fullWidth={true}
                InputProps={{
                  type: isShowPassword ? 'text' : 'password',
                  endAdornment: (<InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {isShowPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>)
                }}
              />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2" style={{ textAlign: "center", padding: "12px" }}>
              {isLoading
                ? <BeatLoader color={"#c0c0c0"} />
                : <Box className="d-flex justify-content-center">
                  <Button size="large" className="mr-3" style={{ height: '40px' }} disableElevation variant="contained" color="primary" startIcon={<ExitToAppIcon />} onClick={login} >登入</Button>
                    <GoogleOAuthProvider clientId={googleClientId}>
                    <GoogleLogin
                        onSuccess={responseGoogle}
                        onError={responseGoogle}
                        // width="0" // 限制闊度以顯示基本字眼
                      />
                    </GoogleOAuthProvider>
                  </Box>
              }
            </td>
          </tr>
        </tfoot>
      </table>

    </div>
  );
}
