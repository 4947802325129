import React, { useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ReactMoment from "react-moment";
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

import { reportCommReducedChangeSort, gotoCommDetail } from '../../../redux/reportCommReducedReducer';

const useStyles = makeStyles({
    tblList: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& thead": {
            backgroundColor: "#c5e2ff",
            color: "#45525c",
        },

        //"& tr": {
        //    cursor: "pointer",
        //},

        "& tr.alt": {
            backgroundColor: "#f7fbff",
        },

        "& tr.lastWorkingEntity": {
            border: "2px solid #da59da",
        },

        "& tbody tr:hover, tbody tr.alt:hover": {
            backgroundColor: "#ffffcc !important",
        },

        "& th": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },
    },
});

export default function ReportCommReducedList() {
    const classes = useStyles();

    const history = useHistory();
    const dispatch = useDispatch();

    const { isLoading, filter, entities, totalAmount, errors, workingEntityId } = useSelector(state => {
        return state.reportCommReduced;
    });

    const { page, qtyPerPage } = filter;

    const sorts = JSON.parse(filter.sort);

    const _getSortDirection = (field) => {
        const i = _.findIndex(sorts, function (o) { return o.property == field; });
        return (i >= 0) ? sorts[i].direction : "";
    };

    const changeSort = (field) => {
        dispatch(reportCommReducedChangeSort(field, filter));
    };

    const intlAbs2 = Intl.NumberFormat(undefined, { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 });

    const myRef = useRef(null);
    useEffect(() => {
        if (myRef.current) myRef.current.scrollIntoView({ block: "center" });
    }, []);

    return (
        <table className={classes.tblList} >
            <thead>
                <tr>
                    <th>行</th>
                    <th style={{ width: '100px' }} onClick={(_) => changeSort('DateDeposit')}><span>落訂日期</span><MyArrowIcon direction={_getSortDirection("DateDeposit")} /></th>
                    <th style={{ width: '90px' }} onClick={(_) => changeSort('RefNo')}>物業編號<MyArrowIcon direction={_getSortDirection("RefNo")} /></th>
                    <th onClick={(_) => changeSort('LongAddr')}>物業名稱<MyArrowIcon direction={_getSortDirection("LongAddr")} /></th>
                    <th style={{ width: '100px' }} onClick={(_) => changeSort('DateConclude')}>成交日期<MyArrowIcon direction={_getSortDirection("DateConclude")} /></th>
                    <th style={{ width: '120px' }} onClick={(_) => changeSort('ReduceOwner')}>減收業主<MyArrowIcon direction={_getSortDirection("ReduceOwner")} /></th>
                    <th style={{ width: '120px' }} onClick={(_) => changeSort('ReduceBuyer')}>減收買家<MyArrowIcon direction={_getSortDirection("ReduceBuyer")} /></th>
                    <th style={{ width: '100px' }} onClick={(_) => changeSort('DateReduceOwner')}><span>減收業主日期</span><MyArrowIcon direction={_getSortDirection("DateReduceOwner")} /></th>
                    <th style={{ width: '100px' }} onClick={(_) => changeSort('DateReduceBuyer')}><span>減收買家日期</span><MyArrowIcon direction={_getSortDirection("DateReduceBuyer")} /></th>
                    <th style={{ width: '100px' }} onClick={(_) => changeSort('Relevant1stName')}><span>有關營業員</span><MyArrowIcon direction={_getSortDirection("Relevant1stName")} /></th>
                    <th>備註</th>
                </tr>
            </thead>

            {isLoading
                ?
                <tbody>
                    <tr>
                        <td colSpan={11} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
                    </tr>
                </tbody>
                :
                (errors !== "")
                    ?
                    <tbody>
                        <tr>
                            <td colSpan={11} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
                        </tr>
                    </tbody >
                    :

                    <tbody>
                        {
                            entities.length < 1
                                ? <tr>
                                    <td colSpan={11} style={{ padding: "3em", textAlign: "center", }}><em>沒有符合的記錄</em></td>
                                </tr>
                                : entities.map((entity, index) => {
                                    return <tr key={entity.Id} ref={workingEntityId == entity.Id ? myRef : null} className={clsx({ "lastWorkingEntity": workingEntityId == entity.Id, "alt": index % 2 == 1, "normal": index % 2 == 0 })}>
                                        <td>{(page - 1) * qtyPerPage + index + 1}</td>
                                        <td>{entity.DateDeposit && <ReactMoment format="YYYY-MM-DD" date={entity.DateDeposit} />}</td>
                                        <td><Link size="small" onClick={(_) => { dispatch(gotoCommDetail(entity.Id)); }} to={`/commDetail/${entity.Id}`}>{entity.RefNo}</Link></td>
                                        <td><Link size="small" onClick={(_) => { dispatch(gotoCommDetail(entity.Id)); }} to={`/commDetail/${entity.Id}`}>{entity.LongAddr}</Link></td>
                                        <td>{entity.DateConclude && <ReactMoment format="YYYY-MM-DD" date={entity.DateConclude} />}</td>
                                        <td>{entity.ReduceOwner ? '$ ' + intlAbs2.format(entity.ReduceOwner) : '-'}</td>
                                        <td>{entity.ReduceBuyer ? '$ ' + intlAbs2.format(entity.ReduceBuyer) : '-'}</td>
                                        <td>{entity.DateReduceOwner && <ReactMoment format="YYYY-MM-DD" date={entity.DateReduceOwner} />}</td>
                                        <td>{entity.DateReduceBuyer && <ReactMoment format="YYYY-MM-DD" date={entity.DateReduceBuyer} />}</td>
                                        <td>{entity.Relevant1stName}</td>
                                        <td>{entity.Remarks}</td>
                                    </tr>
                                })
                        }
                    </tbody>
            }
        </table>    );
}

function MyArrowIcon({ direction }) {
    switch (direction) {
        case "ASC": return <ArrowDropUpIcon />;
        case "DESC": return <ArrowDropDownIcon />;
        default: return null;
    }
}
