import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { tableRateDetailUpdate } from '../../redux/barReducer';

import Button from '@material-ui/core/Button';
import { BranchSelection, TeamSelection } from '../Selections';
import MyDateField from '../MyDateField';

const useStyles = makeStyles((theme) => ({
    detail: {
        padding: "5px 10px 15px",
        backgroundColor: '#DBDBDB',
        border: '1px solid #B0B0B0',

        "& h3": {
            fontSize: '20px',
            fontWeight: 'bold',
            lineHeight: '32px',
        },

        "& table": {
            width: '100%',
        },

        "& th, & td": {
            padding: '0px 6px',
            textAlign: 'center',
        },
    },
}));

export default function TableRateDetailBars({ class1 }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { tableRateBars } = useSelector(state => {
        return state.bar;
    });

    const bars = tableRateBars[class1];
    return (
        <div className={classes.detail}>
            {renderTitle(class1)}
            <table>
                <thead>
                    <tr>
                        <th>公司佣金收入</th>
                        <th>拆賬比率</th>
                    </tr>
                </thead>
                <tbody>
                    {bars.map((bar, index) =>
                    <tr key={`bar_${index}`}>
                            <td>
                                HK $&nbsp;
                                <BarInputField class1={class1} index={index} fieldName="Fvalue" width={100} tabIndex={99} textAlign="right" />
                                &nbsp; ~ &nbsp;
                                <BarInputField class1={class1} index={index} fieldName="Tvalue" width={100} tabIndex={99} textAlign="right" />
                            </td>
                            <td>
                                <BarInputField class1={class1} index={index} fieldName="Rate" width={40} tabIndex={99} textAlign="right" />
                            </td>
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

//SECOND_HAND: _.range(10).map(i => new Bonus({ Class: "SECOND_HAND" })),
//SPECIAL: _.range(10).map(i => new Bonus({ Class: "SPECIAL" })),
//SUPERVISOR: _.range(10).map(i => new Bonus({ Class: "SUPERVISOR" })),

function renderTitle (class1) {
    switch (class1) {
        case 'SECOND_HAND':
            return <h3 style={{ color: "#001eff" }}>二手盤</h3>;
        case 'SPECIAL':
            return <h3 style={{ color: "#FF0080" }}>特殊盤</h3>;
        case 'SUPERVISOR':
            return <h3 style={{ color: "#660063" }}>主管佣金</h3>;
    }
}

function BarInputField({ class1, index, fieldName, width, tabIndex, textAlign }) {
    const dispatch = useDispatch();
    const { tableRateBars } = useSelector(state => {
        return state.bar;
    });

    const bar = tableRateBars[class1][index];
    return <input value={!_.isNil(bar[fieldName]) ? bar[fieldName] : ''} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(tableRateDetailUpdate(class1, index, fieldName, e.target.value)); }} />
}