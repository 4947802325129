import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Intl from 'intl';

import Branch from "../models/branch";
import BranchHistory from "../models/branchHistory";
import { showLoading, showInfo, showErrorResponse, clearMsg } from './msgReducer';
import { branchLookup as branchSelectionLookup } from './selectionsReducer';


//================== Whole State ==================//
const initialState = {
    isLoading: false,
    filter: { // match EntitiesResult<T>
        allRecords: 0,
        matchedRecords: 0,
        totalRecords: 0,
        qtyPerPage: 100,
        page: 1,
        row: 0,
        filter: "[]",
        sort: JSON.stringify([{ "property": "seq", "direction": "ASC" }]),
        success: true,
        message: '',
    },
    entities: [],
    entityBranchManagers: {},
    errors: '',
    workingEntityId: -1,
    workingEntity: null,
    workingEntityErrors: '',
    branchHistories: [],
};

//================== Action Types ==================//
export const BranchActions = Object.freeze({
    // filter
    FILTER_UPDATE: Symbol("FILTER_UPDATE"),
    CHANGE_SORT: Symbol("CHANGE_SORT"),
    PAGE_UPDATE: Symbol("PAGE_UPDATE"),
    FETCH_PAGE: Symbol("FETCH_PAGE"),

    // listing
    REQUEST: Symbol("REQUEST"),
    SUCCESS: Symbol("SUCCESS"),
    FAILURE: Symbol("FAILURE"),

    UPDATE_SEQ: Symbol("UPDATE_SEQ"),
    SHOW_BRANCH_MANAGER: Symbol("SHOW_BRANCH_MANAGER"),

    // detail page
    DETAIL_ID: Symbol("DETAIL_ID"),
    DETAIL_SUCCESS: Symbol("DETAIL_SUCCESS"), // ajax loaded
    DETAIL_FAILURE: Symbol("DETAIL_FAILURE"),
    DETAIL_DELETED: Symbol("DETAIL_DELETED"), // ajax delete
    DETAIL_UPDATE: Symbol("DETAIL_UPDATE"), 

    // branch detail
    HISTORY_SUCCESS: Symbol("HISTORY_SUCCESS"), // ajax loaded
    ADD_HISTORY: Symbol("ADD_HISTORY"),
    REMOVE_HISTORY: Symbol("REMOVE_HISTORY"),
    HISTORY_UPDATE: Symbol("HISTORY_UPDATE"),

    // tableRate Detail page associated
    TABLERATE_DETAIL_SUCCESS: Symbol("TABLERATE_DETAIL_SUCCESS"),
    TABLERATE_DETAIL_UPDATE: Symbol("TABLERATE_DETAIL_UPDATE"), // branchTran update
});


//================== Action Creators ==================//
export const branchFilterUpdate = (field, value) => {
    return {
        type: BranchActions.FILTER_UPDATE,
        payload: {
            field: field,
            value: value
        }
    }
}

const _setWorkingEntityId = (id) => {
    return {
        type: BranchActions.DETAIL_ID,
        payload: id,
    }
}

export const branchFilterClear = () => {
    return (dispatch) => {
        dispatch(_setWorkingEntityId(initialState.workingEntityId));
        dispatch(branchLookup(initialState.filter));
    }
}

export const branchChangeSort = (field) => {
    return (dispatch, getState) => {
        dispatch({
            type: BranchActions.CHANGE_SORT,
            payload: field,
        });
        const { branch: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(branchLookup(filter));
    };
}

export const branchPageUpdate = (page) => {
    return (dispatch, getState) => {
        const { branch: { filter } } = getState(); // state updated

        let _page = 1;

        if (page == "") {
            _page = "";
        } else {
            _page = parseInt(page);
            if (_.isNaN(_page)) return;
            if (_page < 1) _page = 1;
            if (_page > Math.ceil(filter.matchedRecords / filter.qtyPerPage)) _page = Math.ceil(filter.matchedRecords / filter.qtyPerPage);
        }

        dispatch({
            type: BranchActions.FETCH_PAGE,
            payload: _page,
        });
    };
}

export const branchFetchPage = (page) => {
    return (dispatch, getState) => {
        dispatch({
            type: BranchActions.FETCH_PAGE,
            payload: page,
        });
        const { branch: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(branchLookup(filter));
    };
}

const _branchRequest = () => {
    return {
        type: BranchActions.REQUEST
    }
}

const _branchSuccess = (data) => {
    return {
        type: BranchActions.SUCCESS,
        payload: data,
    }
}

const _branchFailure = (response) => {
    return {
        type: BranchActions.FAILURE,
        payload: response,
    }
}

export const branchLookup = (formData) => {
    const _formData = formData ? formData : initialState.filter;
    //console.log(_formData);
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Branch/GetEntities`;

    return (dispatch, getState) => {
        dispatch(_branchRequest());

        const { branch } = getState(); // state updated
        if (branch.isFilterUpdated) _formData.page = 1; // reset page if filter changed
        if (branch.filter.page == "") _formData.page = 1; // reset page if filter changed

        axios.post(apiEndpoint, _formData, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch(_branchSuccess(response.data));
                } else {
                    dispatch(_branchFailure("未能讀取"));
                }
            })
            .catch(error => {
                dispatch(_branchFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    }
}

const _tableRateDetailSuccess = ({ data }, tableRateId, isReference) => {
    return {
        type: BranchActions.TABLERATE_DETAIL_SUCCESS,
        payload: { data, tableRateId, isReference },
    }
}

export const tableRateDetail = (isReference) => {
    return (dispatch, getState) => {
        const { tableRate: { workingEntity, referenceEntity } } = getState();
        const tableRate = isReference ? referenceEntity : workingEntity;
        if (tableRate.isNew()) {
            dispatch(dispatch(_tableRateDetailSuccess([], tableRate.Id, isReference)));
            return;
        }

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/branch/GetEntitiesByTableRate`;

        axios.post(apiEndpoint, tableRate, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch(_tableRateDetailSuccess(response, tableRate.Id, isReference));
                }
            })
    };
}

export const tableRateDetailUpdate = (class1, index, fieldName, value) => {
    return {
        type: BranchActions.TABLERATE_DETAIL_UPDATE,
        payload: { class1, index, fieldName, value },
    }
}

export const saveEntitiesByTableRate = (tableRate, getState) => {
    const { branch: { tableRateBranchs } } = getState();

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/branch/SaveEntitiesByTableRate?tableRateId=${tableRate.Id}`;
    return axios.post(apiEndpoint, tableRateBranchs, {
        timeout: 30000,
    });
}

export const deleteEntitiesByTableRate = (getState) => {
    const { branch: { tableRateBranchs } } = getState();

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/branch/DeleteEntitiesByTableRate`;
    return axios.post(apiEndpoint, tableRateBranchs, {
        timeout: 30000,
    });
}

export const updateSeq = (index, ve) => {
    return (dispatch, getState) => {
        const { branch: { entities } } = getState();

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/branch/UpdateSeq`;

        return axios.post(apiEndpoint, { seq : entities[index].Seq, ve }, {
            timeout: 30000,
        }).then(response => {
            dispatch(branchLookup());
            dispatch(branchSelectionLookup()); // update branch selections
        }).catch(error => {
            dispatch(showErrorResponse(error));
        })
    }
}

const _showBranchManagerSuccess = (data, index) => {
    return {
        type: BranchActions.SHOW_BRANCH_MANAGER,
        payload: { data, index },
    }
}

export const toggleBranchManager = (entity, index) => {
    return (dispatch, getState) => {
        const { branch: { entityBranchManagers } } = getState();

        if (entityBranchManagers[index]) { // close
            dispatch(_showBranchManagerSuccess(null, index));
            return;
        }

        // open
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/branch/GetManager`;

        return axios.post(apiEndpoint, entity, {
            timeout: 30000,
        }).then(response => {
            dispatch(_showBranchManagerSuccess(response.data, index));
        }).catch(error => {
            dispatch(showErrorResponse(error));
        })
    }
}

const _branchDetailSuccess = (data) => {
    return {
        type: BranchActions.DETAIL_SUCCESS,
        payload: data,
    }
}

const _branchDetailFailure = (response) => {
    return {
        type: BranchActions.DETAIL_FAILURE,
        payload: response,
    }
}

const _historySuccess = (data) => {
    return {
        type: BranchActions.HISTORY_SUCCESS,
        payload: data,
    };
}

export const branchDetail = (Id, isForce) => {
    return (dispatch, getState) => {
        const { branch: { workingEntityId: lastWorkingEntityId } } = getState();
        if (!isForce && lastWorkingEntityId == Id) return; // same entity

        dispatch(_setWorkingEntityId(Id)); // 任何 dispatch 之前先記住 current working entity Id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Branch/GetEntity`;

        dispatch(_branchRequest());

        axios.post(apiEndpoint, { Id }, {
            timeout: 30000,
        })
            .then(response => {
                const historyApiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/BranchHistory/GetEntityByBranch`;

                return axios.post(historyApiEndpoint, response.data, {
                    timeout: 30000,
                })
                    .then(__response => {
                        if (__response.data) {
                            dispatch(_historySuccess(__response.data));
                        }

                        return response;
                    })
            })
            .then(response => {
                if (response.data) {
                    dispatch(_branchDetailSuccess(response.data));
                } else {
                    dispatch(_branchDetailFailure("未能讀取"));
                }
            })
            .catch(error => {
                dispatch(_branchDetailFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

export const branchDetailUpdate = (field, value) => {
    //console.log(`${field} = ${value}`);
    return {
        type: BranchActions.DETAIL_UPDATE,
        payload: { field, value }
    }
}

export const saveEntity = (history) => {
    return (dispatch, getState) => {
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/branch/SaveEntity`;

        const { branch: { workingEntity, branchHistories } } = getState();

        dispatch(showLoading());

        axios.post(apiEndpoint, workingEntity, {
            timeout: 30000,
        })
            .then(response => {
                // save histories by updated branch.Id
                const idBranch = response.data.Id;
                _.forEach(branchHistories, function (h) {
                    h.IdBranch = idBranch;
                });
                const historyApiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/BranchHistory/SaveEntitiesByBranch?idBranch=${idBranch}`;
                return axios.post(historyApiEndpoint, branchHistories, {
                    timeout: 30000,
                }).then(response2 => {
                    return response; // return branch
                });
            })
            .then(response => {
                if (history.location.pathname == "/branchDetail/0") {
                    history.replace(`/branchDetail/${response.data.Id}`);
                    dispatch(showInfo("已保存"));
                    dispatch(branchSelectionLookup()); // update branch selections
                    return;
                }

                dispatch(branchDetail(response.data.Id, true));
                dispatch(showInfo("已保存"));
                dispatch(branchSelectionLookup()); // update branch selections
            })
            .catch(error => {
                dispatch(_branchDetailFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

export const deleteEntity = (history) => {
    return (dispatch, getState, x) => {
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Branch/DeleteEntity`;

        const { branch: { workingEntity, branchHistories } } = getState();

        dispatch(showLoading());
        dispatch(_branchRequest());

        axios.post(apiEndpoint, workingEntity, {
            timeout: 30000,
        })
            .then(response => {
                // delete histories
                const historyApiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/BranchHistory/DeleteEntities`;
                return axios.post(historyApiEndpoint, branchHistories, {
                    timeout: 30000,
                }).then(response2 => {
                    return response; // return branch
                });
            })
            .then(response => {
                const { branch: { filter } } = getState();

                dispatch(clearMsg());
                history.push('/branch');
                dispatch(branchLookup(filter));
                dispatch(branchSelectionLookup()); // update branch selections
            })
            .catch(error => {
                dispatch(_branchDetailFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

export const branchDetailAddHistory = () => {
    return {
        type: BranchActions.ADD_HISTORY
    };
}

export const branchDetailRemoveHistory = (index) => {
    return (dispatch, getState) => {
        dispatch({
            type: BranchActions.REMOVE_HISTORY,
            payload: index,
        });

        _updateBranch(dispatch, getState);
    };
}

export const branchDetailHistoryUpdate = (index, field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: BranchActions.HISTORY_UPDATE,
            payload: { index, field, value },
        });

        _updateBranch(dispatch, getState);
    }
}

const _updateBranch = (dispatch, getState) => {
    const { branch: { branchHistories }, selections: { branches, teams } } = getState();

    if (branchHistories.length < 1) {
        dispatch(branchDetailUpdate('Fdate', null));
        dispatch(branchDetailUpdate('Tdate', null));
        dispatch(branchDetailUpdate('Branch1', null));
        return;
    }

    const minHistory = _.minBy(branchHistories, function (h) { return h.getFdate(); });
    const maxHistory = _.maxBy(branchHistories, function (h) { return h.getFdate(); });

    const { Fdate } = minHistory;
    const { Tdate, Branch } = maxHistory;

    dispatch(branchDetailUpdate('Fdate', Fdate ? Fdate : null));
    dispatch(branchDetailUpdate('Tdate', Tdate ? Tdate : null));
    dispatch(branchDetailUpdate('Branch1', Branch ? Branch : ""));
}

//================== Reducer ==================//
const branchReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);
    let i = -1;

    switch (action.type) {
        case BranchActions.FILTER_UPDATE:
            const { field: filterField, value } = action.payload;
            let filters = JSON.parse(state.filter.filter);
            const iFilter = _.findIndex(filters, function (o) { return o.property == filterField; });
            if (iFilter >= 0) {
                if (value) filters[iFilter].value = value;
                else filters.splice(iFilter, 1);
            } else {
                filters.push({ "property": filterField, "value": value });
            }

            _state.filter.filter = JSON.stringify(filters);
            _state.isFilterUpdated = true;
            //console.log('_state.filter', _state.filter.filter);
            return _state;

        case BranchActions.CHANGE_SORT:
            const field = action.payload;
            let sorts = JSON.parse(state.filter.sort);
            i = _.findIndex(sorts, function (o) { return o.property == field; });
            if (i >= 0) {
                sorts[i].direction = sorts[i].direction == "ASC" ? "DESC" : "ASC";
            } else {
                // single sort only
                sorts = [{ "property": field, "direction": "ASC" }];
            }

            _state.filter.sort = JSON.stringify(sorts);
            //console.log('_state.filter.sort', _state.filter.sort);

            branchLookup(_state.filter);
            return _state;

        case BranchActions.PAGE_UPDATE:
            _state.filter.page = action.payload;
            return _state;

        case BranchActions.FETCH_PAGE:
            _state.filter.page = action.payload;
            return _state;

        case BranchActions.REQUEST:
            _state.isLoading = true;
            return _state;

        case BranchActions.SUCCESS:
            _state.isLoading = false;
            _state.filter = {
                allRecords: action.payload.allRecords,
                matchedRecords: action.payload.matchedRecords,
                totalRecords: action.payload.totalRecords,
                qtyPerPage: action.payload.qtyPerPage,
                page: action.payload.page,
                row: action.payload.row,
                filter: action.payload.filter,
                sort: action.payload.sort,
                success: action.payload.success,
                message: action.payload.message,
            };
            _state.entities = action.payload.entities.map(entity => new Branch(entity));
            _state.errors = "";
            _state.isFilterUpdated = false;
            return _state;

        case BranchActions.FAILURE:
            _state.isLoading = false;
            _state.errors = action.payload;
            _state.entities = [];
            return _state;

        case BranchActions.TABLERATE_DETAIL_SUCCESS:
            _state.tableRateBranchs = cloneDeep(initialState.tableRateBranchs);

            const { data, tableRateId, isReference } = action.payload;
            //console.log(isReference);
            _.forEach(_.keys(_state.tableRateBranchs), function (_class) {
                _.forEach(_.filter(data, function (b) { return b.Class == _class; }), function (branch, i) {
                    branch.IdTableRate = tableRateId; // assign to tableRateDetail workingEntity
                    if (isReference) branch.Id = 0; // new Branchs when referenceing other tableRate

                    if (!_.isNil(branch.Rate)) branch.Rate = branch.Rate.toFixed(2);
                    _state.tableRateBranchs[_class][i] = branch;
                });
            });
            return _state;

        case BranchActions.TABLERATE_DETAIL_UPDATE:
            const { class1: trDetailUpdateClass1, index: trDetailUpdateIndex, fieldName: trDetailUpdateFieldName, value: trDetailUpdateValue } = action.payload;
            _state.tableRateBranchs[trDetailUpdateClass1][trDetailUpdateIndex][trDetailUpdateFieldName] = trDetailUpdateValue;
            return _state;

        case BranchActions.SHOW_BRANCH_MANAGER:
            const { data: managerData, index: managerIndex } = action.payload;
            _state.entityBranchManagers[managerIndex] = managerData;
            return _state;

        case BranchActions.DETAIL_ID:
            _state.workingEntityId = action.payload;
            return _state;

        case BranchActions.DETAIL_SUCCESS:
            _state.isLoading = false;
            _state.workingEntity = new Branch(action.payload);
            _state.workingEntityId = _state.workingEntity.Id;

            // update list
            i = _.findIndex(_state.entities, function (_entity) { return _entity.Id == _state.workingEntity.Id; });
            if (i >= 0) _state.entities[i] = _state.workingEntity;

            return _state;

        case BranchActions.HISTORY_SUCCESS:
            _state.branchHistories = action.payload.map(values => new BranchHistory(values));
            return _state;

        case BranchActions.DETAIL_FAILURE:
            _state.isLoading = false;
            _state.errors = action.payload;
            return _state;

        case BranchActions.DETAIL_UPDATE:
            const { field: updateField, value: updateValue } = action.payload;
            _state.workingEntity[updateField] = updateValue;
            return _state;

        case BranchActions.ADD_HISTORY:
            _state.branchHistories.push(new BranchHistory({}));
            return _state;

        case BranchActions.REMOVE_HISTORY:
            const removeHistoryIndex = action.payload;
            _.pullAt(_state.branchHistories, removeHistoryIndex)
            return _state;

        case BranchActions.HISTORY_UPDATE:
            const { index: hisUpdateIndex , field : hisUpdateField, value : hisUpdateValue } = action.payload;
            _state.branchHistories[hisUpdateIndex][hisUpdateField] = hisUpdateValue;
            return _state;

        default:
            return state;
    }
}

export default branchReducer;
