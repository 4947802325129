import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { branchDetailAddHistory, branchDetailRemoveHistory, branchDetailHistoryUpdate  } from '../../redux/branchReducer';

import Button from '@material-ui/core/Button';
import { BranchSelection, TeamSelection } from '../Selections';
import MyDateField from '../MyDateField';

const useStyles = makeStyles((theme) => ({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& a.nav-link:hover h1": {
            color: "#006ed0",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },
    },
    detail: {
        "& table": {
            width: "100%",
            backgroundColor: "white",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
                textAlign: 'center',
            },

            "& th": {
                backgroundColor: "#FAF3DE",
            },
        },
    },
}));

export default function BranchDetailHistories() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { branchHistories } = useSelector(state => {
        return state.branch;
    });

    return (
        <div className={classes.detail}>
            <h1>分行過往記錄</h1>
            <table>
                <thead>
                    <tr>
                        <th>行</th>
                        <th>開始日期</th>
                        <th>完結日期</th>
                        <th>分行</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {branchHistories.map((history, index) => {
                        const tabInit = 3 + index * 3;
                        return <tr key={`history_${index}`}>
                            <td>{index + 1}</td>
                            <td><MyDateField dateValue={history.Fdate} onDateChange={(d) => { dispatch(branchDetailHistoryUpdate(index, 'Fdate', d)); }} tabIndex={tabInit + 1} /></td>
                            <td><MyDateField dateValue={history.Tdate} onDateChange={(d) => { dispatch(branchDetailHistoryUpdate(index, 'Tdate', d)); }} tabIndex={tabInit + 2} /></td>
                            <td><input value={!_.isNil(history.Branch) ? history.Branch : ''} style={{ width: '150px' }} tabIndex={tabInit + 3} onChange={(e) => { dispatch(branchDetailHistoryUpdate(index, 'Branch', e.target.value)); }} /></td>
                            <td><Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { dispatch(branchDetailRemoveHistory(index)); }}>移除</Button></td>
                        </tr>;
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="6" style={{ textAlign:"left" }}>
                            <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { dispatch(branchDetailAddHistory()); }}>新增分行過往記錄</Button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
}
