import React, { useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { commRefChangeSort } from '../../redux/commRefReducer';

import BeatLoader from "react-spinners/BeatLoader";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles({
    tblList: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& thead": {
            backgroundColor: "#c5e2ff",
            color: "#45525c",
        },

        "& tr": {
            cursor: "pointer",
        },

        "& tr.alt": {
            backgroundColor: "#f7fbff",
        },

        "& tr.lastWorkingEntity": {
            border: "2px solid #da59da",
        },

        "& tbody tr:hover, tbody tr.alt:hover": {
            backgroundColor: "#ffffcc !important",
        },

        "& th": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },

        "& tr.breakdown": {
            borderLeft: '1px solid #B0B0B0',
            borderRight: '1px solid #B0B0B0',

            "& td": {
                border: 'none',
                backgroundColor: '#dddddd',
            }
        },
    },
});

export default function CommRefList() {
    const classes = useStyles();

    const history = useHistory();
    const dispatch = useDispatch();

    const { isLoading, filter, entities, workingEntityId, errors } = useSelector(state => {
        return state.commRef;
    });

    const { dfnDistrictManagers } = useSelector(state => state.selections);

    const { page, qtyPerPage } = filter;

    const sorts = JSON.parse(filter.sort);

    const _getSortDirection = (field) => {
        const i = _.findIndex(sorts, function (o) { return o.property == field; });
        return (i >= 0) ? sorts[i].direction : "";
    };

    const changeSort = (field) => {
        dispatch(commRefChangeSort(field, filter));
    };

    const showDetail = (id) => {
        history.push(`/commRefDetail/${id}`);
    }

    const myRef = useRef(null);
    useEffect(() => {
        if (myRef.current) myRef.current.scrollIntoView({ block: "center" });
    }, []);

    return (
        <table className={classes.tblList} >
            <thead>
                <tr>
                    <th>行</th>
                    <th onClick={(_) => changeSort('id')}><span>編號</span><MyArrowIcon direction={_getSortDirection("id")} /></th>
                    <th onClick={(_) => changeSort('class')}><span>類別</span><MyArrowIcon direction={_getSortDirection("class")} /></th>
                    <th onClick={(_) => changeSort('district')}><span>地區</span><MyArrowIcon direction={_getSortDirection("district")} /></th>
                    <th onClick={(_) => changeSort('lot')}><span>Lot</span><MyArrowIcon direction={_getSortDirection("lot")} /></th>
                    <th onClick={(_) => changeSort('street_eng')}><span>英文地址</span><MyArrowIcon direction={_getSortDirection("street_eng")} /></th>
                    <th onClick={(_) => changeSort('street_chi')}><span>中文地址</span><MyArrowIcon direction={_getSortDirection("street_chi")} /></th>
                    <th onClick={(_) => changeSort('bldg_eng')}><span>英文大廈</span><MyArrowIcon direction={_getSortDirection("bldg_eng")} /></th>
                    <th onClick={(_) => changeSort('bldg_chi')}><span>中文大廈</span><MyArrowIcon direction={_getSortDirection("bldg_chi")} /></th>
                    <th onClick={(_) => changeSort('phrase')}><span>期</span><MyArrowIcon direction={_getSortDirection("phrase")} /></th>
                    <th onClick={(_) => changeSort('block')}><span>座</span><MyArrowIcon direction={_getSortDirection("block")} /></th>
                </tr>
            </thead>
            {isLoading
                ?
                <tbody>
                    <tr>
                        <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
                    </tr>
                </tbody>
                :
                (errors !== "")
                    ?
                    <tbody>
                        <tr>
                            <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
                        </tr>
                    </tbody >
                    :

                    <tbody>
                        {
                            (entities.length < 1) && <tr>
                                <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>沒有符合的記錄</em></td>
                            </tr>
                        }
                        {
                            entities.map((entity, index) => {
                                return <React.Fragment key= { entity.Id }>
                                    <tr ref={workingEntityId == entity.Id ? myRef : null} className={clsx({ "lastWorkingEntity": workingEntityId == entity.Id, "alt": index % 2 == 1, "normal": index % 2 == 0 })} onClick={() => { showDetail(entity.Id) }}>
                                        <td>{(page - 1) * qtyPerPage + index + 1}</td>
                                        <td>{entity.Id}</td>
                                        <td>{entity.Class}</td>
                                        <td>{entity.District}</td>
                                        <td>{entity.Log}</td>
                                        <td>{entity.StreetEng}</td>
                                        <td>{entity.StreetChi}</td>
                                        <td>{entity.BldgEng}</td>
                                        <td>{entity.BldgChi}</td>
                                        <td>{entity.Phase}</td>
                                        <td>{entity.Block}</td>
                                    </tr>
                                </React.Fragment>
                                })
                        }
                    </tbody>
            }
        </table>
    );
}

function MyArrowIcon({ direction }) {
    switch (direction) {
        case "ASC": return <ArrowDropUpIcon />;
        case "DESC": return <ArrowDropDownIcon />;
        default: return null;
    }
}
