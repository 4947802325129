import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Moment from 'moment';

import ReportDebitNote from '../models/reportDebitNote';
import Comm from '../models/comm';

import { showLoading, showInfo, showErrorResponse, clearMsg } from './msgReducer';

//================== Whole State ==================//
const initialState = {
    isInited: false,
    isLoading: false,
    refNos: [],
    parties: [],
    entities: [new ReportDebitNote({})],
    errors: '',
};


//================== Action Types ==================//
export const ReportDebitNoteActions = Object.freeze({
    // reset all entities
    RESET_ALL: Symbol("RESET_ALL"),

    // refno is provided
    SET_REFNOS: Symbol("SET_REFNOS"),

    // listing
    REQUEST: Symbol("REQUEST"), 
    SUCCESS: Symbol("SUCCESS"), 
    FAILURE: Symbol("FAILURE"),

    FETCH_REQUEST: Symbol("FETCH_REQUEST"),
    FETCH_COMM: Symbol("FETCH_COMM"),

    UPDATE: Symbol("UPDATE"),
    SET_EMPLOYEE: Symbol("SET_EMPLOYEE"),
});


//================== Action Creators ==================//
export const setRefnosAndParities = (refNos, parties) => {
    return {
        type: ReportDebitNoteActions.SET_REFNOS,
        payload: { refNos, parties }
    }
}

const _reportDebitNoteRequest = () => {
    return {
        type: ReportDebitNoteActions.REQUEST
    }
}

const _reportDebitNoteFailure = (response) => {
    return {
        type: ReportDebitNoteActions.FAILURE,
        payload: response,
    }
}

export const reportDebitNoteReset = () => {
    return {
        type: ReportDebitNoteActions.RESET_ALL
    };
}

export const reportDebitNoteShowForComms = () => {
    return (dispatch, getState) => {
        const { reportDebitNote: { refNos, parties } } = getState(); // state updated

        if (_.isEmpty(refNos)) { // 模擬 return from axios
            return {
                type: ReportDebitNoteActions.SUCCESS,
                payload: [new ReportDebitNote({})],
            };
        }

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/ReportDebitNote/GetEntities`;

        dispatch(_reportDebitNoteRequest());

        var ayncFn = async () => {
            try {
                const response = await axios.post(apiEndpoint, { refNos, parties }, {
                    timeout: 30000,
                });

                if (response.data) {
                    //console.log(response.data);

                    return dispatch({
                        type: ReportDebitNoteActions.SUCCESS,
                        payload: response.data.map((e, i) => new ReportDebitNote({ ...e, Party: parties[i] })),
                    });
                } else {
                    dispatch(_reportDebitNoteFailure("未能讀取"));
                }

            } catch (error) {
                dispatch(_reportDebitNoteFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            }
        };

        ayncFn();
    };
}

export const reportDebitNoteUpdate = (index, field, value) => {
    return (dispatch, getState) => {

        const { selections: { employees } } = getState();

        dispatch({
            type: ReportDebitNoteActions.UPDATE,
            payload: { index, field, value, employees },
        })
    };
}

export const fetchComm = (index, entity, nth) => {
    return (dispatch, getState) => {
        const { reportDebitNote: { entities } } = getState(); // state updated

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/ReportDebitNote/FetchComm`;

        const refNo = entity[`RefNo${nth}`];

        dispatch({
            type: ReportDebitNoteActions.FETCH_REQUEST,
            payload: { index, nth }
        });

        axios.post(apiEndpoint, { RefNo: refNo }, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch({
                        type: ReportDebitNoteActions.FETCH_COMM,
                        payload: { index, party: entities[index].Party, nth, comm: new Comm(response.data) }
                    });
                } else {
                    dispatch(_reportDebitNoteFailure("未能讀取"));
                }
            })
            .catch(error => {
                dispatch(_reportDebitNoteFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

export const reportDebitNoteSetEmployee = (nthReportDebitNote, employee) => {
    return {
        type: ReportDebitNoteActions.SET_EMPLOYEE,
        payload: { nthReportDebitNote, employee},
    }

}

//================== Reducer ==================//
const reportDebitNoteReducer = (state = initialState, action) => {
    switch (action.type) {
        case ReportDebitNoteActions.RESET_ALL:
            return {
                ...state,
                refNos: initialState.refNos,
                parties: initialState.parties,
                entities: initialState.entities,
            };

        case ReportDebitNoteActions.SET_REFNOS:
            const { refNos, parties } = action.payload;
            return {
                ...state,
                refNos,
                parties
            };

        case ReportDebitNoteActions.REQUEST:
            return {
                ...state,
                isLoading:true,
            };

        case ReportDebitNoteActions.SUCCESS:
            return {
                ...state,
                isLoading: false,
                refNos:[],
                parties:[],
                entities: action.payload,
                errors:'',
            };

        case ReportDebitNoteActions.FAILURE:
            return {
                ...state,
                isLoading:false,
                errors:action.payload,
                entities:[],
            };

        case ReportDebitNoteActions.FETCH_REQUEST:
            const { index: fetchReqIndex, nth: fetchReqNth } = action.payload;

            const fetchReqEntity = new ReportDebitNote({ ...state.entities[fetchReqIndex] });

            fetchReqEntity.Name = '...';
            fetchReqEntity.Addr = '...'
            fetchReqEntity[`Desc${fetchReqNth}`] = '...';
            fetchReqEntity[`Bal${fetchReqNth}`] = '...';
            fetchReqEntity.DateDue = null;
            fetchReqEntity.IdEmployee = "";
            fetchReqEntity.EmployeeName = "";

            state.entities[fetchReqIndex] = fetchReqEntity;

            return { ...state };

        case ReportDebitNoteActions.FETCH_COMM:
            const { index: fetchCommIndex, party: fetchParty, nth: fetchNth, comm: fetchComm } = action.payload;

            const fetchCommEntity = new ReportDebitNote({ ...state.entities[fetchCommIndex] });

            switch (fetchParty) {
                case "OWNER":
                    fetchCommEntity.Name = fetchComm.OwnerEng ? fetchComm.OwnerEng : fetchComm.OwnerChi;
                    fetchCommEntity.Addr = (fetchComm.OwnerMailingAddr ?? "") + (fetchComm.OwnerMailingAddr2 ? "\n" + fetchComm.OwnerMailingAddr2 : "");
                    fetchCommEntity[`Bal${fetchNth}`] = fetchComm.CommOwner;
                    break;
                case "BUYER":
                    fetchCommEntity.Name = fetchComm.BuyerEng ? fetchComm.BuyerEng : fetchComm.BuyerChi;
                    fetchCommEntity.Addr = (fetchComm.BuyerMailingAddr ?? "") + (fetchComm.BuyerMailingAddr2 ? "\n" + fetchComm.BuyerMailingAddr2 : "");
                    fetchCommEntity[`Bal${fetchNth}`] = fetchComm.CommBuyer;
                    break;
                default:
                    fetchCommEntity.Name = "未指定";
                    fetchCommEntity.Addr = "";
                    fetchCommEntity[`Bal${fetchNth}`] = null;
                    break;
            }

            fetchCommEntity[`Desc${fetchNth}`] = fetchComm.LongAddr;
            fetchCommEntity.DateDue = fetchComm.DateDue;
            fetchCommEntity.IdEmployee = fetchComm.Relevant1stId;
            fetchCommEntity.EmployeeName = fetchComm.Relevant1stName;

            _updateBalTotal(fetchCommEntity);
            state.entities[fetchCommIndex] = fetchCommEntity;

            return { ...state };

        case ReportDebitNoteActions.UPDATE:
            const { index: updateIndex, field: updateField, value: updateValue, employees } = action.payload;

            const entity = new ReportDebitNote({ ...state.entities[updateIndex], [updateField]: updateValue });

            switch (updateField) {
                case "Bal1":
                case "Bal2":
                case "Bal3":
                case "Bal4":
                    _updateBalTotal(entity);
                    break;
                case "IdEmployee":
                    entity.EmployeeName = _.find(employees, function (employee) { return employee.Id == updateValue })?.Name ?? "";
                    break;
            }

            state.entities[updateIndex] = entity;

            return { ...state };

        case ReportDebitNoteActions.SET_EMPLOYEE:
            const { nthReportDebitNote, employee } = action.payload;
            const entitySetEmployee = new ReportDebitNote({ ...state.entities[nthReportDebitNote], IdEmployee: employee.Id, EmployeeName: employee.Name });

            state.entities[nthReportDebitNote] = entitySetEmployee;

            return { ...state };

        default:
            return state;
    }
}

function _updateBalTotal(entity) {
    const balTotal = parseFloat(entity.Bal1 ?? "0")
        + parseFloat(entity.Bal2 ?? "0")
        + parseFloat(entity.Bal3 ?? "0")
        + parseFloat(entity.Bal4 ?? "0")

    entity.BalTotal = balTotal ? balTotal.toFixed(2) : '';
}

export default reportDebitNoteReducer;

