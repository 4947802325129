import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Moment from 'moment';
import _ from 'lodash';

import { openPdf } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";

import Button from '@material-ui/core/Button';

import { BranchSelection, TeamSelection, CalMonthSelection } from '../../Selections';

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
  frmFilter: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& th": {
      fontWeight: 'bold',
      border: "1px solid #B0B0B0",
      backgroundColor: "#FAF3DE",
      padding: "2px 4px",
      textAlign: 'center',
    },

    "& td": {
      border: "1px solid #B0B0B0",
      padding: "2px 4px",
    },

    "& label": {
      marginBottom: "0px",
    }
  },
});

export default function ReportCommReduced() {
  const classes = useStyles();

  const history = useHistory();

  const [state, setState] = useState({
    calMonth: Moment.utc().add(-1, "M").format("YYYY-MM-01"),
  });

  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  const print = () => {
    let filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    openPdf("/api/ReportProvision/GetPDF", {
      filter: JSON.stringify(filter)
    }, msgState, msgSetState);
  }

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/report">
          <Typography variant="h1" className={classes.title}>7. 報告</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>7.12. Provision Commission Paid</Typography>
      </Breadcrumbs>

      <div style={{ width: '400px' }}>
        <table className={classes.frmFilter}>
          <tbody>
            <tr>
              <th>月　　份</th>
              <td>
                <CalMonthSelection value={state.calMonth} onChange={(e) => { setState({ ...state, calMonth: e.target.value }); }} tabIndex={3} />
              </td>
            </tr>
          </tbody >
        </table >

        <div className="mt-2 mb-3" style={{ textAlign: "right" }}>
          <Button size="small" variant="contained" disableElevation onClick={print}>列　　印</Button>
        </div>

        <p>
          ※ 假如經理沒有出現在某分行之上，請檢查該經理在 <u>2.員工資料</u> 中，有沒有設定該分行的 <u>員工過往記錄</u>；及 <u>3.主管資料</u> 中，有沒有設定該分行為 <u>屬下分行</u>。
        </p>
      </div >

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
    </>
  );
}
