import Moment from 'moment';

export default class Employee {
  constructor({
    Id,
    IdBranch,
    Branch,
    IdTeam,
    Team,
    Lv,
    Role,
    IdRank,
    Rank,
    DateJoin,
    DateLeave,
    RptDateJoin,
    RptDateLeave,
    Name,
    Alias,
    Hkid,
    AdLogin,
    IsActive,
    IsLeaved,
    HasNotReceived,
    Display,
  }) {
    this.Id = Id;
    this.IdBranch = IdBranch;
    this.Branch = Branch;
    this.IdTeam = IdTeam;
    this.Team = Team;
    this.Lv = Lv;
    this.Role = Role;
    this.IdRank = IdRank;
    this.Rank = Rank;
    this.DateJoin = DateJoin;
    this.DateLeave = DateLeave;
    this.RptDateJoin = RptDateJoin;
    this.RptDateLeave = RptDateLeave;
    this.Name = Name;
    this.Alias = Alias;
    this.Hkid = Hkid;
    this.AdLogin = AdLogin;
    this.IsActive = IsActive;
    this.IsLeaved = IsLeaved;
    this.HasNotReceived = HasNotReceived;
    this.Display = Display;
  }

  isNew() {
    return !this.Id || (this.Id == "0") || (this.Id.toString().toUpperCase() == "NEW");
  }

  getDesc() {
    if (this.isNew()) return "新同事";
    return `${this.Id}. ${this.Name ? this.Name : "[姓名]"} (${this.Alias ? this.Alias : "[別名]"})`;
  }

  getStatus() {
    if (!this.DateLeave || Moment(this.DateLeave).isAfter(new Moment.utc())) return "__IS_ON_THE_JOB__";

    // isLeaved

    return this.HasNotReceived ? "__IS_LEAVED_AND_HAS_NOT_RECEIVED__" : "__IS_LEAVED_AND_SETTLED__";
  }

  getSelectionName() {
    const isOnTheJob = (!this.DateLeave || Moment(this.DateLeave).isAfter(new Moment.utc()));
    return isOnTheJob ? `${this.Name} - ${this.Branch}` : `${this.Name} - ${this.Branch} (已離職)`;
  }
}
