import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { commTranBonusDetailUpdate } from '../../redux/vCommTranBonuReducer';


export default function CommBonusShareDetailNumberField({ commShareIndex, commBonusShareIndex, fieldName, width, tabIndex, textAlign }) {
    const dispatch = useDispatch();
    const { workingEntity } = useSelector(state => {
        return state.vCommTranBonus;
    });

    const commBonusShares = workingEntity.commShares_CommBonusShares[commShareIndex].commBonusShares[commBonusShareIndex];
    return <input value={commBonusShares[fieldName] ? commBonusShares[fieldName]: ""} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(commTranBonusDetailUpdate(commShareIndex, commBonusShareIndex, fieldName, e.target.value)); }} />;
}