import Moment from 'moment';
import Intl from 'intl';
import 'intl/locale-data/jsonp/zh-Hant-HK';

export default class VCommTranBonus {
    constructor({
        Id,
        DateDeposit,
        IdComm,
        RefNo,
        Lot,
        StreetChi,
        BldgChi,
        Block,
        Floor,
        Flat,
        IdCommTran,
        AmountTran,
        DateTran,
        EditDate,
        EditTime,
        EditUser,
        commShares_CommBonusShares,
    }) {
        this.Id = Id;
        this.DateDeposit = DateDeposit;
        this.IdComm = IdComm;
        this.RefNo = RefNo;
        this.Lot = Lot;
        this.StreetChi = StreetChi;
        this.BldgChi = BldgChi;
        this.Block = Block;
        this.Floor = Floor;
        this.Flat = Flat;
        this.IdCommTran = IdCommTran;
        this.AmountTran = AmountTran;
        this.DateTran = DateTran;
        this.EditDate = EditDate;
        this.EditTime = EditTime;
        this.EditUser = EditUser;
        this.commShares_CommBonusShares = commShares_CommBonusShares;
    }

    isNew() {
        return (this.Id == undefined) || (this.Id < 1) || (this.Id.toString().toUpperCase() == "NEW");
    }

    getDesc() {
        if (this.isNew()) return "新獎金分成";

        const strAmount = this.AmountTran > 0 ? " 獎金 $" + new Intl.NumberFormat().format(this.AmountTran) : "";
        const strDate = this.DateTran ? Moment(this.DateTran).format('DD/MM/YYYY') : "";

        return `物業編號 ${this.RefNo}${strAmount} (${strDate} 收)`;
    }

}
