import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import cfg from '../../../config.json';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'reactstrap';

import { openPdf } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";

import ReportCommSupervisorOption from './ReportCommSupervisorOption';
import ReportCommSupervisorList from './ReportCommSupervisorList';

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
});

export default function ReportCommSupervisor() {
  const classes = useStyles();

  const history = useHistory();

  const [filterState, filterSetState] = useState({ calMonth: Moment.utc().add(-1, 'M').format("YYYY-MM-01"), fDate: "", tDate: Moment.utc().add(-1, 'M').endOf('month').format("YYYY-MM-DD"), isHideNoReceivedAndNoBalance: "__TRUE__" });
  const [listState, listSetState] = useState({ vSupervisors: [], idEmployee: "", name: "", isPrints: [] });
  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/SupervisorTeam/GetFirstFdate`;

    axios.post(apiEndpoint, null, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          filterSetState({ ...filterState, fDate: response.data });
        }
      })
      .catch();
  }, []);

  const printSupervisors = (supervisorIds, employeeIds) => {
    const params = {
      ...filterState,
      supervisorIds: supervisorIds,
      employeeIds: employeeIds,
    };

    openPdf("/api/ReportCommSupervisor/GetPDF", params, msgState, msgSetState);
  }

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/report">
          <Typography variant="h1" className={classes.title}>7. 報告</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>7.2. 主管佣金報告</Typography>
      </Breadcrumbs>

      <ReportCommSupervisorOption state={filterState} setState={filterSetState} />
      <ReportCommSupervisorList filter={filterState} state={listState} setState={listSetState} printSupervisors={printSupervisors} />

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
    </>
  );
}
