import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ReactMoment from "react-moment";
import { Link } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles({
  tblList: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& thead": {
      backgroundColor: "#c5e2ff",
      color: "#45525c",
    },

    //"& tr": {
    //    cursor: "pointer",
    //},

    "& tr.alt": {
      backgroundColor: "#f7fbff",
    },

    "& tr.lastWorkingEntity": {
      border: "2px solid #da59da",
    },

    "& tbody tr:hover, tbody tr.alt:hover": {
      backgroundColor: "#ffffcc !important",
    },

    "& th": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },
  },
});

export default function ReportCommList({ state, setState, printSupervisors }) {
  const classes = useStyles();

  const { vSupervisors } = useSelector(state => {
    return state.selections
  });

  //console.log('vSupervisors', vSupervisors);

  // init
  useEffect(() => {
    // 要 load 晒
    if (_.isEmpty(vSupervisors)) return;

    setState({
      ...state,
      vSupervisors: vSupervisors,
      isPrints: vSupervisors.map(s => { return { id: s.Id, idEmployee: s.IdEmployee, value: false }; })
    })
  }, [vSupervisors]);

  const changeFilter = ({ idEmployee: _idEmployee, name: _name }) => {
    const idEmployee = !_.isNil(_idEmployee) ? _idEmployee : state.idEmployee;
    const name = !_.isNil(_name) ? _name : state.name;

    const _vSupervisors = _.filter(vSupervisors, function (s) {
      let visible = true;
      visible &= (idEmployee ? s.IdEmployee.toString().startsWith(idEmployee.toString()) : true);
      visible &= (name ? s.Name.toUpperCase().includes(name.toUpperCase()) : true);
      return visible;
    });
    //console.log(_vSupervisors);

    const oldIsPrints = [...state.isPrints];
    const isPrints = _vSupervisors.map(s => { return { id: s.Id, value: false }; });
    _.forEach(isPrints, function (isPrint) {
      if (!_.find(oldIsPrints, function (oldIsPrint) {
        return oldIsPrint.value && oldIsPrint.Id == isPrint.id;
      })) return;

      isPrint.value = true;
    });

    setState({
      ...state,
      vSupervisors: _vSupervisors,
      idEmployee,
      name,
      isPrints
    });
  }

  const handleChecked = (vSupervisor) => {
    const i = _.findIndex(state.isPrints, function (_isPrint) { return _isPrint.Id == vSupervisor.Id; });
    state.isPrints[i].value = !state.isPrints[i].value;
    setState({ ...state });
  }

  const printChecked = () => {
    const isPrintIdSupervisors = _.filter(state.isPrints, function (isPrint) {
      return isPrint.value;
    }).map(isPrint => isPrint.id);

    const isPrintIdEmployees = _.filter(state.isPrints, function (isPrint) {
      return isPrint.value;
    }).map(isPrint => isPrint.idEmployee);

    printSupervisors(isPrintIdSupervisors, isPrintIdEmployees);
  }

  const checkAll = () => {
    setState({
      ...state,
      isPrints: vSupervisors.map(s => { return { id: s.Id, idEmployee: s.IdEmployee, value: true }; })
    })
  }

  const clearIsPrints = () => {
    _.forEach(state.isPrints, function (isPrint) { isPrint.value = false });
    setState({ ...state });
  }

  const hasChecked = _.findIndex(state.isPrints, function (isPrint) { return isPrint.value; }) >= 0;

  return (
    <table className={classes.tblList} >
      <thead>
        <tr>
          <th>員工編號 <input style={{ width: '50px' }} value={state.idEmployee} onChange={(e) => { changeFilter({ idEmployee: e.target.value }); }} /></th>
          <th>姓名 <input style={{ width: '120px' }} value={state.name} onChange={(e) => { changeFilter({ name: e.target.value }); }} /></th>
          <th>開始日期</th>
          <th>結束日期</th>
          <th width="200">
            {hasChecked && <Button className="mr-1" size="small" variant="contained" onClick={printChecked} >列印已選取</Button>}
            {hasChecked && <Button size="small" variant="contained" onClick={clearIsPrints}>清　　除</Button>}
            {!hasChecked && <Button size="small" variant="contained" onClick={checkAll}>全　　選</Button>}
          </th>
        </tr>
      </thead>

      <tbody>
        {
          _.isEmpty(state.vSupervisors)
            ? <tr>
              <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>沒有符合的記錄</em></td>
            </tr>
            : state.vSupervisors.map((vSupervisor, index) => {
              return <tr key={vSupervisor.Id} className={index % 2 == 1 ? "alt" : "normal"}>
                <td><Link to={`/employeeDetail/${vSupervisor.IdEmployee}`}>{vSupervisor.IdEmployee}</Link></td>
                <td><Link to={`/supervisorDetail/${vSupervisor.Id}`}>{vSupervisor.Name}</Link></td>
                <td>{vSupervisor.Fdate ? <ReactMoment format="YYYY-MM-DD" date={vSupervisor.Fdate} /> : "---"}</td>
                <td>{vSupervisor.Tdate ? <ReactMoment style={{ color: 'red' }} format="YYYY-MM-DD" date={vSupervisor.Tdate} /> : "--- 應用中 ---"}</td>
                <td>
                  <Checkbox size="small" checked={state.isPrints[index]?.value} onChange={(e) => { handleChecked(vSupervisor) }} />
                  <IconButton size="small" onClick={(e) => { printSupervisors([vSupervisor.Id], [vSupervisor.IdEmployee]); }}><PrintIcon /></IconButton>
                </td>
              </tr>
            })
        }
      </tbody>
    </table>
  );
}

function MyArrowIcon({ direction }) {
  switch (direction) {
    case "ASC": return <ArrowDropUpIcon />;
    case "DESC": return <ArrowDropDownIcon />;
    default: return null;
  }
}
