import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Moment from 'moment';
import clsx from 'clsx';

import { commDetail, commDetailUpdate } from '../../redux/commReducer';
import { solicitorsRefLookupInfo } from '../../redux/solicitorsRefReducer';
import { keyDownHandler } from '../../extensions/FormExtensions';

import CommDetailInputField from './CommDetailInputField';
import CommDetailTextArea from './CommDetailTextArea';
import CommDetailBottomButtons from './CommDetailBottomButtons';
import MyDateField from '../MyDateField';

import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import BeatLoader from "react-spinners/BeatLoader";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import ModalLookupInfoes from '../solicitorsRef/ModalLookupInfoes';

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },

  },
  fieldSet: {
    border: "1px solid #c0c0c0",
    padding: "0px 5px 5px",
    backgroundColor: "#FFFFFF",
    lineHeight: '13px',

    '& legend': {
      width: 'auto',
    },

    "& table": {
      width: "100%",

      "& th, & td": {
        padding: "2px 6px",
        border: "1px solid #D5D5D5",
      },

      "& th": {
        backgroundColor: "#FAF3DE",
        textAlign: 'right',
      },
    },
  }
});

export default function CommDetail2() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, workingEntity, entities } = useSelector(state => {
    return state.comm;
  });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  const [prevId, setPrevId] = useState(null);
  const [nextId, setNextId] = useState(null);

  const { id } = useParams();
  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");

    dispatch(commDetail(id));

    const pos = entities && (entities.length > 1) && (_.findIndex(entities, function (e) { return e.Id == id; }));
    setPrevId((pos > 0) ? entities[pos - 1].Id : null);
    setNextId((pos < entities.length - 1) ? entities[pos + 1].Id : null);
  }, [id]);

  const navClicked = (path) => {
    history.push(path);
  };

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/comm/list">
          <Typography variant="h1" className={classes.title}>1. 成交佣金</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : workingEntity !== null ? workingEntity.getDesc() + "（下版）" : ""}</Typography>
      </Breadcrumbs>
      {workingEntity &&
        <div className="tabIndexedForm" onKeyDown={(e) => { keyDownHandler(e); }}>
          <Grid container>
            <Button variant="contained" className="mr-4" size="small" disableElevation onClick={() => { navClicked("/comm"); }}>返回列表</Button>
            <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { navClicked(`/commDetail/${id}`); }}>佣金詳情（上版）</Button>
            <Button variant="contained" className="mr-2" size="small" disableElevation disabled ><span style={{ color: 'rgb(223 0 183)' }}>佣金詳情（下版）</span></Button>
            <div style={{ flex: 1 }} />
            {prevId && <Button className="mr-2" variant="contained" size="small" disableElevation onClick={() => { history.push(`/commDetail2/${prevId}`); }}><NavigateBeforeIcon /></Button>}
            <Button className="mr-2" variant="contained" size="small" disableElevation onClick={() => { dispatch(commDetail(id, true)); }}><RefreshIcon /></Button>
            {nextId && <Button variant="contained" size="small" disableElevation onClick={() => { history.push(`/commDetail2/${nextId}`); }}><NavigateNextIcon /></Button>}
          </Grid>

          <FieldSetOwnerInfo />
          <FieldSetBuyerInfo />
          <FieldSetRemarks />
          <CommDetailBottomButtons />

          <ModalLookupInfoes />
        </div>
      }
    </>
  );
}

function FieldSetOwnerInfo() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { workingEntity } = useSelector(state => {
    return state.comm;
  });

  return <fieldset className={clsx(classes.fieldSet, { "mt-3": true })}>
    <legend>業主資料</legend>
    <table>
      <tbody>
        <tr>
          <th>業主地址</th>
          <td>
            <CommDetailInputField fieldName="OwnerAddr" tabIndex={1} width={300} />
            <div style={{ height: '2px' }} />
            <CommDetailInputField fieldName="OwnerAddr2" tabIndex={2} width={300} />
          </td>
          <th>業主郵寄地址</th>
          <td colSpan={5}>
            <CommDetailInputField fieldName="OwnerMailingAddr" tabIndex={3} width={300} />
            <div style={{ height: '2px' }} />
            <CommDetailInputField fieldName="OwnerMailingAddr2" tabIndex={4} width={300} />
          </td>
        </tr>
        <tr>
          <th>業主(英)</th>
          <td>
            <CommDetailInputField fieldName="OwnerEng" tabIndex={5} width={300} />
          </td>
          <th>業主郵寄收件人</th>
          <td colSpan={5}>
            <CommDetailInputField fieldName="OwnerMailingReceiver" tabIndex={6} width={300} />
          </td>
        </tr>
        <tr>
          <th>業主(中)</th>
          <td>
            <CommDetailInputField fieldName="OwnerChi" tabIndex={7} width={150} />
          </td>
          <th>業主 ID#</th>
          <td>
            <CommDetailInputField fieldName="OwnerHkid" tabIndex={8} width={150} />
          </td>
          <th>Email(收據用)</th>
          <td colSpan={3}>
            <CommDetailInputField fieldName="OwnerEmail" tabIndex={9} width={150} />
          </td>
        </tr>
        <tr>
          <th>業主聯絡人</th>
          <td>
            <CommDetailInputField fieldName="OwnerContact" tabIndex={10} width={150} />
          </td>
          <th>聯絡人身份</th>
          <td>
            <CommDetailInputField fieldName="OwnerContactRelation" tabIndex={11} width={150} />
          </td>
          <th>Email(上數用)</th>
          <td colSpan={3}>
            <CommDetailInputField fieldName="OwnerEmailForm" tabIndex={12} width={150} />
          </td>
        </tr>
        <tr>
          <th>業主電話</th>
          <td>
            <CommDetailInputField fieldName="OwnerTel" tabIndex={13} width={150} />
          </td>
          <th>業主手提</th>
          <td>
            <CommDetailInputField fieldName="OwnerTelMobile" tabIndex={14} width={150} />
          </td>
          <th>其他電話</th>
          <td colSpan={3}>
            <CommDetailInputField fieldName="OwnerTelOther" tabIndex={15} width={150} />
          </td>
        </tr>
        <tr>
          <th>業主傳呼機</th>
          <td>
            <CommDetailInputField fieldName="OwnerTelPager" tabIndex={16} width={150} />
          </td>
          <th>電話2</th>
          <td>
            <CommDetailInputField fieldName="OwnerTel2" tabIndex={17} width={150} />
          </td>
          <th>業主 Fax</th>
          <td colSpan={3}>
            <CommDetailInputField fieldName="OwnerTelFax" tabIndex={18} width={150} />
          </td>
        </tr>
      </tbody>
    </table>

    <TblOwnerSolicitor />

  </fieldset>;
}

function TblOwnerSolicitor() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { workingEntity } = useSelector(state => {
    return state.comm;
  });

  const lookupSolicitors = (fieldName) => {
    if (!workingEntity[fieldName]) return;

    dispatch(solicitorsRefLookupInfo(fieldName));
  };

  return <table className="mt-2">
    <tbody>
      <tr>
        <th>律師樓(英)</th>
        <td colSpan={3}>
          <CommDetailInputField fieldName="OwnerSolicitorsEng" tabIndex={21} width={300} />
          <input type="button" value="..." onClick={(_) => { lookupSolicitors('OwnerSolicitorsEng'); }} />
        </td>
        <th>律師樓(中)</th><td>
          <CommDetailInputField fieldName="OwnerSolicitorsChi" tabIndex={22} width={150} />
          <input type="button" value="..." onClick={(_) => { lookupSolicitors('OwnerSolicitorsChi'); }} />
        </td>
      </tr>
      <tr>
        <th>律師(英)</th>
        <td colSpan={3}>
          <CommDetailInputField fieldName="OwnerLawyerEng" tabIndex={23} width={300} />
          <input type="button" value="..." onClick={(_) => { lookupSolicitors('OwnerLawyerEng'); }} />
        </td>
        <th>律師(中)</th>
        <td>
          <CommDetailInputField fieldName="OwnerLawyerChi" tabIndex={24} width={150} />
          <input type="button" value="..." onClick={(_) => { lookupSolicitors('OwnerLawyerChi'); }} />
        </td>
      </tr>
      <tr>
        <th>律師電話</th>
        <td>
          <CommDetailInputField fieldName="OwnerLawyerTel" tabIndex={25} width={150} />
          <input type="button" value="..." onClick={(_) => { lookupSolicitors('OwnerLawyerTel'); }} />
        </td>
        <th>律師 Fax</th>
        <td><CommDetailInputField fieldName="OwnerLawyerFax" tabIndex={26} width={150} /></td>
        <td colSpan={2}>&nbsp;</td>
      </tr>
    </tbody>
  </table>;
}

function FieldSetBuyerInfo() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { workingEntity } = useSelector(state => {
    return state.comm;
  });

  return <fieldset className={clsx(classes.fieldSet, { "mt-3": true })}>
    <legend>買方/租客資料</legend>
    <table>
      <tbody>
        <tr>
          <th>買方/租客地址</th>
          <td>
            <CommDetailInputField fieldName="BuyerAddr" tabIndex={51} width={300} />
            <div style={{ height: '2px' }} />
            <CommDetailInputField fieldName="BuyerAddr2" tabIndex={52} width={300} />
          </td>
          <th>買方/租客郵寄地址</th>
          <td colSpan={5}>
            <CommDetailInputField fieldName="BuyerMailingAddr" tabIndex={53} width={300} />
            <div style={{ height: '2px' }} />
            <CommDetailInputField fieldName="BuyerMailingAddr2" tabIndex={54} width={300} />
          </td>
        </tr>
        <tr>
          <th>買方/租客(英)</th>
          <td>
            <CommDetailInputField fieldName="BuyerEng" tabIndex={55} width={300} />
          </td>
          <th>買方/租客郵寄收件人</th>
          <td colSpan={5}>
            <CommDetailInputField fieldName="BuyerMailingReceiver" tabIndex={56} width={300} />
          </td>
        </tr>
        <tr>
          <th>買方/租客(中)</th>
          <td>
            <CommDetailInputField fieldName="BuyerChi" tabIndex={57} width={150} />
          </td>
          <th>買方/租客 ID#</th>
          <td>
            <CommDetailInputField fieldName="BuyerHkid" tabIndex={58} width={150} />
          </td>
          <th>Email(收據用)</th>
          <td colSpan={3}>
            <CommDetailInputField fieldName="BuyerEmail" tabIndex={59} width={150} />
          </td>
        </tr>
        <tr>
          <th>買方/租客聯絡人</th>
          <td>
            <CommDetailInputField fieldName="BuyerContact" tabIndex={60} width={150} />
          </td>
          <th>聯絡人身份</th>
          <td>
            <CommDetailInputField fieldName="BuyerContactRelation" tabIndex={61} width={150} />
          </td>
          <th>Email(上數用)</th>
          <td colSpan={3}>
            <CommDetailInputField fieldName="BuyerEmailForm" tabIndex={62} width={150} />
          </td>
        </tr>
        <tr>
          <th>買方/租客電話</th>
          <td>
            <CommDetailInputField fieldName="BuyerTel" tabIndex={63} width={150} />
          </td>
          <th>買方/租客手提</th>
          <td>
            <CommDetailInputField fieldName="BuyerTelMobile" tabIndex={64} width={150} />
          </td>
          <th>其他電話</th>
          <td colSpan={3}>
            <CommDetailInputField fieldName="BuyerTelOther" tabIndex={65} width={150} />
          </td>
        </tr>
        <tr>
          <th>買方/租客傳呼機</th>
          <td>
            <CommDetailInputField fieldName="BuyerTelPager" tabIndex={66} width={150} />
          </td>
          <th>電話2</th>
          <td>
            <CommDetailInputField fieldName="BuyerTel2" tabIndex={67} width={150} />
          </td>
          <th>買方/租客 Fax</th>
          <td colSpan={3}>
            <CommDetailInputField fieldName="BuyerTelFax" tabIndex={68} width={150} />
          </td>
        </tr>
      </tbody>
    </table>

    <TblBuyerSolicitor />
  </fieldset>;
}

function TblBuyerSolicitor() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { workingEntity } = useSelector(state => {
    return state.comm;
  });

  const lookupSolicitors = (fieldName) => {
    if (!workingEntity[fieldName]) return;

    dispatch(solicitorsRefLookupInfo(fieldName));
  };

  return <table className="mt-2">
    <tbody>
      <tr>
        <th>律師樓(英)</th>
        <td colSpan={3}>
          <CommDetailInputField fieldName="BuyerSolicitorsEng" tabIndex={70} width={300} />
          <input type="button" value="..." onClick={(_) => { lookupSolicitors('BuyerSolicitorsEng'); }} />
        </td>
        <th>律師樓(中)</th><td>
          <CommDetailInputField fieldName="BuyerSolicitorsChi" tabIndex={71} width={150} />
          <input type="button" value="..." onClick={(_) => { lookupSolicitors('BuyerSolicitorsChi'); }} />
        </td>
      </tr>
      <tr>
        <th>律師(英)</th>
        <td colSpan={3}>
          <CommDetailInputField fieldName="BuyerLawyerEng" tabIndex={72} width={300} />
          <input type="button" value="..." onClick={(_) => { lookupSolicitors('BuyerLawyerEng'); }} />
        </td>
        <th>律師(中)</th>
        <td>
          <CommDetailInputField fieldName="BuyerLawyerChi" tabIndex={73} width={150} />
          <input type="button" value="..." onClick={(_) => { lookupSolicitors('BuyerLawyerChi'); }} />
        </td>
      </tr>
      <tr>
        <th>律師電話</th>
        <td>
          <CommDetailInputField fieldName="BuyerLawyerTel" tabIndex={74} width={150} />
          <input type="button" value="..." onClick={(_) => { lookupSolicitors('BuyerLawyerTel'); }} />
        </td>
        <th>律師 Fax</th>
        <td><CommDetailInputField fieldName="BuyerLawyerFax" tabIndex={75} width={150} /></td>
        <td colSpan={2}>&nbsp;</td>
      </tr>
    </tbody>
  </table>;
}

function FieldSetRemarks() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { workingEntity } = useSelector(state => {
    return state.comm;
  });

  return <fieldset className={clsx(classes.fieldSet, { "mt-3": true })}>
    <legend>備註</legend>
    <table>
      <tbody>
        <tr>
          <th>備註</th>
          <td colSpan={3}>
            <CommDetailTextArea fieldName="Remarks" tabIndex={100} width={400} minRows={5} />
          </td>
          <th>到期日</th>
          <td colSpan={3} style={{ width: '150px' }}>
            成交日 {workingEntity.DateConclude && <b>{Moment(workingEntity.DateConclude).format('DD/MM/YYYY')} </b>}加 <CommDetailInputField fieldName="OffsetDateDue" tabIndex={104} width={30} /> 日
            <div style={{ height: '5px' }} />
            <b>或</b> 日期: <MyDateField dateValue={workingEntity.DateDue} onDateChange={(d) => { dispatch(commDetailUpdate('DateDue', d)); }} tabIndex={105} />
          </td>
        </tr>
      </tbody>
    </table>
    <div style={{ height: '20px' }} />
    <table>
      <tbody>
        <tr>
          <th>備註(律師)</th>
          <td colSpan={7}>
            <CommDetailTextArea fieldName="RemarksLawyer" tabIndex={101} width={400} minRows={2} />
          </td>
        </tr>
        <tr>
          <th>備註(銀行)</th>
          <td colSpan={7}>
            <CommDetailTextArea fieldName="RemarksBank" tabIndex={102} width={400} minRows={2} />
          </td>
        </tr>
        <tr>
          <th>備註(裝修)</th>
          <td colSpan={7}>
            <CommDetailTextArea fieldName="RemarksDeco" tabIndex={103} width={400} minRows={2} />
          </td>
        </tr>
      </tbody>
    </table>
  </fieldset>;
}
