import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { vCommDueFilterUpdate, vCommDueFilterClear, vCommDueLookup } from '../../redux/vCommDueReducer';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import MyDateField from '../MyDateField';

const useStyles = makeStyles({
    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& input, & select": {
            padding: "0px 1px",
            fontSize: "13px",
        },

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },

        "& label": {
            margin: 0,
        }
    },
});

export default function VCommDueFilter({ filter }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const filters = JSON.parse(filter.filter);

    const isPaidFilter = _.find(filters, function (o) { return o.property == "isPaid" });
    const isPaidValue = isPaidFilter ? isPaidFilter.value : "";

    const classFilter = _.find(filters, function (o) { return o.property == "class" });
    const classValue = classFilter ? classFilter.value : "";

    const _getFilterValue = (field) => {
        const i = _.findIndex(filters, function (o) { return o.property == field; });
        return (i >= 0) ? filters[i].value : "";
    };

    const filterChange = (field, value) => {
        dispatch(vCommDueFilterUpdate(field, value));
    };

    const clearFilter = () => {
        dispatch(vCommDueFilterClear());
    };

    const lookup = () => {
        dispatch(vCommDueLookup(filter));
    }

    return (
        <div style={{ width: '800px' }}>
            <table className={`${classes.frmFilter} mb-2`}>
                <tbody>
                    <tr>
                        <th>物業編號</th>
                        <td><input onChange={(e) => { filterChange('l_ref_no', e.target.value); }} value={_getFilterValue('l_ref_no')} style={{ width: '100px' }} tabIndex={1} /></td>
                        <th>成交日期：</th>
                        <td><MyDateField dateValue={_getFilterValue("t_date_conclude")} onDateChange={(d) => { filterChange('t_date_conclude', d); }} tabIndex={2} /></td>
                        <th>到期日期：</th>
                        <td><MyDateField dateValue={_getFilterValue("t_date_due")} onDateChange={(d) => { filterChange('t_date_due', d); }} tabIndex={2} /></td>
                    </tr>
                    <tr>
                        <th>是否付款：</th>
                        <td>
                            <VCommDueFilterRadioField selectedValue={isPaidValue} fieldName="isPaid" label="全部" value="__ANY__" />
                            <VCommDueFilterRadioField selectedValue={isPaidValue} fieldName="isPaid" label="已付清" value="1" />
                            <VCommDueFilterRadioField selectedValue={isPaidValue} fieldName="isPaid" label="尚欠款" value="0" />
                        </td>
                        <th>種　　類：</th>
                        <td colSpan="3">
                            <VCommDueFilterRadioField selectedValue={classValue} fieldName="class" label="全部" value="__ANY__" />
                            <VCommDueFilterRadioField selectedValue={classValue} fieldName="class" label="二手" value="SECOND_HAND" />
                            <VCommDueFilterRadioField selectedValue={classValue} fieldName="class" label="特殊" value="SPECIAL" />
                            <VCommDueFilterRadioField selectedValue={classValue} fieldName="class" label="未指定" value="__NULL__" />
                        </td>
                    </tr>
                    <tr>
                        <th>合約日期：</th>
                        <td colSpan="5">
                            由
                            &nbsp;
                            <MyDateField dateValue={_getFilterValue("f_date_contract")} onDateChange={(d) => { filterChange('f_date_contract', d); }} tabIndex={2} />
                            &nbsp;
                            至
                            &nbsp;
                            <MyDateField dateValue={_getFilterValue("t_date_contract")} onDateChange={(d) => { filterChange('t_date_contract', d); }} tabIndex={2} />
                        </td>
                    </tr>
                </tbody>
            </table>
            <Grid container justifyContent="flex-end" spacing={2} className="mb-2">
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { lookup(); }}>搜　尋</Button>
                </Grid>
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { clearFilter(); }}>清　除</Button>
                </Grid>
            </Grid>
        </div>
    );
}

function VCommDueFilterRadioField({ fieldName, label, value, selectedValue }) {
    const dispatch = useDispatch();
    const name = `filter_${fieldName}`;
    const id = `${name}_${value}`;
    return <span className="mr-2" >
        <input type="radio" value={value} id={id} name={name} onChange={e => { dispatch(vCommDueFilterUpdate(fieldName, value)); }} checked={selectedValue == value} />
        <label htmlFor={id}>{label}</label>
    </span>;
}
