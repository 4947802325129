import React from 'react';

import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

export const BtnIsConfirmed = ({ isConfirmed, handler, className }) => {
    const label = isConfirmed ? "資料已齊" : "預覽 (資料有可能改變)";
    const color = isConfirmed ? "primary" : "secondary";
    const icon = isConfirmed ? <DoneIcon /> : <HourglassEmptyIcon />;

    return <Chip
        className={className}
        label={label}
        clickable={handler !== null}
        color={color}
        onClick={handler}
        icon={icon}
    />;
}