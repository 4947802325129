import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { teamChangeSort, updateSeq } from '../../redux/teamReducer';

import BeatLoader from "react-spinners/BeatLoader";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles({
  tblList: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& thead": {
      backgroundColor: "#c5e2ff",
      color: "#45525c",
    },

    "& tr": {
      cursor: "pointer",
    },

    "& tr.alt": {
      backgroundColor: "#f7fbff",
    },

    "& tr.lastWorkingEntity": {
      border: "2px solid #da59da",
    },

    "& tbody tr:hover, tbody tr.alt:hover": {
      backgroundColor: "#ffffcc !important",
    },

    "& th": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },

    "& tr.breakdown": {
      borderLeft: '1px solid #B0B0B0',
      borderRight: '1px solid #B0B0B0',

      "& td": {
        border: 'none',
        backgroundColor: '#dddddd',
      }
    },
  },
});

export default function TeamList() {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading, filter, entities, workingEntityId, errors } = useSelector(state => {
    return state.team;
  });

  const { dfnDistrictManagers } = useSelector(state => state.selections);

  const { page, qtyPerPage } = filter;

  const sorts = JSON.parse(filter.sort);

  const _getSortDirection = (field) => {
    const i = _.findIndex(sorts, function (o) { return o.property == field; });
    return (i >= 0) ? sorts[i].direction : "";
  };

  const changeSort = (field) => {
    dispatch(teamChangeSort(field, filter));
  };

  const showDetail = (id) => {
    history.push(`/teamDetail/${id}`);
  }

  const myRef = useRef(null);
  useEffect(() => {
    if (myRef.current) myRef.current.scrollIntoView({ block: "center" });
  }, []);

  return (
    <table className={classes.tblList} >
      <thead>
        <tr>
          <th>行</th>
          <th onClick={(_) => changeSort('id')}><span>編號</span><MyArrowIcon direction={_getSortDirection("id")} /></th>
          <th onClick={(_) => changeSort('role')}><span>身分</span><MyArrowIcon direction={_getSortDirection("role")} /></th>
          <th onClick={(_) => changeSort('team')}><span>組別</span><MyArrowIcon direction={_getSortDirection("team")} /></th>
          <th width="170" onClick={(_) => changeSort('seq')}><span>排序</span><MyArrowIcon direction={_getSortDirection("seq")} /></th>
          <th width="170" onClick={(_) => changeSort('lv')}><span>權限</span><MyArrowIcon direction={_getSortDirection("lv")} /></th>
          <th>有效</th>
        </tr>
      </thead>

      {isLoading
        ?
        <tbody>
          <tr>
            <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
          </tr>
        </tbody>
        :
        (errors !== "")
          ?
          <tbody>
            <tr>
              <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
            </tr>
          </tbody >
          :

          <tbody>
            {
              (entities.length < 1) && <tr>
                <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>沒有符合的記錄</em></td>
              </tr>
            }
            {
              entities.map((entity, index) => {
                //const ddManager = _.find(dfnDistrictManagers, function (ddm) { return ddm.Id == entity.IdDistrictManager });
                return <React.Fragment key={entity.Id}>
                  <tr ref={workingEntityId == entity.Id ? myRef : null} className={clsx({ "lastWorkingEntity": workingEntityId == entity.Id, "alt": index % 2 == 1, "normal": index % 2 == 0 })} onClick={() => { showDetail(entity.Id) }}>
                    <td>{(page - 1) * qtyPerPage + index + 1}</td>
                    <td>{entity.Id}</td>
                    <td>{entity.Role}</td>
                    <td>{entity.Team1}</td>
                    <td>
                      <BtnSeq index={index} seq={entity.Seq} />
                    </td>
                    <td>{entity.Lv}</td>
                    <td><MySettleIcon Tdate={entity.Tdate} /></td>
                  </tr>
                </React.Fragment>
              })
            }
          </tbody>
      }
    </table>
  );
}

function MyArrowIcon({ direction }) {
  switch (direction) {
    case "ASC": return <ArrowDropUpIcon />;
    case "DESC": return <ArrowDropDownIcon />;
    default: return null;
  }
}

function MySettleIcon({ Tdate }) {
  const now = new Date();
  const today = new Date(now.getYear(), now.getMonth(), now.getDate());
  if (!Tdate || Tdate <= today) return <CheckCircleIcon style={{ color: "#4fa917" }} />;
  return <CancelIcon style={{ color: "#e43e3e" }} />
}

function BtnSeq({ index, seq }) {
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();

  const minusSeq = (e) => {
    e.stopPropagation();
    dispatch(updateSeq(index, -1));
  };

  const addictSeq = (e) => {
    e.stopPropagation();
    dispatch(updateSeq(index, 1));
  }

  return (
    !isEditing
      ? <Button size="small" onClick={(e) => { e.stopPropagation(); setIsEditing(true); }}>{seq}</Button>
      : <>
        <IconButton size="small" onClick={minusSeq}><RemoveCircleIcon /></IconButton>
        <Button size="small" onClick={(e) => { e.stopPropagation(); setIsEditing(false); }}>{seq}</Button>
        <IconButton size="small" onClick={addictSeq}><AddCircleIcon /></IconButton>
      </>
  );
}