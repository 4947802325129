import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { reportCommReceivedFilterUpdate, reportCommReceivedFilterClear, reportCommReceivedLookup } from '../../../redux/reportCommReceivedReducer';

import { openPdf, downloadXls } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";

import MyDateField from '../../MyDateField';
import { SupervisorSelection, BranchSelection } from '../../Selections';

import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import BlockIcon from '@material-ui/icons/Block';
import DescriptionIcon from '@material-ui/icons/Description';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles({
    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },

        "& label": {
            marginBottom: "0px",
        }
    },
});

export default function ReportCommReceivedFilter({ filter }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const filters = JSON.parse(filter.filter);

    const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

    const _getFilterValue = (field) => {
        const i = _.findIndex(filters, function (o) { return o.property == field; });
        return (i >= 0) ? filters[i].value : "";
    };

    const filterChange = (field, value) => {
        dispatch(reportCommReceivedFilterUpdate(field, value));
    };

    const clearFilter = () => {
        dispatch(reportCommReceivedFilterClear());
    };

    const lookup = () => {
        dispatch(reportCommReceivedLookup(filter));
    }

    const print = () => {
        openPdf("/api/ReportCommReceived/GetPDF", filter, msgState, msgSetState);
    }

    const excel = () => {
        downloadXls("/api/ReportCommReceived/GetXlsx", filter, "已收佣金報告.xlsx", msgState, msgSetState);
    }    

    return (
        <div style={{width:'450px'}}>
            <table className={classes.frmFilter}>
                <tbody>
                    <tr>
                        <th style={{ textAlign: 'right', width: '130px' }}>選　　項：</th>
                        <td>
                            <ReportCommReceivedRadioField fieldName="optClass" label="全部" value="__ANY__" selectedValue={_getFilterValue("optClass")} />
                            <ReportCommReceivedRadioField fieldName="optClass" label="分行" value="__BRANCH__" selectedValue={_getFilterValue("optClass")} />
                            <ReportCommReceivedRadioField fieldName="optClass" label="主管" value="__SUPERVISOR__" selectedValue={_getFilterValue("optClass")} />
                        </td>
                    </tr>
                    <tr>
                        <th style={{ textAlign: 'right', width: '130px' }}>入帳日期：</th>
                        <td>
                            <MyDateField dateValue={_getFilterValue("fDate")} onDateChange={(d) => { filterChange('fDate', d); }} tabIndex={4} />
                            &nbsp; 至 &nbsp;
                            <MyDateField dateValue={_getFilterValue("tDate")} onDateChange={(d) => { filterChange('tDate', d); }} tabIndex={5} />
                        </td>
                    </tr>
                    {_getFilterValue("optClass") == "__BRANCH__" &&
                        <tr>
                        <th style={{ textAlign: 'right', width: '130px' }}>分　　行：</th><td><BranchSelection showAny={true} onChange={(e) => { filterChange('idBranch', e.target.value); }} value={_getFilterValue("idBranch")} tabIndex={6} /></td>
                        </tr>
                    }
                    {_getFilterValue("optClass") == "__SUPERVISOR__" &&
                        <tr>
                        <th style={{ textAlign: 'right', width: '130px' }}>經　　理：</th><td><SupervisorSelection showAny={true} onChange={(e) => { filterChange('superviseIdemployee', e.target.value); }} value={_getFilterValue("superviseIdemployee")} tabIndex={7} /></td >
                        </tr>
                    }
                </tbody>
            </table>
            <div className="mt-2 mb-3" style={{textAlign: "right"}}>
                <Button size="small" variant="contained" disableElevation onClick={lookup}>
                    <SearchIcon className="mr-1"/>搜&nbsp;尋</Button>
                &nbsp;&nbsp;
                <Button size="small" variant="contained" disableElevation onClick={clearFilter}>
                    <BlockIcon className="mr-1" />清&nbsp;除</Button>
                &nbsp;&nbsp;
                <Button className="mr-2" size="small" variant="contained" disableElevation onClick={print}>
                    <PrintIcon className="mr-1"/>列&nbsp;印</Button>
                <Button size="small" variant="contained" disableElevation onClick={excel}>
                    <DescriptionIcon className="mr-1" />Excel</Button>
            </div>

            <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
        </div>
    );
}

function ReportCommReceivedRadioField({ fieldName, label, value, selectedValue }) {
    const dispatch = useDispatch();

    const name = `filter_${fieldName}`;
    const id = `${name}_${value}`;
    return <span className="mr-1 ml-1">
        <input className="mr-1" type="radio" value={value} id={id} name={name} onChange={(e) => { dispatch(reportCommReceivedFilterUpdate(fieldName, e.target.value)); }} checked={selectedValue == value} />
        <label htmlFor={id}>{label}</label>
    </span>;
}
