import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';


import { commShareAssocUpdate, detailCommSetVisibleCalTool, detailCommCheckDiff, detailCommShareAssocSetEmployee, detailCommSetVisibleCheckDiff } from '../../redux/commShareAssocReducer';
import { detailCommSetModalEmployee } from '../../redux/selectionsReducer';
import { setRefnosAndParities as setDebitNoteRefnosAndParities } from '../../redux/reportDebitNoteReducer';
import CommDetailInputField from '../comm/CommDetailInputField';
import { EmployeeSelection } from '../Selections';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    fieldSet: {
        border: "1px solid #c0c0c0",
        padding: "0px 5px 5px",
        backgroundColor: "#FFFFFF",
        lineHeight: '13px',

        '& legend': {
            width: 'auto',
        },

        "& table": {
            width: "100%",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
            },

            "& th": {
                backgroundColor: "#FAF3DE",
                textAlign: 'right',
            },
        },
        "& table.commShares th": {
            textAlign: 'center',
        },

        "& table.commShares .hidden": {
            display: "none",
        },

        "& table.commShares tr.row1st td": {
            backgroundColor: '#DBFFB8',
        },
    },
    backdrop: {
        backgroundColor: 'rgb(0, 0, 0, 0.2)',
    },
    paper: {
        width: 320,
        margin: '40px auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: "auto",
    },
    paperEmployees: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        width: 320,
        height: 280,
        marginLeft: -160,
        marginTop: -140,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: "auto",
    },
}));

export default function FieldsetCommDetailShares() {
    const classes = useStyles();

    const dispatch = useDispatch();

    const history = useHistory();

    const { workingEntity } = useSelector(state => {
        return state.comm;
    });

    const printDebitNote = (party) => {
        dispatch(setDebitNoteRefnosAndParities([workingEntity.RefNo], [party]));
        history.push("/reportDebitNote");
    };

    return (
        <>
            <fieldset className={clsx(classes.fieldSet, { "mt-3": true })}>
                <legend>佣金分配</legend>
                <table>
                    <tbody>
                        <tr>
                            <th style={{ width: '120px' }}>業主佣共計</th>
                            <td>
                                $ <CommDetailInputField fieldName="CommOwner" tabIndex={21} width={80} />
                            </td>
                            <th style={{ width: '120px' }}>買家/租客佣共計</th>
                            <td>
                                $ <CommDetailInputField fieldName="CommBuyer" tabIndex={22} width={80} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ height: '10px' }} />
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <TblCommShares baseTabIndex={23} index={0} />
                        {/*  23 + 0 + (1 ~ 6) 六行 x 7 field + (1 ~ 7) */}
                   </Grid>
                    <Grid item xs={6}>
                        <TblCommShares baseTabIndex={23} index={6}/>
                        {/*  23 + 6 + (1 to 6) 六行 x 7 field + (1 ~ 7) */}
                    </Grid>
                </Grid>
                <div style={{ height: '10px' }} />
                <table>
                    <tbody>
                        <tr>
                            <th style={{ width: '120px' }}>公司未收業主佣</th><td>$ { workingEntity.BalOwner }</td>
                            <th style={{ width: '120px' }}>公司未收買家佣</th><td>$ {workingEntity.BalBuyer}</td>
                            <td>
                                <Button className="mr-2" onClick={(_) => { printDebitNote('OWNER'); }} size="small" variant="contained" disableElevation>付款通知單 - 業主</Button>
                                <Button className="mr-2" onClick={(_) => { printDebitNote('BUYER'); }} size="small" variant="contained" disableElevation>付款通知單 - 買家</Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>
            <Grid container className="mt-2" spacing={1}>
                <Grid item><Button variant="contained" size="small" disableElevation onClick={e => { dispatch(detailCommSetVisibleCalTool(true)); }}>佣金計算工具</Button></Grid>
                <Grid item><Button variant="contained" size="small" disableElevation onClick={e => { dispatch(detailCommCheckDiff()); }}>測試佣金分配</Button></Grid>
            </Grid>
        </>
    );

};

function TblCommShares({ baseTabIndex, index }) {
    const { workingEntity } = useSelector(state => {
        return state.comm;
    });

    return (
        <table className="commShares">
            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>負責營業員</th>
                    <th>業主佣 $</th>
                    <th>%</th>
                    <th>買家佣 $</th>
                    <th>%</th>
                    <th className={clsx({ "hidden": workingEntity.Class !== "SPECIAL"})}>預支</th>
                </tr>
            </thead>
            <tbody>
                <CommShareRow baseTabIndex={baseTabIndex} nth={index} />
                <CommShareRow baseTabIndex={baseTabIndex} nth={index + 1} />
                <CommShareRow baseTabIndex={baseTabIndex} nth={index + 2} />
                <CommShareRow baseTabIndex={baseTabIndex} nth={index + 3} />
                <CommShareRow baseTabIndex={baseTabIndex} nth={index + 4} />
                <CommShareRow baseTabIndex={baseTabIndex} nth={index + 5} />
            </tbody>
        </table> 
    );
}

function CommShareRow({ baseTabIndex, nth }) {
    const dispatch = useDispatch();

    const { workingEntity } = useSelector(state => {
        return state.comm;
    });

    const { commShareAssocs } = useSelector(state => {
        return state.commShareAssoc;
    });

    //console.log('commShareAssocs', commShareAssocs);

    const QtyFields = 7;
    const startIndex = baseTabIndex + nth * QtyFields;
    //console.log('startIndex', startIndex);

    return (
        <tr className={clsx({ "row1st": nth == 0 })}>
            <th style={{padding: 0}}>{nth + 1}</th>
            <td>
                <CommShareAssocKeyDownloadInputField nth={nth} fieldName="IdEmployee" width={36} onKeyDown={(e) => { if (e.keyCode !== 17) return; dispatch(detailCommSetModalEmployee(true, nth)); }} />
                <CommShareAssocKeyDownloadInputField nth={nth} fieldName="Name" width={100} tabIndex={startIndex + 2} onKeyDown={(e) => { if (e.keyCode !== 17) return; dispatch(detailCommSetModalEmployee(true, nth)); }} />
                <input type="button" value=".." onClick={(event) => { event.stopPropagation(); dispatch(detailCommSetModalEmployee(true, nth)); }} />
                <input style={{ width: '82px' }} readOnly value={commShareAssocs[nth].Branch||""} />
            </td>
            <td>
                <CommShareAssocInputField nth={nth} fieldName="OwnerComm" width={70} tabIndex={startIndex + 3} textAlign="right" />
            </td>
            <td>
                <CommShareAssocInputField nth={nth} fieldName="OwnerPercent" width={40} tabIndex={startIndex + 4} textAlign="right" />
            </td>
            <td>
                <CommShareAssocInputField nth={nth} fieldName="BuyerComm" width={70} tabIndex={startIndex + 5} textAlign="right"/>
            </td>
            <td>
                <CommShareAssocInputField nth={nth} fieldName="BuyerPercent" width={40} tabIndex={startIndex + 6} textAlign="right" />
            </td>
            <td className={clsx({ "hidden": workingEntity.Class !== "SPECIAL" })}>
                <CommShareAssocInputField nth={nth} fieldName="Prepaid" width={60} tabIndex={startIndex + 7} textAlign="right" />
            </td>
        </tr>
    );
}

function CommShareAssocInputField({ nth, fieldName, width, tabIndex, textAlign }) {
    const dispatch = useDispatch();

    const { workingEntity } = useSelector(state => {
        return state.comm;
    });

    const { commShareAssocs } = useSelector(state => {
        return state.commShareAssoc;
    });

    let value = commShareAssocs[nth][fieldName] ? commShareAssocs[nth][fieldName] : '';

    return <input value={value} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(commShareAssocUpdate(workingEntity, nth, fieldName, e.target.value)); }} />
}

function CommShareAssocKeyDownloadInputField({ nth, fieldName, width, tabIndex, textAlign, onKeyDown }) {
    const dispatch = useDispatch();

    const { workingEntity } = useSelector(state => {
        return state.comm;
    });

    const { commShareAssocs } = useSelector(state => {
        return state.commShareAssoc;
    });

    let value = commShareAssocs[nth][fieldName] ? commShareAssocs[nth][fieldName] : '';

    return <input value={value} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(commShareAssocUpdate(workingEntity, nth, fieldName, e.target.value)); }} onKeyDown={onKeyDown} />
}

export function ModalSetEmployee() {
    const dispatch = useDispatch();

    const classes = useStyles();

    const { isShowModalEmployees, nthCommShare, balEmployees } = useSelector(state => {
        return state.selections;
    });

    const isNotLeavedEmployees = _.filter(balEmployees, function (e) { return e.DateLeave == null ? true : false });

    const { commShareAssocs } = useSelector(state => {
        return state.commShareAssoc;
    });

    const [ selectedValue, setSelectedValue ] = useState("");

    useEffect(() => {
        if (!isShowModalEmployees) return;

        if (nthCommShare !== null) {
            const { Name: commShareName } = commShareAssocs[nthCommShare];  // 對 CommShare
            if (commShareName) {
                let index = _.findIndex(isNotLeavedEmployees, function (e) {
                    return _.startsWith(`${e.Name.toLowerCase()} (${e.Branch}})`, commShareName.toLowerCase());
                });

                if (index < 0) index = isNotLeavedEmployees.length - 1;

                setSelectedValue(isNotLeavedEmployees[index].Id);
            } else {
                setSelectedValue("");
            }
        }
    }, [isShowModalEmployees]); // isShowModalEmployees 嘅時候先 update

    const handleClose = () => {
        dispatch(detailCommSetModalEmployee(false, null));
    };

    const handleSelected = (employee) => {
        dispatch(detailCommSetModalEmployee(false, null));
        dispatch(detailCommShareAssocSetEmployee(nthCommShare, employee));
    };

    return <Modal
        open={isShowModalEmployees}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 250, className: classes.backdrop, }}
    >
        <Fade in={isShowModalEmployees}>
            <div className={classes.paperEmployees}>
                <EmployeeSelection showAny={false} value={selectedValue} onPicked={handleSelected} size={10} tabIndex={999} />
                <Grid container justifyContent="center" className="mt-3">
                    <Button variant="contained" size="small" disableElevation onClick={(_) => { handleClose() }}>取　消</Button>
                </Grid>
            </div>
        </Fade>
    </Modal>;
}

export function ModalWarnDiff() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const { isShowModalDiff, diffCommShareOwner, diffCommShareBuyer } = useSelector(state => {
        return state.commShareAssoc;
    })

    let msg = "分成沒有問題";
    if ((diffCommShareOwner > 0.02) && (diffCommShareBuyer > 0.02)) msg = `業主佣金差異為 ${diffCommShareOwner}，買家佣金差異為 ${diffCommShareBuyer}`;
    else if (diffCommShareOwner > 0.02) msg = `業主佣金差異為 $${diffCommShareOwner}`;
    else if (diffCommShareBuyer > 0.02) msg = `買家佣金差異為 $${diffCommShareBuyer}`;

    return <Modal
        open={isShowModalDiff}
        onClose={(_) => { dispatch(detailCommSetVisibleCheckDiff(false)); }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 250, className: classes.backdrop, }}
    >
        <Fade in={isShowModalDiff}>
            <div className={classes.paper}>
                {msg}
            </div>
        </Fade>
    </Modal>;

}