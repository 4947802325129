import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import clsx from 'clsx';
import cfg from '../../../config.json';
import Comm from '../../../models/comm';

import { showLoading, showInfo, showErrorResponse, clearMsg } from '../../../redux/msgReducer';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';

import { CalMonthSelection } from "../../Selections";
import MyDateField from "../../MyDateField";

const useStyles = makeStyles({
    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        //width: "100%",

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },

        "& label": {
            marginBottom: "0px",
        }
    },
});

export default function ReportCommOption({ state, setState }) {
    const classes = useStyles();

    return (
        <div>
            <table className={clsx(classes.frmFilter, { "mb-3": true })}>
                <tbody>
                    <tr>
                        <th style={{ textAlign: 'right', width: '130px' }}>佣金日期：</th>
                        <td>
                            <ReportEmployeeDueOptionRadioField state={state} setState={setState} fieldName="opt" label="只顯示為負責營業員之交易" value="__FIRST_RELEVANT__" />
                            <ReportEmployeeDueOptionRadioField state={state} setState={setState} fieldName="opt" label="顯示所以有份參與之交易" value="__ALL_RELEVANT__" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

function ReportEmployeeDueOptionRadioField({ state, setState, fieldName, label, value }) {
    const name = `filter_${fieldName}`;
    const id = `${name}_${value}`;
    return <span className="mr-1 ml-1">
        <input className="mr-1" type="radio" value={value} id={id} name={name} onChange={(e) => { setState({ ...state, [fieldName]: e.target.value, }); }} checked={state[fieldName] == value} />
        <label htmlFor={id}>{label}</label>
    </span>;
}
