import Moment from 'moment';

export default class BranchHeadCount {
  constructor({
    Id,
    IdBranch,
    Branch,
    Fdate,
    Tdate,
    AvgHeadCount,
  }) {
    this.Id = Id;
    this.IdBranch = IdBranch;
    this.Branch = Branch;
    this.Fdate = Fdate;
    this.Tdate = Tdate;
    this.AvgHeadCount = (AvgHeadCount && Number.parseFloat(AvgHeadCount) > 0) ? Number.parseFloat(AvgHeadCount).toFixed(2) : "";
  }

  isNew() {
    return (this.Id == undefined) || (this.Id < 1) || (this.Id.toString().toUpperCase() == "NEW");
  }

  getDesc() {
    if (this.isNew()) return "新分行人數";

    if (this.Fdate) {
      Moment.locale('en');
      return Moment(this.Fdate).format('YYYY 年 MM 月');
    }

    return `#${this.Id}`;
  }
}
