import React, { useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { vSupervisorChangeSort } from '../../redux/vSupervisorReducer';
import ReactMoment from "react-moment";

import BeatLoader from "react-spinners/BeatLoader";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles({
  tblList: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& thead": {
      backgroundColor: "#c5e2ff",
      color: "#45525c",
    },

    "& tr": {
      cursor: "pointer",
    },

    "& tr.alt": {
      backgroundColor: "#f7fbff",
    },

    "& tr.lastWorkingEntity": {
      border: "2px solid #da59da",
    },

    "& tbody tr:hover, tbody tr.alt:hover": {
      backgroundColor: "#ffffcc !important",
    },

    "& th": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },
  },
});

export default function VSupervisorList() {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading, filter, entities, errors } = useSelector(state => {
    return state.vSupervisor;
  });

  const { workingEntityId } = useSelector(state => {
    return state.supervisor;
  });

  const sorts = JSON.parse(filter.sort);

  const _getSortDirection = (field) => {
    const i = _.findIndex(sorts, function (o) { return o.property == field; });
    return (i >= 0) ? sorts[i].direction : "";
  };

  const changeSort = (field) => {
    dispatch(vSupervisorChangeSort(field, filter));
  };

  const showDetail = (id) => {
    history.push(`/supervisorDetail/${id}`);
  }

  let i = filter.qtyPerPage * (filter.page - 1) + 1;

  const myRef = useRef(null);
  useEffect(() => {
    if (myRef.current) myRef.current.scrollIntoView({ block: "center" });
  }, []);

  return (
    <table className={classes.tblList} >
      <thead>
        <tr>
          <th><span>行</span></th>
          <th onClick={(_) => changeSort('id')}><span>編號</span><MyArrowIcon direction={_getSortDirection("id")} /></th>
          <th onClick={(_) => changeSort('id_employee')}><span>員工編號</span><MyArrowIcon direction={_getSortDirection("id_employee")} /></th>
          <th onClick={(_) => changeSort('name')}><span>員工姓名</span><MyArrowIcon direction={_getSortDirection("name")} /></th>
          <th onClick={(_) => changeSort('alias')}><span>員工別名</span><MyArrowIcon direction={_getSortDirection("alias")} /></th>
          <th onClick={(_) => changeSort('fdate')}><span>開始日期</span><MyArrowIcon direction={_getSortDirection("fdate")} /></th>
          <th onClick={(_) => changeSort('tdate')}><span>完結日期</span><MyArrowIcon direction={_getSortDirection("tdate")} /></th>
          <th><span>分行數量</span></th>
          <th><span>業績分行</span></th>
          <th><span>計算方法</span></th>
        </tr>
      </thead>

      {isLoading
        ?
        <tbody>
          <tr>
            <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
          </tr>
        </tbody>
        :
        (errors !== "")
          ?
          <tbody>
            <tr>
              <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
            </tr>
          </tbody >
          :
          <tbody>
            {
              entities.map((entity, index) => {
                let isCalTeams = _.filter(entity.SupervisorTeams, "IsCal");
                let commPlan = "";
                switch (entity.CommPlan) {
                  case 5: commPlan = <span>Plan E</span>; break;
                  case 6: commPlan = <span style={{ color: 'red' }}>Plan F</span>; break;
                  case 7: commPlan = <span>Plan G</span>; break;
                  default: throw new Error(`${entity.CommPlan} is not handled`);
                }

                return <tr key={entity.Id} ref={workingEntityId == entity.Id ? myRef : null} className={clsx({ "lastWorkingEntity": workingEntityId == entity.Id, "alt": index % 2 == 1, "normal": index % 2 == 0 })} onClick={() => { showDetail(entity.Id) }}>
                  <td>{i++}</td>
                  <td>{entity.Id}</td>
                  <td>{entity.IdEmployee}</td>
                  <td>{entity.Name}</td>
                  <td>{entity.Alias}</td>
                  <td>{entity.Fdate ? <ReactMoment format="YYYY-MM-DD" date={entity.Fdate} /> : "---"}</td>
                  <td>{entity.Tdate ? <ReactMoment format="YYYY-MM-DD" date={entity.Tdate} /> : "--- 應用中 ---"}</td>
                  <td>{entity.SupervisorTeams.length}</td>
                  <td>{isCalTeams.length ? isCalTeams.map(t => t.Branch).join(', ') : '---'}</td>
                  <td>{commPlan}</td>
                </tr>
              })
            }
          </tbody>
      }
    </table>
  );
}

function MyArrowIcon({ direction }) {
  switch (direction) {
    case "ASC": return <ArrowDropUpIcon />;
    case "DESC": return <ArrowDropDownIcon />;
    default: return null;
  }
}
