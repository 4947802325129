import _ from 'lodash';

export default class VSupervisor { // for supervisor selections. which is joined with supervisor and employee
    constructor({
        Id,
        IdEmployee,
        Name,
        Alias,
        Fdate,
        Tdate,
        DateLeave,
        District,
        IdBranch,
        Branch,
        IdBranches,
        DistrictBranchSeq,
    }) {
        this.Id = Id;
        this.IdEmployee = IdEmployee;
        this.Name = Name;
        this.Alias = Alias;
        this.Fdate = Fdate;
        this.Tdate = Tdate;
        this.DateLeave = DateLeave;
        this.District = District;
        this.IdBranch = IdBranch;
        this.Branch = Branch;
        this.IdBranches = IdBranches;
        this.DistrictBranchSeq = DistrictBranchSeq;
    }
}
