import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Moment from 'moment';

import Prepaid from "../models/prepaid";

import { showLoading, showInfo, showError, clearMsg } from './msgReducer';

//================== Whole State ==================//
const initialState = {
    isInited: false,
    isLoading: false,
    isFilterUpdated: false,
    filter: { // match EntitiesResult<T>
        allRecords: 0,
        matchedRecords: 0,
        totalRecords: 0,
        qtyPerPage: 100,
        page: 1,
        row: 0,
        filter: "[]",
        sort: JSON.stringify([{ "property": "date_report", "direction": "DESC" }]),
        success: true,
        message: '',
    },
    entities: [],
    errors: '',
    workingEntityId: -1,
    workingEntity: null,
    workingEntityErrors: '',

    detailPrepaids: _.range(10).map(i => new Prepaid({})),
};


//================== Action Types ==================//
export const PrepaidActions = Object.freeze({
    // filter
    FILTER_UPDATE: Symbol("FILTER_UPDATE"),
    CHANGE_SORT: Symbol("CHANGE_SORT"),
    PAGE_UPDATE: Symbol("PAGE_UPDATE"),
    FETCH_PAGE: Symbol("FETCH_PAGE"),

    // listing
    REQUEST: Symbol("REQUEST"),
    SUCCESS: Symbol("SUCCESS"),
    FAILURE: Symbol("FAILURE"),

    // detail page
    DETAIL_ID: Symbol("DETAIL_ID"),
    DETAIL_SUCCESS: Symbol("DETAIL_SUCCESS"), // ajax loaded
    DETAIL_FAILURE: Symbol("DETAIL_FAILURE"),
    DETAIL_DELETED: Symbol("DETAIL_DELETED"), // ajax delete

    DETAIL_UPDATE: Symbol("DETAIL_UPDATE"), // form fields update
    DETAIL_SET_EMPLOYEE: Symbol("DETAIL_SET_EMPLOYEE"), // form fields update

    DETAIL_COMM_PREPAIDS_UPDATE: Symbol("DETAIL_COMM_PREPAIDS_UPDATE"), 
});


//================== Action Creators ==================//
export const prepaidInit = () => {
    return (dispatch, getState) => {
        const { prepaid: { isInited, filter } } = getState();
        if (isInited) return;

        dispatch({ type: PrepaidActions.INIT, }); // update isInited

        dispatch(prepaidLookup(filter));
    };
}

export const prepaidFilterUpdate = (field, value) => {
    return {
        type: PrepaidActions.FILTER_UPDATE,
        payload: {
            field: field,
            value: value
        }
    }
}

const _setWorkingEntityId = (id) => {
    return {
        type: PrepaidActions.DETAIL_ID,
        payload: id,
    }
}

export const prepaidFilterClear = () => {
    return (dispatch) => {
        dispatch(_setWorkingEntityId(initialState.workingEntityId));
        dispatch(prepaidLookup(initialState.filter));
    }
}

export const prepaidChangeSort = (field) => {
    return (dispatch, getState) => {
        dispatch({
            type: PrepaidActions.CHANGE_SORT,
            payload: field,
        });
        const { prepaid: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(prepaidLookup(filter));
    };
}

export const prepaidPageUpdate = (page) => {
    return (dispatch, getState) => {
        const { prepaid: { filter } } = getState(); // state updated

        let _page = 1;

        if (page == "") {
            _page = "";
        } else {
            _page = parseInt(page);
            if (_.isNaN(_page)) return;
            if (_page < 1) _page = 1;
            if (_page > Math.ceil(filter.matchedRecords / filter.qtyPerPage)) _page = Math.ceil(filter.matchedRecords / filter.qtyPerPage);
        }

        dispatch({
            type: PrepaidActions.FETCH_PAGE,
            payload: _page,
        });
    };
}

export const prepaidFetchPage = (page) => {
    return (dispatch, getState) => {
        dispatch({
            type: PrepaidActions.FETCH_PAGE,
            payload: page,
        });
        const { prepaid: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(prepaidLookup(filter));
    };
}

const _prepaidRequest = () => {
    return {
        type: PrepaidActions.REQUEST
    }
}

const _prepaidSuccess = (data) => {
    return {
        type: PrepaidActions.SUCCESS,
        payload: data,
    }
}

const _prepaidFailure = (response) => {
    return {
        type: PrepaidActions.FAILURE,
        payload: response,
    }
}

export const prepaidLookup = (formData) => {
    const _formData = formData ? formData : initialState.filter;
    //console.log(_formData);
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Prepaid/GetEntities`;

    return (dispatch, getState) => {
        dispatch(_prepaidRequest());

        const { prepaid } = getState(); // state updated
        if (prepaid.isFilterUpdated) _formData.page = 1; // reset page if filter changed
        if (prepaid.filter.page == "") _formData.page = 1; // reset page if filter changed

        axios.post(apiEndpoint, _formData, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch(_prepaidSuccess(response.data));
                } else {
                    dispatch(_prepaidFailure("未能讀取"));
                }
            })
            .catch(error => {
                let msg = "";
                if (error.response && error.response.data && error.response.data.title) msg = error.response.data.title;
                else if (error.response && error.response.status) msg = `未能讀取 (${error.response.status})`;
                else msg = '未能聯繫伺服器';

                dispatch(showError(msg));
                dispatch(_prepaidFailure(msg));
            })
    }
}

const _prepaidDetailSuccess = (data) => {
    return {
        type: PrepaidActions.DETAIL_SUCCESS,
        payload: data,
    }
}

const _prepaidDetailFailure = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: PrepaidActions.DETAIL_FAILURE,
            payload: response,
        })
    }
}

export const prepaidDetail = (Id, isForce) => {
    return (dispatch, getState) => {
        const { prepaid: { workingEntityId: lastWorkingEntityId } } = getState();
        if (!isForce && lastWorkingEntityId == Id) return; // same entity

        dispatch(_setWorkingEntityId(Id)); // 任何 dispatch 之前先記住 current working entity id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Prepaid/GetEntity`;

        dispatch(_prepaidRequest());

        axios.post(apiEndpoint, { Id }, {
            timeout: 30000,
        })
            .then(response => {
                dispatch(clearMsg());

                if (response.data) {
                    dispatch(_prepaidDetailSuccess(response.data));
                } else {
                    dispatch(_prepaidDetailFailure("未能讀取"));
                }
            })
            .catch(error => {
                dispatch(clearMsg());

                let msg = "";
                if (error.response && error.response.data && error.response.data.title) msg = error.response.data.title;
                else if (error.response && error.response.status) msg = `未能讀取 (${error.response.status})`;
                else msg = '未能聯繫伺服器';

                dispatch(showError(msg));
                dispatch(_prepaidDetailFailure(msg));
            })
    };
}

export const prepaidDetailUpdate = (field, value) => {
    return {
        type: PrepaidActions.DETAIL_UPDATE,
        payload: {
            field: field,
            value: value
        }
    };
}

export const prepaidDetailSetEmployee = (employee) => {
    return {
        type: PrepaidActions.DETAIL_SET_EMPLOYEE,
        payload: employee,
    };
}

export const prepaidUpdate = (nth, fieldName, value) => {
    return (dispatch, getState) => {
        // copy idEmployee from detail owner prepaid share
        const { prepaid: { detailOwnerPrepaidShares } } = getState();
        const { IdEmployee, Name } = detailOwnerPrepaidShares[nth];
        console.log(Name);

        dispatch({
            type: PrepaidActions.DETAIL_COMM_PREPAIDS_UPDATE,
            payload: { nth, fieldName, value, IdEmployee, Name },
        });
    }

}

export const saveEntity = (history) => {
    return (dispatch, getState) => {
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Prepaid/SaveEntity`;

        const { prepaid: { workingEntity } } = getState();

        dispatch(showLoading());

        axios.post(apiEndpoint, workingEntity, {
            timeout: 30000,
        })
            .then(response => {
                if (history.location.pathname == "/prepaidDetail/0") {
                    history.replace(`/prepaidDetail/${response.data.Id}`);
                    dispatch(showInfo("已保存"));
                    return;
                }

                dispatch(_prepaidDetailSuccess(response.data));
                dispatch(showInfo("已保存"));
            })
            .catch(error => {
                let msg = "";
                if (error.response && error.response.data && error.response.data.title) msg = error.response.data.title;
                else if (error.response && error.response.status) msg = `未能保存 (${error.response.status})`;
                else msg = '未能聯繫伺服器';

                dispatch(showError(msg));
                dispatch(_prepaidDetailFailure(msg));
            })
    };
}

export const deleteEntity = (history) => {
    return (dispatch, getState, x) => {
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Prepaid/DeleteEntity`;

        const { prepaid: { workingEntity } } = getState();

        dispatch(showLoading());
        dispatch(_prepaidRequest());

        axios.post(apiEndpoint, workingEntity, {
            timeout: 30000,
        })
            .then(response => {
                const { prepaid: { filter } } = getState();

                dispatch(clearMsg());
                history.push('/prepaid');
                dispatch(prepaidLookup(filter));
            })
            .catch(error => {
                let msg = "";
                if (error.response && error.response.data && error.response.data.title) msg = error.response.data.title;
                else if (error.response && error.response.status) msg = `未能刪除 (${error.response.status})`;
                else msg = '未能聯繫伺服器';

                dispatch(showError(msg));
                dispatch(_prepaidDetailFailure(msg));
            })
    };
}


//================== Reducer ==================//
const prepaidReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);
    let i = -1;

    switch (action.type) {
        case PrepaidActions.INIT:
            _state.isInited = true;
            return _state;

        case PrepaidActions.FILTER_UPDATE:
            const { field: filterField, value } = action.payload;
            let filters = JSON.parse(state.filter.filter);
            const iFilter = _.findIndex(filters, function (o) { return o.property == filterField; });
            if (iFilter >= 0) {
                if (value) filters[iFilter].value = value;
                else filters.splice(iFilter, 1);
            } else {
                filters.push({ "property": filterField, "value": value });
            }

            _state.filter.filter = JSON.stringify(filters);
            _state.isFilterUpdated = true;
            //console.log('_state.filter', _state.filter.filter);
            return _state;

        case PrepaidActions.CHANGE_SORT:
            const field = action.payload;
            let sorts = JSON.parse(state.filter.sort);
            i = _.findIndex(sorts, function (o) { return o.property == field; });
            if (i >= 0) {
                sorts[i].direction = sorts[i].direction == "ASC" ? "DESC" : "ASC";
            } else {
                // single sort only
                sorts = [{ "property": field, "direction": "ASC" }];
            }

            _state.filter.sort = JSON.stringify(sorts);
            //console.log('_state.filter.sort', _state.filter.sort);

            prepaidLookup(_state.filter);
            return _state;

        case PrepaidActions.PAGE_UPDATE:
            _state.filter.page = action.payload;
            return _state;

        case PrepaidActions.FETCH_PAGE:
            _state.filter.page = action.payload;
            return _state;

        case PrepaidActions.REQUEST:
            _state.isLoading = true;
            return _state;

        case PrepaidActions.SUCCESS:
            _state.isLoading = false;
            _state.filter = {
                allRecords: action.payload.allRecords,
                matchedRecords: action.payload.matchedRecords,
                totalRecords: action.payload.totalRecords,
                qtyPerPage: action.payload.qtyPerPage,
                page: action.payload.page,
                row: action.payload.row,
                filter: action.payload.filter,
                sort: action.payload.sort,
                success: action.payload.success,
                message: action.payload.message,
            };
            _state.entities = action.payload.entities;
            _state.errors = "";
            _state.isFilterUpdated = false;
            return _state;

        case PrepaidActions.FAILURE:
            _state.isLoading = false;
            _state.errors = action.payload;
            _state.entities = [];
            return _state;

        case PrepaidActions.DETAIL_ID:
            _state.workingEntityId = action.payload;
            return _state;

        case PrepaidActions.DETAIL_SUCCESS:
            _state.isLoading = false;
            _state.workingEntity = new Prepaid(action.payload);
            _state.workingEntityId = _state.workingEntity.Id;

            // update list
            i = _.findIndex(_state.entities, function (_entity) { return _entity.Id == _state.workingEntity.Id; });
            if (i >= 0) _state.entities[i] = _state.workingEntity;

            //console.log(_state.workingEntity);
            //console.log(_state.workingEntity.getDesc());

            return _state;

        case PrepaidActions.DETAIL_FAILURE:
            _state.isLoading = false;
            _state.workingEntityErrors = action.payload;
            //console.log(_state.errors);
            return _state;

        case PrepaidActions.DETAIL_UPDATE:
            const { field: updateField, value: updateValue } = action.payload;
            _state.workingEntity[updateField] = updateValue;
            return _state;

        case PrepaidActions.DETAIL_SET_EMPLOYEE:
            const { Id: DetailSetEmployeeId, Name: DetailSetEmployeeName, Branch: DetailSetBranch } = action.payload;
            //console.log(action.payload);
            _state.workingEntity["IdEmployee"] = DetailSetEmployeeId;
            _state.workingEntity["Name"] = DetailSetEmployeeName;
            _state.workingEntity["Branch"] = DetailSetBranch;
            return _state;

        case PrepaidActions.DETAIL_COMM_PREPAIDS_UPDATE:
            const { nth, fieldName: prepaidField, value: prepaidValue, IdEmployee, Name } = action.payload;
            _state.detailPrepaids[nth][prepaidField] = prepaidValue;
            _state.detailPrepaids[nth]["IdEmployee"] = IdEmployee;
            _state.detailPrepaids[nth]["Name"] = Name;
            return _state;

        default:
            return state;
    }
}

export default prepaidReducer;
