import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { commTranBonusDetail, commTranBonusDetailUpdate } from '../../redux/vCommTranBonuReducer';
//commTranBonusDetailSetEmployee

import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import BeatLoader from "react-spinners/BeatLoader";
import NumberFormat from 'react-number-format';

//import Button from '@material-ui/core/Button';
//import Grid from '@material-ui/core/Grid';
//import RefreshIcon from '@material-ui/icons/Refresh';

import { CommBonusShareRolesSelection, CommBonusShareManagerSelection } from '../Selections';
import CommBonusShareDetailInputField from './CommBonusShareDetailInputField';
import CommBonusShareDetailBottomButtons from "./CommBonusShareDetailBottomButtons";

//import Modal from '@material-ui/core/Modal';
//import Backdrop from '@material-ui/core/Backdrop';
//import Fade from '@material-ui/core/Fade';

//import CommBonusShareDetailBottomButtons from './CommBonusShareDetailBottomButtons';

const useStyles = makeStyles((theme) => ({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& a.nav-link:hover h1": {
            color: "#006ed0",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },
    },
    table: {
        width: "100%",
        backgroundColor: "white",

        "& th, & td": {
            padding: "2px 6px",
            border: "1px solid #D5D5D5",
        },

        "& th": {
            backgroundColor: "#FAF3DE",
            textAlign: 'right',
        },
    },
    backdrop: {
        backgroundColor: 'rgb(0, 0, 0, 0.2)',
    },
    paper: {
        margin: '40px auto',
        width: 320,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: "auto",
    },
}));

export default function CommBonusShareDetail() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { isLoading, workingEntity } = useSelector(state => {
        return state.vCommTranBonus;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    const { id } = useParams();
    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");

        dispatch(commTranBonusDetail(id));

    }, [id]);

    return (
        <>
            <Breadcrumbs className={classes.title}>
                <NavLink className="nav-link" tag={Link} to="/commBonusShare/list">
                    <Typography variant="h1" className={classes.title}>1.2. 獎金分成</Typography>
                </NavLink>
                <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : workingEntity !== null ? workingEntity.getDesc() : ""}</Typography>
            </Breadcrumbs>

            { workingEntity
                && workingEntity.commShares_CommBonusShares
                && workingEntity.commShares_CommBonusShares.map((cs, index) => <CommShareTable key={index} bonus={workingEntity.AmountTran} cs={cs} index={index} />)
            }

            <CommBonusShareDetailBottomButtons />
        </>
    );
}

function CommShareTable({ bonus, cs, index }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { workingEntity } = useSelector(state => {
        return state.vCommTranBonus;
    });

    const sumPercent = _.sumBy(cs.commBonusShares, function (cbs) {
        return cbs.Percent ? _.isString(cbs.Percent) ? _.round(parseFloat(cbs.Percent), 2) : cbs.Percent : 0;
    });

    const sumAmount = _.sumBy(cs.commBonusShares, function (cbs) {
        return cbs.Amount ? _.isString(cbs.Amount) ? _.round(parseFloat(cbs.Amount), 2) : cbs.Amount : 0;
    });

    return <table className={classes.table} style={{ width: 'auto', marginBottom: "20px" }}>
        <thead>
            <tr>
                <th colSpan={5} style={{ background: '#efffe6', textAlign: 'left', paddingLeft: '8px' }}>
                    {cs.IdEmployee}. {cs.Name} (收到獎金 <NumberFormat prefix="$" thousandSeparator={true} value={bonus} displayType="text" decimalScale="2" fixedDecimalScale />
                    &nbsp; x &nbsp;
                    分成 <NumberFormat value={cs.Percent} displayType="text" decimalScale="2" fixedDecimalScale suffix="%" /> = 
                    上數 <NumberFormat prefix="$" thousandSeparator={true} value={bonus * cs.Percent / 100} displayType="text" decimalScale="2" fixedDecimalScale />)
                </th>
            </tr>
            <tr>
                <th width={30}>&nbsp;</th><th>級別</th><th>主管 / 員工</th><th>獎金百分比</th><th>實收</th>
            </tr>
        </thead>
        <tbody>
            {cs.commBonusShares.map((bs, index2) => {
                return <tr key={index2}>
                    <td style={{ textAlign: 'center' }}>{index2 + 1}</td>
                    <td style={{ textAlign: 'center' }}>
                        <CommBonusShareRolesSelection value={bs.SharedRole} onChange={(e) => { dispatch(commTranBonusDetailUpdate(index, index2, "SharedRole", e.target.value)); }} />
                    </td>
                    <td>
                        <CommBonusShareManagerSelection idEmployee={cs.IdEmployee} value={bs.SharedIdEmployee} onChange={(e) => { dispatch(commTranBonusDetailUpdate(index, index2, "SharedIdEmployee", e.target.value)); }} tabIndex={1} />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <CommBonusShareDetailInputField commShareIndex={index} commBonusShareIndex={index2} fieldName={"Percent"} width={70} tabIndex={1} textAlign={"right"} /> %
                    </td>
                    <td>
                        $ <CommBonusShareDetailInputField commShareIndex={index} commBonusShareIndex={index2} fieldName={"Amount"} width={100} tabIndex={1} textAlign={"right"} />
                    </td>
                </tr>;
            })}
        </tbody>
        <tbody>
            <tr>
                <td colSpan={3} style={{ textAlign: 'right' }}>小計：</td>
                <td style={{ textAlign: "right", color: (sumPercent > 99.9 && sumPercent < 100.1 ? "inherit" : "red") }}><NumberFormat displayType={'text'} value={sumPercent} decimalScale={2} fixedDecimalScale /> %</td>
                <td style={{ textAlign: "right", color: (sumPercent > 99.9 && sumPercent < 100.1 ? "inherit" : "red") }}>$ <NumberFormat displayType={'text'} thousandSeparator={true} value={sumAmount} decimalScale={2} fixedDecimalScale /></td>
            </tr>
        </tbody>
    </table>
}