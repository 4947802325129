import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';

import Moment from 'moment';
import { setWorkingEntityId } from './supervisorReducer';

//================== Whole State ==================//
const initialState = {
    isLoading: false,
    isFilterUpdated: false,
    filter: { // match EntitiesResult<T>
        allRecords: 0,
        matchedRecords: 0,
        totalRecords: 0,
        qtyPerPage: 100,
        page: 1,
        row: 0,
        filter: JSON.stringify([{ "property": "cal_date", "value": new Moment.utc().startOf('day') }]),
        sort: JSON.stringify([{ "property": "name", "direction": "ASC" }]),
        success: true,
        message: '',
    },
    entities: [],
    errors: '',
};


//================== Action Types ==================//
export const VSupervisorActions = Object.freeze({
    // filter
    FILTER_UPDATE: Symbol("FILTER_UPDATE"),
    CHANGE_SORT: Symbol("CHANGE_SORT"),
    PAGE_UPDATE: Symbol("PAGE_UPDATE"),
    FETCH_PAGE: Symbol("FETCH_PAGE"), 

    // listing
    REQUEST: Symbol("REQUEST"), 
    SUCCESS: Symbol("SUCCESS"), 
    FAILURE: Symbol("FAILURE"),
});


//================== Action Creators ==================//
export const vSupervisorFilterUpdate = (field, value) => {
    return {
        type: VSupervisorActions.FILTER_UPDATE,
        payload: {
            field: field,
            value: value
        }
    }
}

export const vSupervisorFilterClear = (history) => {
    return (dispatch) => {
        dispatch(setWorkingEntityId(0));
        dispatch(vSupervisorLookup(initialState.filter, history));
    }
}

export const vSupervisorChangeSort = (field) => {
    return (dispatch, getState) => {
        dispatch({
            type: VSupervisorActions.CHANGE_SORT,
            payload: field,
        });
        const { vSupervisor: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(vSupervisorLookup(filter));
    };
}

export const vSupervisorPageUpdate = (page) => {
    return (dispatch, getState) => {
        const { vSupervisor: { filter } } = getState(); // state updated

        let _page = 1;

        if (page == "") {
            _page = "";
        } else {
            _page = parseInt(page);
            if (_.isNaN(_page)) return;
            if (_page < 1) _page = 1;
            if (_page > Math.ceil(filter.matchedRecords / filter.qtyPerPage)) _page = Math.ceil(filter.matchedRecords / filter.qtyPerPage);
        }

        dispatch({
            type: VSupervisorActions.FETCH_PAGE,
            payload: _page,
        });
    };
}

export const vSupervisorFetchPage = (page) => {
    return (dispatch, getState) => {
        dispatch({
            type: VSupervisorActions.FETCH_PAGE,
            payload: page,
        });
        const { vSupervisor: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(vSupervisorLookup(filter));
    };
}

const _vSupervisorRequest = () => {
    return {
        type: VSupervisorActions.REQUEST
    }
}

const _vSupervisorSuccess = (data) => {
    return {
        type: VSupervisorActions.SUCCESS,
        payload: data,
    }
}

const _vSupervisorFailure = (response) => {
    return {
        type: VSupervisorActions.FAILURE,
        payload: response,
    }
}

export const vSupervisorLookup = (formData) => {
    const _formData = formData ? formData : initialState.filter;
    //console.log(_formData);
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Supervisor/GetEntities`;

    return (dispatch, getState) => {
        dispatch(_vSupervisorRequest());

        const { vSupervisor } = getState(); // state updated
        if (vSupervisor.isFilterUpdated) _formData.page = 1; // reset page if filter changed
        if (vSupervisor.filter.page == "") _formData.page = 1; // reset page if filter changed

        axios.post(apiEndpoint, _formData, {
            timeout: 30000,
        })
            .then(response => {
            if (response.data) {
                dispatch(_vSupervisorSuccess(response.data)); 
            } else {
                dispatch(_vSupervisorFailure("未能讀取"));
            }
        })
        .catch(error => {
            if (error.response && error.response.data && error.response.data.errors) {
                dispatch(_vSupervisorFailure(error.response.data.errors));
            } else if (error.response && error.response.status) {
                dispatch(_vSupervisorFailure(`未能讀取 (${error.response.status})`));
            } else {
                dispatch(_vSupervisorFailure(`未能聯繫伺服器`));
            }
        })
    }
}


//================== Reducer ==================//
const vSupervisorReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);

    switch (action.type) {
        case VSupervisorActions.FILTER_UPDATE:
            const { field: filterField, value } = action.payload;
            let filters = JSON.parse(state.filter.filter);
            const iFilter = _.findIndex(filters, function (o) { return o.property == filterField; });
            if (iFilter >= 0) {
                if (value) filters[iFilter].value = value;
                else filters.splice(iFilter, 1);
            } else {
                filters.push({ "property": filterField, "value": value });
            }

            _state.filter.filter = JSON.stringify(filters);
            _state.isFilterUpdated = true;
            //console.log('_state.filter', _state.filter.filter);
            return _state;

        case VSupervisorActions.CHANGE_SORT:
            const field = action.payload;
            let sorts = JSON.parse(state.filter.sort);
            const i = _.findIndex(sorts, function (o) { return o.property == field; });
            if (i >= 0) {
                sorts[i].direction = sorts[i].direction == "ASC" ? "DESC" : "ASC";
            } else {
                // single sort only
                sorts = [{ "property": field, "direction": "ASC" }];
            }

            _state.filter.sort = JSON.stringify(sorts);
            //console.log('_state.filter.sort', _state.filter.sort);

            vSupervisorLookup(_state.filter);
            return _state;

        case VSupervisorActions.PAGE_UPDATE:
            _state.filter.page = action.payload;
            return _state;

        case VSupervisorActions.FETCH_PAGE:
            _state.filter.page = action.payload;
            return _state;

        case VSupervisorActions.REQUEST:
            _state.isLoading = true;
            return _state

        case VSupervisorActions.SUCCESS:
            _state.isLoading = false;
            _state.filter = {
                allRecords: action.payload.allRecords,
                matchedRecords: action.payload.matchedRecords,
                totalRecords: action.payload.totalRecords,
                qtyPerPage: action.payload.qtyPerPage,
                page: action.payload.page,
                row: action.payload.row,
                filter: action.payload.filter,
                sort: action.payload.sort,
                success: action.payload.success,
                message: action.payload.message,
            };
            _state.entities = action.payload.entities;
            _state.errors = "";
            _state.isFilterUpdated = false;
            return _state;

        case VSupervisorActions.FAILURE:
            _state.isLoading = false;
            _state.errors = action.payload;
            _state.entities = [];
            return _state;

        default:
            return state;
    }
}

export default vSupervisorReducer;

