import React, { useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import Moment from 'moment';

import { employeeVacationLookup, employeeVacationFetchPage, employeeVacationPageUpdate } from '../../redux/employeeVacationReducer';

import EmployeeVacationFilter from './EmployeeVacationFilter';
import EmployeeVacationList from './EmployeeVacationList';
import Pagination from '../Pagination';

const useStyles = makeStyles({
    title: {
        paddingBottom: "15px",
        fontSize: "26px",
        lineHeight: "26px",
        fontWeight: "bold",
    },
});

export default function EmployeeVacation() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { hasFilter } = useParams();

    const { filter } = useSelector(state => {
        return state.employeeVacation;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");

        dispatch(employeeVacationLookup(hasFilter ? filter : null));
    }, []);

    const _getFilterValue = (field) => {
        const filters = JSON.parse(filter.filter);
        const i = _.findIndex(filters, function (o) { return o.property == field; });
        return (i >= 0) ? filters[i].value : "";
    };

    const newEmployeeVacations = () => {
        const calMonth = _getFilterValue('f_cal_month') ? new Moment.utc(_getFilterValue('f_cal_month')) : new Moment.utc();
        history.push(`/employeeVacationDetails/${calMonth.local().format('YYYY-MM-01')}`); // not sending to server, client side had to down to local as parameter
    }

    return (
        <div>
            <Typography variant="h1" className={classes.title}>4.1. 放假日數</Typography>
            <Grid container className="mb-2" spacing={2}>
                <Grid item>
                    <Button variant="contained" size="small" onClick={newEmployeeVacations}>以「開始日期」新增記錄</Button>
                </Grid>
            {/*    <Grid item>*/}
            {/*        <Button variant="contained" size="small" onClick={newEmployeeVacation}>新增放假日數 (單一記錄)</Button>*/}
            {/*    </Grid>*/}
            </Grid>
            <EmployeeVacationFilter filter={filter} />
            <Pagination filter={filter} fnFetchPage={employeeVacationFetchPage} fnPageUpdate={employeeVacationPageUpdate} />
            <EmployeeVacationList />
            <div style={{ margin: "5px", textAlign: 'center' }}>
                <Pagination filter={filter} fnFetchPage={employeeVacationFetchPage} fnPageUpdate={employeeVacationPageUpdate} />
            </div>

        </div>
    );
}
