import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    background: "#50504D",
    padding: "15px 10px",
    color: "white",
    fontSize: '13px',
    fontFamily: 'Arial',
  },
});

export default function Footer() {
  const classes = useStyles();

  return (
    <Typography className={classes.root}>
      v.1.7.1 Copyright © Century 21 Goodwin Property Consultants. All Rights Reserved.
    </Typography>
  );
}
