import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { NavLink } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';

import { commLookup } from '../redux/commReducer';

const useStyles = makeStyles({
  root: {
    borderBottom: "1px solid #D1CB9D",
    clear: "both",
    padding: "0px",
    margin: "0px",
    background: "linear-gradient(0deg, rgba(233,217,166,1) 0%, rgba(234,226,185,1) 48%, rgba(255,255,255,1) 52%, rgba(247,243,226,1) 100%);",

    "& li": {
      display: "inline-block",

      "& a": {
        padding: "0px 10px",
        lineHeight: "30px",
        fontWeight: "bold",
        fontSize: "15px",

        "&.selected": {
          background: "linear-gradient(0deg, rgba(111,89,19,1) 0%, rgba(128,111,24,1) 48%, rgba(145,125,51,1) 52%, rgba(126,106,19,1) 100%);",
          color: "white",
        },

        "&:hover": {
          background: "linear-gradient(0deg, rgba(129,100,28,1) 0%, rgba(138,105,30,1) 48%, rgba(162,130,64,1) 52%, rgba(147,114,34,1) 100%);",
          color: "white !important",
        },
      },
    },
  },
});

const links = [
  {
    "name": "1. 成交佣金",
    "pathname": "/comm",
    "onClick": commLookup,
    "subPaths": [
      "/comm",
      "/commDetail",
      "/commDetail2",
    ],
  },
  {
    "name": "1.1.預支佣金",
    "pathname": "/prepaid",
    "subPaths": [
      "/prepaid",
      "/prepaidDetail",
    ],
  },
  {
    "name": "1.2.獎金分成",
    "pathname": "/commBonusShare",
    "subPaths": [
      "/commBonusShare",
      "/commBonusShareDetail",
    ],
  },
  {
    "name": "2. 員工資料",
    "pathname": "/employee",
    "subPaths": [
      "/employee",
      "/employeeDetail",
    ],
  },
  {
    "name": "3. 主管資料",
    "pathname": "/supervisor",
    "subPaths": [
      "/supervisor",
      "/supervisorDetail",
    ],
  },
  {
    "name": "4. 分行人數",
    "pathname": "/branchHeadCount",
    "subPaths": [
      "/branchHeadCount",
      "/branchHeadCountDetail",
      "/branchHeadCountDetails",
    ],
  },
  {
    "name": "4.1. 放假日數",
    "pathname": "/employeeVacation",
    "subPaths": [
      "/employeeVacation",
      "/employeeVacationDetail",
      "/employeeVacationDetails",
    ],
  },
  {
    "name": "4.2 Bank Referral",
    "pathname": "/bankReferral",
    "subPaths": [
      "/bankReferral",
      "/bankReferralDetails",
    ],
  },
  {
    "name": "5. 佣金拆賬表",
    "pathname": "/tableRate",
    "subPaths": [
      "/tableRate",
      "/tableRateDetail",
    ],
  },
  {
    "name": "6. 到期佣金",
    "pathname": "/commDue",
    "subPaths": [
      "/commDue",
      "/commDueDetail",
    ],
  },
  {
    "name": "7. 報告",
    "pathname": "/report",
    "subPaths": [
      "/report",
      "/reportComm",
      "/reportCommPrepaid",
      "/reportCommSupervisor",
      "/reportCommReceived",
      "/reportCommReduced",
      "/reportBusiness",
      "/reportConclude",
      "/reportEmployeeDue",
      "/reportCommDue",
      "/reportEmployeeTurnover",
      "/reportDebitNote",
      "/reportReceipt",
      "/reportRank",
      "/reportQtyTran",
      "/reportProvision",
      "/reportSupervisorRank",
      "/reportSolicitors",
      "/reportCommOverview",
    ],
  },
  {
    "name": "10. 設定",
    "pathname": "/setting",
    "subPaths": [
      "/setting",
      "/branch",
      "/branchDetail",
      "/team",
      "/teamDetail",
      "/commRef",
      "/commRefDetail",
      "/solicitorsRef",
      "/solicitorsRefDetail",
      "/user",
      "/userDetail",
    ],
  },
];

const publicMobileLinks = [
  {
    "name": "1. 佣紙",
    "isAgent": true,
    "pathname": "/credReportComm",
    "subPaths": [
      "/credReportComm",
    ]
  },
  {
    "name": "1.1. 主管佣紙",
    "isAgent": true,
    "isSupervisor": true,
    "pathname": "/credReportCommSupervisor",
    "subPaths": [
      "/credReportCommSupervisor",
    ]
  },
  {
    "name": "2. 已上數",
    "isAgent": true,
    "pathname": "/credReportBusiness",
    "subPaths": [
      "/credReportBusiness",
    ]
  },
  {
    "name": "3. 營業員應收未收報告",
    "isAgent": true,
    "pathname": "/credReportEmployeeDue",
    "subPaths": [
      "/credReportEmployeeDue",
    ]
  },
  {
    "name": "3.1. 應收未收佣金報告",
    "isAgent": false,
    "pathname": "/credReportCommDue",
    "subPaths": [
      "/credReportCommDue",
    ]
  },
  {
    "name": "4. 已收佣金報告",
    "isAgent": true,
    "isSupervisor": true,
    "pathname": "/credReportCommReceived",
    "subPaths": [
      "/credReportCommReceived",
    ]
  },
  {
    "name": "5. 營業員排位",
    "isAgent": true,
    "isSupervisor": true,
    "pathname": "/credReportRank",
    "subPaths": [
      "/credReportRank",
    ]
  },
  {
    "name": "6. 經理業績報告",
    "isAgent": true,
    "isSupervisor": true,
    "pathname": "/credReportSupervisorRank",
    "subPaths": [
      "/credReportSupervisorRank",
    ]
  },
];

export default function NavMenu() {
  const classes = useStyles();

  const { jWTClaimModel: { Creds, User: { DfnRole } } } = useSelector(state => {
    return state.auth;
  });
  //console.log('DfnRole', DfnRole);
  const isSupervisor = (DfnRole == "S"); // S = Supervisor, E = Employee
  const hasCredAgent = Creds.includes("AGENT");
  //console.log('isSupervisor', isSupervisor);
  //console.log('Creds', Creds);

  // 同事
  if (Creds.includes("PUBLIC_MOBILE")) return ( // 會開 PUBLIC_MOBILE 介面嘅人，現時有 agent、經理、分行文員，見 [dfn_role_credential_base] set 比 [dfn_role]
    <ul className={classes.root}>
      {_.filter(publicMobileLinks, (link) => {
        // 2 支 flag: 1) supervisor, 2) User 有 AGENT cred
        let pass = true;
        pass &= (_.isUndefined(link.isSupervisor)) || (link.isSupervisor && isSupervisor) || (!link.isSupervisor && !isSupervisor);
        pass &= (_.isUndefined(link.isAgent)) || (link.isAgent && hasCredAgent) || (!link.isAgent && !hasCredAgent);
        return pass;
      }).map((link, i) => {
        return <MyNavItem key={`link${i}`} link={link} />;
      })}
    </ul>
  )

  // account
  return (
    <ul className={classes.root}>
      {links.map((link, i) => {
        return <MyNavItem key={`link${i}`} link={link} />;
      })}
    </ul>
  );
}

export function MyNavItem({ link }) {
  const location = useLocation();

  const dispatch = useDispatch();

  const { name, pathname, subPaths, onClick } = link;

  // request by TingTing 2021-12-20. reset filters for <Comm>
  const _onclick = onClick ? (_) => { dispatch(onClick()); } : null;

  var i = _.findIndex(subPaths, function (l) { return `${l}` == location.pathname || location.pathname.startsWith(`${l}/`) });

  return (
    <li>
      {i >= 0
        ? <NavLink tag={Link} to={pathname} className="selected" onClick={_onclick}>{name}</NavLink>
        : <NavLink tag={Link} to={pathname} onClick={_onclick}>{name}</NavLink>
      }
    </li>
  );
}