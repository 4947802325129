import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { vCommDueLookup, vCommDueFetchPage, vCommDuePageUpdate } from '../../redux/vCommDueReducer';

import VCommDueFilter from './VCommDueFilter';
import VCommDueList from './VCommDueList';
import Pagination from '../Pagination';

const useStyles = makeStyles({
    title: {
        paddingBottom: "15px",
        fontSize: "26px",
        lineHeight: "26px",
        fontWeight: "bold",
    },
});

export default function CommDue() {
    const classes = useStyles();

    const history = useHistory();

    const dispatch = useDispatch();

    const newVCommDue = () => {
        history.push("/vCommDueDetail/0");
    }

    const { filter } = useSelector(state => {
        return state.vCommDue;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");

        dispatch(vCommDueLookup());
    }, []);

    return (
        <div>
            <Typography variant="h1" className={classes.title}>6. 到期佣金</Typography>
            <VCommDueFilter filter={filter} />
            <Pagination filter={filter} fnFetchPage={vCommDueFetchPage} fnPageUpdate={vCommDuePageUpdate} />
            <VCommDueList />
            <div style={{ margin: "5px", textAlign: 'center' }}>
                <Pagination filter={filter} fnFetchPage={vCommDueFetchPage} fnPageUpdate={vCommDuePageUpdate} />
            </div>

        </div>
    );
}
