import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Intl from 'intl';

import Team from "../models/team";
import { teamDetailTableRates } from './tableRateReducer';
import { showLoading, showInfo, showErrorResponse, clearMsg } from './msgReducer';
import { teamLookup as teamSelectionLookup } from './selectionsReducer';


//================== Whole State ==================//
const initialState = {
    isLoading: false,
    filter: { // match EntitiesResult<T>
        allRecords: 0,
        matchedRecords: 0,
        totalRecords: 0,
        qtyPerPage: 100,
        page: 1,
        row: 0,
        filter: "[]",
        sort: JSON.stringify([{ "property": "seq", "direction": "ASC" }]),
        success: true,
        message: '',
    },
    entities: [],
    entityTeamManagers: {},
    errors: '',
    workingEntityId: -1,
    workingEntity: null,
};

//================== Action Types ==================//
export const TeamActions = Object.freeze({
    // filter
    CHANGE_SORT: Symbol("CHANGE_SORT"),
    PAGE_UPDATE: Symbol("PAGE_UPDATE"),
    FETCH_PAGE: Symbol("FETCH_PAGE"),

    // listing
    REQUEST: Symbol("REQUEST"),
    SUCCESS: Symbol("SUCCESS"),
    FAILURE: Symbol("FAILURE"),

    UPDATE_SEQ: Symbol("UPDATE_SEQ"),

    // detail page
    DETAIL_ID: Symbol("DETAIL_ID"),
    DETAIL_SUCCESS: Symbol("DETAIL_SUCCESS"),
    DETAIL_FAILURE: Symbol("DETAIL_FAILURE"),
    DETAIL_UPDATE: Symbol("DETAIL_UPDATE"), 
    DETAIL_DELETED: Symbol("DETAIL_DELETED"),
});


//================== Action Creators ==================//
const _setWorkingEntityId = (id) => {
    return {
        type: TeamActions.DETAIL_ID,
        payload: id,
    }
}

export const teamChangeSort = (field) => {
    return (dispatch, getState) => {
        dispatch({
            type: TeamActions.CHANGE_SORT,
            payload: field,
        });
        const { team: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(teamLookup(filter));
    };
}

export const teamPageUpdate = (page) => {
    return (dispatch, getState) => {
        const { team: { filter } } = getState(); // state updated

        let _page = 1;

        if (page == "") {
            _page = "";
        } else {
            _page = parseInt(page);
            if (_.isNaN(_page)) return;
            if (_page < 1) _page = 1;
            if (_page > Math.ceil(filter.matchedRecords / filter.qtyPerPage)) _page = Math.ceil(filter.matchedRecords / filter.qtyPerPage);
        }

        dispatch({
            type: TeamActions.FETCH_PAGE,
            payload: _page,
        });
    };
}

export const teamFetchPage = (page) => {
    return (dispatch, getState) => {
        dispatch({
            type: TeamActions.FETCH_PAGE,
            payload: page,
        });
        const { team: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(teamLookup(filter));
    };
}

const _teamRequest = () => {
    return {
        type: TeamActions.REQUEST
    }
}

const _teamSuccess = (data) => {
    return {
        type: TeamActions.SUCCESS,
        payload: data,
    }
}

const _teamFailure = (response) => {
    return {
        type: TeamActions.FAILURE,
        payload: response,
    }
}

export const teamLookup = (formData) => {
    const _formData = formData ? formData : initialState.filter;
    //console.log(_formData);
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Team/GetEntities`;

    return (dispatch, getState) => {
        dispatch(_teamRequest());

        const { team } = getState(); // state updated
        if (team.isFilterUpdated) _formData.page = 1; // reset page if filter changed
        if (team.filter.page == "") _formData.page = 1; // reset page if filter changed

        axios.post(apiEndpoint, _formData, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch(_teamSuccess(response.data));
                } else {
                    dispatch(_teamFailure("未能讀取"));
                }
            })
            .catch(error => {
                dispatch(_teamFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    }
}

export const updateSeq = (index, ve) => {
    return (dispatch, getState) => {
        const { team: { entities } } = getState();

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/team/UpdateSeq`;

        return axios.post(apiEndpoint, { seq : entities[index].Seq, ve }, {
            timeout: 30000,
        }).then(response => {
            dispatch(teamLookup());
            dispatch(teamSelectionLookup()); // update team selections
        }).catch(error => {
            dispatch(showErrorResponse(error));
        })
    }
}

const _teamDetailSuccess = (data) => {
    return {
        type: TeamActions.DETAIL_SUCCESS,
        payload: data,
    }
}

const _teamDetailFailure = (response) => {
    return {
        type: TeamActions.DETAIL_FAILURE,
        payload: response,
    }
}

export const teamDetail = (Id, isForce) => {
    return (dispatch, getState) => {
        const { team: { workingEntityId: lastWorkingEntityId } } = getState();
        if (!isForce && lastWorkingEntityId == Id) return; // same entity

        dispatch(_setWorkingEntityId(Id)); // 任何 dispatch 之前先記住 current working entity Id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Team/GetEntity`;

        dispatch(_teamRequest());

        axios.post(apiEndpoint, { Id }, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch(_teamDetailSuccess(response.data));
                    dispatch(teamDetailTableRates());
                } else {
                    dispatch(_teamDetailFailure("未能讀取"));
                }
            })
            .catch(error => {
                dispatch(_teamDetailFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

export const teamDetailUpdate = (field, value) => {
    //console.log(`${field} = ${value}`);
    return {
        type: TeamActions.DETAIL_UPDATE,
        payload: { field, value }
    }
}

export const saveEntity = (history) => {
    return (dispatch, getState) => {
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/team/SaveEntity`;

        const { team: { workingEntity } } = getState();

        dispatch(showLoading());

        axios.post(apiEndpoint, workingEntity, {
            timeout: 30000,
        })
            .then(response => {
                if (history.location.pathname == "/teamDetail/0") {
                    history.replace(`/teamDetail/${response.data.Id}`); // reload detail
                } else {
                    dispatch(teamDetail(response.data.Id, true)); // update detail
                }
                dispatch(showInfo("已保存"));

                const { team: { filter } } = getState(); 
                dispatch(teamLookup(filter)); // update list

                dispatch(teamSelectionLookup()); // update team selections
            })
            .catch(error => {
                dispatch(_teamDetailFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

export const deleteEntity = (history) => {
    return (dispatch, getState, x) => {
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Team/DeleteEntity`;

        const { team: { workingEntity } } = getState();

        dispatch(showLoading());
        dispatch(_teamRequest());

        axios.post(apiEndpoint, workingEntity, {
            timeout: 30000,
        })
            .then(response => {
                dispatch(clearMsg());
                history.push('/team');

                const { team: { filter } } = getState(); 
                dispatch(teamLookup(filter));  // update list

                dispatch(teamSelectionLookup()); // update team selections
            })
            .catch(error => {
                dispatch(_teamDetailFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}


//================== Reducer ==================//
const teamReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);
    let i = -1;

    switch (action.type) {
        case TeamActions.CHANGE_SORT:
            const field = action.payload;
            let sorts = JSON.parse(state.filter.sort);
            i = _.findIndex(sorts, function (o) { return o.property == field; });
            if (i >= 0) {
                sorts[i].direction = sorts[i].direction == "ASC" ? "DESC" : "ASC";
            } else {
                // single sort only
                sorts = [{ "property": field, "direction": "ASC" }];
            }

            _state.filter.sort = JSON.stringify(sorts);
            //console.log('_state.filter.sort', _state.filter.sort);

            teamLookup(_state.filter);
            return _state;

        case TeamActions.PAGE_UPDATE:
            _state.filter.page = action.payload;
            return _state;

        case TeamActions.FETCH_PAGE:
            _state.filter.page = action.payload;
            return _state;

        case TeamActions.REQUEST:
            _state.isLoading = true;
            return _state;

        case TeamActions.SUCCESS:
            _state.isLoading = false;
            _state.filter = {
                allRecords: action.payload.allRecords,
                matchedRecords: action.payload.matchedRecords,
                totalRecords: action.payload.totalRecords,
                qtyPerPage: action.payload.qtyPerPage,
                page: action.payload.page,
                row: action.payload.row,
                filter: action.payload.filter,
                sort: action.payload.sort,
                success: action.payload.success,
                message: action.payload.message,
            };
            _state.entities = action.payload.entities.map(entity => new Team(entity));
            _state.errors = "";
            _state.isFilterUpdated = false;
            return _state;

        case TeamActions.FAILURE:
            _state.isLoading = false;
            _state.errors = action.payload;
            _state.entities = [];
            return _state;

        case TeamActions.DETAIL_ID:
            _state.workingEntityId = action.payload;
            return _state;

        case TeamActions.DETAIL_SUCCESS:

            _state.isLoading = false;
            _state.workingEntity = new Team(action.payload);
            _state.workingEntityId = _state.workingEntity.Id;

            return _state;

        case TeamActions.DETAIL_FAILURE:
            _state.isLoading = false;
            _state.errors = action.payload;
            return _state;

        case TeamActions.DETAIL_UPDATE:
            const { field: updateField, value: updateValue } = action.payload;
            _state.workingEntity[updateField] = updateValue;
            return _state;

        default:
            return state;
    }
}

export default teamReducer;
