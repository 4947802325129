export default class Bonu {
    constructor({
        Id,
        IdTableRate,
        Class,
        Target,
        Bonus,

    }) {
        this.Id = Id;
        this.IdTableRate = IdTableRate;
        this.Class = Class;
        this.Target = Target;
        this.Bonus = Bonus;
    }

    isNew() {
        return !this.Id || (this.Id < 1);
    }
}
