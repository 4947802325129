import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Moment from 'moment';

import ReportCommReceived from '../models/reportCommReceived';

import { showLoading, showInfo, showErrorResponse, clearMsg } from './msgReducer';

//================== Whole State ==================//
const initialState = {
    isLoading: false,
    isFilterUpdated: false,
    filter: { // match EntitiesResult<T>
        allRecords: 0,
        matchedRecords: 0,
        totalRecords: 0,
        qtyPerPage: 100,
        page: 1,
        row: 0,
        filter: JSON.stringify([
            { "property": "fDate", "value": Moment.utc().format("YYYY-MM-01") },
            { "property": "tDate", "value": Moment.utc().endOf('month').format("YYYY-MM-DD") },
            { "property": "optClass", "value": "__ANY__" },
            { "property": "idBranch", "value": "" },
            { "property": "superviseIdemployee", "value": "" },
        ]),
        sort: JSON.stringify([{ "property": "date_deposit", "direction": "DESC" }]),
        success: true,
        message: '',
    },
    entities: [],
    totalAmount: 0,
    errors: '',
};


//================== Action Types ==================//
export const ReportCommReceivedActions = Object.freeze({
    // filter
    FILTER_UPDATE: Symbol("FILTER_UPDATE"),
    CHANGE_SORT: Symbol("CHANGE_SORT"),
    PAGE_UPDATE: Symbol("PAGE_UPDATE"),
    FETCH_PAGE: Symbol("FETCH_PAGE"), 

    // listing
    REQUEST: Symbol("REQUEST"), 
    SUCCESS: Symbol("SUCCESS"), 
    FAILURE: Symbol("FAILURE"),

    GOTO_COMM_DETAIL: Symbol("GOTO_COMM_DETAIL"),
});


//================== Action Creators ==================//
export const reportCommReceivedFilterUpdate = (field, value) => {
    return {
        type: ReportCommReceivedActions.FILTER_UPDATE,
        payload: {
            field: field,
            value: value
        }
    }
}

export const reportCommReceivedFilterClear = () => {
    return (dispatch) => {
        dispatch(reportCommReceivedLookup(initialState.filter));
    }
}

export const reportCommReceivedChangeSort = (field) => {
    return (dispatch, getState) => {
        dispatch({
            type: ReportCommReceivedActions.CHANGE_SORT,
            payload: field,
        });
        const { reportCommReceived: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(reportCommReceivedLookup(filter));
    };
}

export const reportCommReceivedPageUpdate = (page) => {
    return (dispatch, getState) => {
        const { reportCommReceived: { filter } } = getState(); // state updated

        let _page = 1;

        if (page == "") {
            _page = "";
        } else {
            _page = parseInt(page);
            if (_.isNaN(_page)) return;
            if (_page < 1) _page = 1;
            if (_page > Math.ceil(filter.matchedRecords / filter.qtyPerPage)) _page = Math.ceil(filter.matchedRecords / filter.qtyPerPage);
        }

        dispatch({
            type: ReportCommReceivedActions.FETCH_PAGE,
            payload: _page,
        });
    };
}

export const reportCommReceivedFetchPage = (page) => {
    return (dispatch, getState) => {
        dispatch({
            type: ReportCommReceivedActions.FETCH_PAGE,
            payload: page,
        });
        const { reportCommReceived: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(reportCommReceivedLookup(filter));
    };
}

const _reportCommReceivedRequest = () => {
    return {
        type: ReportCommReceivedActions.REQUEST
    }
}

const _reportCommReceivedSuccess = (data) => {
    return {
        type: ReportCommReceivedActions.SUCCESS,
        payload: data,
    }
}

const _reportCommReceivedFailure = (response) => {
    return {
        type: ReportCommReceivedActions.FAILURE,
        payload: response,
    }
}

export const reportCommReceivedLookup = (formData) => {
    const _formData = formData ? formData : initialState.filter;
    //console.log(_formData);
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/ReportCommReceived/GetEntities`;

    return (dispatch, getState) => {
        dispatch(_reportCommReceivedRequest());

        const { reportCommReceived } = getState(); // state updated
        if (reportCommReceived.isFilterUpdated) _formData.page = 1; // reset page if filter changed
        if (reportCommReceived.filter.page == "") _formData.page = 1; // reset page if filter changed

        axios.post(apiEndpoint, _formData, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch(_reportCommReceivedSuccess(response.data)); 
                } else {
                    dispatch(_reportCommReceivedFailure("未能讀取"));
                }
            })
            .catch(error => {
                dispatch(_reportCommReceivedFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    }
}

export const gotoCommDetail = (idComm) => {
    return {
        type: ReportCommReceivedActions.GOTO_COMM_DETAIL,
        payload: idComm,
    }
}

//================== Reducer ==================//
const reportCommReceivedReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);
    let i = -1;

    switch (action.type) {
        case ReportCommReceivedActions.FILTER_UPDATE:
            const { field: filterField, value } = action.payload;
            let filters = JSON.parse(state.filter.filter);
            const iFilter = _.findIndex(filters, function (o) { return o.property == filterField; });
            if (iFilter >= 0) {
                if (value) filters[iFilter].value = value;
                else filters.splice(iFilter, 1);
            } else {
                filters.push({ "property": filterField, "value": value });
            }

            _state.filter.filter = JSON.stringify(filters);
            _state.isFilterUpdated = true;
            //console.log('_state.filter', _state.filter.filter);
            return _state;

        case ReportCommReceivedActions.CHANGE_SORT:
            const field = action.payload;
            let sorts = JSON.parse(state.filter.sort);
            i = _.findIndex(sorts, function (o) { return o.property == field; });
            if (i >= 0) {
                sorts[i].direction = sorts[i].direction == "ASC" ? "DESC" : "ASC";
            } else {
                // single sort only
                sorts = [{ "property": field, "direction": "ASC" }];
            }

            _state.filter.sort = JSON.stringify(sorts);
            //console.log('_state.filter.sort', _state.filter.sort);

            reportCommReceivedLookup(_state.filter);
            return _state;

        case ReportCommReceivedActions.PAGE_UPDATE:
            _state.filter.page = action.payload;
            return _state;

        case ReportCommReceivedActions.FETCH_PAGE:
            _state.filter.page = action.payload;
            return _state;

        case ReportCommReceivedActions.REQUEST:
            _state.isLoading = true;
            return _state;

        case ReportCommReceivedActions.SUCCESS:
            _state.isLoading = false;
            _state.filter = {
                allRecords: action.payload.allRecords,
                matchedRecords: action.payload.matchedRecords,
                totalRecords: action.payload.totalRecords,
                qtyPerPage: action.payload.qtyPerPage,
                page: action.payload.page,
                row: action.payload.row,
                filter: action.payload.filter,
                sort: action.payload.sort,
                success: action.payload.success,
                message: action.payload.message,
            };
            _state.entities = action.payload.entities.map(e => new ReportCommReceived(e));
            _state.totalAmount = parseFloat(action.payload.message); // success 時借用了 message 裝 taotalAMount, 見 cReportCommReceieved.cs
            _state.errors = "";
            _state.isFilterUpdated = false;
            return _state;

        case ReportCommReceivedActions.FAILURE:
            _state.isLoading = false;
            _state.errors = action.payload;
            _state.entities = [];
            return _state;

        case ReportCommReceivedActions.GOTO_COMM_DETAIL:
            const idComm = action.payload;
            _state.workingEntityId = idComm;
            return _state;

        default:
            return state;
    }
}

export default reportCommReceivedReducer;

