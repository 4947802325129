import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';
import { NavLink } from 'react-router-dom'

import { branchChangeSort, updateSeq, toggleBranchManager } from '../../redux/branchReducer';
import ReactMoment from "react-moment";
import Moment from "moment";

import BeatLoader from "react-spinners/BeatLoader";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles({
  tblList: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& thead": {
      backgroundColor: "#c5e2ff",
      color: "#45525c",
    },

    "& tr": {
      cursor: "pointer",
    },

    "& tr.alt": {
      backgroundColor: "#f7fbff",
    },

    "& tr.lastWorkingEntity": {
      border: "2px solid #da59da",
    },

    "& tbody tr:hover, tbody tr.alt:hover": {
      backgroundColor: "#ffffcc !important",
    },

    "& th": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },

    "& tr.breakdown": {
      borderLeft: '1px solid #B0B0B0',
      borderRight: '1px solid #B0B0B0',

      "& td": {
        border: 'none',
        backgroundColor: '#dddddd',
      }
    },
  },
});

export default function BranchList() {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading, filter, entities, workingEntityId, entityBranchManagers, errors } = useSelector(state => {
    return state.branch;
  });

  const { dfnDistrictManagers } = useSelector(state => state.selections);

  const { page, qtyPerPage } = filter;

  const sorts = JSON.parse(filter.sort);

  const _getSortDirection = (field) => {
    const i = _.findIndex(sorts, function (o) { return o.property == field; });
    return (i >= 0) ? sorts[i].direction : "";
  };

  const changeSort = (field) => {
    dispatch(branchChangeSort(field, filter));
  };

  const showDetail = (id) => {
    history.push(`/branchDetail/${id}`);
  }

  const myRef = useRef(null);
  useEffect(() => {
    if (myRef.current) myRef.current.scrollIntoView({ block: "center" });
  }, []);

  return (
    <table className={classes.tblList} >
      <thead>
        <tr>
          <th>行</th>
          <th onClick={(_) => changeSort('id')}><span>編號</span><MyArrowIcon direction={_getSortDirection("id")} /></th>
          <th onClick={(_) => changeSort('branch')}><span>分行</span><MyArrowIcon direction={_getSortDirection("branch")} /></th>
          <th onClick={(_) => changeSort('id_district_manager')}><span>分區</span><MyArrowIcon direction={_getSortDirection("id_district_manager")} /></th>
          <th width="170" onClick={(_) => changeSort('seq')}><span>排序</span><MyArrowIcon direction={_getSortDirection("seq")} /></th>
          <th onClick={(_) => changeSort('fdate')}><span>開始日期</span><MyArrowIcon direction={_getSortDirection("fdate")} /></th>
          <th onClick={(_) => changeSort('tdate')}><span>結束日期</span><MyArrowIcon direction={_getSortDirection("tdate")} /></th>
          <th>有效</th>
          <th><Button onClick={(e) => { _.forEach(entities, function (entity, index) { dispatch(toggleBranchManager(entity, index)) }); }} size="small">顯示業績經理</Button></th>
        </tr>
      </thead>

      {isLoading
        ?
        <tbody>
          <tr>
            <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
          </tr>
        </tbody>
        :
        (errors !== "")
          ?
          <tbody>
            <tr>
              <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
            </tr>
          </tbody >
          :

          <tbody>
            {
              (entities.length < 1) && <tr>
                <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>沒有符合的記錄</em></td>
              </tr>
            }
            {
              entities.map((entity, index) => {
                const ddManager = _.find(dfnDistrictManagers, function (ddm) { return ddm.Id == entity.IdDistrictManager });
                const managers = entityBranchManagers[index];
                return <React.Fragment key={entity.Id}>
                  <tr ref={workingEntityId == entity.Id ? myRef : null} className={clsx({ "lastWorkingEntity": workingEntityId == entity.Id, "alt": index % 2 == 1, "normal": index % 2 == 0 })} onClick={() => { showDetail(entity.Id) }}>
                    <td>{(page - 1) * qtyPerPage + index + 1}</td>
                    <td>{entity.Id}</td>
                    <td>{entity.Branch1}</td>
                    <td>{ddManager ? ddManager.District : "---"}</td>
                    <td>
                      <BtnSeq index={index} seq={entity.Seq} />
                    </td>
                    <td>{entity.Fdate ? <ReactMoment format="YYYY-MM-DD" date={entity.Fdate} /> : "---"}</td>
                    <td>{entity.Tdate ? <ReactMoment format="YYYY-MM-DD" date={entity.Tdate} /> : "--- 應用中 ---"}</td>
                    <td><MySettleIcon Tdate={entity.Tdate} /></td>
                    <td><BtnToggleManager entity={entity} index={index} /></td>
                  </tr>
                  {managers &&
                    <TrBreakDown managers={managers} />
                  }
                </React.Fragment>
              })
            }
          </tbody>
      }
    </table>
  );
}

function MyArrowIcon({ direction }) {
  switch (direction) {
    case "ASC": return <ArrowDropUpIcon />;
    case "DESC": return <ArrowDropDownIcon />;
    default: return null;
  }
}

function MySettleIcon({ Tdate }) {
  const now = new Date();
  const today = new Date(now.getYear(), now.getMonth(), now.getDate());
  if (!Tdate || Tdate <= today) return <CheckCircleIcon style={{ color: "#4fa917" }} />;
  return <CancelIcon style={{ color: "#e43e3e" }} />
}

function BtnSeq({ index, seq }) {
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();

  const minusSeq = (e) => {
    e.stopPropagation();
    dispatch(updateSeq(index, -1));
  };

  const addictSeq = (e) => {
    e.stopPropagation();
    dispatch(updateSeq(index, 1));
  }

  return (
    !isEditing
      ? <Button size="small" onClick={(e) => { e.stopPropagation(); setIsEditing(true); }}>{seq}</Button>
      : <>
        <IconButton size="small" onClick={minusSeq}><RemoveCircleIcon /></IconButton>
        <Button size="small" onClick={(e) => { e.stopPropagation(); setIsEditing(false); }}>{seq}</Button>
        <IconButton size="small" onClick={addictSeq}><AddCircleIcon /></IconButton>
      </>
  );
}

function BtnToggleManager({ entity, index }) {
  const dispatch = useDispatch();

  const { entityBranchManagers } = useSelector(state => {
    return state.branch;
  });

  const toggle = (e) => {
    e.stopPropagation();
    dispatch(toggleBranchManager(entity, index));
  };

  return <IconButton size="small" onClick={toggle}>{entityBranchManagers[index] ? <ExpandLessIcon /> : < ExpandMoreIcon />}</IconButton>;
}

function TrBreakDown({ managers }) {
  return managers.map((manager, index) => {
    const lastTdate = Moment(managers[index - 1]?.Tdate);
    const thisFdate = Moment(manager?.Fdate);
    const isSkipped = (index !== 0) && (lastTdate.diff(thisFdate, 'days') !== -1);
    //console.log(isSkipped);

    return <tr className="breakdown" key={manager.IdSupervisorTeam}>
      <td colSpan="4">&nbsp;</td>
      <td><NavLink to={`supervisorDetail/${manager.IdSupervisor}`}>{manager.Name}</NavLink></td>
      <td>{manager.Fdate ? <ReactMoment format="YYYY-MM-DD" style={{ color: (isSkipped ? "red" : "inherit") }} date={manager.Fdate} /> : "---"}</td>
      <td>{manager.Tdate ? <ReactMoment format="YYYY-MM-DD" date={manager.Tdate} /> : "--- 應用中 ---"}</td>
      <td colSpan="2">&nbsp;</td>
    </tr>;
  });
}