import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { employeeDetailUpdate } from '../../redux/employeeReducer';

export default function EmployeeDetailInputField({ fieldName, width, tabIndex, textAlign }) {
    const dispatch = useDispatch();
    const { workingEntity } = useSelector(state => {
        return state.employee;
    });

    return <input value={workingEntity[fieldName] ? workingEntity[fieldName] : ''} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(employeeDetailUpdate(fieldName, e.target.value)); }} />
}