import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Moment from 'moment';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import _ from 'lodash';

import { downloadPdf } from '../../extensions/FileExtensions';
import { LocalModelMsg } from "../ModalMsg";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { DfnDistrictManagerSelection } from "../Selections";

const useStyles = makeStyles({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& a.nav-link:hover h1": {
            color: "#006ed0",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },
    },

    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },

        "& label": {
            marginBottom: "0px",
        }
    },
});

export default function CredReportRank() {
    const classes = useStyles();

    const { jWTClaimModel: { Creds, User: { Role } } } = useSelector(state => {
        return state.auth;
    });

    const [state, setState] = useState({
        fDate: Moment.utc().format("YYYY-MM-01"),
        tDate: Moment.utc().endOf('month').format("YYYY-MM-DD"),
        isHideLeaved: "__NO__",
        isHideEmpty: "__YES__",
    });

    const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

    const print = () => {
        const filter = _.keys(state).map(key => ({
            property: key,
            value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
        }));
        //console.log(filter);

        downloadPdf("/api/CredReportRank/GetPDF", {
            filter: JSON.stringify(filter)
        }, '營業員排位.pdf', msgState, msgSetState);
    }

    return (
        <>
            <Breadcrumbs className={classes.title}>
                <Typography variant="h1" className={classes.title}>4. 營業員排位</Typography>
            </Breadcrumbs>

            <div className="form-group row">
                <label htmlFor="idDfnDistrictManager" className="col-sm-2 col-form-label">分區</label>
                <div className="col-sm-10">
                    <DfnDistrictManagerSelection className="form-control" showAny={true} value={state.idDfnDistrictManager} onChange={(e) => { setState({ ...state, idDfnDistrictManager: e.target.value }); }} tabIndex="1" />
                </div>
            </div>

            <div className="form-group row">
                <label className="col-sm-2 col-form-label">計算年度及月份</label>
                <div className="col-sm-10">
                    <input
                        className="form-control"
                        type="date"
                        value={state.fDate}
                        onChange={(e) => { setState({ ...state, fDate: e.target.value }); }}
                        tabIndex="1"
                    />
                </div>
            </div>

            <div className="form-group row">
                <label className="d-none d-sm-block col-sm-2 col-form-label">&nbsp;</label>
                <div className="col-sm-10">
                    <span>至</span>
                </div>
            </div>

            <div className="form-group row">
                <label className="d-none d-sm-block col-sm-2 col-form-label">&nbsp;</label>
                <div className="col-sm-10">
                    <input
                        className="form-control"
                        type="date"
                        value={state.tDate}
                        onChange={(e) => { setState({ ...state, tDate: e.target.value }); }}
                        tabIndex="1"
                    />
                </div>
            </div>

            <div className="form-group row">
                <label className="none d-sm-block col-sm-2 col-form-label">隱藏已離職</label>
                <FormControl>
                    <RadioGroup className="col-sm-10" row aria-label="隱藏已離職" onChange={(event) => setState({ ...state, isHideLeaved: event.target.value })} value={state.isHideLeaved}>
                        <FormControlLabel value="__NO__" control={<Radio />} label="顯示全部" />
                        <FormControlLabel value="__YES__" control={<Radio />} label="隱藏已離職的營業員" />
                    </RadioGroup>
                </FormControl>
            </div>

            <div className="form-group row">
                <label className="none d-sm-block col-sm-2 col-form-label">隱藏沒有金額</label>
                <FormControl>
                    <RadioGroup className="col-sm-10" row aria-label="隱藏沒有金額" onChange={(event) => setState({...state, isHideEmpty:event.target.value })} value={state.isHideEmpty}>
                        <FormControlLabel value="__YES__" control={<Radio />} label="隱藏沒有金額的營業員" />
                        <FormControlLabel value="__NO__" control={<Radio />} label="顯示全部" />
                    </RadioGroup>
                </FormControl>
            </div>

            <div className="mt-2 mb-3" style={{ textAlign: "center" }}>
                <Button size="small" variant="contained" disableElevation onClick={print}>列　　印</Button>
            </div>

            <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
        </>
    );
}

function CredRptRankFilterRadioField({ state, setState, fieldName, label, value, index, tabIndex }) {
    const name = `filter_${fieldName}_${index}`;
    const id = `${name}_${value}_${index}`;
    return <span className="mr-1 ml-1">
        <input className="mr-1 form-control" type="radio" tabIndex={tabIndex} value={value} id={id} name={name} onChange={(e) => { setState({ ...state, [fieldName]: e.target.value, }); }} checked={state[fieldName] == value} />
        <label htmlFor={id}>{label}</label>
    </span>;
}
