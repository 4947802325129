import React, { useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { solicitorsRefChangeSort } from '../../redux/solicitorsRefReducer';

import BeatLoader from "react-spinners/BeatLoader";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles({
    tblList: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& thead": {
            backgroundColor: "#c5e2ff",
            color: "#45525c",
        },

        "& tr": {
            cursor: "pointer",
        },

        "& tr.alt": {
            backgroundColor: "#f7fbff",
        },

        "& tr.lastWorkingEntity": {
            border: "2px solid #da59da",
        },

        "& tbody tr:hover, tbody tr.alt:hover": {
            backgroundColor: "#ffffcc !important",
        },

        "& th": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },

        "& tr.breakdown": {
            borderLeft: '1px solid #B0B0B0',
            borderRight: '1px solid #B0B0B0',

            "& td": {
                border: 'none',
                backgroundColor: '#dddddd',
            }
        },
    },
});

export default function SolicitorsRefList() {
    const classes = useStyles();

    const history = useHistory();
    const dispatch = useDispatch();

    const { isLoading, filter, entities, workingEntityId, errors } = useSelector(state => {
        return state.solicitorsRef;
    });

    const { dfnDistrictManagers } = useSelector(state => state.selections);

    const { page, qtyPerPage } = filter;

    const sorts = JSON.parse(filter.sort);

    const _getSortDirection = (field) => {
        const i = _.findIndex(sorts, function (o) { return o.property == field; });
        return (i >= 0) ? sorts[i].direction : "";
    };

    const changeSort = (field) => {
        dispatch(solicitorsRefChangeSort(field, filter));
    };

    const showDetail = (id) => {
        history.push(`/solicitorsRefDetail/${id}`);
    }

    const myRef = useRef(null);
    useEffect(() => {
        if (myRef.current) myRef.current.scrollIntoView({ block: "center" });
    }, []);

    return (
        <table className={classes.tblList} >
            <thead>
                <tr>
                    <th>行</th>
                    <th onClick={(_) => changeSort('id')}><span>編號</span><MyArrowIcon direction={_getSortDirection("id")} /></th>
                    <th onClick={(_) => changeSort('solicitors_chi')}><span>律師樓(中)</span><MyArrowIcon direction={_getSortDirection("solicitors_chi")} /></th>
                    <th onClick={(_) => changeSort('solicitors_eng')}><span>律師樓(英)</span><MyArrowIcon direction={_getSortDirection("solicitors_eng")} /></th>
                    <th onClick={(_) => changeSort('lawyer_chi')}><span>律師(中)</span><MyArrowIcon direction={_getSortDirection("lawyer_chi")} /></th>
                    <th onClick={(_) => changeSort('lawyer_eng')}><span>律師(英)</span><MyArrowIcon direction={_getSortDirection("lawyer_eng")} /></th>
                    <th onClick={(_) => changeSort('lawyer_tel')}><span>律師 Tel</span><MyArrowIcon direction={_getSortDirection("lawyer_tel")} /></th>
                </tr>
            </thead>

            {isLoading
                ?
                <tbody>
                    <tr>
                        <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
                    </tr>
                </tbody>
                :
                (errors !== "")
                    ?
                    <tbody>
                        <tr>
                            <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
                        </tr>
                    </tbody >
                    :

                    <tbody>
                        {
                            (entities.length < 1) && <tr>
                                <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>沒有符合的記錄</em></td>
                            </tr>
                        }
                        {
                            entities.map((entity, index) => {
                                return <React.Fragment key= { entity.Id }>
                                    <tr ref={workingEntityId == entity.Id ? myRef : null} className={clsx({ "lastWorkingEntity": workingEntityId == entity.Id, "alt": index % 2 == 1, "normal": index % 2 == 0 })} onClick={() => { showDetail(entity.Id) }}>
                                        <td>{(page - 1) * qtyPerPage + index + 1}</td>
                                        <td>{entity.Id}</td>
                                        <td>{entity.SolicitorsChi}</td>
                                        <td>{entity.SolicitorsEng}</td>
                                        <td>{entity.LawyerChi}</td>
                                        <td>{entity.LawyerEng}</td>
                                        <td>{entity.LawyerTel}</td>
                                    </tr>
                                </React.Fragment>
                                })
                        }
                    </tbody>
            }
        </table>
    );
}

function MyArrowIcon({ direction }) {
    switch (direction) {
        case "ASC": return <ArrowDropUpIcon />;
        case "DESC": return <ArrowDropDownIcon />;
        default: return null;
    }
}
