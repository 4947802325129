import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Moment from 'moment';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import _ from 'lodash';

import { downloadPdf } from '../../extensions/FileExtensions';
import { LocalModelMsg } from "../ModalMsg";

import { CalMonthSelection, CredBranchSelection, CredEmployeeSelection } from "../Selections";

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },

  frmFilter: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& th": {
      fontWeight: 'bold',
      border: "1px solid #B0B0B0",
      backgroundColor: "#FAF3DE",
      padding: "2px 4px",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      padding: "2px 4px",
    },

    "& label": {
      marginBottom: "0px",
    }
  },
});

export default function CredReportBusiness() {
  const classes = useStyles();

  const { jWTClaimModel: { Creds, User: { Role } } } = useSelector(state => {
    return state.auth;
  });

  const isSupervisor = (Role == "主管");

  const [state, setState] = useState({
    idDfnDistrictManager: "",
    fCalMonth: Moment.utc().format("YYYY-MM-01"),
    tCalMonth: Moment.utc().endOf('month').format("YYYY-MM-DD"),
    idBranch: "",
    idEmployee: "",
  });

  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

  const print = () => {
    const filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    downloadPdf("/api/CredReportBusiness/GetPDF", {
      filter: JSON.stringify(filter)
    }, '已上數.pdf', msgState, msgSetState);
  }

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <Typography variant="h1" className={classes.title}>2. 已上數</Typography>
      </Breadcrumbs>

      <div className="form-group row">
        <label htmlFor="fCalMonth" className="col-sm-2 col-form-label">計算年度及月份</label>
        <div className="col-sm-10">
          <CalMonthSelection className="form-control" value={state.fCalMonth} onChange={(e) => { setState({ ...state, fCalMonth: e.target.value }); }} tabIndex="1" />
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="tCalMonth" className="d-none d-sm-block col-sm-2 col-form-label">&nbsp;</label>
        <div className="col-sm-10">
          <span>至</span>
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="tCalMonth" className="d-none d-sm-block col-sm-2 col-form-label">&nbsp;</label>
        <div className="col-sm-10">
          <CalMonthSelection className="form-control" value={state.tCalMonth} onChange={(e) => { setState({ ...state, tCalMonth: e.target.value }); }} tabIndex="2" isEndOfMonth={true} />
        </div>
      </div>

      {isSupervisor && <div className="form-group row">
        <label htmlFor="calMonth" className="col-sm-2 col-form-label">分行:</label>
        <div className="col-sm-10">
          <CredBranchSelection className="form-control" value={state.idBranch} onChange={(e) => { setState({ ...state, idBranch: e.target.value }); }} tabIndex="3" />
        </div>
      </div>
      }

      {!state.idBranch && isSupervisor && <div className="form-group row">
        <label htmlFor="calMonth" className="col-sm-2 col-form-label">同事:</label>
        <div className="col-sm-10">
          <CredEmployeeSelection showAny={false} className="form-control" value={state.idEmployee} onChange={(e) => { setState({ ...state, idEmployee: e.target.value }); }} tabIndex="3" />
        </div>
      </div>
      }

      <div className="mt-2 mb-3" style={{ textAlign: "center" }}>
        <Button size="small" variant="contained" disableElevation onClick={print}>列　　印</Button>
      </div>

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
    </>
  );
}
