import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import axios from 'axios';

import { reportCommPrepaidOptionUpdate } from '../../../redux/reportCommPrepaidReducer';

import { CalMonthSelection, EmployeeSelection } from '../../Selections';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },

        "& label": {
            marginBottom: "0px",
        }
    },
});

export default function ReportCommPrepaidOption() {
    const classes = useStyles();

    const dispatch = useDispatch();

    const { option: { calMonth, hasEarnOnly, sort, reCalIdEmployee } } = useSelector(state => {
        return state.reportCommPrepaid;
    });

    const print = () => {
        console.log('print', { calMonth, hasEarnOnly, sort });
    }

    const reCal = () => {
        console.log('reCal', reCalIdEmployee);
    }

    return (
        <div style={{width:'550px'}}>
            <table className={classes.frmFilter}>
                <tbody>
                    <tr>
                        <th style={{ textAlign: 'right', width: '130px' }}>計算日期：</th>
                        <td>
                            <CalMonthSelection value={calMonth} onChange={(e) => { dispatch(reportCommPrepaidOptionUpdate('calMonth', e.target.value)); }} tabIndex={1} />
                        </td>
                    </tr>
                    <tr>
                        <th style={{ textAlign: 'right', width: '130px' }}>計算日期收到佣金：</th>
                        <td>
                            <ReportCommPrepaidRadioField fieldName="hasEarnOnly" label="只顯示計算日收到佣金" value="__YES__" selectedValue={hasEarnOnly} />
                            <ReportCommPrepaidRadioField fieldName="hasEarnOnly" label="所有" value="__NO__" selectedValue={hasEarnOnly} />
                        </td>
                    </tr>
                    <tr>
                        <th style={{ textAlign: 'right', width: '130px' }}>排序選項：</th>
                        <td>
                            <ReportCommPrepaidRadioField fieldName="sort" label="以落訂日期排序" value="__DATE_DEPOSIT__" selectedValue={sort} />
                            <ReportCommPrepaidRadioField fieldName="sort" label="所有" value="__EMPLOYEE__" selectedValue={sort} />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="mt-2" style={{ textAlign: "right" }}>
                <Button size="small" variant="contained" disableElevation onClick={print}>列　　印</Button>
            </div>

            <table className={clsx(classes.frmFilter, "mt-2 mb-3")}>
                <tbody>
                    <tr>
                        <th style={{ textAlign: 'right', width: '130px' }}>手動重新計算：</th>
                        <td>
                            <EmployeeSelection showAny={false} value={reCalIdEmployee} onPicked={(employee) => { dispatch(reportCommPrepaidOptionUpdate('reCalIdEmployee', employee ? employee.Id : "")); }} size={1} tabIndex={4} width="auto" />
                            <Button className="ml-2" size="small" variant="contained" disableElevation onClick={reCal}>計　　算</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

function ReportCommPrepaidRadioField({ fieldName, label, value, selectedValue }) {
    const dispatch = useDispatch();

    const name = `filter_${fieldName}`;
    const id = `${name}_${value}`;
    return <span className="mr-1 ml-1">
        <input className="mr-1" type="radio" value={value} id={id} name={name} onChange={(e) => { dispatch(reportCommPrepaidOptionUpdate(fieldName, e.target.value)); }} checked={selectedValue == value} />
        <label htmlFor={id}>{label}</label>
    </span>;
}
