import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Moment from 'moment';

import { showError } from './msgReducer';

let tDateConclude = new Moment().startOf('day');
//console.log('tDateConclude', tDateConclude);
const dayOfMonth = tDateConclude.date();
if (dayOfMonth <= 10) {
  // end of this month
  tDateConclude = tDateConclude.endOf('month');
}
else if (dayOfMonth <= 20) {
  // 10th of next month
  tDateConclude.add(1, 'months');
  tDateConclude.set({ "date": 10 });
}
else {
  // 20th of next month
  tDateConclude.add(1, 'months');
  tDateConclude.set({ "date": 20 });
}

//================== Whole State ==================//
const initialState = {
  isLoading: false,
  isFilterUpdated: false,
  filter: { // match EntitiesResult<T>
    allRecords: 0,
    matchedRecords: 0,
    totalRecords: 0,
    qtyPerPage: 100,
    page: 1,
    row: 0,
    filter: JSON.stringify([
      { "property": "t_date_conclude", "value": tDateConclude},
      { "property": "class", "value": "SECOND_HAND" },
      { "property": "isPaid", "value": "__ANY__" },
    ]),
    sort: JSON.stringify([{ "property": "date_conclude", "direction": "DESC" }]),
    success: true,
    message: '',
  },
  entities: [],
  workingEntityId: -1,
  errors: '',
  isPrintDebitNoteBuyers: [],
  isPrintDebitNoteOwners: [],
};


//================== Action Types ==================//
export const VCommDueActions = Object.freeze({
  // filter
  FILTER_UPDATE: Symbol("FILTER_UPDATE"),
  CHANGE_SORT: Symbol("CHANGE_SORT"),
  PAGE_UPDATE: Symbol("PAGE_UPDATE"),
  FETCH_PAGE: Symbol("FETCH_PAGE"),

  // listing
  REQUEST: Symbol("REQUEST"),
  SUCCESS: Symbol("SUCCESS"),
  FAILURE: Symbol("FAILURE"),

  GOTO_COMM_DETAIL: Symbol("GOTO_COMM_DETAIL"),
  TICK_DEBIT_NOTE: Symbol("TICK_DEBIT_NOTE"),
  TICK_ALL_DEBIT_NOTES: Symbol("TICK_ALL_DEBIT_NOTES"),
  CLEAR_TICK_ALL_DEBIT_NOTES: Symbol("CLEAR_TICK_ALL_DEBIT_NOTES"),
});


//================== Action Creators ==================//
export const vCommDueFilterUpdate = (field, value) => {
  return {
    type: VCommDueActions.FILTER_UPDATE,
    payload: {
      field: field,
      value: value
    }
  }
}

export const vCommDueFilterClear = () => {
  return (dispatch) => {
    dispatch(vCommDueLookup(initialState.filter));
  }
}

export const vCommDueChangeSort = (field) => {
  return (dispatch, getState) => {
    dispatch({
      type: VCommDueActions.CHANGE_SORT,
      payload: field,
    });
    const { vCommDue: { filter } } = getState(); // state updated
    //console.log(filter);
    dispatch(vCommDueLookup(filter));
  };
}

export const vCommDuePageUpdate = (page) => {
  return (dispatch, getState) => {
    const { vCommDue: { filter } } = getState(); // state updated

    let _page = 1;

    if (page == "") {
      _page = "";
    } else {
      _page = parseInt(page);
      if (_.isNaN(_page)) return;
      if (_page < 1) _page = 1;
      if (_page > Math.ceil(filter.matchedRecords / filter.qtyPerPage)) _page = Math.ceil(filter.matchedRecords / filter.qtyPerPage);
    }

    dispatch({
      type: VCommDueActions.FETCH_PAGE,
      payload: _page,
    });
  };
}

export const vCommDueFetchPage = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: VCommDueActions.FETCH_PAGE,
      payload: page,
    });
    const { vCommDue: { filter } } = getState(); // state updated
    //console.log(filter);
    dispatch(vCommDueLookup(filter));
  };
}

const _vCommDueRequest = () => {
  return {
    type: VCommDueActions.REQUEST
  }
}

const _vCommDueSuccess = (data) => {
  return {
    type: VCommDueActions.SUCCESS,
    payload: data,
  }
}

const _vCommDueFailure = (response) => {
  return {
    type: VCommDueActions.FAILURE,
    payload: response,
  }
}

export const vCommDueLookup = (formData) => {
  const _formData = formData ? formData : initialState.filter;
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/VCommDue/GetEntities`;

  return (dispatch, getState) => {
    dispatch(_vCommDueRequest());

    const { vCommDue } = getState(); // state updated
    if (vCommDue.isFilterUpdated) _formData.page = 1; // reset page if filter changed
    if (vCommDue.filter.page == "") _formData.page = 1; // reset page if filter changed

    axios.post(apiEndpoint, _formData, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_vCommDueSuccess(response.data));
        } else {
          dispatch(_vCommDueFailure("未能讀取"));
        }
      })
      .catch(error => {
        let msg = "";
        if (error.response && error.response.data && error.response.data.title) msg = error.response.data.title;
        else if (error.response && error.response.status) msg = `未能讀取 (${error.response.status})`;
        else msg = '未能聯繫伺服器';

        dispatch(showError(msg));
        dispatch(_vCommDueFailure(msg));
      })
  }
}

export const vCommDueTickAllDebitNotes = (party) => {
  return {
    type: VCommDueActions.TICK_ALL_DEBIT_NOTES,
    payload: party,
  }
}

export const vCommDueClearTickAllDebitNotes = (party) => {
  return {
    type: VCommDueActions.CLEAR_TICK_ALL_DEBIT_NOTES,
    payload: party,
  }
}

export const vCommDueTickDebitNote = (party, index) => {
  return {
    type: VCommDueActions.TICK_DEBIT_NOTE,
    payload: { party, index },
  }
}

export const gotoCommDetail = (idComm) => {
  return {
    type: VCommDueActions.GOTO_COMM_DETAIL,
    payload: idComm,
  }
}

//================== Reducer ==================//
const vCommDueReducer = (state = initialState, action) => {
  let _state = cloneDeep(state);
  let i = 0;

  switch (action.type) {
    case VCommDueActions.FILTER_UPDATE:
      const { field: filterField, value } = action.payload;
      let filters = JSON.parse(state.filter.filter);
      const iFilter = _.findIndex(filters, function (o) { return o.property == filterField; });
      if (iFilter >= 0) {
        if (value) filters[iFilter].value = value;
        else filters.splice(iFilter, 1);
      } else {
        filters.push({ "property": filterField, "value": value });
      }

      _state.filter.filter = JSON.stringify(filters);
      _state.isFilterUpdated = true;
      //console.log('_state.filter', _state.filter.filter);
      return _state;

    case VCommDueActions.CHANGE_SORT:
      const field = action.payload;
      let sorts = JSON.parse(state.filter.sort);
      i = _.findIndex(sorts, function (o) { return o.property == field; });
      if (i >= 0) {
        sorts[i].direction = sorts[i].direction == "ASC" ? "DESC" : "ASC";
      } else {
        // single sort only
        sorts = [{ "property": field, "direction": "ASC" }];
      }

      _state.filter.sort = JSON.stringify(sorts);
      //console.log('_state.filter.sort', _state.filter.sort);

      vCommDueLookup(_state.filter);
      return _state;

    case VCommDueActions.PAGE_UPDATE:
      _state.filter.page = action.payload;
      return _state;

    case VCommDueActions.FETCH_PAGE:
      _state.filter.page = action.payload;
      return _state;

    case VCommDueActions.REQUEST:
      _state.isLoading = true;
      return _state;

    case VCommDueActions.SUCCESS:
      _state.isLoading = false;
      _state.filter = {
        allRecords: action.payload.allRecords,
        matchedRecords: action.payload.matchedRecords,
        totalRecords: action.payload.totalRecords,
        qtyPerPage: action.payload.qtyPerPage,
        page: action.payload.page,
        row: action.payload.row,
        filter: action.payload.filter,
        sort: action.payload.sort,
        success: action.payload.success,
        message: action.payload.message,
      };
      _state.entities = action.payload.entities;
      _state.isPrintDebitNoteBuyers = _state.entities.map(e => false);
      _state.isPrintDebitNoteOwners = _state.entities.map(e => false);
      _state.errors = "";
      _state.isFilterUpdated = false;
      return _state;

    case VCommDueActions.FAILURE:
      _state.isLoading = false;
      _state.errors = action.payload;
      _state.entities = [];
      return _state;

    case VCommDueActions.TICK_ALL_DEBIT_NOTES:
      const party = action.payload;
      switch (party) {
        case "BUYER":
          for (i = 0; i < _state.entities.length; i++) {
            if (!_state.entities[i].CommBuyer) continue;
            if (_state.entities[i].BuyerPayment >= _state.entities[i].CommBuyer) continue;
            _state.isPrintDebitNoteBuyers[i] = true;
          }
          break;
        case "OWNER":
          for (i = 0; i < _state.entities.length; i++) {
            if (!_state.entities[i].CommOwner) continue;
            if (_state.entities[i].OwnerPayment >= _state.entities[i].CommOwner) continue;
            _state.isPrintDebitNoteOwners[i] = true;
          }
          break;
      }
      return _state;

    case VCommDueActions.CLEAR_TICK_ALL_DEBIT_NOTES:
      const partyClear = action.payload;
      switch (partyClear) {
        case "BUYER":
          for (i = 0; i < _state.entities.length; i++) {
            if (!_state.entities[i].CommBuyer) continue;
            if (_state.entities[i].BuyerPayment >= _state.entities[i].CommBuyer) continue;
            _state.isPrintDebitNoteBuyers[i] = false;
          }
          break;
        case "OWNER":
          for (i = 0; i < _state.entities.length; i++) {
            if (!_state.entities[i].CommOwner) continue;
            if (_state.entities[i].OwnerPayment >= _state.entities[i].CommOwner) continue;
            _state.isPrintDebitNoteOwners[i] = false;
          }
          break;
      }
      return _state;

    case VCommDueActions.TICK_DEBIT_NOTE:
      const { party: tickDebitNoteParty, index: tickDebitNoteIndex } = action.payload;
      switch (tickDebitNoteParty) {
        case "BUYER": _state.isPrintDebitNoteBuyers[tickDebitNoteIndex] = !_state.isPrintDebitNoteBuyers[tickDebitNoteIndex]; break;
        case "OWNER": _state.isPrintDebitNoteOwners[tickDebitNoteIndex] = !_state.isPrintDebitNoteOwners[tickDebitNoteIndex]; break;
      }
      return _state;

    case VCommDueActions.GOTO_COMM_DETAIL:
      const idComm = action.payload;
      _state.workingEntityId = idComm;
      return _state;

    default:
      return state;
  }
}

export default vCommDueReducer;
