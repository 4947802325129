export default class SolicitorsRef {
    constructor({
        Id,
        SolicitorsEng,
        SolicitorsChi,
        LawyerEng,
        LawyerChi,
        LawyerTel,
        LawyerFax,
        RemarksLawyer,
    }) {
        this.Id = Id;
        this.SolicitorsEng = SolicitorsEng;
        this.SolicitorsChi = SolicitorsChi;
        this.LawyerEng = LawyerEng;
        this.LawyerChi = LawyerChi;
        this.LawyerTel = LawyerTel;
        this.LawyerFax = LawyerFax;
        this.RemarksLawyer = RemarksLawyer;
    }

    isNew() {
        return !this.Id || (this.Id < 1);
    }

    getDesc() {
        if (this.isNew()) return "新參考律師樓";

        return this.SolicitorsChi ? `#${this.Id} ${this.SolicitorsChi}` : `參考律師樓 #${this.Id}`;
    }
}
