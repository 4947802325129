import React, { useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { vCommTranBonusChangeSort } from '../../redux/vCommTranBonuReducer';
import { showConfirm, showWarning } from '../../redux/msgReducer';
import ReactMoment from "react-moment";

import BeatLoader from "react-spinners/BeatLoader";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Button from '@material-ui/core/Button';

import MyCurrency from '../MyCurrency';

const useStyles = makeStyles({
    tblList: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& thead": {
            backgroundColor: "#c5e2ff",
            color: "#45525c",
        },

        "& tr": {
            cursor: "pointer",
        },

        "& tr.alt": {
            backgroundColor: "#f7fbff",
        },

        "& tr.lastWorkingEntity": {
            border: "2px solid #da59da",
        },

        "& tbody tr:hover, tbody tr.alt:hover": {
            backgroundColor: "#ffffcc !important",
        },

        "& th": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },
    },
});

export default function CommBonusShareList() {
    const classes = useStyles();

    const history = useHistory();
    const dispatch = useDispatch();

    const { isLoading, filter, entities, workingEntityId, errors } = useSelector(state => {
        return state.vCommTranBonus;
    });

    const sorts = JSON.parse(filter.sort);

    const _getSortDirection = (field) => {
        const i = _.findIndex(sorts, function (o) { return o.property == field; });
        return (i >= 0) ? sorts[i].direction : "";
    };

    const changeSort = (field) => {
        dispatch(vCommTranBonusChangeSort(field, filter));
    };

    const showDetail = (id) => {
        history.push(`/commBonusShareDetail/${id}`);
    }

    const confirmJump = (entity) => {
        const fnConfirm = (_) => { history.push(`/commDetail/${entity.IdComm}`); };
        dispatch(showConfirm(`跳到佣金 ${entity.RefNo}？`, fnConfirm));
    };

    const myRef = useRef(null);
    useEffect(() => {
        if (myRef.current) myRef.current.scrollIntoView({ block: "center" });
    }, []);

    return (
        <table className={classes.tblList} >
            <thead>
                <tr>
                    <th onClick={(_) => changeSort('date_deposit')}><span>落訂日期</span><MyArrowIcon direction={_getSortDirection("date_deposit")} /></th>
                    <th onClick={(_) => changeSort('ref_no')}><span>編號</span><MyArrowIcon direction={_getSortDirection("ref_no")} /></th>
                    <th onClick={(_) => changeSort('bldg_chi')}><span>大廈</span><MyArrowIcon direction={_getSortDirection("bldg_chi")} /></th>
                    <th onClick={(_) => changeSort('block')}><span>座</span><MyArrowIcon direction={_getSortDirection("block")} /></th>
                    <th onClick={(_) => changeSort('floor')}><span>層</span><MyArrowIcon direction={_getSortDirection("floor")} /></th>
                    <th onClick={(_) => changeSort('flat')}><span>單位</span><MyArrowIcon direction={_getSortDirection("flat")} /></th>
                    <th onClick={(_) => changeSort('date_tran')}><span>獎金日期</span><MyArrowIcon direction={_getSortDirection("date_tran")} /></th>
                    <th onClick={(_) => changeSort('amount_tran')}><span>獎金</span><MyArrowIcon direction={_getSortDirection("amount_tran")} /></th>
                </tr>
            </thead>

            {isLoading
                ?
                <tbody>
                    <tr>
                        <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
                    </tr>
                </tbody>
                :
                    (errors !== "")
                        ?
                        <tbody>
                            <tr>
                                <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
                            </tr>
                        </tbody >
                        :
                        <tbody>
                            {
                                entities.map((entity, index) => {
                                    return <tr key={entity.Id} ref={workingEntityId == entity.Id ? myRef : null} className={clsx({ "lastWorkingEntity": workingEntityId == entity.Id, "alt": index % 2 == 1, "normal": index % 2 == 0 })} onClick={() => { showDetail(entity.Id) }}>
                                        <td><ReactMoment format="YYYY-MM-DD" date={entity.DateDeposit} /></td>
                                        <td><Button size="small" onClick={e => { e.stopPropagation(); confirmJump(entity) }}>{entity.RefNo}</Button></td>
                                        <td>{entity.BldgChi}</td>
                                        <td>{entity.Block}</td>
                                        <td>{entity.Floor}</td>
                                        <td>{entity.Flat}</td>
                                        <td><ReactMoment format="YYYY-MM-DD" date={entity.DateTran} /></td>
                                        <td><MyCurrency value={entity.AmountTran} /></td>
                                    </tr>
                                })
                            }
                        </tbody>
            }
        </table>
    );
}

function MyArrowIcon({ direction }) {
    switch (direction) {
        case "ASC": return <ArrowDropUpIcon />;
        case "DESC": return <ArrowDropDownIcon />;
        default: return null;
    }
}
