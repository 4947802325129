import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { prepaidDetailUpdate } from '../../redux/prepaidReducer';

export default function PrepaidDetailInputField({ fieldName, width, tabIndex, textAlign }) {
    const dispatch = useDispatch();
    const { workingEntity } = useSelector(state => {
        return state.prepaid;
    });

    return <input value={workingEntity[fieldName] ? workingEntity[fieldName] : ''} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(prepaidDetailUpdate(fieldName, e.target.value)); }} />
}