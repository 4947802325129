import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { vSupervisorFilterUpdate, vSupervisorFilterClear, vSupervisorLookup } from '../../redux/vSupervisorReducer';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import MyDateField from '../MyDateField';

const useStyles = makeStyles({
    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& input, & select": {
            padding: "0px 1px",
            fontSize: "13px",
        },

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },

        "& label": {
            margin: 0,
        }
    },
});

export default function VSupervisorFilter({ filter }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const filters = JSON.parse(filter.filter);
    const isActiveFilter = _.find(filters, function (o) { return o.property == "is_active" });
    const isActiveValue = isActiveFilter ? isActiveFilter.value : "";

    const _getFilterValue = (field) => {
        const i = _.findIndex(filters, function (o) { return o.property == field; });
        return (i >= 0) ? filters[i].value : "";
    };

    const filterChange = (field, value) => {
        dispatch(vSupervisorFilterUpdate(field, value));
    };

    const clearFilter = () => {
        dispatch(vSupervisorFilterClear());
    };

    const lookup = () => {
        dispatch(vSupervisorLookup(filter));
    }

    return (
        <div style={{ width: '1024px' }}>
            <table className={`${classes.frmFilter} mb-2`}>
                <tbody>
                    <tr>
                        <th>員工編號：</th>
                        <td colSpan="5">
                            <input onChange={(e) => { filterChange('id', e.target.value); }} value={_getFilterValue('id')} style={{ width: '100px' }} tabIndex={1} />
                        </td>
                    </tr>
                    <tr>
                        <th>員工姓名：</th>
                        <td>
                            <input onChange={(e) => { filterChange('s_name', e.target.value); }} value={_getFilterValue('s_name')} style={{ width: '100px' }} tabIndex={2} />
                        </td>
                        <th>員工別名：</th>
                        <td>
                            <input onChange={(e) => { filterChange('s_alias', e.target.value); }} value={_getFilterValue('s_alias')} style={{ width: '100px' }} tabIndex={3} />
                        </td>
                        <th>計算日期：</th>
                        <td>
                            <MyDateField dateValue={_getFilterValue("cal_date")} onDateChange={(d) => { filterChange('cal_date', d); }} tabIndex={4} />
                        </td>
                    </tr>
                </tbody>
            </table>
            <Grid container justifyContent="flex-end" spacing={2} className="mb-2">
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { lookup(); }}>搜　尋</Button>
                </Grid>
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { clearFilter(); }}>清　除</Button>
                </Grid>
            </Grid>
        </div>
    );
}

