import React, { useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

//import Moment from 'moment';
import _ from 'lodash';

import Typography from '@material-ui/core/Typography';
//import Button from '@material-ui/core/Button';
//import Grid from '@material-ui/core/Grid';

import { jobSendInvoiceLookup, jobSendInvoiceFetchPage, jobSendInvoicePageUpdate } from '../../redux/jobSendInvoiceReducer';

//import JobSendInvoiceFilter from './JobSendInvoiceFilter';
import JobSendInvoiceList from './JobSendInvoiceList';
import Pagination from '../Pagination';

const useStyles = makeStyles({
  title: {
    paddingBottom: "15px",
    fontSize: "26px",
    lineHeight: "26px",
    fontWeight: "bold",
  },
});


export default function JobSendInvoice() {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useDispatch();

  const { hasFilter } = useParams();

  const { filter } = useSelector(state => {
    return state.jobSendInvoice;
  });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  useEffect(() => {
    dispatch(jobSendInvoiceLookup(hasFilter ? filter : null));
  }, []);

  return (
    <div>
      <Typography variant="h1" className={classes.title}>7.9.2 發送付款通知單記錄</Typography>
      {/*<JobSendInvoiceFilter filter={filter} />*/}
      <Pagination filter={filter} fnFetchPage={jobSendInvoiceFetchPage} fnPageUpdate={jobSendInvoicePageUpdate} />
      <JobSendInvoiceList />
      <div style={{ margin: "5px", textAlign: 'center' }}>
        <Pagination filter={filter} fnFetchPage={jobSendInvoiceFetchPage} fnPageUpdate={jobSendInvoicePageUpdate} />
      </div>
    </div>
  );
}
