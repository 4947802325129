import React, { useEffect, useRef } from 'react';
import { Link as RouteLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { setRefnosAndParities as setDebitNoteRefnosAndParities } from '../../redux/reportDebitNoteReducer';
import { vCommDueChangeSort, vCommDueTickDebitNote, vCommDueTickAllDebitNotes, vCommDueClearTickAllDebitNotes, gotoCommDetail } from '../../redux/vCommDueReducer';
import ReactMoment from "react-moment";
import NumberFormat from 'react-number-format';

import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import BeatLoader from "react-spinners/BeatLoader";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PrintIcon from '@material-ui/icons/Print';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const useStyles = makeStyles({
  tblList: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& thead": {
      backgroundColor: "#c5e2ff",
      color: "#45525c",
    },

    //"& tr": {
    //    cursor: "pointer",
    //},

    "& tr.alt": {
      backgroundColor: "#f7fbff",
    },

    "& tr.lastWorkingEntity": {
      border: "2px solid #da59da",
    },

    "& tbody tr:hover, tbody tr.alt:hover": {
      backgroundColor: "#ffffcc !important",
    },

    "& th": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },
  },
});

export default function VSupervisorList() {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading, filter, entities, errors, workingEntityId, isPrintDebitNoteBuyers, isPrintDebitNoteOwners } = useSelector(state => {
    return state.vCommDue;
  });

  const sorts = JSON.parse(filter.sort);

  const _getSortDirection = (field) => {
    const i = _.findIndex(sorts, function (o) { return o.property == field; });
    return (i >= 0) ? sorts[i].direction : "";
  };

  const changeSort = (field) => {
    dispatch(vCommDueChangeSort(field, filter));
  };

  const printSelectedDebitNote = () => {
    let refNos = [];
    let parties = [];

    for (var i = 0; i < entities.length; i++) {
      if (isPrintDebitNoteBuyers[i]) {
        refNos.push(entities[i].RefNo);
        parties.push("BUYER");
      }

      if (isPrintDebitNoteOwners[i]) {
        refNos.push(entities[i].RefNo);
        parties.push("OWNER");
      }
    }

    //for (var i = 0; i < isPrintDebitNoteBuyers.length; i++) {
    //  if (isPrintDebitNoteBuyers[i]) {
    //    refNos.push(entities[i].RefNo);
    //    parties.push("BUYER");
    //  }
    //}

    //for (var i = 0; i < isPrintDebitNoteOwners.length; i++) {
    //  if (isPrintDebitNoteOwners[i]) {
    //    refNos.push(entities[i].RefNo);
    //    parties.push("OWNER");
    //  }
    //}

    if (!_.isEmpty(refNos)) {
      dispatch(setDebitNoteRefnosAndParities(refNos, parties));
      history.push("/reportDebitNote");
    }
  };

  const printDebitNote = (party, index) => {
    dispatch(setDebitNoteRefnosAndParities([entities[index].RefNo], [party]));
    history.push("/reportDebitNote");
  };

  let i = filter.qtyPerPage * (filter.page - 1) + 1;

  const myRef = useRef(null);
  useEffect(() => {
    if (myRef.current) myRef.current.scrollIntoView({ block: "center" });
  }, []);

  return (
    <table className={classes.tblList} >
      <thead>
        <tr>
          <th rowSpan="2"><span>行</span></th>
          <th rowSpan="2" onClick={(_) => changeSort('ref_no')}><span>編號</span><MyArrowIcon direction={_getSortDirection("ref_no")} /></th>
          <th width="90" rowSpan="2" onClick={(_) => changeSort('date_contract')}><span>合約日期</span><MyArrowIcon direction={_getSortDirection("date_contract")} /></th>
          <th width="90" rowSpan="2" onClick={(_) => changeSort('date_conclude')}><span>成交日期</span><MyArrowIcon direction={_getSortDirection("date_conclude")} /></th>
          <th rowSpan="2" onClick={(_) => changeSort('long_addr')}><span>地址</span><MyArrowIcon direction={_getSortDirection("long_addr")} /></th>
          <th width="90" rowSpan="2" onClick={(_) => changeSort('comm_buyer')}><span>買家佣</span><MyArrowIcon direction={_getSortDirection("comm_owner")} /></th>
          <th width="90" rowSpan="2" onClick={(_) => changeSort('buyerPayment')}><span>買家已付</span><MyArrowIcon direction={_getSortDirection("buyerPayment")} /></th>
          <th width="90" rowSpan="2" onClick={(_) => changeSort('comm_owner')}><span>業主佣</span><MyArrowIcon direction={_getSortDirection("comm_owner")} /></th>
          <th width="90" rowSpan="2" onClick={(_) => changeSort('ownerPayment')}><span>業主已付</span><MyArrowIcon direction={_getSortDirection("ownerPayment")} /></th>
          <th width="40" rowSpan="2"><span>買家OK</span></th>
          <th width="40" rowSpan="2"><span>業主OK</span></th>
          <th colSpan="2">
            <Link component="button" onClick={(_) => { printSelectedDebitNote(); }}>列印已選通知單</Link>
          </th>
        </tr>
        <tr>
          <th width="75">
            {!_.some(isPrintDebitNoteBuyers, function (i) { return i }) && <Link component="button" onClick={(_) => { dispatch(vCommDueTickAllDebitNotes("BUYER")); }}>所有買家</Link>}
            {_.some(isPrintDebitNoteBuyers, function (i) { return i }) && <Link component="button" onClick={(_) => { dispatch(vCommDueClearTickAllDebitNotes("BUYER")); }}>取　　消</Link>}
          </th>
          <th width="75">
            {!_.some(isPrintDebitNoteOwners, function (i) { return i }) && <Link component="button" onClick={(_) => { dispatch(vCommDueTickAllDebitNotes("OWNER")); }}>所有業主</Link>}
            {_.some(isPrintDebitNoteOwners, function (i) { return i }) && <Link component="button" onClick={(_) => { dispatch(vCommDueClearTickAllDebitNotes("OWNER")); }}>取　　消</Link>}
          </th>
        </tr>
      </thead>

      {isLoading
        ?
        <tbody>
          <tr>
            <td colSpan={15} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
          </tr>
        </tbody>
        :
        (errors !== "")
          ?
          <tbody>
            <tr>
              <td colSpan={15} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
            </tr>
          </tbody >
          :
          <tbody>
            {
              entities.length < 1
                ? <tr>
                  <td colSpan={15} style={{ padding: "3em", textAlign: "center", }}><em>沒有符合的記錄</em></td>
                </tr>
                : entities.map((entity, index) => {
                  return <tr key={entity.Id} ref={workingEntityId == entity.Id ? myRef : null} className={clsx({ "lastWorkingEntity": workingEntityId == entity.Id, "alt": index % 2 == 1, "normal": index % 2 == 0 })}>
                    <td>{i++}</td>
                    <td><RouteLink onClick={(_) => { dispatch(gotoCommDetail(entity.Id)) }} to={`/commDetail/${entity.Id}`} >{entity.RefNo}</RouteLink></td>
                    <td>{entity.DateContract ? <ReactMoment format="YYYY-MM-DD" date={entity.DateContract} /> : "---"}</td>
                    <td>{entity.DateConclude ? <ReactMoment format="YYYY-MM-DD" date={entity.DateConclude} /> : "---"}</td>
                    <td><RouteLink onClick={(_) => { dispatch(gotoCommDetail(entity.Id)) }} to={`/commDetail/${entity.Id}`} >{entity.LongAddr}</RouteLink></td>
                    <td>{entity.CommBuyer ? <NumberFormat value={entity.CommBuyer} displayType="text" prefix="$" thousandSeparator="," /> : "-"}</td>
                    <td>{entity.BuyerPayment ? <NumberFormat value={entity.BuyerPayment} displayType="text" prefix="$" thousandSeparator="," /> : "-"}</td>
                    <td>{entity.CommOwner ? <NumberFormat value={entity.CommOwner} displayType="text" prefix="$" thousandSeparator="," /> : "-"}</td>
                    <td>{entity.OwnerPayment ? <NumberFormat value={entity.OwnerPayment} displayType="text" prefix="$" thousandSeparator="," /> : "-"}</td>
                    <td><MySettleIcon arReceive={entity.CommBuyer} received={entity.BuyerPayment} /></td>
                    <td><MySettleIcon arReceive={entity.CommOwner} received={entity.OwnerPayment} /></td>
                    <td>
                      <input type="checkbox" onChange={e => { }} checked={isPrintDebitNoteBuyers[index]} onClick={e => { dispatch(vCommDueTickDebitNote("BUYER", index)); }} />
                      <IconButton className="ml-1" size="small" onClick={e => { printDebitNote("BUYER", index); }}><PrintIcon style={{ color: "rgb(162 162 162)" }} /></IconButton>
                    </td>
                    <td>
                      <input type="checkbox" onChange={e => { }} checked={isPrintDebitNoteOwners[index]} onClick={e => { dispatch(vCommDueTickDebitNote("OWNER", index)); }} />
                      <IconButton className="ml-1" size="small" onClick={e => { printDebitNote("OWNER", index); }}><PrintIcon style={{ color: "rgb(162 162 162)" }} /></IconButton>
                    </td>
                  </tr>
                })

            }
          </tbody>
      }
    </table>
  );
}

function MyArrowIcon({ direction }) {
  switch (direction) {
    case "ASC": return <ArrowDropUpIcon />;
    case "DESC": return <ArrowDropDownIcon />;
    default: return null;
  }
}

function MySettleIcon({ arReceive, received }) {
  if (arReceive == 0) return <RemoveCircleIcon style={{ color: "rgb(193 178 197)" }} />;
  if (received >= arReceive) return <CheckCircleIcon style={{ color: "#4fa917" }} />;
  return <CancelIcon style={{ color: "#e43e3e" }} />
}