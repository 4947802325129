import React, { useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { vSupervisorLookup, vSupervisorFetchPage, vSupervisorPageUpdate } from '../../redux/vSupervisorReducer';

import VSupervisorFilter from './VSupervisorFilter';
import VSupervisorList from './VSupervisorList';
import Pagination from '../Pagination';

const useStyles = makeStyles({
    title: {
        paddingBottom: "15px",
        fontSize: "26px",
        lineHeight: "26px",
        fontWeight: "bold",
    },
});


export default function Supervisor() {
    const classes = useStyles();

    const history = useHistory();

    const dispatch = useDispatch();

    const { hasFilter } = useParams();

    const newSupervisor = () => {
        history.push("/supervisorDetail/0");
    }

    const { filter } = useSelector(state => {
        return state.vSupervisor;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");

        dispatch(vSupervisorLookup(hasFilter ? filter : null));
    }, []);

    return (
        <div>
            <Typography variant="h1" className={classes.title}>3. 主管資料</Typography>
            <Grid container justifyContent="space-between" className="mb-2">
                <Grid item>
                    <Button variant="contained" size="small" onClick={newSupervisor}>新增主管資料</Button>
                </Grid>
            </Grid>
            <VSupervisorFilter filter={filter} />
            <Pagination filter={filter} fnFetchPage={vSupervisorFetchPage} fnPageUpdate={vSupervisorPageUpdate} />
            <VSupervisorList />
            <div style={{ margin: "5px", textAlign: 'center' }}>
                <Pagination filter={filter} fnFetchPage={vSupervisorFetchPage} fnPageUpdate={vSupervisorPageUpdate} />
            </div>

        </div>
    );
}
