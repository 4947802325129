import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
//import Moment from 'moment';

//import JobSendInvoice from '../models/jobSendInvoice';

//import { showLoading, showInfo, showErrorResponse, clearMsg } from './msgReducer';

//================== Whole State ==================//
//let initialCalDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
//initialCalDate.setMonth(initialCalDate.getMonth() - 1);

const initialState = {
  isInited: false,
  isLoading: false,
  isFilterUpdated: false,
  filter: { // match EntitiesResult<T>
    allRecords: 0,
    matchedRecords: 0,
    totalRecords: 0,
    qtyPerPage: 100,
    page: 1,
    row: 0,
    filter: "[]",
    sort: JSON.stringify([{ "property": "date_due", "direction": "DESC" }]),
    success: true,
    message: '',
  },
  entities: [],
  workingEntityId: -1,
  workingEntities: [],
  errors: '',
};


//================== Action Types ==================//
export const JobSendInvoiceActions = Object.freeze({
  // filter
  FILTER_UPDATE: Symbol("FILTER_UPDATE"),
  CHANGE_SORT: Symbol("CHANGE_SORT"),
  PAGE_UPDATE: Symbol("PAGE_UPDATE"),
  FETCH_PAGE: Symbol("FETCH_PAGE"),

  // listing
  REQUEST: Symbol("REQUEST"),
  SUCCESS: Symbol("SUCCESS"),
  FAILURE: Symbol("FAILURE"),

  // detail page
  DETAIL_ID: Symbol("DETAIL_ID"),
  //DETAIL_SUCCESS: Symbol("DETAIL_SUCCESS"), // ajax loaded
  //DETAIL_FAILURE: Symbol("DETAIL_FAILURE"),

  //DETAIL_UPDATE: Symbol("DETAIL_UPDATE"), // form fields update
  //DETAIL_DELETED: Symbol("DETAIL_DELETED"), // ajax delete

  //DETAILS_INIT: Symbol("DETAILS_INIT"), // for calDate
});


//================== Action Creators ==================//
export const jobSendInvoiceFilterUpdate = (field, value) => {
  return {
    type: JobSendInvoiceActions.FILTER_UPDATE,
    payload: {
      field: field,
      value: value
    }
  }
}

const _setWorkingEntityId = (id) => {
  return {
    type: JobSendInvoiceActions.DETAIL_ID,
    payload: id,
  }
}

export const jobSendInvoiceFilterClear = () => {
  return (dispatch) => {
    dispatch(_setWorkingEntityId(initialState.workingEntityId));
    dispatch(jobSendInvoiceLookup(initialState.filter));
  }
}

export const jobSendInvoiceChangeSort = (field) => {
  return (dispatch, getState) => {
    dispatch({
      type: JobSendInvoiceActions.CHANGE_SORT,
      payload: field,
    });
    const { jobSendInvoice: { filter } } = getState(); // state updated
    //console.log(filter);
    dispatch(jobSendInvoiceLookup(filter));
  };
}

export const jobSendInvoicePageUpdate = (page) => {
  return (dispatch, getState) => {
    const { jobSendInvoice: { filter } } = getState(); // state updated

    let _page = 1;

    if (page == "") {
      _page = "";
    } else {
      _page = parseInt(page);
      if (_.isNaN(_page)) return;
      if (_page < 1) _page = 1;
      if (_page > Math.ceil(filter.matchedRecords / filter.qtyPerPage)) _page = Math.ceil(filter.matchedRecords / filter.qtyPerPage);
    }

    dispatch({
      type: JobSendInvoiceActions.FETCH_PAGE,
      payload: _page,
    });
  };
}

export const jobSendInvoiceFetchPage = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: JobSendInvoiceActions.FETCH_PAGE,
      payload: page,
    });
    const { jobSendInvoice: { filter } } = getState(); // state updated
    //console.log(filter);
    dispatch(jobSendInvoiceLookup(filter));
  };
}

const _jobSendInvoiceRequest = () => {
  return {
    type: JobSendInvoiceActions.REQUEST
  }
}

const _jobSendInvoiceSuccess = (data) => {
  return {
    type: JobSendInvoiceActions.SUCCESS,
    payload: data,
  }
}

const _jobSendInvoiceFailure = (response) => {
  return {
    type: JobSendInvoiceActions.FAILURE,
    payload: response,
  }
}

export const jobSendInvoiceLookup = (formData) => {
  const _formData = formData ? formData : initialState.filter;
  //console.log(_formData);
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/JobSendInvoice/GetEntities`;

  return (dispatch, getState) => {
    dispatch(_jobSendInvoiceRequest());

    const { jobSendInvoice } = getState(); // state updated
    if (jobSendInvoice.isFilterUpdated) _formData.page = 1; // reset page if filter changed
    if (jobSendInvoice.filter.page == "") _formData.page = 1; // reset page if filter changed

    axios.post(apiEndpoint, _formData, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_jobSendInvoiceSuccess(response.data));
        } else {
          dispatch(_jobSendInvoiceFailure("未能讀取"));
        }
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          dispatch(_jobSendInvoiceFailure(error.response.data.errors));
        } else if (error.response && error.response.status) {
          dispatch(_jobSendInvoiceFailure(`未能讀取 (${error.response.status})`));
        } else {
          dispatch(_jobSendInvoiceFailure(`未能聯繫伺服器`));
        }
      })
  }
}

//const _jobSendInvoiceDetailSuccess = (data) => {
//  return {
//    type: JobSendInvoiceActions.DETAIL_SUCCESS,
//    payload: data,
//  }
//}

//const _jobSendInvoiceDetailFailure = (response) => {
//  return (dispatch, getState) => {
//    dispatch({
//      type: JobSendInvoiceActions.DETAIL_FAILURE,
//      payload: response,
//    })
//  }
//}

//export const jobSendInvoiceDetails = (Id, isForce) => {
//  return (dispatch, getState) => {
//    const { jobSendInvoice: { workingEntityId: lastWorkingEntityId } } = getState();
//    if (!isForce && lastWorkingEntityId == Id) return; // same entity

//    dispatch(_setWorkingEntityId(Id)); // 任何 dispatch 之前先記住 current working entity id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗

//    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/JobSendInvoice/GetSameMonthEntities`;

//    dispatch(_jobSendInvoiceRequest());

//    axios.post(apiEndpoint, { Id }, {
//      timeout: 30000,
//    })
//      .then(response => {
//        dispatch(clearMsg());

//        if (response.data) {
//          dispatch(_jobSendInvoiceDetailSuccess(response.data));
//        } else {
//          dispatch(_jobSendInvoiceDetailFailure("未能讀取"));
//        }
//      })
//      .catch(error => {
//        dispatch(_jobSendInvoiceDetailFailure("未能讀取"));
//        dispatch(showErrorResponse(error));
//      })
//  };
//}

//export const jobSendInvoiceDetailUpdate = (index, fieldName, value) => {
//  return {
//    type: JobSendInvoiceActions.DETAIL_UPDATE,
//    payload: { index, fieldName, value },
//  }
//}

//export const jobSendInvoiceDetailsInit = (calDate) => {
//  return {
//    type: JobSendInvoiceActions.DETAILS_INIT,
//    payload: calDate
//  }
//}

//export const saveEntities = (history) => {
//  return (dispatch, getState) => {
//    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/JobSendInvoice/SaveEntities`;

//    const { jobSendInvoice: { workingEntities } } = getState();

//    dispatch(showLoading());

//    axios.post(apiEndpoint, workingEntities, {
//      timeout: 30000,
//    })
//      .then(response => {
//        if (history.location.pathname == "/jobSendInvoiceDetails/0") {
//          history.replace(`/jobSendInvoiceDetails/${response.data[0].Id}`);
//          dispatch(showInfo("已保存"));
//          return;
//        }

//        dispatch(_jobSendInvoiceDetailSuccess(response.data));
//        dispatch(showInfo("已保存"));
//      })
//      .catch(error => {
//        dispatch(_jobSendInvoiceDetailFailure("未能讀取"));
//        dispatch(showErrorResponse(error));
//      })
//  };
//}

//export const deleteEntities = (history) => {
//  return (dispatch, getState, x) => {
//    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/JobSendInvoice/DeleteEntities`;

//    const { jobSendInvoice: { workingEntities } } = getState();

//    dispatch(showLoading());
//    dispatch(_jobSendInvoiceRequest());

//    axios.post(apiEndpoint, workingEntities, {
//      timeout: 30000,
//    })
//      .then(response => {
//        const { jobSendInvoice: { filter } } = getState();

//        dispatch(clearMsg());
//        history.push('/jobSendInvoice');
//        dispatch(jobSendInvoiceLookup(filter));
//      })
//      .catch(error => {
//        dispatch(_jobSendInvoiceDetailFailure("未能讀取"));
//        dispatch(showErrorResponse(error));
//      })
//  };
//}

//================== Reducer ==================//
const jobSendInvoiceReducer = (state = initialState, action) => {
  let _state = cloneDeep(state);

  switch (action.type) {
    case JobSendInvoiceActions.FILTER_UPDATE:
      const { field: filterField, value } = action.payload;
      let filters = JSON.parse(state.filter.filter);
      const iFilter = _.findIndex(filters, function (o) { return o.property == filterField; });
      if (iFilter >= 0) {
        if (value) filters[iFilter].value = value;
        else filters.splice(iFilter, 1);
      } else {
        filters.push({ "property": filterField, "value": value });
      }

      _state.filter.filter = JSON.stringify(filters);
      _state.isFilterUpdated = true;
      //console.log('_state.filter', _state.filter.filter);
      return _state;

    case JobSendInvoiceActions.CHANGE_SORT:
      const field = action.payload;
      let sorts = JSON.parse(state.filter.sort);
      const i = _.findIndex(sorts, function (o) { return o.property == field; });
      if (i >= 0) {
        sorts[i].direction = sorts[i].direction == "ASC" ? "DESC" : "ASC";
      } else {
        // single sort only
        sorts = [{ "property": field, "direction": "ASC" }];
      }

      _state.filter.sort = JSON.stringify(sorts);
      //console.log('_state.filter.sort', _state.filter.sort);

      jobSendInvoiceLookup(_state.filter);
      return _state;

    case JobSendInvoiceActions.PAGE_UPDATE:
      _state.filter.page = action.payload;
      return _state;

    case JobSendInvoiceActions.FETCH_PAGE:
      _state.filter.page = action.payload;
      return _state;

    case JobSendInvoiceActions.REQUEST:
      _state.isLoading = true;
      return _state

    case JobSendInvoiceActions.SUCCESS:
      _state.isLoading = false;
      _state.filter = {
        allRecords: action.payload.allRecords,
        matchedRecords: action.payload.matchedRecords,
        totalRecords: action.payload.totalRecords,
        qtyPerPage: action.payload.qtyPerPage,
        page: action.payload.page,
        row: action.payload.row,
        filter: action.payload.filter,
        sort: action.payload.sort,
        success: action.payload.success,
        message: action.payload.message,
      };
      _state.entities = action.payload.entities;
      _state.errors = "";
      _state.isFilterUpdated = false;
      return _state;

    case JobSendInvoiceActions.FAILURE:
      _state.isLoading = false;
      _state.errors = action.payload;
      _state.entities = [];
      return _state;

    case JobSendInvoiceActions.DETAIL_ID:
      _state.workingEntityId = action.payload;
      return _state;

    //case JobSendInvoiceActions.DETAIL_SUCCESS:
    //  _state.isLoading = false;
    //  _state.workingEntities = action.payload.map(c => new JobSendInvoice(c));
    //  return _state;

    //case JobSendInvoiceActions.DETAIL_FAILURE:
    //  _state.isLoading = false;
    //  return _state;

    //case JobSendInvoiceActions.DETAIL_UPDATE:
    //  const { index: updateIndex, fieldName: updateFieldName, value: updateValue } = action.payload;
    //  _state.workingEntities[updateIndex][updateFieldName] = updateValue;

    //  switch (updateFieldName) {
    //    case 'Fdate': // apply to all
    //      _.forEach(_state.workingEntities, function (jobSendInvoice) {
    //        jobSendInvoice.Fdate = updateValue;
    //      });
    //      break;
    //    case 'Tdate': // apply to all
    //      _.forEach(_state.workingEntities, function (jobSendInvoice) {
    //        jobSendInvoice.Tdate = updateValue;
    //      });
    //      break;
    //  }
    //  return _state;

    //case JobSendInvoiceActions.DETAILS_INIT:
    //  const Fdate = Moment.utc(action.payload).format('YYYY-MM-01');
    //  const Tdate = Moment.utc(action.payload).endOf('month').format('YYYY-MM-DD');
    //  console.log('Tdate', Tdate);

    //  _.forEach(_state.workingEntities, function (jobSendInvoice) {
    //    jobSendInvoice.Fdate = Fdate;
    //    jobSendInvoice.Tdate = Tdate;
    //  });

    //  return _state;

    default:
      return state;
  }
}

export default jobSendInvoiceReducer;

