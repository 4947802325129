import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Moment from 'moment';

import EmployeeHistory from '../models/employeeHistory';

import { employeeDetailUpdate as employeeUpdate } from './employeeReducer';
import { showLoading, showInfo, showError, clearMsg } from './msgReducer';

//================== Whole State ==================//
const initialState = {
  isLoading: false,
  errors: '',
  employeeHistoryEntities: [],
};


//================== Action Types ==================//
export const EmployeeDetailActions = Object.freeze({
  // employee detail page
  EMPLOYEE_DETAIL_REQUEST: Symbol("EMPLOYEE_DETAIL_REQUEST"),
  EMPLOYEE_DETAIL_SUCCESS: Symbol("EMPLOYEE_DETAIL_SUCCESS"),
  EMPLOYEE_DETAIL_FAILURE: Symbol("EMPLOYEE_DETAIL_FAILURE"),

  EMPLOYEE_DETAIL_UPDATE: Symbol("EMPLOYEE_DETAIL_UPDATE"),
  EMPLOYEE_DETAIL_ADD: Symbol("EMPLOYEE_DETAIL_ADD"),
  EMPLOYEE_DETAIL_REMOVE: Symbol("EMPLOYEE_DETAIL_REMOVE"),
});


//================== Action Creators ==================//
const _employeeDetailRequest = () => {
  return {
    type: EmployeeDetailActions.EMPLOYEE_DETAIL_REQUEST,
  }
}


const _employeeDetailSuccess = (data) => {
  return {
    type: EmployeeDetailActions.EMPLOYEE_DETAIL_SUCCESS,
    payload: data,
  }
}

const _employeeDetailFailure = (response) => {
  return {
    type: EmployeeDetailActions.EMPLOYEE_DETAIL_FAILURE,
    payload: response,
  }
}

export const employeeDetail = () => {
  return (dispatch, getState) => {
    const { employee: { workingEntity: detailEmployee } } = getState();
    if (!detailEmployee) return;

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/EmployeeHistory/GetEntitiesByEmployee`;

    dispatch(_employeeDetailRequest());

    axios.post(apiEndpoint, detailEmployee, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_employeeDetailSuccess(response.data));
        } else {
          dispatch(_employeeDetailFailure("未能讀取"));
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.errors) {
          dispatch(_employeeDetailFailure(error.response.data.errors));
        } else if (error.response && error.response.status) {
          dispatch(_employeeDetailFailure(`未能讀取 (${error.response.status})`));
        } else {
          dispatch(_employeeDetailFailure(`未能聯繫伺服器`));
        }
      })
  };
}

export const employeeDetailAdd = () => {
  return (dispatch, getState) => {
    const { employee: { workingEntity: detailEmployee } } = getState();

    dispatch({
      type: EmployeeDetailActions.EMPLOYEE_DETAIL_ADD,
      payload: detailEmployee,
    });
  };
}

export const employeeDetailRemove = (index) => {
  return (dispatch, getState) => {
    dispatch({
      type: EmployeeDetailActions.EMPLOYEE_DETAIL_REMOVE,
      payload: index,
    });

    _updateEmployee(dispatch, getState);
  };
}

export const employeeDetailUpdate = (index, field, value) => {
  return (dispatch, getState) => {
    const { selections: { branches, teams } } = getState();

    dispatch({
      type: EmployeeDetailActions.EMPLOYEE_DETAIL_UPDATE,
      payload: { index, field, value, branches, teams }
    });

    _updateEmployee(dispatch, getState);
  };
}

const _updateEmployee = (dispatch, getState) => {
  const { employeeHistory: { employeeHistoryEntities }, selections: { branches, teams, dfnEmpRoles } } = getState();

  if (employeeHistoryEntities.length < 1) {
    dispatch(employeeUpdate('DateJoin', null));
    dispatch(employeeUpdate('DateLeave', null));
    dispatch(employeeUpdate('IdBranch', null));
    dispatch(employeeUpdate('Branch', null));
    dispatch(employeeUpdate('IdTeam', null));
    dispatch(employeeUpdate('Team', null));
    dispatch(employeeUpdate('Role', null));
    return;
  }

  const minHistory = _.minBy(employeeHistoryEntities, function (h) { return h.getFdate(); });
  const maxHistory = _.maxBy(employeeHistoryEntities, function (h) { return h.getFdate(); });

  const { Fdate } = minHistory;
  const { Tdate } = maxHistory;

  dispatch(employeeUpdate('DateJoin', Fdate ? Fdate : null));
  dispatch(employeeUpdate('DateLeave', Tdate ? Tdate : null));
  dispatch(employeeUpdate('RptDateJoin', Fdate ? Fdate : null)); // duplicate, 唔記得有乜用, 舊系統用嚟 check 係咪過期
  dispatch(employeeUpdate('RptDateLeave', Tdate ? Tdate : null)); // duplicate, 唔記得有乜用, 舊系統用嚟 check 係咪過期

  if (maxHistory.IdBranch) {
    dispatch(employeeUpdate('IdBranch', maxHistory.IdBranch));
    const branch = _.find(branches, function (b) { return b.Id == maxHistory.IdBranch; });
    if (branch) dispatch(employeeUpdate('Branch', branch.Branch1));
  }
  if (maxHistory.IdTeam) {
    //console.log(maxHistory);
    dispatch(employeeUpdate('IdTeam', maxHistory.IdTeam));
    dispatch(employeeUpdate('Team', maxHistory.Team));

    // lookup team by maxHistory
    const team = _.find(teams, t => t.Id == maxHistory.IdTeam);
    // lookup dfnEmpRole by team
    const dfnEmpRole = _.find(dfnEmpRoles, r => r.Id == team?.IdRole);
    // update employee
    dispatch(employeeUpdate('Role', dfnEmpRole?.Role ?? ""));
  }
}

export const saveEntitiesInEmployeeDetail = (response, getState) => {
  const { Id: employeeId } = response.data;
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/EmployeeHistory/SaveEntitiesInEmployeeDetail?employeeId=${employeeId}`;

  const { employee: { workingEntity }, employeeHistory: { employeeHistoryEntities } } = getState();

  _.forEach(employeeHistoryEntities, function (h) { h.IdEmployee = workingEntity.Id; });

  return axios.post(apiEndpoint, employeeHistoryEntities, {
    timeout: 30000,
  });
}

export const deleteEntitiesInEmployeeDetail = (response, getState) => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/EmployeeHistory/DeleteEntitiesInEmployeeDetail`;

  const { employeeHistory: { employeeHistoryEntities } } = getState();

  return axios.post(apiEndpoint, employeeHistoryEntities, {
    timeout: 30000,
  });
}


//================== Reducer ==================//
const employeeHistoryReducer = (state = initialState, action) => {
  let _state = cloneDeep(state);

  switch (action.type) {
    case EmployeeDetailActions.EMPLOYEE_DETAIL_REQUEST:
      _state.isLoading = true;
      return _state;

    case EmployeeDetailActions.EMPLOYEE_DETAIL_SUCCESS:
      _state.isLoading = false;
      _state.employeeHistoryEntities = action.payload.map(h => new EmployeeHistory(h));
      return _state;

    case EmployeeDetailActions.EMPLOYEE_DETAIL_FAILURE:
      _state.isLoading = false;
      _state.errors = action.payload;
      _state.employeeHistoryEntities = [];
      return _state;

    case EmployeeDetailActions.EMPLOYEE_DETAIL_ADD:
      const { Id: IdEmployee, IdBranch, IdRank, IdTeam, Rank, Branch, Team } = action.payload;
      let h = new EmployeeHistory({ IdEmployee, IdBranch, IdRank, IdTeam, Rank, Branch, Team });
      _state.employeeHistoryEntities.push(h);
      return _state;

    case EmployeeDetailActions.EMPLOYEE_DETAIL_REMOVE:
      const index = action.payload;
      _.pullAt(_state.employeeHistoryEntities, index)
      return _state;

    case EmployeeDetailActions.EMPLOYEE_DETAIL_UPDATE:
      const { index: detailUpdateIndex, field: detailUpdateField, value: detailUpdateValue, branches: detailUpdateBranches, teams: detailUpdateTeams } = action.payload;
      _state.employeeHistoryEntities[detailUpdateIndex][detailUpdateField] = detailUpdateValue;

      switch (detailUpdateField) {
        case "IdBranch":
          const branch = _.find(detailUpdateBranches, function (b) { return b.Id == detailUpdateValue; });
          _state.employeeHistoryEntities[detailUpdateIndex]['Branch'] = branch.Branch1;
          break;
        case "IdTeam":
          const team = _.find(detailUpdateTeams, function (b) { return b.Id == detailUpdateValue; });
          _state.employeeHistoryEntities[detailUpdateIndex]['Team'] = team?.Team1 ?? "";
          break;
      }
      return _state;

    default:
      return state;
  }
}

export default employeeHistoryReducer;

