import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { prepaidDetail, prepaidDetailUpdate, prepaidDetailSetEmployee } from '../../redux/prepaidReducer';
import { detailPrepaidSetModalEmployee } from '../../redux/selectionsReducer';

import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import BeatLoader from "react-spinners/BeatLoader";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';

import PrepaidDetailInputField from './PrepaidDetailInputField';
import MyDateField from '../MyDateField';
import { EmployeeSelection } from '../Selections';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import PrepaidDetailBottomButtons from './PrepaidDetailBottomButtons';

const useStyles = makeStyles((theme) => ({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& a.nav-link:hover h1": {
            color: "#006ed0",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },
    },
    detail: {
        "& table": {
            width: "100%",
            backgroundColor: "white",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
            },

            "& th": {
                backgroundColor: "#FAF3DE",
                textAlign: 'right',
            },
        },
    },
    backdrop: {
        backgroundColor: 'rgb(0, 0, 0, 0.2)',
    },
    paper: {
        margin: '40px auto',
        width: 320,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: "auto",
    },
}));

export default function PrepaidDetail() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { isLoading, workingEntity } = useSelector(state => {
        return state.prepaid;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    const { id } = useParams();
    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");

        dispatch(prepaidDetail(id));
    }, [id]);

    const navClicked = (path) => {
        history.push(path);
    };

    return (
        <>
            <Breadcrumbs className={classes.title}>
                <NavLink className="nav-link" tag={Link} to="/prepaid/list">
                    <Typography variant="h1" className={classes.title}>1.1. 預支佣金</Typography>
                </NavLink>
                <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : workingEntity !== null ? workingEntity.getDesc() : ""}</Typography>
            </Breadcrumbs>
            {workingEntity &&
                <div className={classes.detail}>
                    <Grid container>
                        <Button variant="contained" className="mr-4" size="small" disableElevation onClick={() => { navClicked("/prepaid"); }}>返回列表</Button>
                        <div style={{ flex: 1 }} />
                        <Button variant="contained" size="small" disableElevation onClick={() => { dispatch(prepaidDetail(id, true)); }}><RefreshIcon /></Button>
                    </Grid>
                    <table className="mt-2" style={{ width: '400px' }}>
                        <tbody>
                            <tr>
                                <th>報表日期</th>
                                <td><MyDateField dateValue={workingEntity.DateReport} onDateChange={(d) => { dispatch(prepaidDetailUpdate('DateReport', d)); }} tabIndex={1} /> (01/MM/YYYY)</td>
                            </tr>
                            <tr>
                                <th>員　　工</th>
                                <td>
                                    <PrepaidDetailInputField fieldName="IdEmployee" width={40} tabIndex="2" />
                                    <PrepaidDetailInputField fieldName="Name" width={100} tabIndex="3" />
                                    <input type="button" value="..." onClick={(_) => { dispatch(detailPrepaidSetModalEmployee(true)); }} />
                                    <input style={{ width: '55px' }} readOnly value={workingEntity.Branch || ""} />
                                </td >
                            </tr >
                            <tr>
                                <th style={{ width: '85px' }}>修正金額</th>
                                <td><PrepaidDetailInputField fieldName="HiddenAdjustment" tabIndex="4" width={60} /></td>
                            </tr>
                            <tr>
                                <th style={{ width: '85px' }}>預支佣金</th>
                                <td>( $ <PrepaidDetailInputField fieldName="PrepaidComm" tabIndex="5" width={60} /> )</td>
                            </tr>
                        </tbody >
                    </table >
                </div>
            }
            <PrepaidDetailBottomButtons />

            <ModalSetEmployee />
        </>
    );
}

function ModalSetEmployee() {
    const dispatch = useDispatch();

    const classes = useStyles();

    const { workingEntity } = useSelector(state => {
        return state.prepaid;
    });

    const { isShowModalEmployees, balEmployees: employees } = useSelector(state => {
        return state.selections;
    });
    //console.log('employees', employees);

    let selectedValue = "";
    if (workingEntity && workingEntity.Name) {
        let index = _.findIndex(employees, function (e) {
            return _.startsWith(`${e.Name.toLowerCase()} (${e.Branch}})`, workingEntity.Name.toLowerCase());
        });

        if (index < 0) index = employees.length - 1;

        if (employees[index]) selectedValue = employees[index].Id;
    }

    const handleClose = () => {
        dispatch(detailPrepaidSetModalEmployee(false));
    };

    const handleSelected = (employee) => {
        dispatch(detailPrepaidSetModalEmployee(false));
        dispatch(prepaidDetailSetEmployee(employee));
    };

    return <Modal
        open={isShowModalEmployees}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 250, className: classes.backdrop, }}
    >
        <Fade in={isShowModalEmployees}>
            <div className={classes.paper}>
                <EmployeeSelection showAny={false} value={selectedValue} onPicked={handleSelected} size={10} tabIndex={999} />
                <Grid container justifyContent="center" className="mt-3">
                    <Button variant="contained" size="small" disableElevation onClick={(_) => { handleClose() }}>取　消</Button>
                </Grid>
            </div>
        </Fade>
    </Modal>;
}
