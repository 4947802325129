import React from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { prepaidFilterUpdate, prepaidFilterClear, prepaidLookup } from '../../redux/prepaidReducer';
import MyDateField from '../MyDateField';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& input, & select": {
            padding: "0px 1px",
            fontSize: "13px",
            height: "1.5em",
        },

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },
    },
});

export default function PrepaidFilter({ filter }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const filters = JSON.parse(filter.filter);

    const _getFilterValue = (field) => {
        const i = _.findIndex(filters, function (o) { return o.property == field; });
        return (i >= 0) ? filters[i].value : "";
    };

    const filterChange = (field, value) => {
        dispatch(prepaidFilterUpdate(field, value));
    };

    const clearFilter = () => {
        dispatch(prepaidFilterClear());
    };

    const lookup = () => {
        dispatch(prepaidLookup(filter));
    }

    return (
        <div style={{ width: '500px' }}>
            <table className={`${classes.frmFilter} mb-2`}>
                <tbody>
                    <tr>
                        <th style={{ width: '100px' }}>開始日期</th><td><MyDateField dateValue={_getFilterValue("f_date_report")} onDateChange={(d) => { filterChange('f_date_report', d); }} tabIndex={1} /></td>
                        <th style={{ width: '100px' }}>結束日期</th><td><MyDateField dateValue={_getFilterValue("t_date_report")} onDateChange={(d) => { filterChange('t_date_report', d); }} tabIndex={2} /></td>
                    </tr>
                    <tr>
                        <th style={{ width: '100px' }}>員工編號</th><td><input onChange={(e) => { filterChange('id_employee', e.target.value); }} value={_getFilterValue('id_employee')} style={{ width: '100px' }} tabIndex={3} /></td>
                        <th style={{ width: '100px' }}>員工姓名</th><td><input onChange={(e) => { filterChange('s_name', e.target.value); }} value={_getFilterValue('s_name')} style={{ width: '100px' }} tabIndex={4} /></td>
                    </tr>
                </tbody>
            </table>
            <Grid container justifyContent="flex-end" spacing={2} className="mb-2">
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { lookup(); }}>搜　尋</Button>
                </Grid>
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { clearFilter(); }}>清　除</Button>
                </Grid>
            </Grid>
        </div>
    );
}
