import React, { useMemo }  from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import { EmployeeSelection } from '../../Selections';
import MyDateField from '../../MyDateField';
import { reportDebitNoteUpdate, fetchComm } from '../../../redux/reportDebitNoteReducer';
import { reportDebitNoteSetModalEmployee } from '../../../redux/selectionsReducer';
import IconButton from '@material-ui/core/IconButton';
import FindInPageIcon from '@material-ui/icons/FindInPage';

const useStyles = makeStyles({
    debitNote: {
        fontSize: '15px',
        backgroundColor: '#DFDFDF',
        border: '1px solid #C5C5C5',
        marginBottom: '10px',
        padding: '10px 25px',
        width: '900px',

        "& table": {
            width: '100%',

            "& th, & td": {
                verticalAlign: 'top',
            },

            "& label": {
                marginBottom: 'auto',
            },

            "& textarea": {
                height: '3em',
                lineHeight: '1.2em',
                overflow: 'hidden'
            }
        },

    },
});

export default function MemoFrmDebitNote({ entity, index, print }) {
    const memoFrmDebitNote = useMemo(() => <FrmDebitNote entity={entity} index={index} print={print} />, [entity]);
    return memoFrmDebitNote;
};

function FrmDebitNote({entity, index, print }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    //console.log('render FrmDebitNote');
    return (
        <div className={classes.debitNote}>
            <table>
                <thead>
                    <tr>
                        <th style={{width:'90px'}}>付款通知單</th>
                        <th style={{ width: '170px' }}>
                            <FrmDebitNoteRadioField index={index} fieldName="Party" label="業主" value="OWNER" selectedValue={entity.Party} />
                            <FrmDebitNoteRadioField index={index} fieldName="Party" label="買家 / 租客" value="BUYER" selectedValue={entity.Party} />
                        </th>
                        <th style={{ textAlign:'center' }}>世紀21奇豐物業顧問行</th>
                        <th style={{ width: '260px' }}>&nbsp;</th>
                    </tr>
                </thead>
            </table>
            <table>
                <tbody>
                    <tr>
                        <td rowSpan="2">客戶名稱：</td>
                        <td rowSpan="2">
                            <textarea style={{ width: '180px' }} value={entity.Name ? entity.Name: ''} onChange={(e) => { dispatch(reportDebitNoteUpdate(index, 'Name', e.target.value)); }} />
                        </td>
                        <td rowSpan="2">地址：</td>
                        <td rowSpan="2">
                            <textarea style={{ width: '290px' }} value={entity.Addr ? entity.Addr: ""} onChange={(e) => { dispatch(reportDebitNoteUpdate(index, 'Addr', e.target.value)); }} />
                        </td>
                        <td style={{ textAlign: 'right', }}>日　　期：</td>
                        <td><MyDateField dateValue={entity.Date} onDateChange={(d) => { dispatch(reportDebitNoteUpdate(index, 'Date', d)); }} /></td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'right', }}>通知單號碼：</td>
                        <td><FrmDebitNoteInputField index={index} fieldName="NoteNo" value={entity.NoteNo} width="100" /></td>
                    </tr>
                </tbody>
            </table>
            <table style={{width:'auto'}}>
                <tbody>
                    <tr>
                        <td>RE:</td>
                        <td>
                            <FrmDebitNoteInputField index={index} fieldName="RefNo1" value={entity.RefNo1} width="100" />
                            <IconButton size="small" onClick={(e) => { dispatch(fetchComm(index, entity, '1')); }}><FindInPageIcon /></IconButton>
                        </td>
                        <td>
                            <FrmDebitNoteTextArea index={index} fieldName="Desc1" value={entity.Desc1} width="360" height="55" />
                        </td>
                        <td>佣金:</td>
                        <td>
                            <FrmDebitNoteInputField index={index} fieldName="Bal1" value={entity.Bal1} width="80" textAlign="right" />
                        </td>
                    </tr>
                    <tr>
                        <td>RE:</td>
                        <td>
                            <FrmDebitNoteInputField index={index} fieldName="RefNo2" value={entity.RefNo2} width="100" />
                            <IconButton size="small" onClick={(e) => { dispatch(fetchComm(index, entity, '2')); }}><FindInPageIcon /></IconButton>
                        </td>
                        <td>
                            <FrmDebitNoteTextArea index={index} fieldName="Desc2" value={entity.Desc2} width="360" height="55" />
                        </td>
                        <td>佣金:</td>
                        <td>
                            <FrmDebitNoteInputField index={index} fieldName="Bal2" value={entity.Bal2} width="80" textAlign="right" />
                        </td>
                    </tr>
                    <tr>
                        <td>RE:</td>
                        <td>
                            <FrmDebitNoteInputField index={index} fieldName="RefNo3" value={entity.RefNo3} width="100" />
                            <IconButton size="small" onClick={(e) => { dispatch(fetchComm(index, entity, '3')); }}><FindInPageIcon /></IconButton>
                        </td>
                        <td>
                            <FrmDebitNoteTextArea index={index} fieldName="Desc3" value={entity.Desc3} width="360" height="55" />
                        </td>
                        <td>佣金:</td>
                        <td>
                            <FrmDebitNoteInputField index={index} fieldName="Bal3" value={entity.Bal3} width="80" textAlign="right" />
                        </td>
                    </tr>
                    <tr>
                        <td>RE:</td>
                        <td>
                            <FrmDebitNoteInputField index={index} fieldName="RefNo4" value={entity.RefNo4} width="100" />
                            <IconButton size="small" onClick={(e) => { dispatch(fetchComm(index, entity, '4')); }}><FindInPageIcon /></IconButton>
                        </td>
                        <td>
                            <FrmDebitNoteTextArea index={index} fieldName="Desc4" value={entity.Desc4} width="360" height="55" />
                        </td>
                        <td>佣金:</td>
                        <td>
                            <FrmDebitNoteInputField index={index} fieldName="Bal4" value={entity.Bal4} width="80" textAlign="right" />
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                            到期付款日:&nbsp;
                            <MyDateField dateValue={entity.DateDue} onDateChange={(d) => { dispatch(reportDebitNoteUpdate(index, 'DateDue', d)); }} />
                        </td>
                        <td>總數:</td>
                        <td><input readOnly value={entity.BalTotal??""} style={{ width:'80px', textAlign: "right" }} /></td>
                    </tr>
                </tbody>
            </table>
            <table style={{width:'auto'}}>
                <thead>
                    <tr>
                        <td style={{ width: '90px' }}>同事： </td>
                        <td>
                            <FrmDebitNoteInputField index={index} fieldName="IdEmployee" value={entity.IdEmployee} width={40} />
                            <FrmDebitNoteInputField index={index} fieldName="EmployeeName" value={entity.EmployeeName} width={100} />
                            <input type="button" value="..." onClick={(_) => { dispatch(reportDebitNoteSetModalEmployee(true, index)); }} />
                            {/*<input style={{ width: '55px' }} readOnly value={entity.Branch} />*/}
                        </td>
                        <td><Button className="ml-2" size="small" disableElevation color="primary" variant="contained" onClick={(_) => { print(index); }}>列印付款通知單</Button></td>
                    </tr>
                </thead>
            </table>
        </div>
    );
}

function FrmDebitNoteRadioField({ index, fieldName, label, value, selectedValue }) {
    //console.log(`value=${value}, selectedValue=${selectedValue}, checked=${value == selectedValue}`);

    const dispatch = useDispatch();

    const name = `field_${index}__${fieldName}`;
    const id = `${name}_${value}`;
    return <span className="mr-1 ml-1">
        <input className="mr-1" type="radio" value={value} id={id} name={name} onChange={(e) => { dispatch(reportDebitNoteUpdate(index, fieldName, e.target.value)); }} checked={selectedValue == value} />
        <label htmlFor={id}>{label}</label>
    </span>;
}

function FrmDebitNoteInputField({ index, fieldName, value, width, textAlign }) {
    const dispatch = useDispatch();

    return <input style={{ width: width ? width + 'px' : '100%', textAlign: textAlign ? textAlign: 'auto' }} value={value?value:''} onChange={(e) => { dispatch(reportDebitNoteUpdate(index, fieldName, e.target.value)); }} />
}

function FrmDebitNoteTextArea({ index, fieldName, value, width, height, tabIndex }) {
    const dispatch = useDispatch();

    return <textarea value={value ? value : ''} style={{ width: `${width}px`, height: `${height}px` }} tabIndex={tabIndex} onChange={(e) => { dispatch(reportDebitNoteUpdate(index, fieldName, e.target.value)); }} />
}
