import _ from 'lodash';

export default class CommShareAssoc {
    constructor({
        IdComm,
        IdEmployee,
        Name,
        Alias,
        Branch,
        IdCommShareOwner,
        OwnerComm,
        OwnerPercent,
        IdCommShareBuyer,
        BuyerComm,
        BuyerPercent,
        IdCommPrepaid,
        Prepaid,
    }) {
        this.IdComm = IdComm;
        this.IdEmployee = IdEmployee;
        this.Name = Name;
        this.Alias = Alias;
        this.Branch = Branch;
        this.IdCommShareOwner = IdCommShareOwner;
        this.OwnerComm = OwnerComm;
        this.OwnerPercent = OwnerPercent;
        this.IdCommShareBuyer = IdCommShareBuyer;
        this.BuyerComm = BuyerComm;
        this.BuyerPercent = BuyerPercent;
        this.IdCommPrepaid = IdCommPrepaid;
        this.Prepaid = Prepaid;
    }

    getPercent() {
        let percents = [];
        const ownerPercent = parseFloat(this.OwnerPercent);
        const buyerPercent = parseFloat(this.BuyerPercent);
        if (ownerPercent > 0) percents.push(ownerPercent);
        if (buyerPercent > 0) percents.push(buyerPercent);
        //console.log(percents);
        return _.isEmpty(percents) ? 0 : parseFloat(_.mean(percents).toFixed(2));
    }

    getDesc() {
        const percent = this.getPercent();
        const strPercent = (percent > 0) ? " " + percent.toFixed(2) : "";

        return `${this.Name}${strPercent}`;
    }
}
