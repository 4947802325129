import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import Moment from 'moment';

import { supervisorDetailAdd, supervisorDetailRemove, supervisorDetailUpdate  } from '../../redux/supervisorTeamReducer';

import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { BranchSelection } from '../Selections';
import MyToolTip from '../MyToolTip';
import MyDateField from '../MyDateField';

const GreenCheckBox = withStyles({
    root: {
        color: "silver",
        '&$checked': {
            color: "#00b100",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& a.nav-link:hover h1": {
            color: "#006ed0",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },
    },
    detail: {
        "& table": {
            width: "100%",
            backgroundColor: "white",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
                textAlign: 'center',
            },

            "& th": {
                backgroundColor: "#FAF3DE",
            },
        },
    },
}));

export default function SupervisorTeams() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isShowActiveOnly, setIsShowActiveOnly] = useState(true);

    const { supervisorDetailEntities } = useSelector(state => {
        return state.supervisorTeam;
    });

    const handleIsShowActiveOnlyChange = (e) => {
        setIsShowActiveOnly(!isShowActiveOnly);
    };

    return (
        <div className={classes.detail}>
            <h1>屬下分行列表</h1>
            <FormGroup row>
                <FormControlLabel control={<Switch checked={isShowActiveOnly} color="primary" onChange={handleIsShowActiveOnlyChange} name="checkedA" />} label={isShowActiveOnly ? `隱藏已結束屬下分行 ${supervisorDetailEntities && supervisorDetailEntities.length ? `(Total:${supervisorDetailEntities.length})`: "" }` : "顯示所有屬下分行"} />
            </FormGroup>
            <table>
                <thead>
                    <tr>
                        <th>行</th>
                        <th>分行</th>
                        <th>開始日期</th>
                        <th>結束日期</th>
                        <th>
                            <span>業績分行</span>
                            <MyToolTip message={<span>經理業績報告中，經理的營業額只包括<span style={{ color: 'red' }}>已揀選</span>的分行</span>} />
                        </th>
                        <th>　</th>
                    </tr>
                </thead>
                <tbody>
                    {supervisorDetailEntities.map((history, index) => <SupervisorTeam key={`history_${index}`} isShowActiveOnly={isShowActiveOnly} history={history} index={index} />) }
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="6" style={{ textAlign:"left" }}>
                            <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { dispatch(supervisorDetailAdd()); }}>新增屬下分行</Button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
}

function SupervisorTeam({ isShowActiveOnly, history, index }) {
    const dispatch = useDispatch();

    const today = Moment.utc().startOf('day');
    const tDate = history.Id && history.Tdate ? Moment.utc(history.Tdate, "YYYY-MM-DD HH:mm:ss") : today; // 要用 utc 呀！否則 timezone 會錯Q晒
    //console.log(history.Tdate);
    //console.log(`history.Tdate:${history.Tdate}, tDate:${tDate}, today:${today}`);

    const isShow = !isShowActiveOnly || !tDate.isBefore(today);

    return <tr style={{ display: isShow ? "table-row": "none" }}>
        <td>{index + 1}</td>
        <td><BranchSelection onChange={(e) => { dispatch(supervisorDetailUpdate(index, 'IdBranch', e.target.value)); }} value={history.IdBranch} tabIndex={99} /></td>
        <td><MyDateField dateValue={history.Fdate} onDateChange={(d) => { dispatch(supervisorDetailUpdate(index, 'Fdate', d)); }} tabIndex={99} /></td>
        <td><MyDateField dateValue={history.Tdate} onDateChange={(d) => { dispatch(supervisorDetailUpdate(index, 'Tdate', d)); }} tabIndex={99} /></td>
        <td>
            <GreenCheckBox
                checked={history.IsCal ?? false}
                onChange={(e) => { dispatch(supervisorDetailUpdate(index, 'IsCal', e.target.checked)); }}
            />
            {history.IsCal ?
                <b>包　括</b>
                : <span>不包括</span>
            }
        </td>
        <td><Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { dispatch(supervisorDetailRemove(index)); }}>移除</Button></td>
    </tr>;
}