import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';

import CommRef from '../models/commRef';
import { showLoading, showInfo, showErrorResponse, clearMsg } from './msgReducer';


//================== Whole State ==================//
const initialState = {
    isLoading: false,
    filter: { // match EntitiesResult<T>
        allRecords: 0,
        matchedRecords: 0,
        totalRecords: 0,
        qtyPerPage: 100,
        page: 1,
        row: 0,
        filter: "[]",
        sort: JSON.stringify([{ "property": "id", "direction": "DESC" }]),
        success: true,
        message: '',
    },
    entities: [],
    workingEntityId: -1,
    workingEntity: null,
    duplicateEntityId: -1,// for duplication in detail
    errors: '',
    lookupAddrsIsOpen: false,
    lookupAddrs: [],
};


//================== Action Types ==================//
export const CommRefActions = Object.freeze({
    // filter
    CHANGE_SORT: Symbol("CHANGE_SORT"),
    FILTER_UPDATE: Symbol("FILTER_UPDATE"),
    PAGE_UPDATE: Symbol("PAGE_UPDATE"),
    FETCH_PAGE: Symbol("FETCH_PAGE"),

    // listing
    REQUEST: Symbol("REQUEST"),
    SUCCESS: Symbol("SUCCESS"),
    FAILURE: Symbol("FAILURE"),

    // detail page
    DETAIL_ID: Symbol("DETAIL_ID"),
    DETAIL_SUCCESS: Symbol("DETAIL_SUCCESS"),
    DETAIL_FAILURE: Symbol("DETAIL_FAILURE"),
    DETAIL_UPDATE: Symbol("DETAIL_UPDATE"),
    DETAIL_DELETED: Symbol("DETAIL_DELETED"),

    // duplicate current workingEntity
    DUPLICATE_ID: Symbol("DUPLICATE_ID"),
    DUPLICATE_SUCCESS: Symbol("DUPLICATE_SUCCESS"),

    // lookup address
    LOOKUP_ADDRS_SHOW: Symbol("LOOKUP_ADDRS_SHOW"), 
    LOOKUP_ADDRS_SUCCESS: Symbol("LOOKUP_ADDRS_SUCCESS"),
    LOOKUP_ADDRS_FAILURE: Symbol("LOOKUP_ADDRS_FAILURE"),
    LOOKUP_ADDRS_HIDE: Symbol("LOOKUP_ADDRS_HIDE"),
});


//================== Action Creators ==================//
export const commRefFilterUpdate = (field, value) => {
    return {
        type: CommRefActions.FILTER_UPDATE,
        payload: {
            field: field,
            value: value
        }
    }
}

const _setWorkingEntityId = (id) => {
    return {
        type: CommRefActions.DETAIL_ID,
        payload: id,
    }
}

const _setDuplicateEntityId = (id) => {
    return {
        type: CommRefActions.DUPLICATE_ID,
        payload: id,
    }
}

export const commRefFilterClear = () => {
    return (dispatch) => {
        dispatch(_setWorkingEntityId(initialState.workingEntityId));
        dispatch(commRefLookup(initialState.filter));
    }
}

export const commRefChangeSort = (field) => {
    return (dispatch, getState) => {
        dispatch({
            type: CommRefActions.CHANGE_SORT,
            payload: field,
        });
        const { commRef: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(commRefLookup(filter));
    };
}

export const commRefPageUpdate = (page) => {
    return (dispatch, getState) => {
        const { commRef: { filter } } = getState(); // state updated

        let _page = 1;

        if (page == "") {
            _page = "";
        } else {
            _page = parseInt(page);
            if (_.isNaN(_page)) return;
            if (_page < 1) _page = 1;
            if (_page > Math.ceil(filter.matchedRecords / filter.qtyPerPage)) _page = Math.ceil(filter.matchedRecords / filter.qtyPerPage);
        }

        dispatch({
            type: CommRefActions.FETCH_PAGE,
            payload: _page,
        });
    };
}

export const commRefFetchPage = (page) => {
    return (dispatch, getState) => {
        dispatch({
            type: CommRefActions.FETCH_PAGE,
            payload: page,
        });
        const { commRef: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(commRefLookup(filter));
    };
}

const _commRefRequest = () => {
    return {
        type: CommRefActions.REQUEST
    }
}

const _commRefSuccess = (data) => {
    return {
        type: CommRefActions.SUCCESS,
        payload: data,
    }
}

const _commRefFailure = (response) => {
    return {
        type: CommRefActions.FAILURE,
        payload: response,
    }
}

export const commRefLookup = (formData) => {
    const _formData = formData ? formData : initialState.filter;
    //console.log(_formData);
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/CommRef/GetEntities`;

    return (dispatch, getState) => {
        dispatch(_commRefRequest());

        const { commRef } = getState(); // state updated
        if (commRef.isFilterUpdated) _formData.page = 1; // reset page if filter changed
        if (commRef.filter.page == "") _formData.page = 1; // reset page if filter changed

        axios.post(apiEndpoint, _formData, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch(_commRefSuccess(response.data));
                } else {
                    dispatch(_commRefFailure("未能讀取"));
                }
            })
            .catch(error => {
                dispatch(_commRefFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    }
}

const _commRefDetailSuccess = (data) => {
    return {
        type: CommRefActions.DETAIL_SUCCESS,
        payload: data,
    }
}

export const _commRefDuplicateSuccess = (data) => {
    return {
        type: CommRefActions.DUPLICATE_SUCCESS,
        payload: data,
    }
}

const _commRefDetailFailure = (response) => {
    return {
        type: CommRefActions.DETAIL_FAILURE,
        payload: response,
    }
}

export const commRefDetail = (Id, IdDuplicate, isForce) => {
    return (dispatch, getState) => {
        const { commRef: { workingEntityId: lastWorkingEntityId, duplicateEntityId: lastDuplicateEntityId } } = getState();

        let workingEntityId = -1;
        let fnSuccess = null;

        if (!IdDuplicate) {
            workingEntityId = Id;
            if (!isForce && lastWorkingEntityId == Id) return; // same entity
            dispatch(_setWorkingEntityId(Id)); // 任何 dispatch 之前先記住 current working entity Id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗
            fnSuccess = _commRefDetailSuccess;
        } else {
            workingEntityId = IdDuplicate;
            if (!isForce && lastDuplicateEntityId == IdDuplicate) return; // same entity
            dispatch(_setDuplicateEntityId(IdDuplicate)); // 任何 dispatch 之前先記住 current working entity Id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗
            fnSuccess = _commRefDuplicateSuccess;
        }

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/CommRef/GetEntity`;

        dispatch(_commRefRequest());

        axios.post(apiEndpoint, { Id: workingEntityId }, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch(fnSuccess(response.data));
                } else {
                    dispatch(_commRefDetailFailure("未能讀取"));
                }
            })
            .catch(error => {
                dispatch(_commRefDetailFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

export const commRefDetailUpdate = (field, value) => {
    //console.log(`${field} = ${value}`);
    return {
        type: CommRefActions.DETAIL_UPDATE,
        payload: { field, value }
    }
}

export const saveEntity = (history) => {
    return (dispatch, getState) => {
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/commRef/SaveEntity`;

        const { commRef: { workingEntity } } = getState();

        dispatch(showLoading());

        axios.post(apiEndpoint, workingEntity, {
            timeout: 30000,
        })
            .then(response => {
                if ((history.location.pathname == "/commRefDetail/0") || history.location.pathname.startsWith("/commRefDetail/0/")) {
                    history.replace(`/commRefDetail/${response.data.Id}`); // reload detail
                } else {
                    dispatch(commRefDetail(response.data.Id, null, true)); // update detail
                }
                dispatch(showInfo("已保存"));

                const { commRef: { filter } } = getState();
                dispatch(commRefLookup(filter)); // update list
            })
            .catch(error => {
                dispatch(_commRefDetailFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

export const deleteEntity = (history) => {
    return (dispatch, getState) => {
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/CommRef/DeleteEntity`;

        const { commRef: { workingEntity } } = getState();

        dispatch(showLoading());
        dispatch(_commRefRequest());

        axios.post(apiEndpoint, workingEntity, {
            timeout: 30000,
        })
            .then(response => {
                dispatch(clearMsg());
                history.push('/commRef');

                const { commRef: { filter } } = getState();
                dispatch(commRefLookup(filter));  // update list
            })
            .catch(error => {
                dispatch(_commRefDetailFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

const _commRefLookupAddrsSuccess = (data) => {
    return {
        type: CommRefActions.LOOKUP_ADDRS_SUCCESS,
        payload: data,
    }
}

const _commRefLookupAddrsFailure = (response) => {
    return {
        type: CommRefActions.LOOKUP_ADDRS_FAILURE,
        payload: response,
    }
}

export const commRefLookupAddrs = (fieldName) => {
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/CommRef/LookupAddrs`;

    return (dispatch, getState) => {
        const { comm: { workingEntity } } = getState(); // 取得 comm 的 workingEntity
        const data = { property: fieldName, value: workingEntity[fieldName] };
        //console.log(data)

        dispatch({ type: CommRefActions.LOOKUP_ADDRS_SHOW });

        axios.post(apiEndpoint, data, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch(_commRefLookupAddrsSuccess(response.data));
                } else {
                    dispatch(_commRefLookupAddrsFailure("未能讀取"));
                }
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.errors) {
                    dispatch(_commRefLookupAddrsFailure(error.response.data.errors));
                } else if (error.response && error.response.status) {
                    dispatch(_commRefLookupAddrsFailure(`未能讀取 (${error.response.status})`));
                } else {
                    dispatch(_commRefLookupAddrsFailure(`未能聯繫伺服器`));
                }
            })
    }
}

export const commRefLookupAddrsHide = () => {
    return {
        type: CommRefActions.LOOKUP_ADDRS_HIDE,
    }
}


//================== Reducer ==================//
const commRefReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);
    let i = -1;

    switch (action.type) {
        case CommRefActions.FILTER_UPDATE:
            const { field: filterField, value } = action.payload;
            let filters = JSON.parse(state.filter.filter);
            const iFilter = _.findIndex(filters, function (o) { return o.property == filterField; });
            if (iFilter >= 0) {
                if (value) filters[iFilter].value = value;
                else filters.splice(iFilter, 1);
            } else {
                filters.push({ "property": filterField, "value": value });
            }

            _state.filter.filter = JSON.stringify(filters);
            _state.isFilterUpdated = true;
            //console.log('_state.filter', _state.filter.filter);
            return _state;

        case CommRefActions.CHANGE_SORT:
            const field = action.payload;
            let sorts = JSON.parse(state.filter.sort);
            i = _.findIndex(sorts, function (o) { return o.property == field; });
            if (i >= 0) {
                sorts[i].direction = sorts[i].direction == "ASC" ? "DESC" : "ASC";
            } else {
                // single sort only
                sorts = [{ "property": field, "direction": "ASC" }];
            }

            _state.filter.sort = JSON.stringify(sorts);
            //console.log('_state.filter.sort', _state.filter.sort);

            commRefLookup(_state.filter);
            return _state;

        case CommRefActions.PAGE_UPDATE:
            _state.filter.page = action.payload;
            return _state;

        case CommRefActions.FETCH_PAGE:
            _state.filter.page = action.payload;
            return _state;

        case CommRefActions.REQUEST:
            _state.isLoading = true;
            return _state;

        case CommRefActions.SUCCESS:
            _state.isLoading = false;
            _state.filter = {
                allRecords: action.payload.allRecords,
                matchedRecords: action.payload.matchedRecords,
                totalRecords: action.payload.totalRecords,
                qtyPerPage: action.payload.qtyPerPage,
                page: action.payload.page,
                row: action.payload.row,
                filter: action.payload.filter,
                sort: action.payload.sort,
                success: action.payload.success,
                message: action.payload.message,
            };
            _state.entities = action.payload.entities.map(entity => new CommRef(entity));
            _state.errors = "";
            _state.isFilterUpdated = false;
            return _state;

        case CommRefActions.FAILURE:
            _state.isLoading = false;
            _state.errors = action.payload;
            _state.entities = [];
            return _state;

        case CommRefActions.DETAIL_ID:
            _state.workingEntityId = action.payload;
            _state.duplicateEntityId = null;
            return _state;

        case CommRefActions.DETAIL_SUCCESS:

            _state.isLoading = false;
            _state.workingEntity = new CommRef(action.payload);
            _state.workingEntityId = _state.workingEntity.Id;

            return _state;

        case CommRefActions.DUPLICATE_ID:
            _state.workingEntityId = 0;
            _state.duplicateEntityId = action.payload;
            return _state;

        case CommRefActions.DUPLICATE_SUCCESS:
            _state.isLoading = false;
            _state.workingEntity = new CommRef({ ...action.payload, Id: 0 }); // always 0 for duplication
            return _state;

        case CommRefActions.DETAIL_FAILURE:
            _state.isLoading = false;
            _state.errors = action.payload;
            return _state;

        case CommRefActions.DETAIL_UPDATE:
            const { field: updateField, value: updateValue } = action.payload;
            _state.workingEntity[updateField] = updateValue;
            _state.workingEntityId = _state.workingEntity.Id;
            return _state;

        case CommRefActions.LOOKUP_ADDRS_SHOW:
            _state.errors = '';
            _state.lookupAddrsIsOpen = true;
            _state.lookupAddrs = [];
            return _state;

        case CommRefActions.LOOKUP_ADDRS_SUCCESS:
            _state.lookupAddrs = action.payload.entities;
            return _state;

        case CommRefActions.LOOKUP_ADDRS_FAILURE:
            _state.errors = action.payload;
            return _state;

        case CommRefActions.LOOKUP_ADDRS_HIDE:
            _state.lookupAddrsIsOpen = false;
            return _state;

        default:
            return state;
    }
}

export default commRefReducer;

