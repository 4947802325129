import React from 'react';
import cfg from '../../config.json';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import axios from 'axios';

import { commTranUpdate, detailCommMoreCommTran, clearCommTran } from '../../redux/commTranReducer';

import MyDateField from '../MyDateField';
import Button from '@material-ui/core/Button';

import { openPdf } from '../../extensions/FileExtensions';

const useStyles = makeStyles((theme) => ({
  fieldSet: {
    border: "1px solid #c0c0c0",
    padding: "0px 5px 5px",
    backgroundColor: "#FFFFFF",
    lineHeight: '13px',

    '& legend': {
      width: 'auto',
    },

    "& table": {
      width: "100%",

      "& th, & td": {
        padding: "2px 6px",
        border: "1px solid #D5D5D5",
      },

      "& th": {
        backgroundColor: "#FAF3DE",
        textAlign: 'right',
      },
    },
    "& table.commShares th": {
      textAlign: 'center',
    },

    "& table.commShares .hidden": {
      display: "none",
    },

    "& table.commShares tr.row1st td": {
      backgroundColor: '#DBFFB8',
    },
  },
  backdrop: {
    backgroundColor: 'rgb(0, 0, 0, 0.2)',
  },
  paper: {
    margin: '40px auto',
    width: 320,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },
}));

// msgState, msgSetState is used for openPdf
export default function FieldsetCommDetailTrans({ msgState, msgSetState }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    commTran: { detailOwnerCommTrans, detailOwnerCommTransIsVisible, detailBuyerCommTrans, detailBuyerCommTransIsVisible, },
  } = useSelector(state => state);

  return (
    <>
      <fieldset className={clsx(classes.fieldSet, { "mt-3": true })}>
        <legend>公司已收佣金</legend>
        <table>
          <tbody>
            {detailOwnerCommTrans.map((commTran, nth) => <CommTranRow msgState={msgState} msgSetState={msgSetState} key={`commTranOwner_${nth}`} commTran={commTran} nth={nth} isVisible={detailOwnerCommTransIsVisible[nth]} baseTabIndex={107} />)}
            <tr>
              <td colSpan="2" >
                <Button onClick={(_) => { dispatch(detailCommMoreCommTran("RECEIVED", "OWNER")); }} size="small" variant="contained" disableElevation>更多已收業主佣金</Button>
              </td>
            </tr>
            {detailBuyerCommTrans.map((commTran, nth) => <CommTranRow msgState={msgState} msgSetState={msgSetState} key={`commTranOwner_${nth}`} commTran={commTran} nth={nth} isVisible={detailBuyerCommTransIsVisible[nth]} baseTabIndex={177} />)}
            <tr>
              <td colSpan="2" >
                <Button onClick={(_) => { dispatch(detailCommMoreCommTran("RECEIVED", "BUYER")); }} size="small" variant="contained" disableElevation>更多已收買家佣金</Button>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ height: '10px' }} />
      </fieldset>
    </>
  );

};

// msgState, msgSetState is used for openPdf
function CommTranRow({ commTran, nth, isVisible, baseTabIndex, msgState, msgSetState }) {
  const dispatch = useDispatch();

  const {
    comm: { workingEntity, }
  } = useSelector(state => state);

  const noEmail = (
    ((commTran.Party == "OWNER") && ((workingEntity?.OwnerEmail ?? "").length < 1))
    ||
    ((commTran.Party == "BUYER") && ((workingEntity?.BuyerEmail ?? "").length < 1))
  );

  const printReportReceipt = (commTran) => {
    openPdf("/api/ReportReceipt/GetReceipt", commTran, msgState, msgSetState);
  };

  const emailReportReceipt = async (commTran) => {
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/ReportReceipt/EmailReceipt`;

    try {
      msgSetState({ ...msgState, isShow: true, type: "LOADING" });

      var response = await axios.post(apiEndpoint, commTran, {
        timeout: 30000,
      });

      msgSetState({ ...msgState, isShow: true, type: "INFO", msg: "已傳送" });

    } catch (error) {
      console.log('emailReportReceipt', error.toString());
      msgSetState({ ...msgState, isShow: true, type: 'ERROR', msg: "未能電郵: " + error.toString() });
    }
  };

  return <tr style={{ display: isVisible ? "table-row" : "none" }}>
    <th style={{ width: '120px' }}> 收{commTran.Party == "OWNER" ? "業主" : "買家"}佣 {nth + 1}</th>
    <td>
      佣金 $ <CommTranInputField commTran={commTran} nth={nth} fieldName="Amount" width={70} tabIndex={baseTabIndex + nth * 7 + 1} />
      &nbsp;&nbsp;
      日期 <MyDateField dateValue={commTran.DateTran} onDateChange={(d) => { dispatch(commTranUpdate(commTran.Class, commTran.Party, nth, 'DateTran', d)); }} tabIndex={baseTabIndex + nth * 7 + 2} />
      &nbsp;&nbsp;
      <CommTranRadioField commTran={commTran} nth={nth} fieldName="PaymentMethod" label="現金" value="CASH" tabIndex={baseTabIndex + nth * 7 + 3} />
      &nbsp;
      <CommTranRadioField commTran={commTran} nth={nth} fieldName="PaymentMethod" label="支票" value="CHEQUE" tabIndex={baseTabIndex + nth * 7 + 4} />
      &nbsp;
      <CommTranInputField commTran={commTran} nth={nth} fieldName="PaymentCheque" width={120} tabIndex={baseTabIndex + nth * 7 + 5} />
      &nbsp;
      (Bank: <CommTranInputField commTran={commTran} nth={nth} fieldName="PaymentBank" width={120} tabIndex={baseTabIndex + nth * 7 + 6} />)
      &nbsp;&nbsp;
      <CommTranRadioField commTran={commTran} nth={nth} fieldName="PaymentMethod" label="銀行過數" value="BANK" tabIndex={baseTabIndex + nth * 7 + 7} />
      &nbsp;&nbsp;
      <Button onClick={(_) => { dispatch(clearCommTran(commTran.Class, commTran.Party, nth)); }} size="small" variant="contained" disableElevation>清除</Button>
      &nbsp;&nbsp;
      <Button onClick={(_) => { printReportReceipt(commTran); }} size="small" variant="contained" disabled={commTran.isNew()} disableElevation>列印正式收據</Button>
      &nbsp;&nbsp;
      <Button onClick={(_) => { emailReportReceipt(commTran); }} size="small" variant="contained" disabled={commTran.isNew() || noEmail} disableElevation>email正式收據</Button>
    </td>
  </tr>;
}

function CommTranInputField({ commTran, nth, fieldName, width, tabIndex, textAlign }) {
  const dispatch = useDispatch();
  return <input value={commTran[fieldName] ? commTran[fieldName] : ''} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(commTranUpdate(commTran.Class, commTran.Party, nth, fieldName, e.target.value)); }} />;
}

function CommTranRadioField({ commTran, nth, fieldName, label, value, tabIndex }) {
  const dispatch = useDispatch();
  const name = `commTran_${commTran.Party}_${nth}_${fieldName}`;
  const id = `${name}_${value}`;
  return <span>
    <input type="radio" value={value} id={id} name={name} onChange={e => { dispatch(commTranUpdate(commTran.Class, commTran.Party, nth, fieldName, value)); }} checked={commTran[fieldName] == value} tabIndex={tabIndex} />
    <label htmlFor={id}>{label}</label>
  </span>;
}

