import _ from 'lodash';

export default class ReportReceipt {
    constructor({
        Party,
        Name,
        Addr,
        Date,
        NoteNo,
        RefNo1,
        Desc1,
        Bal1,
        RefNo2,
        Desc2,
        Bal2,
        RefNo3,
        Desc3,
        Bal3,
        RefNo4,
        Desc4,
        Bal4,
        BalTotal,
        ReceiptNo,
        PaymentMethod,
        PaymentCheque,
        PaymentBank,
    }) {
        this.Party = Party;
        this.Name = Name;
        this.Addr = Addr;
        this.Date = Date;
        this.NoteNo = NoteNo;
        this.RefNo1 = RefNo1;
        this.Desc1 = Desc1;
        this.Bal1 = Bal1;
        this.RefNo2 = RefNo2;
        this.Desc2 = Desc2;
        this.Bal2 = Bal2;
        this.RefNo3 = RefNo3;
        this.Desc3 = Desc3;
        this.Bal3 = Bal3;
        this.RefNo4 = RefNo4;
        this.Desc4 = Desc4;
        this.Bal4 = Bal4;
        this.BalTotal = BalTotal;
        this.ReceiptNo = ReceiptNo;
        this.PaymentMethod = PaymentMethod;
        this.PaymentCheque = PaymentCheque;
        this.PaymentBank = PaymentBank;
    }
}
