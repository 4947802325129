import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Moment from 'moment';

import Employee from '../models/employee';
import { employeeDetail as employeeDetailHistories, saveEntitiesInEmployeeDetail, deleteEntitiesInEmployeeDetail } from './employeeHistoryReducer';
import { supervisorLookup as selectionSupervisorLookup } from './selectionsReducer';
import { showLoading, showInfo, showError, showErrorResponse, clearMsg } from './msgReducer';


//================== Whole State ==================//
const initialState = {
  isLoading: false,
  isFilterUpdated: false,
  filter: { // match EntitiesResult<T>
    allRecords: 0,
    matchedRecords: 0,
    totalRecords: 0,
    qtyPerPage: 100,
    page: 1,
    row: 0,
    //filter: "[]",
    filter: JSON.stringify([{ "property": "status", "value": "__IS_ON_THE_JOB__" }]),
    sort: JSON.stringify([{ "property": "name", "direction": "ASC" }]),
    success: true,
    message: '',
  },
  entities: [],
  isDetailInited: false,
  workingEntityId: -1,
  workingEntity: null,
  errors: '',
  lookupAddrsIsOpen: false,
  lookupAddrs: [],
};


//================== Action Types ==================//
export const EmployeeActions = Object.freeze({
  // filter
  FILTER_UPDATE: Symbol("FILTER_UPDATE"),
  CHANGE_SORT: Symbol("CHANGE_SORT"),
  PAGE_UPDATE: Symbol("PAGE_UPDATE"),
  FETCH_PAGE: Symbol("FETCH_PAGE"),

  // listing
  REQUEST: Symbol("REQUEST"),
  SUCCESS: Symbol("SUCCESS"),
  FAILURE: Symbol("FAILURE"),

  // detail page
  DETAIL_ID: Symbol("DETAIL_ID"),
  DETAIL_SUCCESS: Symbol("DETAIL_SUCCESS"),
  DETAIL_FAILURE: Symbol("DETAIL_FAILURE"),

  DETAIL_UPDATE: Symbol("DETAIL_UPDATE"), // form fields update
  DETAIL_UPDATE_BYHISTORY: Symbol("DETAIL_UPDATE_BYHISTORY"),
});


//================== Action Creators ==================//
export const employeeFilterUpdate = (field, value) => {
  return {
    type: EmployeeActions.FILTER_UPDATE,
    payload: {
      field: field,
      value: value
    }
  }
}

const _setWorkingEntityId = (id) => {
  return {
    type: EmployeeActions.DETAIL_ID,
    payload: id,
  }
}

export const employeeFilterClear = (history) => {
  return (dispatch) => {
    dispatch(_setWorkingEntityId(initialState.workingEntityId));
    dispatch(balEmployeeLookup(initialState.filter, history));
  }
}

export const employeeChangeSort = (field) => {
  return (dispatch, getState) => {
    dispatch({
      type: EmployeeActions.CHANGE_SORT,
      payload: field,
    });
    const { employee: { filter } } = getState(); // state updated
    //console.log(filter);
    dispatch(balEmployeeLookup(filter));
  };
}

export const employeePageUpdate = (page) => {
  return (dispatch, getState) => {
    const { employee: { filter } } = getState(); // state updated

    let _page = 1;

    if (page == "") {
      _page = "";
    } else {
      _page = parseInt(page);
      if (_.isNaN(_page)) return;
      if (_page < 1) _page = 1;
      if (_page > Math.ceil(filter.matchedRecords / filter.qtyPerPage)) _page = Math.ceil(filter.matchedRecords / filter.qtyPerPage);
    }

    dispatch({
      type: EmployeeActions.FETCH_PAGE,
      payload: _page,
    });
  };
}

export const employeeFetchPage = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: EmployeeActions.FETCH_PAGE,
      payload: page,
    });
    const { employee: { filter } } = getState(); // state updated
    //console.log(filter);
    dispatch(balEmployeeLookup(filter));
  };
}

const _employeeRequest = () => {
  return {
    type: EmployeeActions.REQUEST
  }
}

const _employeeSuccess = (data) => {
  return {
    type: EmployeeActions.SUCCESS,
    payload: data,
  }
}

const _employeeFailure = (response) => {
  return {
    type: EmployeeActions.FAILURE,
    payload: response,
  }
}

export const balEmployeeLookup = (formData) => {
  const _formData = formData ? formData : initialState.filter;
  //console.log(_formData);
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Employee/GetEntities`;

  return (dispatch, getState) => {
    dispatch(_employeeRequest());

    const { employee } = getState(); // state updated
    if (employee.isFilterUpdated) _formData.page = 1; // reset page if filter changed
    if (employee.filter.page == "") _formData.page = 1; // reset page if filter changed

    axios.post(apiEndpoint, _formData, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_employeeSuccess(response.data));
        } else {
          dispatch(_employeeFailure("未能讀取"));
        }
      })
      .catch(error => {
        dispatch(showErrorResponse(error));
      })
  }
}

const _employeeDetailSuccess = (data) => {
  return {
    type: EmployeeActions.DETAIL_SUCCESS,
    payload: data,
  }
}

const _employeeDetailFailure = (response) => {
  return {
    type: EmployeeActions.DETAIL_FAILURE,
    payload: response,
  }
}

export const employeeDetail = (Id, isForce) => {
  return (dispatch, getState) => {
    const { employee: { workingEntityId: lastWorkingEntityId } } = getState();
    if (!isForce && lastWorkingEntityId == Id) return; // same entity

    dispatch(_setWorkingEntityId(Id)); // 任何 dispatch 之前先記住 current working entity Id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Employee/GetEntity`;

    dispatch(_employeeRequest());

    axios.post(apiEndpoint, { Id }, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_employeeDetailSuccess(response.data));
          dispatch(employeeDetailHistories());
        } else {
          dispatch(_employeeDetailFailure("未能讀取"));
        }
      })
      .catch(error => {
        dispatch(_employeeDetailFailure("未能讀取"));
        dispatch(showErrorResponse(error));
      })
  };
}

export const employeeDetailUpdate = (field, value) => {
  //console.log(`${field} = ${value}`);
  return {
    type: EmployeeActions.DETAIL_UPDATE,
    payload: { field, value }
  }
}

export const saveEntity = (history) => {
  return (dispatch, getState) => {
    var isNew = (history.location.pathname == "/employeeDetail/new" ? true : false)

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Employee/SaveEntity?isNew=${isNew}`;

    const { employee: { workingEntity } } = getState();

    dispatch(showLoading());

    axios.post(apiEndpoint, workingEntity,
      {
        timeout: 30000,
      })
      .then(response => {
        return saveEntitiesInEmployeeDetail(response, getState)
          .then(_ => {
            //console.log(_);
            return response;
          });
      })
      .then(response => {
        if (history.location.pathname == "/employeeDetail/new") {
          history.replace(`/employeeDetail/${response.data.Id}`);
          dispatch(showInfo("已保存"));
          return;
        }

        dispatch(_employeeDetailSuccess(response.data));
        dispatch(employeeDetailHistories());
        const { employee: { filter } } = getState();
        dispatch(showInfo("已保存"));

        dispatch(balEmployeeLookup(filter));
        dispatch(selectionSupervisorLookup());
      })
      .catch(error => {
        dispatch(_employeeDetailFailure("未能讀取"));
        dispatch(showErrorResponse(error));
      })
  };
}

export const deleteEntity = (history) => {
  return (dispatch, getState, x) => {
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Employee/DeleteEntity`;

    const { employee: { workingEntity } } = getState();

    dispatch(showLoading());
    dispatch(_employeeRequest());

    axios.post(apiEndpoint, workingEntity, {
      timeout: 30000,
    })
      .then(response => {
        return deleteEntitiesInEmployeeDetail(response, getState)
          .then(_ => {
            //console.log(_);
            return response;
          });
      })
      .then(response => {
        const { employee: { filter } } = getState();

        dispatch(clearMsg());
        history.push('/employee');
        dispatch(balEmployeeLookup(filter));

        dispatch(balEmployeeLookup());
        dispatch(selectionSupervisorLookup());
      })
      .catch(error => {
        dispatch(_employeeDetailFailure("未能讀取"));
        dispatch(showErrorResponse(error));
      })
  };
}


//================== Reducer ==================//
const employeeReducer = (state = initialState, action) => {
  let _state = cloneDeep(state);

  switch (action.type) {
    case EmployeeActions.FILTER_UPDATE:
      const { field: filterField, value } = action.payload;
      let filters = JSON.parse(state.filter.filter);
      const iFilter = _.findIndex(filters, function (o) { return o.property == filterField; });
      if (iFilter >= 0) {
        if (value) filters[iFilter].value = value;
        else filters.splice(iFilter, 1);
      } else {
        filters.push({ "property": filterField, "value": value });
      }

      _state.filter.filter = JSON.stringify(filters);
      _state.isFilterUpdated = true;
      //console.log('_state.filter', _state.filter.filter);
      return _state;

    case EmployeeActions.CHANGE_SORT:
      const field = action.payload;
      let sorts = JSON.parse(state.filter.sort);
      const i = _.findIndex(sorts, function (o) { return o.property == field; });
      if (i >= 0) {
        sorts[i].direction = sorts[i].direction == "ASC" ? "DESC" : "ASC";
      } else {
        // single sort only
        sorts = [{ "property": field, "direction": "ASC" }];
      }

      _state.filter.sort = JSON.stringify(sorts);
      //console.log('_state.filter.sort', _state.filter.sort);

      balEmployeeLookup(_state.filter);
      return _state;

    case EmployeeActions.PAGE_UPDATE:
      _state.filter.page = action.payload;
      return _state;

    case EmployeeActions.FETCH_PAGE:
      _state.filter.page = action.payload;
      return _state;

    case EmployeeActions.REQUEST:
      _state.isLoading = true;
      return _state

    case EmployeeActions.SUCCESS:
      //console.log('EmployeeActions.SUCCESS', action.payload.entities);
      _state.isLoading = false;
      _state.filter = {
        allRecords: action.payload.allRecords,
        matchedRecords: action.payload.matchedRecords,
        totalRecords: action.payload.totalRecords,
        qtyPerPage: action.payload.qtyPerPage,
        page: action.payload.page,
        row: action.payload.row,
        filter: action.payload.filter,
        sort: action.payload.sort,
        success: action.payload.success,
        message: action.payload.message,
      };
      _state.entities = action.payload.entities.map(e => new Employee(e));
      _state.errors = "";
      _state.isFilterUpdated = false;
      return _state;

    case EmployeeActions.FAILURE:
      _state.isLoading = false;
      _state.errors = action.payload;
      _state.entities = [];
      return _state;

    case EmployeeActions.DETAIL_ID:
      _state.workingEntityId = action.payload;
      return _state;

    case EmployeeActions.DETAIL_SUCCESS:

      _state.isLoading = false;
      _state.workingEntity = new Employee(action.payload);
      _state.workingEntityId = _state.workingEntity.Id;
      //console.log(_state.workingEntity);
      //console.log(_state.workingEntity.getDesc());

      return _state;

    case EmployeeActions.DETAIL_FAILURE:
      _state.isLoading = false;
      _state.errors = action.payload;
      //console.log(_state.errors);
      return _state;

    case EmployeeActions.DETAIL_UPDATE:
      const { field: updateField, value: updateValue } = action.payload;
      _state.workingEntity[updateField] = updateValue;
      return _state;

    default:
      return state;
  }
}

export default employeeReducer;

