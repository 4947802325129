import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import ReactMoment from "react-moment";
import Moment from 'moment';

import Button from '@material-ui/core/Button';
import { CalMonthSelection } from "../Selections";

const useStyles = makeStyles((theme) => ({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& a.nav-link:hover h1": {
            color: "#006ed0",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },
    },
    detail: {
        "& table": {
            width: "100%",
            backgroundColor: "white",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
                textAlign: 'center',
            },

            "& th": {
                backgroundColor: "#FAF3DE",
            },
        },
    },
}));

export default function SupervisorDetailTableRates() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { supervisorDetailEntities: _supervisorDetailEntities } = useSelector(state => {
        return state.tableRate;
    });

    const { workingEntity: supervisor } = useSelector(state => {
        return state.supervisor;
    });

    const [state, setState] = useState({
        fCalMonth: Moment.utc().format("YYYY-MM-01"),
        tCalMonth: Moment.utc().format("YYYY-MM-01"),
    });

    const supervisorDetailEntities = _.filter(_supervisorDetailEntities, function (entity) {
        var entityFdate = Moment.utc(entity.Fdate ? entity.Fdate : new Date(), "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
        var entityTdate = Moment.utc(entity.Tdate ? entity.Tdate : new Date(), "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");

        var fCalMonth = Moment.utc(state.fCalMonth, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
        var tCalMonth = Moment.utc(state.tCalMonth, "YYYY-MM-DD HH:mm:ss").endOf('month').format("YYYY-MM-DD");
        //console.log(`state.fCalMonth:${fCalMonth}, state.tCalMonth:${tCalMonth}, entityFdate:${entityFdate}, entityTdate:${entityTdate}`);

        return entityFdate <= tCalMonth && entityTdate >= fCalMonth;
    });

    return (
        <div className={classes.detail}>
            <h1>佣金拆賬表</h1>
            <div className='mb-1' style={{ width: '400px' }}>
                <table className={classes.frmFilter}>
                    <tbody>
                        <tr>
                            <th>顯示範圍</th>
                            <td>
                                <CalMonthSelection value={state.fCalMonth} onChange={(e) => { setState({ ...state, fCalMonth: e.target.value }); }} />
                                <span> 至 </span>
                                <CalMonthSelection value={state.tCalMonth} onChange={(e) => { setState({ ...state, tCalMonth: e.target.value }); }} />
                            </td>
                        </tr>
                    </tbody >
                </table >
            </div>
            <table>
                <thead>
                    <tr>
                        <th>拆賬表編號</th>
                        <th>拆賬表</th>
                        <th>主管大數 Rate</th>
                        <th>開始日期</th>
                        <th>結束日期</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {supervisorDetailEntities.map((tableRate, index) =>
                    <tr key={`tableRates_${index}`}>
                        <td>{tableRate.Id}</td>
                            <td>{tableRate.getDesc()}</td>
                        <td style={{textAlign:'center'}}>
                            <div style={{ width: '250px', textAlign: 'left', display: "inline-block" }}>
                                {tableRate.Bars.map(b => b.getBarsComponent())}
                            </div>
                        </td>
                        <td>{tableRate.Fdate ? <ReactMoment format="YYYY-MM-DD" date={tableRate.Fdate} /> : "---"}</td>
                        <td>{tableRate.Tdate ? <ReactMoment format="YYYY-MM-DD" date={tableRate.Tdate} /> : "--- 應用中 ---"}</td>
                        <td>
                            <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { history.push(`/tableRateDetail/${tableRate.Id}`); }}>修改</Button>
                        </td>
                    </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="6" style={{ textAlign:"left" }}>
                            <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { history.push(`/tableRateDetail/0/${supervisor.IdEmployee}/0`); }}>新增佣金拆賬表</Button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
}
