export default class Comm {
  constructor({
    Id,
    RefNo,
    IdBranch,
    Branch,
    IdTeam,
    Team,
    Class,
    TagReport,
    Estate,
    CodeDistrict,
    District,
    Lot,
    StreetEng,
    StreetChi,
    BldgEng,
    BldgChi,
    Phrase,
    Block,
    Floor,
    Flat,
    LongAddr,
    AddrLang,
    CarPark,
    DateDeposit,
    DateContract,
    DateConclude,
    OffsetDateDue,
    DateDue,
    Price,
    Rent,
    CommOwner,
    CommBuyer,
    ReduceOwner,
    ReduceBuyer,
    DateReduceOwner,
    DateReduceBuyer,
    AcReceiveOwner,
    AcReceiveBuyer,
    BalOwner,
    BalBuyer,
    OwnerAddr,
    OwnerAddr2,
    OwnerMailingAddr,
    OwnerMailingAddr2,
    OwnerMailingReceiver,
    OwnerEng,
    OwnerChi,
    OwnerHkid,
    OwnerContact,
    OwnerContactRelation,
    OwnerEmail,
    OwnerEmailForm,
    OwnerTel,
    OwnerTelMobile,
    OwnerTelOther,
    OwnerTelPager,
    OwnerTel2,
    OwnerTelFax,
    OwnerSolicitorsEng,
    OwnerSolicitorsChi,
    OwnerLawyerEng,
    OwnerLawyerChi,
    OwnerLawyerTel,
    OwnerLawyerFax,
    BuyerAddr,
    BuyerAddr2,
    BuyerMailingAddr,
    BuyerMailingAddr2,
    BuyerMailingReceiver,
    BuyerEng,
    BuyerChi,
    BuyerHkid,
    BuyerContact,
    BuyerContactRelation,
    BuyerEmail,
    BuyerEmailForm,
    BuyerTel,
    BuyerTelMobile,
    BuyerTelOther,
    BuyerTelPager,
    BuyerTel2,
    BuyerTelFax,
    BuyerSolicitorsEng,
    BuyerSolicitorsChi,
    BuyerLawyerEng,
    BuyerLawyerChi,
    BuyerLawyerTel,
    BuyerLawyerFax,
    Remarks,
    RemarksLawyer,
    RemarksBank,
    RemarksDeco,
    IsExclusive,
    Relevant,
    Relevant1stId,
    Relevant1stName,
    EditDate,
    EditTime,
    EditUser,
  }) {
    this.Id = Id;
    this.RefNo = RefNo;
    this.IdBranch = IdBranch;
    this.Branch = Branch;
    this.IdTeam = IdTeam;
    this.Team = Team;
    this.Class = Class;
    this.TagReport = TagReport;
    this.Estate = Estate;
    this.CodeDistrict = CodeDistrict;
    this.District = District;
    this.Lot = Lot;
    this.StreetEng = StreetEng;
    this.StreetChi = StreetChi;
    this.BldgEng = BldgEng;
    this.BldgChi = BldgChi;
    this.Phrase = Phrase;
    this.Block = Block;
    this.Floor = Floor;
    this.Flat = Flat;
    this.LongAddr = LongAddr;
    this.AddrLang = AddrLang;
    this.CarPark = CarPark;
    this.DateDeposit = DateDeposit;
    this.DateContract = DateContract;
    this.DateConclude = DateConclude;
    this.OffsetDateDue = OffsetDateDue;
    this.DateDue = DateDue;
    this.Price = Price;
    this.Rent = Rent;
    this.CommOwner = CommOwner;
    this.CommBuyer = CommBuyer;
    this.ReduceOwner = ReduceOwner;
    this.ReduceBuyer = ReduceBuyer;
    this.DateReduceOwner = DateReduceOwner;
    this.DateReduceBuyer = DateReduceBuyer;
    this.AcReceiveOwner = AcReceiveOwner;
    this.AcReceiveBuyer = AcReceiveBuyer;
    this.BalOwner = BalOwner;
    this.BalBuyer = BalBuyer;
    this.OwnerAddr = OwnerAddr;
    this.OwnerAddr2 = OwnerAddr2;
    this.OwnerMailingAddr = OwnerMailingAddr;
    this.OwnerMailingAddr2 = OwnerMailingAddr2;
    this.OwnerMailingReceiver = OwnerMailingReceiver;
    this.OwnerEng = OwnerEng;
    this.OwnerChi = OwnerChi;
    this.OwnerHkid = OwnerHkid;
    this.OwnerContact = OwnerContact;
    this.OwnerContactRelation = OwnerContactRelation;
    this.OwnerEmail = OwnerEmail;
    this.OwnerEmailForm = OwnerEmailForm;
    this.OwnerTel = OwnerTel;
    this.OwnerTelMobile = OwnerTelMobile;
    this.OwnerTelOther = OwnerTelOther;
    this.OwnerTelPager = OwnerTelPager;
    this.OwnerTel2 = OwnerTel2;
    this.OwnerTelFax = OwnerTelFax;
    this.OwnerSolicitorsEng = OwnerSolicitorsEng;
    this.OwnerSolicitorsChi = OwnerSolicitorsChi;
    this.OwnerLawyerEng = OwnerLawyerEng;
    this.OwnerLawyerChi = OwnerLawyerChi;
    this.OwnerLawyerTel = OwnerLawyerTel;
    this.OwnerLawyerFax = OwnerLawyerFax;
    this.BuyerAddr = BuyerAddr;
    this.BuyerAddr2 = BuyerAddr2;
    this.BuyerMailingAddr = BuyerMailingAddr;
    this.BuyerMailingAddr2 = BuyerMailingAddr2;
    this.BuyerMailingReceiver = BuyerMailingReceiver;
    this.BuyerEng = BuyerEng;
    this.BuyerChi = BuyerChi;
    this.BuyerHkid = BuyerHkid;
    this.BuyerContact = BuyerContact;
    this.BuyerContactRelation = BuyerContactRelation;
    this.BuyerEmail = BuyerEmail;
    this.BuyerEmailForm = BuyerEmailForm;
    this.BuyerTel = BuyerTel;
    this.BuyerTelMobile = BuyerTelMobile;
    this.BuyerTelOther = BuyerTelOther;
    this.BuyerTelPager = BuyerTelPager;
    this.BuyerTel2 = BuyerTel2;
    this.BuyerTelFax = BuyerTelFax;
    this.BuyerSolicitorsEng = BuyerSolicitorsEng;
    this.BuyerSolicitorsChi = BuyerSolicitorsChi;
    this.BuyerLawyerEng = BuyerLawyerEng;
    this.BuyerLawyerChi = BuyerLawyerChi;
    this.BuyerLawyerTel = BuyerLawyerTel;
    this.BuyerLawyerFax = BuyerLawyerFax;
    this.Remarks = Remarks;
    this.RemarksLawyer = RemarksLawyer;
    this.RemarksBank = RemarksBank;
    this.RemarksDeco = RemarksDeco;
    this.IsExclusive = IsExclusive;
    this.Relevant = Relevant;
    this.Relevant1stId = Relevant1stId;
    this.Relevant1stName = Relevant1stName;
    this.EditDate = EditDate;
    this.EditTime = EditTime;
    this.EditUser = EditUser;
  }

  isNew() {
    return (this.Id == undefined) || (this.Id < 1) || (this.Id.toString().toUpperCase() == "NEW");
  }

  getDesc() {
    if (this.isNew()) return "新成交佣金";

    if (this.RefNo) return `物業編號 #${this.RefNo}`;

    return `#${this.Id}`;
  }
}
