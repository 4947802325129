import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { saveEntity, deleteEntity } from '../../redux/branchReducer';
import { showConfirm } from '../../redux/msgReducer';

import Button from '@material-ui/core/Button';

export default function BranchDetailBottomButtons() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { workingEntity } = useSelector(state => {
        return state.branch;
    });

    const navClicked = (path) => {
        history.push(path);
    };

    const confirmDelete = () => {
        const fnConfirm = (_) => { dispatch(deleteEntity(history)); };
        dispatch(showConfirm("確定刪除？", fnConfirm));
    };

    return <div style={{ textAlign: 'center' }} className="mt-5">
        <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { dispatch(saveEntity(history)); }}>儲存分行資料</Button>
        {workingEntity && !workingEntity.isNew() && <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { confirmDelete(); }}>刪　除</Button>}
        <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { navClicked("/branch"); }}>返回列表</Button>
    </div>
}
