import React from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { authLogout } from '../redux/authReducer';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import LargeLogo from "../style/logo_v2019.png";
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import BlockIcon from '@material-ui/icons/Block';

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#443d27",
    //background: "#ffc000",
    padding: "12px",
    borderBottom: "4px solid black",
  },

  btnLogo: {
    paddingRight: "10px",
    display: "inline-block",

    "& span": {
      backgroundImage: `url(${LargeLogo})`,
      width: '230px',
      height: '54px',
      display: "block",
      backgroundSize: 'contain',
    },
    //"& span": {
    //    backgroundImage: `url(${AdminLogo})`,
    //    height: '50px',
    //    width: '86px',
    //    marginRight: '10px',
    //    display: "inline-block",
    //},

    //"& h1": {
    //    fontSize: "20px",
    //    fontWeight: "bold",
    //    verticalAlign: "text-bottom",
    //    display: "inline-block",
    //},
  },

  headerText: {
    color: 'white',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 2, 2, 2),
  },
}));

export default function Header() {
  const classes = useStyles();

  const { jWTClaimModel } = useSelector(state => {
    return state.auth;
  });

  return (
    <Box display="flex" p={1} className={classes.root}>
      <Box flexGrow={1}>
        <BtnHome />
      </Box>
      {(jWTClaimModel !== null) &&
        <Box alignSelf="flex-end">
          <BtnProfile jWTClaimModel={jWTClaimModel} />
          <BtnLogout />
        </Box>
      }
    </Box>
  );
}

function BtnHome() {
  const classes = useStyles();

  const history = useHistory();

  return (
    <ButtonBase className={classes.btnLogo} disableRipple onClick={() => { history.push("/"); }}><span /></ButtonBase>
  );
}

function BtnProfile({ jWTClaimModel: { User } }) {
  const classes = useStyles();

  const history = useHistory();

  function showUserDetail() {
    history.push(`/setPassword`);
  }

  return (
    <Button className={classes.headerText} size="small" onClick={showUserDetail}>{User.Name}{User.Role ? ` (${User.Role})` : ''}</Button>
  );
}

function BtnLogout() {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  function logout() {
    dispatch(authLogout(history));
  }

  return (
    <>
      <Button variant="contained" size="small" onClick={() => { setIsModalOpen(true) }}>登出</Button>
      <Modal
        className={classes.modal}
        open={isModalOpen}
        onClose={() => { setIsModalOpen(false) }}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <Fade in={isModalOpen}>
          <div className={classes.paper}>
            <p>登出系統？</p>
            <Box display="flex" p={1}>
              <Box className="mr-2">
                <Button variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<DirectionsRunIcon />}
                  onClick={logout}
                >登出</Button>
              </Box>
              <Box>
                <Button variant="contained"
                  color="default"
                  size="small"
                  startIcon={<BlockIcon />}
                  onClick={() => { setIsModalOpen(false) }}
                >取消</Button>
              </Box>
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  );
}