import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { bankReferralLookup, isConfirmedLookup, bankReferralSaveAll } from '../../redux/bankReferralReducer';

import { openPdf } from '../../extensions/FileExtensions';
import { LocalModelMsg } from "../ModalMsg";

import BankReferralFilter from './BankReferralFilter';
import BankReferralList from './BankReferralList';

const useStyles = makeStyles({
  title: {
    paddingBottom: "15px",
    fontSize: "26px",
    lineHeight: "26px",
    fontWeight: "bold",
  },
});


export default function BankReferral() {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useDispatch();

  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

  const { filter } = useSelector(state => {
    return state.bankReferral;
  });

  const { entities } = useSelector(state => {
    return state.bankReferral;
  });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  useEffect(() => {
    dispatch(bankReferralLookup());
    dispatch(isConfirmedLookup());
  }, []);

  const saveAll = () => {
    dispatch(bankReferralSaveAll());
  }

  const _getFilterValue = (field) => {
    const filters = JSON.parse(filter.filter);
    const i = _.findIndex(filters, function (o) { return o.property == field; });
    return (i >= 0) ? filters[i].value : "";
  };

  const printEntity = (index, report) => {
    //console.log('printEntity', _getFilterValue('date_report'));

    const { IdEmployee } = entities[index];
    const params = {
      calMonth: _getFilterValue('date_report'),
      employeeIds: [IdEmployee],
    };

    switch (report) {
      case "ReportComm":
        openPdf("/api/ReportComm/GetPDF", params, msgState, msgSetState);
        break;
      case "ReportCommSupervisor":
        openPdf("/api/ReportCommSupervisor/GetPDF", params, msgState, msgSetState);
        break;
    }
  };

  return (
    <div>
      <Typography variant="h1" className={classes.title}>4.2. Bank Referral</Typography>
      <BankReferralFilter filter={filter} />

      <div style={{ overflow: 'auto' }}>
        <BankReferralList printEntity={printEntity} />
      </div>
      <div style={{ margin: "10px", textAlign: 'center' }}>
        <Button variant="contained" size="small" onClick={saveAll}>保　　存</Button>
      </div>

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
    </div>
  );
}
