import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import axios from 'axios';
import cfg from '../../../config.json';
import Comm from '../../../models/comm';

import { showLoading, showInfo, showErrorResponse, clearMsg } from '../../../redux/msgReducer';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';

import { CalMonthSelection } from "../../Selections";
import MyDateField from "../../MyDateField";

const useStyles = makeStyles({
    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        //width: "100%",

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },

        "& label": {
            marginBottom: "0px",
        }
    },
});

export default function ReportCommOption({ state, setState }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { calMonths } = useSelector(state => {
        return state.selections;
    });

    // init
    if (_.isEmpty(state.calMonth) && !_.isEmpty(calMonths)) {
        setState({ ...state, calMonth: calMonths[0].format("YYYY-MM-DD") });
    }

    return (
        <div>
            <table className={clsx(classes.frmFilter, { "mb-3": true })}>
                <tbody>
                    <tr>
                        <th style={{ textAlign: 'right', width: '130px' }}>計算年度及月份：</th>
                        <td>
                            <CalMonthSelection value={state.calMonth} onChange={(e) => { setState({ ...state, calMonth: e.target.value }); }} tabIndex="1" />
                        </td>
                    </tr>
                    <tr>
                        <th style={{ textAlign: 'right', width: '130px' }}>佣金日期：</th>
                        <td>
                            <RptCommFilterRadioField state={state} setState={setState} fieldName="autoDate" label="自動選取，只列印尚餘未收及收到佣金" value="__AUTO__" />
                            <RptCommFilterRadioField state={state} setState={setState} fieldName="autoDate" label="指定範圍的所有交易" value="__CUSTOMIZE__" />
                            {state.autoDate == "__CUSTOMIZE__" &&
                                <React.Fragment>
                                <MyDateField dateValue={state.fDate} onDateChange={(d) => { setState({ ...state, fDate: d?.format("YYYY-MM-DD") }); }} tabIndex={1} />
                                &nbsp;至&nbsp;
                                <MyDateField dateValue={state.tDate} onDateChange={(d) => { setState({ ...state, tDate: d?.format("YYYY-MM-DD") }); }} tabIndex={2} />
                                </React.Fragment>
                            }
                        </td>
                    </tr>
                {/*    <tr>*/}
                {/*        <th rowSpan={2} style={{ textAlign: 'right', width: '130px' }}>列印選項：</th>*/}
                {/*        <td>*/}
                {/*            <RptCommFilterRadioField state={state} setState={setState} fieldName="outStanding" label="只列印尚餘未收及收到佣金" value="__OUTSTANDING__" />*/}
                {/*            <RptCommFilterRadioField state={state} setState={setState} fieldName="outStanding" label="所有交易" value="__ANY__" />*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <td>*/}
                {/*            <RptCommFilterRadioField state={state} setState={setState} fieldName="isShowPrepaid" label="只顯示員工應得" value="__NO__" />*/}
                {/*            <RptCommFilterRadioField state={state} setState={setState} fieldName="isShowPrepaid" label="顯示預支佣金及員工應得" value="__YES__" />*/}
                {/*        </td>*/}
                {/*    </tr>*/}
                </tbody>
            </table>
        </div>
    );
}

function RptCommFilterRadioField({ state, setState, fieldName, label, value }) {
    const name = `filter_${fieldName}`;
    const id = `${name}_${value}`;
    return <span className="mr-1 ml-1">
        <input className="mr-1" type="radio" value={value} id={id} name={name} onChange={(e) => { setState({ ...state, [fieldName]: e.target.value, }); }} checked={state[fieldName] == value} />
        <label htmlFor={id}>{label}</label>
    </span>;
}
