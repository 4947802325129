import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import clsx from 'clsx';
import cfg from '../../config.json';

import { commDetailUpdate } from '../../redux/commReducer';
import { commRefLookupAddrs } from '../../redux/commRefReducer';

import CommDetailInputField, { CommDetailKeyDownloadInputField } from './CommDetailInputField';
import MyDateField from '../MyDateField';

import { BranchSelection, TeamSelection, DistrictSelection, DfnCommClassSelection, DfnCommTagReportSelection } from '../Selections';

const useStyles = makeStyles({
    fieldSet: {
        border: "1px solid #c0c0c0",
        padding: "0px 5px 5px",
        backgroundColor: "#FFFFFF",
        lineHeight: '13px',

        '& legend': {
            width: 'auto',
        },

        "& table": {
            width: "100%",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
            },

            "& th": {
                backgroundColor: "#FAF3DE",
                textAlign: 'right',
            },
        },
    }
});

export default function FieldsetCommInfo({ msgState, msgSetState }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const { workingEntity } = useSelector(state => {
        return state.comm;
    });

    const [ isChecking, setIsChecking ] = useState(false);

    const lookupAddress = (fieldName) => {
        if (!workingEntity[fieldName]) return;

        dispatch(commRefLookupAddrs(fieldName));
    };

    const checkRefNoIsExists = async (e) => {
        if (!workingEntity.isNew()) return;
        if (!e.target.value) return;

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Comm/CheckRefNoIsNotExists`;

        setIsChecking(true);

        try {
            await axios.post(apiEndpoint, { RefNo: e.target.value }, {
                timeout: 30000,
            });

            setIsChecking(false);

        } catch (error) {
            var errMsg = error?.response?.data ?? `${e.target.value} 未能使用`;
            msgSetState({ ...msgState, isShow: true, type: "ERROR", msg: errMsg });

            setIsChecking(false);
        }
    }

    return <fieldset className={clsx(classes.fieldSet, { "mt-3": true })}>
        <legend>佣金資料</legend>
        <table>
            <tbody>
                <tr>
                    <th style={{ width: '75px' }}>物業編號</th>
                    <td>
                        <CommDetailInputField fieldName="RefNo" tabIndex={1} width={150} onBlur={checkRefNoIsExists} />
                        <div style={{ height: '15px', width: '120px', display: 'inline-block', visibility: isChecking ? "visible":"hidden"}}>
                            <span style={{ color: 'red' }}>&nbsp;檢查相同編號..</span>
                        </div>
                    </td>
                    <th style={{ width: '75px' }}>分行</th>
                    <td><BranchSelection showAny={false} onChange={(e) => { dispatch(commDetailUpdate('IdBranch', e.target.value)); }} value={workingEntity.IdBranch} tabIndex={2} /></td>
                    <th style={{ width: '75px' }}>組別</th>
                    <td><TeamSelection showAny={false} onChange={(e) => { dispatch(commDetailUpdate('IdTeam', e.target.value)); }} value={workingEntity.IdTeam} tabIndex={3} /></td>
                    <th style={{ width: '75px' }}>樓盤類別</th>
                    <td><DfnCommClassSelection showAny={false} onChange={(e) => { dispatch(commDetailUpdate('Class', e.target.value)); }} value={workingEntity.Class} tabIndex={4} /></td>
                </tr>
                <tr>
                    <th style={{ width: '75px' }}>地區</th>
                    <td colSpan={7}><DistrictSelection showAny={false} onChange={(e) => { dispatch(commDetailUpdate('District', e.target.value)); }} value={workingEntity.District} tabIndex={5} /></td>
                </tr>
                <tr>
                    <th style={{ width: '75px' }}>Lot/街</th><td colSpan={7}>
                        <CommDetailKeyDownloadInputField fieldName="Lot" tabIndex={6} width={380} onKeyDown={(e) => { if (e.keyCode !== 17) return; lookupAddress('Lot'); }} />
                        <input type="button" value="..." onClick={(_) => { lookupAddress('Lot'); }} />
                    </td>
                </tr>
                <tr>
                    <th style={{ width: '75px' }}>街道(英)</th><td colSpan={3}>
                        <CommDetailKeyDownloadInputField fieldName="StreetEng" tabIndex={7} width={380} onKeyDown={(e) => { if (e.keyCode !== 17) return; lookupAddress('StreetEng'); }} />
                        <input type="button" value="..." onClick={(_) => { lookupAddress('StreetEng'); }} />
                    </td>
                    <th style={{ width: '75px' }}>大廈(英)</th><td colSpan={3}>
                        <CommDetailKeyDownloadInputField fieldName="BldgEng" tabIndex={9} width={380} onKeyDown={(e) => { if (e.keyCode !== 17) return; lookupAddress('BldgEng'); }} />
                        <input type="button" value="..." onClick={(_) => { lookupAddress('BldgEng'); }} />
                    </td>
                </tr>
                <tr>
                    <th style={{ width: '75px' }}>街道(中)</th><td colSpan={3}>
                        <CommDetailKeyDownloadInputField fieldName="StreetChi" tabIndex={8} width={380} onKeyDown={(e) => { if (e.keyCode !== 17) return; lookupAddress('StreetChi'); }} />
                        <input type="button" value="..." onClick={(_) => { lookupAddress('StreetChi'); }} />
                    </td>

                    <th style={{ width: '75px' }}>大廈(中)</th><td colSpan={3}>
                        <CommDetailKeyDownloadInputField fieldName="BldgChi" tabIndex={10} width={380} onKeyDown={(e) => { if (e.keyCode !== 17) return; lookupAddress('BldgChi'); }} />
                        <input type="button" value="..." onClick={(_) => { lookupAddress('BldgChi'); }} />
                    </td>
                </tr>
                <tr>
                    <th style={{ width: '75px' }}>期數</th>
                    <td><CommDetailInputField fieldName="Phrase" tabIndex={11} width={50} /></td>
                    <th style={{ width: '75px' }}>座</th>
                    <td><CommDetailInputField fieldName="Block" tabIndex={12} width={50} /></td>
                    <th style={{ width: '75px' }}>層數</th>
                    <td><CommDetailInputField fieldName="Floor" tabIndex={13} width={50} /></td>
                    <th style={{ width: '75px' }}>單位</th>
                    <td><CommDetailInputField fieldName="Flat" tabIndex={14} width={50} /></td>
                </tr>
                <tr>
                    <th style={{ width: '75px' }}>落訂日期</th>
                    <td><MyDateField dateValue={workingEntity.DateDeposit} onDateChange={(d) => { dispatch(commDetailUpdate('DateDeposit', d)); }} tabIndex={15} /></td>
                    <th style={{ width: '75px' }}>賣價</th>
                    <td style={{ width: '110px' }}>$ <CommDetailInputField fieldName="Price" tabIndex={16} width={50} /> 百萬</td>
                    <th style={{ width: '75px' }}>租金</th>
                    <td style={{ width: '110px' }}>$ <CommDetailInputField fieldName="Rent" tabIndex={17} width={50} /></td>
                    <th style={{ width: '75px' }}>特別類別</th>
                    <td><DfnCommTagReportSelection showAny={false} onChange={(e) => { dispatch(commDetailUpdate('TagReport', e.target.value)); }} value={workingEntity.TagReport} tabIndex={18} /></td>
                </tr>
                <tr>
                    <th style={{ width: '75px' }}>合約日期</th><td><MyDateField dateValue={workingEntity.DateContract} onDateChange={(d) => { dispatch(commDetailUpdate('DateContract', d)); }} tabIndex={19} /></td>
                    <th style={{ width: '75px' }}>成交日期</th><td><MyDateField dateValue={workingEntity.DateConclude} onDateChange={(d) => { dispatch(commDetailUpdate('DateConclude', d)); }} tabIndex={20} /></td>
                    <th style={{ width: '75px' }}>連車位</th><td colSpan="3"><CommDetailInputField fieldName="CarPark" tabIndex={21} width={60} /></td>
                    {/*<th style={{ width: '75px' }}>地址語言</th>*/}
                    {/*<td>*/}
                    {/*    <div className="form-check form-check-inline">*/}
                    {/*        <input className="form-check-input" type="radio" id="addr_lang_zh" value="ZH" onChange={_ => { dispatch(commDetailUpdate('AddrLang', "ZH")); }} checked={workingEntity.AddrLang == "ZH"} tabIndex={22} />*/}
                    {/*        <label className="form-check-label" htmlFor="addr_lang_zh">中文</label>*/}
                    {/*    </div>*/}
                    {/*    <div className="form-check form-check-inline">*/}
                    {/*        <input className="form-check-input" type="radio" id="addr_lang_en" value="EN" onChange={_ => { dispatch(commDetailUpdate('AddrLang', "EN")); }} checked={workingEntity.AddrLang == "EN"} tabIndex={22} />*/}
                    {/*        <label className="form-check-label" htmlFor="addr_lang_en">英文</label>*/}
                    {/*    </div>*/}
                    {/*</td>*/}
                </tr>
            </tbody>
        </table>
    </fieldset>;
}
