import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'moment';
import _ from 'lodash';

import { employeeVacationDetails, employeeVacationDetailUpdate } from '../../redux/employeeVacationReducer';

import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import BeatLoader from "react-spinners/BeatLoader";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';

import EmployeeVacationDetailInputField from './EmployeeVacationDetailInputField';
import EmployeeVacationDetailBottomButtons from './EmployeeVacationDetailBottomButtons';
import MyDateField from '../MyDateField';

const useStyles = makeStyles((theme) => ({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& a.nav-link:hover h1": {
            color: "#006ed0",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },
    },
    table: {
        width: "100%",
        backgroundColor: "white",

        "& tr.alt": {
            backgroundColor: "#FFF9F0",
        },

        "& th": {
            backgroundColor: "#FAF3DE",
        },

        "& th, & td": {
            padding: "2px 6px",
            border: "1px solid #D5D5D5",
            textAlign: 'center',
        },
    },
}));

export default function EmployeeVacationDetails() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { isLoading, workingEntities } = useSelector(state => {
        return state.employeeVacation;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    const { calMonth } = useParams();

    //console.log('calMonth', calMonth);
    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");

        dispatch(employeeVacationDetails(calMonth));
    }, [calMonth]);

    const navClicked = (path) => {
        history.push(path);
    };

    return (
        <>
            <Breadcrumbs className={classes.title}>
                <NavLink className="nav-link" tag={Link} to="/employeeVacation/list">
                    <Typography variant="h1" className={classes.title}>4.1. 放假日數</Typography>
                </NavLink>
                <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : Moment(calMonth).format('YYYY 年 MM 月')}</Typography>
            </Breadcrumbs>
            { workingEntities &&
                <>
                    <Grid container>
                        <Button variant="contained" className="mr-4" size="small" disableElevation onClick={() => { navClicked("/employeeVacation"); }}>返回列表</Button>
                        <div style={{ flex: 1 }} />
                        <Button variant="contained" size="small" disableElevation onClick={() => { dispatch(employeeVacationDetails(calMonth, true)); }}><RefreshIcon /></Button>
                    </Grid>

                <div style={{ height: '10px' }}></div>
                {(workingEntities.length > 0) &&
                    <table className={classes.table} style={{ width: '270px' }}>
                        <tbody>
                            <tr>
                                <th style={{ width: '120px' }}>月份</th><td><MyDateField dateValue={workingEntities[0].CalMonth} onDateChange={(d) => { dispatch(employeeVacationDetailUpdate(0, 'CalMonth', d)); }} tabIndex={1} /></td>
                            </tr>
                        </tbody>
                    </table>
                }

                <div style={{height:'10px'}}></div>

                {workingEntities &&
                    <table className={classes.table} style={{ width: '600px' }}>
                        <thead>
                            <tr>
                                <th>分行</th>
                                <th>編號</th>
                                <th>同事</th>
                                <th>放假日數</th>
                            </tr>
                        </thead>
                    <tbody>
                        {workingEntities.map((employeeVacation, index) =>
                            <tr key={`employeeVacation_${index}`} className={index%2 == 0? "normal": "alt"}>
                                <td>
                                    {employeeVacation.Branch}
                                </td>
                                <td>
                                    {employeeVacation.IdEmployee}
                                </td>
                                <td>
                                    {employeeVacation.Name}
                                </td>
                                <td>
                                    <EmployeeVacationDetailInputField index={index} fieldName="QtyVacation" width={60} tabIndex={index} textAlign="right" />
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                }

                    <EmployeeVacationDetailBottomButtons />
                </>
            }
        </>
    );}
