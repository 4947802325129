import React, { useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';
import Moment from 'moment';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ReactMoment from "react-moment";
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

import { reportCommPrepaidChangeSort, gotoCommDetail } from '../../../redux/reportCommPrepaidReducer';

const useStyles = makeStyles({
    tblList: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& thead": {
            backgroundColor: "#c5e2ff",
            color: "#45525c",
        },

        "& tr": {
            cursor: "pointer",
        },

        "& tr.alt": {
            backgroundColor: "#f7fbff",
        },

        "& tr.lastWorkingEntity": {
            border: "2px solid #da59da",
        },

        "& tbody tr:hover, tbody tr.alt:hover": {
            backgroundColor: "#ffffcc !important",
        },

        "& th": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },
    },
});

export default function ReportCommPrepaidList() {
    const classes = useStyles();

    const history = useHistory();
    const dispatch = useDispatch();

    const { isLoading, filter, entities, totalAmount, errors, workingEntityId } = useSelector(state => {
        return state.reportCommPrepaid;
    });

    const { page, qtyPerPage } = filter;

    const sorts = JSON.parse(filter.sort);

    const _getSortDirection = (field) => {
        const i = _.findIndex(sorts, function (o) { return o.property == field; });
        return (i >= 0) ? sorts[i].direction : "";
    };

    const changeSort = (field) => {
        dispatch(reportCommPrepaidChangeSort(field, filter));
    };

    const intlAbs2 = Intl.NumberFormat(undefined, { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 });
    const intlMax2 = Intl.NumberFormat(undefined, { style: 'decimal', maximumFractionDigits: 2 });
    const intlMin3 = Intl.NumberFormat(undefined, { style: 'decimal', minimumFractionDigits: 3 });

    const myRef = useRef(null);
    useEffect(() => {
        if (myRef.current) myRef.current.scrollIntoView({ block: "center" });
    }, []);

    return (
        <table className={classes.tblList} >
            <thead>
                <tr>
                    <th rowSpan="2">行</th>
                    <th rowSpan="2" onClick={(_) => changeSort('date_deposit')}><span>落訂日期</span><MyArrowIcon direction={_getSortDirection("date_deposit")} /></th>
                    <th rowSpan="2" onClick={(_) => changeSort('ref_no')}>物業編號<MyArrowIcon direction={_getSortDirection("ref_no")} /></th>
                    <th rowSpan="2" onClick={(_) => changeSort('bldg_chi')}>大廈<MyArrowIcon direction={_getSortDirection("bldg_chi")} /></th>
                    <th rowSpan="2" onClick={(_) => changeSort('branch')}>分行<MyArrowIcon direction={_getSortDirection("branch")} /></th>
                    <th rowSpan="2" onClick={(_) => changeSort('id_employee')}>員工編號<MyArrowIcon direction={_getSortDirection("id_employee")} /></th>
                    <th rowSpan="2" onClick={(_) => changeSort('name')}>員工<MyArrowIcon direction={_getSortDirection("name")} /></th>
                    <th rowSpan="2" onClick={(_) => changeSort('price')}>售價 (M)<MyArrowIcon direction={_getSortDirection("price")} /></th>
                    <th colSpan="2">佣金</th>
                    <th colSpan="2">分成（％）</th>
                    <th rowSpan="2" onClick={(_) => changeSort('amount')}>預支金額<MyArrowIcon direction={_getSortDirection("amount")} /></th>
                    <th rowSpan="2">&nbsp;</th>
                </tr>
                <tr>
                    <th onClick={(_) => changeSort('comm_owner')}>業主<MyArrowIcon direction={_getSortDirection("comm_owner")} /></th>
                    <th onClick={(_) => changeSort('comm_buyer')}>買家<MyArrowIcon direction={_getSortDirection("comm_buyer")} /></th>
                    <th onClick={(_) => changeSort('percent_owner')}>業主<MyArrowIcon direction={_getSortDirection("percent_owner")} /></th>
                    <th onClick={(_) => changeSort('percent_buyer')}>買家<MyArrowIcon direction={_getSortDirection("percent_buyer")} /></th>
                </tr>
            </thead>

            {isLoading
                ?
                <tbody>
                    <tr>
                        <td colSpan={14} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
                    </tr>
                </tbody>
                :
                (errors !== "")
                    ?
                    <tbody>
                        <tr>
                            <td colSpan={14} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
                        </tr>
                    </tbody >
                    :

                    <tbody>
                        {
                            entities.length < 1
                                ? <tr>
                                    <td colSpan={14} style={{ padding: "3em", textAlign: "center", }}><em>沒有符合的記錄</em></td>
                                </tr>
                                : entities.map((entity, index) => {
                                    return <tr key={entity.Id + "_" + index} ref={workingEntityId == entity.Id ? myRef : null} className={clsx({ "lastWorkingEntity": workingEntityId == entity.Id, "alt": index % 2 == 1, "normal": index % 2 == 0 })}>
                                        <td>{(page - 1) * qtyPerPage + index + 1}</td>
                                        <td>{entity.DateDeposit && <ReactMoment format="YYYY-MM-DD" date={entity.DateDeposit} />}</td>
                                        <td><Link onClick={(_) => { dispatch(gotoCommDetail(entity.Id)); }} to={`/commDetail/${entity.Id}`}>{entity.RefNo}</Link></td>
                                        <td><Link onClick={(_) => { dispatch(gotoCommDetail(entity.Id)); }} to={`/commDetail/${entity.Id}`}>{entity.BldgChi}</Link></td>
                                        <td>{entity.Branch}</td>
                                        <td>{entity.IdEmployee}</td>
                                        <td>{entity.Name}</td>
                                        <td>{entity.Price ? intlMin3.format(entity.Price) + " M" : "-"}</td>
                                        <td>{entity.CommOwner ? "$ " + intlAbs2.format(entity.CommOwner) : "-"}</td>
                                        <td>{entity.CommBuyer ? "$ " + intlAbs2.format(entity.CommOwner) : "-"}</td>
                                        <td>{entity.PercentOwner ? intlMax2.format(entity.PercentOwner) + "%" : "-"}</td>
                                        <td>{entity.PercentBuyer ? intlMax2.format(entity.PercentBuyer) + "%": "-"}</td>
                                        <td>{entity.Prepaid ? "$ " + intlAbs2.format(entity.Prepaid) : "-"}</td>
                                        <td><Link onClick={(_) => { dispatch(gotoCommDetail(entity.Id)); }} to={`/reportComm/${Moment(entity.DateDeposit).format('YYYY-MM-01')}/${entity.IdEmployee}`}>糧單</Link></td>
                                    </tr>
                                })
                        }
                    </tbody>
            }
        </table>    );
}

function MyArrowIcon({ direction }) {
    switch (direction) {
        case "ASC": return <ArrowDropUpIcon />;
        case "DESC": return <ArrowDropDownIcon />;
        default: return null;
    }
}
