import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { commLookup, commFetchPage, commPageUpdate } from '../../redux/commReducer';
import { downloadXls } from '../../extensions/FileExtensions';

import CommFilter from './CommFilter';
import CommList from './CommList';
import Pagination from '../Pagination';
import { LocalModelMsg } from "../ModalMsg";

const useStyles = makeStyles({
    title: {
        paddingBottom: "15px",
        fontSize: "26px",
        lineHeight: "26px",
        fontWeight: "bold",
    },
});

export default function Comm() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { hasFilter } = useParams();

    const newComm = () => {
        history.push("/commDetail/0");
    }

    const { filter } = useSelector(state => {
        return state.comm;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");

        dispatch(commLookup(hasFilter ? filter : null));
    }, []);

    const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

    const download = () => {
        downloadXls("/api/Comm/GetXlsx", {
            filter
        }, "成交佣金.xlsx", msgState, msgSetState);
    }

    return (
        <>
            <Typography variant="h1" className={classes.title}>1. 成交佣金</Typography>
            <Grid container justifyContent="space-between" className="mb-2">
                <Grid item>
                    <Button variant="contained" size="small" onClick={newComm}>新增成交佣金</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" size="small" onClick={download}>下載 Excel 檔</Button>
                </Grid>
            </Grid>
            <CommFilter filter={filter} />
            <Pagination filter={filter} fnFetchPage={commFetchPage} fnPageUpdate={commPageUpdate} />
            <CommList />
            <div style={{ margin: "5px", textAlign:'center' }}>
                <Pagination filter={filter} fnFetchPage={commFetchPage} fnPageUpdate={commPageUpdate} />
            </div>

            <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
        </>
    );
}
