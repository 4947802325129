import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Moment from 'moment';

import SupervisorTeam from '../models/supervisorTeam';

import { supervisorDetailUpdate as supervisorUpdate } from './supervisorReducer';
import { showLoading, showInfo, showErrorResponse, clearMsg } from './msgReducer';

//================== Whole State ==================//
const initialState = {
    isLoading: false,
    errors: '',
    supervisorDetailEntities: [],
};


//================== Action Types ==================//
export const SupervisorDetailActions = Object.freeze({
    // supervisor detail page
    SUPERVISOR_DETAIL_SUCCESS: Symbol("SUPERVISOR_DETAIL_SUCCESS"),

    SUPERVISOR_DETAIL_UPDATE: Symbol("SUPERVISOR_DETAIL_UPDATE"),
    SUPERVISOR_DETAIL_ADD: Symbol("SUPERVISOR_DETAIL_ADD"),
    SUPERVISOR_DETAIL_REMOVE: Symbol("SUPERVISOR_DETAIL_REMOVE"),
});


//================== Action Creators ==================//
export const supervisorDetailTeams = () => {
    return (dispatch, getState) => {
        const { supervisor: { workingEntity : supervisor } } = getState();
        if (!supervisor) return;

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/SupervisorTeam/GetEntitiesBySupervisor`;

        axios.post(apiEndpoint, supervisor, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch( { type: SupervisorDetailActions.SUPERVISOR_DETAIL_SUCCESS, payload: response.data, });
                }
            })
            .catch(error => {
                dispatch(showErrorResponse(error));
            })
    };
}

export const supervisorDetailAdd = () => {
    return (dispatch, getState) => {
        const { supervisor: { workingEntity: detailSupervisor } } = getState();

        dispatch({
            type: SupervisorDetailActions.SUPERVISOR_DETAIL_ADD,
            payload: detailSupervisor,
        });
    };
}

export const supervisorDetailRemove = (index) => {
    return (dispatch, getState) => {
        dispatch({
            type: SupervisorDetailActions.SUPERVISOR_DETAIL_REMOVE,
            payload: index,
        });

        _updateSupervisor(dispatch, getState);
    };
}

export const supervisorDetailUpdate = (index, field, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: SupervisorDetailActions.SUPERVISOR_DETAIL_UPDATE,
            payload: { index, field, value }
        });

        _updateSupervisor(dispatch, getState);
    };
}

const _updateSupervisor = (dispatch, getState) => {
    const { supervisorTeam: { supervisorDetailEntities } } = getState();

    if (supervisorDetailEntities.length < 1) {
        dispatch(supervisorUpdate('Fdate', null));
        dispatch(supervisorUpdate('Tdate', null));
        return;
    }

    // 要 t.IdBranch 有資料
    const minTeam = _.minBy(supervisorDetailEntities, function (t) { return !t.IdBranch ? new Date() : t.getFdate(); });
    const maxTeam = _.maxBy(supervisorDetailEntities, function (t) { return !t.IdBranch ? new Date(1900, 1, 1) : !_.isNil(t.getTdate()) ? t.getTdate() : new Date(); });

    const { Fdate } = minTeam; // 無資料當係今日，搵 min
    const { Tdate } = maxTeam; // 無資料當係 1900 年，但有可能係未完結, 如果 Tdate = null 當係今日，搵 max 出嚟
    //console.log(Tdate);

    dispatch(supervisorUpdate('Fdate', Fdate ? Fdate : null));
    dispatch(supervisorUpdate('Tdate', Tdate ? Tdate : null));
}

export const saveEntitiesInSupervisorDetail = (response, getState) => {
    const { Id: supervisorId } = response.data;
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/SupervisorTeam/SaveEntitiesInSupervisorDetail?supervisorId=${supervisorId}`;

    const { supervisor: { workingEntity }, supervisorTeam: { supervisorDetailEntities } } = getState();

    _.forEach(supervisorDetailEntities, function (h) { h.IdSupervisor = workingEntity.Id; });

    return axios.post(apiEndpoint, supervisorDetailEntities, {
        timeout: 30000,
    });
}

export const deleteEntitiesInSupervisorDetail = (response, getState) => {
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/SupervisorTeam/DeleteEntitiesInSupervisorDetail`;

    const { supervisorTeam: { supervisorDetailEntities } } = getState();

    return axios.post(apiEndpoint, supervisorDetailEntities, {
        timeout: 30000,
    });
}


//================== Reducer ==================//
const supervisorReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);

    switch (action.type) {
        case SupervisorDetailActions.SUPERVISOR_DETAIL_REQUEST:
            _state.isLoading = true;
            return _state;

        case SupervisorDetailActions.SUPERVISOR_DETAIL_SUCCESS:
            _state.isLoading = false;
            _state.supervisorDetailEntities = action.payload.map(h => new SupervisorTeam(h));
            return _state;

        case SupervisorDetailActions.SUPERVISOR_DETAIL_FAILURE:
            _state.isLoading = false;
            _state.errors = action.payload;
            _state.supervisorDetailEntities = [];
            return _state;

        case SupervisorDetailActions.SUPERVISOR_DETAIL_ADD:
            const { Id: IdSupervisor, IdBranch, IdRank, IdTeam, Rank, Branch, Team } = action.payload;
            let h = new SupervisorTeam({ IdSupervisor, IdBranch, IdRank, IdTeam, Rank, Branch, Team });
            _state.supervisorDetailEntities.push(h);
            return _state;

        case SupervisorDetailActions.SUPERVISOR_DETAIL_REMOVE:
            const index = action.payload;
            _.pullAt(_state.supervisorDetailEntities, index)
            return _state;

        case SupervisorDetailActions.SUPERVISOR_DETAIL_UPDATE:
            const { index: detailUpdateIndex, field: detailUpdateField, value:detailUpdateValue } = action.payload;
            _state.supervisorDetailEntities[detailUpdateIndex][detailUpdateField] = detailUpdateValue;
            return _state;

        default:
            return state;
    }
}

export default supervisorReducer;

