import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { branchDetail, branchDetailUpdate } from '../../redux/branchReducer';

import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import BeatLoader from "react-spinners/BeatLoader";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReactMoment from "react-moment";

import BranchDetailBottomButtons from './BranchDetailBottomButtons';
import BranchDetailHistories from './BranchDetailHistories';

import { DfnDistrictManagerSelection, SupervisorSelection } from '../Selections';

const useStyles = makeStyles({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },

    },
    detail: {
        "& table": {
            width: "100%",
            backgroundColor: "white",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
            },

            "& th": {
                backgroundColor: "#FAF3DE",
                textAlign: 'right',
            },
        },
    },
});

export default function BranchDetail() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { isLoading, workingEntity } = useSelector(state => {
        return state.branch;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    const { id } = useParams();
    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");

        dispatch(branchDetail(id));
    }, [id]);

    const navClicked = (path) => {
        history.push(path);
    };

    return (
        <>
            <Breadcrumbs className={classes.title}>
                <NavLink className="nav-link" tag={Link} to="/setting">
                    <Typography variant="h1" className={classes.title}>10. 設定</Typography>
                </NavLink>
                <NavLink className="nav-link" tag={Link} to="/branch/list">
                    <Typography variant="h1" className={classes.title}>分行</Typography>
                </NavLink>
                <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : workingEntity !== null ? workingEntity.getDesc() : ""}</Typography>
            </Breadcrumbs>
            { workingEntity &&
                <div className={classes.detail}>
                    <Grid container>
                        <Button variant="contained" className="mr-4" size="small" disableElevation onClick={() => { navClicked("/branch"); }}>返回列表</Button>
                        <div style={{ flex: 1 }} />
                        <Button variant="contained" size="small" disableElevation onClick={() => { dispatch(branchDetail(id, true)); }}><RefreshIcon /></Button>
                    </Grid>

                    <table className="mt-2" style={{ width: 'auto' }}>
                        <tbody>
                            <tr><th style={{ width: '120px' }}>分行</th><td>{workingEntity.Branch1}</td></tr>
                            <tr><th>開始日期</th><td>{workingEntity.Fdate ? <ReactMoment format="YYYY-MM-DD" date={workingEntity.Fdate} /> : "---"}</td></tr>
                            <tr><th>結束日期</th><td>{workingEntity.Tdate ? <ReactMoment format="YYYY-MM-DD" date={workingEntity.Tdate} /> : "--- 應用中 ---"}</td></tr>

                            <tr><th>排序</th><td><input value={workingEntity.Seq ? workingEntity.Seq : ''} style={{ width: '40px' }} tabIndex={1} onChange={(e) => { dispatch(branchDetailUpdate('Seq', e.target.value)); }} /></td></tr>
                            <tr><th>分區</th><td><DfnDistrictManagerSelection showAny={false} value={workingEntity.IdDistrictManager} onChange={(e) => { dispatch(branchDetailUpdate('IdDistrictManager', e.target.value)); }} tabIndex={2} /></td></tr>
                            <tr><th>經理</th><td>
                                <SupervisorSelection showAny={false} value={workingEntity.IdEmployee} onChange={(e) => { dispatch(branchDetailUpdate('IdEmployee', e.target.value)); }} tabIndex={3} />
                                <span style={{ color: 'red' }}>(用於 7.13 分行排名報告, 一般是按日期找尋主管)</span>
                            </td ></tr >
                        </tbody >
                    </table >

                </div>
            }

            <div style={{height: '10px'}}></div>

            <BranchDetailHistories />

            <BranchDetailBottomButtons />
        </>
    );
}
