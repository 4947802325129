import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { solicitorsRefDetail, solicitorsRefDetailUpdate } from '../../redux/solicitorsRefReducer';

import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import BeatLoader from "react-spinners/BeatLoader";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';

import SolicitorsRefDetailBottomButtons from './SolicitorsRefDetailBottomButtons';

const useStyles = makeStyles({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },

    },
    detail: {
        "& table": {
            width: "100%",
            backgroundColor: "white",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
            },

            "& th": {
                backgroundColor: "#FAF3DE",
                textAlign: 'right',
            },
        },
    },
});

export default function SolicitorsRefDetail() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { isLoading, workingEntity } = useSelector(state => {
        return state.solicitorsRef;
    });

    const { id, idDuplicate } = useParams();
    dispatch(solicitorsRefDetail(id, idDuplicate));

    const navClicked = (path) => {
        history.push(path);
    };

    return (
        <>
            <Breadcrumbs className={classes.title}>
                <NavLink className="nav-link" tag={Link} to="/setting">
                    <Typography variant="h1" className={classes.title}>10. 設定</Typography>
                </NavLink>
                <NavLink className="nav-link" tag={Link} to="/solicitorsRef/list">
                    <Typography variant="h1" className={classes.title}>參考律師樓</Typography>
                </NavLink>
                <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : workingEntity !== null ? workingEntity.getDesc() : ""}</Typography>
            </Breadcrumbs>
            { workingEntity &&
                <div className={classes.detail}>
                    <Grid container>
                        <Button variant="contained" className="mr-4" size="small" disableElevation onClick={() => { navClicked("/solicitorsRef"); }}>返回列表</Button>
                        <div style={{ flex: 1 }} />
                    <Button variant="contained" size="small" disableElevation onClick={() => { dispatch(solicitorsRefDetail(id, idDuplicate, true)); }}><RefreshIcon /></Button>
                    </Grid>

                <table className="mt-2" style={{ width: 'auto' }}>
                    <tbody>
                        <tr>
                            <th style={{ width: '100px' }}>律師樓(中)</th><td colSpan={3}><SolicitorsRefDetailInputField fieldName="SolicitorsChi" width={400} tabIndex={1} /></td>
                        </tr>
                        <tr>
                            <th>律師樓(英)</th><td colSpan={3}><SolicitorsRefDetailInputField fieldName="SolicitorsEng" width={400} tabIndex={2} /></td>
                        </tr>
                        <tr>
                            <th>律師(中)</th><td colSpan={3}><SolicitorsRefDetailInputField fieldName="LawyerChi" width={400} tabIndex={3} /></td>
                        </tr>
                        <tr>
                            <th>律師(英)</th><td colSpan={3}><SolicitorsRefDetailInputField fieldName="LawyerEng" width={400} tabIndex={4} /></td>
                        </tr>
                        <tr>
                            <th style={{ width: '100px' }}>律師 Tel</th><td><SolicitorsRefDetailInputField fieldName="LawyerTel" width={200} tabIndex={5} /></td>
                            <th style={{ width: '100px' }}>律師 Fax</th><td><SolicitorsRefDetailInputField fieldName="LawyerFax" width={200} tabIndex={6} /></td>
                        </tr>
                        <tr>
                            <th>備註(律師)</th><td colSpan={3}>
                                <textarea value={workingEntity?.RemarksLawyer ?? ""} style={{ width: '400px' }} tabIndex={7} onChange={(e) => { dispatch(solicitorsRefDetailUpdate("RemarksLawyer", e.target.value)); }} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            }

            <div style={{ height: "10px" }}></div>

            <SolicitorsRefDetailBottomButtons />
        </>
    );
}


function SolicitorsRefDetailInputField({ fieldName, width, tabIndex, textAlign }) {
    const dispatch = useDispatch();
    const { workingEntity } = useSelector(state => {
        return state.solicitorsRef;
    });

    return <input value={workingEntity[fieldName] ? workingEntity[fieldName] : ''} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(solicitorsRefDetailUpdate(fieldName, e.target.value)); }} />
}