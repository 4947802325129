import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Moment from 'moment';

import ReportReceipt from '../models/reportReceipt';
import Comm from '../models/comm';

import { showLoading, showInfo, showErrorResponse, clearMsg } from './msgReducer';

//================== Whole State ==================//
const initialState = {
    isInited: false,
    isLoading: false,
    commTranIds: [],
    refNos: [],
    parties: [],
    entities: [new ReportReceipt({})],
    errors: '',
};


//================== Action Types ==================//
export const ReportReceiptActions = Object.freeze({
    // commTranId is provided
    SET_COMMTRANS: Symbol("SET_COMMTRANS"),

    // refno is provided
    SET_REFNOS: Symbol("SET_REFNOS"),

    // listing
    REQUEST: Symbol("REQUEST"), 
    SUCCESS: Symbol("SUCCESS"), 
    FAILURE: Symbol("FAILURE"),

    FETCH_REQUEST: Symbol("FETCH_REQUEST"),
    FETCH_COMM: Symbol("FETCH_COMM"),

    UPDATE: Symbol("UPDATE"),
    SET_EMPLOYEE: Symbol("SET_EMPLOYEE"),
});


//================== Action Creators ==================//
export const setCommTrans = (ids, refNos, parties, nths) => {
    return {
        type: ReportReceiptActions.SET_COMMTRANS,
        payload: { ids, refNos, parties, nths }
    }
}

export const setRefNoPartyNths = (refNos, parties, nths) => {
    return {
        type: ReportReceiptActions.SET_REFNOS,
        payload: { refNos, parties, nths }
    }
}

const _reportReceiptRequest = () => {
    return {
        type: ReportReceiptActions.REQUEST
    }
}

const _reportReceiptFailure = (response) => {
    return {
        type: ReportReceiptActions.FAILURE,
        payload: response,
    }
}

export const reportReceiptShowForComms = () => {
    return (dispatch, getState) => {
        const { reportReceipt: { ids, refNos, parties } } = getState(); // state updated

        if (_.isEmpty(refNos)) { // 模擬 return from axios
            return {
                type: ReportReceiptActions.SUCCESS,
                payload: [new ReportReceipt({})],
            };
        }

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/ReportReceipt/GetEntities`;

        dispatch(_reportReceiptRequest());

        axios.post(apiEndpoint, { ids, refNos, parties }, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    return dispatch({
                        type: ReportReceiptActions.SUCCESS,
                        payload: response.data.map((e, i) => new ReportReceipt({ ...e, Party: parties[i] })),
                    });
                } else {
                    dispatch(_reportReceiptFailure("未能讀取"));
                }
            })
            .catch(error => {
                dispatch(_reportReceiptFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

export const reportReceiptUpdate = (index, field, value) => {
    return (dispatch, getState) => {

        const { selections: { employees } } = getState();

        dispatch({
            type: ReportReceiptActions.UPDATE,
            payload: { index, field, value, employees },
        })
    };
}

export const fetchComm = (index, entity, nth) => {
    return (dispatch, getState) => {
        const { reportReceipt: { entities } } = getState(); // state updated

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/ReportReceipt/FetchComm`;

        const refNo = entity[`RefNo${nth}`];

        dispatch({
            type: ReportReceiptActions.FETCH_REQUEST,
            payload: { index, nth }
        });

        axios.post(apiEndpoint, { RefNo: refNo }, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch({
                        type: ReportReceiptActions.FETCH_COMM,
                        payload: { index, party: entities[index].Party, nth, comm: new Comm(response.data) }
                    });
                } else {
                    dispatch(_reportReceiptFailure("未能讀取"));
                }
            })
            .catch(error => {
                dispatch(_reportReceiptFailure("未能讀取"));
                dispatch(showErrorResponse(error));
            })
    };
}

export const reportReceiptSetEmployee = (nthReportReceipt, employee) => {
    return {
        type: ReportReceiptActions.SET_EMPLOYEE,
        payload: { nthReportReceipt, employee},
    }

}

//================== Reducer ==================//
const reportReceiptReducer = (state = initialState, action) => {
    switch (action.type) {
        case ReportReceiptActions.SET_REFNOS:
            const { refNos, parties, nths } = action.payload;
            return {
                ...state,
                id: [],
                refNos,
                parties,
                nths
            };

        case ReportReceiptActions.SET_COMMTRANS:
            const { ids: commTranIds, refNos: commTranRefNos, parties: commTranParties, nths: commTranNths } = action.payload;
            return {
                ...state,
                ids: commTranIds,
                refNos: commTranRefNos,
                parties: commTranParties,
                nths: commTranNths,
            };

        case ReportReceiptActions.REQUEST:
            return {
                ...state,
                isLoading:true,
            };

        case ReportReceiptActions.SUCCESS:
            return {
                ...state,
                isLoading: false,
                refNos:[],
                parties:[],
                entities: action.payload,
                errors:'',
            };

        case ReportReceiptActions.FAILURE:
            return {
                ...state,
                isLoading:false,
                errors:action.payload,
                entities:[],
            };

        case ReportReceiptActions.FETCH_REQUEST:
            const { index: fetchReqIndex, nth: fetchReqNth } = action.payload;

            const fetchReqEntity = new ReportReceipt({ ...state.entities[fetchReqIndex] });

            fetchReqEntity.Name = '...';
            fetchReqEntity.Addr = '...'
            fetchReqEntity[`Desc${fetchReqNth}`] = '...';
            fetchReqEntity[`Bal${fetchReqNth}`] = '...';
            fetchReqEntity.DateDue = null;
            fetchReqEntity.IdEmployee = "";
            fetchReqEntity.EmployeeName = "";

            state.entities[fetchReqIndex] = fetchReqEntity;

            return { ...state };

        case ReportReceiptActions.FETCH_COMM:
            const { index: fetchCommIndex, party: fetchParty, nth: fetchNth, comm: fetchComm } = action.payload;

            const fetchCommEntity = new ReportReceipt({ ...state.entities[fetchCommIndex] });

            switch (fetchParty) {
                case "OWNER":
                    fetchCommEntity.Name = fetchComm.OwnerEng ? fetchComm.OwnerEng : fetchComm.OwnerChi;
                    fetchCommEntity.Addr = (fetchComm.OwnerMailingAddr ?? "") + (fetchComm.OwnerMailingAddr2 ? "\n" + fetchComm.OwnerMailingAddr2 : "");
                    fetchCommEntity[`Bal${fetchNth}`] = fetchComm.CommOwner;
                    break;
                case "BUYER":
                    fetchCommEntity.Name = fetchComm.BuyerEng ? fetchComm.BuyerEng : fetchComm.BuyerChi;
                    fetchCommEntity.Addr = (fetchComm.BuyerMailingAddr ?? "") + (fetchComm.BuyerMailingAddr2 ? "\n" + fetchComm.BuyerMailingAddr2 : "");
                    fetchCommEntity[`Bal${fetchNth}`] = fetchComm.CommBuyer;
                    break;
                default:
                    fetchCommEntity.Name = "未指定";
                    fetchCommEntity.Addr = "";
                    fetchCommEntity[`Bal${fetchNth}`] = null;
                    break;
            }

            fetchCommEntity[`Desc${fetchNth}`] = fetchComm.LongAddr;
            fetchCommEntity.DateDue = fetchComm.DateDue;
            fetchCommEntity.IdEmployee = fetchComm.Relevant1stId;
            fetchCommEntity.EmployeeName = fetchComm.Relevant1stName;

            _updateBalTotal(fetchCommEntity);
            state.entities[fetchCommIndex] = fetchCommEntity;
            console.log(fetchCommEntity);
            return { ...state };

        case ReportReceiptActions.UPDATE:
            const { index: updateIndex, field: updateField, value: updateValue, employees } = action.payload;

            const entity = new ReportReceipt({ ...state.entities[updateIndex], [updateField]: updateValue });

            switch (updateField) {
                case "Bal1":
                case "Bal2":
                case "Bal3":
                case "Bal4":
                    _updateBalTotal(entity);
                    break;
                case "IdEmployee":
                    entity.EmployeeName = _.find(employees, function (employee) { return employee.Id == updateValue })?.Name ?? "";
                    break;
            }

            state.entities[updateIndex] = entity;

            return { ...state };

        case ReportReceiptActions.SET_EMPLOYEE:
            const { nthReportReceipt, employee } = action.payload;
            const entitySetEmployee = new ReportReceipt({ ...state.entities[nthReportReceipt], IdEmployee: employee.Id, EmployeeName: employee.Name });

            state.entities[nthReportReceipt] = entitySetEmployee;

            return { ...state };

        default:
            return state;
    }
}

function _updateBalTotal(entity) {
    const balTotal = parseFloat(entity.Bal1 ?? "0")
        + parseFloat(entity.Bal2 ?? "0")
        + parseFloat(entity.Bal3 ?? "0")
        + parseFloat(entity.Bal4 ?? "0")

    entity.BalTotal = balTotal ? balTotal.toFixed(2) : '';
}

export default reportReceiptReducer;

