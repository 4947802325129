export default class CommBonusShare {
    constructor({
        Id,
        IdComm,
        RefNo,
        DateDeposit,
        IdCommTran,
        DateTran,
        AmountTran,
        IdCommShare,
        PercentCommShare,
        IdEmployee,
        Employee,
        Seq,
        SharedRole,
        SharedIdEmployee,
        SharedEmployee,
        Percent,
        Amount,
        EditDate,
        EditTime,
        EditUser,
        Bldg,
        Block,
        Floor,
        Flat,
    }) {
        this.Id = Id;
        this.IdComm = IdComm;
        this.RefNo = RefNo;
        this.DateDeposit = DateDeposit;
        this.IdCommTran = IdCommTran;
        this.DateTran = DateTran;
        this.AmountTran = AmountTran;
        this.IdCommShare = IdCommShare;
        this.PercentCommShare = PercentCommShare;
        this.IdEmployee = IdEmployee;
        this.Employee = Employee;
        this.Seq = Seq;
        this.SharedRole = SharedRole;
        this.SharedIdEmployee = SharedIdEmployee;
        this.SharedEmployee = SharedEmployee;
        this.Percent = Percent;
        this.Amount = Amount;
        this.EditDate = EditDate;
        this.EditTime = EditTime;
        this.EditUser = EditUser;
        this.Bldg = Bldg;
        this.Block = Block;
        this.Floor = Floor;
        this.Flat = Flat;
    }

    getDesc() {
        if (this.Id.toString().toUpperCase() == "NEW" || this.Id < 1) return "新獎金分成";

        return `#${this.Id}`;
    }
}
