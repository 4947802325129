import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& a.nav-link:hover h1": {
            color: "#006ed0",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },
    },
    detail: {
        "& table": {
            width: "100%",
            backgroundColor: "white",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
            },

            "& th": {
                backgroundColor: "#FAF3DE",
                textAlign: 'right',
            },
        },
    },
    backdrop: {
        backgroundColor: 'rgb(0, 0, 0, 0.2)',
    },
    paper: {
        margin: '40px auto',
        width: 320,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: "auto",
    },
}));

export default function EmployeeVacationDetail() {
    return <h1>EmployeeVacationDetail</h1>;

}
