//================== Whole State ==================//
const initialState = {
    isShow: false,
    type: '',
    msg: '',
    fnConfirmed: null,
};


//================== Action Types ==================//
export const MsgActions = Object.freeze({
    LOADING: Symbol("LOADING"),
    INFO: Symbol("INFO"),
    WARNING: Symbol("WARNING"),
    ERROR: Symbol("ERROR"),
    ERROR_RESPONSE: Symbol("ERROR_RESPONSE"),
    CONFIRM: Symbol("CONFIRM"),
    CLEAR: Symbol("CLEAR"),
});


//================== Action Creators ==================//
export const showLoading = () => {
    return {
        type: MsgActions.LOADING,
    };
}

export const showInfo = (msg) => {
    return {
        type: MsgActions.INFO,
        payload: msg
    };
}

export const showError = (msg) => {
    return {
        type: MsgActions.ERROR,
        payload: msg,
    }
}

export const showErrorResponse = (error) => {
    return {
        type: MsgActions.ERROR_RESPONSE,
        payload: error,
    }
}

export const showWarning = (msg) => {
    return {
        type: MsgActions.WARNING,
        payload: msg
    }
}

export const showConfirm = (msg, fnConfirmed) => {
    return {
        type: MsgActions.CONFIRM,
        payload: { msg, fnConfirmed },
    }
}

export const clearMsg = () => {
    return {
        type: MsgActions.CLEAR,
    };
}

//================== Reducer ==================//
const msgReducer = (state = initialState, action) => {
    switch (action.type) {
        case MsgActions.LOADING:
            return {
                ...initialState,
                isShow: true,
                type: 'LOADING',
            };

        case MsgActions.INFO:
            return {
                ...initialState,
                isShow: true,
                type: 'INFO',
                msg: action.payload,
            };

        case MsgActions.WARNING:
            return {
                ...initialState,
                isShow: true,
                type: 'WARNING',
                msg: action.payload,
            };

        case MsgActions.ERROR:
            return {
                ...initialState,
                isShow: true,
                type: 'ERROR',
                msg: action.payload,
            };

        case MsgActions.ERROR_RESPONSE:
            const error = action.payload;
            let errorMsg = "";
            if (error.response && error.response.data && error.response.data.title) errorMsg = error.response.data.title;
            else if (error.response && error.response.data) errorMsg = error.response.data;
            else if (error.response && error.response.status) errorMsg = `未能聯繫伺服器 (${error.response.status})`;
            else errorMsg = '未能聯繫伺服器';
            return {
                ...initialState,
                isShow: true,
                type: 'ERROR',
                msg: errorMsg,
            };

        case MsgActions.CONFIRM:
            const { msg, fnConfirmed } = action.payload;
            return {
                ...initialState,
                isShow: true,
                type: 'CONFIRM',
                msg: msg,
                fnConfirmed: fnConfirmed,
            };

        case MsgActions.CLEAR:
            return {
                ...state,
                isShow: false,
            };

        default:
            return state;
    }
}

export default msgReducer;
