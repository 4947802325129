import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import BeatLoader from "react-spinners/BeatLoader";

import MemoFrmReceipt from './MemoFrmReceipt';
import { reportReceiptShowForComms, reportReceiptSetEmployee } from '../../../redux/reportReceiptReducer';

import { openPdf } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";

const useStyles = makeStyles((theme) => ({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
  backdrop: {
    backgroundColor: 'rgb(0, 0, 0, 0.2)',
  },
  paper: {
    margin: '40px auto',
    width: 320,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
  },
}));

export default function ReportReceipt() {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reportReceiptShowForComms());
  }, [])

  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

  const { entities, isLoading } = useSelector(state => {
    return state.reportReceipt;
  });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  const print = (index) => {
    const _entities = _.isNil(index) ? entities : [entities[index]];
    //console.log("ReportReceipt", _entities);

    openPdf("/api/ReportReceipt/GetPDF", _entities, msgState, msgSetState);
  }

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/report">
          <Typography variant="h1" className={classes.title}>7. 報告</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : "7.9.1. 正式收據"}</Typography>
      </Breadcrumbs>

      <Button className="mb-3" size="small" disableElevation variant="contained" onClick={(_) => { print(); }}>列印全部</Button>
      {entities.map((entity, index) => <div key={index}>
        <MemoFrmReceipt entity={entity} index={index} print={print} />
        <Button className="mb-3" size="small" disableElevation variant="contained" onClick={(_) => { print(); }}>列印全部</Button>
      </div>)}

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
    </>
  );
}
