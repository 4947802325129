import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Moment from 'moment';
import _ from 'lodash';

import { openPdf } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";

import Button from '@material-ui/core/Button';

import MyDateField from '../../MyDateField';
import { BranchSelection, EmployeeSelection } from '../../Selections';

const useStyles = makeStyles({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& a.nav-link:hover h1": {
            color: "#006ed0",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },
    },
    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
            textAlign: "center",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },

        "& label": {
            marginBottom: "0px",
        }
    },
});

export default function ReportCommReduced() {
    const classes = useStyles();

    const history = useHistory();

    const [state, setState] = useState({
        fDate: Moment.utc().add(-1, 'M').format("YYYY-MM-01"),
        tDate: Moment.utc().add(-1, 'M').endOf('month').format("YYYY-MM-DD"),
        refNo: "",
        solicitors: "",
        idBranch: "",
        idEmployee: "",
        isHideEmpty: "__YES__",
    });

    const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

    const setIdBranches = (e) => {
        setState({
            ...state,
            idBranches: Array.from(e.target.selectedOptions, option => option.value)
        });
    }

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");
    }, [Creds, history]);

    const dispatch = useDispatch();

    const print = () => {
        let filter = _.keys(state).map(key => ({
            property: key,
            value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
        }));
        //console.log(filter);

        openPdf("/api/ReportSolicitors/GetPDF", {
            filter: JSON.stringify(filter)
        }, msgState, msgSetState);
    }

    return (
        <>
            <Breadcrumbs className={classes.title}>
                <NavLink className="nav-link" tag={Link} to="/report">
                    <Typography variant="h1" className={classes.title}>7. 報告</Typography>
                </NavLink>
                <Typography variant="h1" className={classes.title}>7.14. 律師樓報告</Typography>
            </Breadcrumbs>

            <div style={{ width: '650px' }}>
                <table className={classes.frmFilter}>
                    <tbody>
                        <tr>
                            <th>落訂日期</th>
                            <td colSpan="3">
                                <MyDateField dateValue={state.fDate} onDateChange={(d) => { setState({ ...state, fDate: d?.format("YYYY-MM-DD") }); }} tabIndex={1} />
                                &nbsp;至&nbsp;
                                <MyDateField dateValue={state.tDate} onDateChange={(d) => { setState({ ...state, tDate: d?.format("YYYY-MM-DD") }); }} tabIndex={2} />
                            </td>
                        </tr>
                        <tr>
                            <th>物業編號</th><td><input value={state.refNo} onChange={(e) => { setState({...state, refNo:e.target.value });}} style={{ width: '100px' }} /></td>
                            <th>律師行</th><td><input value={state.solicitors} onChange={(e) => { setState({ ...state, solicitors: e.target.value }); }} style={{ width: '300px' }} /></td>
                        </tr>
                        <tr>
                            <th>分　　行</th>
                            <td colSpan="3">
                                <BranchSelection showAny={true} value={state.idBranch} onChange={(e) => { setState({ ...state, idBranch: e.target.value }) }} tabIndex={1} />
                                &nbsp;
                                * 要列印沒有分行的員工 (如 Office 或經理) 可以下面員工製作報告
                            </td>
                        </tr>
                        <tr>
                            <th>員　　工</th>
                            <td colSpan="3">
                                <EmployeeSelection size={1} width="auto" showAny={true} value={state.idEmployee} onPicked={(employee) => { setState({ ...state, idEmployee: employee ? employee.Id: "" }) }} tabIndex={1} />
                            </td>
                        </tr>
                        <tr>
                            <th>隱藏空白</th>
                            <td colSpan="3">
                                <RptSolicitorsFilterRadioField state={state} setState={setState} fieldName="isHideEmpty" label="隱藏沒有律師行" value="__YES__" />
                                <RptSolicitorsFilterRadioField state={state} setState={setState} fieldName="isHideEmpty" label="列印全部" value="__NO__" />
                            </td>
                        </tr>
                    </tbody >
                </table >

                <div className="mt-2 mb-3" style={{ textAlign: "right" }}>
                    <Button size="small" variant="contained" disableElevation onClick={print}>列　　印</Button>
                </div>
            </div >

            <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
        </>
    );
}

function RptSolicitorsFilterRadioField({ state, setState, fieldName, label, value }) {
    const name = `filter_${fieldName}`;
    const id = `${name}_${value}`;
    return <span className="mr-1 ml-1">
        <input className="mr-1" type="radio" value={value} id={id} name={name} onChange={(e) => { setState({ ...state, [fieldName]: e.target.value, }); }} checked={state[fieldName] == value} />
        <label htmlFor={id}>{label}</label>
    </span>;
}
