import Moment from 'moment';

export default class EmployeeVacation {
    constructor({
        Id,
        CalMonth,
        IdBranch,
        Branch,
        IdEmployee,
        Name,
        QtyVacation,
    }) {
        this.Id = Id;
        this.CalMonth = CalMonth;
        this.IdBranch = IdBranch;
        this.Branch = Branch;
        this.IdEmployee = IdEmployee;
        this.Name = Name;
        this.QtyVacation = QtyVacation;
    }

    isNew() {
        return (this.Id == undefined) || (this.Id < 1) || (this.Id.toString().toUpperCase() == "NEW");
    }

    getDesc() {
        if (this.isNew()) return "新放假日數";

        let strCalMonth = '';

        if (this.CalMonth) {
            Moment.locale('en');
            strCalMonth = Moment(this.CalMonth).format('YYYY 年 MM 月');
        }

        return `${this.Name} ${strCalMonth}`;
    }
}
