import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { teamDetail, teamDetailUpdate } from '../../redux/teamReducer';

import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import BeatLoader from "react-spinners/BeatLoader";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';

import TeamDetailBottomButtons from './TeamDetailBottomButtons';
import TeamDetailTableRates from '../tableRate/TeamDetailTableRates';
import { DfnEmpRoleSelection } from '../Selections';

const useStyles = makeStyles({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },

    },
    detail: {
        "& table": {
            width: "100%",
            backgroundColor: "white",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
            },

            "& th": {
                backgroundColor: "#FAF3DE",
                textAlign: 'right',
            },
        },
    },
});

export default function TeamDetail() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { isLoading, workingEntity } = useSelector(state => {
        return state.team;
    });

    const { dfnEmpRoles } = useSelector(state => {
        return state.selections;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    const { id } = useParams();
    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");

        dispatch(teamDetail(id));
    }, [id]);

    const navClicked = (path) => {
        history.push(path);
    };

    const handleRoleChange = (e) => {
        dispatch(teamDetailUpdate('Role', e.target.value));

        const dfnEmpRole = _.find(dfnEmpRoles, r => r.Role == e.target.value);
        dispatch(teamDetailUpdate('IdRole', dfnEmpRole?.Id));
    };

    return (
        <>
            <Breadcrumbs className={classes.title}>
                <NavLink className="nav-link" tag={Link} to="/setting">
                    <Typography variant="h1" className={classes.title}>10. 設定</Typography>
                </NavLink>
                <NavLink className="nav-link" tag={Link} to="/team/list">
                    <Typography variant="h1" className={classes.title}>組別</Typography>
                </NavLink>
                <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : workingEntity !== null ? workingEntity.getDesc() : ""}</Typography>
            </Breadcrumbs>
            {workingEntity &&
                <div className={classes.detail}>
                    <Grid container>
                        <Button variant="contained" className="mr-4" size="small" disableElevation onClick={() => { navClicked("/team"); }}>返回列表</Button>
                        <div style={{ flex: 1 }} />
                        <Button variant="contained" size="small" disableElevation onClick={() => { dispatch(teamDetail(id, true)); }}><RefreshIcon /></Button>
                    </Grid>

                    <table className="mt-2" style={{ width: 'auto' }}>
                        <tbody>
                            <tr><th style={{ width: '120px' }}>組　　別</th><td><input value={workingEntity.Team1 ? workingEntity.Team1 : ''} style={{ width: '200px' }} tabIndex={1} onChange={(e) => { dispatch(teamDetailUpdate('Team1', e.target.value)); }} /></td></tr>
                            <tr><th>身　　份</th><td><DfnEmpRoleSelection showAny={false} onChange={handleRoleChange} value={workingEntity.Role} tabIndex={2} /></td></tr>
                            <tr><th>排　　序</th><td><input value={workingEntity.Seq ? workingEntity.Seq : ''} style={{ width: '40px' }} tabIndex={3} onChange={(e) => { dispatch(teamDetailUpdate('Seq', e.target.value)); }} /></td></tr>
                            <tr><th>權　　限</th><td><input value={workingEntity.Lv ? workingEntity.Lv : ''} style={{ width: '40px' }} tabIndex={4} onChange={(e) => { dispatch(teamDetailUpdate('Lv', e.target.value)); }} /></td></tr>
                            <tr>
                                <th>有 效 的</th>
                                <td>
                                    <TeamIsActiveRadioField fieldName="IsActive" label="有效的" value={true} selectedValue={workingEntity.IsActive ?? false} />
                                    <TeamIsActiveRadioField fieldName="IsActive" label="已停用" value={false} selectedValue={workingEntity.IsActive ?? false} />
                                </td>
                            </tr>
                        </tbody >
                    </table >

                </div>
            }

            <div style={{ height: "10px" }}></div>

            <TeamDetailTableRates />

            <TeamDetailBottomButtons />
        </>
    );
}


function TeamIsActiveRadioField({ fieldName, label, value, selectedValue }) {
    const dispatch = useDispatch();
    const name = `filter_${fieldName}`;
    const id = `${name}_${value}`;
    return <span className="mr-2" >
        <input type="radio" value={value} id={id} name={name} onChange={e => { dispatch(teamDetailUpdate(fieldName, value)); }} checked={selectedValue == value} />
        <label htmlFor={id}>{label}</label>
    </span>;
}
