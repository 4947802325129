import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Moment from 'moment';

import { showLoading, showInfo, showErrorResponse, clearMsg } from './msgReducer';
import RptCommItem from '../models/rptCommItem';

//================== Whole State ==================//
let initDateReport = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
initDateReport.setMonth(initDateReport.getMonth() - 1);
const initMomentDateReport = new Moment(initDateReport);

const initialState = {
  isLoading: false,
  isFilterUpdated: false,
  filter: { // match EntitiesResult<T>
    allRecords: 0,
    matchedRecords: 0,
    totalRecords: 0,
    qtyPerPage: 100,
    page: 1,
    row: 0,
    filter: JSON.stringify([{ "property": "date_report", "value": initMomentDateReport.format("YYYY-MM-DD") }]),
    sort: JSON.stringify([{ "property": "id", "direction": "ASC" }]),
    success: true,
    message: '',
  },
  isConfirmed: false,
  entities: [],
  workingEntityId: -1,
  calMonth: '',
  workingEntities: [],
  errors: '',
  uploadXlsxIsOpen: false,
};


//================== Action Types ==================//
export const BankReferralActions = Object.freeze({
  // filter
  FILTER_UPDATE: Symbol("FILTER_UPDATE"),
  CHANGE_SORT: Symbol("CHANGE_SORT"),
  //PAGE_UPDATE: Symbol("PAGE_UPDATE"),
  //FETCH_PAGE: Symbol("FETCH_PAGE"), 

  // listing
  REQUEST: Symbol("REQUEST"),
  SUCCESS: Symbol("SUCCESS"),
  FAILURE: Symbol("FAILURE"),

  // detail page
  DETAIL_SWAP: Symbol("DETAIL_SWAP"),
  //DETAIL_ID: Symbol("DETAIL_ID"),
  DETAIL_SUCCESS: Symbol("DETAIL_SUCCESS"), // ajax loaded
  DETAIL_FAILURE: Symbol("DETAIL_FAILURE"),

  DETAIL_UPDATE: Symbol("DETAIL_UPDATE"), // form fields update
  DETAIL_DELETED: Symbol("DETAIL_DELETED"),
  DETAIL_MORE: Symbol("DETAIL_MORE"),

  SET_UPLOAD_XLSX: Symbol("SET_UPLOAD_XLSX"),
  UPLOAD_XLSX: Symbol("UPLOAD_XLSX"),
  UPLOAD_XLSX_SUCCESS: Symbol("UPLOAD_XLSX_SUCCESS"),

  TOGGLE_ISCONFIRMED: Symbol("TOGGLE_ISCONFIRMED"),
  ISCONFIRMED_SUCCESS: Symbol("ISCONFIRMED_SUCCESS"),
});


//================== Action Creators ==================//
export const bankReferralFilterUpdate = (field, value) => {
  return (dispatch, getState) => {
    switch (field) {
      case "date_report":
        dispatch({
          type: BankReferralActions.FILTER_UPDATE,
          payload: {
            field: field,
            value: value
          }
        });

        const { bankReferral: { filter } } = getState(); // state updated

        dispatch(bankReferralLookup(filter));
        dispatch(isConfirmedLookup(filter));
        return;
    }

    dispatch({
      type: BankReferralActions.FILTER_UPDATE,
      payload: {
        field: field,
        value: value
      }
    });
  };
}

export const bankReferralFilterClear = () => {
  return (dispatch) => {
    //dispatch(_setWorkingEntityId(initialState.workingEntityId));
    dispatch(bankReferralLookup(initialState.filter));
  }
}

export const bankReferralChangeSort = (field) => {
  return (dispatch, getState) => {
    dispatch({
      type: BankReferralActions.CHANGE_SORT,
      payload: field,
    });
    const { bankReferral: { filter } } = getState(); // state updated
    //console.log(filter);
    dispatch(bankReferralLookup(filter));
  };
}

export const bankReferralSwap = (index) => {
  return {
    type: BankReferralActions.DETAIL_SWAP,
    payload: index,
  }
}

export const bankReferralDelete = (index) => {
  return {
    type: BankReferralActions.DETAIL_DELETED,
    payload: index,
  }
}

export const bankReferralMore = () => {
  return {
    type: BankReferralActions.DETAIL_MORE,
  }
}

export const bankReferralPageUpdate = (page) => {
  return (dispatch, getState) => {
    const { bankReferral: { filter } } = getState(); // state updated

    let _page = 1;

    if (page == "") {
      _page = "";
    } else {
      _page = parseInt(page);
      if (_.isNaN(_page)) return;
      if (_page < 1) _page = 1;
      if (_page > Math.ceil(filter.matchedRecords / filter.qtyPerPage)) _page = Math.ceil(filter.matchedRecords / filter.qtyPerPage);
    }

    dispatch({
      type: BankReferralActions.FETCH_PAGE,
      payload: _page,
    });
  };
}

export const bankReferralFetchPage = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: BankReferralActions.FETCH_PAGE,
      payload: page,
    });
    const { bankReferral: { filter } } = getState(); // state updated
    //console.log(filter);
    dispatch(bankReferralLookup(filter));
  };
}

const _bankReferralRequest = () => {
  return {
    type: BankReferralActions.REQUEST
  }
}

const _bankReferralSuccess = (data) => {
  return {
    type: BankReferralActions.SUCCESS,
    payload: data,
  }
}

const _bankReferralFailure = (response) => {
  return {
    type: BankReferralActions.FAILURE,
    payload: response,
  }
}

export const bankReferralLookup = (formData) => {
  const _formData = formData ? formData : initialState.filter;
  //console.log(_formData);
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/RptCommItem/GetEntities`;

  return (dispatch, getState) => {
    dispatch(_bankReferralRequest());

    const { bankReferral } = getState(); // state updated
    if (bankReferral.isFilterUpdated) _formData.page = 1; // reset page if filter changed
    if (bankReferral.filter.page == "") _formData.page = 1; // reset page if filter changed

    axios.post(apiEndpoint, _formData, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_bankReferralSuccess(response.data));
        } else {
          dispatch(_bankReferralFailure("未能讀取"));
        }
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          dispatch(_bankReferralFailure(error.response.data.errors));
        } else if (error.response && error.response.status) {
          dispatch(_bankReferralFailure(`未能讀取 (${error.response.status})`));
        } else {
          dispatch(_bankReferralFailure(`未能聯繫伺服器`));
        }
      })
  }
}

export const isConfirmedLookup = (formData) => {
  const _formData = formData ? formData : initialState.filter;
  //console.log(_formData);

  return async (dispatch, getState) => {
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/RptCommItem/GetIsConfirmed`;

    const dateReport = JSON.parse(_formData.filter)[0].value; // the first field is date_report;
    //console.log('isConfirmedLookup', date_report);

    try {
      var response = await axios.post(apiEndpoint, { dateReport }, { timeout: 30000 });
      dispatch({
        type: BankReferralActions.ISCONFIRMED_SUCCESS,
        payload: response.data,
      });
    }
    catch (err) {
    }
  };
}

export const toggleIsConfirmed = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/RptCommItem/ToggleIsConfirmed`;

  return async (dispatch, getState) => {
    const { bankReferral: { filter } } = getState();

    const dateReport = JSON.parse(filter.filter)[0].value; // the first field is date_report;
    console.log('isConfirmedLookup', dateReport);

    try {
      var response = await axios.post(apiEndpoint, { dateReport }, { timeout: 30000 });
      dispatch({
        type: BankReferralActions.TOGGLE_ISCONFIRMED,
        payload: response.data,
      });
    }
    catch (err) {
    }
  };
}

const _bankReferralDetailSuccess = (data) => {
  return {
    type: BankReferralActions.DETAIL_SUCCESS,
    payload: data,
  }
}

const _bankReferralDetailFailure = (response) => {
  return (dispatch, getState) => {
    dispatch({
      type: BankReferralActions.DETAIL_FAILURE,
      payload: response,
    });
  }
}

export const bankReferralDetailUpdate = (index, fieldName, value) => {
  return {
    type: BankReferralActions.DETAIL_UPDATE,
    payload: { index, fieldName, value },
  }
}

export const bankReferralSaveAll = (history) => {
  return (dispatch, getState) => {
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/RptCommItem/SaveEntities`;

    const { bankReferral: { entities, filter } } = getState();

    dispatch(showLoading());

    const saveFilter = JSON.parse(filter.filter);
    const dateReportFilter = _.find(saveFilter, { 'property': 'date_report' });
    const { value: dateReport } = dateReportFilter;

    axios.post(apiEndpoint, {
      dateReport,
      entities
    }, {
      timeout: 30000,
    })
      .then(response => {
        const { bankReferral: { filter } } = getState();
        dispatch(clearMsg());
        dispatch(bankReferralLookup(filter));
        dispatch(showInfo("已保存"));
      })
      .catch(error => {
        dispatch(_bankReferralDetailFailure("未能讀取"));
        dispatch(showErrorResponse(error));
      });
  };
}

//export const uploadXlsxHide = () => {
//    return {
//        type: BankReferralActions.SET_UPLOAD_XLSX,
//        payload: false,
//    }
//}

//export const uploadXlsxShow = () => {
//    return {
//        type: BankReferralActions.SET_UPLOAD_XLSX,
//        payload: true,
//    }
//}

export const uploadXlsx = (file, optExists) => {
  return (dispatch, getState) => {
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/RptCommItem/ImportExcel`;

    const { bankReferral: { filter } } = getState();

    //dispatch(showLoading());

    const saveFilter = JSON.parse(filter.filter);
    const dateReportFilter = _.find(saveFilter, { 'property': 'date_report' });
    const { value: dateReport } = dateReportFilter;

    // Create an object of formData
    const formData = new FormData();
    formData.append("dateReport", dateReport);
    formData.append(
      "file",
      file,
      file.name
    );

    axios.post(apiEndpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 30000,
    })
      .then(response => {
        dispatch({
          type: BankReferralActions.UPLOAD_XLSX_SUCCESS,
          payload: {
            uploadEntities: response.data,
            optExists: optExists,
          }
        });
        //const { bankReferral: { filter } } = getState();
        dispatch(clearMsg());
      })
      .catch(error => {
        dispatch(_bankReferralDetailFailure("未能讀取"));
        dispatch(showErrorResponse(error));
      });
  };
}

//================== Reducer ==================//
const bankReferralReducer = (state = initialState, action) => {
  let _state = cloneDeep(state);

  switch (action.type) {
    case BankReferralActions.FILTER_UPDATE:
      const { field: filterField, value } = action.payload;
      let filters = JSON.parse(state.filter.filter);
      const iFilter = _.findIndex(filters, function (o) { return o.property == filterField; });
      if (iFilter >= 0) {
        if (value) filters[iFilter].value = value;
        else filters.splice(iFilter, 1);
      } else {
        filters.push({ "property": filterField, "value": value });
      }

      _state.filter.filter = JSON.stringify(filters);
      _state.isFilterUpdated = true;
      //console.log('_state.filter', _state.filter.filter);
      return _state;

    case BankReferralActions.CHANGE_SORT:
      const field = action.payload;
      let sorts = JSON.parse(state.filter.sort);
      const i = _.findIndex(sorts, function (o) { return o.property == field; });
      if (i >= 0) {
        sorts[i].direction = sorts[i].direction == "ASC" ? "DESC" : "ASC";
      } else {
        // single sort only
        sorts = [{ "property": field, "direction": "ASC" }];
      }

      _state.filter.sort = JSON.stringify(sorts);
      //console.log('_state.filter.sort', _state.filter.sort);

      bankReferralLookup(_state.filter);
      return _state;

    case BankReferralActions.PAGE_UPDATE:
      _state.filter.page = action.payload;
      return _state;

    case BankReferralActions.FETCH_PAGE:
      _state.filter.page = action.payload;
      return _state;

    case BankReferralActions.REQUEST:
      _state.isLoading = true;
      return _state

    case BankReferralActions.SUCCESS:
      _state.isLoading = false;
      _state.filter = {
        allRecords: action.payload.allRecords,
        matchedRecords: action.payload.matchedRecords,
        totalRecords: action.payload.totalRecords,
        qtyPerPage: action.payload.qtyPerPage,
        page: action.payload.page,
        row: action.payload.row,
        filter: action.payload.filter,
        sort: action.payload.sort,
        success: action.payload.success,
        message: action.payload.message,
      };
      _state.entities = action.payload.entities;
      _state.errors = "";
      _state.isFilterUpdated = false;
      return _state;

    case BankReferralActions.FAILURE:
      _state.isLoading = false;
      _state.errors = action.payload;
      _state.entities = [];
      return _state;

    case BankReferralActions.DETAIL_SWAP:
      const swapIndex = action.payload;
      const workingEntity = _state.entities[swapIndex];

      const { EmpBankReferral, EmpOthers, EmpRemarks, SupBankReferral, SupOthers, SupRemarks } = workingEntity;
      workingEntity.EmpBankReferral = SupBankReferral;
      workingEntity.EmpOthers = SupOthers;
      workingEntity.EmpRemarks = SupRemarks;

      workingEntity.SupBankReferral = EmpBankReferral;
      workingEntity.SupOthers = EmpOthers;
      workingEntity.SupRemarks = EmpRemarks;

      _state.entities[swapIndex] = workingEntity;

      return _state;

    case BankReferralActions.DETAIL_ID:
      _state.workingEntityId = action.payload;
      return _state;

    case BankReferralActions.DETAILS_CALMONTH:
      _state.calMonth = action.payload;
      return _state;

    case BankReferralActions.DETAIL_SUCCESS:
      _state.isLoading = false;
      _state.workingEntities = action.payload.map(c => new RptCommItem(c));
      return _state;

    case BankReferralActions.DETAIL_FAILURE:
      _state.isLoading = false;
      return _state;

    case BankReferralActions.DETAIL_UPDATE:
      const { index: updateIndex, fieldName: updateFieldName, value: updateValue } = action.payload;
      _state.entities[updateIndex][updateFieldName] = updateValue;
      return _state;

    case BankReferralActions.DETAIL_DELETED:
      const deleteIndex = action.payload;
      _.pullAt(_state.entities, deleteIndex);
      return _state;

    case BankReferralActions.DETAIL_MORE:
      const moreFilters = JSON.parse(state.filter.filter);
      const dateReportFilter = _.find(moreFilters, { 'property': 'date_report' });
      const { value: dateReport } = dateReportFilter;
      _state.entities.push(new RptCommItem({ Id: 0, DateReport: dateReport }));
      return _state;

    case BankReferralActions.SET_UPLOAD_XLSX:
      _state.uploadXlsxIsOpen = action.payload;
      return _state;

    case BankReferralActions.UPLOAD_XLSX_SUCCESS:
      const { uploadEntities, optExists } = action.payload;
      switch (optExists) {
        case "OVERRIDE":
          // exists 拎走 upload
          const existing = _.differenceWith(state.entities, uploadEntities, function (item1, item2) { return item1.Id == item2.Id; });
          _state.entities = _.concat(existing, uploadEntities);
          break;
        case "SKIP":
          // upload 拎走 exists
          const uploading = _.differenceWith(uploadEntities, state.entities, function (item1, item2) { return item1.Id == item2.Id; });
          _state.entities = _.concat(state.entities, uploading);
          break;
      }

      //_state.entities = _.sortBy(_state.entities, ['Name']);
      return _state;

    case BankReferralActions.TOGGLE_ISCONFIRMED:
      _state.isConfirmed = !_state.isConfirmed;
      return _state;

    case BankReferralActions.ISCONFIRMED_SUCCESS:
      _state.isConfirmed = action.payload;
      return _state;

    default:
      return state;
  }
}

export default bankReferralReducer;

