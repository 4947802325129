import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';

import VCommTranBonus from '../models/vCommTranBonus';

import { showLoading, showInfo, showError, clearMsg } from './msgReducer';

//================== Whole State ==================//
const initialState = {
    isLoading: false,
    isFilterUpdated: false,
    filter: { // match EntitiesResult<T>
        allRecords: 0,
        matchedRecords: 0,
        totalRecords: 0,
        qtyPerPage: 100,
        page: 1,
        row: 0,
        filter: "[]",
        sort: JSON.stringify([{ "property": "date_tran", "direction": "DESC" }]),
        success: true,
        message: '',
    },
    entities: [],
    errors: '',
    workingEntityId: -1,
    workingEntity: null,
    workingEntityErrors: '',
};


//================== Action Types ==================//
export const VCommTranBonusActions = Object.freeze({
    // filter
    FILTER_UPDATE: Symbol("FILTER_UPDATE"),
    CHANGE_SORT: Symbol("CHANGE_SORT"),
    PAGE_UPDATE: Symbol("PAGE_UPDATE"),
    FETCH_PAGE: Symbol("FETCH_PAGE"),

    // listing
    REQUEST: Symbol("REQUEST"),
    SUCCESS: Symbol("SUCCESS"),
    FAILURE: Symbol("FAILURE"),

    // detail page
    DETAIL_ID: Symbol("DETAIL_ID"),
    DETAIL_SUCCESS: Symbol("DETAIL_SUCCESS"), // ajax loaded
    DETAIL_FAILURE: Symbol("DETAIL_FAILURE"),
    DETAIL_DELETED: Symbol("DETAIL_DELETED"), // ajax delete

    DETAIL_UPDATE: Symbol("DETAIL_UPDATE"), // form fields update
});


//================== Action Creators ==================//
export const vCommTranBonusFilterUpdate = (field, value) => {
    return {
        type: VCommTranBonusActions.FILTER_UPDATE,
        payload: {
            field: field,
            value: value
        }
    }
}

const _setWorkingEntityId = (id) => {
    return {
        type: VCommTranBonusActions.DETAIL_ID,
        payload: id,
    }
}

export const vCommTranBonusFilterClear = () => {
    return (dispatch) => {
        dispatch(_setWorkingEntityId(initialState.workingEntityId));
        dispatch(vCommTranBonusLookup(initialState.filter));
    }
}

export const vCommTranBonusChangeSort = (field) => {
    return (dispatch, getState) => {
        dispatch({
            type: VCommTranBonusActions.CHANGE_SORT,
            payload: field,
        });
        const { vCommTranBonus: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(vCommTranBonusLookup(filter));
    };
}

export const vCommTranBonusPageUpdate = (page) => {
    return (dispatch, getState) => {
        const { vCommTranBonus: { filter } } = getState(); // state updated

        let _page = 1;

        if (page == "") {
            _page = "";
        } else {
            _page = parseInt(page);
            if (_.isNaN(_page)) return;
            if (_page < 1) _page = 1;
            if (_page > Math.ceil(filter.matchedRecords / filter.qtyPerPage)) _page = Math.ceil(filter.matchedRecords / filter.qtyPerPage);
        }

        dispatch({
            type: VCommTranBonusActions.FETCH_PAGE,
            payload: _page,
        });
    };
}

export const vCommTranBonusFetchPage = (page) => {
    return (dispatch, getState) => {
        dispatch({
            type: VCommTranBonusActions.FETCH_PAGE,
            payload: page,
        });
        const { vCommTranBonus: { filter } } = getState(); // state updated
        //console.log(filter);
        dispatch(vCommTranBonusLookup(filter));
    };
}

const _vCommTranBonusRequest = () => {
    return {
        type: VCommTranBonusActions.REQUEST
    }
}

const _vCommTranBonusSuccess = (data) => {
    return {
        type: VCommTranBonusActions.SUCCESS,
        payload: data,
    }
}

const _vCommTranBonusFailure = (response) => {
    return {
        type: VCommTranBonusActions.FAILURE,
        payload: response,
    }
}

export const vCommTranBonusLookup = (formData) => {
    const _formData = formData ? formData : initialState.filter;
    //console.log(_formData);
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/VCommTranBonu/GetEntities`;

    return (dispatch, getState) => {
        dispatch(_vCommTranBonusRequest());

        const { vCommTranBonus } = getState(); // state updated
        if (vCommTranBonus.isFilterUpdated) _formData.page = 1; // reset page if filter changed
        if (vCommTranBonus.filter.page == "") _formData.page = 1; // reset page if filter changed

        axios.post(apiEndpoint, _formData, {
            timeout: 30000,
        })
            .then(response => {
                dispatch(clearMsg());

                if (response.data) {
                    dispatch(_vCommTranBonusSuccess(response.data));
                } else {
                    dispatch(_vCommTranBonusFailure("未能讀取"));
                }
            })
            .catch(error => {
                let msg = "";
                if (error.response && error.response.data && error.response.data.title) msg = error.response.data.title;
                else if (error.response && error.response.status) msg = `未能讀取 (${error.response.status})`;
                else msg = '未能聯繫伺服器';

                dispatch(showError(msg));
                dispatch(_vCommTranBonusFailure(msg));
            })
    }
}

const _commTranBonusDetailSuccess = (data) => {
    return {
        type: VCommTranBonusActions.DETAIL_SUCCESS,
        payload: data,
    }
}

const _commTranBonusDetailFailure = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: VCommTranBonusActions.DETAIL_FAILURE,
            payload: response,
        })
    }
}

export const commTranBonusDetail = (Id, isForce) => {
    return (dispatch, getState) => {
        const { vCommTranBonus: { workingEntityId: lastWorkingEntityId } } = getState();
        if (!isForce && lastWorkingEntityId == Id) return; // same entity

        dispatch(_setWorkingEntityId(Id)); // 任何 dispatch 之前先記住 current working entity id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/VCommTranBonu/GetVCommTranBonusShared`;

        dispatch(_vCommTranBonusRequest());

        axios.post(apiEndpoint, { Id }, {
            timeout: 30000,
        })
            .then(response => {
                dispatch(clearMsg());

                dispatch(_commTranBonusDetailSuccess(response.data));
            })
            .catch(error => {
                dispatch(clearMsg());
                console.log(error);

                let msg = "";
                if (error.response && error.response.data && error.response.data.title) msg = error.response.data.title;
                else if (error.response && error.response.status) msg = `未能讀取 (${error.response.status})`;
                else msg = '未能聯繫伺服器';

                dispatch(showError(msg));
                dispatch(_commTranBonusDetailFailure(msg));
            });
    };
}

export const commTranBonusDetailUpdate = (commShareIndex, commShareShareIndex, field, value) => {
    return {
        type: VCommTranBonusActions.DETAIL_UPDATE,
        payload: { commShareIndex, commShareShareIndex, field, value }
    };
}

export const saveEntity = () => {
    return (dispatch, getState) => {
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/VCommTranBonu/SaveVCommTranBonuShared`;

        const { vCommTranBonus: { workingEntity } } = getState();

        dispatch(showLoading());

        axios.post(apiEndpoint, workingEntity, {
            timeout: 30000,
        })
            .then(response => {
                dispatch(_commTranBonusDetailSuccess(response.data));
                dispatch(showInfo("已保存"));
            })
            .catch(error => {
                let msg = "";
                if (error.response && error.response.data && error.response.data.title) msg = error.response.data.title;
                else if (error.response && error.response.status) msg = `未能保存 (${error.response.status})`;
                else msg = '未能聯繫伺服器';

                dispatch(showError(msg));
                dispatch(_commTranBonusDetailFailure(msg));
            })
    };
}

export const deleteEntity = (history) => {
    return (dispatch, getState, x) => {
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/VCommTranBonu/DeleteVCommTranBonuShared`;

        const { vCommTranBonus: { workingEntity } } = getState();

        dispatch(showLoading());
        dispatch(_vCommTranBonusRequest());

        axios.post(apiEndpoint, workingEntity, {
            timeout: 30000,
        })
            .then(response => {
                const { vCommTranBonus: { filter } } = getState();

                dispatch(clearMsg());
                history.push('/commBonusShare');
                dispatch(vCommTranBonusLookup(filter));
            })
            .catch(error => {
                let msg = "";
                if (error.response && error.response.data && error.response.data.title) msg = error.response.data.title;
                else if (error.response && error.response.status) msg = `未能刪除 (${error.response.status})`;
                else msg = '未能聯繫伺服器';

                dispatch(showError(msg));
                dispatch(_commTranBonusDetailFailure(msg));
            })
    };
}


//================== Reducer ==================//
const vCommTranBonusReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);
    let i = -1;

    switch (action.type) {
        case VCommTranBonusActions.FILTER_UPDATE:
            const { field: filterField, value } = action.payload;
            let filters = JSON.parse(state.filter.filter);
            const iFilter = _.findIndex(filters, function (o) { return o.property == filterField; });
            if (iFilter >= 0) {
                if (value) filters[iFilter].value = value;
                else filters.splice(iFilter, 1);
            } else {
                filters.push({ "property": filterField, "value": value });
            }

            _state.filter.filter = JSON.stringify(filters);
            _state.isFilterUpdated = true;
            //console.log('_state.filter', _state.filter.filter);
            return _state;

        case VCommTranBonusActions.CHANGE_SORT:
            const field = action.payload;
            let sorts = JSON.parse(state.filter.sort);
            i = _.findIndex(sorts, function (o) { return o.property == field; });
            if (i >= 0) {
                sorts[i].direction = sorts[i].direction == "ASC" ? "DESC" : "ASC";
            } else {
                // single sort only
                sorts = [{ "property": field, "direction": "ASC" }];
            }

            _state.filter.sort = JSON.stringify(sorts);
            //console.log('_state.filter.sort', _state.filter.sort);

            vCommTranBonusLookup(_state.filter);
            return _state;

        case VCommTranBonusActions.PAGE_UPDATE:
            _state.filter.page = action.payload;
            return _state;

        case VCommTranBonusActions.FETCH_PAGE:
            _state.filter.page = action.payload;
            return _state;

        case VCommTranBonusActions.REQUEST:
            _state.isLoading = true;
            return _state;

        case VCommTranBonusActions.SUCCESS:
            _state.isLoading = false;
            _state.filter = {
                allRecords: action.payload.allRecords,
                matchedRecords: action.payload.matchedRecords,
                totalRecords: action.payload.totalRecords,
                qtyPerPage: action.payload.qtyPerPage,
                page: action.payload.page,
                row: action.payload.row,
                filter: action.payload.filter,
                sort: action.payload.sort,
                success: action.payload.success,
                message: action.payload.message,
            };
            _state.entities = action.payload.entities.map(e => new VCommTranBonus(e));
            _state.errors = "";
            _state.isFilterUpdated = false;
            return _state;

        case VCommTranBonusActions.FAILURE:
            _state.isLoading = false;
            _state.errors = action.payload;
            _state.entities = [];
            return _state;

        case VCommTranBonusActions.DETAIL_ID:
            _state.workingEntityId = action.payload;
            return _state;

        case VCommTranBonusActions.DETAIL_SUCCESS:
            _state.isLoading = false;
            _state.workingEntity = new VCommTranBonus(action.payload);
            _state.workingEntityId = _state.workingEntity.Id;

            //console.log(action.payload);

            // update list
            i = _.findIndex(_state.entities, function (_entity) { return _entity.Id == _state.workingEntity.Id; });
            if (i >= 0) {
                //console.log(_state.entities[i]);
                //console.log(_state.workingEntity);
                _state.entities[i] = _state.workingEntity;
            }

            //console.log(_state.workingEntity.getDesc());

            return _state;

        case VCommTranBonusActions.DETAIL_FAILURE:
            _state.isLoading = false;
            _state.workingEntityErrors = action.payload;
            //console.log(_state.errors);
            return _state;

        case VCommTranBonusActions.DETAIL_UPDATE:
            _state.workingEntity = updateWorkingEntity(_state.workingEntity, action.payload)
            return _state;

        default:
            return state;
    }
}

export default vCommTranBonusReducer;

function updateWorkingEntity(entity, { commShareIndex, commShareShareIndex, field, value }) {
    const cbs = entity.commShares_CommBonusShares[commShareIndex].commBonusShares[commShareShareIndex];
    const sharedCommTranAmount = entity.AmountTran * entity.commShares_CommBonusShares[commShareIndex].CalPercent / 100;

    switch (field) {
        case "Percent":
            cbs.Percent = value;
            cbs.Amount = _.round(sharedCommTranAmount * parseFloat(cbs.Percent) / 100, 2);
            break;

        case "Amount":
            cbs.Amount = value
            cbs.Percent = _.round(parseFloat(cbs.Amount) / sharedCommTranAmount * 100, 2);
            break;

        default:
            cbs[field] = value;
            break;
    }
    return entity;
}
