import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { commDetailUpdate } from '../../redux/commReducer';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

export default function CommDetailTextArea({ fieldName, width, minRows, tabIndex }) {
    const dispatch = useDispatch();
    const { workingEntity } = useSelector(state => {
        return state.comm;
    });

    return <TextareaAutosize minRows={minRows} value={workingEntity[fieldName] ? workingEntity[fieldName] : ''} style={{ width: `${width}px` }} tabIndex={tabIndex} onChange={(e) => { dispatch(commDetailUpdate(fieldName, e.target.value)); }} />
}