export default class User {
  constructor({
    Id,
    DfnRole,
    Role,
    Name,
    Login,
    CcEmail,
    BranchIds,
    Password,
    DateJoin,
    DateLogin,
    IsPrinting,
    IsActive,
  }) {
    this.Id = Id;
    this.DfnRole = DfnRole;
    this.Role = Role;
    this.Name = Name;
    this.Login = Login;
    this.CcEmail = CcEmail;
    this.BranchIds = BranchIds;
    this.Password = Password;
    this.DateJoin = DateJoin;
    this.DateLogin = DateLogin;
    this.IsPrinting = IsPrinting;
    this.IsActive = IsActive;
  }

  isNew() {
    return !this.Id || (this.Id < 1);
  }

  getDesc() {
    if (this.isNew()) return "新用戶";
    return `#${this.Id}. ${this.Name ? this.Name : "[用戶]"}`;
  }
}
