import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Moment from 'moment';
import _ from 'lodash';

import { openPdf, downloadXls, } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";

import Button from '@material-ui/core/Button';

import { BranchSelection, TeamSelection, CalMonthSelection } from '../../Selections';

import DescriptionIcon from '@material-ui/icons/Description';
import PrintIcon from '@material-ui/icons/Print';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
  frmFilter: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& th": {
      fontWeight: 'bold',
      border: "1px solid #B0B0B0",
      backgroundColor: "#FAF3DE",
      padding: "2px 4px",
      textAlign: 'center',
    },

    "& td": {
      border: "1px solid #B0B0B0",
      padding: "2px 4px",
    },

    "& label": {
      marginBottom: "0px",
    }
  },
});

export default function ReportCommReduced() {
  const classes = useStyles();

  const history = useHistory();

  const [state, setState] = useState({
    calMonth: Moment.utc().add(-1, "M").format("YYYY-MM-01"),
  });

  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  const print = (forceRecal) => {
    let filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));

    filter.push({
      property: "forceRecal",
      value: forceRecal ? "TRUE" : "FALSE"
    });
    //console.log(filter);

    openPdf("/api/ReportCommOverview/GetPDF", {
      filter: JSON.stringify(filter)
    }, msgState, msgSetState);
  }

  const excel = () => {
    const filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    downloadXls("/api/ReportCommOverview/GetXlsx", {
      filter: JSON.stringify(filter)
    }, `佣金報告 (月份總覽 ${Moment.utc(state.calMonth).format("YYYY-MM")}).xlsx`, msgState, msgSetState);
  }

  const commSnapshot = () => {
    const filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    downloadXls("/api/ReportCommOverview/GetZip", {
      filter: JSON.stringify(filter)
    }, `佣金報告 (Snapshot ${Moment.utc(state.calMonth).format("YYYY-MM")}).zip`, msgState, msgSetState);
  }

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/report">
          <Typography variant="h1" className={classes.title}>7. 報告</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>7.15. 佣金報告 (月份總覽)</Typography>
      </Breadcrumbs>

      <div style={{ width: '600px' }}>
        <table className={classes.frmFilter}>
          <tbody>
            <tr>
              <th>計算年度及月份</th>
              <td>
                <CalMonthSelection value={state.calMonth} onChange={(e) => { setState({ ...state, calMonth: e.target.value }); }} tabIndex={3} />
              </td>
            </tr>
          </tbody >
        </table >

        <div className="mt-2 mb-3" style={{ textAlign: "right" }}>
          <Button size="small" className="mr-3" variant="contained" disableElevation onClick={(e) => { print(true); }}>重新計算並列印（請耐心等候）</Button>
          <Button startIcon={<PrintIcon />} className="mr-3" size="small" variant="contained" disableElevation onClick={(e) => { print(false); }}>列　印</Button>
          <Button startIcon={<DescriptionIcon />} className="mr-3" size="small" variant="contained" disableElevation onClick={excel}>Excel</Button>
          <Button startIcon={<CloudDownloadIcon />} size="small" variant="contained" disableElevation onClick={commSnapshot}>分行 Snapshot</Button>
        </div>

        <p>
          ※ 由於計算佣金需時頗長，「月份總覽」不會重新計算佣金，只會收集已計算的資料。如發現個別員工沒有資料，你需要在「<Link style={{ color: 'blue', textDecoration: 'underline' }} to="/reportComm">7.1 佣金報告(糧單)</Link> 及「<Link style={{ color: 'blue', textDecoration: 'underline' }} to="/reportCommSupervisor">7.2. 主管佣金報告</Link>」先印出報告。
        </p>
      </div >

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
    </>
  );
}
