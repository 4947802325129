import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';

import { reportCommReducedLookup, reportCommReducedFetchPage, reportCommReducedPageUpdate } from '../../../redux/reportCommReducedReducer';

import ReportCommReducedFilter from './ReportCommReducedFilter';
import ReportCommReducedList from './ReportCommReducedList';
import Pagination from '../../Pagination';

const useStyles = makeStyles({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& a.nav-link:hover h1": {
            color: "#006ed0",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },
    },
});

export default function ReportCommReduced() {
    const classes = useStyles();

    const history = useHistory();

    const dispatch = useDispatch();

    const { filter } = useSelector(state => {
        return state.reportCommReduced;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");

        dispatch(reportCommReducedLookup());
    }, []);

    return (
        <>
            <Breadcrumbs className={classes.title}>
                <NavLink className="nav-link" tag={Link} to="/report">
                    <Typography variant="h1" className={classes.title}>7. 報告</Typography>
                </NavLink>
                <Typography variant="h1" className={classes.title}>7.3.2 減收佣金報告</Typography>
            </Breadcrumbs>

            <ReportCommReducedFilter filter={filter} />
            <Pagination filter={filter} fnFetchPage={reportCommReducedFetchPage} fnPageUpdate={reportCommReducedPageUpdate} />
            <ReportCommReducedList />
            <div style={{ margin: "5px", textAlign: 'center' }}>
                <Pagination filter={filter} fnFetchPage={reportCommReducedFetchPage} fnPageUpdate={reportCommReducedPageUpdate} />
            </div>
        </>
    );
}
