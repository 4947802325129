import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { commFilterUpdate, commFilterClear, commLookup } from '../../redux/commReducer';
import { registerTabIndexEls, keyDownHandler } from '../../extensions/FormExtensions';

import { BranchSelection, TeamSelection, DistrictSelection, DfnCommClassSelection } from '../Selections';
import MyDateField from '../MyDateField';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  frmFilter: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& input, & select": {
      padding: "0px 1px",
      fontSize: "13px",
      height: "1.5em",
    },

    "& th": {
      fontWeight: 'bold',
      border: "1px solid #B0B0B0",
      backgroundColor: "#FAF3DE",
      padding: "2px 4px",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      padding: "2px 4px",
    },
  },
});

export default function CommFilter({ filter }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const filters = JSON.parse(filter.filter);

  const _getFilterValue = (field) => {
    const i = _.findIndex(filters, function (o) { return o.property == field; });
    return (i >= 0) ? filters[i].value : "";
  };

  useEffect(() => {
    registerTabIndexEls(); // for .tabIndexedForm
  }, []);

  const filterChange = (field, value) => {
    dispatch(commFilterUpdate(field, value));
  };

  const clearFilter = () => {
    dispatch(commFilterClear());
  };

  const lookup = () => {
    dispatch(commLookup(filter));
  };

  return (
    <div>
      <table className={`tabIndexedForm ${classes.frmFilter} mb-2`} onKeyDown={(e) => { keyDownHandler(e, lookup); }}>
        <tbody>
          <tr>
            <th style={{ width: '85px' }}>物業編號</th><td><input onChange={(e) => { filterChange('l_ref_no', e.target.value); }} value={_getFilterValue('l_ref_no')} style={{ width: '100px' }} tabIndex={1} /></td>
            <th style={{ width: '55px' }}>大廈</th><td colSpan={3}><input onChange={(e) => { filterChange('building', e.target.value); }} value={_getFilterValue('building')} style={{ width: '100px' }} tabIndex={5} /></td>
            <th style={{ width: '110px' }}>業主姓名</th><td><input onChange={(e) => { filterChange('owner', e.target.value); }} value={_getFilterValue('owner')} style={{ width: '120px' }} tabIndex={11} /></td>
            <th style={{ width: '110px' }}>落訂日期</th><td><MyDateField dateValue={_getFilterValue("f_date_deposit")} onDateChange={(d) => { filterChange('f_date_deposit', d); }} tabIndex={15} /> 至 <MyDateField dateValue={_getFilterValue("t_date_deposit")} onDateChange={(d) => { filterChange('t_date_deposit', d); }} tabIndex={16} /></td>
          </tr>
          <tr>
            <th>分　　行</th><td><BranchSelection showAny={true} onChange={(e) => { filterChange('id_branch', e.target.value); }} value={_getFilterValue('id_branch')} tabIndex={2} /></td>
            <th style={{ width: '55px' }}>期</th><td><input onChange={(e) => { filterChange('phrase', e.target.value); }} value={_getFilterValue('phrase')} style={{ width: '40px' }} tabIndex={6} /></td>
            <th style={{ width: '55px' }}>座</th><td><input onChange={(e) => { filterChange('block', e.target.value); }} value={_getFilterValue('block')} style={{ width: '40px' }} tabIndex={7} /></td>
            <th>業主電話</th><td><input onChange={(e) => { filterChange('phone_owner', e.target.value); }} value={_getFilterValue('phone_owner')} style={{ width: '120px' }} tabIndex={12} /></td>
            <th>成交日期</th><td><MyDateField dateValue={_getFilterValue("f_date_conclude")} onDateChange={(d) => { filterChange('f_date_conclude', d); }} tabIndex={17} /> 至 <MyDateField dateValue={_getFilterValue("t_date_conclude")} onDateChange={(d) => { filterChange('t_date_conclude', d); }} tabIndex={18} /></td>
          </tr>
          <tr>
            <th>組　　別</th><td><TeamSelection showAny={true} onChange={(e) => { filterChange('id_team', e.target.value); }} value={_getFilterValue('id_team')} tabIndex={3} /></td>
            <th>層數</th><td><input onChange={(e) => { filterChange('floor', e.target.value); }} value={_getFilterValue('floor')} style={{ width: '40px' }} tabIndex={8} /></td>
            <th>單位</th><td><input onChange={(e) => { filterChange('flat', e.target.value); }} value={_getFilterValue('flat')} style={{ width: '40px' }} tabIndex={9} /></td>
            <th>買方/租客姓名</th><td><input onChange={(e) => { filterChange('buyer', e.target.value); }} value={_getFilterValue('buyer')} style={{ width: '120px' }} tabIndex={13} /></td>
            <th>合約日期</th><td><MyDateField dateValue={_getFilterValue("f_date_contract")} onDateChange={(d) => { filterChange('f_date_contract', d); }} tabIndex={19} /> 至 <MyDateField dateValue={_getFilterValue("t_date_contract")} onDateChange={(d) => { filterChange('t_date_contract', d); }} tabIndex={20} /></td>
          </tr>
          <tr>
            <th>地　　區</th><td><DistrictSelection showAny={true} onChange={(e) => { filterChange('id_team', e.target.value); }} value={_getFilterValue('id_team')} tabIndex={4} /></td>
            <th>類別</th><td colSpan={3}><DfnCommClassSelection showAny={true} onChange={(e) => { filterChange('class', e.target.value); }} value={_getFilterValue('class')} tabIndex={10} /></td>
            <th>買方/租客電話</th><td><input onChange={(e) => { filterChange('phone_buyer', e.target.value); }} value={_getFilterValue('phone_buyer')} style={{ width: '120px' }} tabIndex={14} /></td>
            <th>買賣雙方姓名</th><td><input onChange={(e) => { filterChange('owner_or_buyer', e.target.value); }} value={_getFilterValue('owner_or_buyer')} style={{ width: '150px' }} tabIndex={21} /></td>
          </tr>
        </tbody>
      </table>
      <Grid container justifyContent="flex-end" spacing={2} className="mb-2">
        <Grid item >
          <Button variant="contained" size="small" onClick={(_) => { lookup(); }}>搜　尋</Button>
        </Grid>
        <Grid item >
          <Button variant="contained" size="small" onClick={(_) => { clearFilter(); }}>清　除</Button>
        </Grid>
      </Grid>
    </div>
  );
}
