import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
        padding: "4em 0px",
        textAlign: "center",
        fontSize: "26px",
        fontWeight: "bold",
    },
});


export default function Home() {
    const classes = useStyles();

    return (
        <div>
            <Typography variant="h1" className={classes.root}>
                世紀21奇豐物業 ─ 佣金系統
            </Typography>
            <hr />
        </div>
    );
}
