export default class Bar {
  constructor({
    Id,
    Branch1,
    Seq,
    Fdate,
    Tdate,
    IdDistrictManager,
    IdEmployee,
    IsActive,
    IdSupervisors,
    IdSupEmployees,
  }) {
    this.Id = Id;
    this.Branch1 = Branch1;
    this.Seq = Seq;
    this.Fdate = Fdate;
    this.Tdate = Tdate;
    this.IdDistrictManager = IdDistrictManager;
    this.IdEmployee = IdEmployee;
    this.IsActive = IsActive;
    this.IdSupervisors = IdSupervisors;
    this.IdSupEmployees = IdSupEmployees;
  }

  isNew() {
    return !this.Id || (this.Id < 1);
  }

  getDesc() {
    if (this.isNew()) return "新分行";
    return `${this.Id}. ${this.Branch1 ? this.Branch1 : "[分行]"}`;
  }
}
