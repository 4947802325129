import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep, initial } from 'lodash';
import Moment from 'moment';

import { commUpdateByCommShare } from "./commReducer";
import CommShareAssoc from "../models/commShareAssoc";

//================== Whole State ==================//
const initialState = {
  isLoading: false,
  errors: '',
  commShareAssocs: _.range(12).map(i => new CommShareAssoc({})), // there are 12 rows!!

  isShowModalCalTool: false, // toggle showing cal tools

  isShowModalDiff: false,
  diffCommShareOwner: 0, // toggle showing no diff modal window
  diffCommShareBuyer: 0, // toggle showing no diff modal window
};


//================== Action Types ==================//
export const CommShareAssocActions = Object.freeze({
  FETCH: Symbol("FETCH"),
  SUCCESS: Symbol("SUCCESS"),
  FAILURE: Symbol("FAILURE"),

  CLEAR_ASSOCS: Symbol("CLEAR_ASSOCS"), // before comm loaded
  COMM_UPDATE: Symbol("COMM_UPDATE"), // comm update
  UPDATE: Symbol("UPDATE"), // commShare update
  SAVE: Symbol("SAVE"),

  SET_EMPLOYEE: Symbol("SET_EMPLOYEE"),
  SET_VISIBLE_CAL_TOOL: Symbol("SET_VISIBLE_CAL_TOOL"),
  APPLY_CAL_TOOL: Symbol("APPLY_CAL_TOOL"),

  CHECK_DIFF: Symbol("CHECK_DIFF"),
  SET_VISIBLE_CHECK_DIFF: Symbol("SET_VISIBLE_CHECK_DIFF"),
});


//================== Action Creators ==================//
const _fetch = () => {
  return {
    type: CommShareAssocActions.FETCH,
  }
}

const _success = (data) => {
  return {
    type: CommShareAssocActions.SUCCESS,
    payload: data,
  }
}

const _failure = (response) => {
  return {
    type: CommShareAssocActions.FAILURE,
    payload: response,
  }
}

export const detailCommGetAssocs = () => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Comm/GetAssoc`;

  return (dispatch, getState) => {
    const { comm: { workingEntity } } = getState(); // 取得 comm 的 workingEntity
    const data = workingEntity;

    dispatch(_fetch());

    axios.post(apiEndpoint, data, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_success(response.data));
        } else {
          dispatch(_failure("未能讀取"));
        }
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          dispatch(_failure(error.response.data.errors));
        } else if (error.response && error.response.status) {
          dispatch(_failure(`未能讀取 (${error.response.status})`));
        } else {
          dispatch(_failure(`未能聯繫伺服器`));
        }
      });
  }
}

export const detailCommUpdate = (field, value) => {
  return {
    type: CommShareAssocActions.COMM_UPDATE,
    payload: { field, value },
  };
}

export const clearCommShareAssocs = (field, value) => {
  //console.log('CommShareAssocActions.CLEAR_ASSOCS');
  return {
    type: CommShareAssocActions.CLEAR_ASSOCS,
    payload: { field, value },
  };
}

export const commShareAssocUpdate = (comm, nth, fieldName, value) => {
  return (dispatch, getState) => {
    dispatch({
      type: CommShareAssocActions.UPDATE,
      payload: { comm, nth, fieldName, value },
    });

    dispatch(commUpdateByCommShare());
  };
}

export const detailCommShareAssocSetEmployee = (nth, employee) => {
  return (dispatch, getState) => {
    dispatch({
      type: CommShareAssocActions.SET_EMPLOYEE,
      payload: { nth, employee },
    });

    dispatch(commUpdateByCommShare());
  };
}

export const detailCommSetVisibleCalTool = (isVisible) => {
  return {
    type: CommShareAssocActions.SET_VISIBLE_CAL_TOOL,
    payload: isVisible,
  };
}

export const getDetailCommCheckDiff = (comm, commShareAssocs) => {
  //console.log("commShareAssocReducer.getDetailCommCheckDiff()", commShareAssocs);

  for (let i = 0; i <= commShareAssocs.length; i++) {
    if ((commShareAssocs[i]?.OwnerComm || commShareAssocs[i]?.BuyerComm) && !commShareAssocs[i]?.IdEmployee) return `分成 #${i + 1} 沒有員工編號`;
    if (commShareAssocs[i]?.IdEmployee && !(commShareAssocs[i]?.OwnerComm || commShareAssocs[i]?.BuyerComm)) return commShareAssocs[i]?.Name ? `${commShareAssocs[i]?.Name} 沒有佣金`: `分成 #${i + 1} 沒有佣金`;

    if (!commShareAssocs[i]?.IdEmployee) continue;

    for (let j = i + 1; j <= commShareAssocs.length; j++) {
      if (!commShareAssocs[j]?.IdEmployee) continue;

      //console.log(`${commShareAssocs[i].IdEmployee} vs ${commShareAssocs[j].IdEmployee}`);
      if (commShareAssocs[i].IdEmployee == commShareAssocs[j].IdEmployee) return `分成 #${i + 1} 與 #${j + 1} 的員工編號相同 (${commShareAssocs[i].IdEmployee})`;
    }
  }

  const { CommOwner, CommBuyer } = comm;

  const sumOwner = _.sumBy(commShareAssocs, function (cs) { return parseFloat(cs.OwnerComm ? cs.OwnerComm : 0); });
  const sumBuyer = _.sumBy(commShareAssocs, function (cs) { return parseFloat(cs.BuyerComm ? cs.BuyerComm : 0); });

  const diffCommShareOwner = _.round(Math.abs(CommOwner - sumOwner), 2);
  const diffCommShareBuyer = _.round(Math.abs(CommBuyer - sumBuyer), 2);

  if (diffCommShareOwner > 0 && diffCommShareBuyer > 0) return `業主佣金差異 $${diffCommShareOwner}，買家佣金差異 $${diffCommShareBuyer}`;
  if (diffCommShareOwner > 0) return `業主佣金差異 $${diffCommShareOwner}`;
  if (diffCommShareBuyer > 0) return `買家佣金差異 $${diffCommShareBuyer}`;

  return null;
}

export const detailCommCheckDiff = () => {
  return (dispatch, getState) => {
    const { comm: { workingEntity: { CommOwner, CommBuyer } } } = getState(); // 取得 comm 的 workingEntity
    dispatch({
      type: CommShareAssocActions.CHECK_DIFF,
      payload: { CommOwner, CommBuyer },
    });
  };
}

export const detailCommApplyCalTool = (values) => {
  return {
    type: CommShareAssocActions.APPLY_CAL_TOOL,
    payload: values,
  };
}


export const detailCommSetVisibleCheckDiff = (isVisible) => {
  return {
    type: CommShareAssocActions.SET_VISIBLE_CHECK_DIFF,
    payload: isVisible,
  };
}

export const saveAssoc = (comm, getState) => {
  const { commShareAssoc: { commShareAssocs } } = getState();

  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Comm/SaveCommShareAssocs`;
  const data = { comm, commShareAssocs };
  console.log("commShareAssocReducer.saveAssoc", data);
  return axios.post(apiEndpoint, data, {
    timeout: 30000,
    //}).then(response => {
  });
}


//================== Reducer ==================//
const commShareAssocReducer = (state = initialState, action) => {
  let _state = cloneDeep(state);

  switch (action.type) {
    case CommShareAssocActions.FETCH:
      _state.isLoading = true;
      _state.commShareAssocs = initialState.commShareAssocs;
      return _state;


    case CommShareAssocActions.SUCCESS:
      //console.log('CommShareAssocActions.SUCCESS', action.payload.entities);
      _state.isLoading = false;
      let i = 0;
      i = 0;

      _.forEach(action.payload.entities, entity => {
        _state.commShareAssocs[i] = new CommShareAssoc(entity);
        i++;
      });
      //console.log(_state.commShareAssocs);

      return _state;

    case CommShareAssocActions.FAILURE:
      _state.isLoading = false;
      _state.errors = action.payload;
      return _state;

    case CommShareAssocActions.COMM_UPDATE:
      const { field, value } = action.payload;
      switch (field) {
        case "CommOwner":
        case "CommBuyer":
          break;
        default: return _state;
      }

      _calCommShareAssocs(_state.commShareAssocs, field, value);
      return _state;

    case CommShareAssocActions.CLEAR_ASSOCS:
      _state.commShareAssocs = initialState.commShareAssocs;
      return _state;

    case CommShareAssocActions.UPDATE:
      const { comm, nth, fieldName: updateField, value: _updateValue } = action.payload;
      _state.commShareAssocs[nth][updateField] = _updateValue;

      switch (updateField) {
        case "OwnerComm":
        case "OwnerPercent":
        case "BuyerComm":
        case "BuyerPercent":
          break;
        default:
          return _state;
      }

      const commOwner = _.round(parseFloat(comm.CommOwner ? comm.CommOwner : 0), 2);
      const commBuyer = _.round(parseFloat(comm.CommBuyer ? comm.CommBuyer : 0), 2);
      const updateValue = _.round(parseFloat(_updateValue ? _updateValue : 0), 2);

      switch (updateField) {
        case "OwnerComm":
          if (!commOwner) return _state;
          const ownerPercent = _.round(updateValue / commOwner * 100, 2);
          // 0.01 allowance
          if (!parseFloat(_state.commShareAssocs[nth].OwnerPercent) || Math.abs(parseFloat(_state.commShareAssocs[nth].OwnerPercent) - ownerPercent) >= 0.02)
            _state.commShareAssocs[nth].OwnerPercent = ownerPercent;
          break;
        case "OwnerPercent":
          if (!commOwner) return _state;
          _state.commShareAssocs[nth].OwnerComm = _.round(commOwner * updateValue / 100, 2);
          break;
        case "BuyerComm":
          if (!commBuyer) return _state;
          const buyerPercent = _.round(updateValue / commBuyer * 100, 2);
          // 0.01 allowance
          if (!parseFloat(_state.commShareAssocs[nth].BuyerPercent) || Math.abs(parseFloat(_state.commShareAssocs[nth].BuyerPercent) - buyerPercent) >= 0.02)
            _state.commShareAssocs[nth].BuyerPercent = buyerPercent;
          break;
        case "BuyerPercent":
          if (!commBuyer) return _state;
          _state.commShareAssocs[nth].BuyerComm = _.round(commBuyer * updateValue / 100, 2);
          break;
      }
      return _state;

    case CommShareAssocActions.SET_EMPLOYEE:
      const { nth: nthSetEmployee, employee } = action.payload;
      if (nthSetEmployee == null) return _state;
      _state.commShareAssocs[nthSetEmployee].IdEmployee = employee ? employee.Id : null;
      _state.commShareAssocs[nthSetEmployee].Name = employee ? employee.Name : null;
      _state.commShareAssocs[nthSetEmployee].Alias = employee ? employee.Alias : null;
      _state.commShareAssocs[nthSetEmployee].Branch = employee ? employee.Branch : null;

      return _state;

    case CommShareAssocActions.SET_VISIBLE_CAL_TOOL:
      _state.isShowModalCalTool = action.payload;
      return _state;

    case CommShareAssocActions.APPLY_CAL_TOOL:
      const owner = action.payload.owner;
      const buyer = action.payload.buyer;
      const values = action.payload.agents;
      for (let i = 0; i < 10; i++) {
        if (!values[i].owner && !values[i].buyer) continue;

        _state.commShareAssocs[i].OwnerComm = values[i].owner;
        _state.commShareAssocs[i].OwnerPercent = _.round(values[i].owner / owner * 100, 2);

        _state.commShareAssocs[i].BuyerComm = values[i].buyer;
        _state.commShareAssocs[i].BuyerPercent = _.round(values[i].buyer / buyer * 100, 2);
      }
      return _state;

    case CommShareAssocActions.CHECK_DIFF:
      const { CommOwner, CommBuyer } = action.payload;
      const sumOwner = _.sumBy(_state.commShareAssocs, function (cs) { return parseFloat(cs.OwnerComm ? cs.OwnerComm : 0); });
      const sumBuyer = _.sumBy(_state.commShareAssocs, function (cs) { return parseFloat(cs.BuyerComm ? cs.BuyerComm : 0); });

      _state.diffCommShareOwner = Math.abs(CommOwner - sumOwner);
      _state.diffCommShareBuyer = Math.abs(CommBuyer - sumBuyer);
      _state.isShowModalDiff = true;
      return _state;

    case CommShareAssocActions.SET_VISIBLE_CHECK_DIFF:
      _state.isShowModalDiff = action.payload;
      return _state;

    default:
      return state;
  }
}

export default commShareAssocReducer;

const _calCommShareAssocs = (CommShareAssocs, field, value) => {
  let amount = _.parseInt(value)

  for (let i = 0; i < 10; i++) {
    switch (field) {
      case "CommOwner":
        if (_.isNaN(amount) || !CommShareAssocs[i].OwnerPercent) {
          CommShareAssocs[i].OwnerComm = null;
          continue;
        }

        CommShareAssocs[i].OwnerComm = _.round(amount / 100 * CommShareAssocs[i].OwnerPercent, 2);
        break;
      case "CommBuyer":
        if (_.isNaN(amount) || !CommShareAssocs[i].BuyerPercent) {
          CommShareAssocs[i].BuyerComm = null;
          continue;
        }

        CommShareAssocs[i].BuyerComm = _.round(amount / 100 * CommShareAssocs[i].BuyerPercent, 2);
        break;
    }
  }
}

