import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    title: {
        paddingBottom: "15px",
        fontSize: "26px",
        lineHeight: "26px",
        fontWeight: "bold",
    },
    links: {
        padding: "0px 0px 0px 40px",
        listStyleType: "none",

        "& a, h3": {
            fontWeight: "bold",
            fontSize: "19px",
            lineHeight: "30px",
            padding: "0px",
        },

        "& ul": {
            padding: "0px 0px 0px 40px",
            listStyleType: "none",
        }
    },
});

export default function Setting() {
    const classes = useStyles();

    return (
        <div>
            <Typography variant="h1" className={classes.title}>10. 設定</Typography>
            <ul className={classes.links}>
                <li>
                    <h3>
                        <NavLink tag={Link} to="/branch">10.1. 設定分行</NavLink>
                    </h3>
                </li>
                <li>
                    <h3>
                        <NavLink tag={Link} to="/team">10.2. 設定組別</NavLink>
                    </h3>
                </li>
                <li>
                    <h3>
                        <NavLink tag={Link} to="/supervisorGroup">10.2.1 設定主管拆賬表組別</NavLink>
                    </h3>
                </li>
                <li>
                    <h3>
                        <NavLink tag={Link} to="/commRef">10.3. 設定參考地址</NavLink>
                    </h3>
                </li>
                <li>
                    <h3>
                        <NavLink tag={Link} to="/solicitorsRef">10.4. 設定參考律師樓</NavLink>
                    </h3>
                </li>
                <li>
                    <h3>
                        <NavLink tag={Link} to="/user">10.5. 設定登入用戶</NavLink>
                    </h3>
                </li>
            </ul>
        </div>
    );
}
