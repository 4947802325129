export default class CommPrepaid {
    constructor({
        Id,
        IdComm,
        RefNo,
        IdEmployee,
        Name,
        Alias,
        Amount,
        DateDeposit,
        EditDate,
        EditTime,
        EditUser,
    }) {
        this.Id = Id;
        this.IdComm = IdComm;
        this.RefNo = RefNo;
        this.IdEmployee = IdEmployee;
        this.Name = Name;
        this.Alias = Alias;
        this.Amount = Amount;
        this.DateDeposit = DateDeposit;
        this.EditDate = EditDate;
        this.EditTime = EditTime;
        this.EditUser = EditUser;
    }

    getDesc() {
        if (this.Id.toString().toUpperCase() == "NEW" || this.Id < 1) return "新預支佣金";

        return `#${this.Id}`;
    }
}
