import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Moment from 'moment';

import CommBonusShare from "../models/commBonusShare";

import { showLoading, showInfo, showError, clearMsg } from './msgReducer';

//================== Whole State ==================//
const initialState = {
    isLoading: false,
    errors: '',
    workingEntityId: -1,
    workingEntity: null,
    workingEntityErrors: '',
};


//================== Action Types ==================//
export const CommBonusShareActions = Object.freeze({
    // listing
    REQUEST: Symbol("REQUEST"),

    // detail page
    DETAIL_ID: Symbol("DETAIL_ID"),
    DETAIL_SUCCESS: Symbol("DETAIL_SUCCESS"), // ajax loaded
    DETAIL_FAILURE: Symbol("DETAIL_FAILURE"),
    DETAIL_DELETED: Symbol("DETAIL_DELETED"), // ajax delete

    DETAIL_UPDATE: Symbol("DETAIL_UPDATE"), // form fields update
});


//================== Action Creators ==================//
const _commBonusShareRequest = () => {
    return {
        type: CommBonusShareActions.REQUEST
    }
}

const _setWorkingEntityId = (id) => {
    return {
        type: CommBonusShareActions.DETAIL_ID,
        payload: id,
    }
}

const _commBonusShareDetailSuccess = (data) => {
    return {
        type: CommBonusShareActions.DETAIL_SUCCESS,
        payload: data,
    }
}

const _commBonusShareDetailFailure = (response) => {
    return (dispatch, getState) => {
        dispatch({
            type: CommBonusShareActions.DETAIL_FAILURE,
            payload: response,
        })
    }
}

export const commBonusShareDetail = (Id, isForce) => {
    return (dispatch, getState) => {
        const { commBonusShare: { workingEntityId: lastWorkingEntityId } } = getState();
        if (!isForce && lastWorkingEntityId == Id) return; // same entity

        dispatch(_setWorkingEntityId(Id)); // 任何 dispatch 之前先記住 current working entity id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/CommBonusShare/GetEntity`;

        dispatch(_commBonusShareRequest());

        axios.post(apiEndpoint, { Id }, {
            timeout: 30000,
        })
            .then(response => {
                dispatch(clearMsg());

                if (response.data) {
                    dispatch(_commBonusShareDetailSuccess(response.data));
                } else {
                    dispatch(_commBonusShareDetailFailure("未能讀取"));
                }
            })
            .catch(error => {
                dispatch(clearMsg());

                let msg = "";
                if (error.response && error.response.data && error.response.data.title) msg = error.response.data.title;
                else if (error.response && error.response.status) msg = `未能讀取 (${error.response.status})`;
                else msg = '未能聯繫伺服器';

                dispatch(showError(msg));
                dispatch(_commBonusShareDetailFailure(msg));
            })
    };
}

export const commBonusShareDetailUpdate = (field, value) => {
    return {
        type: CommBonusShareActions.DETAIL_UPDATE,
        payload: {
            field: field,
            value: value
        }
    };
}

export const commBonusShareUpdate = (nth, fieldName, value) => {
    return (dispatch, getState) => {
        // copy idEmployee from detail owner comm share
        const { commBonusShare: { detailOwnerCommShares } } = getState();
        const { IdEmployee, Name } = detailOwnerCommShares[nth];
        console.log(Name);

        dispatch({
            type: CommBonusShareActions.DETAIL_COMM_PREPAIDS_UPDATE,
            payload: { nth, fieldName, value, IdEmployee, Name },
        });
    }

}


//================== Reducer ==================//
const commBonusShareReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);

    switch (action.type) {
        case CommBonusShareActions.REQUEST:
            _state.isLoading = true;
            return _state;

        case CommBonusShareActions.DETAIL_ID:
            _state.workingEntityId = action.payload;
            return _state;

        case CommBonusShareActions.DETAIL_SUCCESS:
            _state.isLoading = false;
            _state.workingEntity = new CommBonusShare(action.payload);
            _state.workingEntityId = _state.workingEntity.Id;
            //console.log(_state.workingEntity);
            //console.log(_state.workingEntity.getDesc());

            return _state;

        case CommBonusShareActions.DETAIL_FAILURE:
            _state.isLoading = false;
            _state.workingEntityErrors = action.payload;
            //console.log(_state.errors);
            return _state;

        case CommBonusShareActions.DETAIL_UPDATE:
            const { field: updateField, value: updateValue } = action.payload;
            _state.workingEntity[updateField] = updateValue;
            return _state;

        default:
            return state;
    }
}

export default commBonusShareReducer;
