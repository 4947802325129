import _ from 'lodash';

export default class ReportCommReceived {
    constructor({
        Id,
        DateDeposit,
        RefNo,
        LongAddr,
        DateConclude,
        Price,
        Relevant1stName,
        IdEmployee,
        Name,
        Percent,
        CalPercent,
        Party,
        DateTran,
        Amount,
        Remarks,
    }) {
        this.Id = Id;
        this.DateDeposit = DateDeposit;
        this.RefNo = RefNo;
        this.LongAddr = LongAddr;
        this.DateConclude = DateConclude;
        this.Price = Price;
        this.Relevant1stName = Relevant1stName;
        this.IdEmployee = IdEmployee;
        this.Name = Name;
        this.Percent = Percent;
        this.CalPercent = CalPercent;
        this.Party = Party;
        this.DateTran = DateTran;
        this.Amount = Amount;
        this.Remarks = Remarks;
    }

    getFirstName() {
        if (_.isEmpty(this.Name)) return '-';

        if (this.Name.indexOf(',') < 0) return this.Name;

        return this.Name.split(',')[0];
    }
}
