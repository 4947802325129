import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';

import Moment from 'moment';
import _ from 'lodash';

import { openPdf, downloadXls, actionPdf, PreviewPdf } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";

import Button from '@material-ui/core/Button';

import MyDateField from '../../MyDateField';
import { DfnDistrictManagerSelection, BranchSelection, SupervisorMultipleCheckbox, CcEmailMultipleCheckbox } from '../../Selections';

import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EmailIcon from '@material-ui/icons/Email';

const StyledTabs = withStyles({
  root: {
    padding: '0px',
    minHeight: 'auto',
  },
  indicator: {
    backgroundColor: '#f7de8c'
  }
})(Tabs);

const StyledTab = withStyles({
  root: {
    padding: '0px',
    minHeight: 'auto',
    lineHeight: '2rem',
    borderRadius: '8px 8px 0px 0px',
    border: '1px solid silver',
    backgroundColor: '#ebebeb',
  },
  selected: {
    backgroundColor: '#FAF3DE',
  },
})(Tab);

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
  frmFilter: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& th": {
      fontWeight: 'bold',
      border: "1px solid #B0B0B0",
      backgroundColor: "#FAF3DE",
      padding: "2px 4px",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      padding: "2px 4px",
    },

    "& label": {
      marginBottom: "0px",
    }
  },
});

export default function ReportRank() {
  const classes = useStyles();
  const history = useHistory();

  // security
  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  const { branches, balEmployees, cfgSelection: { includes: { supervisorEmployeeIncludes } } } = useSelector(state => {
    return state.selections;
  });

  const [state, setState] = useState({
    fDate: Moment.utc().startOf('month'),
    tDate: Moment.utc().endOf('month'),
    idDfnDistrictManager: "",
    idBranch: "",
    isHideEmpty: "__YES__",
    isHideLeaved: "__YES__",
    supervisorAdLogins: "[]",
    otherReceivers: "",
    ccEmails: "[]",
  });

  const filter = _.keys(state).map(key => ({
    property: key,
    value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
  }));

  const [tabValue, setTabValue] = React.useState(0); // tab
  const [preview, setPreview] = useState(false); // preview
  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null }); // dialog

  const dfnDistrictManagerSelectionChanged = (idDfnDistrictManager) => {
    if (!idDfnDistrictManager) {
      const allDistrictEmployees = _.filter(balEmployees, (e) => (e.AdLogin) && (!e.DateLeave) && (e.Role == "主管"));

      setState({
        ...state,
        idDfnDistrictManager,
        supervisorAdLogins: JSON.stringify(allDistrictEmployees.map(e => e.AdLogin))
      });

      return;
    }

    const districtBranches = _.filter(branches, (b) => b.IdDistrictManager == idDfnDistrictManager);
    const districtEmployees = _.filter(balEmployees, (e) => (e.AdLogin) && (!e.DateLeave) && (e.Role == "主管") && _.findIndex(districtBranches, (b) => b.Id == e.IdBranch) >= 0);

    setState({
      ...state,
      idDfnDistrictManager,
      supervisorAdLogins: JSON.stringify([...supervisorEmployeeIncludes.map(e => e.AdLogin), ...districtEmployees.map(e => e.AdLogin)])
    });
  };

  // PreviewPdf action
  const email = () => {
    const filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));

    actionPdf("/api/ReportRank/EmailPDF", {
      filter: JSON.stringify(filter)
    }, msgState, msgSetState);
  };

  const { supervisorAdLogins, otherReceivers, ccEmails } = state;

  const qtyReceivers = JSON.parse(supervisorAdLogins).length + JSON.parse(ccEmails).length + _.filter(otherReceivers.split("\n"), (s) => s.indexOf("@") > 0).length;

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/report">
          <Typography variant="h1" className={classes.title}>7. 報告</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>7.10. 營業員每月排位</Typography>
      </Breadcrumbs>

      <div style={{ width: '650px' }}>
        <StyledTabs
          value={tabValue}
          onChange={(_e, value) => {
            setTabValue(value);
            if (value == 1 && !state.idDfnDistrictManager) dfnDistrictManagerSelectionChanged(""); // default select managers
          }}
          indicatorColor="primary"
        >
          <StyledTab disableRipple label="列 印" />
          <StyledTab disableRipple label="電 郵" />
        </StyledTabs>
        <SwipeableViews index={tabValue} >
          <TabPrint index={0} state={state} setState={setState} dfnDistrictManagerSelectionChanged={dfnDistrictManagerSelectionChanged} msgState={msgState} msgSetState={msgSetState} />
          <TabPreviewEmail index={0} state={state} setState={setState} dfnDistrictManagerSelectionChanged={dfnDistrictManagerSelectionChanged} setPreview={setPreview} />
        </SwipeableViews>
      </div >

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
      <PreviewPdf open={preview} setOpen={setPreview} url={"/api/ReportRank/GetPDF"} params={{ filter: JSON.stringify(filter) }}>
        <Button startIcon={<EmailIcon />} className="mr-2" size="small" variant="contained" disabled={qtyReceivers <= 0} disableElevation onClick={email}>{qtyReceivers ? `電郵給 (${qtyReceivers} 位同事)` : `電郵`}</Button>
      </PreviewPdf>
    </>
  );
}

function TabPrint({ index, state, setState, dfnDistrictManagerSelectionChanged, msgState, msgSetState }) {
  const classes = useStyles();

  const print = async () => {
    let filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    await openPdf("/api/ReportRank/GetPDF", {
      filter: JSON.stringify(filter)
    }, msgState, msgSetState);
  };

  const excel = () => {
    const filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    downloadXls("/api/ReportRank/GetXlsx", {
      filter: JSON.stringify(filter)
    }, "營業員每月排位.xlsx", msgState, msgSetState);
  }

  return <div index={index}>
    <table className={classes.frmFilter}>
      <tbody>
        <tr>
          <th>分　　區</th>
          <td>
            <DfnDistrictManagerSelection showAny={true} value={state.idDfnDistrictManager} onChange={(e) => { dfnDistrictManagerSelectionChanged(e.target.value); }} tabIndex={1} />
          </td>
        </tr>
        <tr>
          <th>分　　行</th>
          <td>
            <BranchSelection showAny={true} value={state.idBranch} onChange={(e) => { setState({ ...state, idBranch: e.target.value }) }} tabIndex={2} />
          </td>
        </tr>
        <tr>
          <th>營業日期</th>
          <td>
            <MyDateField dateValue={state.fDate} onDateChange={(d) => { setState({ ...state, fDate: d?.format("YYYY-MM-DD") }); }} tabIndex={3} />
            &nbsp;至&nbsp;
            <MyDateField dateValue={state.tDate} onDateChange={(d) => { setState({ ...state, tDate: d?.format("YYYY-MM-DD") }); }} tabIndex={4} />
          </td>
        </tr>
        <tr>
          <th>隱藏沒有金額</th>
          <td>
            <RptRankFilterRadioField state={state} setState={setState} fieldName="isHideEmpty" label="隱藏沒有金額的營業員" value="__YES__" tabIndex={5} index="0" />
            <RptRankFilterRadioField state={state} setState={setState} fieldName="isHideEmpty" label="顯示全部" value="__NO__" tabIndex={6} index="0" />
          </td>
        </tr>
        <tr>
          <th>隱藏已離職</th>
          <td>
            <RptRankFilterRadioField state={state} setState={setState} fieldName="isHideLeaved" label="隱藏已離職的營業員" value="__YES__" tabIndex={7} index="0" />
            <RptRankFilterRadioField state={state} setState={setState} fieldName="isHideLeaved" label="顯示全部" value="__NO__" tabIndex={8} index="0" />
          </td>
        </tr>
      </tbody >
    </table >
    <div className="mt-2 mb-3" style={{ textAlign: "right" }}>
      <Button startIcon={<PrintIcon />} className="mr-3" size="small" variant="contained" disableElevation onClick={(e) => { print(false); }}>列　印</Button>
      <Button startIcon={<DescriptionIcon />} size="small" variant="contained" disableElevation onClick={excel}>Excel</Button>
    </div>
  </div>;
}

function TabPreviewEmail({ index, state, setState, dfnDistrictManagerSelectionChanged, setPreview }) {
  const classes = useStyles();

  const handleAdLoginChange = (strAdLogin) => {
    setState({
      ...state,
      supervisorAdLogins: strAdLogin
    });
  };

  const handleCcEmailChange = (strCcEmail) => {
    setState({
      ...state,
      ccEmails: strCcEmail,
    });
  };

  const { supervisorAdLogins, otherReceivers, ccEmails } = state;

  const qtyReceivers = JSON.parse(supervisorAdLogins).length + JSON.parse(ccEmails).length + _.filter(otherReceivers.split("\n"), (s) => s.indexOf("@") > 0).length;

  return <div index={index}>
    <table className={classes.frmFilter}>
      <tbody>
        <tr>
          <th>分　　區</th>
          <td>
            <DfnDistrictManagerSelection showAny={true} value={state.idDfnDistrictManager} onChange={(e) => { dfnDistrictManagerSelectionChanged(e.target.value); }} tabIndex={101} />
          </td>
        </tr>
        <tr>
          <th>收件主管</th>
          <td>
            <SupervisorMultipleCheckbox value={state.supervisorAdLogins} onChange={handleAdLoginChange} tabIndex={102} isShowEmail={true} />
          </td>
        </tr>
        <tr>
          <th>CC 電郵</th>
          <td>
            <CcEmailMultipleCheckbox value={state.ccEmails} onChange={handleCcEmailChange} tabIndex={103} />
          </td>
        </tr>
        <tr>
          <th>CC 其他電郵</th>
          <td>
            <TextareaAutosize minRows={2} value={state.otherReceivers} style={{ width: 400 }} tabIndex={104} onChange={(e) => {
              let value = e.target.value;
              if (_.endsWith(value, "@cent")) {
                value = value.replaceAll("@cent", "@century21-goodwin.com");
                value = value.replaceAll("@century21-goodwin.comury21-goodwin.com", "@century21-goodwin.com");
              }
              setState({ ...state, otherReceivers: value });
            }} />
          </td>
        </tr>
        <tr>
          <th>分　　行</th>
          <td>
            <BranchSelection showAny={true} value={state.idBranch} onChange={(e) => { setState({ ...state, idBranch: e.target.value }) }} tabIndex={104} />
          </td>
        </tr>
        <tr>
          <th>營業日期</th>
          <td>
            <MyDateField dateValue={state.fDate} onDateChange={(d) => { setState({ ...state, fDate: d?.format("YYYY-MM-DD") }); }} tabIndex={105} />
            &nbsp;至&nbsp;
            <MyDateField dateValue={state.tDate} onDateChange={(d) => { setState({ ...state, tDate: d?.format("YYYY-MM-DD") }); }} tabIndex={106} />
          </td>
        </tr>
        <tr>
          <th>隱藏沒有金額</th>
          <td>
            <RptRankFilterRadioField state={state} setState={setState} fieldName="isHideEmpty" label="隱藏沒有金額的營業員" value="__YES__" tabIndex={104} index="1" />
            <RptRankFilterRadioField state={state} setState={setState} fieldName="isHideEmpty" label="顯示全部" value="__NO__" tabIndex={105} index="1" />
          </td>
        </tr>
        <tr>
          <th>隱藏已離職</th>
          <td>
            <RptRankFilterRadioField state={state} setState={setState} fieldName="isHideLeaved" label="隱藏已離職的營業員" value="__YES__" tabIndex={106} index="1" />
            <RptRankFilterRadioField state={state} setState={setState} fieldName="isHideLeaved" label="顯示全部" value="__NO__" tabIndex={107} index="1" />
          </td>
        </tr>
      </tbody >
    </table >
    <div className="mt-2 mb-3" style={{ textAlign: "right" }}>
      <Button startIcon={<VisibilityIcon />} size="small" variant="contained" disableElevation onClick={(_) => { setPreview(true); }}>{qtyReceivers ? `檢視 (共 ${qtyReceivers} 位收件人)` : `檢視`}</Button>
    </div>
  </div>;
}


function RptRankFilterRadioField({ state, setState, fieldName, label, value, index, tabIndex }) {
  const name = `filter_${fieldName}_${index}`;
  const id = `${name}_${value}_${index}`;
  return <span className="mr-1 ml-1">
    <input className="mr-1" type="radio" tabIndex={tabIndex} value={value} id={id} name={name} onChange={(e) => { setState({ ...state, [fieldName]: e.target.value, }); }} checked={state[fieldName] == value} />
    <label htmlFor={id}>{label}</label>
  </span>;
}
