import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { tableRateDetailUpdate } from '../../redux/bonusReducer';

import Button from '@material-ui/core/Button';
import { BranchSelection, TeamSelection } from '../Selections';
import MyDateField from '../MyDateField';

const useStyles = makeStyles((theme) => ({
    detail: {
        padding: "5px 40px 15px",
        backgroundColor: '#3997A3',
        border: '1px solid #156069',

        "& h3": {
            fontSize: '20px',
            fontWeight: 'bold',
            lineHeight: '32px',
        },

        "& table": {
            width: '100%',
        },

        "& th, & td": {
            padding: '0px 6px',
            textAlign: 'center',
            color: 'white',
        },
    },
}));

export default function TableRateDetailBonuses({ class1 }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { tableRateBonuses } = useSelector(state => {
        return state.bonus;
    });

    const bonuses = tableRateBonuses[class1];

    return (
        <div className={classes.detail}>
            <table>
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Target</th>
                        <th>&nbsp;</th>
                        <th>Bonus</th>
                    </tr>
                </thead>
                <tbody>
                    {bonuses.map((bonus, index) =>
                        <tr key={`bonus_${index}`}>
                            <td>{index + 1}</td>
                            <td>
                                <BonusInputField class1={class1} index={index} fieldName="Target" width={100} tabIndex={99} textAlign="right" />
                            </td>
                            <td>---</td>
                            <td>
                                <BonusInputField class1={class1} index={index} fieldName="Bonus" width={100} tabIndex={99} textAlign="right" />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}


function BonusInputField({ class1, index, fieldName, width, tabIndex, textAlign }) {
    const dispatch = useDispatch();
    const { tableRateBonuses } = useSelector(state => {
        return state.bonus;
    });

    const bonus = tableRateBonuses[class1][index];
    return <input value={!_.isNil(bonus[fieldName]) ? bonus[fieldName] : ''} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(tableRateDetailUpdate(class1, index, fieldName, e.target.value)); }} />
}