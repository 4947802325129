export default class SupervisorGroup {
    constructor({
        Id,
        Group,
        IsActive,
    }) {
        this.Id = Id;
        this.Group = Group;
        this.IsActive = IsActive;
    }

    isNew() {
        return !this.Id || (this.Id < 1);
    }

    getDesc() {
        if (this.isNew()) return "新主管拆賬表分組";
        return `${this.Id}. ${this.Group ? this.Group : "[新主管拆賬表分組]"}`;
    }
}
