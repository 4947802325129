import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { employeeDetailAdd, employeeDetailRemove, employeeDetailUpdate  } from '../../redux/employeeHistoryReducer';

import Button from '@material-ui/core/Button';
import { BranchSelection, TeamSelection } from '../Selections';
import MyDateField from '../MyDateField';

const useStyles = makeStyles((theme) => ({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& a.nav-link:hover h1": {
            color: "#006ed0",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },
    },
    detail: {
        "& table": {
            width: "100%",
            backgroundColor: "white",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
                textAlign: 'center',
            },

            "& th": {
                backgroundColor: "#FAF3DE",
            },
        },
    },
}));

export default function EmployeeDetailHistories() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { employeeHistoryEntities } = useSelector(state => {
        return state.employeeHistory;
    });

    const { teams } = useSelector(state => {
        return state.selections;
    });

    const handleTeamChange = (e, index) => {
        dispatch(employeeDetailUpdate(index, 'IdTeam', e.target.value));

        const team = _.find(teams, r => r.Id == e.target.value);
        dispatch(employeeDetailUpdate(index, 'Team', team?.Team1 ?? ""));
    };

    return (
        <div className={classes.detail}>
            <h1>員工過往記錄</h1>
            <table>
                <thead>
                    <tr>
                        <th>行</th>
                        <th>開始日期</th>
                        <th>完結日期</th>
                        <th>分行</th>
                        <th>組別</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                { employeeHistoryEntities.map((history, index) =>
                    <tr key={`history_${index}`}>
                        <td>{index + 1}</td>
                        <td><MyDateField dateValue={history.Fdate} onDateChange={(d) => { dispatch(employeeDetailUpdate(index, 'Fdate', d)); }} tabIndex={99} /></td>
                        <td><MyDateField dateValue={history.Tdate} onDateChange={(d) => { dispatch(employeeDetailUpdate(index, 'Tdate', d)); }} tabIndex={99} /></td>
                        <td><BranchSelection onChange={(e) => { dispatch(employeeDetailUpdate(index, 'IdBranch', e.target.value)); }} value={history.IdBranch} tabIndex={99} /></td>
                        <td><TeamSelection onChange={(e) => { handleTeamChange(e, index); }} value={history.IdTeam} tabIndex={99} /></td>
                        <td><Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { dispatch(employeeDetailRemove(index)); }}>移除</Button></td>
                    </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="6" style={{ textAlign:"left" }}>
                            <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { dispatch(employeeDetailAdd()); }}>新增員工過往記錄</Button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
}
