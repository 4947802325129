import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { branchHeadCountDetailUpdate } from '../../redux/branchHeadCountReducer';

export default function BranchHeadCountDetailInputField({ index, fieldName, width, tabIndex, textAlign }) {
    const dispatch = useDispatch();
    const { workingEntities } = useSelector(state => {
        return state.branchHeadCount;
    });

    return <input value={workingEntities[index][fieldName] ? workingEntities[index][fieldName] : ''} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(branchHeadCountDetailUpdate(index, fieldName, e.target.value)); }} />
}