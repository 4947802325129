import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { userDetail, userDetailUpdate, resetPassword } from '../../redux/userReducer';
import { showConfirm } from '../../redux/msgReducer';

import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import BeatLoader from "react-spinners/BeatLoader";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';

import UserDetailBottomButtons from './UserDetailBottomButtons';
import { DfnUserRoleSelection, BranchMultipleCheckbox } from '../Selections';

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },

  },
  detail: {
    "& table": {
      width: "100%",
      backgroundColor: "white",

      "& th, & td": {
        padding: "2px 6px",
        border: "1px solid #D5D5D5",
      },

      "& th": {
        backgroundColor: "#FAF3DE",
        textAlign: 'right',
      },
    },
  },
});

export default function UserDetail() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, workingEntity } = useSelector(state => {
    return state.user;
  });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  const { id } = useParams();
  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");

    dispatch(userDetail(id));
  }, [id]);

  const navClicked = (path) => {
    history.push(path);
  };

  const confirmResetPassword = (e) => {
    const fnConfirmed = (_) => { dispatch(resetPassword()); };
    dispatch(showConfirm("確定重設密碼為 12345 ？", fnConfirmed));
  };
  const branchIds = workingEntity?.BranchIds ?? "[]";
  const branchIdsSelected = (strIdBranch) => {
    dispatch(userDetailUpdate("BranchIds", strIdBranch));
  };

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/setting">
          <Typography variant="h1" className={classes.title}>10. 設定</Typography>
        </NavLink>
        <NavLink className="nav-link" tag={Link} to="/user/list">
          <Typography variant="h1" className={classes.title}>登入用戶</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : workingEntity !== null ? workingEntity.getDesc() : ""}</Typography>
      </Breadcrumbs>
      {workingEntity &&
        <div className={classes.detail}>
          <Grid container>
            <Button variant="contained" className="mr-4" size="small" disableElevation onClick={() => { navClicked("/user"); }}>返回列表</Button>
            <div style={{ flex: 1 }} />
            <Button variant="contained" size="small" disableElevation onClick={() => { dispatch(userDetail(id, true)); }}><RefreshIcon /></Button>
          </Grid>

          <table className="mt-2" style={{ width: '640px' }}>
            <tbody>
              <tr>
                <th style={{ width: '120px' }}>組別</th>
                <td>
                  <DfnUserRoleSelection showAny={false} value={workingEntity.DfnRole} onChange={(e) => { dispatch(userDetailUpdate("DfnRole", e.target.value)); }} tabIndex={1} />
                </td>
              </tr>
              {(((workingEntity?.DfnRole) ?? "") == "N") && // 分行文員
                <tr>
                  <th>相關分行</th>
                  <td>
                    <BranchMultipleCheckbox value={branchIds} onChange={branchIdsSelected} />
                    {branchIds !== "[]" && <IconButton size="small" onClick={e => { branchIdsSelected("[]"); }}>
                      <ClearIcon />
                    </IconButton>}
                  </td>
                </tr>
              }
              <tr><th>姓名</th><td>{workingEntity.isNew() ? <UserDetailInputField fieldName="Name" width={250} tabIndex={2} /> : workingEntity.Name}</td></tr>
              <tr><th>登入帳戶</th><td>{workingEntity.isNew() ? <UserDetailInputField fieldName="Login" width={250} tabIndex={2} /> : workingEntity.Login}</td></tr>
              <tr><th>電郵</th><td><UserDetailInputField fieldName="CcEmail" width={250} tabIndex={2} /> ※ 用於cc電郵</td></tr>
              <tr>
                <th>重設密碼</th>
                <td>
                  {!workingEntity.isNew() &&
                    <Button variant="contained" className="mr-4" size="small" onClick={confirmResetPassword}>重設密碼</Button>
                  }
                  ※ 預設密碼為 '12345'
                </td>
              </tr>
              <tr>
                <th>有效的</th>
                <td>
                  <UserDetailIsActiveRadioField fieldName="IsActive" label="有效的" value={true} selectedValue={workingEntity.IsActive ?? true} />
                  <UserDetailIsActiveRadioField fieldName="IsActive" label="已停用" value={false} selectedValue={workingEntity.IsActive ?? true} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }

      <div style={{ height: "10px" }}></div>

      <UserDetailBottomButtons />
    </>
  );
}


function UserDetailInputField({ fieldName, width, tabIndex, textAlign }) {
  const dispatch = useDispatch();
  const { workingEntity } = useSelector(state => {
    return state.user;
  });

  return <input value={workingEntity[fieldName] ? workingEntity[fieldName] : ''} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(userDetailUpdate(fieldName, e.target.value)); }} />
}


function UserDetailIsActiveRadioField({ fieldName, label, value, selectedValue }) {
  const dispatch = useDispatch();
  const name = `detail_${fieldName}`;
  const id = `${name}_${value}`;
  return <span className="mr-2" >
    <input type="radio" value={value} id={id} name={name} onChange={e => { dispatch(userDetailUpdate(fieldName, value)); }} checked={selectedValue == value} />
    <label htmlFor={id}>{label}</label>
  </span>;
}
