import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Moment from 'moment';
import _ from 'lodash';

import { openPdf, downloadXls, actionPdf, PreviewPdf } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";
import { SupervisorMultipleCheckbox, CcEmailMultipleCheckbox } from '../../Selections';

import Button from '@material-ui/core/Button';

import MyDateField from '../../MyDateField';

import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EmailIcon from '@material-ui/icons/Email';

const StyledTabs = withStyles({
  root: {
    padding: '0px',
    minHeight: 'auto',
  },
  indicator: {
    backgroundColor: '#f7de8c'
  }
})(Tabs);

const StyledTab = withStyles({
  root: {
    padding: '0px',
    minHeight: 'auto',
    lineHeight: '2rem',
    borderRadius: '8px 8px 0px 0px',
    border: '1px solid silver',
    backgroundColor: '#ebebeb',
  },
  selected: {
    backgroundColor: '#FAF3DE',
  },
})(Tab);

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
  frmFilter: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& th": {
      fontWeight: 'bold',
      border: "1px solid #B0B0B0",
      backgroundColor: "#FAF3DE",
      padding: "2px 4px",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      padding: "2px 4px",
    },

    "& label": {
      marginBottom: "0px",
    }
  },
});

export default function ReportSolicitors() {
  const classes = useStyles();
  const history = useHistory();

  // security
  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  const [state, setState] = useState({
    fDate: Moment.utc().add(Moment.utc().month() == 0 ? -1 : 0, 'Y').startOf('year'), // 如果係一月就上年
    tDate: Moment.utc().add(-1, 'M').endOf('month'),
    opt: "__TOTAL__",
    sorting: "__SUBTOTAL__",
    supervisorAdLogins: "[]",
    otherReceivers: "",
    ccEmails: "[]",
  });

  const { balEmployees } = useSelector(state => {
    return state.selections;
  })

  useEffect(() => {
    const allDistrictEmployees = _.filter(balEmployees, (e) => (e.AdLogin) && (!e.DateLeave) && (e.Role == "主管"));
    //console.log(allDistrictEmployees);

    setState(state => ({
      ...state,
      supervisorAdLogins: JSON.stringify(allDistrictEmployees.map(e => e.AdLogin))
    }));
  }, [balEmployees, state.supervisorAdLogins]);

  const filter = _.keys(state).map(key => ({
    property: key,
    value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
  }));

  const [tabValue, setTabValue] = React.useState(0); // tab
  const [preview, setPreview] = useState(false); // preview
  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null }); // dialog

  // PreviewPdf action
  const email = () => {
    const filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));

    actionPdf("/api/ReportSupervisorRank/EmailPDF", {
      filter: JSON.stringify(filter)
    }, msgState, msgSetState);
  };

  const { supervisorAdLogins, otherReceivers, ccEmails } = state;

  const qtyReceivers = JSON.parse(supervisorAdLogins).length + JSON.parse(ccEmails).length + _.filter(otherReceivers.split("\n"), (s) => s.indexOf("@") > 0).length;

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/report">
          <Typography variant="h1" className={classes.title}>7. 報告</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>7.13. 經理業績報告</Typography>
      </Breadcrumbs>

      <div style={{ width: '650px' }}>
        <StyledTabs
          value={tabValue}
          onChange={(_e, value) => {
            setTabValue(value);
          }}
          indicatorColor="primary"
        >
          <StyledTab disableRipple label="列 印" />
          <StyledTab disableRipple label="電 郵" />
        </StyledTabs>
        <SwipeableViews index={tabValue} >
          <TabPrint index={0} state={state} setState={setState} msgState={msgState} msgSetState={msgSetState} />
          <TabPreviewEmail index={1} state={state} setState={setState} setPreview={setPreview} />
        </SwipeableViews>
      </div >

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
      <PreviewPdf open={preview} setOpen={setPreview} url={"/api/ReportSupervisorRank/GetPDF"} params={{ filter: JSON.stringify(filter) }}>
        <Button startIcon={<EmailIcon />} className="mr-2" size="small" variant="contained" disabled={qtyReceivers <= 0} disableElevation onClick={email}>{qtyReceivers ? `電郵給 (${qtyReceivers} 位同事)` : `電郵`}</Button>
      </PreviewPdf>
    </>
  );
}

function TabPrint({ index, state, setState, msgState, msgSetState }) {
  const classes = useStyles();

  const print = () => {
    let filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    openPdf("/api/ReportSupervisorRank/GetPDF", {
      filter: JSON.stringify(filter)
    }, msgState, msgSetState);
  }

  const excel = () => {
    const filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    downloadXls("/api/ReportSupervisorRank/GetXlsx", {
      filter: JSON.stringify(filter)
    }, "經理業績報告.xlsx", msgState, msgSetState);
  }

  return <div index={index}>
    <table className={classes.frmFilter}>
      <tbody>
        <tr>
          <th>日　　期</th>
          <td>
            <MyDateField dateValue={state.fDate} onDateChange={(d) => { setState({ ...state, fDate: d?.format("YYYY-MM-DD") }); }} tabIndex={1} />
            &nbsp;至&nbsp;
            <MyDateField dateValue={state.tDate} onDateChange={(d) => { setState({ ...state, tDate: d?.format("YYYY-MM-DD") }); }} tabIndex={2} />
          </td>
        </tr>
        <tr>
          <th>選　　項</th>
          <td>
            <ReportSupervisorRankFilterRadioField state={state} setState={setState} fieldName="opt" label="只列印大數" value="__TOTAL__" index={index} />
            <ReportSupervisorRankFilterRadioField state={state} setState={setState} fieldName="opt" label="列印月份 Breakdown" value="__LANDSCAPE__" index={index} />
            <ReportSupervisorRankFilterRadioField state={state} setState={setState} fieldName="opt" label="列印月份及分行 Breakdown" value="__BREAKDOWN__" index={index} />
          </td>
        </tr>
        {
          (state.opt == "__TOTAL__" || state.opt == "__BREAKDOWN__") &&
          <tr>
            <th>排　　序</th>
            <td>
              <ReportSupervisorRankFilterRadioField state={state} setState={setState} fieldName="sorting" label="營業額" value="__SUBTOTAL__" index={index} />
              <ReportSupervisorRankFilterRadioField state={state} setState={setState} fieldName="sorting" label="平均數" value="__AVERAGE__" index={index} />
            </td>
          </tr>
        }
      </tbody >
    </table >

    <div className="mt-2 mb-3" style={{ textAlign: "right" }}>
      <Button startIcon={<PrintIcon />} className="mr-3" size="small" variant="contained" disableElevation onClick={(e) => { print(false); }}>列　印</Button>
      <Button startIcon={<DescriptionIcon />} size="small" variant="contained" disableElevation onClick={excel}>Excel</Button>
    </div>

    <br />

    <p>
      ※１：假如 <u>7.13. 經理業績報告</u> 的數字未能對應 <u>7.4. 營業報告（分行總結）</u>，請留意每個分行的 <u>業績經理</u> 是否保持 <strong>連逐</strong> 及 <strong>沒有重覆</strong>。
      你可以到 <Link style={{ color: 'blue', textDecoration: 'underline' }} to={`/branch/`}>10.1. 設定分行</Link> &nbsp; &gt; 「顯示業績經理」查閱。
    </p>

    <br />

    <p>
      ※２：假如分行在某時段並沒有 <u>業績經理</u>，請以 <strong>Manager Pool</strong> 設定屬下分行作對數用，這是它的連結：<Link style={{ color: 'blue', textDecoration: 'underline' }} to={`/supervisorDetail/26`}>3. 主管資料 &gt; 9001. Manager Pool (Adjustment)</Link>
    </p>
  </div>;
}

function TabPreviewEmail({ index, state, setState, setPreview }) {
  const classes = useStyles();

  const handleAdLoginChange = (strAdLogin) => {
    setState({
      ...state,
      supervisorAdLogins: strAdLogin
    });
  };

  const handleCcEmailChange = (strCcEmail) => {
    setState({
      ...state,
      ccEmails: strCcEmail,
    });
  };

  const { supervisorAdLogins, otherReceivers, ccEmails } = state;

  const qtyReceivers = JSON.parse(supervisorAdLogins).length + JSON.parse(ccEmails).length + _.filter(otherReceivers.split("\n"), (s) => s.indexOf("@") > 0).length;

  return <div index={index}>
    <table className={classes.frmFilter}>
      <tbody>
        <tr>
          <th>收件主管</th>
          <td>
            <SupervisorMultipleCheckbox value={state.supervisorAdLogins} onChange={handleAdLoginChange} tabIndex={102} isShowEmail={true} />
          </td>
        </tr>
        <tr>
          <th>CC 電郵</th>
          <td>
            <CcEmailMultipleCheckbox value={state.ccEmails} onChange={handleCcEmailChange} tabIndex={104} />
          </td>
        </tr>
        <tr>
          <th>CC 其他電郵</th>
          <td>
            <TextareaAutosize minRows={2} value={state.otherReceivers} style={{ width: 400 }} tabIndex={104} onChange={(e) => {
              let value = e.target.value;
              if (_.endsWith(value, "@cent")) {
                value = value.replaceAll("@cent", "@century21-goodwin.com");
                value = value.replaceAll("@century21-goodwin.comury21-goodwin.com", "@century21-goodwin.com");
              }
              setState({ ...state, otherReceivers: value });
            }} />
          </td>
        </tr>
        <tr>
          <th>日　　期</th>
          <td>
            <MyDateField dateValue={state.fDate} onDateChange={(d) => { setState({ ...state, fDate: d?.format("YYYY-MM-DD") }); }} tabIndex={1} />
            &nbsp;至&nbsp;
            <MyDateField dateValue={state.tDate} onDateChange={(d) => { setState({ ...state, tDate: d?.format("YYYY-MM-DD") }); }} tabIndex={2} />
          </td>
        </tr>
        <tr>
          <th>選　　項</th>
          <td>
            <ReportSupervisorRankFilterRadioField state={state} setState={setState} fieldName="opt" label="只列印大數" value="__TOTAL__" index={index} />
            <ReportSupervisorRankFilterRadioField state={state} setState={setState} fieldName="opt" label="列印月份 Breakdown" value="__LANDSCAPE__" index={index} />
            <ReportSupervisorRankFilterRadioField state={state} setState={setState} fieldName="opt" label="列印月份及分行 Breakdown" value="__BREAKDOWN__" index={index} />
          </td>
        </tr>
        {
          (state.opt == "__TOTAL__" || state.opt == "__BREAKDOWN__") &&
          <tr>
            <th>排　　序</th>
            <td>
              <ReportSupervisorRankFilterRadioField state={state} setState={setState} fieldName="sorting" label="營業額" value="__SUBTOTAL__" index={index} />
              <ReportSupervisorRankFilterRadioField state={state} setState={setState} fieldName="sorting" label="平均數" value="__AVERAGE__" index={index} />
            </td>
          </tr>
        }
      </tbody >
    </table >
    <div className="mt-2 mb-3" style={{ textAlign: "right" }}>
      <Button startIcon={<VisibilityIcon />} size="small" variant="contained" disableElevation onClick={(_) => { setPreview(true); }}>{qtyReceivers ? `檢視 (共 ${qtyReceivers} 位收件人)` : `檢視`}</Button>
    </div>
  </div>;
}

function ReportSupervisorRankFilterRadioField({ state, setState, fieldName, label, value, index, tabIndex }) {
  const name = `filter_${fieldName}_${index}`;
  const id = `${name}_${value}`;
  return <span className="mr-1 ml-1">
    <input className="mr-1" type="radio" value={value} id={id} name={name} onChange={(e) => { setState({ ...state, [fieldName]: e.target.value, }); }} checked={state[fieldName] == value} />
    <label htmlFor={id}>{label}</label>
  </span>;
}
