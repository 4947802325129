import _, { cloneDeep } from 'lodash';

import CommPrepaid from "../models/commPrepaid";

//================== Whole State ==================//
const initialState = {
    detailCommPrepaids: _.range(10).map(i => new CommPrepaid({})),
};


//================== Action Types ==================//
export const CommPrepaidActions = Object.freeze({
    DETAIL_COMM_PREPAIDS_UPDATE: Symbol("DETAIL_COMM_PREPAIDS_UPDATE"), 
});


//================== Action Creators ==================//
export const commPrepaidUpdate = (nth, fieldName, value) => {
    return (dispatch, getState) => {
        // copy idEmployee from detail owner comm share
        const { prepaid: { detailOwnerCommShares } } = getState();
        const { IdEmployee, Name } = detailOwnerCommShares[nth];

        dispatch({
            type: CommPrepaidActions.DETAIL_COMM_PREPAIDS_UPDATE,
            payload: { nth, fieldName, value, IdEmployee, Name },
        });
    }

}


//================== Reducer ==================//
const commPrepaidReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);

    switch (action.type) {
        case CommPrepaidActions.DETAIL_COMM_PREPAIDS_UPDATE:
            const { nth, fieldName: prepaidField, value: prepaidValue, IdEmployee, Name } = action.payload;
            _state.detailCommPrepaids[nth][prepaidField] = prepaidValue;
            _state.detailCommPrepaids[nth]["IdEmployee"] = IdEmployee;
            _state.detailCommPrepaids[nth]["Name"] = Name;
            return _state;

        default:
            return state;
    }
}

export default commPrepaidReducer;
