import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { commDetailUpdate } from '../../redux/commReducer';

export default function CommDetailInputField({ fieldName, width, tabIndex, textAlign, onBlur }) {
    const dispatch = useDispatch();
    const { workingEntity } = useSelector(state => {
        return state.comm;
    });

    return !onBlur ? <input value={workingEntity[fieldName] ? workingEntity[fieldName] : ''} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(commDetailUpdate(fieldName, e.target.value)); }} />
        : <input value={workingEntity[fieldName] ? workingEntity[fieldName] : ''} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onBlur={onBlur} onChange={(e) => { dispatch(commDetailUpdate(fieldName, e.target.value)); }} />;
}

export function CommDetailKeyDownloadInputField({ fieldName, width, tabIndex, textAlign, onKeyDown }) {
    const dispatch = useDispatch();
    const { workingEntity } = useSelector(state => {
        return state.comm;
    });

    return <input onKeyDown={onKeyDown} value={workingEntity[fieldName] ? workingEntity[fieldName] : ''} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(commDetailUpdate(fieldName, e.target.value)); }} />
}