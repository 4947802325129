import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { commDetail } from '../../redux/commReducer';
import { keyDownHandler } from '../../extensions/FormExtensions';

import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import BeatLoader from "react-spinners/BeatLoader";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import FieldsetCommInfo from './FieldsetCommInfo';
import FieldsetCommDetailShares, { ModalSetEmployee, ModalWarnDiff } from '../commShare/FieldsetCommDetailShares';
import ModalCalTool from '../commShare/ModalCalTool';
import FieldsetCommDetailTrans from '../commTran/FieldsetCommDetailTrans';
import FieldsetCommDetailBonus from '../commTran/FieldsetCommDetailBonus';
import FieldsetCommDetailReduce from './FieldsetCommDetailReduce';
import CommDetailBottomButtons from './CommDetailBottomButtons';

import ModalLookupAddress from '../commRef/ModalLookupAddress';
import { LocalModelMsg } from "../ModalMsg";

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
});

export default function CommDetail() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, workingEntity, entities } = useSelector(state => {
    return state.comm;
  });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  // msgState, msgSetState is used for openPdf
  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

  const [prevId, setPrevId] = useState(null);
  const [nextId, setNextId] = useState(null);

  const { id } = useParams();
  //console.log("id", id);
  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");

    dispatch(commDetail(id));

    const pos = entities && (entities.length > 1) && (_.findIndex(entities, function (e) { return e.Id == id; }));
    setPrevId((pos > 0) ? entities[pos - 1].Id : null);
    setNextId((pos < entities.length - 1) ? entities[pos + 1].Id : null);
  }, [id]);

  const navClicked = (path) => {
    history.push(path);
  };

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/comm/list">
          <Typography variant="h1" className={classes.title}>1. 成交佣金</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : workingEntity !== null ? workingEntity.getDesc() + "（上版）" : ""}</Typography>
      </Breadcrumbs>
      {workingEntity &&
        <div className="tabIndexedForm" onKeyDown={(e) => { keyDownHandler(e); }}>
          <Grid container>
            <Button variant="contained" className="mr-4" size="small" disableElevation onClick={() => { navClicked("/comm"); }}>返回列表</Button>
            <Button variant="contained" className="mr-2" size="small" disableElevation disabled><span style={{ color: 'rgb(223 0 183)' }}>佣金詳情（上版）</span></Button>
            <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { navClicked(`/commDetail2/${id}`); }}>佣金詳情（下版）</Button>
            <div style={{ flex: 1 }} />
            {prevId && <Button className="mr-2" variant="contained" size="small" disableElevation onClick={() => { history.push(`/commDetail/${prevId}`); }}><NavigateBeforeIcon /></Button>}
            <Button className="mr-2" variant="contained" size="small" disableElevation onClick={() => { dispatch(commDetail(id, true)); }}><RefreshIcon /></Button>
            {nextId && <Button variant="contained" size="small" disableElevation onClick={() => { history.push(`/commDetail/${nextId}`); }}><NavigateNextIcon /></Button>}
          </Grid>
          <FieldsetCommInfo msgState={msgState} msgSetState={msgSetState} />
          <FieldsetCommDetailShares />
          <FieldsetCommDetailTrans msgState={msgState} msgSetState={msgSetState} />
          <FieldsetCommDetailBonus />
          <FieldsetCommDetailReduce />
          <CommDetailBottomButtons msgSetState={msgSetState} />
        </div>
      }

      <ModalLookupAddress />
      <ModalSetEmployee />
      <ModalCalTool />
      <ModalWarnDiff />

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
    </>
  );
}

