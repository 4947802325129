import React, { useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Moment from 'moment';
import _ from 'lodash';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { branchHeadCountLookup, branchHeadCountFetchPage, branchHeadCountPageUpdate } from '../../redux/branchHeadCountReducer';

import BranchHeadCountFilter from './BranchHeadCountFilter';
import BranchHeadCountList from './BranchHeadCountList';
import Pagination from '../Pagination';

const useStyles = makeStyles({
  title: {
    paddingBottom: "15px",
    fontSize: "26px",
    lineHeight: "26px",
    fontWeight: "bold",
  },
});


export default function BranchHeadCount() {
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useDispatch();

  const { hasFilter } = useParams();

  const { filter } = useSelector(state => {
    return state.branchHeadCount;
  });

  const filters = JSON.parse(filter.filter);
  const _getFilterValue = (field) => {
    const i = _.findIndex(filters, function (o) { return o.property == field; });
    return (i >= 0) ? filters[i].value : "";
  };

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  useEffect(() => {
    dispatch(branchHeadCountLookup(hasFilter ? filter : null));
  }, []);

  const newBranchHeadCounts = () => {
    const _calDate = _getFilterValue("cal_date");
    const calDate = _calDate ? Moment.utc(_calDate).format("YYYY-MM-01") : null;
    //console.log('calDate', calDate);

    history.push(calDate ? `/branchHeadCountDetails/0/${calDate}` : "/branchHeadCountDetails/0");
  }

  return (
    <div>
      <Typography variant="h1" className={classes.title}>4. 分行人數</Typography>
      <Grid container className="mb-2" spacing={2}>
        <Grid item>
          <Button variant="contained" size="small" onClick={newBranchHeadCounts}>新增分行人數</Button>
        </Grid>
        {/*    <Grid item>*/}
        {/*        <Button variant="contained" size="small" onClick={newBranchHeadCount}>新增分行人數 (單一記錄)</Button>*/}
        {/*    </Grid>*/}
      </Grid>
      <BranchHeadCountFilter filter={filter} />
      <Pagination filter={filter} fnFetchPage={branchHeadCountFetchPage} fnPageUpdate={branchHeadCountPageUpdate} />
      <BranchHeadCountList />
      <div style={{ margin: "5px", textAlign: 'center' }}>
        <Pagination filter={filter} fnFetchPage={branchHeadCountFetchPage} fnPageUpdate={branchHeadCountPageUpdate} />
      </div>

    </div>
  );
}
