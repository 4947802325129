import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';
import Moment from 'moment';

import { prepaidChangeSort } from '../../redux/prepaidReducer';
import ReactMoment from "react-moment";

import BeatLoader from "react-spinners/BeatLoader";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Button from '@material-ui/core/Button';

import { openPdf } from '../../extensions/FileExtensions';
import MyCurrency from '../MyCurrency';

const useStyles = makeStyles({
    tblList: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& thead": {
            backgroundColor: "#c5e2ff",
            color: "#45525c",
        },

        "& tr": {
            cursor: "pointer",
        },

        "& tr.alt": {
            backgroundColor: "#f7fbff",
        },

        "& tr.lastWorkingEntity": {
            border: "2px solid #da59da",
        },

        "& tbody tr:hover, tbody tr.alt:hover": {
            backgroundColor: "#ffffcc !important",
        },

        "& th": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },
    },
});

export default function PrepaidList() {
    const classes = useStyles();

    const history = useHistory();
    const dispatch = useDispatch();

    const { isLoading, filter, entities, workingEntityId, errors } = useSelector(state => {
        return state.prepaid;
    });
    //console.log('filter', filter);

    // for fitting print commReport
    const [msgState, msgSetState] = useState({ isShow : false, type : "", msg : "", fnConfirmed : null });

    const sorts = JSON.parse(filter.sort);

    const _getSortDirection = (field) => {
        const i = _.findIndex(sorts, function (o) { return o.property == field; });
        return (i >= 0) ? sorts[i].direction : "";
    };

    const changeSort = (field) => {
        dispatch(prepaidChangeSort(field, filter));
    };

    const showDetail = (id) => {
        history.push(`/prepaidDetail/${id}`);
    }

    const myRef = useRef(null);
    useEffect(() => {
        if (myRef.current) myRef.current.scrollIntoView({ block: "center" });
    }, []);

    const printEmployees = (DateReport, IdEmployee) => {
        // simualte ReportComm.printEmployees
        const params = {
            "calMonth": Moment(DateReport).format("YYYY-MM-DD"),
            "autoDate": "__AUTO__", // get the commissions that is not settled or paid this month
            "outStanding": "__OUTSTANDING__", // seems override by autoDate = customize + fDate, tDate
            "isShowPrepaid": "__YES__", // always shows prepaid since where we are
            "employeeIds": [IdEmployee]
        };

        // call the same api
        openPdf("/api/ReportComm/GetPDF", params, msgState, msgSetState);
    }

    return (
        <table className={classes.tblList} >
            <thead>
                <tr>
                    <th onClick={(_) => changeSort('date_report')}><span>報表日期</span><MyArrowIcon direction={_getSortDirection("date_report")} /></th>
                    <th onClick={(_) => changeSort('id_employee')}><span>員工編號</span><MyArrowIcon direction={_getSortDirection("id_employee")} /></th>
                    <th onClick={(_) => changeSort('name')}><span>員工姓名</span><MyArrowIcon direction={_getSortDirection("name")} /></th>
                    <th onClick={(_) => changeSort('alias')}><span>員工別名</span><MyArrowIcon direction={_getSortDirection("alias")} /></th>
                    <th onClick={(_) => changeSort('hidden_adjustment')}><span>修正金額</span><MyArrowIcon direction={_getSortDirection("hidden_adjustment")} /></th>
                    <th onClick={(_) => changeSort('prepaid_comm')}><span>預支佣金</span><MyArrowIcon direction={_getSortDirection("prepaid_comm")} /></th>
                    <th>&nbsp;</th>
                </tr>
            </thead>

            {isLoading
                ?
                <tbody>
                    <tr>
                        <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
                    </tr>
                </tbody>
                :
                    (errors !== "")
                        ?
                        <tbody>
                            <tr>
                                <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
                            </tr>
                        </tbody >
                        :
                        <tbody>
                            {
                                entities.map((entity, index) => {
                                    return <tr key={entity.Id} ref={workingEntityId == entity.Id ? myRef : null} className={clsx({ "lastWorkingEntity": workingEntityId == entity.Id, "alt": index % 2 == 1, "normal": index % 2 == 0 })} onClick={() => { showDetail(entity.Id) }}>
                                        <td>{entity.DateReport && <ReactMoment format="YYYY-MM-DD" date={entity.DateReport} />}</td>
                                        <td>{entity.IdEmployee}</td>
                                        <td>{entity.Name}</td>
                                        <td>{entity.Alias}</td>
                                        <td><MyCurrency value={entity.HiddenAdjustment} /></td>
                                        <td><MyCurrency value={entity.PrepaidComm} /></td>
                                        <td><Button size="small" onClick={(e) => { e.stopPropagation(); printEmployees(entity.DateReport, entity.IdEmployee); }} >糧單</Button></td>
                                    </tr>
                                })
                            }
                        </tbody>
            }
        </table>
    );
}

function MyArrowIcon({ direction }) {
    switch (direction) {
        case "ASC": return <ArrowDropUpIcon />;
        case "DESC": return <ArrowDropDownIcon />;
        default: return null;
    }
}
