import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { tableRateFilterUpdate, tableRateFilterClear, tableRateLookup } from '../../redux/tableRateReducer';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import MyToolTip from '../MyToolTip';

import { TeamSelection, SupervisorSelection } from '../Selections';
import MyDateField from '../MyDateField';

const useStyles = makeStyles({
    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& input, & select": {
            padding: "0px 1px",
            fontSize: "13px",
        },

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
            textAlign: "right",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },

        "& label": {
            margin: 0,
        }
    },
});

export default function TableRateFilter({ filter }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const filters = JSON.parse(filter.filter);

    const _getFilterValue = (field) => {
        const i = _.findIndex(filters, function (o) { return o.property == field; });
        return (i >= 0) ? filters[i].value : "";
    };

    const typeFilter = _getFilterValue("type");

    const filterChange = (field, value) => {
        dispatch(tableRateFilterUpdate(field, value));
    };

    const clearFilter = () => {
        dispatch(tableRateFilterClear());
    };

    const lookup = () => {
        dispatch(tableRateLookup(filter));
    }

    return (
        <div style={{ width: '680px' }}>
            <table className={`${classes.frmFilter} mb-2`}>
                <tbody>
                    <tr>
                        <th style={{ width: '120px' }}>類　　型：</th>
                        <td style={{ width: '560px' }} colSpan={typeFilter == "__ANY__" ? 1: 3}>
                            <TableRateIsSupervisorRadioField selectedValue={typeFilter} fieldName="type" label="全部" value="__ANY__" />
                            <TableRateIsSupervisorRadioField selectedValue={typeFilter} fieldName="type" label="組別" value="__TEAM__" />
                            <TableRateIsSupervisorRadioField selectedValue={typeFilter} fieldName="type" label="主管組別" value="__SUPERVISOR_GROUP__" />
                            <TableRateIsSupervisorRadioField selectedValue={typeFilter} fieldName="type" label="主管(個人)" value="__SUPERVISOR__" />
                            <MyToolTip message={<span>在計算某月份的 <b style={{ color: 'red' }}>主管佣金</b> 時，假如該月份找到 <b style={{ color: 'red' }}>主管(個人)</b> 的拆賬表，會先以該拆賬表計算應收佣金。<br />否則按照主管在該月份的<b>組別</b>，及主管在該月份的<b>屬下分行數目</b>，找出 <b style={{ color: 'red' }}>主管組別及分行數目</b> 的拆賬表計算應收佣金</span>} />
                        </td>
                    </tr>
                    <tr>
                        {typeFilter == "__TEAM__" && <th>組　　別：</th>}
                        {typeFilter == "__TEAM__" && <td><TeamSelection role="營業員" showAny={true} onChange={(e) => { filterChange('id_team', e.target.value); }} value={_getFilterValue('id_team')} tabIndex={3} /></td>}
                        {typeFilter == "__SUPERVISOR_GROUP__" && <th>主管組別：</th>}
                        {typeFilter == "__SUPERVISOR_GROUP__" && <td><TeamSelection role="主管" showAny={true} onChange={(e) => { filterChange('id_team', e.target.value); }} value={_getFilterValue('id_team')} tabIndex={3} /></td>}
                        {typeFilter == "__SUPERVISOR__" && <th>主管(個人)：</th>}
                        {typeFilter == "__SUPERVISOR__" && <td><SupervisorSelection showAny={true} onChange={(e) => { filterChange('id_employee', e.target.value); }} value={_getFilterValue('id_employee')} tabIndex={3} /></td>}
                        <th style={{ width: '120px' }}>計算日期：</th>
                        <td style={{ width:'120px'}}>
                            <MyDateField dateValue={_getFilterValue("cal_date")} onDateChange={(d) => { filterChange('cal_date', d); }} tabIndex={4} />
                        </td>
                    </tr>
                </tbody>
            </table>
            <Grid container justifyContent="flex-end" spacing={2} className="mb-2">
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { lookup(); }}>搜　尋</Button>
                </Grid>
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { clearFilter(); }}>清　除</Button>
                </Grid>
            </Grid>
        </div>
    );
}

function TableRateIsSupervisorRadioField({ fieldName, label, value, selectedValue }) {
    const dispatch = useDispatch();
    const name = `filter_${fieldName}`;
    const id = `${name}_${value}`;
    return <span className="mr-2" >
        <input type="radio" value={value} id={id} name={name} onChange={e => { dispatch(tableRateFilterUpdate(fieldName, value)); }} checked={selectedValue == value} />
        <label htmlFor={id}>{label}</label>
    </span>;
}
