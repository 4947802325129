import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';

import TableRate from '../models/tableRate';
import {
  tableRateDetail as tableRateDatailBars,
  saveEntitiesByTableRate as barSaveEntitiesByTableRate,
  deleteEntitiesByTableRate as barDeleteEntitiesByTableRate,
} from './barReducer';
import {
  tableRateDetail as tableRateDatailBonuses,
  saveEntitiesByTableRate as bonusSaveEntitiesByTableRate,
  deleteEntitiesByTableRate as bonusDeleteEntitiesByTableRate,
} from './bonusReducer';

import { showLoading, showInfo, showErrorResponse, clearMsg } from './msgReducer';

//================== Whole State ==================//
let initialCalDate = new Date();

const initialState = {
  isLoading: false,
  isFilterUpdated: false,
  filter: { // match EntitiesResult<T>
    allRecords: 0,
    matchedRecords: 0,
    totalRecords: 0,
    qtyPerPage: 100,
    page: 1,
    row: 0,
    filter: JSON.stringify([{ "property": "type", "value": "__ANY__" }, { "property": "cal_date", "value": initialCalDate }]),
    sort: JSON.stringify([{ "property": "name", "direction": "ASC" }]),
    success: true,
    message: '',
  },
  entities: [],
  workingEntityId: -1,
  workingEntity: null,
  referenceEntity: null,
  errors: '',
  supervisorDetailId: -1,
  supervisorDetailEntities: [],
  teamDetailId: -1,
  teamDetailEntities: []
};


//================== Action Types ==================//
export const TableRateActions = Object.freeze({
  // filter
  FILTER_UPDATE: Symbol("FILTER_UPDATE"),
  CHANGE_SORT: Symbol("CHANGE_SORT"),
  PAGE_UPDATE: Symbol("PAGE_UPDATE"),
  FETCH_PAGE: Symbol("FETCH_PAGE"),

  // listing
  REQUEST: Symbol("REQUEST"),
  SUCCESS: Symbol("SUCCESS"),
  FAILURE: Symbol("FAILURE"),

  // detail
  DETAIL_ID: Symbol("DETAIL_ID"),
  DETAIL_SUCCESS: Symbol("DETAIL_SUCCESS"), // ajax loaded
  DETAIL_FAILURE: Symbol("DETAIL_FAILURE"),
  DETAIL_DELETED: Symbol("DETAIL_DELETED"), // ajax delete

  DETAIL_UPDATE: Symbol("DETAIL_UPDATE"), // form fields update

  REFERENCE_OLD_TABLE_RATE: Symbol("REFERENCE_OLD_TABLE_RATE"),

  SUPERVISOR_DETAIL_SUCCESS: Symbol("SUPERVISOR_DETAIL_SUCCESS"),
  TEAM_DETAIL_SUCCESS: Symbol("TEAM_DETAIL_SUCCESS"),
});


//================== Action Creators ==================//
export const tableRateFilterUpdate = (field, value) => {
  return {
    type: TableRateActions.FILTER_UPDATE,
    payload: {
      field: field,
      value: value
    }
  }
}

const _setWorkingEntityId = (id) => {
  return {
    type: TableRateActions.DETAIL_ID,
    payload: id,
  }
}

export const tableRateFilterClear = (history) => {
  return (dispatch) => {
    dispatch(_setWorkingEntityId(initialState.workingEntityId));
    dispatch(tableRateLookup(initialState.filter, history));
  }
}

export const tableRateChangeSort = (field) => {
  return (dispatch, getState) => {
    dispatch({
      type: TableRateActions.CHANGE_SORT,
      payload: field,
    });
    const { tableRate: { filter } } = getState(); // state updated
    //console.log(filter);
    dispatch(tableRateLookup(filter));
  };
}

export const tableRatePageUpdate = (page) => {
  return (dispatch, getState) => {
    const { tableRate: { filter } } = getState(); // state updated

    let _page = 1;

    if (page == "") {
      _page = "";
    } else {
      _page = parseInt(page);
      if (_.isNaN(_page)) return;
      if (_page < 1) _page = 1;
      if (_page > Math.ceil(filter.matchedRecords / filter.qtyPerPage)) _page = Math.ceil(filter.matchedRecords / filter.qtyPerPage);
    }

    dispatch({
      type: TableRateActions.FETCH_PAGE,
      payload: _page,
    });
  };
}

export const tableRateFetchPage = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: TableRateActions.FETCH_PAGE,
      payload: page,
    });
    const { tableRate: { filter } } = getState(); // state updated
    //console.log(filter);
    dispatch(tableRateLookup(filter));
  };
}

const _tableRateRequest = () => {
  return {
    type: TableRateActions.REQUEST
  }
}

const _tableRateSuccess = (data) => {
  return {
    type: TableRateActions.SUCCESS,
    payload: data,
  }
}

const _tableRateFailure = (response) => {
  return {
    type: TableRateActions.FAILURE,
    payload: response,
  }
}

export const tableRateLookup = (formData) => {
  const _formData = formData ? formData : initialState.filter;
  //console.log(_formData);
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/TableRate/GetEntities`;

  return (dispatch, getState) => {
    dispatch(_tableRateRequest());

    const { tableRate } = getState(); // state updated
    if (tableRate.isFilterUpdated) _formData.page = 1; // reset page if filter changed
    if (tableRate.filter.page == "") _formData.page = 1; // reset page if filter changed

    axios.post(apiEndpoint, _formData, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_tableRateSuccess(response.data));
        } else {
          dispatch(_tableRateFailure("未能讀取"));
        }

        return response;
      })
      .catch(error => {
        dispatch(_tableRateFailure("未能讀取"));
        dispatch(showErrorResponse(error));
      })
  }
}

const _tableRateDetailSuccess = (data) => {
  return {
    type: TableRateActions.DETAIL_SUCCESS,
    payload: data,
  }
}

const _tableRateDetailFailure = (response) => {
  return (dispatch, getState) => {
    dispatch({
      type: TableRateActions.DETAIL_FAILURE,
      payload: response,
    })
  }
}

export const tableRateDetail = (id, idEmployee, idTeam, isForce) => {
  return (dispatch, getState) => {
    const { tableRate: { workingEntityId: lastWorkingEntityId } } = getState();

    const key = `${id}_${idEmployee !== "0" ? idEmployee : "_"}_${idTeam !== "0" ? idTeam : "_"}`;
    if (!isForce && lastWorkingEntityId == key) return; // same entity

    dispatch(_setWorkingEntityId(key)); // 任何 dispatch 之前先記住 current working entity id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/TableRate/GetEntity`;

    dispatch(_tableRateRequest());

    axios.post(apiEndpoint, { id }, {
      timeout: 30000,
    })
      .then(response => {
        dispatch(clearMsg());

        if (response.data) {
          dispatch(_tableRateDetailSuccess(idEmployee !== undefined && idEmployee !== "0" ? { ...response.data, IsSupervisor: true, IdEmployee: idEmployee } : idTeam !== undefined && idTeam !== "0" ? { ...response.data, IsSupervisor: false, IdTeam: idTeam } : response.data));
          dispatch(tableRateDatailBars(false)); // specified is using workingEntity
          dispatch(tableRateDatailBonuses(false)); // specified is using workingEntity
        } else {
          dispatch(_tableRateDetailFailure("未能讀取"));
        }
      })
      .catch(error => {
        dispatch({ type: TableRateActions.DETAIL_FAILURE });
        dispatch(showErrorResponse(error));
      })
  };
}

export const tableRateDetailUpdate = (field, value) => {
  return {
    type: TableRateActions.DETAIL_UPDATE,
    payload: { field, value },
  };
}

const _referenceOldTableRate = (data) => {
  return {
    type: TableRateActions.REFERENCE_OLD_TABLE_RATE,
    payload: data,
  }
}

export const referecneOldTableRate = (IdEmployee, IdTeam, QtySuperviseTeam) => {
  return (dispatch, getState) => {
    if (!IdEmployee && !IdTeam) return;

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/TableRate/ReferenceOldTableRate`;

    dispatch(_tableRateRequest());

    axios.post(apiEndpoint, { IdEmployee, IdTeam, QtySuperviseTeam }, {
      timeout: 30000,
    })
      .then(response => {
        dispatch(clearMsg());

        if (response.data) {
          dispatch(_referenceOldTableRate(response.data));
          dispatch(tableRateDatailBars(true)); // specified is using referneceEntity
          dispatch(tableRateDatailBonuses(true)); // specified is using referneceEntity
        } else {
          dispatch(_tableRateDetailFailure("未能讀取"));
        }
      })
      .catch(error => {
        dispatch({ type: TableRateActions.DETAIL_FAILURE });
        dispatch(showErrorResponse(error));
      })
  };
}

export const supervisorDetailTableRates = () => {
  return (dispatch, getState) => {
    const { supervisor: { workingEntity: supervisor } } = getState();
    if (!supervisor) return;

    //console.log(supervisor);

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/TableRate/GetTableRatesWithSupervisor`;

    axios.post(apiEndpoint, supervisor, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch({ type: TableRateActions.SUPERVISOR_DETAIL_SUCCESS, payload: response.data });
        }
      })
      .catch(error => {
        dispatch(showErrorResponse(error));
      })
  };
}

export const teamDetailTableRates = () => {
  return (dispatch, getState) => {
    const { team: { workingEntity: team } } = getState();
    if (!team) return;

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/TableRate/GetTableRatesWithTeam`;

    axios.post(apiEndpoint, team, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch({ type: TableRateActions.TEAM_DETAIL_SUCCESS, payload: response.data });
        }
      })
      .catch(error => {
        dispatch(showErrorResponse(error));
      })
  };
}

export const saveEntity = (history) => {
  return (dispatch, getState) => {
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/TableRate/SaveEntity`;

    const { tableRate: { workingEntity } } = getState();

    dispatch(showLoading());

    axios.post(apiEndpoint, workingEntity,
      {
        timeout: 30000,
      })
      .then(response => {
        return Promise.all([
          barSaveEntitiesByTableRate(response.data, getState),
          bonusSaveEntitiesByTableRate(response.data, getState),
        ]).then(values => {
          return response;
        });
      })
      .then(response => {
        if (history.location.pathname == "/tableRateDetail/0") {
          history.replace(`/tableRateDetail/${response.data.Id}`);
          dispatch(showInfo("已保存"));
          return;
        }

        dispatch(_tableRateDetailSuccess(response.data));
        dispatch(tableRateDatailBars(false)); // specified is using workingEntity
        dispatch(tableRateDatailBonuses(false)); // specified is using workingEntity

        const { tableRate: { filter } } = getState();
        dispatch(tableRateLookup(filter));

        dispatch(showInfo("已保存"));
      })
      .catch(error => {
        dispatch({ type: TableRateActions.DETAIL_FAILURE });
        dispatch(showErrorResponse(error));
      })
  };
}

export const deleteEntity = (history) => {
  return (dispatch, getState, x) => {
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/TableRate/DeleteEntity`;

    const { tableRate: { workingEntity } } = getState();

    dispatch(showLoading());
    dispatch(_tableRateRequest());

    axios.post(apiEndpoint, workingEntity, {
      timeout: 30000,
    })
      .then(response => {
        return Promise.all([
          barDeleteEntitiesByTableRate(getState),
          bonusDeleteEntitiesByTableRate(getState),
        ]).then(values => {
          return response;
        });
      })
      .then(response => {
        const { vSupervisor: { filter } } = getState();

        dispatch(clearMsg());
        history.push('/tableRate');
        dispatch(tableRateLookup(filter));
      })
      .catch(error => {
        dispatch({ type: TableRateActions.DETAIL_FAILURE });
        dispatch(showErrorResponse(error));
      })
  };
}


//================== Reducer ==================//
const tableRateReducer = (state = initialState, action) => {
  let _state = cloneDeep(state);

  switch (action.type) {
    case TableRateActions.FILTER_UPDATE:
      const { field: filterField, value } = action.payload;
      let filters = JSON.parse(state.filter.filter);
      const iFilter = _.findIndex(filters, function (o) { return o.property == filterField; });
      if (iFilter >= 0) {
        if (value) filters[iFilter].value = value;
        else filters.splice(iFilter, 1);
      } else {
        filters.push({ "property": filterField, "value": value });
      }

      _state.filter.filter = JSON.stringify(filters);
      _state.isFilterUpdated = true;
      //console.log('_state.filter', _state.filter.filter);
      return _state;

    case TableRateActions.CHANGE_SORT:
      const field = action.payload;
      let sorts = JSON.parse(state.filter.sort);
      const i = _.findIndex(sorts, function (o) { return o.property == field; });
      if (i >= 0) {
        sorts[i].direction = sorts[i].direction == "ASC" ? "DESC" : "ASC";
      } else {
        // single sort only
        sorts = [{ "property": field, "direction": "ASC" }];
      }

      _state.filter.sort = JSON.stringify(sorts);
      //console.log('_state.filter.sort', _state.filter.sort);

      tableRateLookup(_state.filter);
      return _state;

    case TableRateActions.PAGE_UPDATE:
      _state.filter.page = action.payload;
      return _state;

    case TableRateActions.FETCH_PAGE:
      _state.filter.page = action.payload;
      return _state;

    case TableRateActions.REQUEST:
      _state.isLoading = true;
      return _state

    case TableRateActions.SUCCESS:
      _state.isLoading = false;
      _state.filter = {
        allRecords: action.payload.allRecords,
        matchedRecords: action.payload.matchedRecords,
        totalRecords: action.payload.totalRecords,
        qtyPerPage: action.payload.qtyPerPage,
        page: action.payload.page,
        row: action.payload.row,
        filter: action.payload.filter,
        sort: action.payload.sort,
        success: action.payload.success,
        message: action.payload.message,
      };
      _state.entities = action.payload.entities.map(entity => new TableRate(entity));
      _state.errors = "";
      _state.isFilterUpdated = false;
      //console.log(_state.entities.map(entity => entity.getType()));
      return _state;

    case TableRateActions.FAILURE:
      _state.isLoading = false;
      _state.errors = action.payload;
      _state.entities = [];
      return _state;

    case TableRateActions.DETAIL_ID:
      _state.workingEntityId = action.payload;
      return _state;

    case TableRateActions.DETAIL_SUCCESS:
      _state.isLoading = false;
      _state.workingEntity = new TableRate(action.payload);
      _state.workingEntityId = _state.workingEntity.Id;
      return _state;

    case TableRateActions.DETAIL_FAILURE:
      _state.isLoading = false;
      _state.workingEntityErrors = action.payload;
      //console.log(_state.errors);
      return _state;

    case TableRateActions.DETAIL_UPDATE:
      const { field: updateField, value: updateValue } = action.payload
      _state.workingEntity[updateField] = updateValue;

      //console.log('_state.workingEntity', _state.workingEntity);

      //switch (updateField) {
      //    case "IsSupervisor":
      //        if (updateValue == true) _state.workingEntity.IdTeam = null;
      //        if (updateValue == false) _state.workingEntity.IdEmployee = null;
      //        break;
      //}
      return _state;

    case TableRateActions.REFERENCE_OLD_TABLE_RATE:
      _state.isLoading = false;
      _state.referenceEntity = new TableRate(action.payload);
      return _state;

    case TableRateActions.SUPERVISOR_DETAIL_SUCCESS:
      _state.supervisorDetailEntities = action.payload.map(tr => new TableRate(tr));
      return _state;

    case TableRateActions.TEAM_DETAIL_SUCCESS:
      _state.teamDetailEntities = action.payload.map(tr => new TableRate(tr));
      return _state;

    default:
      return state;
  }
}

export default tableRateReducer;

