import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _, { cloneDeep } from 'lodash';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import { solicitorsRefLookup, solicitorsRefChangeSort, solicitorsRefFetchPage, solicitorsRefPageUpdate } from '../../redux/solicitorsRefReducer';

import SolicitorsRefFilter from './SolicitorsRefFilter';
import Pagination from '../Pagination';
import SolicitorsRefList from './SolicitorsRefList';

const useStyles = makeStyles({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },

    },
});

export default function SolicitorsRef() {
    const classes = useStyles();

    const history = useHistory();

    const dispatch = useDispatch();

    const newSolicitorsRef = () => {
        history.push("/solicitorsRefDetail/0");
    }

    const { isLoading, filter, entities, workingEntityId, errors } = useSelector(state => {
        return state.solicitorsRef;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");

        dispatch(solicitorsRefLookup());
    }, []);

    const sorts = JSON.parse(filter.sort);

    return (
        <>
            <Breadcrumbs className={classes.title}>
                <NavLink className="nav-link" tag={Link} to="/setting">
                    <Typography variant="h1" className={classes.title}>10. 設定</Typography>
                </NavLink>
                <Typography variant="h1" className={classes.title}>參考律師樓</Typography>
            </Breadcrumbs>
            <Grid container justifyContent="space-between" className="mb-2">
                <Grid item>
                    <Button variant="contained" size="small" onClick={newSolicitorsRef}>新增參考律師樓</Button>
                </Grid>
            </Grid>

            <SolicitorsRefFilter filter={filter} />
            <Pagination filter={filter} fnFetchPage={solicitorsRefFetchPage} fnPageUpdate={solicitorsRefPageUpdate} />

            <SolicitorsRefList />

            <Pagination filter={filter} fnFetchPage={solicitorsRefFetchPage} fnPageUpdate={solicitorsRefPageUpdate} />
        </>
    );
}
