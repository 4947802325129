import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Moment from 'moment';

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import {
  credCalMonthLookup, credEmployeeLookup, credSupervisorLookup, credBranchLookup
} from '../redux/selectionsReducer';

export function UserSelection({ showAny, value, onChange, tabIndex, name }) {
  const { users } = useSelector(state => {
    return state.selections;
  });

  return <select name={name} onChange={onChange} value={value ? value : ""} tabIndex={tabIndex}>
    <option key={0} value="">{showAny && "--- 全部 ---"}</option>
    {
      users.map((u) => {
        return <option key={u.Id} value={u.Id}>{u.Name}</option>
        //return <option key={u.Id} value={u.Id}>{u.User1}{.Tdate !== null && " (已結束)"}</option>
      })
    }
  </select>
}

export function EmployeeSelection({ showAny, value, onPicked, size, tabIndex, width, name, autoFocus, isShowBalEmployeesOnly = true }) {
  const { balEmployees, employees } = useSelector(state => {
    return state.selections;
  });

  //console.log('balEmployee', _.find(balEmployees, function (_employee) { return _employee.Id == 1364 }));
  //console.log('employee', _.find(employees, function (_employee) { return _employee.Id == 1364 }));

  const [isShowingAll, setIsShowingAll] = useState(false);

  const isNotLeavedEmployees = useMemo(() => { // heavy loading
    return _.filter(balEmployees, function (e) {
      const isOnTheJob = (!e.DateLeave || Moment(e.DateLeave).isAfter(new Moment.utc()));
      return isOnTheJob;
    });
  }, [balEmployees]);

  if (autoFocus == undefined) autoFocus = true;
  if (width == undefined) width = '100%';
  if (onPicked == undefined) onPicked = (employee) => { console.log('onPicked', employee); };

  if (tabIndex == undefined) tabIndex = 999;

  const [_value, _setValue] = useState(value);

  // 預設 size = 1
  const _size = size == undefined ? 1 : size;

  // size > 1 
  const onChange = (event) => {
    const idEmployee = event.target.value;
    _setValue(event.target.value);

    if (_size > 1) return; // size > 1 = listing: not onPicked, must triggered by enter or click

    if (!idEmployee) {
      onPicked(null);
      return;
    }

    const employee = _.find(employees, function (_employee) { return _employee.Id == event.target.value });
    onPicked(employee);
    //console.log(employee);
  }

  // size <= 1
  const onClick = (employee) => {
    if (_size <= 1) return; // size <= 1, 唔應用 option.onClick, 應用 onChange

    onPicked(employee);
    //console.log(employee);
  }

  const onKeyDown = (event) => {
    if (event.keyCode !== 13) return;
    event.stopPropagation();

    const idEmployee = event.target.value;
    if (!idEmployee) {
      onPicked(null);
      return;
    }

    const employee = _.find(employees, function (_employee) { return _employee.Id == event.target.value });
    onPicked(employee);
  }

  return <>
    {!isShowingAll &&
      <select autoFocus={autoFocus} name={name} style={{ width: width }} size={_size} onKeyDown={onKeyDown} onChange={onChange} value={_value} tabIndex={tabIndex}>
        <option key={0} value="" onClick={() => { onClick(null); }}>{showAny && "--- 全部 ---"}</option>
        {
          isNotLeavedEmployees.map((e) => {
            return <option key={e.Id} value={e.Id} onClick={() => { onClick(e); }}>{e.Name} ({e.Branch})</option>
          })
        }
      </select>
    }
    {isShowingAll &&
      <select autoFocus={autoFocus} name={name} style={{ width: width }} size={_size} onKeyDown={onKeyDown} onChange={onChange} value={_value} tabIndex={tabIndex}>
        <option key={0} value="" onClick={() => { onClick(null); }}>{showAny && "--- 全部 ---"}</option>
        {
          employees.map((e) => {
            return <option key={e.Id} value={e.Id} onClick={() => { onClick(e); }}>{e.getSelectionName()}</option>
          })
        }
      </select>
    }
    {!isShowBalEmployeesOnly &&
      <FormControlLabel className="ml-1" control={<Switch checked={isShowingAll} color="default" onClick={() => setIsShowingAll(!isShowingAll)} />} label={isShowingAll ? "全部" : "有數"} />
    }
  </>
}

export function BranchSelection({ showAny, value, onChange, tabIndex, name }) {
  const { branches } = useSelector(state => {
    return state.selections;
  });

  return <select name={name} onChange={onChange} value={value ? value : ""} tabIndex={tabIndex}>
    <option key={0} value="">{showAny && "--- 全部 ---"}</option>
    {
      branches.map((b) => {
        return <option key={b.Id} value={b.Id}>{b.Branch1}{b.Tdate !== null && " (已結束)"}</option>
      })
    }
  </select>
}

export function BranchMultiSelection({ showAny, value, onChange, tabIndex, size, name }) {
  const { branches } = useSelector(state => {
    return state.selections;
  });

  if (size == undefined) size = 5;

  return <select name={name} size={size} onChange={onChange} tabIndex={tabIndex} multiple>
    <option key={0} value="">{showAny && "--- 全部 ---"}</option>
    {
      branches.map((b) => {
        return <option key={b.Id} value={b.Id} selected={_.includes(b.Id)} >{b.Branch1}{b.Tdate !== null && " (已結束)"}</option>
      })
    }
  </select>
}

export function TeamSelection({ showAny, value, onChange, tabIndex, name, role = "" }) {
  const { teams } = useSelector(state => {
    return state.selections;
  });

  const _teams = role == "" ? teams : _.filter(teams, function (t) { return t.Role == role });

  return <select name={name} onChange={onChange} value={value ? value : ""} tabIndex={tabIndex}>
    <option key={0} value="">{showAny && "--- 全部 ---"}</option>
    {
      _teams.map((t) => {
        return <option key={t.Id} value={t.Id}>{t.getSelectionDesc()}</option>
      })
    }
  </select>
}

export function SupervisorGroupSelection({ showAny, value, onChange, tabIndex, name }) {
  const { supervisorGroups } = useSelector(state => {
    return state.selections;
  });

  return <select name={name} onChange={onChange} value={value ? value : ""} tabIndex={tabIndex}>
    <option key={0} value="">{showAny && "--- 全部 ---"}</option>
    {
      supervisorGroups.map((g) => {
        return <option key={g.Id} value={g.Id}>{g.Group}</option>
      })
    }
  </select>
}

export function DistrictSelection({ showAny, value, onChange, tabIndex, name }) {
  const { districts } = useSelector(state => {
    return state.selections;
  });

  return <select name={name} onChange={onChange} value={value ? value : ""} tabIndex={tabIndex}>
    <option key={0} value="">{showAny && "--- 全部 ---"}</option>
    {
      districts.map((d) => {
        return <option key={d.Id} value={d.District1}>{d.Code} {d.District1}</option>
      })
    }
  </select>
}

export function DfnCommClassSelection({ showAny, value, onChange, tabIndex, name }) {
  const { dfnCommClasses } = useSelector(state => {
    return state.selections;
  });

  return <select name={name} onChange={onChange} value={value ? value : ""} tabIndex={tabIndex}>
    <option key={0} value="">{showAny && "--- 全部 ---"}</option>
    {
      dfnCommClasses.map((d) => {
        return <option key={d.Id} value={d.Class}>{d.Display}</option>
      })
    }
  </select>
}

export function DfnCommTagReportSelection({ showAny, value, onChange, tabIndex, name }) {
  const { dfnCommTagReports } = useSelector(state => {
    return state.selections;
  });

  return <select name={name} onChange={onChange} value={value ? value : ""} tabIndex={tabIndex}>
    <option key={0} value="">{showAny && "--- 全部 ---"}</option>
    {
      dfnCommTagReports.map((d) => {
        return <option key={d.Id} value={d.TagReport}>{d.TagReport}</option>
      })
    }
  </select>
}

// 因為 Comm 只用 Role，所以改為用 Role value 而非 Role Id, 見 TeamDetail 及 EmployeeList Filter
export function DfnEmpRoleSelection({ showAny, value, onChange, tabIndex, name }) {
  const { dfnEmpRoles } = useSelector(state => {
    return state.selections;
  });

  return <select name={name} onChange={onChange} value={value ? value : ""} tabIndex={tabIndex}>
    <option key={0} value="">{showAny && "--- 全部 ---"}</option>
    {
      dfnEmpRoles.map((r) => {
        return <option key={r.Id} value={r.Role}>{r.Role}</option>; // 因為 Comm 只用 Role，所以改為用 Role value 而非 Role Id, 見 TeamDetail 及 EmployeeList Filter
      })
    }
  </select>
}

export function DfnUserRoleSelection({ showAny, value, onChange, tabIndex, name }) {
  const { dfnUserRoles } = useSelector(state => {
    return state.selections;
  });

  return <select name={name} onChange={onChange} value={value ? value : ""} tabIndex={tabIndex}>
    <option key={0} value="">{showAny && "--- 全部 ---"}</option>
    {
      dfnUserRoles.map((r) => {
        return <option key={r.Value} value={r.Value}>{r.Display}</option>
      })
    }
  </select>
}

export function SupervisorSelection({ showAny, value, onChange, tabIndex, name }) {
  const { vSupervisors } = useSelector(state => {
    return state.selections;
  });

  return <select name={name} onChange={onChange} value={value ? value : ""} tabIndex={tabIndex}>
    <option key={0} value="">{showAny && "--- 全部 ---"}</option>
    {
      vSupervisors.map((s) => {
        return <option key={s.IdEmployee} value={s.IdEmployee}>{s.Name} ({s.Alias}) #{s.IdEmployee}</option>
      })
    }
  </select>
}


export function CommBonusShareRolesSelection({ value, onChange, tabIndex, name }) {
  const commBonusShareRoles = ['上數同事', '分行經理', '區域經理', '助理董事', '營業董事'];

  return <select name={name} onChange={onChange} value={value ? value : ""} tabIndex={tabIndex}>
    <option key={0} value=""></option>
    {
      commBonusShareRoles.map((s, i) => {
        return <option key={i + 1} value={s}>{s}</option>
      })
    }
  </select>
}

export function CommBonusShareManagerSelection({ idEmployee, value, onChange, tabIndex, name }) {
  const { vSupervisors, balEmployees } = useSelector(state => {
    return state.selections;
  });

  value = value ? value : "";
  //console.log('value', value);

  const employee = _.find(balEmployees, function (e) { return e.Id == idEmployee });

  return <select name={name} onChange={onChange} value={value} tabIndex={tabIndex}>
    <option key={0} value=""></option>
    {employee && <option key={1} value={employee.Id}>{employee.Id}. {employee.Name} ({employee.Alias})</option>}
    <option key={2} value="" disabled>--- 主管 ---</option>
    {
      vSupervisors.map((s) => {
        return <option key={s.IdEmployee} value={s.IdEmployee}>{s.Name} ({s.Alias}) #{s.IdEmployee}</option>
      })
    }
  </select>
}

export function DfnEmpRankSelection({ showAny, value, onChange, tabIndex, name }) {
  const { dfnEmpRanks } = useSelector(state => {
    return state.selections;
  });

  return <select name={name} onChange={onChange} value={value ? value : ""} tabIndex={tabIndex}>
    <option key={0} value="">{showAny && "--- 全部 ---"}</option>
    {
      dfnEmpRanks.map((r) => {
        return <option key={r.Id} value={r.Id}>{r.Rank}</option>
      })
    }
  </select>
}

export function DfnDistrictManagerSelection({ showAny, value, onChange, tabIndex, name, className }) {
  const { dfnDistrictManagers, cfgSelection: { skips: { dfnDistrictManagerSkips } } } = useSelector(state => {
    return state.selections;
  });

  return <select name={name} className={className} onChange={onChange} value={value ? value : ""} tabIndex={tabIndex}>
    <option key={0} value="">{showAny && "--- 全部 ---"}</option>
    {
      _.filter(dfnDistrictManagers, function (e) {
        return !_.some(dfnDistrictManagerSkips, function (skips) { return skips.Id == e.Id; });
      }).map((r) => {
        return <option key={r.Id} value={r.Id}>{r.District}</option>
      })
    }
  </select>
}

export function CalMonthSelection({ value, onChange, tabIndex, name, isEndOfMonth, className }) {
  const { calMonths } = useSelector(state => {
    return state.selections;
  });

  return <select id={name} name={name} onChange={onChange} value={value} tabIndex={tabIndex} className={className ? className : ""}>
    {
      calMonths.map((m) => {

        // Moment.endOf 同 Moment.startOf 係真係將 moment instance 嘅 value update
        // 而唔係 return new instance !!! (會改變 reducer 內 state.selections.calMonths)
        // 見 https://momentjs.com/docs/#/manipulating/end-of/ : it sets to the end of a unit of time.

        m = isEndOfMonth ? m.endOf('month') : m.startOf('month');

        return <option key={m.format("YYYY-MM-DD")} value={m.format("YYYY-MM-DD")}>{m.format("YYYY MMM")}</option>
      })
    }

  </select>;
}

export function CredCalMonthSelection({ value, onChange, tabIndex, name, isEndOfMonth, className }) {
  const dispatch = useDispatch();

  const { credCalMonths } = useSelector(state => {
    return state.selections;
  });

  useEffect(() => {
    if (_.isEmpty(credCalMonths)) dispatch(credCalMonthLookup());
  }, []);

  return <select id={name} name={name} onChange={onChange} value={value} tabIndex={tabIndex} className={className ? className : ""}>
    {
      credCalMonths.map((m) => {

        // Moment.endOf 同 Moment.startOf 係真係將 moment instance 嘅 value update
        // 而唔係 return new instance !!! (會改變 reducer 內 state.selections.calMonths)
        // 見 https://momentjs.com/docs/#/manipulating/end-of/ : it sets to the end of a unit of time.

        m = isEndOfMonth ? m.endOf('month') : m.startOf('month');

        return <option key={m.format("YYYY-MM-DD")} value={m.format("YYYY-MM-DD")}>{m.format("YYYY MMM")}</option>
      })
    }

  </select>;
}

export function CredEmployeeSelection({ value, onChange, tabIndex, name, className }) {
  const dispatch = useDispatch();

  const { credEmployees } = useSelector(state => {
    return state.selections;
  });

  useEffect(() => {
    if (_.isEmpty(credEmployees)) dispatch(credEmployeeLookup());
  }, []);

  return <select id={name} name={name} onChange={onChange} value={value} tabIndex={tabIndex} className={className ? className : ""}>
    {
      credEmployees.map((e) => {
        return <option key={e.Id} value={e.Id}>{e.Name} ({e.Branch})</option>
      })
    }
  </select>;
}

export function CredSupervisorSelection({ value, onChange, tabIndex, name, className }) {
  const dispatch = useDispatch();

  // 都係 Employee
  const { credSupervisors } = useSelector(state => {
    return state.selections;
  });

  useEffect(() => {
    if (_.isEmpty(credSupervisors)) dispatch(credSupervisorLookup());
  }, []);

  return <select id={name} name={name} onChange={onChange} value={value} tabIndex={tabIndex} className={className ? className : ""}>
    {
      credSupervisors.map((e) => {
        return <option key={e.Id} value={e.Id}>{e.Name} ({e.Branch})</option>
      })
    }
  </select>;
}

// isShowChoseEmployee: 有可能只出現分行 (分行文員) 見 [CredReportCommDue]
export function CredBranchSelection({ isShowChoseEmployee, value, onChange, tabIndex, name, className }) {
  const dispatch = useDispatch();

  const { credBranches } = useSelector(state => {
    return state.selections;
  });

  useEffect(() => {
    if (_.isEmpty(credBranches)) dispatch(credBranchLookup());
  }, []);

  return <select name={name} onChange={onChange} value={value ? value : ""} tabIndex={tabIndex} className={className ? className : ""}>
    {!isShowChoseEmployee && <option key={0} value=""></option>}
    {isShowChoseEmployee && <option key={0} value="">--- (按同事) ---</option>}
    {
      credBranches.map((b) => {
        return <option key={b.Id} value={b.Id}>{b.Branch1}{b.Tdate !== null && " (已結束)"}</option>
      })
    }
  </select>
}

export function SupervisorMultipleCheckbox({ value, onChange, tabIndex, isShowEmail }) {
  const StyledAutocomplete = withStyles({
    root: {
      border: '1px solid darkgrey',
      "& .MuiInput-underline:before": {
        border: "none",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        border: "none",
      },
      "& .MuiInput-underline:after": {
        border: "none",
      },
    },
    focused: {
      border: '1px solid black',
    },
    endAdornment: {
      top: "calc(100% - 30px)",
    },
    option: {
      fontSize: "0.8em",
    }
  })(Autocomplete);

  const { vSupervisors, balEmployees } = useSelector(state => {
    return state.selections;
  });

  const entities = _.filter(vSupervisors.map(s => {
    const employee = _.find(balEmployees, function (e) { return e.Id == s.IdEmployee });

    return {
      ...employee,
      ...s
    };
  }), (entity) => entity.AdLogin);

  //console.log('entities', entities);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const values = JSON.parse(value ? value : "[]");
  const autoCompleteValue = _.filter(entities, (entity) => _.indexOf(values, entity.AdLogin) >= 0);

  return (
    <StyledAutocomplete
      multiple
      options={entities}
      disableCloseOnSelect
      size="small"
      value={autoCompleteValue}
      onChange={(_, entities) => { onChange(value ? JSON.stringify(entities.map((entity) => entity.AdLogin)) : "[]"); }}
      getOptionSelected={(entity, _value) => { return entity.AdLogin == _value.AdLogin; }}
      getOptionLabel={(entity) => isShowEmail ? `${entity.AdLogin} (${entity.District} ${entity.Name})` : entity.Name}
      renderOption={(entity, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {isShowEmail && `${entity.District} ${entity.Name}`}
          {!isShowEmail && entity.Name}
        </React.Fragment>
      )}
      style={{ width: isShowEmail ? 400 : 250 }}
      renderInput={(params) => (
        <TextField {...params} tabIndex={tabIndex} />
      )}
    />
  );
}

export function CcEmailMultipleCheckbox({ value, onChange, tabIndex }) {
  const StyledAutocomplete = withStyles({
    root: {
      border: '1px solid darkgrey',
      "& .MuiInput-underline:before": {
        border: "none",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        border: "none",
      },
      "& .MuiInput-underline:after": {
        border: "none",
      },
    },
    focused: {
      border: '1px solid black',
    },
    endAdornment: {
      top: "calc(100% - 30px)",
    },
    option: {
      fontSize: "0.8em",
    }
  })(Autocomplete);

  // actually it is user
  const { ccEmails } = useSelector(state => {
    return state.selections;
  });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const values = JSON.parse(value ? value : "[]");
  const autoCompleteValue = _.filter(ccEmails, (user) => _.indexOf(values, user.CcEmail) >= 0);

  return (
    <StyledAutocomplete
      multiple
      options={ccEmails}
      disableCloseOnSelect
      size="small"
      value={autoCompleteValue}
      onChange={(_, entities) => { onChange(value ? JSON.stringify(entities.map((entity) => entity.CcEmail)) : "[]"); }}
      getOptionSelected={(entity, _value) => { return entity.CcEmail == _value.CcEmail; }}
      getOptionLabel={(entity) => `${entity.CcEmail} (${entity.Name})`}
      renderOption={(entity, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {`${entity.CcEmail} (${entity.Name})`}
        </React.Fragment>
      )}
      style={{ width: 400 }}
      renderInput={(params) => (
        <TextField {...params} tabIndex={tabIndex} />
      )}
    />
  );
}

export function BranchMultipleCheckbox({ value, onChange, tabIndex }) {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      width: 450,
      border: '1px solid darkgrey',
      "& .MuiInput-underline:before": {
        border: "none",
      },
      "& .MuiSelect-root": { // the selection box
        padding: '0px 20px 0px 0px',
        backgroundColor: 'white',
        minHeight: '2.25em',
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        border: "none",
      },
      "& .MuiInput-underline:after": {
        border: "none",
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    menuDialog: {
      maxHeight: "20em",
      "& .MuiList-root": { // the dialog > ul
        padding: 'unset',
        "& .MuiListItem-root": { // the dialog > ul > li
          padding: 'unset',
          "&:not(:last-child)": {
            borderBottom: '1px solid #e0e0e0',
          },
          "&.Mui-selected": {
            backgroundColor: "#f2f3ff",
          },
          "& .MuiCheckbox-root": { // the dialog > ul > li > checkbox
            margin: 'unset',
          }
        },
      },
    },
  }));

  const classes = useStyles();

  const { branches } = useSelector(state => {
    return state.selections;
  });

  const values = JSON.parse(value ? value : "[]");

  const MenuProps = {
    PaperProps: {
      className: classes.menuDialog,
    },
  };

  const handleDelete = (value) => {
    onChange(JSON.stringify(_.pull(values, value)));
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        multiple
        variant="outlined"
        value={values}
        onChange={(event) => onChange(value ? JSON.stringify(event.target.value) : "[]")}
        input={<Input id="select-multiple-chip" />}
        inputProps={{ tabIndex: tabIndex }}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip key={value} label={_.find(branches, { 'Id': value })?.Branch1 ?? ""} className={classes.chip} onMouseDown={(event) => { event.stopPropagation(); }} onDelete={(event) => handleDelete(value)} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
        autoWidth="true"
      >
        {branches.map((branch) => (
          <MenuItem key={branch.Id} value={branch.Id}>
            <Checkbox checked={values.indexOf(branch.Id) > -1} />
            {branch.Branch1}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}