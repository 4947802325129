export default class Supervisor {
    constructor({
        Id,
        IdFoxproMgrInfo,
        IdEmployee,
        Name,
        Alias,
        Fdate,
        Tdate,
        RptCommFdate,
        QtyBranch,
        CommPlan,
        IdBranches,
    }) {
        this.Id = Id;
        this.IdFoxproMgrInfo = IdFoxproMgrInfo;
        this.IdEmployee = IdEmployee;
        this.Name = Name;
        this.Alias = Alias;
        this.Fdate = Fdate;
        this.Tdate = Tdate;
        this.RptCommFdate = RptCommFdate;
        this.QtyBranch = QtyBranch;
        this.CommPlan = CommPlan;
        this.IdBranches = IdBranches;
    }

    isNew() {
        return !this.Id || (this.Id == "0") || (this.Id.toString().toUpperCase() == "NEW");
    }

    getDesc() {
        if (this.isNew()) return "新主管";
        return `${this.IdEmployee}. ${this.Name ? this.Name : "[姓名]"} (${this.Alias ? this.Alias : "[別名]"})`;
    }
}
