import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Moment from 'moment';

import Comm from '../models/comm';

import { detailCommUpdate, saveAssoc as commShareSaveAssoc, clearCommShareAssocs, detailCommGetAssocs } from './commShareAssocReducer';
import { clearCommTranAssocs, detailCommTransAssocs, saveAssoc as commTranSaveAssoc } from './commTranReducer';
import { commRefLookupAddrsHide } from "./commRefReducer";
import { solicitorsRefLookupInfoHide } from "./solicitorsRefReducer";
import { showLoading, showInfo, showErrorResponse, clearMsg } from './msgReducer';

//================== Whole State ==================//
const initialState = {
  isLoading: false,
  isFilterUpdated: false,
  filter: { // match EntitiesResult<T>
    allRecords: 0,
    matchedRecords: 0,
    totalRecords: 0,
    qtyPerPage: 100,
    page: 1,
    row: 0,
    filter: "[]",
    sort: JSON.stringify([{ "property": "date_deposit", "direction": "DESC" }]),
    success: true,
    message: '',
  },
  entities: [],
  errors: '',
  workingEntityId: -1,
  workingEntity: null,
  workingEntityErrors: '',
};


//================== Action Types ==================//
export const CommActions = Object.freeze({
  // filter
  FILTER_UPDATE: Symbol("FILTER_UPDATE"),
  CHANGE_SORT: Symbol("CHANGE_SORT"),
  PAGE_UPDATE: Symbol("PAGE_UPDATE"),
  FETCH_PAGE: Symbol("FETCH_PAGE"),

  // listing
  REQUEST: Symbol("REQUEST"),
  SUCCESS: Symbol("SUCCESS"),
  FAILURE: Symbol("FAILURE"),

  // detail page
  DETAIL_ID: Symbol("DETAIL_ID"),
  DETAIL_SUCCESS: Symbol("DETAIL_SUCCESS"), // ajax loaded
  DETAIL_FAILURE: Symbol("DETAIL_FAILURE"),
  DETAIL_DELETED: Symbol("DETAIL_DELETED"), // ajax delete

  DETAIL_UPDATE: Symbol("DETAIL_UPDATE"), // form fields update
  DETAIL_APPLY_CAL_TOOL: Symbol("DETAIL_APPLY_CAL_TOOL"), // modal cal tool apply values


  // apply to working entity after lookup from other module
  APPLY_REF: Symbol("APPLY_REF"),
  APPLY_SOLICITOR: Symbol("APPLY_SOLICITOR"),
});


//================== Action Creators ==================//
export const commFilterUpdate = (field, value) => {
  return {
    type: CommActions.FILTER_UPDATE,
    payload: {
      field: field,
      value: value
    }
  }
}

const _setWorkingEntityId = (id) => {
  return {
    type: CommActions.DETAIL_ID,
    payload: id,
  }
}

export const commFilterClear = () => {
  return (dispatch) => {
    dispatch(_setWorkingEntityId(initialState.workingEntityId));
    dispatch(commLookup(initialState.filter));
  }
}

export const commChangeSort = (field) => {
  return (dispatch, getState) => {
    dispatch({
      type: CommActions.CHANGE_SORT,
      payload: field,
    });
    const { comm: { filter } } = getState(); // state updated
    //console.log(filter);
    dispatch(commLookup(filter));
  };
}

export const commPageUpdate = (page) => {
  return (dispatch, getState) => {
    const { comm: { filter } } = getState(); // state updated

    let _page = 1;

    if (page == "") {
      _page = "";
    } else {
      _page = parseInt(page);
      if (_.isNaN(_page)) return;
      if (_page < 1) _page = 1;
      if (_page > Math.ceil(filter.matchedRecords / filter.qtyPerPage)) _page = Math.ceil(filter.matchedRecords / filter.qtyPerPage);
    }

    dispatch({
      type: CommActions.FETCH_PAGE,
      payload: _page,
    });
  };
}

export const commFetchPage = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: CommActions.FETCH_PAGE,
      payload: page,
    });
    const { comm: { filter } } = getState(); // state updated
    //console.log(filter);
    dispatch(commLookup(filter));
  };
}

const _commRequest = () => {
  return {
    type: CommActions.REQUEST
  }
}

const _commSuccess = (data) => {
  return {
    type: CommActions.SUCCESS,
    payload: data,
  }
}

const _commFailure = (response) => {
  return {
    type: CommActions.FAILURE,
    payload: response,
  }
}

export const commLookup = (formData) => {
  const _formData = formData ? formData : initialState.filter;
  //console.log('_formData', _formData);
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Comm/GetEntities`;

  return (dispatch, getState) => {
    const { comm } = getState(); // state updated

    // commLookup 會 duplicate, 因為 Comm useEffect() 會 call，MyNavItem 又會 call
    // request by TingTing 2021-12-20. reset filters for <Comm>
    if (comm.isLoading) return;

    dispatch(_commRequest());

    if (comm.isFilterUpdated) _formData.page = 1; // reset page if filter changed
    if (comm.filter.page == "") _formData.page = 1; // reset page if filter changed

    axios.post(apiEndpoint, _formData, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_commSuccess(response.data));
        } else {
          dispatch(_commFailure("未能讀取"));
        }
      })
      .catch(error => {
        dispatch(_commFailure("未能讀取"));
        dispatch(showErrorResponse(error));
      })
  }
}

const _commDetailSuccess = (data) => {
  return {
    type: CommActions.DETAIL_SUCCESS,
    payload: data,
  }
}

const _commDetailFailure = (response) => {
  return (dispatch, getState) => {
    dispatch({
      type: CommActions.DETAIL_FAILURE,
      payload: response,
    })
  }
}

export const commDetail = (Id, isForce) => {
  return (dispatch, getState) => {
    const { comm: { workingEntityId: lastWorkingEntityId } } = getState();
    if (!isForce && lastWorkingEntityId == Id) return; // same entity

    dispatch(_setWorkingEntityId(Id)); // 任何 dispatch 之前先記住 current working entity id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Comm/GetEntity`;

    dispatch(_commRequest());
    dispatch(clearCommShareAssocs()); // manually clear commshare
    dispatch(clearCommTranAssocs()); // manually clear commTran

    axios.post(apiEndpoint, { Id }, {
      timeout: 30000,
    })
      .then(response => {
        dispatch(clearMsg());

        if (response.data) {
          dispatch(_commDetailSuccess(response.data));
          dispatch(detailCommGetAssocs());
          dispatch(detailCommTransAssocs());
        } else {
          dispatch(_commDetailFailure("未能讀取"));
        }
      })
      .catch(error => {
        dispatch(_commDetailFailure("未能讀取"));
        dispatch(showErrorResponse(error));
      })
  };
}

export const commDetailUpdate = (field, value) => {
  //console.log(`${field} = ${value}`);

  return (dispatch, getState) => {
    switch (field) {
      case "CommOwner":
      case "CommBuyer":
        dispatch({
          type: CommActions.DETAIL_UPDATE,
          payload: {
            field: field,
            value: value
          }
        });
        dispatch(detailCommUpdate(field, value));
        break;
      default:
        dispatch({
          type: CommActions.DETAIL_UPDATE,
          payload: {
            field: field,
            value: value
          }
        });
    }
  };
}

const _commLookupAddrsSuccess = (data) => {
  return {
    type: CommActions.LOOKUP_ADDRS_SUCCESS,
    payload: data,
  }
}


const _commLookupAddrsFailure = (response) => {
  return {
    type: CommActions.LOOKUP_ADDRS_FAILURE,
    payload: response,
  }
}

export const commLookupAddrs = (fieldName) => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/CommRef/LookupAddrs`;

  return (dispatch, getState) => {
    const { comm: { workingEntity } } = getState();
    const data = { property: fieldName, value: workingEntity[fieldName] };
    //console.log(data)

    dispatch({ type: CommActions.LOOKUP_ADDRS_SHOW });

    axios.post(apiEndpoint, data, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_commLookupAddrsSuccess(response.data));
        } else {
          dispatch(_commLookupAddrsFailure("未能讀取"));
        }
      })
      .catch(error => {
        dispatch(_commDetailFailure("未能讀取"));
        dispatch(showErrorResponse(error));
      })
  }
}

export const commApplyRef = (commRef) => {
  return (dispatch, getState) => {
    dispatch(commRefLookupAddrsHide());
    dispatch({
      type: CommActions.APPLY_REF,
      payload: commRef,
    });
  }
}

export const commApplySolicitor = (solicitorsRef) => {
  return (dispatch, getState) => {
    const { solicitorsRef: { lookupParty } } = getState(); // 由 solicitorsRef 取得 party

    dispatch(solicitorsRefLookupInfoHide());
    dispatch({
      type: CommActions.APPLY_SOLICITOR,
      payload: {
        solicitorsRef: solicitorsRef,
        lookupParty: lookupParty,
      },
    });
  }
}

export const commDetailApplyCalTool = (values) => {
  return {
    type: CommActions.DETAIL_APPLY_CAL_TOOL,
    payload: values,
  };
}

export const saveEntity = (history) => {
  return (dispatch, getState) => {
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Comm/SaveEntity`;

    const { comm: { workingEntity } } = getState();

    dispatch(showLoading());

    const getData = async () => {
      try {
        const response = await axios.post(apiEndpoint, workingEntity, {
          timeout: 30000,
        });

        await Promise.all([
          commShareSaveAssoc(response.data, getState),
          commTranSaveAssoc(response.data, getState),
        ]);

        const responseDbComm = await axios.post(apiEndpoint.replace("SaveEntity", "UpdateAssocs"), response.data, {
          timeout: 30000,
        });

        // new Entity
        //console.log(workingEntity);
        if (!workingEntity.Id) {
          // 跳到上版
          history.replace(`/commDetail/${response.data.Id}`);
          dispatch(showInfo("已保存"));
        }

        dispatch(_commDetailSuccess(responseDbComm.data));
        dispatch(showInfo("已保存"));
        dispatch(detailCommGetAssocs());
        dispatch(detailCommTransAssocs());

      } catch (error) {
        dispatch(_commDetailFailure("未能讀取"));
        dispatch(showErrorResponse(error));
      }
    }

    getData();

    //axios.post(apiEndpoint, workingEntity, {
    //    timeout: 30000,
    //})
    //    .then(response => {
    //        return Promise.all([
    //            commShareSaveAssoc(response.data, getState),
    //            commTranSaveAssoc(response.data, getState),
    //        ]).then(values => {
    //            return response;
    //        });
    //    })
    //    .then(response => {
    //        if (history.location.pathname == "/commDetail/0") {
    //            history.replace(`/commDetail/${response.data.Id}`);
    //            dispatch(showInfo("已保存"));
    //            return;
    //        }

    //        dispatch(_commDetailSuccess(response.data));
    //        dispatch(showInfo("已保存"));
    //        dispatch(detailCommGetAssocs());
    //        dispatch(detailCommTransAssocs());
    //    })
    //    .catch(error => {
    //        dispatch(_commDetailFailure("未能讀取"));
    //        dispatch(showErrorResponse(error));
    //    })
  };
}

export const deleteEntity = (history) => {
  return (dispatch, getState, x) => {
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Comm/DeleteEntity`;

    const { comm: { workingEntity } } = getState();

    dispatch(showLoading());
    dispatch(_commRequest());

    axios.post(apiEndpoint, workingEntity, {
      timeout: 30000,
    })
      .then(response => {
        const { comm: { filter } } = getState();

        dispatch(clearMsg());
        history.push('/comm');
        dispatch(commLookup(filter));
      })
      .catch(error => {
        dispatch(_commDetailFailure("未能讀取"));
        dispatch(showErrorResponse(error));
      })
  };
}

export const commUpdateByCommShare = () => {
  return (dispatch, getState) => {
    const { commShareAssoc: { commShareAssocs } } = getState();
    const filteredCommShares = _.filter(commShareAssocs, function (a) { return !_.isEmpty(a.IdEmployee) && a.getPercent() > 0 });
    // do not sort it !!! request by tingting 23/12/2021
    //const sortedCommShares = _.sortBy(filteredCommShares, function (a) { return a.getPercent() * -1 });
    const Relevant = filteredCommShares.map(s => s.getDesc()).join(", ");
    //console.log(Relevant);
    const { IdEmployee: Relevant1stId, Name: Relevant1stName } = !_.isEmpty(filteredCommShares) ? filteredCommShares[0] : { IdEmployee: "", Name: "" };
    //console.log(Relevant1stId);
    //console.log(Relevant1stName);

    dispatch({ type: CommActions.DETAIL_UPDATE, payload: { field: "Relevant", value: Relevant } });
    dispatch({ type: CommActions.DETAIL_UPDATE, payload: { field: "Relevant1stId", value: Relevant1stId } });
    dispatch({ type: CommActions.DETAIL_UPDATE, payload: { field: "Relevant1stName", value: Relevant1stName } });

    // debug
    //const { comm: { workingEntity } } = getState();
    //console.log(workingEntity);
  };
};

export const commUpdateByCommTran = () => {
  return (dispatch, getState) => {
    const { commTran: { detailOwnerCommTrans, detailBuyerCommTrans } } = getState();
    const ownerTranAmount = _.sumBy(detailOwnerCommTrans, function (t) { return _.isNaN(parseFloat(t.Amount)) ? 0 : parseFloat(t.Amount); });
    const buyerTranAmount = _.sumBy(detailBuyerCommTrans, function (t) { return _.isNaN(parseFloat(t.Amount)) ? 0 : parseFloat(t.Amount); });

    const { comm: { workingEntity: { CommOwner: commOwner, CommBuyer: commBuyer } } } = getState();
    dispatch({ type: CommActions.DETAIL_UPDATE, payload: { field: "BalOwner", value: commOwner - ownerTranAmount } });
    dispatch({ type: CommActions.DETAIL_UPDATE, payload: { field: "BalBuyer", value: commBuyer - buyerTranAmount } });
  };
};


//================== Reducer ==================//
const commReducer = (state = initialState, action) => {
  let _state = cloneDeep(state);
  let i = -1;

  switch (action.type) {
    case CommActions.FILTER_UPDATE:
      const { field: filterField, value } = action.payload;
      let filters = JSON.parse(state.filter.filter);
      const iFilter = _.findIndex(filters, function (o) { return o.property == filterField; });
      if (iFilter >= 0) {
        if (value) filters[iFilter].value = value;
        else filters.splice(iFilter, 1);
      } else {
        filters.push({ "property": filterField, "value": value });
      }

      _state.filter.filter = JSON.stringify(filters);
      _state.isFilterUpdated = true;
      //console.log('_state.filter', _state.filter.filter);
      return _state;

    case CommActions.CHANGE_SORT:
      const field = action.payload;
      let sorts = JSON.parse(state.filter.sort);
      i = _.findIndex(sorts, function (o) { return o.property == field; });
      if (i >= 0) {
        sorts[i].direction = sorts[i].direction == "ASC" ? "DESC" : "ASC";
      } else {
        // single sort only
        sorts = [{ "property": field, "direction": "ASC" }];
      }

      _state.filter.sort = JSON.stringify(sorts);
      //console.log('_state.filter.sort', _state.filter.sort);

      commLookup(_state.filter);
      return _state;

    case CommActions.PAGE_UPDATE:
      _state.filter.page = action.payload;
      return _state;

    case CommActions.FETCH_PAGE:
      _state.filter.page = action.payload;
      return _state;

    case CommActions.REQUEST:
      _state.isLoading = true;
      return _state;

    case CommActions.SUCCESS:
      _state.isLoading = false;
      _state.filter = {
        allRecords: action.payload.allRecords,
        matchedRecords: action.payload.matchedRecords,
        totalRecords: action.payload.totalRecords,
        qtyPerPage: action.payload.qtyPerPage,
        page: action.payload.page,
        row: action.payload.row,
        filter: action.payload.filter,
        sort: action.payload.sort,
        success: action.payload.success,
        message: action.payload.message,
      };
      _state.entities = action.payload.entities;
      _state.errors = "";
      _state.isFilterUpdated = false;
      return _state;

    case CommActions.FAILURE:
      _state.isLoading = false;
      _state.errors = action.payload;
      _state.entities = [];
      return _state;

    case CommActions.DETAIL_ID:
      _state.workingEntityId = action.payload;
      return _state;

    case CommActions.DETAIL_SUCCESS:
      _state.isLoading = false;
      _state.workingEntity = new Comm(action.payload);
      _state.workingEntityId = _state.workingEntity.Id;

      // update list
      i = _.findIndex(_state.entities, function (_entity) { return _entity.Id == _state.workingEntity.Id; });
      if (i >= 0) _state.entities[i] = _state.workingEntity;

      //console.log(_state.workingEntity);
      //console.log(_state.workingEntity.getDesc());

      return _state;

    case CommActions.DETAIL_FAILURE:
      _state.isLoading = false;
      _state.workingEntityErrors = action.payload;
      //console.log(_state.errors);
      return _state;

    case CommActions.DETAIL_UPDATE:
      _state.workingEntity = updateWorkingEntity(_state.workingEntity, action.payload)
      return _state;

    case CommActions.APPLY_REF:
      _state.workingEntity.District = action.payload.District;
      _state.workingEntity.Class = action.payload.Class;
      _state.workingEntity.Lot = action.payload.Lot;
      _state.workingEntity.StreetEng = action.payload.StreetEng;
      _state.workingEntity.StreetChi = action.payload.StreetChi;
      _state.workingEntity.BldgEng = action.payload.BldgEng;
      _state.workingEntity.BldgChi = action.payload.BldgChi;
      _state.workingEntity.Phrase = action.payload.Phrase;
      _state.workingEntity.Block = action.payload.Block;
      return _state;

    case CommActions.APPLY_SOLICITOR:
      const { solicitorsRef, lookupParty } = action.payload;
      switch (lookupParty.toUpperCase()) {
        case "OWNER":
          _state.workingEntity.OwnerSolicitorsEng = solicitorsRef.SolicitorsEng;
          _state.workingEntity.OwnerSolicitorsChi = solicitorsRef.SolicitorsChi;
          _state.workingEntity.OwnerLawyerEng = solicitorsRef.LawyerEng;
          _state.workingEntity.OwnerLawyerChi = solicitorsRef.LawyerChi;
          _state.workingEntity.OwnerLawyerTel = solicitorsRef.LawyerTel;
          _state.workingEntity.OwnerLawyerFax = solicitorsRef.LawyerFax;
          return _state;

        case "BUYER":
          _state.workingEntity.BuyerSolicitorsEng = solicitorsRef.SolicitorsEng;
          _state.workingEntity.BuyerSolicitorsChi = solicitorsRef.SolicitorsChi;
          _state.workingEntity.BuyerLawyerEng = solicitorsRef.LawyerEng;
          _state.workingEntity.BuyerLawyerChi = solicitorsRef.LawyerChi;
          _state.workingEntity.BuyerLawyerTel = solicitorsRef.LawyerTel;
          _state.workingEntity.BuyerLawyerFax = solicitorsRef.LawyerFax;
          return _state;
      }

    case CommActions.DETAIL_APPLY_CAL_TOOL:
      _state.workingEntity.CommOwner = action.payload.owner;
      _state.workingEntity.CommBuyer = action.payload.buyer;
      return _state;

    default:
      return state;
  }
}

function updateWorkingEntity(entity, { field, value }) {
  //console.log(`${field} = ${value}`);

  switch (field) {
    case "CommOwner":
      entity.CommOwner = value;

      break;

    case "CommBuyer":
      entity.CommBuyer = value;
      break;

    case "DateConclude":
      //console.log(`DateConclude: ${field} = ${value}`);
      if (!value) {
        entity.DateConclude = null;
        entity.DateDue = null;
        return entity;
      }

      entity.DateConclude = value;

      if (!entity.OffsetDateDue) {
        entity.DateDue = null;
        return entity; // 以成交日期加 offset
      }

      entity.DateDue = Moment(entity.DateConclude).add(entity.OffsetDateDue, 'days');
      break;

    case "OffsetDateDue":
      //console.log(`OffsetDateDue: ${field} = ${value}`);
      entity.OffsetDateDue = _.parseInt(value);
      if (!_.isInteger(entity.OffsetDateDue)) {
        entity.OffsetDateDue = null;
        entity.DateDue = null;
        return entity;
      }
      if (!entity.DateConclude) return entity; // 以成交日期加 offset

      entity.DateDue = Moment(entity.DateConclude).add(entity.OffsetDateDue, 'days');
      break;

    case "DateDue":
      //console.log(`DateDue: ${field} = ${value}`);
      if (!value) {
        entity.DateDue = null;
        entity.OffsetDateDue = null;
        return entity;
      }

      entity.DateDue = Moment(value).toDate();

      if (!entity.DateConclude) return entity; // 以成交日期加 offset

      entity.OffsetDateDue = Moment(entity.DateDue).diff(Moment(entity.DateConclude), 'days');
      break;

    default:
      entity[field] = value;
      break;
  }
  return entity;
}

export default commReducer;

