import $ from 'jquery';
import _ from 'lodash';

export const registerTabIndexEls = () => {
    const parent = $(".tabIndexedForm");
    const elParent = parent.get(0);
    const orderedEls = _.sortBy(_.filter(parent.find("[tabindex]"), function (el) { return parseInt($(el).attr('tabindex')) > 0; }), function (el) { return parseInt($(el).attr('tabindex')); });
    Object.assign(elParent, { orderedEls });
    //console.log(orderedEls);
}

export const keyDownHandler = (event, fnLookup) => {
    //console.log('keyDownHandler', event.target);
    switch (event.keyCode) {
        case 17: ctrlKeyHandler(event, fnLookup); break; // CTRL
        case 37: arrowKeyHandler(event); break; // LEFT
        case 38: arrowKeyHandler(event); break; // UP
        case 39: arrowKeyHandler(event); break; // RIGHT
        case 40: arrowKeyHandler(event); break; // DOWN
        case 13: enterKeyHandler(event, fnLookup); break; // ENTER
        default: return;
    }
};

const ctrlKeyHandler = (event, fnLookup) => {
    if (_.isNil(fnLookup)) return;

    fnLookup.call();
};

const arrowKeyHandler = (event) => {
    const inputEl = event.target;

    //console.log('arrowKeyHandler', inputEl);

    if (inputEl.tagName !== "INPUT") return;

    let step = 0;

    switch (event.keyCode) {
        case 37: // LEFT
            if (inputEl.selectionStart !== 0) return; // cursor not head, do nothing
            step = -1;
            break;
        case 38: step = -1; break; // UP
        case 39: // RIGHT
            if (inputEl.selectionStart !== inputEl.value.length) return; // cursor not tail, do nothing
            step = 1;
            break;
        case 40: step = 1; break; // DOWN
        default: return;
    }

    const indexedForm = $(inputEl).parents(".tabIndexedForm").get(0);
    if (!indexedForm) return; // NOT indexedForm

    event.stopPropagation();

    let { orderedEls } = indexedForm; // should be prepared in registerTabIndexEls()
    if (_.isNil(orderedEls)) {
        registerTabIndexEls(); // do it
        orderedEls = indexedForm.orderedEls;
    }

    let cursor = _.findIndex(orderedEls, function (el) { return el == inputEl; });
    let fucosedEl = null;
    let i = 500;

    while (fucosedEl == null && i) {
        i--;
        cursor += step;
        if (cursor == -1) cursor = orderedEls.length - 1;
        if (cursor >= orderedEls.length) cursor = 0;

        fucosedEl = orderedEls[cursor];
        if (fucosedEl.offsetParent == null)  fucosedEl = null; // clear, perform next item if element is not visible
    }

    fucosedEl.focus();
};

const enterKeyHandler = (event, fnLookup) => {
    var inputEl = event.target;
    switch (inputEl.tagName) {
        case "INPUT": break;
        case "SELECT": break;
        default: return;
    }

    const indexedForm = $(inputEl).parents(".tabIndexedForm").get(0);
    if (!indexedForm) return; // NOT indexedForm

    event.stopPropagation();

    let { orderedEls } = indexedForm; // should be prepared in registerTabIndexEls()
    if (_.isNil(orderedEls)) {
        registerTabIndexEls(); // do it
        orderedEls = indexedForm.orderedEls;
    }

    const index = _.findIndex(orderedEls, function (el) { return el == inputEl; });
    if (index == orderedEls.length - 1) {
        orderedEls[0].focus();
        fnLookup.call();
        return;
    }

    let newEl = null;
    for (let i = index + 1; i < orderedEls.length; i++) {
        newEl = orderedEls[i];
        if (_.isNil(newEl.offsetParent)) continue; // hidden

        newEl.focus();
        break;
    }
};

