import Moment from 'moment';

export default class Prepaid {
    constructor({
        Id,
        DateReport,
        IdEmployee,
        Name,
        Alias,
        Branch,
        BalAdjustment,
        HiddenAdjustment,
        PrepaidComm,
        EditDate,
        EditTime,
        EditUser,
    }) {
        this.Id = Id;
        this.DateReport = DateReport;
        this.IdEmployee = IdEmployee;
        this.Name = Name;
        this.Alias = Alias;
        this.Branch = Branch;
        this.BalAdjustment = BalAdjustment;
        this.HiddenAdjustment = HiddenAdjustment;
        this.PrepaidComm = PrepaidComm;
        this.EditDate = EditDate;
        this.EditTime = EditTime;
        this.EditUser = EditUser;
    }

    isNew() {
        return (this.Id == undefined) || (this.Id < 1) || (this.Id.toString().toUpperCase() == "NEW");
    }

    getDesc() {
        if (this.isNew()) return "新預支佣金";

        //386. Yoyo Kan (2021-02-01)
        let strDateReport = "";
        if (this.DateReport) {
            Moment.locale('en');
            strDateReport = Moment(this.DateReport).format('(YYYY-MM-DD)');
        }

        return `#${this.Id} ${this.Name ? this.Name : '[員工]'} ${strDateReport}`;
    }
}
