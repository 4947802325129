import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { commRefDetail, commRefDetailUpdate } from '../../redux/commRefReducer';

import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import BeatLoader from "react-spinners/BeatLoader";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';

import CommRefDetailBottomButtons from './CommRefDetailBottomButtons';
import { DistrictSelection, DfnCommClassSelection } from '../Selections';

const useStyles = makeStyles({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },

    },
    detail: {
        "& table": {
            width: "100%",
            backgroundColor: "white",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
            },

            "& th": {
                backgroundColor: "#FAF3DE",
                textAlign: 'right',
            },
        },
    },
});

export default function CommRefDetail() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { isLoading, workingEntity } = useSelector(state => {
        return state.commRef;
    });

    const { id, idDuplicate } = useParams();
    dispatch(commRefDetail(id, idDuplicate));

    const navClicked = (path) => {
        history.push(path);
    };

    return (
        <>
            <Breadcrumbs className={classes.title}>
                <NavLink className="nav-link" tag={Link} to="/setting">
                    <Typography variant="h1" className={classes.title}>10. 設定</Typography>
                </NavLink>
                <NavLink className="nav-link" tag={Link} to="/commRef/list">
                    <Typography variant="h1" className={classes.title}>參考地址</Typography>
                </NavLink>
                <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : workingEntity !== null ? workingEntity.getDesc() : ""}</Typography>
            </Breadcrumbs>
            { workingEntity &&
                <div className={classes.detail}>
                    <Grid container>
                        <Button variant="contained" className="mr-4" size="small" disableElevation onClick={() => { navClicked("/commRef"); }}>返回列表</Button>
                        <div style={{ flex: 1 }} />
                    <Button variant="contained" size="small" disableElevation onClick={() => { dispatch(commRefDetail(id, idDuplicate, true)); }}><RefreshIcon /></Button>
                    </Grid>

                    <table style={{ width: '550px' }}>
                        <tbody>
                            <tr>
                            <th style={{ width: '100px' }}>地　　區</th><td colSpan={3}>
                                <DistrictSelection showAny={false} onChange={(e) => { dispatch(commRefDetailUpdate("District", e.target.value)); }} value={workingEntity.District} tabIndex={1} />
                                </td>
                            </tr>
                            <tr>
                            <th>樓盤類別</th><td colSpan={3}>
                                <DfnCommClassSelection showAny={false} onChange={(e) => { dispatch(commRefDetailUpdate("Class", e.target.value)); }} value={workingEntity.Class} tabIndex={1} />
                                </td>
                            </tr>
                            <tr>
                            <th>Lot</th><td colSpan={3}><CommRefDetailInputField fieldName="Lot" width={400} tabIndex={3}/></td>
                            </tr>
                            <tr>
                            <th>英文街道</th><td colSpan={3}><CommRefDetailInputField fieldName="StreetEng" width={400} tabIndex={4} /></td>
                            </tr>
                            <tr>
                            <th>中文街道</th><td colSpan={3}><CommRefDetailInputField fieldName="StreetChi" width={400} tabIndex={5} /></td>
                            </tr>
                            <tr>
                            <th>英文大廈</th><td colSpan={3}><CommRefDetailInputField fieldName="BldgEng" width={400} tabIndex={6} /></td>
                            </tr>
                            <tr>
                            <th>中文大廈</th><td colSpan={3}><CommRefDetailInputField fieldName="BldgChi" width={400} tabIndex={7} /></td>
                            </tr>
                            <tr>
                            <th style={{ width: '100px' }}>期 (Phrase)</th><td><CommRefDetailInputField fieldName="Phrase" width={50} tabIndex={8} /> 期</td>
                            <th style={{ width: '100px' }}>座 (Block)</th><td><CommRefDetailInputField fieldName="Block" width={50} tabIndex={9} /> 座</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }

            <div style={{ height: "10px" }}></div>

            <CommRefDetailBottomButtons />
        </>
    );
}


function CommRefDetailInputField({ fieldName, width, tabIndex, textAlign }) {
    const dispatch = useDispatch();
    const { workingEntity } = useSelector(state => {
        return state.commRef;
    });

    return <input value={workingEntity[fieldName] ? workingEntity[fieldName] : ''} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(commRefDetailUpdate(fieldName, e.target.value)); }} />
}