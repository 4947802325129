import React, { useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { branchHeadCountChangeSort } from '../../redux/branchHeadCountReducer';
import ReactMoment from "react-moment";
import NumberFormat from 'react-number-format';

import BeatLoader from "react-spinners/BeatLoader";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles({
    tblList: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& thead": {
            backgroundColor: "#c5e2ff",
            color: "#45525c",
        },

        "& tr": {
            cursor: "pointer",
        },

        "& tr.alt": {
            backgroundColor: "#f7fbff",
        },

        "& tr.lastWorkingEntity": {
            border: "2px solid #da59da",
        },

        "& tbody tr:hover, tbody tr.alt:hover": {
            backgroundColor: "#ffffcc !important",
        },

        "& th": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },
    },
});

export default function BranchHeadCountList() {
    const classes = useStyles();

    const history = useHistory();
    const dispatch = useDispatch();

    const { isLoading, filter, entities, workingEntityId, errors } = useSelector(state => {
        return state.branchHeadCount;
    });

    const sorts = JSON.parse(filter.sort);

    const _getSortDirection = (field) => {
        const i = _.findIndex(sorts, function (o) { return o.property == field; });
        return (i >= 0) ? sorts[i].direction : "";
    };

    const changeSort = (field) => {
        dispatch(branchHeadCountChangeSort(field, filter));
    };

    const showMonthDetail = (id) => {
        history.push(`/branchHeadCountDetails/${id}`);
    }

    //const showDetail = (id) => {
    //    history.push(`/branchHeadCountDetail/${id}`);
    //}

    let i = filter.qtyPerPage * (filter.page - 1) + 1;

    const myRef = useRef(null);
    useEffect(() => {
        if (myRef.current) myRef.current.scrollIntoView({ block: "center" });
    }, []);

    return (
        <table className={classes.tblList} >
            <thead>
                <tr>
                    <th><span>行</span></th>
                    <th onClick={(_) => changeSort('branch')}><span>分行</span><MyArrowIcon direction={_getSortDirection("branch")} /></th>
                    <th onClick={(_) => changeSort('fdate')}><span>開始日期</span><MyArrowIcon direction={_getSortDirection("fdate")} /></th>
                    <th onClick={(_) => changeSort('tdate')}><span>完結日期</span><MyArrowIcon direction={_getSortDirection("tdate")} /></th>
                    <th onClick={(_) => changeSort('avg_head_count')}><span>PC 平均人數</span><MyArrowIcon direction={_getSortDirection("avg_head_count")} /></th>
                    {/*<th>&nbsp;</th>*/}
                </tr>
            </thead>

            {isLoading
                ?
                <tbody>
                    <tr>
                        <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
                    </tr>
                </tbody>
                :
                (errors !== "")
                    ?
                    <tbody>
                        <tr>
                            <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
                        </tr>
                    </tbody >
                    :
                    <tbody>
                        {
                            entities.length < 1
                                ? <tr>
                                    <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>沒有符合的記錄</em></td>
                                </tr>
                                : entities.map((entity, index) => {
                                    return <tr key={entity.Id} ref={workingEntityId == entity.Id ? myRef : null} className={clsx({ "lastWorkingEntity": workingEntityId == entity.Id, "alt": index % 2 == 1, "normal": index % 2 == 0 })} onClick={() => { showMonthDetail(entity.Id) }}>
                                        <td>{i++}</td>
                                        <td>{entity.Branch}</td>
                                        <td>{entity.Fdate ? <ReactMoment format="YYYY-MM-DD" date={entity.Fdate} /> : "---"}</td>
                                        <td>{entity.Tdate ? <ReactMoment format="YYYY-MM-DD" date={entity.Tdate} /> : "--- 應用中 ---"}</td>
                                        <td>{entity.AvgHeadCount ? <NumberFormat value={entity.AvgHeadCount} displayType="text" decimalScale="2" fixedDecimalScale /> : "-"}</td>
                                        {/*<td><Button onClick={(e) => { e.stopPropagation(); showDetail(entity.Id); }} variant="contained" size="small" >修改</Button></td>*/}
                                    </tr>
                                })
                        }
                    </tbody>
            }
        </table>
    );
}

function MyArrowIcon({ direction }) {
    switch (direction) {
        case "ASC": return <ArrowDropUpIcon />;
        case "DESC": return <ArrowDropDownIcon />;
        default: return null;
    }
}
