import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { commDetailUpdate } from '../../redux/commReducer';

import CommDetailInputField from './CommDetailInputField';
import MyDateField from '../MyDateField';

const useStyles = makeStyles((theme) => ({
    fieldSet: {
        border: "1px solid #c0c0c0",
        padding: "0px 5px 5px",
        backgroundColor: "#FFFFFF",
        lineHeight: '13px',

        '& legend': {
            width: 'auto',
        },

        "& table": {
            width: "100%",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
            },

            "& th": {
                backgroundColor: "#FAF3DE",
                textAlign: 'right',
            },
        },
        "& table.commShares th": {
            textAlign: 'center',
        },

        "& table.commShares .hidden": {
            display: "none",
        },

        "& table.commShares tr.row1st td": {
            backgroundColor: '#DBFFB8',
        },
    },
    backdrop: {
        backgroundColor: 'rgb(0, 0, 0, 0.2)',
    },
    paper: {
        margin: '40px auto',
        width: 320,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: "auto",
    },
}));

export default function FieldsetCommDetailReduce() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { workingEntity: { DateReduceOwner, DateReduceBuyer } } = useSelector(state => {
        return state.comm;
    });

    const baseTabIndex = 253;

    return (
        <>
            <fieldset className={clsx(classes.fieldSet, { "mt-3": true })}>
                <legend>公司減收</legend>
                <b style={{lineHeight:'26px', color:"blue"}}>※ 減收佣金只用於列印 7.3.2 減收佣金報告，「佣金分配」中請填上減收後的金額</b>
                <table>
                    <tbody>
                        <tr>
                            <th width="120">減收業主佣</th>
                            <td width="120">$ <CommDetailInputField fieldName="ReduceOwner" width="70" tabIndex={baseTabIndex + 1} textAlign="right"/></td>
                            <th width="120">日　　期</th>
                            <td><MyDateField dateValue={DateReduceOwner} onDateChange={(d) => { dispatch(commDetailUpdate('DateReduceOwner', d)); }} tabIndex={baseTabIndex + 2} /></td>
                        </tr>
                        <tr>
                            <td colSpan="2" />
                        </tr>
                        <tr>
                            <th>減收買家佣</th>
                            <td width="120">$ <CommDetailInputField fieldName="ReduceBuyer" width="70" tabIndex={baseTabIndex + 3} textAlign="right" /></td>
                            <th width="120">日　　期</th>
                            <td><MyDateField dateValue={DateReduceBuyer} onDateChange={(d) => { dispatch(commDetailUpdate('DateReduceBuyer', d)); }} tabIndex={baseTabIndex + 4} /></td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ height: '10px' }} />
            </fieldset>
        </>
    );

};