export default class JobSendInvoice {
  constructor({
    Id,
    IdComm,
    MailSubject,
    QueueItemNo,
    BuildNo,
    Comm,
    SendTo,
    Cc,
    CcManager,
    FilePath,
    Status,
    DatetimeCreate,
    DatetimeSend,
    DatetimeSent,
    DatetimeAbort,
    DatetimeError,
    MsgError,
    DatePrint,
    Addr,
    Bal1,
    Bal2,
    Bal3,
    Bal4,
    BalTotal,
    DateReport,
    DateDue,
    DateContract1,
    DateContract2,
    DateContract3,
    DateContract4,
    Desc1,
    Desc2,
    Desc3,
    Desc4,
    EmployeeName,
    IdEmployee,
    Name,
    NoteNo,
    Party,
    RefNo1,
    RefNo2,
    RefNo3,
    RefNo4,
    QrCode,
    Responsibility
  }) {
    this.Id = Id;
    this.IdComm = IdComm;
    this.MailSubject = MailSubject;
    this.QueueItemNo = QueueItemNo;
    this.BuildNo = BuildNo;
    this.Comm = Comm;
    this.SendTo = SendTo;
    this.Cc = Cc;
    this.CcManager = CcManager;
    this.FilePath = FilePath;
    this.Status = Status;
    this.DatetimeCreate = DatetimeCreate;
    this.DatetimeSend = DatetimeSend;
    this.DatetimeSent = DatetimeSent;
    this.DatetimeAbort = DatetimeAbort;
    this.DatetimeError = DatetimeError;
    this.MsgError = MsgError;
    this.DatePrint = DatePrint;
    this.Addr = Addr;
    this.Bal1 = Bal1;
    this.Bal2 = Bal2;
    this.Bal3 = Bal3;
    this.Bal4 = Bal4;
    this.BalTotal = BalTotal;
    this.DateReport = DateReport;
    this.DateDue = DateDue;
    this.DateContract1 = DateContract1;
    this.DateContract2 = DateContract2;
    this.DateContract3 = DateContract3;
    this.DateContract4 = DateContract4;
    this.Desc1 = Desc1;
    this.Desc2 = Desc2;
    this.Desc3 = Desc3;
    this.Desc4 = Desc4;
    this.EmployeeName = EmployeeName;
    this.IdEmployee = IdEmployee;
    this.Name = Name;
    this.NoteNo = NoteNo;
    this.Party = Party;
    this.RefNo1 = RefNo1;
    this.RefNo2 = RefNo2;
    this.RefNo3 = RefNo3;
    this.RefNo4 = RefNo4;
    this.QrCode = QrCode;
    this.Responsibility = Responsibility;
  }

  isNew() {
    return (this.Id == undefined) || (this.Id < 1) || (this.Id.toString().toUpperCase() == "NEW");
  }

  getDesc() {
    if (this.isNew()) return "新發送付款通知單記錄數";

    return `發送付款通知單記錄數 #${this.Id}`;
  }

  getStatusDatetime() {
    if (this.DatetimeError) return this.DatetimeError;
    if (this.DatetimeAbort) return this.DatetimeAbort;
    if (this.DatetimeSent) return this.DatetimeSent;
    //if (this.DatetimeSend) return this.DatetimeSend;

    return null;
  }

  getStatus() {
    // MyJobStatuses
    // __INIT__ < AWAITING < ABORTED < SUCCESS < FAILURE

    if (this.DatetimeError) return "FAILURE";
    if (this.DatetimeSent) return "SUCCESS";
    if (this.DatetimeAbort) return "ABORTED";
    if (this.DatetimeSend) return "AWAITING";
    return "__INIT__";
  }
}
