import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { employeeFilterUpdate, employeeFilterClear, balEmployeeLookup } from '../../redux/employeeReducer';
import { BranchSelection, TeamSelection, DfnEmpRoleSelection } from '../Selections';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& input, & select": {
            padding: "0px 1px",
            fontSize: "13px",
        },

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },

        "& label": {
            margin: 0,
        }
    },
});

export default function EmployeeFilter({ filter }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const filters = JSON.parse(filter.filter);

    const _getFilterValue = (field) => {
        const i = _.findIndex(filters, function (o) { return o.property == field; });
        return (i >= 0) ? filters[i].value : "";
    };

    const status = _getFilterValue('status');

    const filterChange = (field, value) => {
        dispatch(employeeFilterUpdate(field, value));
    };

    const clearFilter = () => {
        dispatch(employeeFilterClear());
    };

    const lookup = () => {
        dispatch(balEmployeeLookup(filter));
    }

    return (
        <div style={{ width: '1024px' }}>
            <table className={`${classes.frmFilter} mb-2`}>
                <tbody>
                    <tr>
                        <th>員工編號：</th>
                        <td>
                            <input onChange={(e) => { filterChange('id', e.target.value); }} value={_getFilterValue('id')} style={{ width: '100px' }} tabIndex={1} />
                        </td>
                        <th>分　　行</th>
                        <td>
                            <BranchSelection showAny={true} onChange={(e) => { filterChange('id_branch', e.target.value); }} value={_getFilterValue('id_branch')} tabIndex={2} />
                        </td>
                        <th>組　　別</th>
                        <td>
                            <TeamSelection showAny={true} onChange={(e) => { filterChange('id_team', e.target.value); }} value={_getFilterValue('id_team')} tabIndex={3} />
                        </td>
                        <th>身　　分</th>
                        <td>
                            <DfnEmpRoleSelection showAny={true} onChange={(e) => { filterChange('role', e.target.value); }} value={_getFilterValue('role')} tabIndex={4} />
                        </td>
                    </tr>
                    <tr>
                        <th>員工姓名：</th>
                        <td>
                            <input onChange={(e) => { filterChange('s_name', e.target.value); }} value={_getFilterValue('s_name')} style={{ width: '100px' }} tabIndex={1} />
                        </td>
                        <th>員工別名：</th>
                        <td>
                            <input onChange={(e) => { filterChange('s_alias', e.target.value); }} value={_getFilterValue('s_alias')} style={{ width: '100px' }} tabIndex={1} />
                        </td>
                        <th>狀　　況</th>
                        <td colSpan="3">
                            <EmployeeFilterIsActiveRadioField selectedValue={status} fieldName="status" label="全部" value="ANY" />
                            <EmployeeFilterIsActiveRadioField selectedValue={status} fieldName="status" label="在職" value="__IS_ON_THE_JOB__" tooltip="「員工過往記錄」未完結" />
                            <EmployeeFilterIsActiveRadioField selectedValue={status} fieldName="status" label="已離職" value="__IS_LEAVED_AND_HAS_NOT_RECEIVED__" tooltip="「員工過往記錄」已完結，但未收齊佣金" />
                            <EmployeeFilterIsActiveRadioField selectedValue={status} fieldName="status" label="停用" value="__IS_LEAVED_AND_SETTLED__" tooltip="「員工過往記錄」已完結（即已離職），且已收齊所有佣金" />
                        </td>

                    </tr>
                </tbody>
            </table>
            <Grid container justifyContent="flex-end" spacing={2} className="mb-2">
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { lookup(); }}>搜　尋</Button>
                </Grid>
                <Grid item >
                    <Button variant="contained" size="small" onClick={(_) => { clearFilter(); }}>清　除</Button>
                </Grid>
            </Grid>
        </div>
    );
}

const MyTooltip = withStyles({
    tooltip: {
        backgroundColor: "indianred",
    },
    arrow: {
        color: "indianred",
    }
})(Tooltip);

function EmployeeFilterIsActiveRadioField({ fieldName, label, value, selectedValue, tooltip }) {

    const dispatch = useDispatch();
    const name = `filter_${fieldName}`;
    const id = `${name}_${value}`;

    return <MyTooltip arrow title={tooltip??""}><span className="mr-3" >
        <input className="mr-1" type="radio" value={value} id={id} name={name} onChange={e => { dispatch(employeeFilterUpdate(fieldName, value)); }} checked={selectedValue == value} />
        <label htmlFor={id}>{label}</label>
    </span></MyTooltip>;
}
