import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { tableRateDetail, tableRateDetailUpdate, referecneOldTableRate } from '../../redux/tableRateReducer';

import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import BeatLoader from "react-spinners/BeatLoader";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MyToolTip from '../MyToolTip';

import RefreshIcon from '@material-ui/icons/Refresh';

import { SupervisorSelection, TeamSelection } from '../Selections';
import MyDateField from '../MyDateField';
import TableRateDetailBottomButtons from './TableRateDetailBottomButtons';

import TableRateDetailBars from '../bar/TableRateDetailBars';
import TableRateDetailBonuses from '../bonus/TableRateDetailBonuses';

const useStyles = makeStyles((theme) => ({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
  detail: {
    "& table": {
      width: "auto",
      backgroundColor: "white",

      "& th, & td": {
        padding: "2px 6px",
        border: "1px solid #D5D5D5",
      },

      "& th": {
        backgroundColor: "#FAF3DE",
        textAlign: 'right',
      },
    },
  },
  tableRate: {
    width: '370px',
    float: 'left',
  },
}));

export default function TableRateDetail() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, workingEntity } = useSelector(state => {
    return state.tableRate;
  });
  //console.log(workingEntity?.getType()?? "");

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  const { id, idEmployee, idTeam } = useParams();
  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");

    dispatch(tableRateDetail(id, idEmployee, idTeam));
  }, [id]);

  const workingType = workingEntity?.getType() ?? "__TEAM__";
  const [type, setType] = useState();
  useEffect(() => {
    setType(workingType);
  }, [workingType]);

  //console.log(idEmployee);
  //console.log(idTeam);

  const navClicked = (path) => {
    history.push(path);
  };

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/tableRate/list">
          <Typography variant="h1" className={classes.title}>5. 佣金拆賬比率表</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>{isLoading ? <BeatLoader /> : !_.isNil(workingEntity) ? workingEntity.getDesc() : ""}</Typography>
      </Breadcrumbs>
      {workingEntity &&
        <div className={classes.detail}>
          <Grid container>
            <Button variant="contained" className="mr-4" size="small" disableElevation onClick={() => { navClicked("/tableRate"); }}>返回列表</Button>
            <div style={{ flex: 1 }} />
            <Button variant="contained" size="small" disableElevation onClick={() => { dispatch(tableRateDetail(id, idEmployee, true)); }}><RefreshIcon /></Button>
          </Grid>

          <table className="mt-2 mb-2">
            <tbody>
              <tr>
                <th style={{ width: '80px' }}>類型</th>
                {workingEntity.isNew() ?
                  <td colSpan={type == "__SUPERVISOR_GROUP__" ? 7 : 5}>
                    <TableRateTypes type={type} setType={setType} />
                  </td>
                  :
                  <td colSpan={type == "__SUPERVISOR_GROUP__" ? 7 : 5}>
                    {workingEntity.getDescType()}
                  </td>
                }
              </tr>
              <tr>
                {type == "__TEAM__" &&
                  <th>組別</th>
                }
                {type == "__TEAM__" &&
                  <td>
                    <TeamSelection role="營業員" showAny={false} value={workingEntity.IdTeam} onChange={(e) => { dispatch(tableRateDetailUpdate("IdTeam", e.target.value)); }} tabIndex={2} />
                    {workingEntity.isNew() &&
                      <Button variant="contained" className="mr-2 ml-2" size="small" disableElevation onClick={() => { dispatch(referecneOldTableRate(null, workingEntity.IdTeam, null)); }}>參考舊記錄</Button>
                    }
                    {workingEntity.isNew() &&
                      <MyToolTip message={<span>參考舊記錄：<br />以選定的 <b style={{ color: "red", textDecoration: "underline" }}>組別</b> 找出舊有的比率表，假如跳 Bar 的拆賬沒有改變，便只需修改<b style={{ color: "red", textDecoration: "underline" }}>分行</b> 及 <b style={{ color: "red", textDecoration: "underline" }}>日期</b></span>} />
                    }                                    </td>
                }
                {type == "__SUPERVISOR_GROUP__" &&
                  <th>主管組別</th>
                }
                {type == "__SUPERVISOR_GROUP__" &&
                  <td>
                    <TeamSelection role="主管" showAny={false} value={workingEntity.IdSupervisorGroup} onChange={(e) => { dispatch(tableRateDetailUpdate("IdSupervisorGroup", e.target.value)); }} tabIndex={2} />
                    {workingEntity.isNew() &&
                      <Button variant="contained" className="mr-2 ml-2" size="small" disableElevation onClick={() => { dispatch(referecneOldTableRate(null, workingEntity.IdSupervisorGroup, workingEntity.QtySuperviseTeam)); }}>參考舊記錄</Button>
                    }
                    {workingEntity.isNew() &&
                      <MyToolTip message={<span>參考舊記錄：<br />以選定的 <b style={{ color: "red", textDecoration: "underline" }}>組別</b> 找出舊有的比率表，假如跳 Bar 的拆賬沒有改變，便只需修改<b style={{ color: "red", textDecoration: "underline" }}>分行</b> 及 <b style={{ color: "red", textDecoration: "underline" }}>日期</b></span>} />
                    }
                  </td>
                }
                {type == "__SUPERVISOR_GROUP__" &&
                  <th>分行數量</th>
                }
                {type == "__SUPERVISOR_GROUP__" &&
                  <td><TableRateInputField fieldName="QtySuperviseTeam" width="40" tabIndex={3} /></td>
                }
                {type == "__SUPERVISOR__" &&
                  <th>主管</th>
                }
                {type == "__SUPERVISOR__" &&
                  <td>
                    <SupervisorSelection showAny={false} value={workingEntity.IdEmployee} onChange={(e) => { dispatch(tableRateDetailUpdate("IdEmployee", e.target.value)); }} tabIndex={2} />
                    {workingEntity.isNew() &&
                      <Button variant="contained" className="mr-2 ml-2" size="small" disableElevation onClick={() => { dispatch(referecneOldTableRate(workingEntity.IdEmployee, null)); }}>參考舊記錄</Button>
                    }
                    {workingEntity.isNew() &&
                      <MyToolTip message={<span>參考舊記錄：<br />以選定的 <b style={{ color: "red", textDecoration: "underline" }}>主管</b> 找出舊有的比率表，假如跳 Bar 的拆賬沒有改變，便只需修改 <b style={{ color: "red", textDecoration: "underline" }}>日期</b></span>} />
                    }
                  </td>
                }
                <th style={{ width: '85px' }}>開始日期</th>
                <td>
                  <MyDateField dateValue={workingEntity.Fdate} onDateChange={(d) => { dispatch(tableRateDetailUpdate("Fdate", d)); }} tabIndex={4} />
                </td>
                <th style={{ width: '85px' }}>結束日期</th>
                <td>
                  <MyDateField dateValue={workingEntity.Tdate} onDateChange={(d) => { dispatch(tableRateDetailUpdate("Tdate", d)); }} tabIndex={4} />
                  &nbsp;(未完結請漏空)
                </td>
              </tr>
            </tbody >
          </table >
        </div>

      }

      {
        workingEntity && (type == "__SUPERVISOR_GROUP__" || type == "__SUPERVISOR__") &&
        <div style={{ borderRight: "1px dashed red", float: "left", marginRight: '10px', width: '380px', display: 'block', }}>
          <h1 style={{ fontSize: "20px", lineHeight: "32px", fontWeight: "bold" }}>大數</h1>
          <div className={classes.tableRate}>
            <TableRateDetailBars class1="SUPERVISOR" />
            <div style={{ height: '10px', }}></div>
            <TableRateDetailBonuses class1="SUPERVISOR" />
          </div>
        </div>
      }

      <div style={{ float: "left", marginRight: '10px', width: '380px', display: 'block', }}>
        <h1 style={{ fontSize: "20px", lineHeight: "32px", fontWeight: "bold" }}>個人跑數</h1>
        <div className={classes.tableRate}>
          <TableRateDetailBars class1="SPECIAL" />
          <div style={{ height: '10px', }}></div>
          <TableRateDetailBonuses class1="SPECIAL" />
        </div>
      </div>

      <div style={{ float: "left", marginRight: '10px', width: '380px', display: 'block', }}>
        <h1>　</h1>
        <div className={classes.tableRate}>
          <TableRateDetailBars class1="SECOND_HAND" />
          <div style={{ height: '10px', }}></div>
          <TableRateDetailBonuses class1="SECOND_HAND" />
        </div>
      </div>

      <div style={{ clear: "both" }}></div>

      <TableRateDetailBottomButtons />
    </>
  );
}

//SECOND_HAND: _.range(10).map(i => new Bonus({ Class: "SECOND_HAND" })),
//SPECIAL: _.range(10).map(i => new Bonus({ Class: "SPECIAL" })),
//SUPERVISOR: _.range(10).map(i => new Bonus({ Class: "SUPERVISOR" })),

function TableRateTypes({ type, setType }) {
  return <p>
    <TableRateType value={type} setType={setType} type="__TEAM__" label="組別" />
    <TableRateType value={type} setType={setType} type="__SUPERVISOR_GROUP__" label="主管組別" />
    <TableRateType value={type} setType={setType} type="__SUPERVISOR__" label="主管(個人)" />
  </p>
}

// new instant only
function TableRateType({ type, setType, value, label }) {
  const dispatch = useDispatch();

  const name = `table_rate_type`;
  const id = `${name}_${type}`;


  const handleChange = (e) => {
    dispatch(tableRateDetailUpdate("IdTeam", null));
    dispatch(tableRateDetailUpdate("IdSupervisorGroup", null));
    dispatch(tableRateDetailUpdate("IdEmployee", null));

    setType(e.target.value);
  }

  return <span className="mr-2" >
    <input type="radio" value={type} id={id} name="table_rate_type" onChange={handleChange} checked={type == value} />
    <label className="ml-1" style={{ marginBottom: 'auto', lineHeight: "2em" }} htmlFor={id}>{label}</label>
  </span>;
}

function TableRateInputField({ fieldName, width, tabIndex, textAlign }) {
  const dispatch = useDispatch();
  const { workingEntity } = useSelector(state => {
    return state.tableRate;
  });

  return <input value={workingEntity[fieldName] ? workingEntity[fieldName] : ''} style={{ width: `${width}px`, textAlign: textAlign ? textAlign : "left" }} tabIndex={tabIndex} onChange={(e) => { dispatch(tableRateDetailUpdate(fieldName, e.target.value)); }} />
}