import React, { useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { tableRateLookup, tableRateFetchPage, tableRatePageUpdate } from '../../redux/tableRateReducer';

import TableRateFilter from './TableRateFilter';
import TableRateList from './TableRateList';
import Pagination from '../Pagination';

const useStyles = makeStyles({
    title: {
        paddingBottom: "15px",
        fontSize: "26px",
        lineHeight: "26px",
        fontWeight: "bold",
    },
});

export default function TableRate() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { hasFilter } = useParams();

    const newTableRate = () => {
        history.push("/tableRateDetail/0");
    }

    const { filter } = useSelector(state => {
        return state.tableRate;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");

        dispatch(tableRateLookup(hasFilter ? filter : null));
    }, []);

    return (
        <div>
            <Typography variant="h1" className={classes.title}>5. 佣金拆賬比率表</Typography>
            <Grid container justifyContent="space-between" className="mb-2">
                <Grid item>
                    <Button variant="contained" size="small" onClick={newTableRate}>新增拆賬比率表</Button>
                </Grid>
            </Grid>
            <TableRateFilter filter={filter} />
            <Pagination filter={filter} fnFetchPage={tableRateFetchPage} fnPageUpdate={tableRatePageUpdate} />
            <TableRateList />
            <div style={{ margin: "5px", textAlign: 'center' }}>
                <Pagination filter={filter} fnFetchPage={tableRateFetchPage} fnPageUpdate={tableRatePageUpdate} />
            </div>

        </div>
    );
}
