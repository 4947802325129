import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';

import User from '../models/user';
import { ccEmailLookup } from '../redux/selectionsReducer';
import { showLoading, showInfo, showErrorResponse, clearMsg } from './msgReducer';

//================== Whole State ==================//
const initialState = {
  isLoading: false,
  isFilterUpdated: false,
  filter: { // match EntitiesResult<T>
    allRecords: 0,
    matchedRecords: 0,
    totalRecords: 0,
    qtyPerPage: 100,
    page: 1,
    row: 0,
    filter: JSON.stringify([{ "property": "is_active", "value": "1" }]),
    sort: JSON.stringify([{ "property": "name", "direction": "ASC" }]),
    success: true,
    message: '',
  },
  entities: [],
  isDetailInited: false,
  workingEntityId: -1,
  workingEntity: null,
  errors: '',
  lookupAddrsIsOpen: false,
  lookupAddrs: [],
};


//================== Action Types ==================//
export const UserActions = Object.freeze({
  DUPLICATE_ID: Symbol("DUPLICATE_ID"),

  // filter
  CHANGE_SORT: Symbol("CHANGE_SORT"),
  FILTER_UPDATE: Symbol("FILTER_UPDATE"),
  PAGE_UPDATE: Symbol("PAGE_UPDATE"),
  FETCH_PAGE: Symbol("FETCH_PAGE"),

  // listing
  REQUEST: Symbol("REQUEST"),
  SUCCESS: Symbol("SUCCESS"),
  FAILURE: Symbol("FAILURE"),

  // detail page
  DETAIL_ID: Symbol("DETAIL_ID"),
  DETAIL_SUCCESS: Symbol("DETAIL_SUCCESS"),
  DETAIL_FAILURE: Symbol("DETAIL_FAILURE"),
  DETAIL_UPDATE: Symbol("DETAIL_UPDATE"),
  DETAIL_DELETED: Symbol("DETAIL_DELETED"),

  RESET_PASSWORD_SUCCESS: Symbol("RESET_PASSWORD_SUCCESS"),
  CHANGE_PASSWORD_SUCCESS: Symbol("CHANGE_PASSWORD_SUCCESS"),
});


//================== Action Creators ==================//
export const userFilterUpdate = (field, value) => {
  return {
    type: UserActions.FILTER_UPDATE,
    payload: {
      field: field,
      value: value
    }
  }
}

const _setWorkingEntityId = (id) => {
  return {
    type: UserActions.DETAIL_ID,
    payload: id,
  }
}

const _setDuplicateEntityId = (id) => {
  return {
    type: UserActions.DUPLICATE_ID,
    payload: id,
  }
}

export const userFilterClear = () => {
  return (dispatch) => {
    dispatch(_setWorkingEntityId(initialState.workingEntityId));
    dispatch(userLookup(initialState.filter));
  }
}

export const userChangeSort = (field) => {
  return (dispatch, getState) => {
    dispatch({
      type: UserActions.CHANGE_SORT,
      payload: field,
    });
    const { user: { filter } } = getState(); // state updated
    //console.log(filter);
    dispatch(userLookup(filter));
  };
}

export const userPageUpdate = (page) => {
  return (dispatch, getState) => {
    const { user: { filter } } = getState(); // state updated

    let _page = 1;

    if (page == "") {
      _page = "";
    } else {
      _page = parseInt(page);
      if (_.isNaN(_page)) return;
      if (_page < 1) _page = 1;
      if (_page > Math.ceil(filter.matchedRecords / filter.qtyPerPage)) _page = Math.ceil(filter.matchedRecords / filter.qtyPerPage);
    }

    dispatch({
      type: UserActions.FETCH_PAGE,
      payload: _page,
    });
  };
}

export const userFetchPage = (page) => {
  return (dispatch, getState) => {
    dispatch({
      type: UserActions.FETCH_PAGE,
      payload: page,
    });
    const { user: { filter } } = getState(); // state updated
    //console.log(filter);
    dispatch(userLookup(filter));
  };
}

const _userRequest = () => {
  return {
    type: UserActions.REQUEST
  }
}

const _userSuccess = (data) => {
  return {
    type: UserActions.SUCCESS,
    payload: data,
  }
}

const _userFailure = (response) => {
  return {
    type: UserActions.FAILURE,
    payload: response,
  }
}

export const userLookup = (formData) => {
  const _formData = formData ? formData : initialState.filter;
  //console.log(_formData);
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/User/GetEntities`;

  return (dispatch, getState) => {
    dispatch(_userRequest());

    const { user } = getState(); // state updated
    if (user.isFilterUpdated) _formData.page = 1; // reset page if filter changed
    if (user.filter.page == "") _formData.page = 1; // reset page if filter changed

    axios.post(apiEndpoint, _formData, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_userSuccess(response.data));
        } else {
          dispatch(_userFailure("未能讀取"));
        }
      })
      .catch(error => {
        dispatch(_userFailure("未能讀取"));
        dispatch(showErrorResponse(error));
      })
  }
}

const _userDetailSuccess = (data) => {
  return {
    type: UserActions.DETAIL_SUCCESS,
    payload: data,
  }
}

export const _userDuplicateSuccess = (data) => {
  return {
    type: UserActions.DUPLICATE_SUCCESS,
    payload: data,
  }
}

const _userDetailFailure = (response) => {
  return {
    type: UserActions.DETAIL_FAILURE,
    payload: response,
  }
}

export const userDetail = (Id, IdDuplicate, isForce) => {
  return (dispatch, getState) => {
    const { user: { workingEntityId: lastWorkingEntityId, duplicateEntityId: lastDuplicateEntityId } } = getState();

    let workingEntityId = -1;
    let fnSuccess = null;

    if (!IdDuplicate) {
      workingEntityId = Id;
      if (!isForce && lastWorkingEntityId == Id) return; // same entity
      dispatch(_setWorkingEntityId(Id)); // 任何 dispatch 之前先記住 current working entity Id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗
      fnSuccess = _userDetailSuccess;
    } else {
      workingEntityId = IdDuplicate;
      if (!isForce && lastDuplicateEntityId == IdDuplicate) return; // same entity
      dispatch(_setDuplicateEntityId(IdDuplicate)); // 任何 dispatch 之前先記住 current working entity Id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗
      fnSuccess = _userDuplicateSuccess;
    }

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/User/GetEntity`;

    dispatch(_userRequest());

    axios.post(apiEndpoint, { Id: workingEntityId }, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(fnSuccess(response.data));
        } else {
          dispatch(_userDetailFailure("未能讀取"));
        }
      })
      .catch(error => {
        dispatch(_userDetailFailure("未能讀取"));
        dispatch(showErrorResponse(error));
      })
  };
}

export const userDetailUpdate = (field, value) => {
  //console.log(`${field} = ${value}`);
  return {
    type: UserActions.DETAIL_UPDATE,
    payload: { field, value }
  }
}

export const saveEntity = (history) => {
  return (dispatch, getState) => {
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/user/SaveEntity`;

    const { user: { workingEntity } } = getState();

    dispatch(showLoading());

    axios.post(apiEndpoint, workingEntity, {
      timeout: 30000,
    })
      .then(response => {
        if ((history.location.pathname == "/userDetail/0") || history.location.pathname.startsWith("/userDetail/0/")) {
          history.replace(`/userDetail/${response.data.Id}`); // reload detail
        } else {
          dispatch(userDetail(response.data.Id, null, true)); // update detail
        }
        dispatch(showInfo("已保存"));

        const { user: { filter } } = getState();
        dispatch(userLookup(filter)); // update list
        dispatch(ccEmailLookup())
      })
      .catch(error => {
        dispatch(_userDetailFailure("未能讀取"));
        dispatch(showErrorResponse(error));
      })
  };
}

export const deleteEntity = (history) => {
  return (dispatch, getState) => {
    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/User/DeleteEntity`;

    const { user: { workingEntity } } = getState();

    dispatch(showLoading());
    dispatch(_userRequest());

    axios.post(apiEndpoint, workingEntity, {
      timeout: 30000,
    })
      .then(response => {
        dispatch(clearMsg());
        history.push('/user');

        const { user: { filter } } = getState();
        dispatch(userLookup(filter));  // update list
      })
      .catch(error => {
        dispatch(_userDetailFailure("未能讀取"));
        dispatch(showErrorResponse(error));
      })
  };
}

export const resetPassword = () => {
  return (dispatch, getState) => {
    const { user: { workingEntity } } = getState();
    dispatch(showLoading());

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/User/ResetPassword`;

    axios.post(apiEndpoint, workingEntity, {
      timeout: 30000,
    })
      .then(response => {
        dispatch(clearMsg());
        dispatch({ type: UserActions.RESET_PASSWORD_SUCCESS });
        dispatch(showInfo("密碼已重設"));
      })
      .catch(error => {
        dispatch(_userDetailFailure("未能讀取"));
        dispatch(showErrorResponse(error));
      })
  }
}

export const changePassword = (state, setState) => {
  //const { oldPassword, newPassword, confirmPassword } = state;
  //console.log(state);

  return (dispatch, getState) => {
    dispatch(showLoading());

    const { auth: { jWTClaimModel: { User: { Login } } } } = getState();

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/User/ChangePassword`;

    axios.post(apiEndpoint, { ...state, Login: Login }, {
      timeout: 30000,
    })
      .then(response => {
        dispatch(clearMsg());
        dispatch({ type: UserActions.CHANGE_PASSWORD_SUCCESS });
        dispatch(showInfo("密碼已修改"));
      })
      .catch(error => {
        dispatch(clearMsg());
        if (error.response && error.response.data && error.response.data.errors) {
          const { NewPassword: NewPasswordError, OldPassword: OldPasswordError, ConfirmPassword: ConfirmPasswordError } = error.response.data.errors;
          setState({ ...state, NewPasswordError, OldPasswordError, ConfirmPasswordError });
          return;
        }
        dispatch(showErrorResponse(error));
      })
  }
}


//================== Reducer ==================//
const userReducer = (state = initialState, action) => {
  let _state = cloneDeep(state);
  let i = -1;

  switch (action.type) {
    case UserActions.FILTER_UPDATE:
      const { field: filterField, value } = action.payload;
      let filters = JSON.parse(state.filter.filter);
      const iFilter = _.findIndex(filters, function (o) { return o.property == filterField; });
      if (iFilter >= 0) {
        if (value) filters[iFilter].value = value;
        else filters.splice(iFilter, 1);
      } else {
        filters.push({ "property": filterField, "value": value });
      }

      _state.filter.filter = JSON.stringify(filters);
      _state.isFilterUpdated = true;
      //console.log('_state.filter', _state.filter.filter);
      return _state;

    case UserActions.CHANGE_SORT:
      const field = action.payload;
      let sorts = JSON.parse(state.filter.sort);
      i = _.findIndex(sorts, function (o) { return o.property == field; });
      if (i >= 0) {
        sorts[i].direction = sorts[i].direction == "ASC" ? "DESC" : "ASC";
      } else {
        // single sort only
        sorts = [{ "property": field, "direction": "ASC" }];
      }

      _state.filter.sort = JSON.stringify(sorts);
      //console.log('_state.filter.sort', _state.filter.sort);

      userLookup(_state.filter);
      return _state;

    case UserActions.PAGE_UPDATE:
      _state.filter.page = action.payload;
      return _state;

    case UserActions.FETCH_PAGE:
      _state.filter.page = action.payload;
      return _state;

    case UserActions.REQUEST:
      _state.isLoading = true;
      return _state;

    case UserActions.SUCCESS:
      _state.isLoading = false;
      _state.filter = {
        allRecords: action.payload.allRecords,
        matchedRecords: action.payload.matchedRecords,
        totalRecords: action.payload.totalRecords,
        qtyPerPage: action.payload.qtyPerPage,
        page: action.payload.page,
        row: action.payload.row,
        filter: action.payload.filter,
        sort: action.payload.sort,
        success: action.payload.success,
        message: action.payload.message,
      };
      _state.entities = action.payload.entities.map(entity => new User(entity));
      _state.errors = "";
      _state.isFilterUpdated = false;
      return _state;

    case UserActions.FAILURE:
      _state.isLoading = false;
      _state.errors = action.payload;
      _state.entities = [];
      return _state;

    case UserActions.DETAIL_ID:
      _state.workingEntityId = action.payload;
      _state.duplicateEntityId = null;
      return _state;

    case UserActions.DETAIL_SUCCESS:

      _state.isLoading = false;
      _state.workingEntity = new User(action.payload);
      _state.workingEntityId = _state.workingEntity.Id;

      return _state;

    case UserActions.DETAIL_FAILURE:
      _state.isLoading = false;
      _state.errors = action.payload;
      return _state;

    case UserActions.DETAIL_UPDATE:
      const { field: updateField, value: updateValue } = action.payload;
      _state.workingEntity[updateField] = updateValue;
      //console.log('updateField', updateField);
      //console.log('updateValue', updateValue);
      //console.log('_state.workingEntity', _state.workingEntity);
      return _state;

    case UserActions.LOOKUP_INFO_SHOW:
      _state.lookupParty = action.payload;
      _state.errors = '';
      _state.lookupInfoesIsOpen = true;
      _state.lookupInfoes = [];
      return _state;

    case UserActions.RESET_PASSWORD_SUCCESS:
      _state.isLoading = false;
      return _state;

    case UserActions.CHANGE_PASSWORD_SUCCESS:
      _state.isLoading = false;
      return _state;

    default:
      return state;
  }
}

export default userReducer;

