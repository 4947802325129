import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';

import Home from './components/home/Home';
import Comm from './components/comm/Comm';
import CommDetail from './components/comm/CommDetail';
import CommDetail2 from './components/comm/CommDetail2';
import Prepaid from './components/prepaid/Prepaid';
import PrepaidDetail from './components/prepaid/PrepaidDetail';
import CommBonusShare from './components/commBonusShare/CommBonusShare';
import CommBonusShareDetail from './components/commBonusShare/CommBonusShareDetail';
import Employee from './components/employee/Employee';
import EmployeeDetail from './components/employee/EmployeeDetail';
import Supervisor from './components/supervisor/Supervisor';
import SupervisorDetail from './components/supervisor/SupervisorDetail';
import BranchHeadCount from './components/branchHeadCount/BranchHeadCount';
import BranchHeadCountDetail from './components/branchHeadCount/BranchHeadCountDetail';
import BranchHeadCountDetails from './components/branchHeadCount/BranchHeadCountDetails';
import EmployeeVacation from './components/employeeVacation/EmployeeVacation';
import EmployeeVacationDetail from './components/employeeVacation/EmployeeVacationDetail';
import EmployeeVacationDetails from './components/employeeVacation/EmployeeVacationDetails';
import TableRate from './components/tableRate/TableRate';
import TableRateDetail from './components/tableRate/TableRateDetail';
import CommDue from './components/commDue/CommDue';

import Report from './components/report/Report';
import ReportComm from './components/report/7.1ReportComm/ReportComm';
import ReportCommPrepaid from './components/report/7.1.1ReportCommPrepaid/ReportCommPrepaid';
import ReportCommSupervisor from './components/report/7.2ReportCommSupervisor/ReportCommSupervisor';
import ReportCommReceived from './components/report/7.3.1ReportCommReceived/ReportCommReceived';
import ReportCommReduced from './components/report/7.3.2ReportCommReduced/ReportCommReduced';
import ReportBusiness from './components/report/7.4ReportBusiness/ReportBusiness';
import ReportConclude from './components/report/7.5ReportConclude/ReportConclude';
import ReportEmployeeDue from './components/report/7.6ReportEmployeeDue/ReportEmployeeDue';
import ReportCommDue from './components/report/7.7ReportCommDue/ReportCommDue';
import ReportEmployeeTurnover from './components/report/7.8ReportEmployeeTurnover/ReportEmployeeTurnover';
import ReportDebitNote from './components/report/7.9ReportDebitNote/ReportDebitNote';
import ReportReceipt from './components/report/7.9.1ReportReceipt/ReportReceipt';
import JobSendInvoice from './components/jobSendInvoice/JobSendInvoice';
import ReportRank from './components/report/7.10ReportRank/ReportRank';
import ReportQtyTran from './components/report/7.11ReportQtyTran/ReportQtyTran';
import ReportProvision from './components/report/7.12ReportProvision/ReportProvision';
import ReportSupervisorRank from './components/report/7.13ReportSupervisorRank/ReportSupervisorRank';
import ReportSolicitors from './components/report/7.14ReportSolicitors/ReportSolicitors';
import ReportCommOverview from './components/report/7.15ReportCommOverview/ReportCommOverview';
import ReportVacation from './components/report/7.16ReportVacation/ReportVacation';

import CredReportComm from './components/cred/CredReportComm';
import CredReportBusiness from './components/cred/CredReportBusiness';
import CredReportCommSupervisor from './components/cred/CredReportCommSupervisor';
import CredReportEmployeeDue from './components/cred/CredReportEmployeeDue';
import CredReportCommDue from './components/cred/CredReportCommDue';
import CredReportRank from './components/cred/CredReportRank';
import CredReportSupervisorRank from './components/cred/CredReportSupervisorRank';
import CredReportCommReceived from './components/cred/CredReportCommReceived';

import BankReferral from './components/bankReferral/BankReferral';

import Setting from './components/setting/Setting';
import Branch from './components/setting/Branch';
import BranchDetail from './components/setting/BranchDetail';
import Team from './components/setting/Team';
import TeamDetail from './components/setting/TeamDetail';
import CommRef from './components/setting/CommRef';
import CommRefDetail from './components/setting/CommRefDetail';
import SolicitorsRef from './components/setting/SolicitorsRef';
import SolicitorsRefDetail from './components/setting/SolicitorsRefDetail';
import User from './components/setting/User';
import UserDetail from './components/setting/UserDetail';
import UserDetailPassword from './components/setting/UserDetailPassword';

import './style/custom.css'
import './style/base.css'

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
        <Switch>
          <Route path='/commDetail2/:id' component={CommDetail2} />
          <Route path='/commDetail/:id' component={CommDetail} />
          <Route path='/comm/:hasFilter?' component={Comm} />
          <Route path='/prepaidDetail/:id' component={PrepaidDetail} />
          <Route path='/prepaid/:hasFilter?' component={Prepaid} />
          <Route path='/commBonusShareDetail/:id' component={CommBonusShareDetail} />
          <Route path='/commBonusShare/:hasFilter?' component={CommBonusShare} />
          <Route path='/employeeDetail/:id' component={EmployeeDetail} />
          <Route path='/employee/:hasFilter?' component={Employee} />
          <Route path='/supervisorDetail/:id' component={SupervisorDetail} />
          <Route path='/supervisor/:hasFilter?' component={Supervisor} />
          <Route path='/branchHeadCountDetails/:id/:calDate' component={BranchHeadCountDetails} />
          <Route path='/branchHeadCountDetails/:id' component={BranchHeadCountDetails} />
          <Route path='/branchHeadCountDetail/:id' component={BranchHeadCountDetail} />
          <Route path='/branchHeadCount/:hasFilter?' component={BranchHeadCount} />
          <Route path='/employeeVacationDetails/:calMonth' component={EmployeeVacationDetails} />
          <Route path='/employeeVacationDetail/:id' component={EmployeeVacationDetail} />
          <Route path='/employeeVacation/:hasFilter?' component={EmployeeVacation} />

          <Route path='/bankReferral/:hasFilter?' component={BankReferral} />

          <Route path='/tableRateDetail/:id/:idEmployee?/:idTeam?' component={TableRateDetail} />
          <Route path='/tableRate/:hasFilter?' component={TableRate} />
          <Route path='/commDue/:hasFilter?' component={CommDue} />

          <Route path='/reportComm/:calMonth?/:idEmployee?' component={ReportComm} />
          <Route path='/reportComm/:hasFilter?' component={ReportComm} />
          <Route path='/reportCommPrepaid/:hasFilter?' component={ReportCommPrepaid} />
          <Route path='/reportCommSupervisor/:hasFilter?' component={ReportCommSupervisor} />
          <Route path='/reportCommReceived/:hasFilter?' component={ReportCommReceived} />
          <Route path='/reportCommReduced/:hasFilter?' component={ReportCommReduced} />
          <Route path='/reportBusiness/:hasFilter?' component={ReportBusiness} />
          <Route path='/reportConclude/:hasFilter?' component={ReportConclude} />
          <Route path='/reportEmployeeDue/:hasFilter?' component={ReportEmployeeDue} />
          <Route path='/reportCommDue/:hasFilter?' component={ReportCommDue} />
          <Route path='/reportEmployeeTurnover/:hasFilter?' component={ReportEmployeeTurnover} />
          <Route path='/reportDebitNote/:hasFilter?' component={ReportDebitNote} />
          <Route path='/jobSendInvoice/:hasFilter?' component={JobSendInvoice} />

          <Route path='/reportReceipt/:hasFilter?' component={ReportReceipt} />
          <Route path='/reportRank/:hasFilter?' component={ReportRank} />
          <Route path='/reportQtyTran/:hasFilter?' component={ReportQtyTran} />
          <Route path='/reportProvision/:hasFilter?' component={ReportProvision} />
          <Route path='/reportSupervisorRank/:hasFilter?' component={ReportSupervisorRank} />
          <Route path='/reportSolicitors/:hasFilter?' component={ReportSolicitors} />
          <Route path='/reportCommOverview/:hasFilter?' component={ReportCommOverview} />
          <Route path='/reportVacation/:hasFilter?' component={ReportVacation} />
          <Route path='/report/:hasFilter?' component={Report} />

          <Route path='/credReportComm' component={CredReportComm} />
          <Route path='/credReportCommSupervisor' component={CredReportCommSupervisor} />
          <Route path='/credReportBusiness' component={CredReportBusiness} />
          <Route path='/CredReportEmployeeDue' component={CredReportEmployeeDue} />
          <Route path='/CredReportCommDue' component={CredReportCommDue} />
          <Route path='/CredReportRank' component={CredReportRank} />
          <Route path='/CredReportSupervisorRank' component={CredReportSupervisorRank} />
          <Route path='/CredReportCommReceived' component={CredReportCommReceived} />

          <Route path='/setting' component={Setting} />
          <Route path='/branchDetail/:id' component={BranchDetail} />
          <Route path='/branch/:hasFilter?' component={Branch} />
          <Route path='/teamDetail/:id' component={TeamDetail} />
          <Route path='/team/:hasFilter?' component={Team} />
          <Route path='/commRefDetail/:id/:idDuplicate?' component={CommRefDetail} />
          <Route path='/commRef/:hasFilter?' component={CommRef} />
          <Route path='/solicitorsRefDetail/:id/:idDuplicate?' component={SolicitorsRefDetail} />
          <Route path='/solicitorsRef/:hasFilter?' component={SolicitorsRef} />
          <Route path='/userDetail/:id' component={UserDetail} />
          <Route path='/setPassword' component={UserDetailPassword} />
          <Route path='/user/:hasFilter?' component={User} />

          <Route path='/' component={Home} />
        </Switch>
      </Layout>
    );
  }
}