import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { vCommTranBonusFilterUpdate, vCommTranBonusFilterClear, vCommTranBonusLookup } from '../../redux/vCommTranBonuReducer';
import MyDateField from '../MyDateField';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  frmFilter: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& input, & select": {
      padding: "0px 1px",
      fontSize: "13px",
      height: "1.5em",
    },

    "& th": {
      fontWeight: 'bold',
      border: "1px solid #B0B0B0",
      backgroundColor: "#FAF3DE",
      padding: "2px 4px",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      padding: "2px 4px",
    },
  },
});

export default function CommPrepaidFilter({ filter }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const filters = JSON.parse(filter.filter);

  const _getFilterValue = (field) => {
    const i = _.findIndex(filters, function (o) { return o.property == field; });
    return (i >= 0) ? filters[i].value : "";
  };

  const filterChange = (field, value) => {
    dispatch(vCommTranBonusFilterUpdate(field, value));
  };

  const clearFilter = () => {
    dispatch(vCommTranBonusFilterClear());
  };

  const lookup = () => {
    dispatch(vCommTranBonusLookup(filter));
  }

  return (
    <div style={{ width: '500px' }}>
      <table className={`${classes.frmFilter} mb-2`}>
        <tbody>
          <tr><th style={{ width: '85px' }}>物業編號</th><td><input onChange={(e) => { filterChange('l_ref_no', e.target.value); }} value={_getFilterValue('l_ref_no')} style={{ width: '100px' }} tabIndex={1} /></td></tr>
          <tr><th>落訂日期</th><td><MyDateField dateValue={_getFilterValue("f_date_deposit")} onDateChange={(d) => { filterChange('f_date_deposit', d); }} tabIndex={2} /> 至 <MyDateField dateValue={_getFilterValue("t_date_deposit")} onDateChange={(d) => { filterChange('t_date_deposit', d); }} tabIndex={3} /></td></tr>
          <tr><th>獎金日期</th><td><MyDateField dateValue={_getFilterValue("f_date_tran")} onDateChange={(d) => { filterChange('f_date_tran', d); }} tabIndex={4} /> 至 <MyDateField dateValue={_getFilterValue("t_date_tran")} onDateChange={(d) => { filterChange('t_date_tran', d); }} tabIndex={5} /></td></tr>
        </tbody>
      </table>
      <Grid container justifyContent="flex-end" spacing={2} className="mb-2">
        <Grid item >
          <Button variant="contained" size="small" onClick={(_) => { lookup(); }}>搜　尋</Button>
        </Grid>
        <Grid item >
          <Button variant="contained" size="small" onClick={(_) => { clearFilter(); }}>清　除</Button>
        </Grid>
      </Grid>
    </div>
  );
}
