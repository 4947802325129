import React, { useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { prepaidLookup, prepaidFetchPage, prepaidPageUpdate } from '../../redux/prepaidReducer';

import PrepaidFilter from './PrepaidFilter';
import PrepaidList from './PrepaidList';
import Pagination from '../Pagination';

const useStyles = makeStyles({
    title: {
        paddingBottom: "15px",
        fontSize: "26px",
        lineHeight: "26px",
        fontWeight: "bold",
    },
});

export default function Prepaid() {
    const classes = useStyles();

    const history = useHistory();

    const dispatch = useDispatch();

    const { hasFilter } = useParams();

    const newPrepaid = () => {
        history.push("/prepaidDetail/0");
    }

    const { filter } = useSelector(state => {
        return state.prepaid;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");

        dispatch(prepaidLookup(hasFilter ? filter : null));

    }, []);

    return (
        <>
            <Typography variant="h1" className={classes.title}>1.1. 預支佣金</Typography>
            <Grid container justifyContent="space-between" className="mb-2">
                <Grid item>
                    <Button variant="contained" size="small" onClick={newPrepaid}>新增預支佣金</Button>
                </Grid>
            </Grid>
            <PrepaidFilter filter={filter} />
            <Pagination filter={filter} fnFetchPage={prepaidFetchPage} fnPageUpdate={prepaidPageUpdate} />
            <PrepaidList />
            <div style={{ margin: "5px", textAlign:'center' }}>
                <Pagination filter={filter} fnFetchPage={prepaidFetchPage} fnPageUpdate={prepaidPageUpdate} />
            </div>
        </>
    );
}
