import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Intl from 'intl';

import Bar from "../models/bar";
import { showErrorResponse } from "./msgReducer";


//================== Whole State ==================//
const initialState = {
    isLoading: false,
    errors: '',
    tableRateBars: {
        SECOND_HAND: _.range(8).map(i => new Bar({ Class: "SECOND_HAND" })),
        SPECIAL: _.range(8).map(i => new Bar({ Class: "SPECIAL" })),
        SUPERVISOR: _.range(8).map(i => new Bar({ Class: "SUPERVISOR" })),
        //SUPERVISOR_SECOND_HAND: _.range(8).map(i => new Bar({ Class: "SUPERVISOR_SECOND_HAND" })),
        //SUPERVISOR_SPECIAL: _.range(8).map(i => new Bar({ Class: "SUPERVISOR_SPECIAL" })),
    }
};

//================== Action Types ==================//
export const BarActions = Object.freeze({
    // tableRate Detail page associated
    TABLERATE_DETAIL_SUCCESS: Symbol("TABLERATE_DETAIL_SUCCESS"),
    TABLERATE_DETAIL_UPDATE: Symbol("TABLERATE_DETAIL_UPDATE"), // commTran update
    TABLERATE_DETAIL_SAVE: Symbol("TABLERATE_DETAIL_SAVE"),
});


//================== Action Creators ==================//
const _tableRateDetailSuccess = ({ data }, tableRateId, isReference) => {
    return {
        type: BarActions.TABLERATE_DETAIL_SUCCESS,
        payload: { data, tableRateId, isReference },
    }
}

export const tableRateDetail = (isReference) => {
    return (dispatch, getState) => {
        const { tableRate: { workingEntity, referenceEntity } } = getState();
        const tableRate = isReference ? referenceEntity : workingEntity;
        if (tableRate.isNew()) {
            dispatch(dispatch(_tableRateDetailSuccess([], tableRate.Id, isReference)));
            return;
        }

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/bar/GetEntitiesByTableRate`;

        axios.post(apiEndpoint, tableRate, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch(_tableRateDetailSuccess(response, tableRate.Id, isReference));
                }
            })
    };
}

export const tableRateDetailUpdate = (class1, index, fieldName, value) => {
    return {
        type: BarActions.TABLERATE_DETAIL_UPDATE,
        payload: { class1, index, fieldName, value },
    }
}

export const saveEntitiesByTableRate = (tableRate, getState) => {
    const { bar: { tableRateBars } } = getState();

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/bar/SaveEntitiesByTableRate?tableRateId=${tableRate.Id}`;
    return axios.post(apiEndpoint, tableRateBars, {
        timeout: 30000,
    });
}

export const deleteEntitiesByTableRate = (getState) => {
    const { bar: { tableRateBars } } = getState();
    console.log('bar/DeleteEntitiesByTableRate');

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/bar/DeleteEntitiesByTableRate`;
    return axios.post(apiEndpoint, tableRateBars, {
        timeout: 30000,
    });
}



//================== Reducer ==================//
const barReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);

    switch (action.type) {
        case BarActions.TABLERATE_DETAIL_SUCCESS:
            _state.tableRateBars = cloneDeep(initialState.tableRateBars);

            const { data, tableRateId, isReference } = action.payload;
            //console.log(isReference);
            _.forEach(_.keys(_state.tableRateBars), function (_class) {
                _.forEach(_.filter(data, function (b) { return b.Class == _class; }), function (bar, i) {
                    bar.IdTableRate = tableRateId; // assign to tableRateDetail workingEntity
                    if (isReference) bar.Id = 0; // new Bars when referenceing other tableRate

                    if (!_.isNil(bar.Rate)) bar.Rate = bar.Rate.toFixed(2);
                    _state.tableRateBars[_class][i] = bar;
                });
            });
            return _state;

        case BarActions.TABLERATE_DETAIL_UPDATE:
            const { class1: updateClass1, index: updateIndex, fieldName: updateFieldName, value: updateValue } = action.payload;
            _state.tableRateBars[updateClass1][updateIndex][updateFieldName] = updateValue;

            switch (updateFieldName) {
                case "Tvalue":
                    if (!_state.tableRateBars[updateClass1][updateIndex + 1]) break;
                    _state.tableRateBars[updateClass1][updateIndex + 1]["Fvalue"] = updateValue;
                    break;
            }

            return _state;

        case BarActions.TABLERATE_DETAIL_SAVE:
            return _state;

        default:
            return state;
    }
}

export default barReducer;
