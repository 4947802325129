import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Moment from 'moment';

import Supervisor from '../models/supervisor';
import { vSupervisorLookup } from './vSupervisorReducer';
import { supervisorDetailTeams, saveEntitiesInSupervisorDetail, deleteEntitiesInSupervisorDetail } from './supervisorTeamReducer';
import { supervisorDetailTableRates } from './tableRateReducer';
import { showLoading, showInfo, showError, showErrorResponse, clearMsg } from './msgReducer';
import { supervisorLookup as supervisorSelectionLookup } from './selectionsReducer';


//================== Whole State ==================//
const initialState = {
    isLoading: false,
    isDetailInited: false,
    workingEntityId: -1,
    workingEntity: null,
    errors: '',
};


//================== Action Types ==================//
export const SupervisorActions = Object.freeze({
    REQUEST: Symbol("REQUEST"),

    // detail page
    DETAIL_ID: Symbol("DETAIL_ID"), 
    DETAIL_SUCCESS: Symbol("DETAIL_SUCCESS"),
    DETAIL_FAILURE: Symbol("DETAIL_FAILURE"),

    DETAIL_UPDATE: Symbol("DETAIL_UPDATE"), // form fields update
    DETAIL_UPDATE_BYHISTORY: Symbol("DETAIL_UPDATE_BYHISTORY"),
});


//================== Action Creators ==================//
export const setWorkingEntityId = (id) => {
    return {
        type: SupervisorActions.DETAIL_ID,
        payload: id,
    }
}

const _supervisorRequest = () => {
    return {
        type: SupervisorActions.REQUEST
    }
}

const _supervisorDetailSuccess = (data) => {
    return {
        type: SupervisorActions.DETAIL_SUCCESS,
        payload: data,
    }
}

const _supervisorDetailFailure = (response) => {
    return {
        type: SupervisorActions.DETAIL_FAILURE,
        payload: response,
    }
}


export const supervisorDetail = (Id, isForce) => {
    return (dispatch, getState) => {
        const { supervisor: { workingEntityId: lastWorkingEntityId } } = getState();
        if (!isForce && lastWorkingEntityId == Id) return; // same entity

        dispatch(setWorkingEntityId(Id)); // 任何 dispatch 之前先記住 current working entity Id, 因為 dispatch 之後會再行 useEffect，喺上一句就 quit 咗

        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Supervisor/GetEntity`;

        dispatch(_supervisorRequest());

        axios.post(apiEndpoint, { Id }, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch(_supervisorDetailSuccess(response.data));
                    dispatch(supervisorDetailTeams());
                    dispatch(supervisorDetailTableRates());
                } else {
                    dispatch(_supervisorDetailFailure("未能讀取"));
                }
            })
            .catch(error => {
                dispatch(showErrorResponse(error));
            })
    };
}

export const supervisorDetailUpdate = (field, value) => {
    //console.log(`${field} = ${value}`);
    return {
        type: SupervisorActions.DETAIL_UPDATE,
        payload: { field, value }
    }
}

export const saveEntity = (history) => {
    return (dispatch, getState) => {
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Supervisor/SaveEntity`;

        const { supervisor: { workingEntity } } = getState();

        dispatch(showLoading());

        axios.post(apiEndpoint, workingEntity, 
        {
            timeout: 30000,
        })
            .then(response => {
                return saveEntitiesInSupervisorDetail(response, getState)
                    .then(_ => {
                        //console.log(_);
                        return response;
                    });
            })
            .then(response => {
                if (history.location.pathname == "/supervisorDetail/0") {
                    history.replace(`/supervisorDetail/${response.data.Id}`); // reload detail
                } else {
                    dispatch(supervisorDetail(response.data.Id, true)); // update detail
                }
                dispatch(showInfo("已保存"));

                const { vSupervisor: { filter } } = getState();
                dispatch(vSupervisorLookup(filter)); // update list

                dispatch(supervisorSelectionLookup()); // update team selections
            })
            .catch(error => {
                dispatch(showErrorResponse(error));
            })
    };
}

export const deleteEntity = (history) => {
    return (dispatch, getState, x) => {
        const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Supervisor/DeleteEntity`;

        const { supervisor: { workingEntity } } = getState();

        dispatch(showLoading());
        dispatch(_supervisorRequest());

        axios.post(apiEndpoint, workingEntity, {
            timeout: 30000,
        })
            .then(response => {
                return deleteEntitiesInSupervisorDetail(response, getState)
                    .then(_ => {
                        //console.log(_);
                        return response;
                    });
            })
            .then(response => {
                dispatch(clearMsg());
                history.push('/supervisor');

                const { vSupervisor: { filter } } = getState();
                dispatch(vSupervisorLookup(filter)); // update list

                dispatch(supervisorSelectionLookup()); // update team selections
            })
            .catch(error => {
                dispatch(showErrorResponse(error));
            })
    };
}


//================== Reducer ==================//
const supervisorReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);

    switch (action.type) {
        case SupervisorActions.REQUEST:
            _state.isLoading = true;
            return _state;

        case SupervisorActions.DETAIL_INIT:
            _state.isDetailInited = true;
            return _state;

        case SupervisorActions.DETAIL_ID:
            _state.workingEntityId = action.payload;
            return _state;
            
        case SupervisorActions.DETAIL_SUCCESS:

            _state.isLoading = false;
            _state.workingEntity = new Supervisor(action.payload);
            _state.workingEntityId = _state.workingEntity.Id;
            //console.log(_state.workingEntity);
            //console.log(_state.workingEntity.getDesc());

            return _state;

        case SupervisorActions.DETAIL_FAILURE:
            _state.isLoading = false;
            _state.errors = action.payload;
            //console.log(_state.errors);
            return _state;

        case SupervisorActions.DETAIL_UPDATE:
            const { field: updateField, value: updateValue } = action.payload;
            _state.workingEntity[updateField] = updateValue;
            return _state;

        default:
            return state;
    }
}

export default supervisorReducer;

