import React, { useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { commChangeSort } from '../../redux/commReducer';
import ReactMoment from "react-moment";

import BeatLoader from "react-spinners/BeatLoader";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles({
  tblList: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& thead": {
      backgroundColor: "#c5e2ff",
      color: "#45525c",
    },

    "& tr": {
      cursor: "pointer",
    },

    "& tr.alt": {
      backgroundColor: "#f7fbff",
    },

    "& tr.lastWorkingEntity": {
      border: "2px solid #da59da",
    },

    "& tbody tr:hover, tbody tr.alt:hover": {
      backgroundColor: "#ffffcc !important",
    },

    "& th": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },
  },
});

export default function CommList() {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading, filter, entities, workingEntityId, errors } = useSelector(state => {
    return state.comm;
  });

  const sorts = JSON.parse(filter.sort);

  const _getSortDirection = (field) => {
    const i = _.findIndex(sorts, function (o) { return o.property == field; });
    return (i >= 0) ? sorts[i].direction : "";
  };

  const changeSort = (field) => {
    dispatch(commChangeSort(field, filter));
  };

  const showDetail = (id) => {
    history.push(`/commDetail/${id}`);
  }

  const myRef = useRef(null);
  useEffect(() => {
    if (myRef.current) myRef.current.scrollIntoView({ block: "center" });
  }, []);

  return (
    <table className={classes.tblList} >
      <thead>
        <tr>
          <th onClick={(_) => changeSort('date_deposit')}><span>落訂日期</span><MyArrowIcon direction={_getSortDirection("date_deposit")} /></th>
          <th onClick={(_) => changeSort('ref_no')}>物業編號<MyArrowIcon direction={_getSortDirection("ref_no")} /></th>
          <th onClick={(_) => changeSort('district')}>地區<MyArrowIcon direction={_getSortDirection("district")} /></th>
          <th onClick={(_) => changeSort('class')}>類別<MyArrowIcon direction={_getSortDirection("class")} /></th>
          <th onClick={(_) => changeSort('bldg_chi')}>大廈<MyArrowIcon direction={_getSortDirection("bldg_chi")} /></th>
          <th onClick={(_) => changeSort('phrase')}>期<MyArrowIcon direction={_getSortDirection("phrase")} /></th>
          <th onClick={(_) => changeSort('block')}>座<MyArrowIcon direction={_getSortDirection("block")} /></th>
          <th onClick={(_) => changeSort('floor')}>層數<MyArrowIcon direction={_getSortDirection("floor")} /></th>
          <th onClick={(_) => changeSort('flat')}>單位<MyArrowIcon direction={_getSortDirection("flat")} /></th>
          <th onClick={(_) => changeSort('branch')}>分行<MyArrowIcon direction={_getSortDirection("branch")} /></th>
        </tr>
      </thead>

      {isLoading
        ?
        <tbody>
          <tr>
            <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
          </tr>
        </tbody>
        :
        (errors !== "")
          ?
          <tbody>
            <tr>
              <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
            </tr>
          </tbody >
          :

          <tbody>
            {
              entities.length < 1
                ? <tr>
                  <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>沒有符合的記錄</em></td>
                </tr>
                : entities.map((entity, index) => {
                  return <tr key={entity.Id} ref={workingEntityId == entity.Id ? myRef : null} className={clsx({ "lastWorkingEntity": workingEntityId == entity.Id, "alt": index % 2 == 1, "normal": index % 2 == 0 })} onClick={() => { showDetail(entity.Id) }}>
                    <td>{entity.DateDeposit && <ReactMoment format="YYYY-MM-DD" date={entity.DateDeposit} />}</td>
                    <td>{entity.RefNo}</td>
                    <td>{entity.District}</td>
                    <td>{entity.Class}</td>
                    <td>{entity.BldgChi}</td>
                    <td>{entity.Phrase}</td>
                    <td>{entity.Block}</td>
                    <td>{entity.Floor}</td>
                    <td>{entity.Flat}</td>
                    <td>{entity.Branch}</td>
                  </tr>
                })
            }
          </tbody>
      }
    </table>
  );
}

function MyArrowIcon({ direction }) {
  switch (direction) {
    case "ASC": return <ArrowDropUpIcon />;
    case "DESC": return <ArrowDropDownIcon />;
    default: return null;
  }
}
