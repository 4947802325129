import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import Moment from 'moment';

import CommTran from "../models/commTran";
import { commUpdateByCommTran } from "./commReducer";

//================== Whole State ==================//
const initialState = {
  isLoading: false,
  errors: '',
  detailOwnerCommTrans: _.range(10).map(i => new CommTran({ Class: "RECEIVED", Party: "OWNER", PaymentMethod: "" })),
  detailOwnerCommTransIsVisible: _.range(10).map(i => false),
  detailBuyerCommTrans: _.range(10).map(i => new CommTran({ Class: "RECEIVED", Party: "BUYER", PaymentMethod: "" })),
  detailBuyerCommTransIsVisible: _.range(10).map(i => false),
  detailBonusCommTrans: _.range(10).map(i => new CommTran({ Class: "RECEIVED", Party: "BONUS" })),
  detailBonusCommTransIsVisible: _.range(10).map(i => false),
};


//================== Action Types ==================//
export const CommTranActions = Object.freeze({
  COMM_TRANS_REQUEST: Symbol("COMM_TRANS_REQUEST"),

  CLEAR_ASSOCS: Symbol("CLEAR_ASSOCS"), // before comm loaded

  // commDetail associate commTrans
  DETAIL_COMM_TRANS: Symbol("DETAIL_COMM_TRANS"), // owner, buyer, bonus
  DETAIL_COMM_TRANS_SUCCESS: Symbol("DETAIL_COMM_TRANS_SUCCESS"),
  DETAIL_COMM_TRANS_FAILURE: Symbol("DETAIL_COMM_TRANS_FAILURE"),
  DETAIL_COMM_TRANS_UPDATE: Symbol("DETAIL_COMM_TRANS_UPDATE"), // commTran update
  DETAIL_COMM_TRANS_SAVE: Symbol("DETAIL_COMMS_SAVE"),

  DETAIL_COMM_MORE_COMM_TRANS: Symbol("DETAIL_COMM_MORE_COMM_TRANS"),
  DETAIL_COMM_CLEAR_COMM_TRAN: Symbol("DETAIL_COMM_CLEAR_COMM_TRAN"),
});


//================== Action Creators ==================//
const _detailCommTransAssoc = ({ commTranClass, party }) => {
  return {
    type: CommTranActions.DETAIL_COMM_TRANS,
    payload: { commTranClass, party }
  }
}

const _detailCommTransSuccess = ({ data, commTranClass, party }) => {
  return {
    type: CommTranActions.DETAIL_COMM_TRANS_SUCCESS,
    payload: { data, commTranClass, party },
  }
}

const _detailCommTransFailure = ({ response, commTranClass, party }) => {
  return {
    type: CommTranActions.DETAIL_COMM_TRANS_FAILURE,
    payload: { response, commTranClass, party }
  }
}

export const clearCommTranAssocs = () => {
  //console.log("CommTranActions.CLEAR_ASSOCS");
  return {
    type: CommTranActions.CLEAR_ASSOCS,
  }
}

export const detailCommTransAssocs = () => {
  return (dispatch, getState) => {
    dispatch(detailCommTrans("RECEIVED", "OWNER"));
    dispatch(detailCommTrans("RECEIVED", "BUYER"));
    dispatch(detailCommTrans("RECEIVED", "BONUS"));
  }
}

const detailCommTrans = (commTranClass, party) => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/CommTran/GetEntities`;

  return (dispatch, getState) => {
    const { comm: { workingEntity } } = getState(); // 取得 comm 的 workingEntity
    const data = { filter: JSON.stringify([{ property: 'class', value: commTranClass }, { property: 'party', value: party }, { property: 'id_comm', value: `${workingEntity.Id}` }]) };

    dispatch(_detailCommTransAssoc({ commTranClass, party }));

    axios.post(apiEndpoint, data, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_detailCommTransSuccess({ data: response.data, commTranClass, party }));
        } else {
          dispatch(_detailCommTransFailure({ response: "未能讀取", commTranClass, party }));
        }
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          dispatch(_detailCommTransFailure({ response: error.response.data.errors, commTranClass, party }));
        } else if (error.response && error.response.status) {
          dispatch(_detailCommTransFailure({ response: `未能讀取 (${error.response.status})`, commTranClass, party }));
        } else {
          dispatch(_detailCommTransFailure({ response: `未能聯繫伺服器`, commTranClass, party }));
        }
      });
  }
}

export const commTranUpdate = (commTranClass, party, nth, fieldName, value) => {
  return (dispatch, getState) => {
    dispatch({
      type: CommTranActions.DETAIL_COMM_TRANS_UPDATE,
      payload: { commTranClass, party, nth, fieldName, value },
    });

    dispatch(commUpdateByCommTran());
  };
}


export const detailCommMoreCommTran = (commTranClass, party) => {
  return {
    type: CommTranActions.DETAIL_COMM_MORE_COMM_TRANS,
    payload: { commTranClass, party },
  }
}

export const clearCommTran = (commTranClass, party, nth) => {
  return {
    type: CommTranActions.DETAIL_COMM_CLEAR_COMM_TRAN,
    payload: { commTranClass, party, nth }
  }
}

export const saveAssoc = (comm, getState) => {
  const { commTran: { detailOwnerCommTrans, detailBuyerCommTrans, detailBonusCommTrans } } = getState();

  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/CommTran/SaveAssoc`;
  const data = { comm, detailOwnerCommTrans, detailBuyerCommTrans, detailBonusCommTrans };
  return axios.post(apiEndpoint, data, {
    timeout: 30000,
    //}).then(response => {
  });
}


//================== Reducer ==================//
const commTranReducer = (state = initialState, action) => {
  let _state = cloneDeep(state);

  switch (action.type) {
    case CommTranActions.DETAIL_COMM_TRANS:
      _state.isLoading = true;

      _state.detailOwnerCommTrans = initialState.detailOwnerCommTrans;
      _state.detailOwnerCommTransIsVisible = initialState.detailOwnerCommTransIsVisible;
      _state.detailBuyerCommTrans = initialState.detailBuyerCommTrans;
      _state.detailBuyerCommTransIsVisible = initialState.detailBuyerCommTransIsVisible;
      _state.detailBonusCommTrans = initialState.detailBonusCommTrans;
      _state.detailBonusCommTransIsVisible = initialState.detailBonusCommTransIsVisible;

      return _state;

    case CommTranActions.CLEAR_ASSOCS:
      _state.detailOwnerCommTrans = initialState.detailOwnerCommTrans;
      _state.detailBuyerCommTrans = initialState.detailBuyerCommTrans;
      _state.detailBonusCommTrans = initialState.detailBonusCommTrans;
      return _state;

    case CommTranActions.DETAIL_COMM_TRANS_SUCCESS:
      _state.isLoading = false;

      const { data, commTranClass, party } = action.payload;
      let i = 0;

      switch (commTranClass) {
        case "RECEIVED":
          switch (party) {
            case "OWNER":
              i = 0;
              _.forEach(data.entities, entity => {
                _state.detailOwnerCommTrans[i] = new CommTran(entity);
                _state.detailOwnerCommTransIsVisible[i] = parseFloat(_state.detailOwnerCommTrans[i].Amount ? _state.detailOwnerCommTrans[i].Amount : "0")
                i++;
              });
              // min 2 visible
              _state.detailOwnerCommTransIsVisible[0] = true;
              _state.detailOwnerCommTransIsVisible[1] = true;
              return _state;
            case "BUYER":
              i = 0;
              _.forEach(data.entities, entity => {
                _state.detailBuyerCommTrans[i] = new CommTran(entity);
                _state.detailBuyerCommTransIsVisible[i] = parseFloat(_state.detailBuyerCommTrans[i].Amount ? _state.detailBuyerCommTrans[i].Amount : "0")
                i++;
              });
              // min 2 visible
              _state.detailBuyerCommTransIsVisible[0] = true;
              _state.detailBuyerCommTransIsVisible[1] = true;
              return _state;
            case "BONUS":
              i = 0;
              _.forEach(data.entities, entity => {
                _state.detailBonusCommTrans[i] = new CommTran(entity);
                _state.detailBonusCommTransIsVisible[i] = parseFloat(_state.detailBonusCommTrans[i].Amount ? _state.detailBonusCommTrans[i].Amount : "0")
                i++;
              });
              // min 2 visible
              _state.detailBonusCommTransIsVisible[0] = true;
              _state.detailBonusCommTransIsVisible[1] = true;
              return _state;
          }
          break;
      }
      return _state;

    case CommTranActions.DETAIL_COMM_TRANS_FAILURE:
      _state.isLoading = false;
      _state.errors = action.payload;
      return _state;

    case CommTranActions.DETAIL_COMM_TRANS_UPDATE:
      const { commTranClass: updateCommTranClass, party: updateParty, nth, fieldName: updateFieldName, value: updateValue } = action.payload;

      switch (updateCommTranClass) {
        case "RECEIVED":
          switch (updateParty) {
            case "OWNER": _state.detailOwnerCommTrans[nth][updateFieldName] = updateValue; break;
            case "BUYER": _state.detailBuyerCommTrans[nth][updateFieldName] = updateValue; break;
            case "BONUS": _state.detailBonusCommTrans[nth][updateFieldName] = updateValue; break;
          }
          break;
      }

      return _state;

    case CommTranActions.DETAIL_COMM_MORE_COMM_TRANS:
      for (let i = 0; i < 10; i++) {
        const { commTranClass: moreCommTranClass, party: moreParty } = action.payload;
        switch (moreParty) {
          case "OWNER":
            if (!_state.detailOwnerCommTransIsVisible[i]) {
              _state.detailOwnerCommTransIsVisible[i] = true;
              i = 11; // break the loop
              break;
            }
            break;
          case "BUYER":
            if (!_state.detailBuyerCommTransIsVisible[i]) {
              _state.detailBuyerCommTransIsVisible[i] = true;
              i = 11; // break the loop
              break;
            }
            break;
          case "BONUS":
            if (!_state.detailBonusCommTransIsVisible[i]) {
              _state.detailBonusCommTransIsVisible[i] = true;
              i = 11; // break the loop
              break;
            }
            break;
        }
      }

      return _state;
    case CommTranActions.DETAIL_COMM_CLEAR_COMM_TRAN:
      const { commTranClass: clearCommTranClass, party: clearParty, nth: clearNth } = action.payload;
      let commTran = null;
      switch (clearCommTranClass) {
        case "RECEIVED":
          switch (clearParty) {
            case "OWNER":
              commTran = state.detailOwnerCommTrans[clearNth];
              _state.detailOwnerCommTrans[clearNth] = new CommTran({ Id: commTran.Id, Party: commTran.Party, Class: commTran.Class });
              break;
            case "BUYER":
              commTran = state.detailBuyerCommTrans[clearNth];
              _state.detailBuyerCommTrans[clearNth] = new CommTran({ Id: commTran.Id, Party: commTran.Party, Class: commTran.Class });
              break;
            case "BONUS":
              commTran = state.detailBonusCommTrans[clearNth];
              _state.detailBonusCommTrans[clearNth] = new CommTran({ Id: commTran.Id, Party: commTran.Party, Class: commTran.Class });
              break;
          }
          break;
      }
      return _state;

    default:
      return state;
  }
}

export default commTranReducer;
