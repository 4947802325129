import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Moment from 'moment';
import { Link, useHistory, useParams } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'reactstrap';

import { openPdf } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";

import ReportCommOption from './ReportCommOption';
import ReportCommList from './ReportCommList';


const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
});

export default function ReportComm() {
  const classes = useStyles();

  const history = useHistory();

  const { calMonth, idEmployee } = useParams();
  const [filterState, filterSetState] = useState({ calMonth: calMonth ? calMonth : "", autoDate: "__AUTO__", fDate: Moment.utc().format("YYYY-MM-01"), tDate: Moment.utc().endOf('month').format("YYYY-MM-DD"), outStanding: "__OUTSTANDING__", isShowPrepaid: calMonth ? "__YES__" : "__NO__" });
  const [listState, listSetState] = useState({ employees: [], branch: null, team: null, idEmployee: idEmployee ? idEmployee : "", name: "", isPrints: [] });
  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  const printEmployees = (employeeIds) => {
    const params = {
      ...filterState,
      employeeIds: employeeIds,
    };

    console.log(params);

    openPdf("/api/ReportComm/GetPDF", params, msgState, msgSetState);
  }

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/report">
          <Typography variant="h1" className={classes.title}>7. 報告</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>7.1. 佣金報告 (糧單)</Typography>
      </Breadcrumbs>

      <ReportCommOption state={filterState} setState={filterSetState} />
      <ReportCommList filter={filterState} state={listState} setState={listSetState} printEmployees={printEmployees} />

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
    </>
  );
}
