import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { userFilterUpdate, userFilterClear, userLookup } from '../../redux/userReducer';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  frmFilter: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& input, & select": {
      padding: "0px 1px",
      fontSize: "13px",
      height: "1.5em",
    },

    "& th": {
      fontWeight: 'bold',
      border: "1px solid #B0B0B0",
      backgroundColor: "#FAF3DE",
      padding: "2px 4px",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      padding: "2px 4px",
    },
  },
});

export default function UserFilter({ filter }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const filters = JSON.parse(filter.filter);
  const isActiveFilter = _.find(filters, function (o) { return o.property == "is_active" });
  const isActiveValue = isActiveFilter ? isActiveFilter.value : "";

  //const _getFilterValue = (field) => {
  //    const i = _.findIndex(filters, function (o) { return o.property == field; });
  //    return (i >= 0) ? filters[i].value : "";
  //};

  //const filterChange = (field, value) => {
  //    dispatch(userFilterUpdate(field, value));
  //};

  const clearFilter = () => {
    dispatch(userFilterClear());
  };

  const lookup = () => {
    dispatch(userLookup(filter));
  }

  return (
    <div style={{ width: '500px' }}>
      <table className={`${classes.frmFilter} mb-2`}>
        <tbody>
          <tr>
            <th>顯示停用：</th>
            <td>
              <UserFilterIsActiveRadioField selectedValue={isActiveValue} fieldName="is_active" label="全部" value="" />
              <UserFilterIsActiveRadioField selectedValue={isActiveValue} fieldName="is_active" label="有效的" value="1" />
              <UserFilterIsActiveRadioField selectedValue={isActiveValue} fieldName="is_active" label="已停用" value="0" />
            </td>
          </tr>
        </tbody>
      </table>
      <Grid container justifyContent="flex-end" spacing={2} className="mb-2">
        <Grid item >
          <Button variant="contained" size="small" onClick={(_) => { lookup(); }}>搜　尋</Button>
        </Grid>
        <Grid item >
          <Button variant="contained" size="small" onClick={(_) => { clearFilter(); }}>清　除</Button>
        </Grid>
      </Grid>
    </div>
  );
}

function UserFilterIsActiveRadioField({ fieldName, label, value, selectedValue }) {
  const dispatch = useDispatch();
  const name = `filter_${fieldName}`;
  const id = `${name}_${value}`;
  return <span className="mr-2" >
    <input type="radio" value={value} id={id} name={name} onChange={e => { dispatch(userFilterUpdate(fieldName, value)); }} checked={selectedValue == value} />
    <label htmlFor={id}>{label}</label>
  </span>;
}
