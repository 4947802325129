export default class SupervisorTeam {
    constructor({
        Id,
        Fdate,
        Tdate,
        IdSupervisor,
        IdBranch,
        Branch,
        IdTeam,
        Team,
        IsCal,
    }) {
        this.Id = Id;
        this.Fdate = Fdate;
        this.Tdate = Tdate;
        this.IdSupervisor = IdSupervisor;
        this.IdBranch = IdBranch;
        this.Branch = Branch;
        this.IdTeam = IdTeam;
        this.Team = Team;
        this.IsCal = IsCal;
    }

    isNew() {
        return (this.Id == undefined) || (this.Id < 1) || (this.Id.toString().toUpperCase() == "NEW");
    }

    getFdate() {
        return this.Fdate ? new Date(this.Fdate) : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    }

    getTdate() {
        return this.Tdate ? new Date(this.Tdate) : null;
    }
}
