import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Moment from 'moment';

import { openPdf, downloadXls } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";

import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import MyDateField from '../../MyDateField';

import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';

import { BranchSelection, TeamSelection, CalMonthSelection } from '../../Selections';
import MyToolTip from '../../MyToolTip';

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
  frmFilter: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& th": {
      fontWeight: 'bold',
      border: "1px solid #B0B0B0",
      backgroundColor: "#FAF3DE",
      padding: "2px 4px",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      padding: "2px 4px",
    },

    "& label": {
      marginBottom: "0px",
    }
  },
});

export default function ReportCommReduced() {
  const classes = useStyles();

  const history = useHistory();

  const [state, setState] = useState({
    isRange: "FALSE",
    fDate: "",
    tDate: "",
    calMonth: Moment.utc().format("YYYY-MM-01"),
    idBranch: "",
    idTeam: "",
    opt: "__BUYER__",
  });

  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  const print = () => {
    const filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    openPdf("/api/ReportConclude/GetPDF", {
      filter: JSON.stringify(filter)
    }, msgState, msgSetState);
  }

  const excel = () => {
    const filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    downloadXls("/api/ReportConclude/GetXlsx", {
      filter: JSON.stringify(filter)
    }, "物業成交報告.xlsx", msgState, msgSetState);
  }

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/report">
          <Typography variant="h1" className={classes.title}>7. 報告</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>7.5 物業成交報告</Typography>
      </Breadcrumbs>

      <div style={{ width: '650px' }}>
        <table className={classes.frmFilter}>
          <tbody>
            <tr>
              <th>分　　行</th>
              <td>
                <BranchSelection showAny={true} value={state.idBranch} onChange={(e) => { setState({ ...state, idBranch: e.target.value }) }} tabIndex={1} />
              </td>
            </tr>
            <tr>
              <th>組　　別</th>
              <td>
                <TeamSelection showAny={true} value={state.idTeam} onChange={(e) => { setState({ ...state, idTeam: e.target.value }) }} tabIndex={1} />
              </td>
            </tr>
            {(state.opt !== '__PARTIES__') &&
              <tr>
                <th>成交日期</th>
                <td>
                    <CalMonthSelection value={state.calMonth} onChange={(e) => { setState({ ...state, calMonth: e.target.value }); }} tabIndex={3} />
                </td>
              </tr>
            }
            {(state.opt == '__PARTIES__') &&
              <tr>
                <th>入帳日期</th>
                {state.isRange == "FALSE" &&
                  <td>
                    <span>月份</span>&nbsp;<Switch color="default" checked={state.isRange == "TRUE"} onChange={(e) => { setState({ ...state, isRange: "TRUE" }); }} />&nbsp;<span>期間</span>
                    <br/>
                    <CalMonthSelection value={state.calMonth} onChange={(e) => { setState({ ...state, calMonth: e.target.value }); }} tabIndex={3} />
                  </td>
                }
                {state.isRange == "TRUE" &&
                  <td>
                    <span>月份</span>&nbsp;<Switch color="default" checked={state.isRange == "TRUE"} onChange={(e) => { setState({ ...state, isRange: "FALSE" }); }} />&nbsp;<span>期間</span>
                    <br />
                    <MyDateField dateValue={state.fDate} onDateChange={(d) => { setState({ ...state, fDate: d }); }} tabIndex={4} />
                    &nbsp; 至 &nbsp;
                    <MyDateField dateValue={state.tDate} onDateChange={(d) => { setState({ ...state, tDate: d }); }} tabIndex={5} />
                  </td>
                }
              </tr>
            }
            <tr>
              <th>選　　項</th>
              <td>
                <RptConcludeFilterRadioField state={state} setState={setState} fieldName="opt" label="買家名稱" value="__BUYER__" />
                <RptConcludeFilterRadioField state={state} setState={setState} fieldName="opt" label="律師行" value="__SOLICITORS__" />
                <RptConcludeFilterRadioField state={state} setState={setState} fieldName="opt" label="買家及賣家名稱（做問卷用）" value="__PARTIES__" />
                <MyToolTip message={<span>買家及賣家名稱（做問卷用）：<br />只包括「最後一筆已收佣金」在上述月份，及已收齊佣金的成交</span>} />
              </td>
            </tr>
          </tbody >
        </table >

        <div className="mt-2 mb-3" style={{ textAlign: "right" }}>
          <Button startIcon={<PrintIcon />} className="mr-3" size="small" variant="contained" disableElevation onClick={print}>列　印</Button>
          <Button startIcon={<DescriptionIcon />} size="small" variant="contained" disableElevation onClick={excel}>Excel</Button>
        </div>
      </div >

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
    </>
  );
}

function RptConcludeFilterRadioField({ state, setState, fieldName, label, value }) {
  const name = `filter_${fieldName}`;
  const id = `${name}_${value}`;
  return <span className="mr-1 ml-1">
    <input className="mr-1" type="radio" value={value} id={id} name={name} onChange={(e) => { setState({ ...state, [fieldName]: e.target.value, }); }} checked={state[fieldName] == value} />
    <label htmlFor={id}>{label}</label>
  </span>;
}
