import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { saveEntity, deleteEntity } from '../../redux/commReducer';
import { showConfirm, showError } from '../../redux/msgReducer';

import { getDetailCommCheckDiff } from '../../redux/commShareAssocReducer';

import Button from '@material-ui/core/Button';

export default function CommDetailBottomButtons({ msgState, msgSetState }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const { comm: { workingEntity: comm }, commShareAssoc: { commShareAssocs } } = useSelector(state => {
        return state;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    const navClicked = (path) => {
        history.push(path);
    };

    const confirmSave = () => {
        const strDiff = getDetailCommCheckDiff(comm, commShareAssocs);
        if (strDiff) {
            dispatch(showError(`未能保存：${strDiff}`));
            return
        }

        if (!comm.Class) {
            dispatch(showError(`請指定樓盤類別`));
            return;
        }

        dispatch(saveEntity(history));
    };

    const confirmDelete = () => {
        const fnConfirm = (_) => { dispatch(deleteEntity(history)); };
        dispatch(showConfirm("確定刪除？", fnConfirm));
    };

    return <div style={{ textAlign: 'center' }} className="mt-5">
        {Creds.includes("SAVING") && <React.Fragment>
            < Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { confirmSave(); }}>儲存佣金詳情</Button>
            {!comm.isNew() && <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { confirmDelete(); }}>刪　除</Button>}
        </React.Fragment>}
        <Button variant="contained" className="mr-2" size="small" disableElevation onClick={() => { navClicked("/comm"); }}>返回列表</Button>
    </div>
}
