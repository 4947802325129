import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Header from './Header';
import NavMenu from './NavMenu';
import Footer from './Footer';
import ModalMsg from './ModalMsg';

import Auth from './auth/Auth';

import {
  userLookup, employeeLookup, balEmployeeLookup, branchLookup, teamLookup, districtLookup
  , dfnCommClassLookup, dfnCommTagReportLookup, dfnEmpRoleLookup, supervisorLookup
  , dfnEmpRankLookup, dfnDistrictManagerLookup, dfnUserRoleLookup, calMonthLookup
  , ccEmailLookup, cfgSelectionLookup, supervisorGroupLookup
} from '../redux/selectionsReducer';

const useStyles = makeStyles({
  root: {
    backgroundColor: "#F5F5F5",
    padding: "20px 30px 60px",
  },
});

export default function Layout(props) {
  const dispatch = useDispatch();

  /*** for selections common components. see selectionsReducer ***/
  useEffect(() => {
    dispatch(userLookup());
    dispatch(employeeLookup());
    dispatch(balEmployeeLookup());
    dispatch(branchLookup());
    dispatch(teamLookup());
    dispatch(supervisorGroupLookup());
    dispatch(districtLookup());
    dispatch(dfnCommClassLookup());
    dispatch(dfnCommTagReportLookup());
    dispatch(dfnEmpRoleLookup());
    dispatch(dfnUserRoleLookup());
    dispatch(supervisorLookup());
    dispatch(dfnEmpRankLookup());
    dispatch(dfnDistrictManagerLookup());
    dispatch(calMonthLookup());
    dispatch(ccEmailLookup());
    dispatch(cfgSelectionLookup());
  }, []);

  const classes = useStyles();

  const { jWTClaimModel } = useSelector(state => {
    return state.auth;
  });

  return (
    <div>
      <Header />
      {
        jWTClaimModel == null
          ? <>
            <Auth />
            </>
          :
          <div>
            <NavMenu />
            <div className={classes.root}>
              {props.children}
            </div>
          </div>
      }
      <Footer />
      <ModalMsg />
    </div>
  );
}
