import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Moment from 'moment';
import axios from 'axios';
import _ from 'lodash';

import { openPdf, downloadXls } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";

import Button from '@material-ui/core/Button';

import MyDateField from '../../MyDateField';
import { BranchSelection, TeamSelection } from '../../Selections';

import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles({
  title: {
    "& a.nav-link": {
      padding: "0px",
    },

    "& a.nav-link:hover h1": {
      color: "#006ed0",
    },

    "& h1": {
      paddingBottom: "15px",
      fontSize: "26px",
      lineHeight: "26px",
      fontWeight: "bold",
    },
  },
  frmFilter: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& th": {
      fontWeight: 'bold',
      border: "1px solid #B0B0B0",
      backgroundColor: "#FAF3DE",
      padding: "2px 4px",
      textAlign: "center",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      padding: "2px 4px",
    },

    "& label": {
      marginBottom: "0px",
    }
  },
});

export default function ReportCommReduced() {
  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    fDate: Moment.utc().add(-1, 'M').format("YYYY-MM-01"),
    tDate: Moment.utc().add(-1, 'M').endOf('month').format("YYYY-MM-DD"),
    idBranch: "",
    idTeam: "",
    commClass: "__ANY__",
    showSubTotal: "__NO__",
    sorting: "__QTY_TRAN__",
  });

  const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

  const setIdBranches = (e) => {
    setState({
      ...state,
      idBranches: Array.from(e.target.selectedOptions, option => option.value)
    });
  }

  // security
  const { jWTClaimModel: { Creds } } = useSelector(state => {
    return state.auth;
  });

  useEffect(() => {
    if (!Creds.includes("COMMISSION")) history.replace("/");
  }, [Creds, history]);

  const print = () => {
    let filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    openPdf("/api/ReportQtyTran/GetPDF", {
      filter: JSON.stringify(filter)
    }, msgState, msgSetState);
  }

  const excel = () => {
    const filter = _.keys(state).map(key => ({
      property: key,
      value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
    }));
    //console.log(filter);

    downloadXls("/api/ReportQtyTran/GetXlsx", {
      filter: JSON.stringify(filter)
    }, "營業員營業比率報告.xlsx", msgState, msgSetState);
  }

  return (
    <>
      <Breadcrumbs className={classes.title}>
        <NavLink className="nav-link" tag={Link} to="/report">
          <Typography variant="h1" className={classes.title}>7. 報告</Typography>
        </NavLink>
        <Typography variant="h1" className={classes.title}>7.11. 營業員營業比率報告</Typography>
      </Breadcrumbs>

      <div style={{ width: '650px' }}>
        <table className={classes.frmFilter}>
          <tbody>
            <tr>
              <th>分　　行</th>
              <td>
                <BranchSelection showAny={true} value={state.idBranch} onChange={(e) => { setState({ ...state, idBranch: e.target.value }) }} tabIndex={1} />
              </td>
            </tr>
            <tr>
              <th>組　　別</th>
              <td>
                <TeamSelection showAny={true} value={state.idTeam} onChange={(e) => { setState({ ...state, idTeam: e.target.value }) }} tabIndex={1} />
              </td>
            </tr>
            <tr>
              <th>營業日期</th>
              <td>
                <MyDateField dateValue={state.fDate} onDateChange={(d) => { setState({ ...state, fDate: d?.format("YYYY-MM-DD") }); }} tabIndex={1} />
                &nbsp;至&nbsp;
                <MyDateField dateValue={state.tDate} onDateChange={(d) => { setState({ ...state, tDate: d?.format("YYYY-MM-DD") }); }} tabIndex={2} />
              </td>
            </tr>
            <tr>
              <th>類　　別</th>
              <td>
                <RptQtyFilterRadioField state={state} setState={setState} fieldName="commClass" label="全部" value="__ANY__" />
                <RptQtyFilterRadioField state={state} setState={setState} fieldName="commClass" label="特殊" value="SPECIAL" />
                <RptQtyFilterRadioField state={state} setState={setState} fieldName="commClass" label="二手" value="SECOND_HAND" />
              </td>
            </tr>
            <tr>
              <th>小　　計</th>
              <td>
                <RptQtyFilterRadioField state={state} setState={setState} fieldName="showSubTotal" label="不要分行小計" value="__NO__" />
                <RptQtyFilterRadioField state={state} setState={setState} fieldName="showSubTotal" label="按分行小計排序" value="__YES__" />
              </td>
            </tr>
            <tr>
              <th>排　　序</th>
              <td>
                <RptQtyFilterRadioField state={state} setState={setState} fieldName="sorting" label="比率" value="__QTY_TRAN__" />
                <RptQtyFilterRadioField state={state} setState={setState} fieldName="sorting" label="姓名" value="__NAME__" />
                <RptQtyFilterRadioField state={state} setState={setState} fieldName="sorting" label="員工編號" value="__ID_EMPLOYEE__" />
              </td>
            </tr>
          </tbody >
        </table >

        <div className="mt-2 mb-3" style={{ textAlign: "right" }}>
          <Button startIcon={<PrintIcon />} className="mr-3" size="small" variant="contained" disableElevation onClick={print}>列　印</Button>
          <Button startIcon={<DescriptionIcon />} size="small" variant="contained" disableElevation onClick={excel}>Excel</Button>
        </div>

        <div className="mt-2 mb-3" style={{ textAlign: "right" }}>
        </div>
      </div >

      <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
    </>
  );
}

function RptQtyFilterRadioField({ state, setState, fieldName, label, value }) {
  const name = `filter_${fieldName}`;
  const id = `${name}_${value}`;
  return <span className="mr-1 ml-1">
    <input className="mr-1" type="radio" value={value} id={id} name={name} onChange={(e) => { setState({ ...state, [fieldName]: e.target.value, }); }} checked={state[fieldName] == value} />
    <label htmlFor={id}>{label}</label>
  </span>;
}
