import axios from 'axios';
import cfg from '../config.json';
import _, { cloneDeep } from 'lodash';
import jwt_decode from 'jwt-decode';

//================== Whole State ==================//
const initialState = {
  isLoading: false,
  fields: {
    Login: {
      value: '',
      errors: [],
    },
    Password: {
      value: '',
      errors: [],
    },
  },
  token: '',
  jWTClaimModel: null,
};

//User:
//DateJoin: "2010-10-09T15:10:01"
//DateLogin: "2021-04-08T05:06:53"
//DfnRole: "I"
//Id: 3
//IsActive: true
//IsPrinting: null
//Login: "raye"
//Name: "Raye Li"
//Role: "電腦部"

//================== Action Types ==================//
export const AuthActions = Object.freeze({
  // login form value changed
  AUTH_FORM_UPDATE: Symbol("AUTH_FORM_UPDATE"),

  // logging ajax actions
  AUTH_REQUEST: Symbol("AUTH_REQUEST"),
  AUTH_SUCCESS: Symbol("AUTH_SUCCESS"),
  AUTH_FAILURE: Symbol("AUTH_FAILURE"),
  AUTH_LOGOUT: Symbol("AUTH_LOGOUT"),

  // logging by storage actions
  AUTH_REQUEST_BY_STORAGE: Symbol("AUTH_REQUEST_BY_STORAGE"),
  AUTH_SUCCESS_BY_STORAGE: Symbol("AUTH_SUCCESS_BY_STORAGE"),
  AUTH_FAILURE_BY_STORAGE: Symbol("AUTH_FAILURE_BY_STORAGE"),

  AUTH_FAILURE_BY_GOOGLE: Symbol("AUTH_FAILURE_BY_GOOGLE"),
});


//================== Action Creators ==================//
export const authFormUpdate = (field, value) => {
  return {
    type: AuthActions.AUTH_FORM_UPDATE,
    payload: {
      field: field,
      value: value,
    }
  }
}

const _authRequest = () => {
  return {
    type: AuthActions.AUTH_REQUEST
  }
}

const _authSuccess = (token) => {
  return {
    type: AuthActions.AUTH_SUCCESS,
    payload: token,
  }
}

const _authFailure = (errors) => {
  return {
    type: AuthActions.AUTH_FAILURE,
    payload: errors,
  }
}

const _authRequestByStorage = () => {
  return {
    type: AuthActions.AUTH_REQUEST_BY_STORAGE
  }
}

const _authSuccessByStorage = (token, jWTClaimModel) => {
  return {
    type: AuthActions.AUTH_SUCCESS_BY_STORAGE,
    payload: {
      token: token,
      jWTClaimModel: jWTClaimModel,
    }
  }
}

const _authFailureByStorage = (history) => {
  return {
    type: AuthActions.AUTH_FAILURE_BY_STORAGE,
    payload: history,
  }
}

const _authFailureByGoogle = (history) => {
  return {
    type: AuthActions.AUTH_FAILURE_BY_GOOGLE,
    payload: history,
  }
}

export const authLogout = (history) => {
  return {
    type: AuthActions.AUTH_LOGOUT,
    payload: history,
  }
}

export const asyncAuth = (formData, history, googleSignOut) => {
  //console.log(formData);
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Authentication/Login`;

  return (dispatch) => {
    dispatch(_authRequest());
    axios.post(apiEndpoint, formData, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          //console.log('login by form', response.data);
          dispatch(_authSuccess(response.data)); // token string
          history.push("/");
        } else {
          //console.log("asyncAuth failed: 1");
          googleSignOut();

          dispatch(_authFailure([{ "Login": "未能取得 token" }]));
        }
      })
      .catch(result => {
        //console.log("asyncAuth failed: 2");
        googleSignOut();

        if (result.response && result.response.data && result.response.data.errors) {
          dispatch(_authFailure(result.response.data.errors));
        } else {
          dispatch(_authFailure([{ "Login": `未能登入 ${result}` }]));
        }
      })
  }
}

export const authRequestByStorage = (history) => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Authentication/GetSession`;
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (_.isEmpty(token)) {
      dispatch(_authFailureByStorage(history));
      return;
    }

    dispatch(_authRequestByStorage());

    axios
      .post(apiEndpoint, null, {
        timeout: 30000,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
      .then(response => {
        if (response.data) {
          dispatch(_authSuccessByStorage(token, response.data));
        } else {
          console.log("authRequestByStorage failed: 1");

          dispatch(_authFailureByStorage(history));
        }
      })
      .catch(result => {
        console.log("authRequestByStorage failed: 2");

        dispatch(_authFailureByStorage(history));
      });
  }
}

export const authRequestByGoogle = (credential, history, googleSignOut) => {
  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Authentication/LoginByGoogle`;
  return (dispatch) => {
    dispatch(_authRequest());
    axios.post(apiEndpoint, { tokenId: credential }, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          //console.log('login by google', response.data);

          dispatch(_authSuccess(response.data)); // token string

          history.push("/");
        } else {
          console.log("authRequestByGoogle failed: 1");

          googleSignOut();

          dispatch(_authFailureByGoogle());
        }
      })
      .catch(result => {
        console.log("authRequestByGoogle failed: 2");

        dispatch(_authFailureByGoogle());
      });
  }
}

export const actUser = (idUser, history) => {
  //console.log('actUser', idUser);

  const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/Authentication/actUser`;
  return (dispatch) => {
    dispatch(_authRequest());
    axios.post(apiEndpoint, { idUser }, {
      timeout: 30000,
    })
      .then(response => {
        if (response.data) {
          dispatch(_authSuccess(response.data)); // token string

          history.push("/");
        } else {
          console.log("actUser failed: 1");
          console.log(response);
        }
      })
      .catch(result => {
        console.log("actUser failed: 2");
        console.log(result);
      });
  }
}

//================== Reducer ==================//
const authReducer = (state = initialState, action) => {
  let _state = cloneDeep(state);

  let history = null;

  switch (action.type) {
    case AuthActions.AUTH_FORM_UPDATE:
      const { field, value } = action.payload;
      _state.fields[field].value = value;
      return _state;

    case AuthActions.AUTH_REQUEST:
      _state.isLoading = true;

      // clear errors
      _.forIn(_state.fields, function (value, key) {
        _state.fields[key].errors = [];
      });
      return _state;

    case AuthActions.AUTH_SUCCESS:
      _state.isLoading = false;
      _state.token = action.payload;
      _state.jWTClaimModel = JSON.parse(jwt_decode(_state.token).JWTClaimModel);
      //console.log(_state.jWTClaimModel);

      axios.defaults.headers.common['Authorization'] = `Bearer ${_state.token}`;

      localStorage.setItem('token', _state.token);
      localStorage.setItem('JWTClaimModel', jwt_decode(_state.token).JWTClaimModel);

      return _state;

    case AuthActions.AUTH_FAILURE:
      axios.defaults.headers.common['Authorization'] = null;

      localStorage.setItem('token', null);
      localStorage.setItem('JWTClaimModel', null);

      _state.isLoading = false;
      _state.token = '';
      _state.jWTClaimModel = null;

      const errors = action.payload;
      // assign errors
      _.forIn(errors, function (value, key) {
        if (!_.isObject(_state.fields[key])) return; // skips fields that not exists;

        _state.fields[key] = { value: _state.fields[key].value, errors: value };
      });
      //console.log(_state);
      return _state;

    case AuthActions.AUTH_LOGOUT:
      axios.defaults.headers.common['Authorization'] = null;

      localStorage.setItem('token', '');
      localStorage.setItem('JWTClaimModel', '');

      _state.token = '';
      _state.jWTClaimModel = null;

      setTimeout(() => {
        history = action.payload;
        history.push("/auth");
      }, 1);

      return _state;

    case AuthActions.AUTH_REQUEST_BY_STORAGE:
      _state.isLoading = true;
      return _state;

    case AuthActions.AUTH_SUCCESS_BY_STORAGE:
      _state.isLoading = false;
      _state.token = action.payload.token;
      _state.jWTClaimModel = action.payload.jWTClaimModel;
      //console.log(_state.jWTClaimModel);

      axios.defaults.headers.common['Authorization'] = `Bearer ${_state.token}`;

      localStorage.setItem('token', _state.token);
      localStorage.setItem('JWTClaimModel', jwt_decode(_state.token).JWTClaimModel);

      return _state;

    case AuthActions.AUTH_FAILURE_BY_STORAGE:
      _state.isLoading = false;

      axios.defaults.headers.common['Authorization'] = null;

      localStorage.setItem('token', '');
      localStorage.setItem('JWTClaimModel', '');

      _state.token = '';
      _state.jWTClaimModel = null;

      setTimeout(() => {
        //console.log('auth');
        history = action.payload;
        history.push("/auth");
      }, 1);

      return _state;

    case AuthActions.AUTH_FAILURE_BY_GOOGLE:
      _state.isLoading = false;

      axios.defaults.headers.common['Authorization'] = null;

      localStorage.setItem('token', '');
      localStorage.setItem('JWTClaimModel', '');

      _state.token = '';
      _state.jWTClaimModel = null;

      return _state;

    default:
      return _state;
  }
}

export default authReducer;
