import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// Example :
//import { downloadPdf } from '../../extensions/FileExtensions';
//import { LocalModelMsg } from "../ModalMsg";

//const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

//const print = () => {
//    const filter = _.keys(state).map(key => ({
//        property: key,
//        value: !_.isArray(state[key]) ? state[key] : JSON.stringify(state[key])
//    }));
//    //console.log(filter);

//    downloadPdf("/api/CredReportBusiness/GetPDF", {
//        filter: JSON.stringify(filter)
//    }, 'ReportBusiness.pdf', msgState, msgSetState);
//}

//return (
//    <>
//        ...
//        <ReportCommList filter={filterState} state={listState} setState={listSetState} printEmployees={printEmployees} />
//        ...
//        <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
//    </>
//);

export const downloadPdf = async (url, params, fileName, msgState, msgSetState) => {
  msgSetState({ ...msgState, isShow: true, type: "LOADING" });

  try {
    const response = await axios.post(url, params, {
      responseType: "blob",
      timeout: 600000,
    });

    msgSetState({ ...msgState, isShow: false });

    const blob = new Blob([response.data], { type: 'application/pdf' })
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.target = '_blank';
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
  }
  catch (error) {
    let errorMessage = "";
    if (!errorMessage) errorMessage = await error.response.data.text();
    if (!errorMessage) errorMessage = error.message ?? "";
    if (!errorMessage) errorMessage = error.toString();
    msgSetState({ ...msgState, isShow: true, type: 'ERROR', msg: errorMessage });
  }
}

export const openPdf = async (url, params, msgState, msgSetState) => {
  msgSetState({ ...msgState, isShow: true, type: "LOADING" });

  try {
    const response = await axios.post(url, params, {
      responseType: "blob",
      timeout: 600000,
    });

    msgSetState({ ...msgState, isShow: false });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const objectUrl = window.URL.createObjectURL(blob);
    window.open(objectUrl);

    return objectUrl;
  }
  catch (error) {
    let errorMessage = "";
    if (!errorMessage) errorMessage = await error.response.data.text();
    if (!errorMessage) errorMessage = error.message ?? "";
    if (!errorMessage) errorMessage = error.toString();
    msgSetState({ ...msgState, isShow: true, type: 'ERROR', msg: errorMessage });
  }
};

export const downloadXls = async (url, params, fileName, msgState, msgSetState) => {
  msgSetState({ ...msgState, isShow: true, type: "LOADING" });

  try {
    const response = await axios.post(url, params, {
      responseType: "blob",
      timeout: 600000,
    });

    msgSetState({ ...msgState, isShow: false });

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.target = '_blank';
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
  }
  catch (error) {
    let errorMessage = "";
    if (!errorMessage) errorMessage = await error.response.data.text();
    if (!errorMessage) errorMessage = error.message ?? "";
    if (!errorMessage) errorMessage = error.toString();
    msgSetState({ ...msgState, isShow: true, type: 'ERROR', msg: errorMessage });
  }
}

export const downloadZip = async (url, params, fileName, msgState, msgSetState) => {
  msgSetState({ ...msgState, isShow: true, type: "LOADING" });

  try {
    const response = await axios.post(url, params, {
      responseType: "blob",
      timeout: 600000,
    });

    msgSetState({ ...msgState, isShow: false });

    const blob = new Blob([response.data], { type: 'application/zip' })
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.target = '_blank';
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
  }
  catch (error) {
    let errorMessage = "";
    if (!errorMessage) errorMessage = await error.response.data.text();
    if (!errorMessage) errorMessage = error.message ?? "";
    if (!errorMessage) errorMessage = error.toString();
    msgSetState({ ...msgState, isShow: true, type: 'ERROR', msg: errorMessage });
  }
}

export const actionPdf = async (url, params, msgState, msgSetState) => {
  msgSetState({ ...msgState, isShow: true, type: "LOADING" });

  try {
    await axios.post(url, params, {
      responseType: "blob",
      timeout: 600000,
    });

    msgSetState({ ...msgState, isShow: true, type: "INFO", msg: "已完成" });
  }
  catch (error) {
    let errorMessage = "";
    if (!errorMessage) errorMessage = await error.response.data.text();
    if (!errorMessage) errorMessage = error.message ?? "";
    if (!errorMessage) errorMessage = error.toString();
    msgSetState({ ...msgState, isShow: true, type: 'ERROR', msg: errorMessage });
  }
}

const StyledDialog = withStyles({
  paper: {
    width: "calc(100vw - 200px)",
    height: "calc(100vh - 100px)",
    "& .darkDialogContent": {
      backgroundColor: '#6c6c6c',
    },
    "& div.centered": {
      height: "100%",
      display: "flex",
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
})(Dialog);

export const PreviewPdf = ({ open, setOpen, url, params, children }) => {
  const [state, setState] = useState({ isLoading: true, objectUrl: "", numPages: 0, pageNumber: 1, scale: 1 });
  const { isLoading, objectUrl, numPages, pageNumber, scale } = state;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setState({
      ...state,
      numPages,
    })
  }

  const handleClose = () => {
    setOpen(false);
  };

  const setScale = (ve) => {
    setState({
      ...state,
      scale: scale + ve * 0.1,
    });
  };

  useEffect(() => {
    if (!open) return;

    setState({
      ...state,
      isLoading: true,
      numPages: 0,
    });

    const getFile = async () => {
      try {
        const response = await axios.post(url, params, {
          responseType: "blob"
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        setState({
          ...state,
          isLoading: false,
          pageNumber: 1,
          objectUrl: window.URL.createObjectURL(blob),
        });
      }
      catch (error) {
        console.log(error);

        setState({
          ...state,
          isLoading: false,
        })
      }
    };

    getFile();
  }, [open]);

  const msgError = <div className="centered">未能顯示</div>;
  const msgLoading = "";
  const msgNoData = <div className="centered"><i>- 沒有數據 -</i></div>;

  return <StyledDialog open={open} onClose={handleClose} maxWidth={false} >
    {isLoading &&
      <DialogContent className="centered">
        <CircularProgress />
      </DialogContent>
    }
    {!isLoading &&
      <DialogContent className="darkDialogContent">
        <Document
          error={msgError}
          loading={msgLoading}
          noData={msgNoData}
          file={objectUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          options={{
            cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
          }}
        >
          <Page className="centered" width={800} scale={scale} pageNumber={pageNumber} />
        </Document>
      </DialogContent>
    }
    <DialogActions>
      <IconButton disabled={scale <= 0.5} onClick={() => setScale(-1)} ><ZoomOutIcon /></IconButton>
      <IconButton disabled={scale >= 1.5} onClick={() => setScale(+1)}><ZoomInIcon /></IconButton>
      {numPages > 1 && <Button startIcon={<NavigateBeforeIcon />} disabled={pageNumber <= 1} onClick={() => { setState({ ...state, pageNumber: pageNumber - 1 }); }}>上一頁</Button>}
      {numPages > 1 && <Typography>{pageNumber} of {numPages}</Typography>}
      {numPages > 1 && <Button endIcon={<NavigateNextIcon />} disabled={pageNumber >= numPages} onClick={() => { setState({ ...state, pageNumber: pageNumber + 1 }); }}>下一頁</Button>}
      {children}
      <Button className="mr-2" startIcon={<BlockIcon />} size="small" variant="contained" disableElevation onClick={handleClose}>取 消</Button>
    </DialogActions>
  </StyledDialog>;
};

