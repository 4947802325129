import React from 'react';
import Intl from 'intl';

export default class Bar {
    constructor({
        Id,
        IdTableRate,
        Class,
        Fvalue,
        Tvalue,
        Rate,
    }) {
        this.Id = Id;
        this.IdTableRate = IdTableRate;
        this.Class = Class;
        this.Fvalue = Fvalue;
        this.Tvalue = Tvalue;
        this.Rate = Rate;
    }

    isNew() {
        return !this.Id || (this.Id < 1);
    }

    getBarsComponent() {
        // Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 2 })
        // Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2 }).format(this.Rate / 100)

        const max2OrNoDigits = Intl.NumberFormat(undefined, { style: 'decimal', maximumFractionDigits: 2 });
        const min2Digits = Intl.NumberFormat(undefined, { style: 'decimal', minimumFractionDigits: 2 });

        return <div key={`bar_${this.Id}`} style={{ textAlign: 'right' }}>$ {max2OrNoDigits.format(this.Fvalue ? this.Fvalue : 0)}
            &nbsp;-&nbsp;$ {max2OrNoDigits.format(this.Tvalue ? this.Tvalue : 0)}
            &nbsp;:&nbsp;{(this.Rate ? min2Digits.format(this.Rate) : " - ")}%</div>
    }
}
