import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { reportCommReducedFilterUpdate, reportCommReducedFilterClear, reportCommReducedLookup } from '../../../redux/reportCommReducedReducer';

import { openPdf } from '../../../extensions/FileExtensions';
import { LocalModelMsg } from "../../ModalMsg";

import MyDateField from '../../MyDateField';
import { BranchSelection } from '../../Selections';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },

        "& label": {
            marginBottom: "0px",
        }
    },
});

export default function ReportCommReducedFilter({ filter }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const filters = JSON.parse(filter.filter);

    const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

    const _getFilterValue = (field) => {
        const i = _.findIndex(filters, function (o) { return o.property == field; });
        return (i >= 0) ? filters[i].value : "";
    };

    const filterChange = (field, value) => {
        dispatch(reportCommReducedFilterUpdate(field, value));
    };

    const clearFilter = () => {
        dispatch(reportCommReducedFilterClear());
    };

    const lookup = () => {
        dispatch(reportCommReducedLookup(filter));
    }

    const print = () => {
        openPdf("/api/ReportCommReduced/GetPDF", filter, msgState, msgSetState);
    }

    return (
        <div style={{width:'450px'}}>
            <table className={classes.frmFilter}>
                <tbody>
                    <tr>
                        <th style={{ textAlign: 'right', width: '130px' }}>落訂日期：</th>
                        <td>
                            <MyDateField dateValue={_getFilterValue("f_date_deposit")} onDateChange={(d) => { filterChange('f_date_deposit', d); }} tabIndex={4} />
                            &nbsp; 至 &nbsp;
                            <MyDateField dateValue={_getFilterValue("t_date_deposit")} onDateChange={(d) => { filterChange('t_date_deposit', d); }} tabIndex={5} />
                        </td>
                    </tr>
                    <tr>
                        <th style={{ textAlign: 'right', width: '130px' }}>減收日期：</th>
                        <td>
                            <MyDateField dateValue={_getFilterValue("f_date_reduced")} onDateChange={(d) => { filterChange('f_date_reduced', d); }} tabIndex={4} />
                            &nbsp; 至 &nbsp;
                            <MyDateField dateValue={_getFilterValue("t_date_reduced")} onDateChange={(d) => { filterChange('t_date_reduced', d); }} tabIndex={5} />
                        </td>
                    </tr>
                    <tr>
                        <th style={{ textAlign: 'right', width: '130px' }}>分　　行：</th>
                        <td><BranchSelection showAny={true} onChange={(e) => { filterChange('id_branch', e.target.value); }} value={_getFilterValue("id_branch")} tabIndex={6} /></td>
                    </tr>
                </tbody>
            </table>
            <div className="mt-2 mb-3" style={{textAlign: "right"}}>
                <Button size="small" variant="contained" disableElevation onClick={lookup}>搜　　尋</Button>
                &nbsp;&nbsp;
                <Button size="small" variant="contained" disableElevation onClick={clearFilter}>清　　除</Button>
                &nbsp;&nbsp;
                <Button size="small" variant="contained" disableElevation onClick={print}>列　　印</Button>
            </div>

            <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
        </div>
    );
}
