export default class CommRef {
    constructor({
        Id,
        Class,
        Estate,
        CodeDistrict,
        District,
        Lot,
        BldgEng,
        BldgChi,
        StreetEng,
        StreetChi,
        Phrase,
        Block,
        EditDate,
        EditTime,
        EditUser,
    }) {
        this.Id = Id;
        this.Class = Class;
        this.Estate = Estate;
        this.CodeDistrict = CodeDistrict;
        this.District = District;
        this.Lot = Lot;
        this.BldgEng = BldgEng;
        this.BldgChi = BldgChi;
        this.StreetEng = StreetEng;
        this.StreetChi = StreetChi;
        this.Phrase = Phrase;
        this.Block = Block;
        this.EditDate = EditDate;
        this.EditTime = EditTime;
        this.EditUser = EditUser;
    }

    isNew() {
        //console.log(this.Id);
        return !this.Id || (this.Id < 1);
    }

    getDesc() {
        if (this.isNew()) return "新參考地址";

        return this.BldgChi ? `#${this.Id} ${this.BldgChi}` : `參考地址 #${this.Id}`;
    }
}
