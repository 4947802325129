import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { vCommTranBonusLookup, vCommTranBonusFetchPage, vCommTranBonusPageUpdate } from '../../redux/vCommTranBonuReducer';

import CommBonusShareFilter from './CommBonusShareFilter';
import CommBonusShareList from './CommBonusShareList';
import Pagination from '../Pagination';

const useStyles = makeStyles({
    title: {
        paddingBottom: "15px",
        fontSize: "26px",
        lineHeight: "26px",
        fontWeight: "bold",
    },
});

export default function CommBonusShare() {
    const classes = useStyles();

    const history = useHistory();

    const dispatch = useDispatch();

    const newCommBonusShare = () => {
        history.push("/commBonusShareDetail/0");
    }

    const { filter } = useSelector(state => {
        return state.vCommTranBonus;
    });

    const { jWTClaimModel: { Creds } } = useSelector(state => {
        return state.auth;
    });

    useEffect(() => {
        if (!Creds.includes("COMMISSION")) history.replace("/");

        dispatch(vCommTranBonusLookup());
    }, []);

    return (
        <>
            <Typography variant="h1" className={classes.title}>1.2. 獎金分成</Typography>
            <CommBonusShareFilter filter={filter} />
            <Pagination filter={filter} fnFetchPage={vCommTranBonusFetchPage} fnPageUpdate={vCommTranBonusPageUpdate} />
            <CommBonusShareList />
            <div style={{ margin: "5px", textAlign: 'center' }}>
                <Pagination filter={filter} fnFetchPage={vCommTranBonusFetchPage} fnPageUpdate={vCommTranBonusPageUpdate} />
            </div>
        </>
    );
}
