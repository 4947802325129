import React, { useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';

import { employeeChangeSort } from '../../redux/employeeReducer';

import BeatLoader from "react-spinners/BeatLoader";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const useStyles = makeStyles({
    tblList: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& thead": {
            backgroundColor: "#c5e2ff",
            color: "#45525c",
        },

        "& tr": {
            cursor: "pointer",
        },

        "& tr.alt": {
            backgroundColor: "#f7fbff",
        },

        "& tr.lastWorkingEntity": {
            border: "2px solid #da59da",
        },

        "& tbody tr:hover, tbody tr.alt:hover": {
            backgroundColor: "#ffffcc !important",
        },

        "& th": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            textAlign: "center",
        },
    },
});

export default function EmployeeList() {
    const classes = useStyles();

    const history = useHistory();
    const dispatch = useDispatch();

    const { isLoading, filter, entities, workingEntityId, errors } = useSelector(state => {
        return state.employee;
    });

  //console.log('EmployeeList', entities);

    const sorts = JSON.parse(filter.sort);

    const _getSortDirection = (field) => {
        const i = _.findIndex(sorts, function (o) { return o.property == field; });
        return (i >= 0) ? sorts[i].direction : "";
    };

    const changeSort = (field) => {
        dispatch(employeeChangeSort(field, filter));
    };

    const showDetail = (id) => {
        history.push(`/employeeDetail/${id}`);
    }

    let i = filter.qtyPerPage * (filter.page - 1) + 1;

    const myRef = useRef(null);
    useEffect(() => {
        if (myRef.current) myRef.current.scrollIntoView({ block: "center" });
    }, []);

    return (
        <table className={classes.tblList} >
            <thead>
                <tr>
                    <th><span>行</span></th>
                    <th onClick={(_) => changeSort('id')}><span>員工編號</span><MyArrowIcon direction={_getSortDirection("id")} /></th>
                    <th onClick={(_) => changeSort('name')}><span>員工姓名</span><MyArrowIcon direction={_getSortDirection("name")} /></th>
                    <th onClick={(_) => changeSort('alias')}><span>員工別名</span><MyArrowIcon direction={_getSortDirection("alias")} /></th>
                    <th onClick={(_) => changeSort('branch')}><span>分行</span><MyArrowIcon direction={_getSortDirection("branch")} /></th>
                    <th onClick={(_) => changeSort('team')}><span>組別</span><MyArrowIcon direction={_getSortDirection("team")} /></th>
                    <th><span>權限</span><MyArrowIcon direction={_getSortDirection("lv")} /></th>
                    <th onClick={(_) => changeSort('hkid')}><span>身分證號碼</span><MyArrowIcon direction={_getSortDirection("hkid")} /></th>
                    <th onClick={(_) => changeSort('ad_login')}><span>電郵帳號</span><MyArrowIcon direction={_getSortDirection("ad_login")} /></th>
                    <th><span>在　職</span>{/*__IS_ON_THE_JOB__*/}</th>
                    <th><span>已離職</span>{/*__IS_LEAVED_AND_HAS_NOT_RECEIVED__*/}</th>
                    <th><span>停　用</span>{/*__IS_LEAVED_AND_SETTLED__*/}</th>
                </tr>
            </thead>

            {isLoading
                ?
                <tbody>
                    <tr>
                        <td colSpan={13} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
                    </tr>
                </tbody>
                :
                (errors !== "")
                    ?
                    <tbody>
                        <tr>
                            <td colSpan={13} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
                        </tr>
                    </tbody >
                    :
                    <tbody>
                        {
                            entities.map((entity, index) => {
                                const status = entity.getStatus();
                                return <tr key={entity.Id} ref={workingEntityId == entity.Id ? myRef : null} className={clsx({ "lastWorkingEntity": workingEntityId == entity.Id, "alt": index % 2 == 1, "normal": index % 2 == 0 })} onClick={() => { showDetail(entity.Id) }}>
                                    <td>{i++}</td>
                                    <td>{entity.Id}</td>
                                    <td>{entity.Name}</td>
                                    <td>{entity.Alias}</td>
                                    <td>{entity.Branch}</td>
                                    <td>{entity.Team}</td>
                                    <td>{entity.Lv}</td>
                                    <td>{entity.Hkid}</td>
                                    <td>{entity.AdLogin}</td>
                                    <td>{status == "__IS_ON_THE_JOB__" && <MyCheckIcon isTrue={true} />}</td>
                                    <td>{status == "__IS_LEAVED_AND_HAS_NOT_RECEIVED__" && <MyCheckIcon isTrue={false} />}</td>
                                    <td>{status == "__IS_LEAVED_AND_SETTLED__" && <MyCheckIcon isTrue={null} />}</td>
                                </tr>
                            })
                        }
                    </tbody>
            }
        </table>
    );
}

function MyArrowIcon({ direction }) {
    switch (direction) {
        case "ASC": return <ArrowDropUpIcon />;
        case "DESC": return <ArrowDropDownIcon />;
        default: return null;
    }
}

function MyCheckIcon({ isTrue }) {
    if (_.isNil(isTrue)) return <RemoveCircleIcon style={{ color: "rgb(96 115 255)" }} />;
    return isTrue ? <CheckCircleIcon style={{ color: "#4fa917" }} /> : <MonetizationOnIcon style={{ color: "#ffb000" }} />;
}
