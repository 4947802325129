export default class RptCommItem {
    constructor({
        Id,
        Branch,
        Hkid,
        IdEmployee,
        Name,
        DateReport,
        EmpBankReferral,
        EmpOthers,
        EmpRemarks,
        SupBankReferral,
        SupCommBonus,
        SupOthers,
        SupRemarks,
    }) {
        this.Id = Id;
        this.Branch = Branch;
        this.Hkid = Hkid;
        this.IdEmployee = IdEmployee;
        this.Name = Name;
        this.DateReport = DateReport;
        this.EmpBankReferral = EmpBankReferral;
        this.EmpOthers = EmpOthers;
        this.EmpRemarks = EmpRemarks;
        this.SupBankReferral = SupBankReferral;
        this.SupCommBonus = SupCommBonus;
        this.SupOthers = SupOthers;
        this.SupRemarks = SupRemarks;
    }
}
