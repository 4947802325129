import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { commApplySolicitor } from "../../redux/commReducer";
import { solicitorsRefLookupInfoHide } from "../../redux/solicitorsRefReducer";

const useStyles = makeStyles((theme)=>({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    backdrop: {
        backgroundColor: 'rgb(255, 255, 255, 0.5)',
    },
    paper: {
        position: 'absolute',
        width: 950,
        height: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: "auto",

        "& table": {
            width: "100%",

            "& th, & td": {
                padding: "2px 6px",
                border: "1px solid #D5D5D5",
            },

            "& thead": {
                backgroundColor: "#c5e2ff",
                color: "#45525c",
            },

            "& tbody tr": {
                cursor: 'pointer',
            },

            "& tbody tr:hover, tbody tr.alt:hover": {
                backgroundColor: "#ffffcc !important",
            },
        }
    }
}));

export default function ModalLookupInfoes() {
    const classes = useStyles();

    const { lookupInfoesIsOpen, lookupInfoes } = useSelector(state => {
        return state.solicitorsRef;
    });

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(solicitorsRefLookupInfoHide());
    };

    const handleSelect = (solicitorsRef) => {
        dispatch(commApplySolicitor(solicitorsRef));
    }

    return <Modal
        className={classes.root}
        open={lookupInfoesIsOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 250, className: classes.backdrop, }}
    >
        <Fade in={lookupInfoesIsOpen}>
            <div className={classes.paper}>
                (符合條件{lookupInfoes.length}筆)
                <table className="list" style={{ fontSize: '12px', width: '100%' }}>
                    <thead>
                        <tr>
                            <th>行</th>
                            <th>律師樓(中)</th>
                            <th>律師樓(英)</th>
                            <th>律師(中)</th>
                            <th>律師(英)</th>
                            <th>律師 Tel</th>
                            <th>律師 Fax</th>
                            <th>律師 Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lookupInfoes.length < 1
                                ?
                                <tr>
                                    <td colSpan="10">
                                        <Grid container justifyContent="center" className="p-3">
                                            沒有記錄
                                        </Grid>
                                    </td>
                                </tr>
                                :
                                lookupInfoes.map((solicitorsRef, index) => <tr key={solicitorsRef.Id} onClick={(_) => { handleSelect(solicitorsRef); }}>
                                    <td>{index+1}</td>
                                    <td>{solicitorsRef.SolicitorsChi}</td>
                                    <td>{solicitorsRef.SolicitorsEng}</td>
                                    <td>{solicitorsRef.LawyerChi}</td>
                                    <td>{solicitorsRef.LawyerEng}</td>
                                    <td>{solicitorsRef.LawyerTel}</td>
                                    <td>{solicitorsRef.LawyerFax}</td>
                                    <td>{solicitorsRef.LawyerRemarks}</td>
                                </tr>) 
                        }
                    </tbody>
                </table>
                <Grid container justifyContent="center"  className="mt-3">
                    <Button variant="contained" size="small" disableElevation onClick={(_) => { handleClose() }}>取　消</Button>
                </Grid>
            </div>
        </Fade>
    </Modal>;
};