import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Moment from 'moment';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import _ from 'lodash';

import { isConfirmedLookup } from '../../redux/bankReferralReducer';

import { downloadPdf } from '../../extensions/FileExtensions';
import { LocalModelMsg } from "../ModalMsg";

import { CredCalMonthSelection, CredSupervisorSelection } from "../Selections";
import { BtnIsConfirmed } from "../bankReferral/BtnIsConfirmed";

const useStyles = makeStyles({
    title: {
        "& a.nav-link": {
            padding: "0px",
        },

        "& a.nav-link:hover h1": {
            color: "#006ed0",
        },

        "& h1": {
            paddingBottom: "15px",
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "bold",
        },
    },

    frmFilter: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        width: "100%",

        "& th": {
            fontWeight: 'bold',
            border: "1px solid #B0B0B0",
            backgroundColor: "#FAF3DE",
            padding: "2px 4px",
        },

        "& td": {
            border: "1px solid #B0B0B0",
            padding: "2px 4px",
        },

        "& label": {
            marginBottom: "0px",
        }
    },
});

export default function CredReportCommSupervisor() {
    const classes = useStyles();

    const dispatch = useDispatch();

    const { isConfirmed } = useSelector(state => {
        return state.bankReferral;
    });

    const [state, setState] = useState({
        calMonth: Moment.utc().subtract(1, 'months').format("YYYY-MM-01"),
        idEmployee: "",
    });

    const [msgState, msgSetState] = useState({ isShow: false, type: "", msg: "", fnConfirmed: null });

    const print = (isRecal) => {
        downloadPdf("/api/CredReportCommSupervisor/GetPDF", { ...state, isRecal }, '主管佣紙.pdf', msgState, msgSetState);
    }

    useEffect(() => {
        _isConfirmedLookup();
    }, []);

    const _isConfirmedLookup = (dateReport) => {
        dispatch(isConfirmedLookup({ filter: JSON.stringify([{ property: "dateReport", value: dateReport ? dateReport : state.calMonth }]) }));
    };

    return (
        <>
            <Breadcrumbs className={classes.title}>
                <Typography variant="h1" className={classes.title}>1.1. 主管佣紙</Typography>
            </Breadcrumbs>

            <div className="form-group row">
                <label htmlFor="calMonth" className="col-sm-2 col-form-label">計算年度及月份:</label>
                <div className="col-sm-10">
                    <CredCalMonthSelection className="form-control" value={state.calMonth} onChange={(e) => { setState({ ...state, calMonth: e.target.value }); _isConfirmedLookup(e.target.value); }} tabIndex="1" />
                    <BtnIsConfirmed className="mt-1" isConfirmed={isConfirmed} />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="calMonth" className="col-sm-2 col-form-label">經理:</label>
                <div className="col-sm-10">
                    <CredSupervisorSelection showAny={false} className="form-control" value={state.idEmployee} onChange={(e) => { setState({ ...state, idEmployee: e.target.value }); }} tabIndex="2" />
                    <small className="form-text text-muted">** 每天預先計算，但如當天有更新才需要重新計算並列印</small>
                </div>
            </div>

            <div className="mt-2 mb-3" style={{ textAlign: "center" }}>
                <Button style={{ backgroundColor: '#ededec' }} size="small" className="mr-3" variant="contained" disableElevation onClick={(e) => { print(true); }}>列印 (實時，需耐心等候 🙏 )</Button>
                <Button size="small" variant="contained" disableElevation onClick={(e) => { print(false); }}>列印 (截至昨天)</Button>
            </div>

            <LocalModelMsg msgState={msgState} msgSetState={msgSetState} />
        </>
    );
}
