import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';
import cfg from '../../config.json';
import axios from 'axios';

import Bar from "../../models/bar";

import { tableRateChangeSort } from '../../redux/tableRateReducer';
import ReactMoment from "react-moment";

import BeatLoader from "react-spinners/BeatLoader";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  tblList: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",

    "& thead": {
      backgroundColor: "#c5e2ff",
      color: "#45525c",
    },

    "& tr": {
      cursor: "pointer",
    },

    "& tr.alt": {
      backgroundColor: "#f7fbff",
    },

    "& tr.lastWorkingEntity": {
      border: "2px solid #da59da",
    },

    "& tbody tr:hover, tbody tr.alt:hover": {
      backgroundColor: "#ffffcc !important",
    },

    "& th": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },

    "& td": {
      border: "1px solid #B0B0B0",
      textAlign: "center",
    },
  },
});

export default function VSupervisorList() {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading, filter, entities, workingEntityId, errors } = useSelector(state => {
    return state.tableRate;
  });
  //console.log('workingEntityId', workingEntityId);
  //console.log(entities.map(entity => entity.getType()));

  const _filter = JSON.parse(filter.filter);
  const _getFilterValue = (field) => {
    const i = _.findIndex(_filter, function (o) { return o.property == field; });
    return (i >= 0) ? _filter[i].value : "";
  };
  const typeFilter = _getFilterValue("type");

  const sorts = JSON.parse(filter.sort);

  const _getSortDirection = (field) => {
    const i = _.findIndex(sorts, function (o) { return o.property == field; });
    return (i >= 0) ? sorts[i].direction : "";
  };

  const changeSort = (field) => {
    dispatch(tableRateChangeSort(field, filter));
  };

  const showDetail = (id) => {
    history.push(`/tableRateDetail/${id}`);
  }

  let i = filter.qtyPerPage * (filter.page - 1) + 1;

  return (
    <table className={classes.tblList} >
      <thead>
        <tr>
          <th><span>行</span></th>
          <th onClick={(_) => changeSort('id')}><span>編號</span><MyArrowIcon direction={_getSortDirection("id")} /></th>
          <th onClick={(_) => changeSort('team')}><span>組別</span><MyArrowIcon direction={_getSortDirection("team")} /></th>
          <th onClick={(_) => changeSort('supervisor_group')}><span>主管組別</span><MyArrowIcon direction={_getSortDirection("supervisor_group")} /></th>
          <th onClick={(_) => changeSort('name')}><span>主管</span><MyArrowIcon direction={_getSortDirection("name")} /></th>
          <th onClick={(_) => changeSort('fdate')}><span>開始日期</span><MyArrowIcon direction={_getSortDirection("fdate")} /></th>
          <th onClick={(_) => changeSort('tdate')}><span>完結日期</span><MyArrowIcon direction={_getSortDirection("tdate")} /></th>
        </tr>
      </thead>

      {isLoading
        ?
        <tbody>
          <tr>
            <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><BeatLoader color={"#c0c0c0"} /></td>
          </tr>
        </tbody>
        :
        (errors !== "")
          ?
          <tbody>
            <tr>
              <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>{errors}</em></td>
            </tr>
          </tbody >
          :
          <tbody>
            {
              entities.length < 1
                ? <tr>
                  <td colSpan={10} style={{ padding: "3em", textAlign: "center", }}><em>沒有符合的記錄</em></td>
                </tr>
                : entities.map((entity, index) => {
                  // since detail page workingEntityId could be a combination. use startsWith instead of equals. see tableRateReducer
                  return <tr key={entity.Id} className={clsx({ "lastWorkingEntity": _.startsWith(workingEntityId, entity.Id + "_"), "alt": index % 2 == 1, "normal": index % 2 == 0 })} onClick={() => { showDetail(entity.Id) }}>
                    <td>{i++}</td>
                    <td>{entity.Id}</td>
                    <td>
                      {entity.Team &&
                        <Tooltip disableFocusListener title={<PreviewTable entity={entity} />} placement="right" arrow ><span>{entity.Team}</span></Tooltip>
                      }
                    </td>
                    <td>
                      {entity.SupervisorGroup &&
                        <Tooltip disableFocusListener title={<PreviewTable entity={entity} />} placement="right" arrow ><span>{entity.SupervisorGroup}{entity.QtySuperviseTeam ? ` (${entity.QtySuperviseTeam})` : ""}</span></Tooltip>
                      }
                    </td>
                    <td>
                      {entity.Name &&
                        <Tooltip disableFocusListener title={<PreviewTable entity={entity} />} placement="right" arrow ><span>{entity.Name}</span></Tooltip>
                      }
                    </td>
                    <td>{entity.Fdate ? <ReactMoment format="YYYY-MM-DD" date={entity.Fdate} /> : "---"}</td>
                    <td>{entity.Tdate ? <ReactMoment format="YYYY-MM-DD" date={entity.Tdate} /> : "--- 應用中 ---"}</td>
                  </tr>
                })
            }
          </tbody>
      }
    </table>
  );
}

function MyArrowIcon({ direction }) {
  switch (direction) {
    case "ASC": return <ArrowDropUpIcon />;
    case "DESC": return <ArrowDropDownIcon />;
    default: return null;
  }
}

function PreviewTable({ entity: tableRate }) {
  const [isInit, setIsInit] = useState(true);
  const [content, setContent] = useState(<div></div>);

  //console.log(tableRate);
  //console.log(tableRate.getType());

  const tableRateType = tableRate.getType();

  useEffect(() => {
    if (!isInit) return;

    const apiEndpoint = `${cfg.ApiOrigin == "window.location.origin" ? window.location.origin : cfg.ApiOrigin}/api/bar/GetEntitiesByTableRate`;

    axios.post(apiEndpoint, tableRate, {
      timeout: 30000,
    })
      .then(response => {
        setIsInit(false);

        if (!response.data) throw '未能讀取';

        const bars = _.filter(response.data, function (bar) {
          switch (tableRateType) {
            case "__TEAM__": return bar.Class == "SECOND_HAND";
            case "__SUPERVISOR_GROUP__": return bar.Class == "SUPERVISOR";
            case "__SUPERVISOR__": return bar.Class == "SUPERVISOR";
          }

          return false;
        });

        setContent(<div>
          <b>{tableRate.getDesc()}</b>
          {bars.map(b => new Bar(b)).map((b) => b.getBarsComponent())}
        </div>);
      })
      .catch(error => {
        setIsInit(false);
        setContent("未能讀取");
      });

  }, [isInit]);

  return isInit ? <CircularProgress /> : content;
}